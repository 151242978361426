import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import SelectComponent from '../../../../components/SelectComponent';
import t from 'typy';
import SettingsAPI from '../../../../service/SettingsAPI';
import DomainsAPI from '../../../../service/DomainsAPI';
import { Form, Formik } from 'formik';
import TreatmentClinicalRecordApi from '../../../../service/TreatmentClinicalRecordApi';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../../../components/actions/Loader.actions';
import ToastUtils from '../../../../utils/ToastUtils';

const TreatmentClinicalRecordKeywordsModal = ({ className, updateParent }, modalRef) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [diagnostic, setDiagnostic] = useState({});
  const [treatment, setTreatment] = useState({});
  const [record, setRecord] = useState({});
  const [tagsOptions, setTagsOptions] = useState([]);
  const [formInit, setFormInit] = useState({});

  console.log(modalRef);

  useImperativeHandle(modalRef, () => ({
    showModal(diagnostic, treatment, record) {
      setShowModal(true);
      setDiagnostic(diagnostic);
      setTreatment(treatment);
      setRecord(record);
      setFormInit({
        tags: diagnostic.tags.map(({ tag }) => ({
          label: tag.nome,
          value: tag.id
        }))
      });
    }
  }));

  useEffect(() => {
    DomainsAPI.findAllTags().then((data) => {
      const tags = data.map((tag) => ({
        label: tag.nome,
        value: tag.id
      }));
      setTagsOptions(tags);
    });
  }, []);

  function closeModal() {
    setShowModal(false);
    setDiagnostic({});
  }

  function saveTags(values) {
    const { tags } = values;

    dispatch(showLoader());
    TreatmentClinicalRecordApi.saveTags({
      diagnostic: diagnostic.id,
      recordId: record,
      treatmentId: treatment,
      tags: t(tags).safeArray.map((tag) => t(tag, 'value').safeNumber)
    })
      .then((_) => {
        dispatch(hideLoader());
        closeModal();
        updateParent();
      })
      .catch((err) => {
        console.error(err);
        ToastUtils.error('Erro ao salvar Tags');
        dispatch(hideLoader());
      });
  }

  return (
    <Modal isOpen={showModal} toggle={closeModal} className={className + ' modal-small'}>
      <ModalHeader
        className='title-primary'
        toggle={closeModal}
        close={
          <button className='close' onClick={closeModal}>
            &times;
          </button>
        }
      >
        <strong>Inserir Keyword - Tag</strong>
      </ModalHeader>
      <Formik onSubmit={saveTags} initialValues={formInit}>
        {({ values, handleChange, error }) => {
          return (
            <Form>
              <ModalBody>
                <SelectComponent
                  name={'tags'}
                  options={tagsOptions}
                  closeMenuOnSelect={false}
                  isMulti={true}
                  values={values}
                  handleChange={handleChange}
                />
              </ModalBody>
              <ModalFooter>
                <Row>
                  <Col sm={{ size: 3, offset: 6 }}>
                    <button className='button-primary' type={'submit'}>
                      Salvar
                    </button>
                  </Col>
                  <Col sm={{ size: 3, offset: 0 }}>
                    <button className='button-tertiary' type={'button'} onClick={() => closeModal()}>
                      Cancelar
                    </button>
                  </Col>
                </Row>
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default forwardRef(TreatmentClinicalRecordKeywordsModal);
