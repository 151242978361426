import t from 'typy';
import { createSelector } from 'reselect';

const treatmentPlanOthersDetailsModalSelector = (state) =>
  t(state, 'TreatmentClinicalRecordReducer.treatmentPlanOtherDetailsModal').safeObject;
const treatmentPlanOthersDetailsSelector = (state) =>
  t(state, 'TreatmentClinicalRecordReducer.treatmentPlanOtherDetailsModal.plan.outros_detalhes').safeObject;

export const propsModalSelector = createSelector(
  treatmentPlanOthersDetailsModalSelector,
  treatmentPlanOthersDetailsSelector,
  (treatmentPlanGoalModal = {}, outros_detalhes = []) => {
    const { modal, treatment, record, plan = {} } = treatmentPlanGoalModal;

    const othersDetails = outros_detalhes.map((o) => o.outros_detalhes);

    if (othersDetails.length == 0) othersDetails.push('');

    return {
      modal,
      treatment,
      record,
      plan: plan.id,
      othersDetails
    };
  }
);
