import React, { Component } from 'react';

import { Col, Row } from 'reactstrap';
import user from '../../../assets/img/icons/user.svg';
import contact from '../../../assets/img/icons/contact.svg';
import calendar from '../../../assets/img/icons/calendar.svg';
import tooth from '../../../assets/img/icons/tooth.png';
import machine from '../../../assets/img/icons/machine.svg';
import settings from '../../../assets/img/icons/settings.svg';
import Icon from '../../../components/Icon.component';
import ButtomArrowUpDownComponentComponent from '../../../components/ButtomArrowUpDownComponent.component';

import RestrictionsCollapse from './components/RestrictionsCollapse';
import DomainsAPI from '../../../service/DomainsAPI';
import UserAPI from '../../../service/User.service';
import { selectStyles } from '../../../../src/config/core.config';

import Select from 'react-select';
import { reactSelectHandleChange, reactSelectStyleIsError } from '../../../utils/ReactSelectUtils';

import { t } from 'typy';

class UserSettingsFormRegisterPermissoesForm extends Component {
  state = {
    modules: [],
    isMaster: false
  };

  images = {
    1: {
      image: <Icon className='icon icon-size-small margin-right-5' image={calendar} alt='calendar' />
    },
    2: {
      image: <Icon className='icon icon-size-small margin-right-5 icon-size-two' image={contact} alt='calendar' />
    },
    3: {
      image: <Icon className='icon icon-size-small margin-right-5 icon-size-four' image={user} alt='calendar' />
    },
    4: {
      image: <img className='icon icon-size-small margin-right-5' src={tooth} alt='calendar' />
    },
    5: {
      image: <img className='icon icon-size-small margin-right-5' src={tooth} alt='calendar' />
    },
    6: {
      image: <img className='icon icon-size-small margin-right-5' src={tooth} alt='calendar' />
    },
    7: {
      image: <img className='icon icon-size-small margin-right-5' src={tooth} alt='calendar' />
    },
    8: {
      image: <Icon className='icon icon-size-small margin-right-5' image={machine} alt='calendar' />
    },
    9: {
      image: <Icon className='icon icon-size-small margin-right-5' image={settings} alt='calendar' />
    }
  };

  componentDidMount = async () => {
    const modules = await DomainsAPI.findAllModules();
    this.setState({ modules });

    const { acessos = [] } = this.props.values;

    acessos.forEach((a) => {
      const moduloId = a.modulo_id;
      const permissao_id = a.permissoes[0] && a.permissoes[0].permissao_id;
      const restricoes = (a.permissoes[0] && a.permissoes[0].restricoes) || [];

      this.props.handleChange({
        target: {
          name: `permissions['mol_${moduloId}']`,
          value: {
            module_id: moduloId,
            permission_id: permissao_id,
            hasRestrictions: restricoes && restricoes.length > 0 ? true : false
          }
        }
      });

      this.refs[`permissoes_${moduloId}`].value = JSON.stringify({
        permissao_id: permissao_id,
        module_id: moduloId
      });

      if (restricoes.length > 0) {
        const buttonRef = this.refs[`restricao_${moduloId}`];
        const button = document.getElementById(`restricao_${moduloId}`);
        button.disabled = false;
        button.click();
        buttonRef.setState({ open: true });
        document.getElementById(`restricao_box_${moduloId}`).classList.add('show');
      }

      restricoes.forEach((r) => {
        const checkbox = document.getElementById(`permissions[mol_${moduloId}].restrictions['rest_${r.restricao_id}']`);
        checkbox.checked = true;

        this.props.handleChange({
          target: {
            name: `permissions[mol_${moduloId}].restrictions['rest_${r.restricao_id}']`,
            value: { restriction_id: r.restricao_id }
          }
        });
      });
    });

    const userResponse = await UserAPI.findById(user);
    this.setState({
      isMaster: userResponse.data.adm
    });
  };

  showRestrictions = (e) => {
    const {
      target: { value }
    } = e;
    const permission = JSON.parse(value);
    const moduloId = permission.module_id;

    const permissoes = this.state.modules.find((m) => m.id === permission.module_id).permissoes || [];

    const permissao = permissoes.find((p) => p.id === permission.permissao_id) || {};
    const restricoes = permissao.restricoes || [];

    this.props.handleChange({
      target: {
        name: `permissions['mol_${moduloId}']`,
        value: {
          module_id: permission.module_id,
          permission_id: permission.permissao_id,
          hasRestrictions: restricoes && restricoes.length > 0 ? true : false
        }
      }
    });

    const button = document.getElementById(`restricao_${moduloId}`);
    const buttonRef = this.refs[`restricao_${moduloId}`];
    const box = document.getElementById(`restricao_box_${moduloId}`);

    if (restricoes && restricoes.length > 0) {
      button.disabled = false;
      button.click();
      buttonRef.setState({ open: true });
    } else {
      const boxShow = box.className.includes('show');

      if (boxShow) {
        box.classList.remove('show');

        button.click();
        buttonRef.setState({ open: false });

        this.props.handleChange({
          target: {
            name: `permissions[mol_${moduloId}].restrictions']`,
            value: []
          }
        });

        box.querySelectorAll('input[type=checkbox]').forEach((c) => (c.checked = false));
      }

      button.disabled = true;
    }
  };

  render() {
    const { display, profiles, errors, values = {}, handleChange } = this.props;
    const { modules } = this.state;
    const perfilName = profiles.find((p) => p.id === parseInt(values.perfil_id)) || {};
    let arrayNoAccess = new Array({ value: null, label: 'Sem acesso' });
    const { acessos = [] } = values;
    return (
      <div style={{ display: display ? 'block' : 'none' }} className='margin-top-10'>
        <Row>
          <Col sm='12'>
            <p className={'text-default text-secondary-d4'}>
              <strong className={'text-secondary-d4'}>Categoria/Perfil </strong>{' '}
              <span className={'text-uppercase'}>{perfilName.nome}</span>
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <div className='box-collapse-background'>
              {modules.map((m) => {
                arrayNoAccess[0].value = JSON.stringify({ id: null, module_id: m.id });
                arrayNoAccess[0].label = 'Sem acesso';
                let restrictions = [];

                return (
                  <div className='box-collapse'>
                    <Row className={'pb-0'}>
                      <Col sm='6'>
                        <p className='vertical-center'>
                          {this.images[m.id].image}
                          {m.nome}
                        </p>
                      </Col>
                      <Col sm={5}>
                        <Select
                          options={arrayNoAccess.concat(
                            m.permissoes.map((p) => {
                              return {
                                value: JSON.stringify({
                                  permissao_id: p.id,
                                  module_id: m.id
                                }),
                                label: p.nome
                              };
                            })
                          )}
                          defaultValue={
                            values.acessos
                              ? values.acessos.find((x) => x.modulo_id == m.id) != undefined
                                ? values.acessos
                                    .find((x) => x.modulo_id == m.id)
                                    .permissoes.map((p) => {
                                      return {
                                        value: JSON.stringify({
                                          permissao_id: p.permissao_id,
                                          module_id: m.id
                                        }),
                                        label: p.permissao_nome
                                      };
                                    })
                                : arrayNoAccess[0]
                              : arrayNoAccess[0]
                          }
                          ignoreCase={true}
                          placeholder={''}
                          onChange={(e) => {
                            arrayNoAccess[0].value = JSON.stringify({ id: null, module_id: m.id });
                            reactSelectHandleChange(e, handleChange, `clean_${m.id}`);
                            let evt = new Event('change', {
                              view: window,
                              bubbles: true,
                              cancelable: true
                            });
                            document.getElementsByName(`onchange_${m.id}`)[0].value = e.value;
                            document.getElementsByName(`onchange_${m.id}`)[0].dispatchEvent(evt);
                          }}
                          styles={reactSelectStyleIsError(errors, `clean_${m.id}.value`, selectStyles)}
                        />
                        <select
                          id='soflow'
                          name={'onchange_' + m.id}
                          className={'d-none'}
                          onChange={this.showRestrictions}
                          ref={`permissoes_${m.id}`}
                        >
                          <option value={JSON.stringify({ id: null, module_id: m.id })}>Sem Acesso</option>
                          {m.permissoes.map((p) => {
                            if (p.restricoes.length > 0) {
                              restrictions = restrictions.concat(
                                p.restricoes.map((r) => {
                                  return {
                                    id: m.id,
                                    restriction_id: r.id,
                                    permission_id: p.id,
                                    value: r.id,
                                    label: r.nome
                                  };
                                })
                              );
                            }

                            return (
                              <option
                                value={JSON.stringify({
                                  permissao_id: p.id,
                                  module_id: m.id
                                })}
                              >
                                {p.nome}
                              </option>
                            );
                          })}
                        </select>
                      </Col>
                      <Col sm={1}>
                        <ButtomArrowUpDownComponentComponent
                          className={'pt-2'}
                          id={`restricao_${m.id}`}
                          ref={`restricao_${m.id}`}
                          show={restrictions.length > 0}
                          disabled={!t(this.props.values, `permissions.mol_${m.id}.hasRestrictions`).safeBoolean}
                        />
                      </Col>
                      <RestrictionsCollapse
                        onChange={handleChange}
                        id={`restricao_box_${m.id}`}
                        restrictions={restrictions}
                        toggler={`#restricao_${m.id}`}
                      />
                    </Row>
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default UserSettingsFormRegisterPermissoesForm;
