import { call, put, takeEvery } from 'redux-saga/effects';
import { hideLoader, showLoader } from '../../../../components/actions/Loader.actions';
import TreatmentImagesApi from '../../../../service/TreatmentImagesApi';
import { openHomeLoadPage } from '../store/TreatmentImagesStore';
import TreatmentAPI from '../../../../service/TreatmentAPI';

export const Sagas = {
  ImagesLoadPage: 'treatment/images/saga/load'
};

export function* watchTreatmentImages() {
  yield takeEvery(Sagas.ImagesLoadPage, prepareImagesHomeLoad);
}

export function* prepareImagesHomeLoad({ payload }) {
  yield put(showLoader());
  const treatment = payload.treatment;
  const patient = yield call(TreatmentAPI.getTreatmentById, treatment);
  let folders = yield call(TreatmentImagesApi.findAllFoldersByTreatment, treatment);

  if (folders.data.length === 0) {
    yield call(TreatmentImagesApi.createFolder, treatment, 'Doc Inicial');
    folders = yield call(TreatmentImagesApi.findAllFoldersByTreatment, treatment);
  }

  yield put(
    openHomeLoadPage({
      treatment,
      patient: patient.data,
      folders: folders.data
    })
  );
  yield put(hideLoader());
}
