import React, { Component } from 'react';
import { Col, Input, Label, ModalBody, ModalFooter, Row, UncontrolledTooltip } from 'reactstrap';

import MaskedInput from 'react-text-mask';

import { Formik } from 'formik';
import * as Yup from 'yup';
import ErrorField from '../../../components/ErrorField.component';
import DomainsAPI from '../../../service/DomainsAPI';
import ClinicAPI from '../../../service/ClinicAPI';
import SimpleSuggestionBoxComponent from '../../../components/SimpleSuggestionBox.component';
import { compose } from 'recompose';
import MessagesComponent from '../../../components/Messages.component';
import { t } from 'typy';
import { IfComponent } from '../../../components/if.component';
import help from '../../../assets/img/icons/help.svg';
import whats from '../../../assets/img/icons/whats.svg';
import Icon from '../../../components/Icon.component';
import { IoLogoWhatsapp } from 'react-icons/io';

// import Select from 'react-select';
// import {
//   reactSelectHandleChange,
//   reactSelectStyleIsError
// } from '../../../utils/ReactSelectUtils';
// import {selectStyles} from '../../../../src/config/core.config'

export class PatientAddForm extends Component {
  state = {
    indicationsTypes: [],
    indicatorSuggestions: [],
    indicationWhoValue: {},
    indicationTypeId: undefined,
    clinic: {
      ddd: ''
    }
  };
  indicationTypesOfShowWhoIndication = [1, 2, 3];

  validateSchema = Yup.object().shape({
    name: Yup.string()
      // .max(27, 'Nome deve conter no máximo 27 caracteres')
      .required('Nome Obrigatório')
  });

  formRef = {};
  handleChange = {};

  componentDidMount = () => {
    this.props.showLoader();
    const clinicPromisse = ClinicAPI.findByUser().then(({ data }) => {
      this.setState({
        clinic: {
          ddd: data.ddd
        }
      });
    });

    const domainPromisse = DomainsAPI.findIndicationTypes().then(({ data }) => {
      this.setState({
        indicationsTypes: data
      });
    });

    Promise.all([clinicPromisse, domainPromisse]).then(() => {
      this.props.hideLoader();
    });
  };

  selectingIndicationType = (id) => {
    this.setState({
      indicationTypeId: id
    });
  };

  clearWhoIndicatior = () => {
    this.setState({
      indicatorSuggestions: [
        {
          id: 0,
          name: 'Nenhuma indicação encontrada'
        }
      ]
    });
  };

  findWhoIndicators = (value) => {
    const { indicationTypeId } = this.state;

    DomainsAPI.findWhoIndication(value, indicationTypeId).then(({ data }) => {
      this.handleChange({
        target: {
          name: 'whoIndicated',
          value: ''
        }
      });
      if (data.length === 0) {
        this.setState({
          indicatorSuggestions: [
            {
              id: 0,
              name: 'Nenhuma indicação encontrada'
            }
          ]
        });
      } else {
        this.setState({
          indicatorSuggestions: data.map((i) => {
            return {
              id: i.id,
              name: i.nome
            };
          })
        });
      }
    });
  };

  render() {
    const formValues = this.props.formModalAddValues || {};
    const whoIndicated = formValues.whoIndicated || {};
    const {
      indicationsTypes,
      indicatorSuggestions,
      indicationTypeId = whoIndicated.id || undefined,
      clinic
    } = this.state;

    const { ddd } = clinic;

    const {
      close,
      onSubmitForm,
      formModalAddValues = { phone1: ddd, phone2: ddd, whoIndicated: {} },
      errorDisplay
    } = this.props;

    return (
      <Formik
        validateOnChange={false}
        enableReinitialize={true}
        initialValues={formModalAddValues}
        validationSchema={this.validateSchema}
        onSubmit={onSubmitForm}
      >
        {({ errors, handleSubmit, handleBlur, handleChange, values }) => {
          this.handleChange = handleChange;
          return (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <Row>
                  <Col sm='12' class='mt-4'>
                    <span className='title-modal'>Informações Pessoais</span>
                  </Col>
                </Row>
                <MessagesComponent
                  display={errorDisplay.display}
                  type={errorDisplay.type}
                  message={errorDisplay.message}
                />
                <div className='margin-top-10'>
                  <Row>
                    <Col sm='8'>
                      <Label>
                        Nome<span>*</span>
                      </Label>
                      <Input
                        name='name'
                        className='input-secondary'
                        onChange={handleChange}
                        invalid={errors.name ? true : false}
                        value={values.name}
                        maxLength={254}
                      />
                      <ErrorField errors={errors} fieldName={'name'} />
                    </Col>
                    <Col sm='4'>
                      <Label>Data de Nascimento</Label>
                      <MaskedInput
                        id='birthDate'
                        name='birthDate'
                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                        value={values.birthDate}
                        onChange={handleChange}
                        render={(ref, props) => (
                          <Input
                            className='input-secondary'
                            innerRef={ref}
                            invalid={errors.birthDate ? true : false}
                            {...props}
                          />
                        )}
                      />
                      <ErrorField errors={errors} fieldName={'birthDate'} />
                    </Col>
                  </Row>
                </div>
                <div className='margin-top-10'>
                  <Row>
                    <Col sm='6'>
                      <label>Tipo Indicação</label>
                      <select
                        id=''
                        className='form-control'
                        name='indicationType'
                        onChange={(e) => {
                          this.selectingIndicationType(e.target.value);
                          handleChange(e);
                        }}
                        value={values.indicationType}
                      >
                        <option />
                        {indicationsTypes.map((i) => (
                          <option value={i.id} key={i.id}>
                            {i.nome}
                          </option>
                        ))}
                      </select>
                    </Col>
                    {this.indicationTypesOfShowWhoIndication.includes(parseInt(indicationTypeId)) ? (
                      <Col sm='6'>
                        <label>Quem Indicou</label>
                        <Icon
                          id='tooltipWhoIndicated'
                          className='icon icon-info-who-indicated'
                          image={help}
                          alt='Nome Favorito'
                        />
                        <UncontrolledTooltip placement='top' target='tooltipWhoIndicated' className='tooltip-white'>
                          Caso o paciente não se lembre do nome de quem indicou, deixe o campo em branco.
                        </UncontrolledTooltip>
                        <SimpleSuggestionBoxComponent
                          name='whoIndicated'
                          className='input-secondary'
                          clearSuggestions={this.clearWhoIndicatior}
                          selectSuggestion={(value) => {
                            handleChange({
                              target: {
                                name: 'whoIndicated',
                                value
                              }
                            });

                            return value.name;
                          }}
                          suggestions={indicatorSuggestions}
                          findSuggestions={this.findWhoIndicators}
                          value={values.whoIndicated || {}}
                          defaultValue={{
                            id: t(values, 'whoIndicated.id').safeObject,
                            name: t(values, 'whoIndicated.name').safeObject
                          }}
                        />
                      </Col>
                    ) : null}

                    {4 === parseInt(indicationTypeId) ? (
                      <Col sm='6'>
                        <label>Quem Indicou</label>
                        <Input
                          className='input-secondary'
                          name='indicationName'
                          value={values.indicationName}
                          onChange={handleChange}
                        />
                      </Col>
                    ) : null}

                    {6 === parseInt(indicationTypeId) ? (
                      <Col sm='6'>
                        <label>Quem Indicou</label>
                        <select
                          id='soflow'
                          className='form-control'
                          name='indicationName'
                          value={values.indicationName}
                          onChange={handleChange}
                          // onChange={e => {
                          //   this.selectingIndicationType(e.target.value);
                          //   handleChange(e);
                          // }}
                        >
                          <option value={'Facebook'}>Facebook</option>
                          <option value={'Instagram'}>Instagram</option>
                        </select>
                      </Col>
                    ) : null}
                  </Row>
                </div>
                <div className='mt-3 pt-2'>
                  <Row>
                    <Col sm='12'>
                      <span className='title-modal'>Telefones e Contatos</span>
                    </Col>
                  </Row>
                </div>
                <div className='mt-1'>
                  <Row>
                    <Col sm='4'>
                      <label>Telefone 1</label>
                      <MaskedInput
                        id='phone1'
                        name='phone1'
                        mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/]}
                        onChange={handleChange}
                        guide={false}
                        value={values.phone1}
                        render={(ref, props) => <Input className='input-secondary' innerRef={ref} {...props} />}
                      />
                    </Col>
                    <Col sm='7'>
                      <label>Descrição</label>
                      <input
                        className='input-secondary'
                        type='text'
                        name='phone1Name'
                        onChange={handleChange}
                        value={values.phone1Name}
                      />
                    </Col>
                    <Col sm='1'>
                      <label>Tipo</label>
                      <a
                        href={'#'}
                        onClick={() => {
                          handleChange({
                            target: {
                              name: 'phone1Type',
                              value:
                                t(values.phone1Type).isUndefined || t(values.phone1Type).safeString === 'telefone'
                                  ? 'whatsapp'
                                  : 'telefone'
                            }
                          });
                        }}
                      >
                        <IfComponent conditional={!values.phone1Type || values.phone1Type === 'telefone'}>
                          <IoLogoWhatsapp className='icon icon-whats' alt='whats' size={'28'} />
                        </IfComponent>
                        <IfComponent conditional={values.phone1Type === 'whatsapp'}>
                          <IoLogoWhatsapp className='icon icon-whats whats-on' alt='whats' size={'28'} />
                        </IfComponent>
                      </a>
                      {/* <IfComponent
                              conditional={ !values.phone2Type || values.phone2Type === 'telefone' }>
                              <Icon
                                className="icon icon-whats"
                                image={ whats }
                                alt="whats"
                              />
                            </IfComponent>
                            <IfComponent conditional={ values.phone2Type === 'whatsapp' }>
                              <Icon
                              className="icon icon-whats whats-on"
                                image={ whats }
                                alt="whats"
                              />
                            </IfComponent> */}
                    </Col>
                  </Row>
                </div>
                <div className='margin-top-10'>
                  <Row>
                    <Col sm='4'>
                      <label>Telefone 2</label>
                      <MaskedInput
                        id='phone2'
                        name='phone2'
                        mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/]}
                        onChange={handleChange}
                        guide={false}
                        value={values.phone2}
                        render={(ref, props) => <Input className='input-secondary' innerRef={ref} {...props} />}
                      />
                    </Col>
                    <Col sm='7'>
                      <label>Descrição</label>
                      <input
                        className='input-secondary'
                        type='text'
                        name='phone2Name'
                        value={values.phone2Name}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col sm='1'>
                      <a
                        href={'#'}
                        onClick={() => {
                          handleChange({
                            target: {
                              name: 'phone2Type',
                              value:
                                t(values.phone2Type).isUndefined || t(values.phone2Type).safeString === 'telefone'
                                  ? 'whatsapp'
                                  : 'telefone'
                            }
                          });
                        }}
                      >
                        <IfComponent conditional={!values.phone2Type || values.phone2Type === 'telefone'}>
                          <IoLogoWhatsapp className='icon icon-whats' alt='whats' size={'28'} />
                        </IfComponent>
                        <IfComponent conditional={values.phone2Type === 'whatsapp'}>
                          <IoLogoWhatsapp className='icon icon-whats whats-on' alt='whats' size={'28'} />
                        </IfComponent>
                      </a>
                    </Col>
                  </Row>
                </div>
                <div className='margin-top-10'>
                  <Row>
                    <Col sm='12'>
                      <label>E-mail</label>
                      <input
                        className='input-secondary'
                        type='text'
                        name='email'
                        value={values.email}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                </div>
              </ModalBody>
              <ModalFooter>
                <Row>
                  <Col sm='6'>
                    <button
                      className='button-tertiary btn-large'
                      type='submit'
                      onClick={(e) => {
                        handleChange({
                          target: {
                            name: 'saveAndComplete',
                            value: true
                          }
                        });
                      }}
                    >
                      Salvar e Completar Cadastro
                    </button>
                  </Col>
                  <Col sm='6'>
                    <button className='button-tertiary float-right' onClick={close} type='button'>
                      Cancelar
                    </button>
                    <button className='button-primary mr-3 float-right'>Salvar</button>
                  </Col>
                </Row>
              </ModalFooter>
            </form>
          );
        }}
      </Formik>
    );
  }
}

const enhanced = compose();

export default enhanced(PatientAddForm);
