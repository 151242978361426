import React, { Component } from 'react';

import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { RadioButton, RadioGroup } from 'react-radio-buttons';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { appointmentRedialModalReducerToProps } from '../selectors/AppointmentRedialModal.selector';
import {
  appointmentRedialCalendar,
  appointmentRedialModal,
  scheduleAppointmentDetailModal
} from '../redux/Schedule.actions';
import MessagesComponent from '../../../components/Messages.component';
import { hideLoader, showLoader } from '../../../components/actions/Loader.actions';
import { showToast } from '../TostPatient.component';

class AppointmentRedialModal extends Component {
  state = {
    type: 'P',
    error: {
      display: false,
      type: '',
      message: ''
    }
  };

  closeModal = () => {
    this.props.closeModal();
    this.setState({ type: '' });
  };

  saveRedial = () => {
    const { type } = this.state;
    const { schedule, showLoader, hideLoader, closeModalDetail, closeModal, appointmentRedialCalendar } = this.props;

    showLoader();
    if (!type) {
      this.setState({
        error: {
          display: true,
          type: 'danger',
          message: 'Remarcado Por Obrigatório'
        }
      });
      hideLoader();
    } else {
      hideLoader();
      closeModal();
      closeModalDetail();
      showToast({ schedule, onClose: () => appointmentRedialCalendar({}) });
      appointmentRedialCalendar({ schedule, type });
    }
  };

  render() {
    const { modal, schedule = {} } = this.props;
    const { paciente = {} } = schedule;
    const { error, type } = this.state;

    return (
      <Modal isOpen={modal} toggle={this.closeModal} className={this.props.className + ' modal-small'}>
        <ModalHeader
          className='title-primary'
          toggle={this.closeModal}
          close={
            <button className='close' onClick={this.closeModal}>
              &times;
            </button>
          }
        >
          <strong>Remarcar Consulta</strong>
          <span className='font-light title-medium-plus'>{paciente.nome}</span>
        </ModalHeader>
        <ModalBody>
          <div className='margin-top-10'>
            <Row>
              <Col sm='12'>
                <p className={'text-default text-md'}>
                  Tem certeza que deseja <span className='text-uppercase'>remarcar</span> essa consulta?
                </p>
              </Col>
            </Row>
            <MessagesComponent display={error.display} type={error.type} message={error.message} />
            <Row className='margin-top-10'>
              <Col sm={12}>
                <label className='text-center w-100 mb-1'>Remarcado por</label>
                <RadioGroup
                  className='radio-griks d-flex justify-content-center pt-2'
                  name='type'
                  value={type ? type : 'P'}
                  horizontal
                  onChange={(e) => this.setState({ type: e })}
                >
                  <RadioButton pointColor={'var(--primary)'} iconSize={20} value='P'>
                    <span className='px-3'>Paciente</span>
                  </RadioButton>
                  <RadioButton pointColor={'var(--primary)'} iconSize={20} value='C'>
                    <span className='px-3'>Clinica</span>
                  </RadioButton>
                </RadioGroup>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col className='btn-list' sm={{ size: 7, offset: 5 }}>
              <button className='button-primary' onClick={this.saveRedial}>
                Remarcar
              </button>
              <button className='button-tertiary' onClick={this.closeModal}>
                Cancelar
              </button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...appointmentRedialModalReducerToProps(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  closeModalDetail: () => dispatch(scheduleAppointmentDetailModal({ modal: false })),
  closeModal: () => dispatch(appointmentRedialModal({ modal: false })),
  appointmentRedialCalendar: (data) => dispatch(appointmentRedialCalendar(data))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(AppointmentRedialModal);
