import React, { Component } from 'react';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Tooltip, UncontrolledTooltip } from 'reactstrap';

import question from '../../../assets/img/icons/question.svg';
import Icon from '../../../components/Icon.component';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { cashCloseModal } from '../redux/cash';
import { cashCloseModalSelectorToProps } from '../selectors/CashCloseModal.selector';
import CashAPI from '../../../service/CashAPI';
import ToastUtils from '../../../utils/ToastUtils';
import { hideLoader, showLoader } from '../../../components/actions/Loader.actions';

import success from '../../../assets/img/icons/success.svg';
import ToastCustomMessageComponent from '../../../components/ToastCustomMessage.component';
import moment from 'moment';
import { formatDate } from '../../../utils/DateUtils';

class CloseCashModal extends Component {
  cashClose = () => {
    const { showLoader, hideLoader, updateParent, closeModal } = this.props;
    showLoader();
    CashAPI.closeCash(formatDate(moment(), 'YYYY-MM-DDTHH:mm'))
      .then((d) => {
        updateParent();
        hideLoader();
        closeModal();
        ToastUtils.success(<ToastCustomMessageComponent iconImage={success} title={'Caixa Fechado com Sucesso !'} />, {
          autoClose: 5000,
          hideProgressBar: true,
          position: 'top-center'
        });
      })
      .catch((err) => {
        console.error(err);
        hideLoader();
        ToastUtils.error('Erro ao fechar caixa entre em contato com o suporte');
      });
  };

  render() {
    const { modal, closeModal, usuario } = this.props;
    return (
      <Modal isOpen={modal} toggle={closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={closeModal}
          close={
            <button className='close' onClick={closeModal}>
              &times;
            </button>
          }
        >
          <strong>
            Fechar o Caixa de <span className='font-light'>{usuario}</span>
          </strong>
        </ModalHeader>
        <ModalBody>
          <div className='margin-top-10'>
            <Row>
              <Col sm='12'>
                <p className='text-md text-secondary'>
                  Confirma que deseja Fechar o Caixa?
                  <Icon className='icon icon-size-small ml-2' image={question} alt='question' id='question' />
                  <UncontrolledTooltip placement='bottom' className={'tooltip-white'} target='question'>
                    Ao Fechar o Caixa, todos os lançamentos realizados nas Fichas Financeiras, serão congelados, não
                    podendo mais ser alterados.
                  </UncontrolledTooltip>
                </p>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter className='margin-top-10'>
          <Row>
            <Col className={'btn-list'} sm={{ size: 8, offset: 4 }}>
              <button className='button-primary' onClick={this.cashClose}>
                Fechar o Caixa
              </button>
              <button className='button-tertiary' onClick={closeModal}>
                Cancelar
              </button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...cashCloseModalSelectorToProps(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  closeModal: () => dispatch(cashCloseModal({ modal: false }))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(CloseCashModal);
