import React, { Component } from 'react';

import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { appointmentDeleteToProps } from '../selectors/AppointmentDeleteModal.selector';
import { appointmentDelete, scheduleAppointmentDetailModal } from '../redux/Schedule.actions';
import UserAPI from '../../../service/User.service';
import ScheduleAPI from '../../../service/ScheduleAPI';
import moment from 'moment';
import MessagesComponent from '../../../components/Messages.component';
import { showLoader, hideLoader } from '../../../components/actions/Loader.actions';

class AppointmentDeleteModal extends Component {
  state = {
    pwd: '',
    message: {
      display: false,
      type: '',
      text: ''
    }
  };

  deleteAppointment = async () => {
    const { schedule = {}, showLoader, hideLoader } = this.props;
    showLoader();
    const ok = await UserAPI.checkPassword(this.state.pwd);
    if (ok) {
      try {
        await ScheduleAPI.appointmentDelete({ id: schedule.id });
        this.closeModal();
        this.props.closeDetailModal();
        this.props.updateParent(moment(schedule.data_agendamento));
      } catch (e) {
        console.log(e);
        this.setState({
          message: {
            display: true,
            type: 'danger',
            text: 'Erro ao deletar agendamento por favor entre em contato com o suporte'
          }
        });
      }
    } else {
      this.setState({
        message: {
          display: true,
          type: 'danger',
          text: 'Login ou senha inválidos'
        }
      });
    }
    hideLoader();
  };

  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    const { modal, schedule = {} } = this.props;
    const { paciente = {} } = schedule;
    const { message } = this.state;

    return (
      <Modal isOpen={modal} toggle={this.closeModal} className={this.props.className + ' modal-small'}>
        <ModalHeader
          className='title-primary'
          toggle={this.closeModal}
          close={
            <button className='close' onClick={this.closeModal}>
              &times;
            </button>
          }
        >
          <strong>Excluir Consulta</strong>
          <span className='font-light'>{paciente.nome}</span>
        </ModalHeader>
        <ModalBody>
          <div className='margin-top-10'>
            <Row>
              <Col sm='12'>
                <p className='text-default text-md'>
                  Tem certeza que deseja <strong className='text-uppercase'>excluir</strong> essa consulta?
                </p>
              </Col>
            </Row>
          </div>
          <div className='margin-top-10' sm='20'>
            <Row>
              <Col sm='12'>
                <p className='text-default text-md'>Todas as informações serão definitivamente perdidas</p>
              </Col>
            </Row>
          </div>
          <MessagesComponent display={message.display} type={message.type} message={message.text} />
          <div className='margin-top-10'>
            <Row>
              <Col sm={{ size: 6, offset: 3 }}>
                <label className='text-default'>Senha</label>
                <input
                  className='input-secondary'
                  autocomplete='new-password'
                  type='password'
                  name='senha' // placeholder="Senha"
                  onChange={({ target: { value } }) => this.setState({ pwd: value })}
                />
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col sm={{ size: 3, offset: 6 }}>
              <button className='button-cancel' onClick={this.deleteAppointment}>
                Excluir
              </button>
            </Col>
            <Col sm='3'>
              <button className='button-tertiary' onClick={this.closeModal}>
                Cancelar
              </button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...appointmentDeleteToProps(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  closeModal: () => dispatch(appointmentDelete({ modal: false })),
  closeDetailModal: () => dispatch(scheduleAppointmentDetailModal({ modal: false }))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(AppointmentDeleteModal);
