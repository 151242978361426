import { call, put } from 'redux-saga/effects';
import { scheduleOrthodonticsFrequencyPage } from '../redux/Schedule.actions';
import { hideLoader, showLoader } from '../../../components/actions/Loader.actions';
import ToastUtils from '../../../utils/ToastUtils';
import FrequencyOrthodonticsAPI from '../../../service/FrequencyOrthodonticsAPI';

export function* scheduleFrequencySagaProcess({ payload: { dentist } }) {
  const { value } = dentist;

  yield put(showLoader());
  try {
    const frequency = yield call(FrequencyOrthodonticsAPI.findFrequency, { dentist: value });

    yield put(scheduleOrthodonticsFrequencyPage({ dentist, frequency: frequency.data }));
  } catch (e) {
    console.log(e);
    ToastUtils.error('Erro ao buscar dados de Frequência em Ortodontia');
  }

  yield put(hideLoader());
}
