import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { FaArrowDown } from 'react-icons/fa/index';
import userDefaultIcon from '../../assets/img/noavatar.png';
import { AiOutlineFolderOpen } from 'react-icons/ai/index';
import Timer from './timer';
import t from 'typy';
import ReactTooltip from 'react-tooltip';
import StringUtils from '../../utils/StringUtils';
import moment from 'moment';
import TimerInMinutes from './timerInMinutes';
import { Link } from 'react-router-dom';
import { hideLoader, showLoader } from '../../components/actions/Loader.actions';
import FlowApi from '../../service/FlowApi';
import ToastUtils from '../../utils/ToastUtils';
import { useDispatch } from 'react-redux';
import { IfComponent } from '../../components/if.component';

const CardFlowInAttendance = ({ fluxo, callback, selectedDate }) => {
  const dispatch = useDispatch();
  const [timerColorConditional, setTimerColorConditional] = useState('#000000');

  function getDiffeenceOfProcedure() {
    const flowStart = moment(fluxo.entrada);
    const timeToSchedule = moment(fluxo.data_agendamento + 'T' + fluxo.horario_de);
    const difference = flowStart.diff(timeToSchedule, 'minutes', true);

    if (difference > 0) {
      return (
        <span className={'text-danger'} style={{ color: '#dc33545' }}>
          +{difference.toFixed(0)}'
        </span>
      );
    } else {
      return <span>{difference.toFixed(0)}'</span>;
    }
  }

  async function nextFlow() {
    try {
      dispatch(showLoader());
      await FlowApi.nextFlow(fluxo.fluxoAtendimento);
      dispatch(hideLoader());
      callback();
    } catch (err) {
      console.error(err);
      dispatch(hideLoader());
      ToastUtils.error('Erro no fluxo');
    }
  }

  // function timerColor(timerMoment) {
  //
  //   let timeAwait = parseInt(moment(timerMoment).format('mm'));
  //   const timeHour = moment(timerMoment).format('HH');
  //
  //   timeAwait += (parseInt(timeHour) * 60);
  //
  //   if (timeAwait <= 0) {
  //     return '#000000';
  //   } else if (timeAwait > 0 && timeAwait < 10) {
  //     return '#dc3545';
  //   } else if (timeAwait >= 10) {
  //     return '#b83cef';
  //   }
  // }

  function timerColorInternal(timer) {
    if (timer <= 0) {
      const color = '#000000';
      setTimerColorConditional(color);
      return color;
    } else if (timer > 0 && timer < 10) {
      const color = '#dc3545';
      setTimerColorConditional(color);
      return color;
    } else if (timer >= 10) {
      const color = '#b83cef';
      setTimerColorConditional(color);
      return color;
    }
  }

  return (
    <div className='row'>
      <div className='col-sm-12'>
        <div className='mb-1'>
          <div className='card-body pt-2 pb-0 pl-0 pr-0' style={{ maxHeight: '77px' }}>
            <div className='row'>
              <div className='col-sm-12'>
                <div className='row'>
                  <div className='col-sm-2'>
                    <img
                      className='img-circle img-ldpi img-user-avatar'
                      style={{ minWidth: '55px', minHeight: '55px' }}
                      src={t(fluxo, 'pacienteFoto').isDefined ? t(fluxo, 'pacienteFoto').safeString : userDefaultIcon}
                      alt='avatar'
                    />
                  </div>
                  <div className='col-sm-8 p-1 px-1 py-2'>
                    <div className='row p-0'>
                      <div className='px-3'>
                        <p className='text-sm font-bold text-secondary'>{t(fluxo, 'pacienteNome').safeString}</p>
                      </div>
                    </div>
                    <div className='row px-3 py-1'>
                      <ReactTooltip id='tooltipProcedimento' className='tooltip-griks' type='light'>
                        {t(fluxo, 'procedimentoNome').safeString}
                      </ReactTooltip>
                      <span
                        data-tip
                        data-for={'tooltipProcedimento'}
                        className='procedure badge-proc align-self-center px-2'
                        style={{
                          backgroundColor: t(fluxo, 'procedimentoCategoriaRGB').safeString,
                          fontSize: '10px'
                        }}
                      >
                        {t(fluxo, 'procedimentoCodiNome').safeString}
                      </span>

                      <ReactTooltip id='tooltipDentista' className='tooltip-griks' type='light'>
                        {fluxo.dentistaNome}
                      </ReactTooltip>
                      <a
                        data-tip
                        data-for={'tooltipDentista'}
                        href={'#'}
                        className='receipt-03'
                        style={{
                          fontSize: '10px',
                          backgroundColor: '#ebecf2',
                          border: '1px solid #ebecf2',
                          textDecoration: 'none',
                          cursor: 'default'
                        }}
                      >
                        {StringUtils.abbreviateString(fluxo.dentistaNome)}
                      </a>
                      <ReactTooltip id='tooltipCadeira' className='tooltip-griks' type='light'>
                        {fluxo.cadeira}
                      </ReactTooltip>
                      <a
                        data-tip
                        data-for={'tooltipCadeira'}
                        href={'#'}
                        className='receipt-03'
                        style={{
                          fontSize: '10px',
                          backgroundColor: '#ebecf2',
                          border: '1px solid #ebecf2',
                          textDecoration: 'none',
                          cursor: 'default'
                        }}
                      >
                        {StringUtils.abbreviateString(fluxo.cadeira)}
                      </a>
                      <ReactTooltip id='tooltipTimerProcedure' className='tooltip-griks' type='light'>
                        Tempo agendado remanescente
                      </ReactTooltip>
                      <span
                        data-tip
                        data-for={'tooltipTimerProcedure'}
                        className='text-secondary text-xsm text-danger font-bold'
                      >
                        <TimerInMinutes
                          entrada={fluxo.entrada}
                          tempo={fluxo.procedimentoTempo}
                          timerColor={timerColorInternal}
                        />
                      </span>
                      <ReactTooltip id='tooltipFinancialFile' className='tooltip-griks' type='light'>
                        Ficha Clínica
                      </ReactTooltip>
                      <IfComponent conditional={t(fluxo, 'tratamentoId').isDefined}>
                        <Link to={`/patient/${fluxo.pacienteId}/treatment/${t(fluxo, 'tratamentoId').safeNumber}`}>
                          <a
                            data-tip
                            data-for={'tooltipFinancialFile'}
                            className='d-flex align-self-center ml-1'
                            href='#'
                          >
                            <AiOutlineFolderOpen size='18px' />
                          </a>
                        </Link>
                      </IfComponent>
                      <IfComponent conditional={t(fluxo, 'tratamentoId').isNullOrUndefined}>
                        <Link to={`/patient/${fluxo.pacienteId}/treatment`}>
                          <a
                            data-tip
                            data-for={'tooltipFinancialFile'}
                            className='d-flex align-self-center ml-1'
                            href='#'
                          >
                            <AiOutlineFolderOpen size='18px' />
                          </a>
                        </Link>
                      </IfComponent>
                    </div>
                  </div>
                  <div className='col-sm-2 p-0 d-flex flex-column justify-content-center align-items-center card-divider'>
                    <div className='row'>
                      <Timer entrada={fluxo.entrada} color={timerColorConditional} />
                    </div>
                    <div className='row'>
                      <IfComponent conditional={moment().isSame(selectedDate, 'day')}>
                        <a className='mr-2' href={'#'} onClick={nextFlow}>
                          <FaArrowDown className='cursor-pointer icon-effect-d' size='20px' />
                        </a>
                      </IfComponent>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardFlowInAttendance;
