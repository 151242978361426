import RestTemplate from '../utils/restTemplate/rest.template';

export default class CashAPI {
  static findEntriesByFinancialFile({ financialFile }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/cash/entry/financial-record/${financialFile}`);
  }

  static anticipateDebit({ financialFile, debit }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/cash/entry/financial-file/${financialFile}/debit/${debit}/antecipate/`);
  }

  static checkHasMaintenance({ entryId }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/cash/entry/${entryId}/hasMaintenance`);
  }

  static deleteEntry({ entryId }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.delete(`/cash/entry/${entryId}`);
  }

  static launchPayment({
    id,
    tipo_lancamento,
    numero_lancamento,
    data_lancamento,
    descricao,
    anotacao,
    forma_pagamento,
    referencia,
    valor_lancamento,
    ficha_financeira,
    voucher,
    paymentEntries
  }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post('/cash/entry', {
      id,
      tipo_lancamento,
      numero_lancamento,
      data_lancamento,
      descricao,
      anotacao,
      forma_pagamento,
      referencia,
      valor_lancamento,
      ficha_financeira,
      voucher,
      entries_ref: paymentEntries && paymentEntries.map((e) => e.id)
    });
  }

  static getCashOpenByUser() {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/cash/');
  }

  static closeCash(data_fechamento) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.put('/cash/close', { data_fechamento });
  }

  static openCash({ saldo_inicial, data_abertura }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post('/cash/open', {
      saldo_inicial,
      data_abertura
    });
  }

  static getCashById({ id }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/cash/${id}`);
  }

  static issueVoucher(cashId) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.put('/cash/issue/voucher', {
      cash_entry: cashId
    });
  }

  static getLaunchForVoucher(id) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/cash/launch/voucher/${id}`);
  }

  static findReportVouchers(dentists, start, end) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/cash/launch/voucher/report', {
      start,
      end,
      dentists: dentists ? dentists.join(',') : null
    });
  }

  static findReportBillings(dentists, start, end) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/cash/report/billings', {
      start,
      end,
      dentists: dentists ? dentists.join(',') : null
    });
  }
}
