import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';

const Icon = (props) => {
  const { image, style } = props;
  return <ReactSVG src={image} svgStyle={style} {...props} />;
};

Icon.propTypes = {
  image: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object
};

export default Icon;
