import React, { Component } from 'react';
import TreatmentFinancialFileCountInformationPage from './TreatmentFinancialFileAcountInformation.page';

import { Container } from 'reactstrap';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { showLoader, hideLoader } from '../../../components/actions/Loader.actions';
import TreatmentFileClosedPaymentPage from './TreatmentFinancialFileClosedPayment.page';
import TreatmentFinancialFileMonthlyPaymentPage from './TreatmentFinancialFileMonthlyPayment.page';
import TreatmentFinancialFileDebitsPage from './TreatmentFinancialFileDebits.page';
import TreatmentFinancialFileEntriesPage from './TreatmentFinancialFileEntries.page';

class TreatmentFinancialFilePage extends Component {
  render() {
    const { updateParent } = this.props;

    return (
      <Container>
        <TreatmentFinancialFileCountInformationPage updateParent={updateParent} />

        <TreatmentFileClosedPaymentPage updateParent={updateParent} />

        <TreatmentFinancialFileMonthlyPaymentPage updateParent={updateParent} />

        <TreatmentFinancialFileDebitsPage updateParent={updateParent} />

        <TreatmentFinancialFileEntriesPage updateParent={updateParent} />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader())
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(TreatmentFinancialFilePage);
