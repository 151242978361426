export const TREATMENT_REGISTER_MODAL_SAGA = 'TREATMENT_REGISTER_MODAL_SAGA';
export const TREATMENT_REGISTER_MODAL = 'TREATMENT_REGISTER_MODAL';
export const TREATMENT_CHANGE_SITUATION_MODAL = 'TREATMENT_CHANGE_SITUATION_MODAL';

export function treatmentRegisterModalSaga({ modal, formLoad }) {
  return {
    type: TREATMENT_REGISTER_MODAL_SAGA,
    payload: {
      modal,
      formLoad
    }
  };
}

export function treatmentRegisterModal({ modal, formData, formLoad }) {
  return {
    type: TREATMENT_REGISTER_MODAL,
    payload: {
      modal,
      formData,
      formLoad
    }
  };
}

export function treatmentChangeSituationModal({ modal, treatment }) {
  return {
    type: TREATMENT_CHANGE_SITUATION_MODAL,
    payload: {
      modal,
      treatment
    }
  };
}
