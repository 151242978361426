import React, { Component } from 'react';
import { Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, InputGroup, InputGroupAddon } from 'reactstrap';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { cashOpenModalSelectorToProps } from '../selectors/CashOpenModal.selector';
import { hideLoader, showLoader } from '../../../components/actions/Loader.actions';
import { cashOpenModal } from '../redux/cash';
import { Form, Formik } from 'formik';
import UserAPI from '../../../service/User.service';
import MessagesComponent from '../../../components/Messages.component';
import CashAPI from '../../../service/CashAPI';
import ToastUtils from '../../../utils/ToastUtils';
import InputDecimalComponent from '../../../components/InputDecimalComponent';
import moment from 'moment';
import { formatDate } from '../../../utils/DateUtils';

class OpenCashModal extends Component {
  initialState = {
    message: {
      display: false,
      type: '',
      text: ''
    },
    pwdInvalid: false
  };

  state = {
    ...this.initialState
  };

  openCash = (values) => {
    const { showLoader, hideLoader, updateParent, closeModal } = this.props;

    showLoader();
    UserAPI.checkPassword(values.senha)
      .then((valid) => {
        if (valid) {
          return CashAPI.openCash({
            saldo_inicial: values.saldo_inicial,
            data_abertura: formatDate(moment(), 'YYYY-MM-DDTHH:mm')
          });
        } else {
          this.setState({
            pwdInvalid: true
          });
          throw Error('Senha inválida');
        }
      })
      .then((d) => {
        hideLoader();
        updateParent();
        closeModal();
      })
      .catch((err) => {
        hideLoader();
        if (err.message === 'Senha inválida') {
          ToastUtils.error('Senha inválida');
        } else {
          ToastUtils.error('Erro ao abrir caixa entre em contato com o suporte');
        }
      });
  };

  render() {
    const { modal, closeModal, usuario } = this.props;
    const { message } = this.state;
    return (
      <Modal isOpen={modal} toggle={closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={closeModal}
          close={
            <button className='close' onClick={closeModal}>
              &times;
            </button>
          }
        >
          <strong>Abrir o Caixa de</strong>
          <span className={'text-uppercase'}>{usuario}</span>
        </ModalHeader>
        <Formik onSubmit={this.openCash} validateOnChange={false}>
          {({ errors, values = {}, handleChange }) => {
            return (
              <Form>
                <ModalBody>
                  <div className='margin-top-10'>
                    <Row>
                      <Col sm='12'>
                        <label>Saldo Inicial (Troco)</label>
                        <InputGroup className={'reversed'} invalid={errors.valorTotal ? true : false}>
                          <InputDecimalComponent
                            name={'saldo_inicial'}
                            data-number-to-fixed='2'
                            data-number-stepfactor='100'
                            handleChange={handleChange}
                            invalid={errors.saldo_inicial ? true : false}
                            value={values.saldo_inicial}
                          />
                          <InputGroupAddon addonType='prepend'>R$</InputGroupAddon>
                        </InputGroup>
                      </Col>
                    </Row>
                    <MessagesComponent display={message.display} type={message.type} message={message.text} />
                    <Row className='margin-top-10'>
                      <Col className='input-float' sm={{ size: 8, offset: 2 }}>
                        <Input
                          className='input-secondary'
                          type='password'
                          name='senha'
                          placeholder='Senha'
                          onChange={handleChange}
                          invalid={this.state.pwdInvalid}
                          autofill={false}
                          autoComplete={'new-password'}
                        ></Input>
                        <label htmlFor='senha'>Senha</label>
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter className='margin-top-10'>
                  <Row>
                    <Col className={'btn-list'} sm={{ size: 7, offset: 5 }}>
                      <button className='button-primary'>Abrir o Caixa</button>
                      <button className='button-tertiary' onClick={closeModal}>
                        Cancelar
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...cashOpenModalSelectorToProps(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  closeModal: () => dispatch(cashOpenModal({ modal: false }))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(OpenCashModal);
