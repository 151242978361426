import { createSelector } from 'reselect';
import t from 'typy';
import moment from 'moment';

const attendenceFormLoadSelector = (state) =>
  t(state, 'TreatmentClinicalRecordReducer.attendenceModal.formLoad').safeObject;
const treatmentSelector = (state) => t(state, 'TreatmentClinicalRecordReducer.attendenceModal.treatment').safeObject;
const recordSelector = (state) => t(state, 'TreatmentClinicalRecordReducer.attendenceModal.record').safeObject;

export const formLoadSelector = createSelector(
  attendenceFormLoadSelector,
  treatmentSelector,
  recordSelector,
  (formLoad = {}, treatment = {}, record = {}) => {
    const { arcos = [], queixaPrincipal = [], dentistas = [], lastAttendence = {}, attendence } = formLoad;

    const dentistsOption = [{ value: 0, label: null, data: {} }];
    dentistsOption.push(...dentistas.map((t) => ({ value: t.id, label: t.nome, data: t })));

    let formInit = {};

    if (t(attendence, 'id').isUndefined) {
      formInit = formInitToInclusion(lastAttendence);
      formInit.dentist = dentistsOption.find((d) => d.value === treatment.dentista_id);
    } else {
      formInit = formInitToEdit(attendence);
    }

    return {
      treatment: treatment.id,
      record,
      archOption: [{}, ...arcos.map((t) => ({ value: t.id, label: t.descricao, data: t }))],
      dentistsOption: dentistsOption,
      formInit: formInit
    };
  }
);

function formInitToInclusion(lastAttendence) {
  const lastArcoSuperior = t(lastAttendence, 'planejado.arco_superior').safeObject;
  const lastArcoInferior = t(lastAttendence, 'planejado.arco_inferior').safeObject;

  const formInit = {
    attendence_date: moment(),
    arco_superior: lastArcoSuperior
      ? {
          value: lastArcoSuperior.id,
          label: lastArcoSuperior.descricao,
          data: lastArcoSuperior
        }
      : {},
    arco_inferior: lastArcoInferior
      ? {
          value: lastArcoInferior.id,
          label: lastArcoInferior.descricao,
          data: lastArcoInferior
        }
      : {},
    planejado_arco_superior: lastArcoSuperior
      ? {
          value: lastArcoSuperior.id,
          label: lastArcoSuperior.descricao,
          data: lastArcoSuperior
        }
      : {},
    planejado_arco_inferior: lastArcoInferior
      ? {
          value: lastArcoInferior.id,
          label: lastArcoInferior.descricao,
          data: lastArcoInferior
        }
      : {}
  };
  return formInit;
}

function formInitToEdit(attendence) {
  const formInit = {
    edit: true,
    id: attendence.id,
    attendence_date: moment(attendence.data_atendimento),
    emergencia: attendence.consulta_emergencia,
    arco_superior: attendence.arco_superior
      ? {
          value: attendence.arco_superior.id,
          label: attendence.arco_superior.descricao,
          data: attendence.arco_superior
        }
      : {},
    arco_inferior: attendence.arco_inferior
      ? {
          value: attendence.arco_inferior.id,
          label: attendence.arco_inferior.descricao,
          data: attendence.arco_inferior
        }
      : {},
    anotacao: attendence.anotacao,
    dentist: attendence.dentista
      ? {
          value: attendence.dentista.id,
          label: attendence.dentista.nome,
          data: attendence.dentista
        }
      : {},
    assistence: attendence.auxiliar
      ? {
          value: attendence.auxiliar.id,
          label: attendence.auxiliar.nome,
          data: attendence.auxiliar
        }
      : {},
    odontograma: {
      q: t(attendence, 'odontograma').isArray
        ? attendence.odontograma.filter((o) => o.tipo === 'Q').map((o) => o.numero)
        : [],
      r: t(attendence, 'odontograma').isArray
        ? attendence.odontograma.filter((o) => o.tipo === 'R').map((o) => o.numero)
        : []
    },
    planejado_id: t(attendence, 'planejado.id').safeObject,
    planejado_arco_superior: t(attendence, 'planejado.arco_superior.id').isDefined
      ? {
          value: attendence.planejado.arco_superior.id,
          label: attendence.planejado.arco_superior.descricao,
          data: attendence.planejado.arco_superior
        }
      : {},
    planejado_arco_inferior: t(attendence, 'planejado.arco_inferior.id').isDefined
      ? {
          value: attendence.planejado.arco_inferior.id,
          label: attendence.planejado.arco_inferior.descricao,
          data: attendence.planejado.arco_inferior
        }
      : {},
    planejado_proximo_atendimento: t(attendence, 'planejado.proximo_atendimento').safeString
  };

  return formInit;
}
