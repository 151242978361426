import React, { useEffect, useRef, useState } from 'react';
import { hideLoader, showLoader } from '../../components/actions/Loader.actions';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { setHeader } from '../template/redux/Content.actions';
import Icon from '../../components/Icon.component';
import arrowLeftIcon from '../../assets/img/icons/arrowleft.svg';
import arrowRightIcon from '../../assets/img/icons/arrowright.svg';
import MultiSelect from '@khanacademy/react-multi-select';
import FlowApi from '../../service/FlowApi';
import moment from 'moment';
import { IfComponent } from '../../components/if.component';
import t from 'typy';
import CardFlowSchedules from './CardFlowSchedules';
import CardFlowAwait from './CardFlowAwait';
import CardFlowInAttendance from './CardFlowInAttendance';
import CardFlowAttended from './CardFlowAttended';
import { loadDentists } from '../../shared/dentists_store';
import { arrayToSelectItems } from '../../utils/ReactSelectUtils';
import UserAPI from '../../service/User.service';
import ToastUtils from '../../utils/ToastUtils';
import flowIcon from '../../assets/img/icons/flow1.svg';
import { MdErrorOutline } from 'react-icons/md/index';
import FlowTimelineModal from './modals/FlowTimeline.modal';

const FlowHomePage = (props) => {
  const dispatch = useDispatch();
  const { dentists } = useSelector(({ DentistsSharedReducer }) => DentistsSharedReducer.dentists) || {};
  const [dataSelecionada, setDataSelecionada] = useState(moment());
  const [flow, setFlow] = useState({});
  const [doctor, setDoctor] = useState([]);
  const [chair, setChair] = useState([]);
  const [chairOptions, setChairOptions] = useState([]);
  const dentistsOptions = dentists && arrayToSelectItems(dentists, 'nome', 'id');

  // onLoad
  useEffect(() => {
    dispatch(
      setHeader({
        title: 'Fluxo',
        image: flowIcon
      })
    );

    dispatch(loadDentists());
    findFlow();

    let pooling = setInterval(() => {
      findFlow(false);
    }, 30000);
    return () => {
      clearInterval(pooling);
    };
  }, []);

  useEffect(() => {
    findFlow();
  }, [dataSelecionada]);

  useEffect(() => {
    console.log(chairOptions);
  }, [chairOptions]);

  useEffect(() => {
    if (doctor.length == 1) {
      dispatch(showLoader());
      UserAPI.getChairsEnabled(doctor[0])
        .then(({ data }) => {
          dispatch(hideLoader());
          setChairOptions(
            data.map((c) => {
              return {
                label: c.nome_cadeira,
                value: c.id
              };
            })
          );
        })
        .catch((err) => {
          ToastUtils.error('Erro ao buscar cadeiras do dentista');
          dispatch(hideLoader());
        });
    } else {
      setChairOptions([]);
    }
  }, [doctor]);

  async function findFlow(showLoaderFind = true) {
    if (showLoaderFind) {
      dispatch(showLoader());
    }

    try {
      await FlowApi.findFlow(dataSelecionada.format('YYYY-MM-DD'), doctor, chair).then(setFlow);
      if (showLoaderFind) {
        dispatch(hideLoader());
      }
    } catch (e) {
      if (showLoaderFind) {
        dispatch(hideLoader());
      }
      console.error(e);
    }
  }

  return (
    <div className='content-box-without-color'>
      <div className='container'>
        <div className='w-100 row'>
          <div className='col-sm-12'>
            <div className='box-default'>
              <div className='container'>
                <div className='row'>
                  <div className='col-sm-12 d-flex align-items-center'>
                    <strong className='title-medium-plus text-uppercase'>Fluxo de Atendimentos</strong>
                    <div
                      className='ml-2 align-items-center d-flex btn btn-outline-restartfilter rounded-lg text-light text-sm font-bold'
                      style={{
                        height: '26px',
                        backgroundColor: '#e75d5c',
                        letterSpacing: '0.1em',
                        cursor: 'default'
                      }}
                    >
                      <strong className='text-light'>BETA</strong>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-12 d-flex align-items-center'>
                    <strong className='title-large'>{dataSelecionada.format('dddd - DD MMM YYYY')}</strong>
                    <a
                      className='ml-3'
                      href={'#'}
                      onClick={() => {
                        setDataSelecionada(moment(dataSelecionada.subtract(1, 'day')));
                      }}
                    >
                      <Icon className='icon icon-size-small' image={arrowLeftIcon} alt='arrow left' />
                    </a>
                    <a
                      className='ml-2'
                      href={'#'}
                      onClick={() => {
                        setDataSelecionada(moment(dataSelecionada.add(1, 'day')));
                      }}
                    >
                      <Icon className='icon icon-size-small' image={arrowRightIcon} alt='arrow right' />
                    </a>
                    <a href={'#'} onClick={() => setDataSelecionada(moment())}>
                      <button className='button-default text-secondary-l1'>Hoje</button>
                    </a>
                    <div className='col-sm-3'>
                      <MultiSelect
                        options={t(dentistsOptions).safeArray}
                        selected={doctor}
                        onSelectedChanged={(selected) => setDoctor(selected)}
                        disableSearch={true}
                        overrideStrings={{
                          selectSomeItems: 'Doutor(a)',
                          allItemsAreSelected: 'Todos os dentistas',
                          selectAll: 'Selecionar Todos',
                          search: 'Buscar'
                        }}
                      />
                    </div>

                    <IfComponent conditional={t(doctor).safeArray.length == 1}>
                      <div className='col-sm-3 ml-2'>
                        <MultiSelect
                          options={t(chairOptions).safeArray}
                          selected={chair}
                          onSelectedChanged={(selected) => setChair(selected)}
                          disableSearch={true}
                          overrideStrings={{
                            selectSomeItems: 'Cadeira',
                            allItemsAreSelected: 'Todos as cadeiras',
                            selectAll: 'Selecionar Todos',
                            search: 'Buscar'
                          }}
                        />
                      </div>
                    </IfComponent>

                    <a className='ml-2' href={'#'}>
                      <button
                        type={'button'}
                        onClick={findFlow}
                        className='button-default w-100 text-secondary-l1'
                        style={{ height: '34px' }}
                      >
                        Filtrar
                      </button>
                    </a>

                    <IfComponent conditional={t(doctor).safeArray.length > 0}>
                      <a className='ml-4' href={'#'}>
                        <button
                          type={'button'}
                          onClick={() => {
                            setChair([]);
                            setDoctor([]);
                          }}
                          className='btn btn-outline-restartfilter text-sm font-light'
                          style={{ height: '34px' }}
                        >
                          Limpar Filtro
                        </button>
                      </a>
                    </IfComponent>
                  </div>
                </div>
                <div className='d-flex col-md-12 justify-content-center mt-4'>
                  <div
                    className='col-sm-4 p-2 bg-background-default flex-fill'
                    style={{ borderTop: '3px solid #AEB6C5' }}
                  >
                    <div className='row align-items-center justify-content-start mb-2'>
                      <strong className='ml-1 title-medium text-uppercase'>Agendados</strong>
                      <span className='ml-2 btn active btn-circle btn-secondary-l2' style={{ cursor: 'default' }}>
                        <p>{t(flow, 'agendados').safeArray.length}</p>
                      </span>
                    </div>
                    {t(flow, 'agendados')
                      .safeArray.sort()
                      .map((f) => {
                        return (
                          <CardFlowSchedules
                            key={f.fluxoAtendimento}
                            fluxo={f}
                            callback={findFlow}
                            selectedDate={dataSelecionada}
                          />
                        );
                      })}
                  </div>
                  <div
                    className='ml-1 p-2 bg-background-default flex-fill col-sm-4'
                    style={{ borderTop: '3px solid #FAE098' }}
                  >
                    <div className='row align-items-center justify-content-start mb-2'>
                      <strong className='ml-1 title-medium text-uppercase'>Aguardando</strong>
                      <span className='ml-2 btn active btn-circle btn-secondary-l2' style={{ cursor: 'default' }}>
                        <p>{t(flow, 'aguardando').safeArray.length}</p>
                      </span>
                    </div>
                    {t(flow, 'aguardando').safeArray.map((f) => {
                      return (
                        <CardFlowAwait
                          key={f.fluxoAtendimento}
                          fluxo={f}
                          callback={findFlow}
                          selectedDate={dataSelecionada}
                        />
                      );
                    })}
                  </div>
                  <div className='flex-column flex-fill col-sm-4'>
                    <div className='p-2 bg-background-default bd-highlight' style={{ borderTop: '3px solid #73DA93' }}>
                      <div className='row align-items-center justify-content-start mb-2'>
                        <strong className='ml-1 title-medium text-uppercase'>Em Atendimento</strong>
                        <span className='ml-2 btn active btn-circle btn-secondary-l2' style={{ cursor: 'default' }}>
                          <p>{t(flow, 'emAtendimento').safeArray.length}</p>
                        </span>
                      </div>
                      {t(flow, 'emAtendimento').safeArray.map((f) => {
                        return (
                          <CardFlowInAttendance
                            key={f.id}
                            fluxo={f}
                            callback={findFlow}
                            selectedDate={dataSelecionada}
                          />
                        );
                      })}
                    </div>
                    <div
                      className='mt-4 p-2 bg-background-default bd-highlight'
                      style={{ borderTop: '3px solid #90BAFB' }}
                    >
                      <div className='row align-items-center justify-content-start mb-2'>
                        <strong className='ml-1 title-medium text-uppercase'>Atendidos</strong>
                        <span className='ml-2 btn active btn-circle btn-secondary-l2' style={{ cursor: 'default' }}>
                          <p>{t(flow, 'atendidos').safeArray.length}</p>
                        </span>
                      </div>
                      {t(flow, 'atendidos')
                        .safeArray.sort((a, b) => {
                          const aStatus = a.historicoStatus.find((s) => s.status === 'ATENDIDO');
                          const bStatus = b.historicoStatus.find((s) => s.status === 'ATENDIDO');

                          if (moment(aStatus.entrada).isBefore(moment(bStatus.entrada))) {
                            return 1;
                          } else if (moment(aStatus.entrada) == moment(bStatus.entrada)) {
                            return 0;
                          } else {
                            return -1;
                          }
                        })
                        .map((f) => {
                          return (
                            <CardFlowAttended
                              key={f.fluxoAtendimento}
                              fluxo={f}
                              callback={findFlow}
                              selectedDate={dataSelecionada}
                            />
                          );
                        })}
                    </div>
                    {/* <FlowTimelineModal/> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlowHomePage;
