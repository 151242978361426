import React, { Component } from 'react';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Form, Formik } from 'formik';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { t } from 'typy';
import { scheduleEventForm, scheduleEventReducerToProps } from '../selectors/ScheduleEventModal.selector';
import { scheduleEventModal } from '../redux/Schedule.actions';
import ScheduleAPI from '../../../service/ScheduleAPI';
import { hideLoader, showLoader } from '../../../components/actions/Loader.actions';
import InputMaskComponent from '../../../components/InputMask.component';
import { maskTime } from '../../../utils/Formatter';
import moment from 'moment';
import MessagesComponent from '../../../components/Messages.component';
import { IfComponent } from '../../../components/if.component';
import warning from '../../../assets/img/icons/warning.svg';
import ModalMessageConfirm from '../../../components/ModalMessageConfirm.component';
import { timeValidate } from '../../../utils/Validations';
import ErrorFieldWithoutBootstrap from '../../../components/ErrorFieldWithoutBootstrap.component';

class ScheduleEventModal extends Component {
  state = {
    message: {
      display: false,
      text: ''
    },
    displayDeleteConfirm: false
  };

  closeModal = () => {
    this.props.closeModal();
    this.setState({
      message: {
        display: false,
        text: ''
      }
    });
  };

  closeModalDeleteConfirm = () => this.setState({ displayDeleteConfirm: false });
  openModalDeleteConfirm = (id) => this.setState({ id, displayDeleteConfirm: true });

  saveEvent = async (values, actions) => {
    const { chair, date, showLoader, hideLoader } = this.props;
    showLoader();
    try {
      let { inicio, fim, bloquear_dia } = values;

      if (bloquear_dia) {
        inicio = moment().hour(0).minute(1).format('HH:mm');
        fim = moment().hour(23).minute(59).format('HH:mm');
      } else {
        if (!inicio || !fim) {
          this.setState({
            message: {
              display: true,
              text: 'Horário Inicio e Horario Final são obrigatórios'
            }
          });
          hideLoader();
          return;
        } else if (!timeValidate(inicio) || !timeValidate(fim)) {
          this.setState({
            message: {
              display: true,
              text: 'Horário Inválido'
            }
          });

          if (!timeValidate(inicio)) {
            actions.setFieldError('inicio', 'Horário Inválido');
          }
          if (!timeValidate(fim)) {
            actions.setFieldError('fim', 'Horário Inválido');
          }

          hideLoader();
          return;
        }else if (fim === '00:00') {
          actions.setFieldError('fim', 'Horário Inválido');
          hideLoader();
          return;
        }
      }
      await ScheduleAPI.saveEvent({
        id: values.id,
        chair: chair.id,
        desc: values.observacao,
        date: date.format('YYYY-MM-DD'),
        start: inicio.replaceAll('_', '0'),
        end: fim.replaceAll('_', '0'),
      });
      this.closeModal();
      this.props.updateParent(date);
    } catch (e) {
      console.log(e);
      this.setState({
        message: {
          display: true,
          text: 'Erro ao salvar evento entre em contato com o suporte'
        }
      });
    }
    hideLoader();
  };

  deleteEvent = () => {
    const { date, showLoader, hideLoader } = this.props;
    showLoader();
    ScheduleAPI.deleteEvent(this.state.id)
      .then(() => {
        this.closeModal();
        this.props.updateParent(date);
        this.closeModalDeleteConfirm();
        hideLoader();
      })
      .catch((e) => {
        console.log(e);
        this.closeModalDeleteConfirm();
        this.setState({
          message: {
            display: true,
            text: 'Erro ao deletar evento entre em contato com o suporte'
          }
        });
        hideLoader();
      });
  };

  render() {
    const { message, displayDeleteConfirm } = this.state;
    const { modal, formInit, chair } = this.props;

    return (
      <Modal isOpen={modal} toggle={this.closeModal} className={this.props.className} size={'default'}>
        <ModalHeader
          className='title-primary'
          toggle={this.closeModal}
          close={
            <button className='close' onClick={this.closeModal}>
              &times;
            </button>
          }
        >
          <strong>Agendar Evento/Compromisso do(a) Doutor(a) </strong>
        </ModalHeader>
        <Formik initialValues={formInit} validateOnChange={false} onSubmit={this.saveEvent}>
          {({ errors, values, handleChange }) => {
            return (
              <Form>
                <ModalBody>
                  <MessagesComponent display={message.display} type={'danger'} message={message.text} />
                  <div className='margin-top-10'>
                    <Row>
                      <Col className='text-left text-default' sm='12'>
                        <p>
                          <strong>Cadeira</strong> {t(chair, 'title').safeString}
                        </p>
                      </Col>
                    </Row>
                  </div>
                  <div className='margin-top-10'>
                    <Row>
                      <Col sm='12'>
                        <label htmlFor='observacao' className='text-default'>
                          Compromisso/Bloqueio
                        </label>
                        <textarea
                          rows='2'
                          cols='50'
                          name={'observacao'}
                          id={'observacao'}
                          value={values.observacao}
                          onChange={handleChange}
                          className='form-control'
                        ></textarea>
                      </Col>
                    </Row>
                  </div>
                  <div className='form-checkbox-content margin-top-10'>
                    <Row>
                      <Col sm='12'>
                        <input
                          type='checkbox'
                          className='form-checkbox'
                          id='bloquear_dia'
                          checked={values.bloquear_dia}
                          onChange={handleChange}
                        />
                        <label
                          className='form-checkout-label text-default'
                          htmlFor='bloquear_dia'
                          style={{ marginLeft: 5 }}
                        >
                          Bloquear o dia inteiro
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className='margin-top-10'>
                    <Row className='pb-0'>
                      <Col sm='6'>
                        <form>
                          <div className='input-inline'>
                            <label className='margin-right-10 text-default' htmlFor='inicio'>
                              Horário Inicio<span> *</span>
                            </label>
                          </div>
                        </form>
                      </Col>
                      <Col sm='6'>
                        <form>
                          <div className='input-inline'>
                            <label className='margin-right-10 text-default' htmlFor='fim'>
                              Horário Fim<span> *</span>
                            </label>
                          </div>
                        </form>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <InputMaskComponent
                          guide={true}
                          value={values.inicio}
                          name={'inicio'}
                          invalid={errors['inicio'] ? true : false}
                          id={'inicio'}
                          mask={maskTime()}
                          onChange={handleChange}
                          disabled={true}
                          readOnly={values.bloquear_dia}
                        />
                        <ErrorFieldWithoutBootstrap fieldName={'inicio'} errors={errors} />
                      </Col>
                      <Col sm={6}>
                        <InputMaskComponent
                          guide={true}
                          value={values.fim}
                          name={'fim'}
                          id={'fim'}
                          invalid={errors['fim'] ? true : false}
                          mask={maskTime()}
                          onChange={handleChange}
                          readOnly={values.bloquear_dia}
                        />
                        <ErrorFieldWithoutBootstrap fieldName={'fim'} errors={errors} />
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Row>
                    <IfComponent conditional={values.id}>
                      <Col sm='3'>
                        <button
                          className='button-cancel'
                          type={'button'}
                          onClick={() => this.openModalDeleteConfirm(values.id)}
                        >
                          Excluir
                        </button>
                      </Col>
                      <Col sm={{ size: 3, offset: 3 }}>
                        <button className='button-primary' type={'submit'}>
                          Salvar
                        </button>
                      </Col>
                    </IfComponent>
                    <IfComponent conditional={!values.id}>
                      <Col sm={{ size: 3, offset: 6 }}>
                        <button className='button-primary' type={'submit'}>
                          Salvar
                        </button>
                      </Col>
                    </IfComponent>

                    <Col sm='3'>
                      <button className='button-tertiary' type={'button'} onClick={this.closeModal}>
                        Cancelar
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
                <ModalMessageConfirm
                  title={<strong>Confirma Exclusão?</strong>}
                  message={
                    <span className='ml-10'>
                      <p className='text-center'>Confirma a Exclusão do Evento ?</p>
                    </span>
                  }
                  openModal={displayDeleteConfirm}
                  icon={warning}
                  close={this.closeModalDeleteConfirm}
                  footer={
                    <Row>
                      <Col sm='3'>
                        <button className='button-tertiary' onClick={this.closeModalDeleteConfirm}>
                          Não
                        </button>
                      </Col>
                      <Col sm={{ size: 3, offset: 6 }}>
                        <button className='button-primary' onClick={this.deleteEvent}>
                          Sim
                        </button>
                      </Col>
                    </Row>
                  }
                />
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...scheduleEventReducerToProps(state),
    formInit: scheduleEventForm(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(scheduleEventModal({ modal: false })),
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader())
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(ScheduleEventModal);
