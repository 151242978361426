import React from 'react';
import PropTypes from 'prop-types';
import InputComponent from './InputComponent';
import styled from 'styled-components';
import _ from 'lodash';

const CustomInput = styled(InputComponent)`
  background: no-repeat scroll 7px 7px;
  background-size: 17px;
  background-color: white !important;
  padding-left: 10px;
`;

function MonthBox(props) {
  const { value, name, label, handler, onClick,placeholder } = props;
  return (
    <CustomInput
      type={'text'}
      name={name}
      handler={handler}
      value={_.capitalize(value)}
      readOnly={true}
      onClick={onClick}
      placeholder={placeholder}
    />
  );
}

MonthBox.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onClick: PropTypes.func,
  handler: PropTypes.func
};

export default MonthBox;
