import { clinicalRecordTreatmentPlan } from '../redux/TreatmentClinicalRecord.action';
import { call, put } from 'redux-saga/effects';
import TreatmentClinicalRecordApi from '../../../../service/TreatmentClinicalRecordApi';
import { showLoader, hideLoader } from '../../../../components/actions/Loader.actions';

export function* clinicalRecordTreatmentPlanSaga({ payload: { show, treatment, record } }) {
  let plan = {};
  let error = null;
  let showPage = show;
  let diagnostic = {};

  try {
    yield put(showLoader());

    const planData = yield call(TreatmentClinicalRecordApi.findTreatmentPlan, {
      treatmentId: treatment,
      recordId: record
    });

    if (planData.status === 204) {
      const planCreateData = yield call(TreatmentClinicalRecordApi.createTreatmentPlan, {
        treatmentId: treatment,
        recordId: record
      });
      plan = planCreateData.data;
    } else {
      plan = planData.data;
    }

    let diagnosticData = yield call(TreatmentClinicalRecordApi.getDiagnostics, {
      treatmentId: treatment,
      recordId: record
    });

    if (diagnosticData.status === 204) {
      diagnosticData = yield call(TreatmentClinicalRecordApi.createDiagnostics, {
        treatmentId: treatment,
        recordId: record
      });
    }

    diagnostic = diagnosticData.data;
  } catch (e) {
    error = 'Erro ao buscar os dados de plano de tratamento';
    console.log(error, e);
    showPage = false;
  }

  yield put(clinicalRecordTreatmentPlan({ show: showPage, treatment, record, plan, error, diagnostic }));
  yield put(hideLoader());
}
