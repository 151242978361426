import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { propsClosedPaymentSelector } from './selectors/FinancialFileClosedPaymentPage.selector';
import { formatReal } from '../../../utils/Formatter';
import { IfComponent } from '../../../components/if.component';
import { t } from 'typy';

class TreatmentFinancialFileClosedPaymentPage extends Component {
  render() {
    const { closed_price = {} } = this.props;

    return (
      <IfComponent conditional={t(closed_price, 'valor_total').isDefined}>
        <Row className='w-100' key={'page'}>
          <Col sm='12'>
            <div className='box-default mt-3'>
              <Row>
                <Col sm='12'>
                  <h2 className='title-medium-plus text-secondary font-bold text-uppercase'>
                    Resumo da conta - Preço Fechado
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col sm='4'>
                  <p className={'text-default text-sm text-secondary-d4'}>
                    <strong className={'text-secondary-d4'}>Valor do Tratamento</strong>
                    {formatReal(
                      closed_price.valor_total - (closed_price.valor_desconto ? closed_price.valor_desconto : 0)
                    )}
                  </p>
                </Col>
                <Col sm='8'>
                  <p className={'text-default text-sm text-secondary-d4'}>
                    <strong className={'text-secondary-d4'}>Dia do Vencimento</strong>
                    {closed_price.dia_vencimento}
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </IfComponent>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...propsClosedPaymentSelector(state)
  };
};

const mapDispatchToProps = (dispatch) => ({});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(TreatmentFinancialFileClosedPaymentPage);
