import React, { Component } from 'react';

import { Container, Row, Col } from 'reactstrap';
import SettingsProcedureSchedulePage from './SettingsProcedureSchedule.page';

import { showLoader, hideLoader } from '../../../components/actions/Loader.actions';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import SettingsAPI from '../../../service/SettingsAPI';
import SettingsProcedurePriceListHomePage from './SettingsProcedurePriceListHome.page';

class SettingsProceduresHomePage extends Component {
  state = {
    tabSelected: 1,
    procedureGroups: []
  };

  componentDidMount = () => {
    this.selectTabProcedure();
  };

  selectTabProcedure = async () => {
    const { showLoader, hideLoader } = this.props;

    showLoader();
    try {
      const groupsResponse = await SettingsAPI.getAllGroupOfProcedures();

      this.setState({
        tabSelected: 1,
        procedureGroups: groupsResponse.data
      });
    } catch (e) {
      console.error(e);
    }
    hideLoader();
  };

  selectTabPricesAndPlans = () => {
    this.setState({
      tabSelected: 2
    });
  };

  render() {
    const { procedureGroups, tabSelected } = this.state;
    const { showLoader, hideLoader } = this.props;

    return (
      <div className='content-box-without-color'>
        <Container>
          <Row>
            <Col sm='12'>&nbsp;</Col>
          </Row>
          <Row style={{ marginTop: -30 }}>
            <Col sm='12'>
              <nav className='nav-inline border-bottom-0 mb-2'>
                <ul className='mb-1'>
                  <li className={tabSelected === 1 ? 'active' : ''}>
                    <a href='#' onClick={this.selectTabProcedure}>
                      Procedimentos de Agenda
                    </a>
                  </li>
                  <li className={tabSelected === 2 ? 'active' : ''}>
                    <a href='#' onClick={this.selectTabPricesAndPlans}>
                      Tabelas de Preços e Planos
                    </a>
                  </li>
                </ul>
              </nav>
              <hr style={{ marginTop: '-15px' }} />
            </Col>
          </Row>
          {tabSelected === 1 && (
            <Row>
              <Col sm={12}>
                <SettingsProcedureSchedulePage
                  groups={procedureGroups}
                  showLoader={showLoader}
                  hideLoader={hideLoader}
                />
              </Col>
            </Row>
          )}

          {tabSelected === 2 && (
            <Row>
              <Col sm={12}>
                <SettingsProcedurePriceListHomePage showLoader={showLoader} hideLoader={hideLoader} />
              </Col>
            </Row>
          )}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader())
  };
};

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(SettingsProceduresHomePage);
