import RestTemplate from '../utils/restTemplate/rest.template';

class ClinicAPI {
  static findById = (id) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/clinic/${id}`);
  };

  static findByUser = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/clinic/user');
  };

  static getReferencesByEmission = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/clinic/preferences/voucher-invoice');
  };

  static getBirthdayToday = () =>{
    const restTemplate = new RestTemplate(true,true);
    return restTemplate.get('/clinic/birthday/today/');
  }

  static updateMessage = (userID) => {
    const restTemplate = new RestTemplate(true,true);
    return restTemplate.put(`/clinic/birthday/messageSent/${userID}`)
  }

}

export default ClinicAPI;
