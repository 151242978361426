import RestTemplate from '../utils/restTemplate/rest.template';

class TreatmentFinancialFileApi {
  static findByTreatment({ treatment }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/tratamento/${treatment}/financial-file`);
  }

  static saveAccountInfo = ({ treatment, financialFile, formData }) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post(`/tratamento/${treatment}/financial-file/${financialFile}/account-info`, {
      id: formData.id,
      tipo_tratamento: formData.tipo_tratamento,
      tipo_responsavel: formData.tipo_responsavel,
      comprovante: formData.comprovante,
      cpf: formData.cpf,
      rg: formData.rg,
      nome_responsavel: formData.nome_responsavel,
      cpf_responsavel: formData.cpf_responsavel,
      rg_responsavel: formData.rg_responsavel
    });
  };

  static saveDebits = ({ treatment, financialFile, debits }) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post(
      `/tratamento/${treatment}/financial-file/${financialFile}/debits`,
      debits.map((d) => ({
        id: d.id,
        vencimento: d.dataParcela + 'T00:00:00',
        valor: d.valor
      }))
    );
  };

  static aproveNegotiation({ treatment, financialFile }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.put(`/tratamento/${treatment}/financial-file/${financialFile}/aprove-negotiation`);
  }
}

export default TreatmentFinancialFileApi;
