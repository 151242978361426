import React, { Component } from 'react';
import { Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import logo from '../../assets/img/logo.jpg';
import { formatReal, formatRealSemVerificacao, maskTime } from '../../utils/Formatter';
import { ToastContainer } from 'react-toastify';
import Select from 'react-select';
import { selectStyles } from '../../config/core.config';
import _ from 'lodash';
import moment from 'moment';
import { Form, Formik } from 'formik';
import InputMaskComponent from '../../components/InputMask.component';
import ErrorField from '../../components/ErrorField.component';
import { reactSelectHandleChange, reactSelectStyleIsError } from '../../utils/ReactSelectUtils';
import { withRouter } from 'react-router';
import * as Yup from 'yup';
import ErrorFieldWithoutBootstrap from '../../components/ErrorFieldWithoutBootstrap.component';
import ClientApi from '../../service/ClientApi';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { hideLoader, showLoader } from '../../components/actions/Loader.actions';
import { IfComponent } from '../../components/if.component';
import t from 'typy';

class SubscribePaymentPage extends Component {
  state = {
    totalValue: 0,
    totalUsers: 0,
    paymentType: 'mês',
    cupomData: {}
  };

  validateFormSchema = Yup.object().shape({
    nome_cartao: Yup.string().required('Nome Obrigatório'),
    numero_cartao: Yup.string().required('Número cartão Obrigatório'),
    mes: Yup.object().shape({
      value: Yup.number().required('Validade Mês Obrigatório')
    }),
    ano: Yup.object().shape({
      value: Yup.number().required('Validade Ano Obrigatório')
    }),
    cdc: Yup.string().required('Cod. Segurança Obrigatório')
  });

  componentDidMount() {
    const { state = {} } = this.props.location;
    const { additionalUsers = 0, paymentType, totalValue, email, nome } = state;
    this.setState({
      totalUsers: 5 + parseInt(additionalUsers),
      paymentType: paymentType === 'M' ? 'mês' : 'ano',
      paymentTypeCode: paymentType,
      totalValue,
      login: email,
      email,
      nome
    });
  }

  next = (values, actions) => {
    console.log(values.ano.value === moment().format('Y'));
    console.log(values.mes.value < moment().format('M'));
    if (values.ano.value === parseInt(moment().format('Y')) && values.mes.value < parseInt(moment().format('M'))) {
      actions.setFieldError('mes.value', 'Mês de validade não pode ser menor que o mês atual');
    } else {
      const { cupomData } = this.state;
      if (t(cupomData, 'valorCupom').isDefined) {
        const totalDesconto = this.calcularValorDesconto(cupomData, values);
        values.totalValue = values.totalValue - totalDesconto;
        values.cupom = cupomData;

        if (values.totalValue < 0) {
          values.totalValue = 0;
        }
        // values.totalValue = (values.totalValue - (values.totalValue * (this.state.discount / 100)));
      }
      this.props.history.push('/trial/subscribe/nf', { ...values });
    }
  };

  _applyCupom = (values) => {
    this.props.showLoader();
    ClientApi.validateCupom(values.cupom).then(({ data }) => {
      if (data.valorCupom > 0) {
        console.log(this.state);
        this.setState({
          ...values,
          cupomData: data
        });
      }
      this.props.hideLoader();
    });
  };

  render() {
    const year = parseInt(moment().format('Y'));
    const { cupomData } = this.state;
    console.log(cupomData);
    return (
      <Formik
        validateOnChange={false}
        validationSchema={this.validateFormSchema}
        enableReinitialize={true}
        initialValues={this.state}
        onSubmit={this.next}
      >
        {({ errors, values, handleChange }) => {
          return (
            <Form>
              <Container className={'subscribe-payment'}>
                <div className='login-container'>
                  <div
                    className={'subscribe-payment-content'}
                    style={{ height: t(cupomData, 'valorCupom').isDefined ? 689 : 608 }}
                  >
                    <Row className={'plans h-100'} style={{ border: 0 }}>
                      <Col className={'content'} sm={7}>
                        <div className={'content-header'}>
                          <div className={'content-title pt-0'}>
                            <img
                              src={logo}
                              alt='logo'
                              style={{
                                width: 125,
                                height: 34
                              }}
                            />
                          </div>
                          <div className={'content-subtitle'}>Informações do seu Cartão de Crédito</div>
                        </div>
                        <div className={'content-details'}>
                          <FormGroup>
                            <Label className='margin-top-20' for='examplePassword'>
                              Nome Impresso no Cartão
                            </Label>
                            <Input
                              className='input-secondary'
                              type='text'
                              name='nome_cartao'
                              value={values.nome_cartao && values.nome_cartao.toUpperCase()}
                              onChange={handleChange}
                              invalid={errors.nome_cartao ? true : false}
                            />
                            <ErrorField errors={errors} fieldName={'nome_cartao'} />
                          </FormGroup>
                          <FormGroup>
                            <Label className='margin-top-20' for='examplePassword'>
                              Número do Cartão
                            </Label>
                            <InputMaskComponent
                              className='input-secondary'
                              type='text'
                              name='numero_cartao'
                              guide={true}
                              value={values.numero_cartao}
                              id={'fim'}
                              mask={[
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                                ' ',
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                                ' ',
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                                ' ',
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/
                              ]}
                              invalid={errors.numero_cartao ? true : false}
                              onChange={handleChange}
                            />
                            <ErrorField errors={errors} fieldName={'numero_cartao'} />
                          </FormGroup>
                          <Row className='text-default pb-0'>
                            <Col sm={4}>
                              <Label className='margin-top-20'>Validade Mês</Label>
                            </Col>
                            <Col sm={4}>
                              <Label className='margin-top-20'>Validade Ano</Label>
                            </Col>
                            <Col sm={4}>
                              <Label className='margin-top-20'>Cod Segurança</Label>
                            </Col>
                          </Row>
                          <Row className='text-default pb-0'>
                            <Col sm={4} className={'pl-0'}>
                              <Select
                                placeholder=''
                                styles={reactSelectStyleIsError(errors, 'mes', selectStyles)}
                                onChange={(e) => reactSelectHandleChange(e, handleChange, 'mes')}
                                options={_.range(1, 13).map((i) => ({
                                  label: i < 10 ? `0${i}` : i,
                                  value: i
                                }))}
                              />
                              <ErrorFieldWithoutBootstrap errors={errors} fieldName={'mes.value'} />
                            </Col>
                            <Col sm={4}>
                              <Select
                                placeholder=''
                                onChange={(e) => reactSelectHandleChange(e, handleChange, 'ano')}
                                options={_.range(year, year + 20).map((i) => ({
                                  label: i,
                                  value: i
                                }))}
                                styles={reactSelectStyleIsError(errors, 'ano.value', selectStyles)}
                              />
                              <ErrorFieldWithoutBootstrap errors={errors} fieldName={'ano.value'} />
                            </Col>
                            <Col sm={4}>
                              <InputMaskComponent
                                className=''
                                type='text'
                                name='cdc'
                                guide={true}
                                value={values.cdc}
                                invalid={errors.cdc ? true : false}
                                id={'fim'}
                                mask={[/\d/, /\d/, /\d/]}
                                onChange={handleChange}
                              />
                              <ErrorField errors={errors} fieldName={'cdc'} />
                            </Col>
                          </Row>
                        </div>
                        <br />
                        <br />
                        <div>
                          <Row>
                            <Col sm={{ size: 10, offset: 1 }}>
                              <div className={'content-term px-4 ml-2'}>
                                Ao prosseguir, você concorda com o &nbsp;
                                <a
                                  href={'https://griks-assets.s3.amazonaws.com/termos_de_uso.pdf'}
                                  target={'blank'}
                                  className={'link-blue'}
                                >
                                  Termo de Uso{' '}
                                </a>
                                &nbsp; e a &nbsp;
                                <a
                                  href={'https://griks-assets.s3.amazonaws.com/politica_privacidade.pdf'}
                                  target={'blank'}
                                  className={'link-blue'}
                                >
                                  {' '}
                                  Política de Privacidade{' '}
                                </a>
                                &nbsp; do Griks
                              </div>
                            </Col>
                          </Row>
                          <IfComponent conditional={t(cupomData, 'valorCupom').isDefined}>
                            <div className={'h-25 mt-2'}>&nbsp;</div>
                          </IfComponent>
                          <Row>
                            <Col>
                              <div className={'content-term px-4 ml-3 pt-3'}>
                                &nbsp;&nbsp;
                                <button
                                  className={'button-tertiary px-lg-5 ml-4 '}
                                  type={'button'}
                                  onClick={() => this.props.history.goBack()}
                                >
                                  Retornar
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col className={'content content-last'}>
                        <div className={'content-details-payment'}>
                          <div className='title-medium-plus' style={{ marginTop: 44, marginBottom: 40 }}>
                            <strong>RESUMO DA COMPRA</strong>
                          </div>
                          <div className={'text-default mt-3'}>
                            <strong>Plano</strong>
                          </div>
                          <div className='text-default'>Básico</div>

                          <div className={'text-default mt-3'}>
                            <strong>Número máximo de usuários</strong>
                          </div>
                          <div className='text-default'>{values.totalUsers}</div>

                          <div className='text-default'>
                            {formatReal(values.totalValue)} / {values.paymentType}
                          </div>
                          <div className={'text-default mt-3'}>
                            <strong>Assinatura</strong>
                          </div>
                          <div className='text-default'>{this.state.paymentTypeCode === 'M' ? 'Mensal' : 'Anual'}</div>

                          <div className={'text-default mt-3'}>
                            <strong>Forma de Pagamento</strong>
                          </div>
                          <div>
                            {formatReal(values.totalValue)} / {values.paymentType}
                          </div>

                          <IfComponent conditional={t(cupomData, 'valorCupom').isNullOrUndefined}>
                            <div className={'margin-top-20'}>
                              <strong>Cupom de Desconto</strong>
                            </div>
                            <div>
                              <div style={{ display: 'flex', flexDirection: 'Row' }}>
                                <Input
                                  className='input-secondary'
                                  type='text'
                                  name='cupom'
                                  value={values.cupom && values.cupom.toUpperCase()}
                                  onChange={handleChange}
                                  invalid={errors.cupom ? true : false}
                                />
                                <button
                                  className={'button-grey w-50'}
                                  type={'button'}
                                  onClick={() => this._applyCupom(values)}
                                >
                                  Aplicar
                                </button>
                              </div>
                            </div>
                          </IfComponent>

                          <IfComponent conditional={t(cupomData, 'valorCupom').isDefined}>
                            {this.calculaDesconto(values, cupomData)}
                            {/*<div className={ 'margin-top-20 content-discount' }><strong>Cupom de Desconto</strong></div>*/}

                            {/*<div*/}
                            {/*  className={ 'content-discount-result' }>*/}
                            {/*  { cupomData.tipoCupom === 'PORCENTAGEM' ? formatReal(*/}
                            {/*    (values.totalValue * cupomData.valorCupom)) : cupomData.valorCupom }*/}
                            {/*</div>*/}

                            {/*<div className={ 'margin-top-20 content-discount' }><strong>Valor Final a Pagar</strong>*/}
                            {/*</div>*/}
                            {/*<div>{*/}
                            {/*  cupomData.tipoCupom === 'PORCENTAGEM' ? formatReal(values.totalValue - (values.totalValue * cupomData.valorCupom)) : values.totalValue - cupomData.valorCupom*/}
                            {/*}</div>*/}
                          </IfComponent>
                          <br />
                          <br />
                          <button className={'content-price-box-subscribe-button button-primary  '} type={'submit'}>
                            Continuar
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
                <ToastContainer />
              </Container>
            </Form>
          );
        }}
      </Formik>
    );
  }

  calculaDesconto = (values, cupomData) => {
    let totalDesconto = this.calcularValorDesconto(cupomData, values);
    return (
      <>
        <div className={'margin-top-20 content-discount'}>
          <strong>Cupom de Desconto</strong>
        </div>
        <div className={'content-discount-result'}>
          {formatRealSemVerificacao(totalDesconto > values.totalValue ? 0.0 : totalDesconto)}
        </div>
        <div className={'margin-top-20 content-discount'}>
          <strong>Valor Final a Pagar</strong>
        </div>
        <div>
          {formatRealSemVerificacao(values.totalValue - totalDesconto < 0 ? 0.0 : values.totalValue - totalDesconto)}
        </div>
        <br />
        <div className={'content-discount'}>
          <IfComponent conditional={this.state.paymentTypeCode === 'M'}>
            Você terá um desconto de{' '}
            {cupomData.tipoCupom === 'PORCENTAGEM'
              ? `${cupomData.valorCupom * 100}%`
              : formatReal(cupomData.valorCupom)}{' '}
            {cupomData.tempoCupom > 1 ? `nas ${cupomData.tempoCupom} primeiras mensalidades` : 'no primeiro pagamento'}
          </IfComponent>
        </div>
      </>
    );
  };

  calcularValorDesconto(cupomData, values) {
    let totalDesconto = 0;
    if (this.state.paymentTypeCode === 'M') {
      if (cupomData.tipoCupom === 'PORCENTAGEM') {
        totalDesconto = values.totalValue * cupomData.valorCupom;
      } else {
        totalDesconto = cupomData.valorCupom;
      }
    } else {
      let descontoMeses = 0;

      if (cupomData.tipoCupom === 'PORCENTAGEM') {
        descontoMeses = cupomData.valorCupom;
        totalDesconto = (cupomData.tempoCupom / 12) * (values.totalValue * descontoMeses);
      } else {
        descontoMeses = cupomData.valorCupom * cupomData.tempoCupom;
        totalDesconto = descontoMeses;
      }
    }
    return totalDesconto;
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader())
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withRouter);

export default enhanced(SubscribePaymentPage);
