import React from 'react';
import PrivateRouter from '../components/PrivateRoute.component';
import ScheduleHomePage from '../pages/schedule/ScheduleHome.page';
import ScheduleConfirmationsPage from '../pages/schedule/ScheduleConfirmations.page';
import ScheduleConfirmationsReportPage from '../pages/schedule/ScheduleConfirmationsReport.page';
import ListSchedulePage from '../pages/schedule/ListSchedule.page';

const ScheduleRouters = (props) => {
  return [
    <PrivateRouter key='home' exact path='/schedule' render={(props) => <ScheduleHomePage {...props} />} />,
    <PrivateRouter
      key='confirmations'
      exact
      path='/schedule/confirmations'
      render={(props) => <ScheduleConfirmationsPage {...props} />}
    />,
    <PrivateRouter
      key='confirmationsReport'
      exact
      path='/schedule/confirmations/report'
      render={(props) => <ScheduleConfirmationsReportPage {...props} />}
    />,
    <PrivateRouter key='scheduleList' exact path='/schedule/list' render={(props) => <ListSchedulePage {...props} />} />
  ];
};

export default ScheduleRouters;
