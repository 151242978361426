import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Col, Container, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import SelectComponent from '../../../../components/SelectComponent';
import ProceduresAPI from '../../../../service/ProceduresAPI';
import { hideLoader, showLoader } from '../../../../components/actions/Loader.actions';
import { useDispatch } from 'react-redux';
import ErrorFieldWithoutBootstrap from '../../../../components/ErrorFieldWithoutBootstrap.component';
import ScheduleAPI from '../../../../service/ScheduleAPI';
import ToastUtils from '../../../../utils/ToastUtils';

const SettingsScheduleAndControlReturStandardProceduresModal = ({ callback }, modalRef) => {
  const dispatch = useDispatch();
  const [dentist, setDentist] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [configs, setConfigs] = useState(null);
  const [categoriasOptions, setCategoriasOptions] = useState([]);
  const [errors, setErrors] = useState([]);

  const [categoriaAgenda, setCategoriaAgenda] = useState(null);
  const [categoriaControleControle, setCategoriaControleControle] = useState(null);
  const [categoriaControleContencao, setCategoriaControleContencao] = useState(null);

  const [procedimentosAgenda, setProcedimentosAgenda] = useState([]);
  const [procedimentoLoading, setProcedimentoLoading] = useState(false);

  const [procedimentosControle, setProcedimentosControle] = useState([]);
  const [procedimentoControleLoading, setProcedimentoControleLoading] = useState(false);

  const [procedimentosControleContencao, setProcedimentosControleContencao] = useState([]);
  const [procedimentoControleContencaoLoading, setProcedimentoControleContencaoLoading] = useState(false);

  const [procedimentoAgenda, setProcedimentoAgenda] = useState(null);
  const [procedimentoControleControle, setProcedimentoControleControle] = useState(null);
  const [procedimentoControleContencao, setProcedimentoControleContencao] = useState(null);

  useImperativeHandle(modalRef, () => ({
    openModal(dentist, configs) {
      setShowModal(true);
      setDentist(dentist);
      setConfigs(configs);
    }
  }));

  useEffect(() => {
    dispatch(showLoader());
    ProceduresAPI.getAllCategories().then(({ data }) => {
      const cat = data.map((c) => {
        return {
          label: c.nome,
          value: c.id
        };
      });
      dispatch(hideLoader());
      setCategoriasOptions(cat);
    });
  }, []);

  useEffect(() => {
    if (configs) {
      const catSelected = categoriasOptions.filter((c) => c.value === parseInt(configs.procedimento_categoria_id));
      setCategoriaAgenda(catSelected[0]);
      setProcedimentoAgenda({ label: configs.procedimento_nome, value: configs.procedimento_id });

      if (configs.procedimentoPadraoContencao) {
        const procedimentoPadraoContencao = configs.procedimentoPadraoContencao;
        setCategoriaControleContencao(
          categoriasOptions.filter((c) => c.value === procedimentoPadraoContencao.categoria.id)[0]
        );
        setProcedimentoControleContencao({
          label: procedimentoPadraoContencao.nome,
          value: procedimentoPadraoContencao.id
        });
      }

      if (configs.procedimentoPadraoControle) {
        const procedimentoPadraoControle = configs.procedimentoPadraoControle;
        setCategoriaControleControle(
          categoriasOptions.filter((c) => c.value === procedimentoPadraoControle.categoria.id)[0]
        );
        setProcedimentoControleControle({
          label: procedimentoPadraoControle.nome,
          value: procedimentoPadraoControle.id
        });
      }
    }
  }, [configs]);

  function findProcedimentos(categoria) {
    console.log(categoria);
    return ProceduresAPI.getAllProcedures(categoria.value, true).then(({ data }) => {
      return data.map((d) => ({ label: d.nome, value: d.id }));
    });
  }

  useEffect(() => {
    if (categoriaAgenda != null) {
      dispatch(showLoader());
      setProcedimentoLoading(true);
      findProcedimentos(categoriaAgenda)
        .then(setProcedimentosAgenda)
        .then(() => {
          setProcedimentoLoading(false);
          dispatch(hideLoader());
        });
    }
  }, [categoriaAgenda]);

  useEffect(() => {
    if (categoriaControleControle != null) {
      setProcedimentoControleLoading(true);
      findProcedimentos(categoriaControleControle)
        .then(setProcedimentosControle)
        .then(() => {
          setProcedimentoControleLoading(false);
          dispatch(hideLoader());
        });
    }
  }, [categoriaControleControle]);

  useEffect(() => {
    if (categoriaControleContencao != null) {
      setProcedimentoControleContencaoLoading(true);
      findProcedimentos(categoriaControleContencao)
        .then(setProcedimentosControleContencao)
        .then(() => {
          setProcedimentoControleContencaoLoading(false);
          dispatch(hideLoader());
        });
    }
  }, [categoriaControleContencao]);

  function closeModal() {
    setShowModal(false);
  }

  async function salvarConfiguracao() {
    let errors = {};
    let valida = true;
    if (!procedimentoAgenda) {
      errors.procedimentoAgenda = 'Procedimento Obrigatório';
      valida = false;
    }

    if (!procedimentoControleControle) {
      errors.procedimentoControleControle = 'Procedimento Obrigatório';
      valida = false;
    }

    if (!procedimentoControleContencao) {
      errors.procedimentoControleContencao = 'Procedimento Obrigatório';
      valida = false;
    }
    setErrors(errors);
    if (valida) {
      dispatch(showLoader());
      Promise.all([
        ScheduleAPI.saveStandardProcedure({
          id: configs.id,
          dentista: dentist,
          procedimento_id: procedimentoAgenda.value
        }),
        ScheduleAPI.saveProcedureForControlReturn(dentist, [
          { procedimento: procedimentoControleControle.value, tipoControleRetorno: 'P' },
          { procedimento: procedimentoControleContencao.value, tipoControleRetorno: 'C' }
        ])
      ])
        .then((response) => {
          dispatch(hideLoader());
          callback();
          closeModal();
        })
        .catch((err) => {
          ToastUtils.error('Erro ao salvar configuração, entre em contato com o suporte');
          dispatch(hideLoader());
        });
    }
  }

  return (
    <Modal isOpen={showModal} toggle={closeModal}>
      <ModalHeader className='title-primary' toggle={closeModal} close={closeModal}>
        <strong>Editar Procedimentos Padrão da Agenda</strong>
      </ModalHeader>
      <ModalBody>
        <div className='margin-top-10'>
          <Row>
            <Col sm='12' className='mb-1'>
              <strong>Procedimento Padrão da Agenda</strong>
            </Col>
          </Row>
          <Row>
            <Col sm='6'>
              <Label className='text-xsm'>Categoria</Label>
            </Col>
            <Col sm='6'>
              <Label className='text-xsm'>Procedimento</Label>
            </Col>
          </Row>
          <Row>
            <Col sm='6'>
              <SelectComponent
                options={categoriasOptions}
                value={categoriaAgenda}
                callbackOnChange={(cat) => {
                  setCategoriaAgenda(cat);
                  setProcedimentoAgenda({});
                }}
              />
              <ErrorFieldWithoutBootstrap fieldName={'categoriaAgenda'} errors={errors} />
            </Col>
            <Col sm='6'>
              <SelectComponent
                options={procedimentosAgenda}
                placeholder={procedimentoLoading ? 'Carregando' : ''}
                callbackOnChange={setProcedimentoAgenda}
                value={procedimentoAgenda}
              />
              <ErrorFieldWithoutBootstrap fieldName={'procedimentoAgenda'} errors={errors} />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col sm='12' className='mb-1'>
              <strong>Procedimento Padrão da Lista Controle</strong>
            </Col>
          </Row>
          <Row>
            <Col sm='6'>
              <Label className='text-xsm'>Categoria</Label>
            </Col>
            <Col sm='6'>
              <Label className='text-xsm'>Procedimento</Label>
            </Col>
          </Row>
          <Row>
            <Col sm='6'>
              <SelectComponent
                options={categoriasOptions}
                value={categoriaControleControle}
                callbackOnChange={(cat) => {
                  setCategoriaControleControle(cat);
                  setProcedimentoControleControle({});
                }}
              />
              <ErrorFieldWithoutBootstrap fieldName={'categoriaControleControle'} errors={errors} />
            </Col>
            <Col sm='6'>
              <SelectComponent
                options={procedimentosControle}
                placeholder={procedimentoControleLoading ? 'Carregando' : ''}
                value={procedimentoControleControle}
                callbackOnChange={setProcedimentoControleControle}
              />
              <ErrorFieldWithoutBootstrap fieldName={'procedimentoControleControle'} errors={errors} />
            </Col>
          </Row>

          <hr />
          <Row>
            <Col sm='12' className='mb-1'>
              <strong>Procedimento Padrão da Lista Contenção</strong>
            </Col>
          </Row>
          <Row>
            <Col sm='6'>
              <Label className='text-xsm'>Categoria</Label>
            </Col>
            <Col sm='6'>
              <Label className='text-xsm'>Procedimento</Label>
            </Col>
          </Row>
          <Row>
            <Col sm='6'>
              <SelectComponent
                options={categoriasOptions}
                value={categoriaControleContencao}
                callbackOnChange={(cat) => {
                  setCategoriaControleContencao(cat);
                  setProcedimentoControleContencao({});
                }}
              />
              <ErrorFieldWithoutBootstrap fieldName={'categoriaControleContencao'} errors={errors} />
            </Col>
            <Col sm='6'>
              <SelectComponent
                options={procedimentosControleContencao}
                placeholder={procedimentoControleContencaoLoading ? 'Carregando' : ''}
                value={procedimentoControleContencao}
                callbackOnChange={setProcedimentoControleContencao}
              />
              <ErrorFieldWithoutBootstrap fieldName={'procedimentoControleContencao'} errors={errors} />
            </Col>
          </Row>
        </div>
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col className='btn-list' sm={{ size: 6, offset: 6 }}>
            <button className='button-primary' onClick={() => salvarConfiguracao()}>
              Salvar
            </button>
            <button className='button-tertiary' onClick={closeModal}>
              Cancelar
            </button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

export default forwardRef(SettingsScheduleAndControlReturStandardProceduresModal);
