import React, { Component } from 'react';

import { Col, Input, InputGroup, InputGroupAddon, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { procedureTablePriceMonthly } from '../redux/SettingsProcedureTablePrice.action';
import { Formik } from 'formik';
import * as Yup from 'yup';

import ErrorField from '../../../../components/ErrorField.component';
import ProceduresAPI from '../../../../service/ProceduresAPI';
import MessagesComponent from '../../../../components/Messages.component';
import InputDecimalComponent from '../../../../components/InputDecimalComponent';
import { t } from 'typy';
import ToastUtils from '../../../../utils/ToastUtils';
import ErrorFieldWithoutBootstrap from '../../../../components/ErrorFieldWithoutBootstrap.component';

class SettingsProcedurePriceMaintenanceModal extends Component {
  state = {
    error: {
      display: false,
      type: 'danger',
      message: ''
    }
  };

  formValidationSchema = Yup.object().shape({
    tipoAparelho: Yup.string().required('Tipo Aparelho Obrigatório'),
    valorEntrada: Yup.number().required('Entrada Obrigatório'),
    valorMensalidade: Yup.number().required('Mensalidade Obrigatório')
  });

  saveProcedureMonthly = (values) => {
    const { showLoader, hideLoader, closeModal, refreshPage, formData = {} } = this.props;

    showLoader();

    ProceduresAPI.saveMonthlyDeviceType({
      id: formData.id,
      aparelho: values.tipoAparelho,
      valorEntrada: values.valorEntrada,
      valorMensalidade: values.valorMensalidade
    })
      .then(({ data }) => {
        hideLoader();
        refreshPage();
        closeModal();
      })
      .catch(({ data }) => {
        console.error(data);
        ToastUtils.error('Erro ao salvar tipo de aparelho');
        hideLoader();
      });
  };

  render() {
    const { modal, formData, closeModal } = this.props;
    const { error } = this.state;
    return (
      <Modal isOpen={modal} toggle={closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={closeModal}
          close={
            <button className='close' onClick={closeModal}>
              &times;
            </button>
          }
        >
          {t(formData, 'id').isDefined ? <strong>Editar</strong> : <strong>Adicionar</strong>} Procedimento
        </ModalHeader>
        <Formik
          validationSchema={this.formValidationSchema}
          initialValues={formData}
          validateOnChange={false}
          onSubmit={this.saveProcedureMonthly}
        >
          {({ errors, handleSubmit, handleBlur, handleChange, values }) => {
            return (
              <form onSubmit={handleSubmit}>
                <ModalBody>
                  <div className='margin-top-10'>
                    <Row className='margin-top-10'>
                      <Col sm='12'>
                        <h1 className='title-modal'>
                          <strong>Ortodontia</strong> Modalidade/Manutenção
                        </h1>
                      </Col>
                    </Row>
                    <Row className='margin-top-10'>
                      <Col className='text-left' sm='12'>
                        <p>
                          <strong>Tipo de Aparelho</strong>
                        </p>
                        <Input
                          value={values.tipoAparelho}
                          name={'tipoAparelho'}
                          onChange={handleChange}
                          invalid={errors.tipoAparelho ? true : false}
                        />
                        <ErrorField errors={errors} fieldName={'tipoAparelho'} />
                      </Col>
                    </Row>
                    <Col className='margin-top-10' sm='12'>
                      <Row>
                        <Col sm='5'>
                          <p>
                            <strong>Entrada Aparelho*</strong>
                          </p>
                          <InputDecimalComponent
                            classNameStyle={'input-secondary'}
                            prefix={'R$ '}
                            name={'valorEntrada'}
                            value={values.valorEntrada}
                            handleChange={handleChange}
                            errors={errors}
                          />
                          <ErrorFieldWithoutBootstrap errors={errors} fieldName={'valorEntrada'} />
                        </Col>

                        <Col sm='7'>
                          <p>
                            <strong>Mensalidade/Manutenção*</strong>
                          </p>
                          <InputDecimalComponent
                            classNameStyle={'input-secondary'}
                            prefix={'R$ '}
                            name={'valorMensalidade'}
                            value={values.valorMensalidade}
                            handleChange={handleChange}
                            errors={errors}
                          />
                          <ErrorFieldWithoutBootstrap errors={errors} fieldName={'valorMensalidade'} />
                        </Col>
                      </Row>
                    </Col>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Row>
                    <Col sm={{ size: 3, offset: 6 }}>
                      <button className='button-primary'>Salvar</button>
                    </Col>
                    <Col sm='3'>
                      <button className='button-tertiary' type={'button'} onClick={closeModal}>
                        Cancelar
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = ({ SettingsProceduresTableReducer }) => {
  const { monthly } = SettingsProceduresTableReducer;
  return {
    ...monthly
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(procedureTablePriceMonthly({ modal: false }))
  };
};

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(SettingsProcedurePriceMaintenanceModal);
