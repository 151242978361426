import React, { Component } from 'react';

import { Container, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { withLoader } from '../../../components/Loader.component';

import { compose } from 'recompose';
import PacienteAPI from '../../../service/PacienteAPI';
import UserAPI from '../../../service/User.service';
import ErrorMessageComponent from '../../../components/ErrorMessage.component';

import warning from '../../../assets/img/icons/warning.svg';
import Icon from '../../../components/Icon.component';

import { connect } from 'react-redux';
import { sendMessage } from '../../../components/actions/ErrorMessage.actions';
import { withRouter } from 'react-router-dom';
import ToastUtils from '../../../utils/ToastUtils';
import success from '../../../assets/img/icons/success.svg';
import ToastCustomMessageComponent from '../../../components/ToastCustomMessage.component';

class PatientDeleteModal extends Component {
  state = {
    password: '',
    error: {
      display: false,
      type: '',
      message: ''
    }
  };

  excluirPaciente = async () => {
    const check = await UserAPI.checkPassword(this.state.password);

    if (!check) {
      this.setState({
        error: {
          display: true,
          type: 'danger',
          message: 'Senha inválida, por favor digite sua senha para confirmar a exclusão do paciente'
        }
      });
    } else {
      this.props.showLoader();
      this.setState({
        error: {
          display: false,
          type: '',
          message: ''
        }
      });

      PacienteAPI.deletePatient(this.props.patient.paciente_id)
        .then((d) => {
          // this.props.sendMessage({
          //   display: true,
          //   message: 'Senha alterada com sucesso',
          //   type: 'info'
          // });

          // this.props.sendMessage({
          //   display: true,
          //   message: 'Paciente Excluido com Sucesso',
          //   type: 'secondary'
          // });
          ToastUtils.success(
            <ToastCustomMessageComponent iconImage={success} title={'Paciente Excluido com Sucesso !'} />,
            {
              autoClose: 6000,
              hideProgressBar: true,
              position: 'top-center'
            }
          );
          this.props.hideLoader();
          this.props.history.push('/patient');
        })
        .catch((err) => {
          this.props.hideLoader();
          this.setState({
            error: {
              display: true,
              type: 'danger',
              message: 'Erro ao excluir paciente entre em contato com o Administrador'
            }
          });
        });
    }
  };

  render() {
    const { showModal, closeModal, patient } = this.props;
    const { error } = this.state;

    return (
      <div className='content-box-without-color'>
        <Container>
          <Modal isOpen={showModal} toggle={closeModal} size='small' className='patientMessage'>
            <ModalHeader
              className='title-primary'
              toggle={closeModal}
              close={() => (
                <button className='close' onClick={closeModal}>
                  &times;
                </button>
              )}
            >
              <strong>Excluir Paciente</strong>
            </ModalHeader>
            <ModalBody>
              <ErrorMessageComponent
                id={'patientDelete'}
                where={'patientDelete'}
                display={error.display}
                type={error.type}
                message={error.message}
              />

              <Row>
                <Col sm='12'>
                  <div className='modal-icon'>
                    <Icon className='icon-alert icon-size-big' image={warning} alt='warning' />
                  </div>
                </Col>
              </Row>
              <div className='margin-top-20'>
                <Row>
                  <Col sm='12'>
                    <p>
                      Tem certeza que deseja excluir <strong>{patient.nome}</strong>?
                    </p>
                  </Col>
                </Row>
              </div>
              <div className='mt-1'>
                <Row>
                  <Col sm='12'>
                    <p>Todas as informações serão definitivamente perdidas</p>
                  </Col>
                </Row>
              </div>
              <div className='margin-top-10'>
                <Row>
                  <Col sm={{ size: 6, offset: 3 }}>
                    <div className='input-float'>
                      <input
                        className='input-secondary'
                        type='password'
                        id='senha'
                        name='senha'
                        placeholder='Senha'
                        onChange={(e) => this.setState({ password: e.target.value })}
                      />
                      <label htmlFor='senha text-sm font-bold'>Senha</label>
                    </div>
                  </Col>
                </Row>
              </div>
            </ModalBody>
            <ModalFooter>
              <Row>
                <Col sm={{ size: 3, offset: 6 }}>
                  <button className='button-cancel' onClick={this.excluirPaciente}>
                    Excluir
                  </button>
                </Col>
                <Col sm='3'>
                  <button className='button-tertiary' onClick={closeModal}>
                    Cancelar
                  </button>
                </Col>
              </Row>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    sendMessage: (data) => dispatch(sendMessage(data))
  };
};

const enhanced = compose(withRouter, withLoader, connect(mapStateToProps, mapDispatchToProps));

export default enhanced(PatientDeleteModal);
