import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import { Form, Formik } from 'formik';
import Radium, { StyleRoot } from 'radium';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { fadeIn } from 'react-animations';
import 'react-month-picker/css/month-picker.css';
import { RadioButton, RadioGroup } from 'react-radio-buttons';
import { useDispatch } from 'react-redux';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { t } from 'typy';
import * as Yup from 'yup';
import noavatar from '../../../assets/img/noavatar.png';
import { hideLoader, showLoader } from '../../../components/actions/Loader.actions';
import ErrorFieldWithoutBootstrap from '../../../components/ErrorFieldWithoutBootstrap.component';
import InputComponent from '../../../components/InputComponent';
import MonthPicker from '../../../components/MonthPicker';
import SugestionBoxComponent from '../../../components/SuggestionBoxComponent';
import ControleRetornoAPI from '../../../service/ControleRetornoAPI';
import PacienteAPI from '../../../service/PacienteAPI';
import FormikUtils from '../../../utils/FormikUtils';
import ToastUtils from '../../../utils/ToastUtils';
import { dateFormat } from '../../../utils/Formatter';

const ScheduleControlReturnAddModal = ({ className, callback }, modalRef) => {
  const dispatch = useDispatch();
  const pickRef = useRef();
  const [patientAutoComplenteValue, setPatientAutoComplenteValue] = useState('');
  const [patientSelected, setPatientSelected] = useState('');
  const [showModal, setShowModal] = useState(null);
  const [dentist, setDentist] = useState(null);
  const [suggestionsData, setSuggestionsData] = useState([]);

  const formValidationSchema = Yup.object().shape({
    patient: Yup.object().required('patient'),
    tipo_retorno: Yup.string().required('Tipo de Retorno obrigatório'),
    mes_alvo: Yup.string().required('Mês Alvo obrigatório')
  });

  useImperativeHandle(modalRef, () => ({
    openModal({ dentist, patient }) {
      setDentist(dentist);
      if (patient && patient.id) {
        setPatientSelected(patient);
        setPatientAutoComplenteValue(patient.nome);
      }

      setShowModal(true);
    }
  }));

  function renderSuggestion(suggestion, { query }) {
    const suggestionText = `${suggestion.name}`;
    const matches = AutosuggestHighlightMatch(suggestionText, query);
    const parts = AutosuggestHighlightParse(suggestionText, matches);
    if (suggestion.id) {
      return (
        <span className={'suggestion-content'}>
          <img src={suggestion.image} style={{ maxWidth: 62, maxHeight: 62 }} alt='' />
          <span className='name'>
            {parts.map((part, index) => {
              const className = part.highlight ? 'highlight' : null;

              return (
                <span className={className} key={index}>
                  {part.text}
                </span>
              );
            })}
          </span>
        </span>
      );
    } else {
      return (
        <span className={'suggestion-content'}>
          <span className='name'>
            <span className={''}>
              {'Nenhum paciente encontrado com o nome'} {<span className='highlight'>({query})</span>}
            </span>
          </span>
        </span>
      );
    }
  }

  function renderSuggestionsContainer({ containerProps, children, query }) {
    return (
      <StyleRoot>
        <div
          {...containerProps}
          style={{
            animation: 'x 0.9s',
            animationName: Radium.keyframes(fadeIn, 'fadeIn')
          }}
        >
          {children}
        </div>
      </StyleRoot>
    );
  }

  async function findPatients(searchTerm) {
    if (searchTerm.length > 2) {
      try {
        const p = await PacienteAPI.pescInc(searchTerm);
        if (p.length === 0) {
          p.push({
            nome: 'Nenhum paciente encontrado'
          });
        }
        setSuggestionsData(
          p.map((pt) => {
            return {
              id: pt.id,
              name: pt.nome,
              image: pt.foto || noavatar
            };
          })
        );
      } catch (err) {
        setSuggestionsData([]);
      }
    } else {
      setSuggestionsData([]);
    }
  }

  function onChangePatientAutoComplete(event, { newValue }) {
    setPatientAutoComplenteValue(newValue);
  }

  const inputPropsPatientAutoComplete = {
    placeholder: '',
    value: patientAutoComplenteValue,
    onChange: onChangePatientAutoComplete
  };

  const renderInputComponent = (inputProps) => (
    <div className=''>
      <input
        {...inputProps}
        className='input-secondary'
        type='text'
        name='firstname'
        autoComplete='off'
        data-lpignore='true'
        spellCheck='false'
      />
    </div>
  );

  function closeModal() {
    setPatientAutoComplenteValue('');
    setShowModal(false);
  }

  function saveControlReturn({ patient, mes_alvo, anotacoes, tipo_retorno }) {
    dispatch(showLoader());
    ControleRetornoAPI.saveControleRetorno({
      paciente: patient.id,
      dentista: dentist.value,
      mesAlvo: dateFormat(mes_alvo, 'MM/YYYY'),
      anotacoes: anotacoes,
      tipoControleRetorno: tipo_retorno
    })
      .then(({ data }) => {
        dispatch(hideLoader());
        closeModal();
        setPatientAutoComplenteValue('');
        callback();
      })
      .catch((err) => {
        console.error(err);
        if (t(err, 'response.data.message').safeString.includes('Paciente já encontrado')) {
          dispatch(hideLoader());
          ToastUtils.error('Paciente já cadastrado na lista de controle e retorno');
        } else {
          dispatch(hideLoader());
          ToastUtils.error('Erro ao adicionar paciente a lista, entre em contato com o suporte');
        }
      });
  }

  return (
    <Modal isOpen={showModal} toggle={closeModal} className={className + ' modal-small'}>
      <ModalHeader
        className='m-0 title-primary'
        toggle={closeModal}
        style={{paddingRight:30,paddingLeft:30,paddingTop:30}}
        close={
          <button className='close' onClick={closeModal}>
            &times;
          </button>
        }
      >
        <strong>Inserir na Lista CONTROLE E CONTENÇÃO</strong>
      </ModalHeader>
      <Formik
        initialValues={{ tipo_retorno: 'P', patient: patientSelected }}
        validationSchema={formValidationSchema}
        validateOnChange={false}
        onSubmit={saveControlReturn}
      >
        {({ values, errors, handleChange }) => {
          return (
            <Form>
              <ModalBody style={{paddingRight:30,paddingLeft:30}}>
                <div className='margin-top-10'>
                  <Row>
                    <Col className='text-left' sm='12'>
                      <label>
                        Paciente <span>*</span>
                      </label>
                    </Col>
                    <Col sm={12}>
                      <form autoComplete='off'>
                        <SugestionBoxComponent
                          renderSuggestionsContainer={renderSuggestionsContainer}
                          renderSuggestion={renderSuggestion}
                          renderInputComponent={(props) =>
                            renderInputComponent({
                              ...props,
                              style: {
                                borderColor: errors.patient ? 'red' : ''
                              }
                            })
                          }
                          findSuggestions={findPatients}
                          selectedSuggestion={(patient) => FormikUtils.setValueField(handleChange, 'patient', patient)}
                          suggestionsData={suggestionsData}
                          inputProps={inputPropsPatientAutoComplete}
                        />
                      </form>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col sm={12} className={'text-left'}>
                      <label>
                        Tipo de Retorno <span>*</span>
                      </label>
                    </Col>
                    <Col sm={12}>
                      <RadioGroup
                        className='radio-griks d-flex justify-content-center pt-2'
                        name='type'
                        value={values.tipo_retorno}
                        horizontal
                        onChange={(e) => FormikUtils.setValueField(handleChange, 'tipo_retorno', e)}
                      >
                        <RadioButton pointColor={'var(--primary)'} iconSize={20} value='P'>
                          <span style={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>Controle Pré-tratamento</span>
                        </RadioButton>
                        <RadioButton pointColor={'var(--primary)'} iconSize={20} value='C'>
                          <span style={{ paddingRight: '4rem', paddingLeft: '4rem' }}>Contenção</span>
                        </RadioButton>
                      </RadioGroup>
                      <ErrorFieldWithoutBootstrap fieldName={'tipo_retorno'} errors={errors} />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col sm={12} className={'text-left'}>
                      <label>
                        Mês Alvo <span>*</span>
                      </label>
                    </Col>
                    <Col sm={12}>
                      <MonthPicker anoInicial={10} valueHowMoment={true} handleChange={handleChange} values={values} />
                      <ErrorFieldWithoutBootstrap fieldName={'mes_alvo'} errors={errors} />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col sm={12} className={'text-left'}>
                      <label>Anotações</label>
                    </Col>
                    <Col sm={12}>
                      <InputComponent type={'textarea'} name={'anotacoes'} handler={handleChange} />
                    </Col>
                  </Row>
                </div>
              </ModalBody>
              <ModalFooter style={{paddingRight:30,paddingLeft:30,paddingBottom:30}}>
                <Row>
                  <Col sm={{ size: 3, offset: 6 }}>
                    <button className='button-tertiary' type={'button'} onClick={() => closeModal()}>
                      Cancelar
                    </button>
                  </Col>
                  <Col sm='3'>
                    <button className='button-primary' type={'submit'}>
                      Salvar
                    </button>
                  </Col>
                </Row>
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default forwardRef(ScheduleControlReturnAddModal);
