import React from 'react';
import PrivateRouter from '../components/PrivateRoute.component';
import AffiliatesPage from '../pages/affiliates/AffiliatesPage';

const AffiliatesRouters = (props) => {
  return [
    <PrivateRouter key='AffiliatesPage' exact path='/affiliates' render={(props) => <AffiliatesPage {...props} />} />
  ];
};

export default AffiliatesRouters;
