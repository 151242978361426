import React, { Component } from 'react';

import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Form, Formik } from 'formik';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { clinicalRecordTreatmentContentionModal } from '../redux/TreatmentClinicalRecord.action';

import { showLoader, hideLoader } from '../../../../components/actions/Loader.actions';
import {
  formInitSelector,
  propsModalSelector
} from '../selectors/TreatmentClinicalRecordTreatmentContentionModalSelector';
import TreatmentClinicalRecordApi from '../../../../service/TreatmentClinicalRecordApi';
import MessagesComponent from '../../../../components/Messages.component';

class TreatmentClinicalRecordContentionModal extends Component {
  state = {
    message: {
      display: false,
      type: 'danger',
      text: ''
    }
  };

  saveContention = async (values) => {
    const { showLoader, hideLoader, updateParent, treatment, record, plan } = this.props;

    try {
      showLoader();
      await TreatmentClinicalRecordApi.saveTreatmentPlanContention({
        treatmentId: treatment,
        recordId: record,
        planId: plan,
        contention: values
      });

      updateParent();
      this.closeModal();
    } catch (e) {
      console.log(e);
      this.setState({
        message: {
          display: true,
          type: 'danger',
          text: 'Erro ao salvar Contenção do Tratamento entre em contato com o suporte'
        }
      });
    }

    hideLoader();
  };

  closeModal = () => {
    this.props.closeModal();
    this.setState({
      message: {
        display: false,
        type: 'danger',
        text: ''
      }
    });
  };

  render() {
    const { modal, formInit } = this.props;
    const { message } = this.state;
    return (
      <Modal isOpen={modal} toggle={this.closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary pb-0'
          toggle={this.closeModal}
          close={
            <button className='close' onClick={this.closeModal}>
              &times;
            </button>
          }
        >
          <strong>Editar Contenção</strong>
        </ModalHeader>
        <Formik initialValues={formInit} validateOnChange={false} onSubmit={this.saveContention}>
          {({ errors, values, handleChange }) => {
            return (
              <Form>
                <ModalBody>
                  <div>
                    <MessagesComponent display={message.display} type={message.type} message={message.text} />
                    <Row className='margin-top-10'>
                      <h1 className='text-default font-bold'>CONTENÇÃO SUPERIOR</h1>
                      <Col sm='12'>
                        <label>Aparelho / Uso / Tempo</label>
                        <input
                          className='input-secondary'
                          type='text'
                          placeholder={'Exemplo: Hawley modificado - Uso 24 hs - 1 ano'}
                          name='superior'
                          value={values.superior}
                          onChange={handleChange}
                        />
                      </Col>
                    </Row>
                    <Row className='margin-top-10 pt-2'>
                      <h1 className='text-default font-bold'>CONTENÇÃO INFERIOR</h1>
                      <Col sm='12'>
                        <label>Aparelho / Tempo</label>
                        <input
                          className='input-secondary'
                          type='text'
                          placeholder={'Exemplo: 3x3 aço 0,6 - 7 anos'}
                          name={'inferior'}
                          value={values.inferior}
                          onChange={handleChange}
                        />
                      </Col>
                    </Row>
                    <hr></hr>
                    <Row className='margin-top-10'>
                      <h1 className='text-default font-bold'>CONTENÇÃO SUPERIOR (FASE COMPLEMENTAR)</h1>
                      <Col sm='12'>
                        <label>Uso / Tempo</label>
                        <input
                          className='input-secondary'
                          type='text'
                          placeholder={'Exemplo: Uso noturno - 2 anos'}
                          name={'superior_complementar'}
                          value={values.superior_complementar}
                          onChange={handleChange}
                        />
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Row>
                    <Col className='btn-list' sm={{ size: 6, offset: 6 }}>
                      <button className='button-primary' type={'submit'}>
                        Salvar
                      </button>
                      <button className='button-tertiary' type={'button'} onClick={this.closeModal}>
                        Cancelar
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...propsModalSelector(state),
    formInit: formInitSelector(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  closeModal: () => dispatch(clinicalRecordTreatmentContentionModal({ modal: false }))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(TreatmentClinicalRecordContentionModal);
