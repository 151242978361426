import React, { Component, useEffect, useState, useRef } from 'react';
import { Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import noavatar from '../../../assets/img/noavatar.png';
import { AiOutlineSortAscending } from 'react-icons/ai';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { t } from 'typy';
import DomainsAPI from '../../../service/DomainsAPI';
import { hideLoader, showLoader } from '../../../components/actions/Loader.actions';
import { arrayToSelectItems, reactSelectHandleChange } from '../../../utils/ReactSelectUtils';
import MultiSelect from '@khanacademy/react-multi-select';
import _ from 'lodash';
import { Form, Formik } from 'formik';
import { IfComponent } from '../../../components/if.component';
import { AiOutlinePrinter } from 'react-icons/ai';
import ReactTooltip from 'react-tooltip';
import DentistsSharedReducer, { loadDentists } from '../../../shared/dentists_store';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import SelectComponent from '../../../components/SelectComponent';
import moment from 'moment';
import ptBR from 'date-fns/locale/pt-BR';
import styled from 'styled-components';
import * as Yup from 'yup';
import CashAPI from '../../../service/CashAPI';
import { formatReal } from '../../../utils/Formatter';
import ToastUtils from '../../../utils/ToastUtils';

const CustomDateRange = styled(DateRange)`
  text-transform: capitalize;
  input,
  select {
    text-transform: capitalize;
  }

  .rdrDayToday .rdrDayNumber span:after {
    background-color: #753491;
  }
`;

const FinancialOverviewReportPage = (props) => {
  const comboref = useRef();
  const dispatch = useDispatch();
  const formSchemaValidate = Yup.object().shape({
    periodo: Yup.object().shape({
      value: Yup.string().nullable().required('Periodo Obrigatório')
    })
  });
  const { dentists } = useSelector(({ DentistsSharedReducer }) => DentistsSharedReducer.dentists);
  const [periodo, setPeriodo] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  });
  const [periodoOptions, setPeriodoOptions] = useState([
    { label: 'Período', value: null },
    { label: 'Este mês', value: 'm' },
    { label: 'Mês passado', value: 'p' },
    { label: 'Selecionar Período', value: 'c' }
  ]);
  const [showPeriodo, setShowPeriodo] = useState(false);
  const dentistsOptions = dentists && arrayToSelectItems(dentists, 'nome', 'id');
  const [total, setTotal] = useState(null);
  const [vouchers, setVouchers] = useState([]);

  useEffect(() => {
    dispatch(loadDentists());
  }, []);

  function buscarRecibos(values) {
    console.log(values);
    let start, end;
    dispatch(showLoader());
    const toDay = moment();
    const lastMonth = moment().subtract(1, 'month');

    switch (values.periodo.value) {
      case 'm':
        start = toDay.startOf('month').format('YYYY-MM-DD');
        end = toDay.endOf('month').format('YYYY-MM-DD');
        break;
      case 'p':
        start = lastMonth.startOf('month').format('YYYY-MM-DD');
        end = lastMonth.endOf('month').format('YYYY-MM-DD');
        break;
      case 'c':
        start = moment(periodo.startDate).format('YYYY-MM-DD');
        end = moment(periodo.endDate).format('YYYY-MM-DD');
        break;
    }

    CashAPI.findReportVouchers(values.dentistas, start, end)
      .then(({ data }) => {
        setTotal(data.total);
        setVouchers(data.vouchers);
        dispatch(hideLoader());
      })
      .catch((err) => {
        console.error(err);
        dispatch(hideLoader());
        ToastUtils.error('Erro ao buscar recibos, entre em contato com o suporte');
      });
  }

  return (
    <div className='box-default report-box-default' style={{ minHeight: '60vh' }}>
      <Row>
        <Col sm={12}>
          <strong className='title-medium-plus text-uppercase'>Relatório de Recibos Emitidos</strong>
        </Col>
      </Row>
      <Formik
        initialValues={{
          periodo: { label: 'Período', value: null }
        }}
        validationSchema={formSchemaValidate}
        onSubmit={buscarRecibos}
        validateOnChange={false}
      >
        {({ values, handleChange, errors, resetForm }) => {
          return (
            <Form>
              <Container>
                <Row>
                  <Col sm={{ size: 3 }}>
                    <div style={{ marginLeft: 0, marginTop: 10, marginBottom: 10 }}>
                      <span className='report-doctor'>
                        <span className='report-doctor-select'>
                          <MultiSelect
                            options={dentistsOptions}
                            selected={t(values.dentistas).safeArray}
                            onSelectedChanged={(selected) =>
                              reactSelectHandleChange(selected, handleChange, 'dentistas')
                            }
                            disableSearch={true}
                            overrideStrings={{
                              selectSomeItems: 'Doutor(a)',
                              allItemsAreSelected: 'Todos os dentistas',
                              selectAll: 'Selecionar Todos',
                              search: 'Buscar'
                            }}
                          />
                        </span>
                      </span>
                    </div>
                  </Col>
                  <Col sm={{ size: 3 }} className='ml-2'>
                    <div style={{ marginLeft: 0, marginTop: 10, marginBottom: 10 }}>
                      <span className='report-doctor'>
                        <span className='report-doctor-select' ref={comboref}>
                          <SelectComponent
                            name={'periodo'}
                            values={values}
                            options={periodoOptions}
                            handleChange={handleChange}
                            placeholder={'Período'}
                            callbackOnChange={(p) => setShowPeriodo(p.value === 'c')}
                            errors={errors}
                          />
                        </span>
                      </span>
                    </div>
                  </Col>
                  <Col sm={{ size: 1 }} className='ml-2'>
                    <button
                      style={{ marginTop: 9, width: 105, height: 35 }}
                      type={'submit'}
                      className='btn btn-secondary-l5 text-sm border border-secondary-l4'
                    >
                      Filtrar
                    </button>
                  </Col>
                  <Col sm={{ size: 2 }} className='ml-4'>
                    <IfComponent conditional={values.dentistas || !t(values.periodo, 'value').isNullOrUndefined}>
                      <button
                        style={{ marginTop: 9, width: 105, height: 35, marginLeft: 0 }}
                        className='btn btn-outline-restartfilter text-sm font-light'
                        type={'button'}
                        onClick={() => {
                          resetForm({
                            periodo: { label: 'Período', value: null }
                          });
                          setVouchers([]);
                          setTotal(null);
                        }}
                      >
                        Limpar Filtro
                      </button>
                    </IfComponent>
                  </Col>
                  <Col sm={{ size: 1 }}>
                    <IfComponent conditional={vouchers && vouchers.length > 0}>
                      <div>
                        <span
                          style={{ marginTop: 9, width: 50, height: 35 }}
                          className='btn btn-secondary-l5 text-sm border border-secondary-l4 offset-7'
                        >
                          <a
                            data-tip
                            data-for={'tooltipPrint'}
                            onClick={() => {
                              localStorage.setItem('overviewData', JSON.stringify({ total, vouchers, periodo }));
                            }}
                            href={'/reports/overview/print'}
                            target={'_blank'}
                            rel='noopener noreferrer'
                          >
                            <AiOutlinePrinter size={22} />
                          </a>

                          <ReactTooltip id='tooltipPrint' className='tooltip-griks' type='light'>
                            Imprimir
                          </ReactTooltip>
                        </span>
                      </div>
                    </IfComponent>
                  </Col>
                  <IfComponent conditional={total}>
                    <Col sm={{ size: 1 }}>
                      <span
                        style={{
                          marginTop: 9,
                          backgroundColor: '#f3f4f6',
                          marginRight: '-5rem',
                          width: 150,
                          height: 35,
                          borderRadius: '0.25rem'
                        }}
                        className='bg border border-secondary-l4 font-bold text-center text-sm float-right'
                      >
                        <p className='p-2 text-secondary'>
                          Total: <strong className='font-bolder'>{formatReal(total)}</strong>
                        </p>
                      </span>
                    </Col>
                  </IfComponent>
                </Row>
              </Container>
              <Table className='table-header-fixed p-2' responsive>
                <thead style={{ backgroundColor: '#ebecf2' }}>
                  <tr className='row py-2'>
                    <th className='col-sm-1 py-0'>
                      <span className='text-sm text-secondary-d4 font-weight-bold text-nowrap'>Recibo</span>
                    </th>
                    <th className='col-sm-1 py-0'>
                      <span className='text-sm text-secondary-d4 font-weight-bold text-nowrap'>Data</span>
                    </th>
                    <th className='col-sm-1 py-0'>
                      <span className='text-sm text-secondary-d4 font-weight-bold text-nowrap'>Doutor(a)</span>
                    </th>
                    <th className='col-sm-2 py-0'>
                      <span className='text-sm text-secondary-d4 font-weight-bold text-nowrap'>Paciente</span>
                    </th>
                    <th className='col-sm-2 py-0'>
                      <span className='text-sm text-secondary-d4 font-weight-bold pl-5 text-nowrap'>CPF Paciente</span>
                    </th>
                    <th className='col-sm-2 py-0'>
                      <span className='text-sm text-secondary-d4 font-weight-bold text-nowrap'>Responsável</span>
                    </th>
                    <th className='col-sm-2 py-0'>
                      <span className='text-sm text-secondary-d4 font-weight-bold pl-5 text-nowrap'>
                        CPF Responsável
                      </span>
                    </th>
                    <th className='col-sm-1 py-0'>
                      <span className='text-sm text-secondary-d4 font-weight-bold text-nowrap'>Valor R$</span>
                    </th>
                  </tr>
                </thead>
                <tbody style={{ maxHeight: 'calc(100vh - 400px)' }}>
                  {vouchers.map((v) => {
                    return (
                      <tr className={'row py-0 box-basic px-0 report-overview-result-tr'} key={v.id}>
                        <td className='col-sm-1'>
                          <div className='text-sm font-light text-secondary'>#{_.padStart(v.numeroRecibo, 4, '0')}</div>
                        </td>
                        <td className='col-sm-1'>
                          <div className='text-sm font-light text-secondary text-nowrap'>
                            {moment(v.dataEmissao).format('DD/MM/YYYY')}
                          </div>
                        </td>
                        <td className='col-sm-1'>
                          <div className='text-sm font-light text-secondary text-nowrap'>{v.dentista.nome}</div>
                        </td>
                        <td className='col-sm-2'>
                          <div className='text-sm font-light text-secondary text-nowrap'>{v.nomePaciente}</div>
                        </td>
                        <td className='col-sm-2'>
                          <div className='text-sm font-light text-secondary pl-5 text-nowrap'>{v.cpfPaciente}</div>
                        </td>
                        <td className='col-sm-2'>
                          <div className='text-sm font-light text-secondary text-nowrap'>
                            {v.tipoResponsavel === 'P' ? 'Próprio(a) Paciente' : v.nomeResponsavel}
                          </div>
                        </td>
                        <td className='col-sm-2'>
                          <div className='text-sm font-light text-secondary pl-5 text-nowrap'>{v.cpfResponsavel}</div>
                        </td>
                        <td className='col-sm-1'>
                          <div className='text-sm font-light text-secondary text-nowrap'>{formatReal(v.valor)}</div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>

              {/* !! TODO: Refatorar mudar para o componente modal_period.js */}
              <Modal isOpen={showPeriodo} toggle={() => setShowPeriodo(false)} size={'mdplus'}>
                <ModalHeader
                  className='title-primary'
                  toggle={() => setShowPeriodo(false)}
                  close={
                    <button className='close' onClick={() => setShowPeriodo(false)}>
                      &times;
                    </button>
                  }
                >
                  <strong>Selecionar Período</strong>
                </ModalHeader>
                <ModalBody style={{ textTransform: 'capitalize' }}>
                  <CustomDateRange
                    locale={ptBR}
                    onChange={(item) => {
                      setPeriodo(item.selection);
                      periodoOptions.map((p) => {
                        if (p.value === 'c') {
                          p.label = `${moment(item.selection.startDate).format('DD/MM/YYYY')} a ${moment(
                            item.selection.endDate
                          ).format('DD/MM/YYYY')}`;
                        }
                      });
                    }}
                    dateDisplayFormat={'dd/MM/yyyy'}
                    rangeColors={['#753491']}
                    editableDateInputs={true}
                    moveRangeOnFirstSelection={true}
                    months={2}
                    ranges={[periodo]}
                    direction='horizontal'
                  />
                </ModalBody>
                <ModalFooter>
                  <Row>
                    <Col sm={{ size: 12, offset: 10 }}>
                      <button
                        className='button-tertiary'
                        onClick={() => {
                          setShowPeriodo(false);
                        }}
                      >
                        Aplicar
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default FinancialOverviewReportPage;
