import t from 'typy';
import { createSelector } from 'reselect';

const diagnosticProblemsModalSelector = (state) =>
  t(state, 'TreatmentClinicalRecordReducer.diagnosticProblemsModal').safeObject;

export const propsModalSelector = createSelector(diagnosticProblemsModalSelector, (diagnosticProblemsModal = {}) => {
  const { modal, diagnostic = {}, treatment, record } = diagnosticProblemsModal;

  const problems = t(diagnostic, 'listaProblemas').isArray
    ? diagnostic.listaProblemas.map((p) => ({
        problema_diagnostico: p.problema
      }))
    : [];

  return {
    modal,
    problems,
    diagnostic,
    treatment,
    record
  };
});
