import { SETTINGS_PROCEDURES_REGISTER_PAGE } from './SettingsProcedures.action';

const initialState = {};

export function SettingsProceduresReducer(state = initialState, action) {
  switch (action.type) {
    case SETTINGS_PROCEDURES_REGISTER_PAGE:
      return {
        ...state,
        register: {
          ...action.payload
        }
      };
    default:
      return state;
  }
}

export default SettingsProceduresReducer;
