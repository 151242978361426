import React,{useEffect,useState,useImperativeHandle,forwardRef} from "react"
import {Col, Row, Modal, ModalHeader, ModalBody, ModalFooter, Input} from 'reactstrap';
//import FormikUtils from '../../../utils/FormikUtils';
import { Form, Formik } from 'formik';
import MonthPicker from '../../../components/MonthPicker';
import InputComponent from '../../../components/InputComponent';
import ErrorFieldWithoutBootstrap from '../../../components/ErrorFieldWithoutBootstrap.component';

const ScheduleControlReturnAddModal = (props,modalref) => {

    const [showModal, setShowModal] = useState(false);

    useImperativeHandle(modalref, () => ({
      openModal(){
        setShowModal(true);
      }
    }));

    function closeModal() {
        setShowModal(false);
    }

    const allPatients = props.pacientes
    for(var teste of allPatients){
      if(props.patientId == teste.id){
        var nomePaciente = teste.paciente.nome
        var tratamentoPaciente = teste.tipoRetorno.descricao
      }
    }
    
    function saveControlReturn({mes_alvo,anotacoes}) {
      console.log(nomePaciente)
      console.log(tratamentoPaciente)
      const Numbers = [1,2,3,4,5,6,7,8,9,0]
      if(mes_alvo != undefined){
        var dia = mes_alvo._i.split('/')
        parseInt(dia[0])
        if(dia[0] in Numbers){
          console.log('0'+mes_alvo._i)
        }else{
          console.log(mes_alvo._i)
        }
      }else{
        mes_alvo = props.mesAlvo
        console.log(mes_alvo)
      }
      console.log(anotacoes)
      closeModal()
    }

    return(
        <Modal isOpen={showModal} toggle={closeModal} className={'modal-small'}>
            <ModalHeader toggle={closeModal} className='m-0 title-primary' style={{paddingRight:30,paddingLeft:30,paddingTop:30}}>
                <strong>Editar na Lista CONTROLE E CONTENÇÃO</strong>
            </ModalHeader>
            <Formik
              onSubmit={saveControlReturn}
            >
              {({ values, errors, handleChange }) => {
                return(
                  <Form>
                    <ModalBody style={{paddingRight:30,paddingLeft:30}}>
                <div>
                    <Row>
                        <Col className='text-left' sm='12'>
                          <label>
                            Paciente
                          </label>
                        </Col>
                        <Col sm={12}>
                            {nomePaciente} 
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col sm={12} className={'text-left'}>
                          <label>
                            Tipo de Retorno
                          </label>
                        </Col>
                        <Col sm={12}>
                            {tratamentoPaciente}
                        </Col>
                    </Row>
                    <br />
                    
                    <Row>
                      <Col sm={12} className={'text-left'}>
                          <label>
                              Mês Alvo <span>*</span>
                          </label>
                      </Col>
                      <Col sm={5}>
                        <MonthPicker placeholder={props.mesAlvo} anoInicial={10} valueHowMoment={true} handleChange={handleChange} values={values}  />
                        <ErrorFieldWithoutBootstrap fieldName={'mes_alvo'} errors={errors} />
                      </Col>
                    </Row>  
                    <br />
                    <Row>
                        <Col sm={12} className={'text-left'}>
                            <label>Anotações</label>
                        </Col>
                        <Col sm={12}>
                            <InputComponent type={'textarea'} name={'anotacoes'} handler={handleChange} defaultValue={props.anotacoes}/>
                        </Col>
                    </Row>
                </div>
            </ModalBody>
            <ModalFooter style={{paddingRight:30,paddingLeft:30,paddingBottom:30}}>
                <Row>
                  <Col sm={{ size: 3, offset: 6 }}>
                    <button className='button-tertiary' type={'button'} onClick={() => closeModal()}>
                      Cancelar
                    </button>
                  </Col>
                  <Col sm='3'>
                    <button className='button-primary' type={'submit'}>
                      Salvar
                    </button>
                  </Col>
                </Row>
            </ModalFooter>
                  </Form>
                )
              }}
            </Formik>
        </Modal>
    )
}
export default forwardRef(ScheduleControlReturnAddModal);

/*
                            <RadioGroup horizontal className='radio-griks d-flex justify-content-center pt-2'>
                                <RadioButton iconSize={20} value='P' disabled={true} disabledColor={'var(--primary)'}>
                                    <span style={{ paddingRight: '1rem', paddingLeft: '1rem' }}>Controle Pré-tratamento</span>
                                </RadioButton>
                                <RadioButton pointColor={'var(--primary)'} iconSize={20} value='C' disabled={true}>
                                    <span style={{ paddingRight: '4rem', paddingLeft: '4rem' }}>Contenção</span>
                                </RadioButton>
                            </RadioGroup>
*/
