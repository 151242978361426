import React, { Component } from 'react';

import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown
} from 'reactstrap';

import arrow from '../../../assets/img/icons/arrow.svg';
import arrowUp from '../../../assets/img/icons/arrowup.svg';
import plus from '../../../assets/img/icons/plus.svg';
import Icon from '../../../components/Icon.component';
import menuDots from '../../../assets/img/menuDots.png';
import SettingsAPI from '../../../service/SettingsAPI';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { showLoader, hideLoader } from '../../../components/actions/Loader.actions';
import MessagesComponent from '../../../components/Messages.component';
import SettingsLibrariesRegisterModal from './modal/SettingsLibrariesRegister.modal';
import { registerModal } from './redux/SettingsLibraries.actions';
import ModalMessageConfirm from '../../../components/ModalMessageConfirm.component';
import warning from '../../../assets/img/icons/warning.svg';
import withSecurity from '../../../config/security/security';
import { IfComponent } from '../../../components/if.component';
import { PERMISSOES } from '../../../config/security/permissoes';
import ReactTooltip from 'react-tooltip';
import { IoIosHelpCircle } from 'react-icons/io';
import { t } from 'typy';
import Constants from '../../../utils/Constants';

class SettingsLibrariesHomePage extends Component {
  state = {
    libraries: [],
    message: {
      display: false,
      type: 'danger',
      text: ''
    },
    displayConfirmModal: false,
    librarySelected: {}
  };

  componentDidMount = () => {
    this.findAllLibraries();
  };

  findAllLibraries = async () => {
    const { showLoader, hideLoader } = this.props;
    showLoader();

    try {
      const libraries = await SettingsAPI.listAllLibraries();
      this.setState({
        libraries: libraries.data
      });
    } catch (e) {
      console.error(e);
      this.setState({
        message: {
          display: true,
          type: 'danger',
          text: 'Erro ao buscar bibliotecas'
        }
      });
    }

    hideLoader();
  };

  editLibraryItem = (library, libraryItem) => {
    this.props.registerModal({ modal: true, formData: { library, libraryItem } });
  };

  deleteLibraryItem = (library, libraryItem) => {
    this.setState({
      displayConfirmModal: true,
      librarySelected: { library, libraryItem }
    });
  };

  confirmDeleteLibraryItem = async () => {
    const {
      librarySelected: { library, libraryItem }
    } = this.state;
    try {
      this.props.showLoader();

      await SettingsAPI.deleteLibraryItem(library.id, libraryItem.id);

      this.closeModalConfirm();
      this.findAllLibraries();
      this.props.hideLoader();
    } catch (e) {
      this.closeModalConfirm();
      this.props.hideLoader();
      this.setState({
        message: {
          display: true,
          type: 'danger',
          text: 'Erro ao deletar biblioteca'
        }
      });
    }
  };

  closeModalConfirm = () => {
    this.setState({ displayConfirmModal: false });
  };

  _orderUP = async (library, item) => {
    this.props.showLoader();
    await SettingsAPI.orderLibrary(library, item.id, 'UP');
    this.findAllLibraries();
  };

  _orderDown = async (library, item) => {
    this.props.showLoader();
    await SettingsAPI.orderLibrary(library, item.id, 'DOWN');
    this.findAllLibraries();
  };

  render() {
    const { libraries = [], message = {}, displayConfirmModal, librarySelected } = this.state;
    const { registerModal, showLoader, hideLoader, security } = this.props;

    const settingsPerm = PERMISSOES.modules.configuracoes;

    return (
      <div className='content-box-without-color mt-4'>
        <Container>
          <MessagesComponent display={message.display} type={message.type} message={message.text} />
          <Row>
            {libraries &&
              libraries.map((l) => {
                return (
                  <Col style={{ padding: '0 10px', marginBottom: 10 }} sm='6' key={l.id}>
                    <div className='box-default w-100'>
                      <h1 className='title-medium-plus text-uppercase text-secondary'>
                        {l.nome}
                        <IfComponent
                          conditional={
                            security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar) ||
                            (security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar_restricao.id) &&
                              security.hasRestriction(
                                settingsPerm.id,
                                settingsPerm.permissoes.editar_restricao.id,
                                settingsPerm.permissoes.editar_restricao.restricoes.editar_bibliotecas
                              ))
                          }
                        >
                          <a href={'#'} onClick={() => registerModal({ modal: true, formData: { library: l } })}>
                            <Icon className='icon icon-size-small margin-left-10' image={plus} alt='plus' />
                          </a>
                        </IfComponent>
                      </h1>
                      <div className='content-overflow-default'>
                        <Table
                          responsive
                          className='table-header-fixed table-reorder table-header-medium table-body-large'
                        >
                          <thead>
                            <tr>
                              <th width='90%'>
                                {l.descricao}
                                <IfComponent conditional={l.tipo === 'A'}>
                                  <IoIosHelpCircle
                                    className='ml-1'
                                    size={16}
                                    color='#616e88'
                                    style={{ cursor: 'help' }}
                                    alt='help'
                                    id='questionDesc'
                                    data-tip
                                    data-for={'questionDesc'}
                                  />
                                  <ReactTooltip id='questionDesc' type='light'>
                                    <Container className='tooltip-leg'>
                                      <Row>
                                        <Col>
                                          <strong>LEGENDA</strong>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>A - Aço</Col>
                                      </Row>
                                      <Row>
                                        <Col>ALN - Alinhador</Col>
                                      </Row>
                                      <Row>
                                        <Col>N - NiTi</Col>
                                      </Row>
                                      <Row>
                                        <Col>NT - NiTi Termoativado</Col>
                                      </Row>
                                      <Row>
                                        <Col>CN - Copper NiTi</Col>
                                      </Row>
                                      <Row>
                                        <Col>TM - Beta Titânio (TMA)</Col>
                                      </Row>
                                      <Row>
                                        <Col>EL - Cromo Cobalto (Elgiloy)</Col>
                                      </Row>
                                      <Row>
                                        <Col>TF - Aço Trançado (Twistflex)</Col>
                                      </Row>
                                      <Row>
                                        <Col>BRD - Braided / Pentaone</Col>
                                      </Row>
                                      <Row>
                                        <Col>PAT - Placa de Acetato</Col>
                                      </Row>
                                      <Row>
                                        <Col>VR - Vivera</Col>
                                      </Row>
                                    </Container>
                                  </ReactTooltip>
                                </IfComponent>
                              </th>
                              <th width='5%'></th>
                            </tr>
                          </thead>
                          <tbody style={{ maxHeight: 320 }}>
                            {l.items.map((i) => {
                              return (
                                <tr key={i.id}>
                                  <td width='95%'>
                                    <a className='button-list' href='#' onClick={() => this._orderDown(l.id, i)}>
                                      <Icon className='icon icon-size-small' image={arrow} alt='plus' />
                                    </a>
                                    <a className='button-list' href='#' onClick={() => this._orderUP(l.id, i)}>
                                      <Icon className='icon icon-size-small' image={arrowUp} alt='plus' />
                                    </a>
                                    <span className='button-list-text'>{i.nome}</span>
                                  </td>
                                  <td width='5%'>
                                    <IfComponent
                                      conditional={
                                        security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar) ||
                                        (security.hasPermission(
                                          settingsPerm.id,
                                          settingsPerm.permissoes.editar_restricao.id
                                        ) &&
                                          security.hasRestriction(
                                            settingsPerm.id,
                                            settingsPerm.permissoes.editar_restricao.id,
                                            settingsPerm.permissoes.editar_restricao.restricoes.editar_bibliotecas
                                          ))
                                      }
                                    >
                                      <UncontrolledDropdown direction='left'>
                                        <DropdownToggle>
                                          <img src={menuDots} style={{ width: 18 }} />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <DropdownItem
                                            onClick={() => {
                                              this.editLibraryItem(l, i);
                                            }}
                                          >
                                            Editar
                                          </DropdownItem>
                                          <DropdownItem
                                            onClick={() => {
                                              this.deleteLibraryItem(l, i);
                                            }}
                                          >
                                            Excluir
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </IfComponent>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </Container>
        <SettingsLibrariesRegisterModal
          showLoader={showLoader}
          hideLoader={hideLoader}
          refreshPage={this.findAllLibraries}
        />
        <ModalMessageConfirm
          title={<strong>Confirma Exclusão ?</strong>}
          message={
            <span>
              <p>
                Confirma a Exclusão
                {t(librarySelected, 'library.id').safeNumber === Constants.BIBLIOTECA_QUEIXA_PRINCIPAL
                  ? ' da Queixa '
                  : ' do Arco '}{' '}
                <strong>{t(librarySelected, 'libraryItem.nome').safeString}</strong> ?
              </p>
            </span>
          }
          openModal={displayConfirmModal}
          icon={warning}
          close={this.closeModalConfirm}
          footer={
            <Row>
              <Col sm='3'>
                <button className='button-tertiary' onClick={this.closeModalConfirm}>
                  Não
                </button>
              </Col>
              <Col sm={{ size: 3, offset: 6 }}>
                <button className='button-primary' onClick={this.confirmDeleteLibraryItem}>
                  Sim
                </button>
              </Col>
            </Row>
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
    registerModal: (data) => dispatch(registerModal(data))
  };
};

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withSecurity);

export default enhanced(SettingsLibrariesHomePage);
