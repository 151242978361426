import React, { Component } from 'react';

import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledTooltip,
  UncontrolledDropdown
} from 'reactstrap';
import plus from '../../../assets/img/icons/plus.svg';
import help from '../../../assets/img/icons/help.svg';
import Icon from '../../../components/Icon.component';
import SettingsAPI from '../../../service/SettingsAPI';
import menuDots from '../../../assets/img/menuDots.png';
import SettingsProcedureRegisterPage from './modals/SettingsProcedureRegister.page';
import ProceduresAPI from '../../../service/ProceduresAPI';
import MessagesComponent from '../../../components/Messages.component';
import ModalMessageConfirm from '../../../components/ModalMessageConfirm.component';
import warning from '../../../assets/img/icons/warning.svg';
import { registerModal } from './redux/SettingsProcedures.action';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import withSecurity from '../../../config/security/security';
import { IfComponent } from '../../../components/if.component';
import { PERMISSOES } from '../../../config/security/permissoes';
import ClinicAPI from '../../../service/ClinicAPI';
import ScheduleAPI from '../../../service/ScheduleAPI';
import SettingsProcedureWarningStandardProcedureModal from './modals/SettingsProcedureWarningStandardProcedure.modal';
import { IoIosHelpCircle } from 'react-icons/io';

class SettingsProcedureSchedulePage extends Component {
  state = {
    groupSelected: { id: 1 },
    procedures: [],
    displayError: false,
    typeError: 'danger',
    messageError: '',
    displayConfirmModal: false,
    procedureSelected: {},
    standardProcedureModel: false
  };

  componentWillReceiveProps(nextProps, nextContext) {
    const groupSelected = nextProps.groups[0]; //nextProps.groups.find(g => g.id === 1) || { id: 1 };
    this.setState({
      groupSelected
    });
    this.groupSelect(groupSelected);
  }

  groupSelect = async (group) => {
    const { showLoader, hideLoader } = this.props;
    showLoader();

    try {
      const proceduresResponse = await SettingsAPI.getProceduresOfGroup(group.id);
      this.setState({
        groupSelected: group,
        procedures: proceduresResponse.data
      });
    } catch (e) {
      console.error(e);
    }

    hideLoader();
  };

  changeStatus = async (procedure) => {
    this.props.showLoader();

    ProceduresAPI.checkIfProcedureAssociateAnyDentist(procedure.id)
      .then(async () => {
        if (procedure.status === 'A') {
          procedure.status = 'I';
        } else {
          procedure.status = 'A';
        }
        const categoriaId = procedure.categoria.id;
        procedure.categoria_id = categoriaId;

        await SettingsAPI.saveProcedure(procedure)
          .then(() => {
            this.groupSelect(procedure.categoria);
            this.props.hideLoader();
          })
          .catch((err) => {
            console.error(err);
            this.props.hideLoader();

            this.setState({
              displayError: true,
              typeError: 'danger',
              messageError: 'Erro ao alterar status do procedimento'
            });
          });
      })
      .catch((err) => {
        this.props.hideLoader();
        console.error(err);
        this.setState({ standardProcedureModel: true });
      });
  };

  deleteProcedure = (procedure) => {
    ProceduresAPI.checkIfProcedureAssociateAnyDentist(procedure.id)
      .then(async () => {
        this.setState({
          procedureSelected: procedure,
          displayConfirmModal: true
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({ standardProcedureModel: true });
      });
  };

  closeModalConfirm = () => {
    this.setState({
      procedureSelected: {},
      displayConfirmModal: false
    });
  };

  confirmDeleteProcedure = async () => {
    const { showLoader, hideLoader } = this.props;
    const { procedureSelected: procedure } = this.state;

    showLoader();

    try {
      await ProceduresAPI.deleteProcedure(procedure.id);
      this.groupSelect(this.state.groupSelected);
    } catch (e) {
      console.error(e);
      this.setState({
        displayError: true,
        typeError: 'danger',
        messageError: 'Erro ao deletar procedimento'
      });
    }
    this.closeModalConfirm();
    hideLoader();
  };

  _disabledUpdateStatus = (p) => {
    const { security } = this.props;
    const settingsPerm = PERMISSOES.modules.configuracoes;
    if (security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar)) {
      return false;
    } else if (
      security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar_restricao.id) &&
      security.hasRestriction(
        settingsPerm.id,
        settingsPerm.permissoes.editar_restricao.id,
        settingsPerm.permissoes.editar_restricao.restricoes.editar_procedimento
      )
    ) {
      return false;
    } else {
      return true;
    }
  };

  _closeModalWarningStandardProcedure = () => {
    this.setState({ standardProcedureModel: false });
  };

  render() {
    const { groups, procedureRegisterModal, showLoader, hideLoader, security } = this.props;
    const {
      groupSelected,
      procedures,
      displayError,
      typeError,
      messageError,
      displayConfirmModal,
      procedureSelected,
      standardProcedureModel
    } = this.state;
    const settingsPerm = PERMISSOES.modules.configuracoes;

    return (
      <Container>
        <div className='box-default w-100' style={{ height: 520 }}>
          <Row>
            <Col sm={12}>
              <h2 className='text-secondary-d4 title-medium-plus text-uppercase'>Ortodontia</h2>
              <MessagesComponent display={displayError} type={typeError} message={messageError} />
            </Col>
          </Row>
          <Row>
            <Col sm='4'>
              <div>
                <Row className={'content-overflow-default'} style={{ maxHeight: 600 }}>
                  <Col sm='12'>
                    <h2 className='title-medium text-uppercase text-secondary-d4'>Grupos</h2>

                    <Table className={'table-header-fixed table-header-medium table-body-large'} responsive>
                      <thead>
                        <tr>
                          <th width='100%' colSpan='2'>
                            Nome do Grupo
                          </th>
                        </tr>
                      </thead>
                      <tbody style={{ maxHeight: 350 }}>
                        {groups.map((g) => {
                          const style = {
                            cursor: 'pointer',
                            background: groupSelected.id === g.id ? 'var(--primary-l5)' : ''
                          };

                          return (
                            <tr onClick={() => this.groupSelect(g)} style={style} className='row pb-0' key={g.id}>
                              <td className='col-sm-2' style={{ maxHeight: 20 }}>
                                <div className='group' style={{ background: g.rgb }}></div>
                              </td>
                              <td className='col-sm-10 pl-0'>
                                <p style={{ width: '100%' }}>{g.nome}</p>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col sm='8'>
              <div>
                <Row className={'content-overflow-default'} style={{ maxHeight: 600 }}>
                  <Col sm='12'>
                    <h2 className='title-medium text-uppercase text-secondary-d4' style={{ height: 18 }}>
                      Procedimentos
                      <IfComponent
                        conditional={
                          security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar) ||
                          (security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar_restricao.id) &&
                            security.hasRestriction(
                              settingsPerm.id,
                              settingsPerm.permissoes.editar_restricao.id,
                              settingsPerm.permissoes.editar_restricao.restricoes.editar_procedimento
                            ))
                        }
                      >
                        <a
                          href={'#'}
                          onClick={() =>
                            procedureRegisterModal({
                              modal: true,
                              formData: { group: groupSelected }
                            })
                          }
                        >
                          <Icon className='icon icon-size-small margin-left-10' image={plus} alt='plus' />
                        </a>
                      </IfComponent>
                    </h2>
                    <UncontrolledTooltip placement='top' target='pendWhats' className='tooltip-white'>
                      Este Procedimento depende de algum Serviço de Laboratório?
                    </UncontrolledTooltip>
                    <UncontrolledTooltip placement='top' target='onOff' className='tooltip-white'>
                      Não selecionado o procedimento deixa de ser exibido na Lista de Seleção
                    </UncontrolledTooltip>
                    <Table className={'table-header-fixed table-header-medium table-body-large'} responsive>
                      <thead>
                        <tr className={'row pb-0'}>
                          <th className={'col-sm-2'}>Codinome</th>
                          <th className={'col-sm-4'}>Procedimento</th>
                          <th className={'col-sm-2 '}>Tempo</th>
                          <th className={'col-sm-2'}>
                            Lab
                            <IoIosHelpCircle
                              size={18}
                              id='pendWhats'
                              className='ml-1'
                              color='#616e88'
                              style={{ cursor: 'help' }}
                              alt='help'
                            />
                          </th>
                          <th className={'col-sm-2'}>
                            On/Off{' '}
                            <IoIosHelpCircle
                              size={18}
                              id='onOff'
                              className='ml-1'
                              color='#616e88'
                              style={{ cursor: 'help' }}
                              alt='help'
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody style={{ maxHeight: 350 }}>
                        {procedures.map((p) => {
                          let styleLine = {};
                          if (p.status === 'I') {
                            styleLine = { opacity: 0.5 };
                          }

                          return (
                            <tr className={'row pb-0'} style={styleLine} key={p.id}>
                              <td className={'col-sm-2'}>
                                <span
                                  className='procedure-primary'
                                  style={{ color: '#000', background: p.categoria.rgb }}
                                >
                                  {p.codinome}
                                </span>
                              </td>
                              <td className={'col-sm-4'}>{p.nome}</td>
                              <td className={'col-sm-2 pr-0'}>{p.tempo} min</td>
                              <td className={'col-sm-2'}> {p.pendencia_laboratorio === 'S' ? 'Sim' : 'Não'}</td>
                              <td className={'col-sm-2 pl-4 py-0'}>
                                <input
                                  type='checkbox'
                                  style={{ marginTop: 5 }}
                                  className='form-checkbox float-left ml-2'
                                  id='check-one'
                                  disabled={this._disabledUpdateStatus(p)}
                                  checked={p.status === 'A'}
                                  onClick={() => this.changeStatus(p)}
                                />
                                {p.status === 'A' && (
                                  <IfComponent
                                    conditional={
                                      security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar) ||
                                      (security.hasPermission(
                                        settingsPerm.id,
                                        settingsPerm.permissoes.editar_restricao.id
                                      ) &&
                                        security.hasRestriction(
                                          settingsPerm.id,
                                          settingsPerm.permissoes.editar_restricao.id,
                                          settingsPerm.permissoes.editar_restricao.restricoes.editar_procedimento
                                        ))
                                    }
                                  >
                                    <UncontrolledDropdown className={'float-right'} direction='left'>
                                      <DropdownToggle>
                                        <img src={menuDots} style={{ width: 20, marginTop: 4 }} />
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem
                                          onClick={() => {
                                            procedureRegisterModal({
                                              modal: true,
                                              formData: {
                                                id: p.id,
                                                group: groupSelected,
                                                procedure: p.nome,
                                                codinome: p.codinome,
                                                time: p.tempo,
                                                lembrete: p.lembrete,
                                                pendencia: p.pendencia_laboratorio
                                              }
                                            });
                                          }}
                                        >
                                          Editar
                                        </DropdownItem>
                                        <DropdownItem onClick={() => this.deleteProcedure(p)}>Excluir</DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </IfComponent>
                                )}

                                {p.status === 'I' && (
                                  <img src={menuDots} style={{ width: 20, float: 'right', marginTop: 4 }} />
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <SettingsProcedureRegisterPage
            showLoader={showLoader}
            hideLoader={hideLoader}
            refreshList={() => this.groupSelect(groupSelected)}
          />

          <ModalMessageConfirm
            title={<strong>Confirma Exclusão?</strong>}
            message={
              <span>
                <p>Confirma a Exclusão do Procedimento {procedureSelected.nome}?</p>
              </span>
            }
            openModal={displayConfirmModal}
            icon={warning}
            close={this.closeModalConfirm}
            footer={
              <Row>
                <Col sm='3'>
                  <button className='button-tertiary' onClick={this.closeModalConfirm}>
                    Não
                  </button>
                </Col>
                <Col sm={{ size: 3, offset: 6 }}>
                  <button className='button-primary' onClick={this.confirmDeleteProcedure}>
                    Sim
                  </button>
                </Col>
              </Row>
            }
          />
          <SettingsProcedureRegisterPage
            showLoader={showLoader}
            hideLoader={hideLoader}
            refreshList={() => this.groupSelect(groupSelected)}
          />

          <ModalMessageConfirm
            title={<strong>Confirma Exclusão?</strong>}
            message={
              <span>
                <p>
                  Confirma a Exclusão do Procedimento <strong>{procedureSelected.nome}</strong>?
                </p>
              </span>
            }
            openModal={displayConfirmModal}
            icon={warning}
            close={this.closeModalConfirm}
            footer={
              <Row>
                <Col sm='3'>
                  <button className='button-tertiary' onClick={this.closeModalConfirm}>
                    Não
                  </button>
                </Col>
                <Col sm={{ size: 3, offset: 6 }}>
                  <button className='button-primary' onClick={this.confirmDeleteProcedure}>
                    Sim
                  </button>
                </Col>
              </Row>
            }
          />
        </div>
        <SettingsProcedureWarningStandardProcedureModal
          modal={standardProcedureModel}
          closeModal={this._closeModalWarningStandardProcedure}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    procedureRegisterModal: (data) => dispatch(registerModal(data))
  };
};

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withSecurity);

export default enhanced(SettingsProcedureSchedulePage);
