import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { setHeader } from '../template/redux/Content.actions';

import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip
} from 'reactstrap';
import plus from '../../assets/img/icons/plus.svg';
import Icon from '../../components/Icon.component';
import PatientAPI from '../../service/PacienteAPI';
import PatientHeaderComponent from '../patinent/components/PatientHeader.component';
import menuDots from '../../assets/img/menuDots.png';
import TreatmentAPI from '../../service/TreatmentAPI';
import TreatmentRegisterModal from './modals/TreatmentRegister.modal';
import { treatmentChangeSituationModal, treatmentRegisterModalSaga } from './redux/Treatment.action';
import { dateFormat } from '../../utils/Formatter';
import TreatmentExclusionConfirm from './modals/TreatmentConfirm.modal';
import MessagesComponent from '../../components/Messages.component';
import TreatmentStatusChangeModal from './modals/TreatmentStatusChange.modal';
import TreatmentMenuPage from './TreatmentMenuPage';
import TreatmentEvaluationPage from './evaluation/TreatmentEvaluation.page';
import { hideLoader, showLoader } from '../../components/actions/Loader.actions';
import TreatmentEvaluationApi from '../../service/TreatmentEvaluationApi';
import TreatmentEvaluationCreateModal from './evaluation/modals/TreatmentEvaluationCreate.modal';
import { treatmentEvaluationCreateModal, treatmentEvaluationPage } from './evaluation/redux/TreatmentEvaluation.action';
import TreatmentBudgetHomePage from './budget/TreatmentBudgetHome.page';
import TreatmentBudgetCreateModal from './budget/modals/TreatmentBudgetCreate.modal';
import { budgetCreateModal, budgetPageModal } from './budget/redux/TreatmentBudget.actions';
import TreatmentBudgetApi from '../../service/TreatmentBudgetApi';
import TreatmentClinicalRecordPage from './clinicalRecord/TreatmentClinicalRecord.page';
import { clinicalRecordPage } from './clinicalRecord/redux/TreatmentClinicalRecord.action';
import TreatmentClinicalRecordApi from '../../service/TreatmentClinicalRecordApi';
import TreatmentFinancialFilePage from './financialFile/TreatmentFinancialFile.page';
import { financialFileSagaCreateModal } from './financialFile/redux/FinancialFile.action';
import { PERMISSOES } from '../../config/security/permissoes';
import { IfComponent } from '../../components/if.component';
import withSecurity from '../../config/security/security';
import ToastUtils from '../../utils/ToastUtils';
import TreatmentImagesHomePage from './images/TreatmentImagesHomePage';
import TreatmentImagesApi from '../../service/TreatmentImagesApi';
import { openHomeLoadPageSaga } from './images/store/TreatmentImagesStore';

class TreatmentHomePage extends Component {
  state = {
    patientId: null,
    patient: {},
    treatments: [],
    exclusionTreatment: false,
    treatmentSelectedForDelete: {},
    treatmentSelected: null,
    treatmentTab: '',
    currentUrl: window.location.href,
    aba: '',
    message: {
      display: false,
      type: 'danger',
      text: ''
    }
  };

  componentDidUpdate() {
    const { patientId } = this.props.match.params;
    if (patientId !== this.state.patientId) {
      this.loadPage();
    }
  }

  componentDidMount = () => {
    this.loadPage();
    const { currentUrl } = this.state;
    const url = new URL(currentUrl)
    this.setState({ aba:url.hash });
  };

  loadPage = () => {
    const { patientId, treatmentId } = this.props.match.params;
    const { showLoader, hideLoader } = this.props;
    this.setState({ patientId });
    showLoader();
    PatientAPI.findById(patientId)
      .then((p) => {
        this.setState({ patient: p });
        this.findTreatments(treatmentId);
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          message: {
            display: true,
            type: 'danger',
            text: 'Erro buscar dados do paciente entre em contato com suporte.'
          }
        });
        hideLoader();
      });

    this.props.setHeader({
      title: ''
    });
  };

  findTreatments = async (treatmentId) => {
    const { patient, treatmentSelected, aba } = this.state;
    const { showLoader, hideLoader } = this.props;

    try {
      showLoader();
      const { data } = await TreatmentAPI.getAllTreatment(patient.paciente_id);
      let treatmentSelectedData = null;

      if (treatmentSelected) {
        treatmentSelectedData = data.find((t) => t.id === treatmentSelected.id);
      } else if (treatmentId) {
        treatmentSelectedData = data.find((t) => t.id === parseInt(treatmentId));
      } else if (data.length === 1) {
        treatmentSelectedData = data[0];
      }

      this.setState({
        treatments: data,
        treatmentSelected: treatmentSelectedData
      });

      switch (aba) {
        case '#A':
          this.selectTreatmentTab('A');
          break;
        case '#O':
          this.selectTreatmentTab('O');
          break;
        case '#F':
          this.selectTreatmentTab('F');
          break;
        case '#FF':
          this.selectTreatmentTab('FF');
          break;
        case '#IM':
          this.selectTreatmentTab('IM');
          break;
        default:
          if (treatmentId) {
            this.selectTreatmentTab('F');
          }
          break;
      }

    } catch (e) {
      console.log(e);
      this.setState({
        message: {
          display: true,
          type: 'danger',
          text: 'Erro buscar tratamentos entre em contato com suporte.'
        }
      });
    }
    hideLoader();
  };

  cancelExclusionTreatmentConfirm = () => this.setState({ exclusionTreatment: false });

  confirmTreatmentConfirm = async () => {
    const { showLoader, hideLoader } = this.props;
    const { treatmentSelectedForDelete } = this.state;
    showLoader();
    try {
      await TreatmentAPI.deleteTreatment(treatmentSelectedForDelete.id);
      await this.findTreatments();
    } catch (e) {
      hideLoader();
      this.setState({
        message: {
          display: true,
          type: 'danger',
          text: 'Erro ao deletar tratamento  entre em contato com suporte.'
        }
      });
    }

    this.cancelExclusionTreatmentConfirm();
  };

  exclusionTreatmentConfirm = (treatment) =>
    this.setState({
      exclusionTreatment: true,
      treatmentSelectedForDelete: treatment
    });

  selectTreatmentTab = (tab) => {
    switch (tab) {
      case 'A':
        this.preTabEvaluationProcess();
        break;
      case 'O':
        this.preTabBudgetProcess();
        break;
      case 'F':
        this.preTabClinicalRecord();
        break;
      case 'FF':
        this.preTabFinancialFile();
        break;
      case 'IM':
        this.preTabImages();
        break;
    }
    this.setState({ treatmentTab: tab });
  };

  preTabImages = async () => {
    const { treatmentSelected } = this.state;
    this.props.loadImagePage({ treatment: treatmentSelected.id });
  };

  preTabFinancialFile = () => {
    const { treatmentSelected } = this.state;
    this.props.treatmentFinancialFilePage({ treatment: treatmentSelected });
  };

  preTabEvaluationProcess = async () => {
    const { showLoader, hideLoader, treatmentEvaluationCreateModal, treatmentEvaluationPage, security } = this.props;

    showLoader();
    try {
      const { treatmentSelected } = this.state;
      // const { data, status } = await TreatmentEvaluationApi.findEvaluationByTreatmentId(treatmentSelected.id);
      // const complaint = await TreatmentEvaluationApi.findComplaintByTreatmentId(treatmentSelected.id);
      const allRequest = await Promise.all([
        TreatmentEvaluationApi.findEvaluationByTreatmentId(treatmentSelected.id),
        TreatmentEvaluationApi.findComplaintByTreatmentId(treatmentSelected.id),
        TreatmentEvaluationApi.findAngleRatingByTreatmentId(treatmentSelected.id),
        TreatmentEvaluationApi.findListIncidentsByTreatmentId(treatmentSelected.id),
        TreatmentEvaluationApi.findEvaluationResultByTreatmentId(treatmentSelected.id),
        TreatmentEvaluationApi.findEvaluationDocumentation(treatmentSelected.id),
        TreatmentEvaluationApi.findEvaluationOutrasAnotacoes(treatmentSelected.id),
        TreatmentEvaluationApi.findEvaluationAttendenceRegister(treatmentSelected.id)
      ]);

      const evaluation = allRequest[0];
      const complaint = allRequest[1];
      const angleRating = allRequest[2];
      const listIncidents = allRequest[3];
      const evaluationResult = allRequest[4];
      const evaluatioDocs = allRequest[5];
      const otherAnnotations = allRequest[6];
      const attendenceRegister = allRequest[7];

      if (evaluation.status === 204) {
        treatmentEvaluationPage({
          show: false,
          formLoad: {}
        });

        const tratamentoAvaliacoesPerm = PERMISSOES.modules.tratamento_avaliacoes;
        if (security.hasPermission(tratamentoAvaliacoesPerm.id, tratamentoAvaliacoesPerm.permissoes.editar)) {
          treatmentEvaluationCreateModal({ modal: true, formLoad: { treatment: treatmentSelected } });
        } else {
          ToastUtils.info('Avaliação não realizada');
        }
      } else {
        treatmentEvaluationPage({
          show: true,
          formLoad: {
            treatment: treatmentSelected,
            evaluation: evaluation.data,
            complaint: complaint.data,
            angleRating: angleRating.data,
            listIncidents: listIncidents.data,
            evaluationResult: evaluationResult.data,
            docs: evaluatioDocs.data,
            otherAnotations: otherAnnotations.data,
            attendenceRegister: attendenceRegister.data
          }
        });
      }
      this.setState({
        message: {
          display: false,
          type: 'danger',
          text: ''
        }
      });
    } catch (e) {
      console.log(e);
      this.setState({
        message: {
          display: true,
          type: 'danger',
          text: 'Erro ao carregar dados de avaliação entre em contato com o suporte.'
        }
      });
    }

    hideLoader();
  };

  preTabBudgetProcess = async () => {
    const { treatmentSelected } = this.state;
    const { showLoader, hideLoader, treatmentBudgetCreate, treatmentBudgetPage, security } = this.props;

    showLoader();
    try {
      const budgetData = await TreatmentBudgetApi.getBudgetByTreatment(treatmentSelected.id);
      const evaluation = await TreatmentEvaluationApi.findEvaluationResultByTreatmentId(treatmentSelected.id);

      if (budgetData.status === 204) {
        const tratamentoOrcamentosPerm = PERMISSOES.modules.tratamento_orcamentos;
        if (
          security.hasPermission(tratamentoOrcamentosPerm.id, tratamentoOrcamentosPerm.permissoes.editar) ||
          security.hasPermission(tratamentoOrcamentosPerm.id, tratamentoOrcamentosPerm.permissoes.editar_restricao.id)
        ) {
          treatmentBudgetCreate({ modal: true, formLoad: { treatment: treatmentSelected } });
        } else {
          ToastUtils.info('Nenhum Orçamento criado');
        }
        treatmentBudgetPage({ show: false, formLoad: { treatment: treatmentSelected } });
      } else {
        treatmentBudgetPage({
          show: true,
          formLoad: {
            treatment: treatmentSelected,
            evaluation: evaluation.data,
            budget: budgetData.data
          }
        });
      }

      this.setState({
        message: {
          display: false,
          type: 'danger',
          text: ''
        }
      });
    } catch (e) {
      console.log(e);
      this.setState({
        message: {
          display: true,
          type: 'danger',
          text: 'Erro ao carregar dados de orçamento entre em contato com o suporte.'
        }
      });
    }
    return hideLoader();
  };

  preTabClinicalRecord = async () => {
    const { treatmentSelected } = this.state;
    const { treatmentClinicalRecordPage, showLoader, hideLoader } = this.props;

    showLoader();
    let clinicalRecord = {};
    try {
      const clinicalRecordData = await TreatmentClinicalRecordApi.getClinicalRecordByTreatment({
        treatment: treatmentSelected.id
      });
      if (clinicalRecordData.status === 204) {
        const createdClinicalRecordData = await TreatmentClinicalRecordApi.createClinicalRecord({
          treatment: treatmentSelected.id
        });
        clinicalRecord = createdClinicalRecordData.data;
      } else {
        clinicalRecord = clinicalRecordData.data;
      }

      treatmentClinicalRecordPage({
        show: true,
        formLoad: {
          clinicalRecord,
          treatment: treatmentSelected
        }
      });

      this.setState({
        message: {
          display: false,
          type: 'danger',
          text: ''
        }
      });
    } catch (e) {
      console.log(e);
      this.setState({
        message: {
          display: true,
          type: 'danger',
          text: 'Erro ao carregar dados de ficha clinica entre em contato com o suporte.'
        }
      });
    }

    hideLoader();
  };


  render() {
    const { patient, treatments, exclusionTreatment, message, treatmentSelected, treatmentTab } = this.state;
    const { paciente_id, foto, numero_identificacao = '', nome = '', idade = '' } = patient;
    const { treatmentRegisterModal, treatmentChangeSituationModal, security } = this.props;

    const modulePermission = PERMISSOES.modules;

    return (
      <div className='content-box-without-color'>
        <Container>
          <PatientHeaderComponent
            id={paciente_id}
            history={this.props.history}
            foto={foto}
            nome={nome}
            numero_identificacao={numero_identificacao}
            idade={idade}
            botao={'TRATAMENTO'}
          />
          <Row className={'mr-0'}>
            <Col sm='12'>
              <div className='box-default'>
                <Row className='p-0'>
                  <Col sm='12'>
                    <h2 className='title-large text-uppercase font-bold mb-3'>
                      Tratamentos
                      <IfComponent
                        conditional={
                          security.hasModuleAccess(modulePermission.tratamento_avaliacoes.id) ||
                          security.hasModuleAccess(modulePermission.tratamento_ficha_financeira.id) ||
                          security.hasModuleAccess(modulePermission.tratamento_ficha_clinica.id) ||
                          security.hasModuleAccess(modulePermission.tratamento_orcamentos.id)
                        }
                      >
                        <a
                          id='addTreatment'
                          href={'#'}
                          onClick={() =>
                            treatmentRegisterModal({
                              modal: true,
                              formLoad: { patient: paciente_id }
                            })
                          }
                        >
                          <Icon className='icon icon-size-small margin-left-10' image={plus} alt='plus' />
                        </a>
                        <UncontrolledTooltip
                          className='tooltip-white text-xsm font-weight-light'
                          placement='top'
                          target='addTreatment'
                        >
                          Criar Novo Tratamento
                        </UncontrolledTooltip>
                      </IfComponent>
                    </h2>
                    <MessagesComponent display={message.display} type={message.type} message={message.text} />
                    <div className='content-overflow-default' id={'list_treatment'}>
                      <Table className='table-default mb-0' responsive>
                        <thead className='bg-secondary-l5'>
                          <tr>
                            <th>Tratamento</th>
                            <th>Especialidade</th>
                            <th>Doutor(a)</th>
                            <th>Avaliação</th>
                            <th>Orçamento</th>
                            <th>Situação do Tratamento</th>
                            <th width='2%'></th>
                            <th width='2%'></th>
                          </tr>
                        </thead>
                        <tbody>
                          {treatments.map((t, index) => {
                            return (
                              <tr key={t.id} className='pb-0'>
                                <td>{t.tratamento}</td>
                                <td>Ortodontia</td>
                                <td>{t.dentista}</td>
                                <td>{dateFormat(t.data_avaliacao, 'DD/MM/YYYY')}</td>
                                <td>{dateFormat(t.data_orcamento, 'DD/MM/YYYY')}</td>
                                <td style={{ color: t.situacao_color }}>{t.situacao}</td>
                                <td className='py-2'>
                                  <Col sm='12'>
                                    <input
                                      type='checkbox'
                                      className='form-checkbox'
                                      id={`check-${t.id}`}
                                      checked={treatmentSelected && treatmentSelected.id === t.id}
                                      onClick={() => this._selectTreatment(t)}
                                    />
                                  </Col>
                                </td>
                                <td className='mb-0 pb-0'>
                                  <UncontrolledDropdown direction='left'>
                                    <DropdownToggle>
                                      <img src={menuDots} style={{ width: 20, lineHeight: 1, marginTop: -6 }} />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      {t.meta && t.meta.hasDelete && (
                                        <DropdownItem
                                          className={'text-default text-sm text-secondary'}
                                          onClick={() => this.exclusionTreatmentConfirm(t)}
                                        >
                                          Excluir Tratamento
                                        </DropdownItem>
                                      )}
                                      <DropdownItem
                                        className={'text-default text-sm text-secondary'}
                                        onClick={() => {
                                          treatmentChangeSituationModal({ modal: true, treatment: t });
                                        }}
                                      >
                                        Editar Situação do Tratamento
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>

        <TreatmentStatusChangeModal refreshParent={this.findTreatments} />
        <TreatmentRegisterModal refreshParent={this._refreshParent} />
        <TreatmentExclusionConfirm
          cancelAction={this.cancelExclusionTreatmentConfirm}
          display={exclusionTreatment}
          confirmAction={this.confirmTreatmentConfirm}
        />

        {treatmentSelected && (
          <TreatmentMenuPage
            tabSelected={treatmentTab}
            selectedTab={this.selectTreatmentTab}
            treatment={treatmentSelected}
            pacienteId={paciente_id}
          />
        )}

        {treatmentSelected && treatmentTab === 'A' && (
          <div>
            <TreatmentEvaluationCreateModal
              updateParent={() => {
                this.findTreatments();
                this.preTabEvaluationProcess();
              }}
            />
            <TreatmentEvaluationPage
              updateParentDelete={() => {
                this.findTreatments();
                this.setState({ treatmentTab: '', treatmentSelected: null });
              }}
              updateParent={() => {
                this.findTreatments();
                this.preTabEvaluationProcess();
              }}
            />
          </div>
        )}

        {treatmentSelected && treatmentTab === 'O' && (
          <div>
            <TreatmentBudgetHomePage
              updateParent={async () => {
                this.findTreatments();
                this.preTabBudgetProcess();

                const budgetData = await TreatmentBudgetApi.getBudgetByTreatment(treatmentSelected.id);
                if (budgetData.data.data_aprovacao_orcamento) {
                  this.selectTreatmentTab('FF');
                }
              }}
              updateDeleteBudget={() => {
                this.findTreatments();
                this.setState({ treatmentTab: '', treatmentSelected: null });
              }}
            />

            <TreatmentBudgetCreateModal
              updateParent={() => {
                this.findTreatments();
                this.preTabBudgetProcess();
              }}
            />
          </div>
        )}

        {treatmentSelected && treatmentTab === 'F' && (
          <TreatmentClinicalRecordPage
            updateParent={() => {
              this.findTreatments();
              this.preTabClinicalRecord();
            }}
            deleteUpdateParent={() => {
              this.findTreatments();
              this.setState({ treatmentTab: '', treatmentSelected: null });
            }}
          />
        )}

        {treatmentSelected && treatmentTab === 'FF' && (
          <TreatmentFinancialFilePage
            updateParent={() => {
              this.findTreatments();
              this.preTabFinancialFile();
            }}
          />
        )}

        {treatmentSelected && treatmentTab === 'IM' && <TreatmentImagesHomePage />}
      </div>
    );
  }

  _selectTreatment = (t) => {
    this.setState({ treatmentTab: '', treatmentSelected: t });
    document.getElementById('list_treatment').scrollTop = 99999;
  };

  _refreshParent = async (treatment) => {
    await this.findTreatments();
    this._selectTreatment(this.state.treatments.find((t) => t.id === treatment.id));
  };
}

const mapStateToProps = (state) => {
  const { displayModalRegister } = state.PatientReducer;
  const { messageDisplay, type, message } = state.ErrorMessage;

  return {
    displayModalRegister,
    messageDisplay,
    type,
    message
  };
};

const mapDispatchToProps = (dispatch) => ({
  setHeader: (data) => dispatch(setHeader(data)),
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  treatmentRegisterModal: (data) => dispatch(treatmentRegisterModalSaga(data)),
  treatmentChangeSituationModal: (data) => dispatch(treatmentChangeSituationModal(data)),

  //Evaluation
  treatmentEvaluationCreateModal: (data) => dispatch(treatmentEvaluationCreateModal(data)),
  treatmentEvaluationPage: (data) => dispatch(treatmentEvaluationPage(data)),

  //Budget
  treatmentBudgetCreate: (data) => dispatch(budgetCreateModal(data)),
  treatmentBudgetPage: (data) => dispatch(budgetPageModal(data)),

  //ClinicalRecord
  treatmentClinicalRecordPage: (data) => dispatch(clinicalRecordPage(data)),

  // Financial File
  treatmentFinancialFilePage: (data) => dispatch(financialFileSagaCreateModal(data)),

  // Images
  loadImagePage: (data) => dispatch(openHomeLoadPageSaga(data))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withSecurity);
export default enhanced(TreatmentHomePage);