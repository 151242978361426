export const TREATMENT_EVALUATION_CREATE_MODAL = 'TREATMENT_EVALUATION_CREATE_MODAL';
export const TREATMENT_EVALUATION_PAGE = 'TREATMENT_EVALUATION_PAGE';
export const TREATMENT_EVALUATION_PRE_OPEN_MODAL_RECEPTION_AND_REGISTER =
  'TREATMENT_EVALUATION_PRE_OPEN_MODAL_RECEPTION_AND_REGISTER';
export const TREATMENT_EVALUATION_MODAL_RECEPTION_AND_REGISTER = 'TREATMENT_EVALUATION_MODAL_RECEPTION_AND_REGISTER';
export const TREATMENT_EVALUATION_PRE_OPEN_MODAL_COMPLAINT = 'TREATMENT_EVALUATION_PRE_OPEN_MODAL_COMPLAINT';
export const TREATMENT_EVALUATION_OPEN_MODAL_COMPLAINT = 'TREATMENT_EVALUATION_OPEN_MODAL_COMPLAINT';
export const TREATMENT_EVALUATION_PRE_OPEN_MODAL_ANGLE_RATING = 'TREATMENT_EVALUATION_PRE_OPEN_MODAL_ANGLE_RATING';
export const TREATMENT_EVALUATION_OPEN_MODAL_ANGLE_RATING = 'TREATMENT_EVALUATION_OPEN_MODAL_ANGLE_RATING';
export const TREATMENT_EVALUATION_OPEN_MODAL_PROBLEMS = 'TREATMENT_EVALUATION_OPEN_MODAL_PROBLEMS';
export const TREATMENT_EVALUATION_PRE_OPEN_MODAL_RESULT = 'TREATMENT_EVALUATION_PRE_OPEN_MODAL_RESULT';
export const TREATMENT_EVALUATION_OPEN_MODAL_RESULT = 'TREATMENT_EVALUATION_OPEN_MODAL_RESULT';
export const TREATMENT_EVALUATION_PRE_OPEN_MODAL_DOCS = 'TREATMENT_EVALUATION_PRE_OPEN_MODAL_DOCS';
export const TREATMENT_EVALUATION_OPEN_MODAL_DOCS = 'TREATMENT_EVALUATION_OPEN_MODAL_DOCS';
export const TREATMENT_EVALUATION_OPEN_MODAL_OTHER_ANNOTATIONS = 'TREATMENT_EVALUATION_OPEN_MODAL_OTHER_ANNOTATIONS';
export const TREATMENT_EVALUATION_OPEN_PRE_MODAL_ATTENDENCE_REGISTER =
  'TREATMENT_EVALUATION_OPEN_PRE_MODAL_ATTENDENCE_REGISTER';
export const TREATMENT_EVALUATION_OPEN_MODAL_ATTENDENCE_REGISTER =
  'TREATMENT_EVALUATION_OPEN_MODAL_ATTENDENCE_REGISTER';

export function treatmentEvaluationCreateModal({ modal, formLoad }) {
  return {
    type: TREATMENT_EVALUATION_CREATE_MODAL,
    payload: {
      modal,
      formLoad
    }
  };
}

export function treatmentEvaluationPage({ show, formLoad }) {
  return {
    type: TREATMENT_EVALUATION_PAGE,
    payload: {
      show,
      formLoad
    }
  };
}

export function treatmentEvaluationPreOpenReceptionRegister({ modal, formLoad }) {
  return {
    type: TREATMENT_EVALUATION_PRE_OPEN_MODAL_RECEPTION_AND_REGISTER,
    payload: {
      modal,
      formLoad
    }
  };
}
export function treatmentEvaluationReceptionRegister({ modal, formLoad }) {
  return {
    type: TREATMENT_EVALUATION_MODAL_RECEPTION_AND_REGISTER,
    payload: {
      modal,
      formLoad
    }
  };
}

export function treatmentEvaluationPreOpenComplaint({ modal, formLoad }) {
  return {
    type: TREATMENT_EVALUATION_PRE_OPEN_MODAL_COMPLAINT,
    payload: {
      modal,
      formLoad
    }
  };
}

export function treatmentEvaluationComplaint({ modal, formLoad }) {
  return {
    type: TREATMENT_EVALUATION_OPEN_MODAL_COMPLAINT,
    payload: {
      modal,
      formLoad
    }
  };
}

export function treatmentEvaluationPreOpenAngleRating({ modal, formLoad }) {
  return {
    type: TREATMENT_EVALUATION_PRE_OPEN_MODAL_ANGLE_RATING,
    payload: {
      modal,
      formLoad
    }
  };
}

export function treatmentEvaluationOpenAngleRating({ modal, formLoad }) {
  return {
    type: TREATMENT_EVALUATION_OPEN_MODAL_ANGLE_RATING,
    payload: {
      modal,
      formLoad
    }
  };
}

export function treatmentEvaluationOpenProblems({ modal, formLoad }) {
  return {
    type: TREATMENT_EVALUATION_OPEN_MODAL_PROBLEMS,
    payload: {
      modal,
      formLoad
    }
  };
}

export function treatmentEvaluationPreModalResult({ modal, formLoad }) {
  return {
    type: TREATMENT_EVALUATION_PRE_OPEN_MODAL_RESULT,
    payload: {
      modal,
      formLoad
    }
  };
}

export function treatmentEvaluationModalResult({ modal, formLoad }) {
  return {
    type: TREATMENT_EVALUATION_OPEN_MODAL_RESULT,
    payload: {
      modal,
      formLoad
    }
  };
}

export function treatmentEvaluationPreModalOrthoDocs({ modal, formLoad }) {
  return {
    type: TREATMENT_EVALUATION_PRE_OPEN_MODAL_DOCS,
    payload: {
      modal,
      formLoad
    }
  };
}

export function treatmentEvaluationModalOrthoDocs({ modal, formLoad }) {
  return {
    type: TREATMENT_EVALUATION_OPEN_MODAL_DOCS,
    payload: {
      modal,
      formLoad
    }
  };
}

export function treatmentEvaluationModalOtherAnnotations({ modal, formLoad }) {
  return {
    type: TREATMENT_EVALUATION_OPEN_MODAL_OTHER_ANNOTATIONS,
    payload: {
      modal,
      formLoad
    }
  };
}

export function treatmentEvaluationModalPreAttendence({ modal, formLoad }) {
  return {
    type: TREATMENT_EVALUATION_OPEN_PRE_MODAL_ATTENDENCE_REGISTER,
    payload: {
      modal,
      formLoad
    }
  };
}

export function treatmentEvaluationModalAttendence({ modal, formLoad }) {
  return {
    type: TREATMENT_EVALUATION_OPEN_MODAL_ATTENDENCE_REGISTER,
    payload: {
      modal,
      formLoad
    }
  };
}
