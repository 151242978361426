import t from 'typy';
import { createSelector } from 'reselect';

const scheduleAppointmentDetailModalSelector = (state) =>
  t(state, 'ScheduleReducer.scheduleAppointmentDetailModal').safeObject;

export const scheduleAppointmentDetailReducerToProps = createSelector(
  scheduleAppointmentDetailModalSelector,
  ({ modal, schedule = {}, scheduleStatus = [], officeHours, config } = {}) => {
    const scheduleStatusOption = scheduleStatus.map((s) => ({ label: s.descricao, value: s.id, data: s }));

    return {
      modal,
      schedule,
      scheduleStatusOption: scheduleStatusOption,
      scheduleStatus: scheduleStatusOption.find((s) => s.data.descricao === schedule.status_consulta),
      scheduleStatusLabel: schedule.status_consulta,
      scheduleStatusRGB: schedule.status_consulta_rgb,
      officeHours,
      config
    };
  }
);
