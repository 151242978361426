import React, { Component } from 'react';
import t from 'typy';
import { Col, Container, Row } from 'reactstrap';
import edit from '../../../assets/img/icons/edit.svg';
import Icon from '../../../components/Icon.component';
import down from '../../../assets/img/icons/arrow.svg';
import up from '../../../assets/img/icons/arrowup.svg';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import _ from 'lodash';
import TreatmentClincalRecordSummaryModal from './modals/TreatmentClincalRecordSummary.modal';
import TreatmentClincalRecordTimelineModal from './modals/TreatmentClincalRecordTimeline.modal';
import {
  clinicalRecordDiagnosticPreLoad,
  clinicalRecordPreAttendancies,
  clinicalRecordSummaryPreModal,
  clinicalRecordTreatmentPlanPreLoad
} from './redux/TreatmentClinicalRecord.action';
import TreatmentClinicalRecordAttendenciesPage from './TreatmentClinicalRecordAttendencies.page';
import TreatmentClinicalRecordDiagnosticPage from './TreatmentClinicalRecordDiagnostic.page';
import TreatmentClinicalRecordPlanPage from './TreatmentClinicalRecordPlan.page';
import { IfComponent } from '../../../components/if.component';
import TreatmentClinicalRecordFinalizeModal from './modals/TreatmentClinicalRecordFinalize.modal';
import TreatmentClinicalRecordContentionFinalizeModal from './modals/TreatmentClinicalRecordContentionFinalize.modal';
import { showLoader, hideLoader } from '../../../components/actions/Loader.actions';
import TreatmentClinicalRecordApi from '../../../service/TreatmentClinicalRecordApi';
import TreatmentExclusionConfirm from '../modals/TreatmentConfirm.modal';
import MessagesComponent from '../../../components/Messages.component';
import moment from 'moment';
import withSecurity from '../../../config/security/security';
import { PERMISSOES } from '../../../config/security/permissoes';
import TimelineComponent from './components/timeline.component';

class TreatmentClinicalRecordPage extends Component {
  state = {
    dropdownOpen: false,
    menu: '',
    modalFinalize: false,
    modalContentionFinalize: false,
    exclusionClinicalRecord: false,
    message: {
      display: false,
      type: 'danger',
      text: ''
    }
  };

  componentWillReceiveProps = (nextProps, nextContext) => {
    if (this.state.menu === '' && t(nextProps, 'formLoad.clinicalRecord.id').isNumber) {
      this.setState({ menu: 'RA' });
      this.preloadMenuRA(
        t(nextProps, 'formLoad.clinicalRecord').safeObject,
        t(nextProps, 'formLoad.treatment').safeObject
      );
    }
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (
      t(this.props, 'formLoad.clinicalRecord.id').safeObject !== t(nextProps, 'formLoad.clinicalRecord.id').safeObject
    ) {
      this.setState({ menu: 'RA' });
      this.preloadMenuRA(
        t(nextProps, 'formLoad.clinicalRecord').safeObject,
        t(nextProps, 'formLoad.treatment').safeObject
      );
    }
    return true;
  }

  preloadMenuRA = (clinicalRecord = {}, treatment = {}) => {
    const { attendencies } = this.props;
    attendencies({
      data: {
        treatment: treatment.id,
        record: clinicalRecord.id
      }
    });
  };

  preloadMenuDiagnostics = (clinicalRecord = {}, treatment = {}) => {
    const { diagnostics } = this.props;

    diagnostics({
      treatment: treatment.id,
      record: clinicalRecord.id
    });
  };

  preloadMenuPlan = (clinicalRecord = {}, treatment = {}) => {
    const { treatmentPlan } = this.props;
    const { diagnostics } = this.props;

    treatmentPlan({
      show: true,
      treatment: treatment.id,
      record: clinicalRecord.id
    });
  };

  changeMenu = (menu) => {
    const { formLoad: { clinicalRecord = {}, treatment = {} } = {} } = this.props;
    this.setState({ menu });

    switch (menu) {
      case 'RA':
        this.preloadMenuRA(clinicalRecord, treatment);
        break;
      case 'D':
        this.preloadMenuDiagnostics(clinicalRecord, treatment);
        break;
      case 'P':
        this.preloadMenuPlan(clinicalRecord, treatment);
        break;
    }
    return false;
  };

  openFinalizeModal = () => this.setState({ modalFinalize: true });

  closeFinalize = () => this.setState({ modalFinalize: false });

  openFinalizeContentionModal = () => this.setState({ modalContentionFinalize: true });
  closeFinalizeContentionModal = () => this.setState({ modalContentionFinalize: false });

  openCancelExclusionConfirm = () => this.setState({ exclusionClinicalRecord: true });
  cancelExclusionConfirm = () => this.setState({ exclusionClinicalRecord: false });

  confirmTreatmentClinicalRecordConfirm = async () => {
    const {
      formLoad: { clinicalRecord = {}, treatment = {} } = {},
      showLoader,
      hideLoader,
      deleteUpdateParent
    } = this.props;
    try {
      showLoader();
      this.cancelExclusionConfirm();
      await TreatmentClinicalRecordApi.delete({
        treatmentId: treatment.id,
        recordId: clinicalRecord.id
      });
      deleteUpdateParent();
    } catch (e) {
      this.setState({
        message: {
          display: true,
          type: 'danger',
          text: 'Erro ao excluir Ficha Clinica entre em contato com o suporte'
        }
      });

      setTimeout(() => {
        this.setState({
          message: {
            display: false,
            type: 'danger',
            text: ''
          }
        });
      }, 5000);
    }
    hideLoader();
  };

  render() {
    const { menu, modalFinalize, modalContentionFinalize, exclusionClinicalRecord, message } = this.state;
    const {
      show,
      formLoad: { clinicalRecord = {}, treatment = {} } = {},
      summary,
      updateParent,
      security
    } = this.props;
    const { sumario = {} } = clinicalRecord;

    const clinicalRecordPermission = PERMISSOES.modules.tratamento_ficha_clinica;
    const hasPermission =
      security.hasPermission(clinicalRecordPermission.id, clinicalRecordPermission.permissoes.editar) ||
      security.hasPermission(clinicalRecordPermission.id, clinicalRecordPermission.permissoes.editar_restricao.id);

    return (
      <Container>
        {show && (
          <div>
            <MessagesComponent display={message.display} type={message.type} message={message.text} />
            <Row className='margin-top-10'>
              <Col sm='12'>
                <div className='box-default'>
                  <Row>
                    <Col sm='12'>
                      <h2 className='title-medium-plus text-bold text-secondary text-uppercase'>
                        Sumário do Tratamento
                        <IfComponent conditional={t(treatment, 'situacao_id').safeString !== 'F'}>
                          <IfComponent conditional={hasPermission}>
                            <a
                              href={'#'}
                              onClick={() => {
                                summary({
                                  modal: true,
                                  formLoad: {
                                    summary: sumario,
                                    treatment,
                                    clinicalRecord
                                  }
                                });
                              }}
                            >
                              <Icon className='icon icon-size-small margin-left-10' image={edit} alt='edit' />
                            </a>
                          </IfComponent>
                        </IfComponent>
                      </h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm='4'>
                      <p className={'text-default text-sm mb-2'}>
                        <strong>Tipo do Tratamento / Fase</strong>
                        {t(sumario, 'tipo_tratamento.descricao').safeString}
                      </p>
                      <p className={'text-default text-sm'}>
                        <strong>Queixa Principal</strong>
                        {t(sumario, 'queixas').isArray
                          ? _.join(
                              sumario.queixas.map((q) => q.queixa.descricao),
                              ', '
                            )
                          : ''}
                      </p>
                    </Col>
                    <Col sm='4'>
                      <p className={'text-default text-sm mb-2 text-nowrap'}>
                        <strong>Plano de Tratamento</strong>
                        {sumario.plano_tratamento}
                      </p>
                      <p className={'text-default text-sm'}>
                        <strong>Tempo de Tratamento</strong>
                        {sumario.tempo_tratamento_ate ? (
                          <span>
                            {sumario.tempo_tratamento_de} a {sumario.tempo_tratamento_ate} meses
                          </span>
                        ) : (
                          <span>{sumario.tempo_tratamento_de} meses</span>
                        )}
                      </p>
                    </Col>
                    <Col sm='4'>
                      <p className={'text-default text-sm mt-4'}>
                        <strong>Atenção</strong>
                        {sumario.atencao}
                      </p>
                    </Col>
                  </Row>

                  <hr className='my-2' />

                  <Row>
                    <Col sm='3'>
                      <p className={'text-default text-sm'}>
                        <strong>Aparelho</strong>
                        {t(sumario, 'aparelho.descricao').safeString}
                      </p>
                    </Col>
                    <Col sm='3'>
                      <p className={'text-default text-sm'}>
                        <strong>Nome</strong>
                        {sumario.nome_comercial}
                      </p>
                    </Col>
                    <Col sm='3'>
                      <p className={'text-default text-sm'}>
                        <strong>Prescrição</strong>
                        {t(sumario, 'prescricao.descricao').safeString}
                      </p>
                    </Col>
                    <Col sm='3'>
                      <p className={'text-default text-sm'}>
                        <strong>Slot</strong>
                        {t(sumario, 'slot.descricao').safeString}
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <Row className='margin-top-10'>
              <Col sm='12'>
                <TimelineComponent fichaClinica={clinicalRecord} tratamento={treatment} />
              </Col>
            </Row>

            <Row className='margin-top-20'>
              <Col sm='12'>
                <nav className='nav-inline border-bottom-0'>
                  <ul>
                    <li className={menu === 'RA' ? 'active' : ''}>
                      <a href='#' onClick={() => this.changeMenu('RA')}>
                        Registro de Atendimentos
                      </a>
                    </li>
                    <li className={menu === 'D' ? 'active' : ''}>
                      <a href='#' onClick={() => this.changeMenu('D')}>
                        Diagnóstico
                      </a>
                    </li>
                    <li className={menu === 'P' ? 'active' : ''}>
                      <a
                        href={'#'}
                        onClick={(e) => {
                          e.preventDefault();
                          return this.changeMenu('P');
                        }}
                      >
                        Plano de Tratamento
                      </a>
                    </li>
                  </ul>
                </nav>
                <hr style={{ marginTop: -19, marginRight: 20 }} />
              </Col>
            </Row>

            {menu === 'RA' && (
              <TreatmentClinicalRecordAttendenciesPage
                updateParent={updateParent}
                enabledEdit={t(treatment, 'situacao_id').safeString !== 'F'}
              />
            )}

            {menu === 'D' && (
              <TreatmentClinicalRecordDiagnosticPage
                updateParent={() => {
                  this.preloadMenuDiagnostics(clinicalRecord, treatment);
                  updateParent();
                }}
                enabledEdit={t(treatment, 'situacao_id').safeString !== 'F'}
              />
            )}
            {menu === 'P' && (
              <TreatmentClinicalRecordPlanPage
                updateParent={() => {
                  this.preloadMenuPlan(clinicalRecord, treatment);
                  updateParent();
                }}
                enabledEdit={t(treatment, 'situacao_id').safeString !== 'F'}
              />
            )}

            <IfComponent
              conditional={
                t(treatment, 'situacao_id').safeString !== 'F' && t(treatment, 'situacao_id').safeString !== 'PA'
              }
            >
              <Row className='margin-top-10'>
                <Col sm={{ size: 3 }} className='btn-list text-right pr-5'>
                  <IfComponent
                    conditional={
                      security.hasPermission(clinicalRecordPermission.id, clinicalRecordPermission.permissoes.editar) ||
                      (security.hasPermission(
                        clinicalRecordPermission.id,
                        clinicalRecordPermission.permissoes.editar_restricao.id
                      ) &&
                        !security.hasRestriction(
                          clinicalRecordPermission.id,
                          clinicalRecordPermission.permissoes.editar_restricao.id,
                          clinicalRecordPermission.permissoes.editar_restricao.restricoes.nao_finalizar
                        ))
                    }
                  >
                    <button className='button-tertiary mr-5' onClick={this.openFinalizeModal}>
                      Finalizar Tratamento Ativo
                    </button>
                  </IfComponent>
                </Col>
                <Col sm={{ size: 2, offset: 7 }} className='btn-list text-right pr-4'>
                  <IfComponent
                    conditional={
                      security.hasPermission(clinicalRecordPermission.id, clinicalRecordPermission.permissoes.editar) ||
                      (security.hasPermission(
                        clinicalRecordPermission.id,
                        clinicalRecordPermission.permissoes.editar_restricao.id
                      ) &&
                        !security.hasRestriction(
                          clinicalRecordPermission.id,
                          clinicalRecordPermission.permissoes.editar_restricao.id,
                          clinicalRecordPermission.permissoes.editar_restricao.restricoes.nao_excluir
                        ))
                    }
                  >
                    <IfComponent conditional={moment().diff(moment(clinicalRecord.dataCriacao), 'days') <= 1}>
                      <button className='button-cancel' onClick={() => this.openCancelExclusionConfirm()}>
                        Excluir
                      </button>
                    </IfComponent>
                  </IfComponent>
                </Col>
              </Row>
            </IfComponent>
            <IfComponent
              conditional={
                t(clinicalRecord, 'tratamentoStatus.id').isDefined && t(treatment, 'situacao_id').safeString !== 'PA'
              }
            >
              <Row className='margin-top-10'>
                <Col sm={{ size: 3, offset: 9 }} className='btn-list text-right pr-4'>
                  <button className='button-tertiary' onClick={this.openFinalizeContentionModal}>
                    Finalizar Contenção
                  </button>
                </Col>
              </Row>
            </IfComponent>
          </div>
        )}

        <TreatmentClincalRecordSummaryModal updateParent={updateParent} />
        <TreatmentClinicalRecordFinalizeModal
          updateParent={() => {
            this.setState({ menu: null });
            updateParent();
          }}
          modal={modalFinalize}
          closeModal={this.closeFinalize}
          treatment={treatment}
          clinicalRecord={clinicalRecord.id}
          showLoader={showLoader}
          hideLoader={hideLoader}
        />
        <TreatmentClinicalRecordContentionFinalizeModal
          updateParent={updateParent}
          modal={modalContentionFinalize}
          closeModal={this.closeFinalizeContentionModal}
          treatment={treatment.id}
          clinicalRecord={clinicalRecord.id}
          showLoader={showLoader}
          hideLoader={hideLoader}
        />
        <TreatmentExclusionConfirm
          cancelAction={this.cancelExclusionConfirm}
          display={exclusionClinicalRecord}
          confirmAction={this.confirmTreatmentClinicalRecordConfirm}
          text={'Tem certeza que deseja excluir a ficha clinica?'}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { TreatmentClinicalRecordReducer: { page = {} } = {} } = state;
  return { ...page };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  summary: (data) => dispatch(clinicalRecordSummaryPreModal(data)),
  attendencies: (data) => dispatch(clinicalRecordPreAttendancies(data)),
  diagnostics: (data) => dispatch(clinicalRecordDiagnosticPreLoad(data)),
  treatmentPlan: (data) => dispatch(clinicalRecordTreatmentPlanPreLoad(data))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withSecurity);

export default enhanced(TreatmentClinicalRecordPage);
