import React, { Component } from 'react';

import { Col, Container, Row } from 'reactstrap';
import edit from '../../../assets/img/icons/edit.svg';
import Icon from '../../../components/Icon.component';
import noavatar from '../../../assets/img/noavatar.png';
import PaginationComponent from '../../../components/Pagination.component';
import PatientAPI from '../../../service/PacienteAPI';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { sendMessage } from '../../../components/actions/ErrorMessage.actions';
import { showPatientEditModal } from '../redux/patient.actions';
import withSecurity from '../../../config/security/security';
import { IfComponent } from '../../../components/if.component';
import { PERMISSOES } from '../../../config/security/permissoes';
import ReactTooltip from 'react-tooltip';


class PatientList extends Component {
  state = {
    activePage: 1,
    patients: {
      items: [],
      totalPages: 1,
      totalElements: 1
    }
  };

  componentDidMount = () => {
    this.props.showLoader();
    this.findPatients();
  };

  findNextPage = (page) => {
    this.findPatients(page);
  };

  findPatients(page = 1, totalPage = 10) {
    this.props.showLoader();

    PatientAPI.findAllPatients(page, totalPage)
      .then((d) => {
        this.props.hideLoader();

        this.setState({
          activePage: page,
          patients: {
            items: d.data,
            totalPages: d.totalPages,
            totalElements: d.totalElements
          }
        });
      })
      .catch((error) => {
        this.props.hideLoader();
        this.props.sendMessage({
          messageDisplay: true,
          type: 'danger',
          message: `Erro ao buscar pacientes (${error.message})`
        });
      });
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.props.meta.refreshPage !== nextProps.meta.refreshPage && nextProps.meta.refreshPage) {
      this.findPatients();
    }

    return true;
  };

  editPatient = (id) => {
    this.props.showLoader();
    PatientAPI.findById(id).then((data) => {
      this.props.hideLoader();
      this.props.showPatientEditModal({ display: true, patient: data });
    });
  };

  render() {
    const { patients, activePage } = this.state;
    const { selectPatientAndShowModal, security } = this.props;

    const modulePermission = PERMISSOES.modules.pacientes;

    return (
      <Container>
        <Row>
          {patients.items.map((p) => (
            <Col md='6' key={p.id}>
              {console.log('p',p)}
              <div className='link' onClick={() => selectPatientAndShowModal(p)}>
                <div className='box-search-link' style={{borderRadius:7}}>
                  <Row>
                    <Col sm='0' className='pl-2'>
                      <div className='img-user-search'>
                        <img className={'img-circle'} src={p.foto ? p.foto : noavatar} alt='usuario' />
                      </div>
                    </Col>
                    <Col sm='6'>
                      <div className='box-search-info vertical-center pl-4'>
                        <span className='title-medium'>
                          {p.nome.length > 30 ?
                            <a data-tip data-for={`name_${ p.id }`}>
                              <strong>
                                {p.nome.match(/.{1,30}/g)[0]}...
                              </strong>
                              <ReactTooltip className='tooltip-griks' id={`name_${ p.id }`} type='gray'>
                                {p.nome}
                              </ReactTooltip>
                            </a>
                            :
                            <strong>
                              {p.nome}
                            </strong>
                          }
                        </span>
                        <p>
                          {p.numeroIdentificacao && '(' + p.numeroIdentificacao + ')'} {p.idade}
                        </p>
                      </div>
                    </Col>
                    <Col sm={3} className={'mt-3 ml-4'}>
                      {p.telefones.map((t) => {
                        if (t.telefone && t.telefone.length > 2) {
                          t.telefone = `(${t.telefone.slice(0, 2)}) ${t.telefone.slice(2, t.telefone.length-4)}-${t.telefone.slice(t.telefone.length-4)}`;
                          return <div style={{padding:0}}>
                                    <strong>
                                      {t.telefone}
                                    </strong>
                                  </div>;
                        }
                      })}
                    </Col>
                    <Col sm='1' className='ml-0'>
                      <IfComponent
                        conditional={
                          security.hasPermission(modulePermission.id, modulePermission.permissoes.editar) ||
                          security.hasPermission(modulePermission.id, modulePermission.permissoes.editar_restricao.id)
                        }
                      >
                        <a
                          onClick={(event) => {
                            event.stopPropagation();
                            this.editPatient(p.id);
                          }}
                        >
                          <Icon className='icon icon-size-small icon-box-edit' image={edit} alt='edit' />
                        </a>
                      </IfComponent>
                      {/* <Link to="/contact-delete">
                          <Icon
                            className="icon icon-size-small icon-box-delete"
                            image={excluir}
                            alt="excluir"
                          />
                        </Link> */}
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        {/*<Row>*/}
        {/*  <Col sm={{ size: 2, offset: 5 }}>*/}
        {/*    <div className="pagination-primary margin-top-20">*/}
        {/*      <PaginationComponent*/}
        {/*        selectedPage={this.findNextPage}*/}
        {/*        totalPage={patients.totalPages}*/}
        {/*        activePage={activePage}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </Col>*/}
        {/*</Row>*/}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const payload = state.PatientReducer || {};
  let refreshPage = false;

  if (payload.refreshList) {
    refreshPage = true;
  }

  return {
    meta: {
      refreshPage
    }
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendMessage: (data) => dispatch(sendMessage(data)),
    showPatientEditModal: (data) => dispatch(showPatientEditModal(data))
  };
};

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withSecurity);

export default enhanced(PatientList);
