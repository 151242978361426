import React, { useRef } from 'react';
import styled from 'styled-components';
import Picker from 'react-month-picker';
import _ from 'lodash';
import moment from 'moment';
import FormikUtils from '../utils/FormikUtils';
import MonthBox from './MonthBox';
import PropTypes from 'prop-types';
// import 'react-month-picker/scss/month-picker.scss';

const CustomPiker = styled(Picker)`
  input {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
  }

  div > label {
    font-size: 20px !important;
  }

  div > i {
    font-size: 30px !important;
  }

  li {
    font-size: 16px !important;

    :hover {
      background-color: #ebe2fb !important;
    }

    &.active {
      background-color: #793491 !important;
    }
  }
`;

const MonthPicker = (props) => {
  const {
    handleChange,
    values,
    value,
    name = 'mes_alvo',
    valueHowMoment = false,
    callbackOnChange,
    anoInicial = 2,
    placeholder
  } = props;
  const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
  const pickRef = useRef();
  return (
    <CustomPiker
      ref={pickRef}
      years={_.range(parseInt(moment().format('YYYY')) - anoInicial, parseInt(moment().format('YYYY')) + 4)}
      value={value ? value : values[name] ? { year: values[name].year(), month: values[name].month() + 1 } : {}}
      lang={months}
      onChange={(ano, mes) => {
        if (!valueHowMoment) {
          const value = mes + '/' + ano;
          FormikUtils.setValueField(handleChange, name, value);
          if (callbackOnChange) callbackOnChange(value);
        } else {
          const value = moment(mes + '/' + ano, 'MM/YYYY');
          FormikUtils.setValueField(handleChange, name, value);
          if (callbackOnChange) callbackOnChange(value);
        }
      }}
    >
      <MonthBox
        name={name}
        value={values[name] && moment(values[name], 'MM/YYYY').format('MMM YYYY')}
        onClick={() => pickRef.current.show()}
        handler={handleChange}
        placeholder={placeholder}
      />
    </CustomPiker>
  );
};

MonthPicker.propTypes = {
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  value: PropTypes.string
};

export default MonthPicker;
