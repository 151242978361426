import t from 'typy';
import { createSelector } from 'reselect';

const scheduleEventModalSelector = (state) => t(state, 'ScheduleReducer.eventModal').safeObject;
const scheduleEventFormSelector = (state) => t(state, 'ScheduleReducer.eventModal.event').safeObject;

export const scheduleEventReducerToProps = createSelector(scheduleEventModalSelector, (eventModal = {}) => {
  const { modal, dentist, chair, date } = eventModal;

  return {
    modal,
    dentist,
    chair,
    date
  };
});

export const scheduleEventForm = createSelector(scheduleEventFormSelector, (event = {}) => {
  const horarioInicio = event.horario_inicio;
  const horarioFim = event.horario_fim;
  return {
    id: event.id_agendamento,
    observacao: event.observacao,
    bloquear_dia: horarioInicio === '00:01' && horarioFim === '23:59',
    inicio: horarioInicio,
    fim: horarioFim
  };
});
