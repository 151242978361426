export default class StringUtils {
  static onlyNumbers = (data) => {
    if (data) {
      return data.replaceAll('[^\\d.]', '');
    }
  };

  static leftPad = (value, totalWidth, paddingChar) => {
    var length = totalWidth - value.toString().length + 1;
    return Array(length).join(paddingChar || '0') + value;
  };

  static abbreviateString(textValue) {
    let name = textValue;

    name = name.replace('DR', '');
    // eslint-disable-next-line no-useless-escape
    name = name.replace('.', '');
    name = name.replace('Dra', '');
    // eslint-disable-next-line no-useless-escape
    name = name.replace('.', '');

    let names = name.trim().split(' ');

    if (names.length > 1) {
      return (names[0].substring(0, 1) + names[names.length - 1].substring(0, 1)).toUpperCase();
    } else {
      return names[0].substring(0, 2).toUpperCase();
    }
  }
}
