import { call, all, put } from 'redux-saga/effects';
import { showLoader, hideLoader } from '../../../../components/actions/Loader.actions';
import DomainsAPI from '../../../../service/DomainsAPI';
import { treatmentEvaluationOpenAngleRating } from '../redux/TreatmentEvaluation.action';

export function* modalAngleRating({ payload: { modal, formLoad = {} } }) {
  yield put(showLoader());

  const domains = yield all([
    call(DomainsAPI.findAllAngles),
    call(DomainsAPI.findAllTeeth),
    call(DomainsAPI.findAllSkeletalClass),
    call(DomainsAPI.findAllSkeletalPatterns)
  ]);

  formLoad.formData = {
    angles: domains[0],
    teeth: domains[1],
    skeletalClass: domains[2],
    skeletalPatterns: domains[3]
  };

  yield put(treatmentEvaluationOpenAngleRating({ modal, formLoad }));

  yield put(hideLoader());
}
