import RestTemplate from '../utils/restTemplate/rest.template';

class TreatmentAPI {
  static getTreatmentById = (treatment) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/tratamento/${treatment}`);
  };

  static getAllTreatment = (patient) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/tratamento/paciente/${patient}`);
  };

  static getAllTreatmentByDentist = (patient, dentist) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/tratamento/paciente/${patient}/dentista/${dentist}`);
  };

  static saveTreatment = (treatment) => {
    const treatmentRequest = {
      dentista_id: treatment.dentist,
      paciente_id: treatment.patient,
      especialidade: treatment.speciality,
      status: treatment.situation
    };

    const restTemplate = new RestTemplate(true);
    return restTemplate.post('/tratamento/', treatmentRequest);
  };

  static deleteTreatment = (id) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.delete(`/tratamento/${id}`);
  };

  static treatmentChangeSituation = (id, status) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.put(`/tratamento/${id}/${status}`);
  };
}

export default TreatmentAPI;
