import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Col, Row } from 'reactstrap';
import Icon from '../../../../components/Icon.component';
import edit from '../../../../assets/img/icons/edit.svg';
import down from '../../../../assets/img/icons/arrow.svg';
import up from '../../../../assets/img/icons/arrowup.svg';
import { IfComponent } from '../../../../components/if.component';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../../../components/actions/Loader.actions';
import TreatmentClinicalRecordApi from '../../../../service/TreatmentClinicalRecordApi';
import ToastUtils from '../../../../utils/ToastUtils';
import { dateFormat } from '../../../../utils/Formatter';
import moment from 'moment';
import t from 'typy';
import TreatmentClincalRecordTimelineModal from '../modals/TreatmentClincalRecordTimeline.modal';

import _ from 'lodash';

const TimelineComponent = ({ fichaClinica, tratamento }) => {
  const modalRef = useRef();
  const dispatch = useDispatch();
  const [openBox, setOpenBox] = useState(true);
  const [timeline, setTimeline] = useState({});
  const [tempoDecorrido, setTempoDecorrido] = useState(0);

  const ritimoColors = {
    UA: '#fae098',
    DA: '#f98c95',
    AD: '#90bafc'
  };

  function openBoxTimeline() {
    if (openBox) {
      findTimeline().then((data) => setTimeline(data));
    }
  }

  function update() {
    findTimeline().then((data) => setTimeline(data));
  }

  async function findTimeline() {
    try {
      dispatch(showLoader());
      const timeline = await TreatmentClinicalRecordApi.findTimeline(tratamento.id, fichaClinica.id).then(
        ({ data }) => data
      );

      dispatch(hideLoader());
      return timeline;
    } catch (err) {
      console.error(err);
      dispatch(hideLoader());
      ToastUtils.error('Erro ao buscar Timeline entre em contato com o suporte');
    }
  }

  useEffect(() => openBoxTimeline(), [openBox]);

  async function editarTimeline() {
    const timeline = await findTimeline();
    modalRef.current.showModal(fichaClinica, tratamento, timeline);
  }

  useEffect(() => {
    if (!timeline.finalTratamento && timeline.inicio) {
      const hoje = moment().date(1);
      const tempoDecorrido = hoje.diff(moment(timeline.inicio), 'months');
      setTempoDecorrido(tempoDecorrido);
    }
  }, [timeline]);

  return (
    <>
      <div className='box-default'>
        <Row>
          <Col sm='12'>
            <h2 className='title-medium-plus text-bold text-secondary text-uppercase'>
              Timeline do Tratamento
              <a href={'javascript:'} onClick={() => editarTimeline()}>
                <Icon className='icon icon-size-small margin-left-10' image={edit} alt='edit' />
              </a>
              <a style={{ marginTop: -6 }} className={'float-right'} href={'#'} onClick={() => setOpenBox(!openBox)}>
                <Icon
                  className='icon icon-size-two margin-left-10 icon-grey-rounded'
                  image={!openBox ? down : up}
                  alt='Expandir'
                />
              </a>
            </h2>
          </Col>
        </Row>

        <IfComponent conditional={openBox}>
          <Row>
            <Col sm={{ size: 2 }} className='margin-left-20'>
              <span
                className='bg font-bold text-center text-sm float-right'
                style={{
                  backgroundColor: '#f7f9fc',
                  border: '1px solid #cfd5e3',
                  borderRadius: '7px',
                  width: '220px',
                  height: '67px'
                }}
              >
                <p className='mt-2 text-xsm font-light text-secondary text-uppercase'>Início Tratamento</p>
                <p className='mt-1 text-sm font-bold text-secondary-d4'>
                  {timeline.inicio && _.capitalize(dateFormat(timeline.inicio, 'MMM YYYY'))}
                </p>
              </span>
            </Col>
            <Col sm={{ size: 2 }} className='margin-left-40'>
              <span
                className='bg font-bold text-center text-sm float-right'
                style={{
                  backgroundColor: '#f7f9fc',
                  border: '1px solid #cfd5e3',
                  borderRadius: '7px',
                  width: '220px',
                  height: '67px'
                }}
              >
                <p className='mt-2 text-xsm font-light text-secondary text-uppercase'>Previsão de Conclusão</p>
                <IfComponent conditional={timeline.previsaoConclusao}>
                  <p className='mt-1 text-sm font-bold text-secondary-d4'>
                    {timeline.previsaoConclusao && _.capitalize(dateFormat(timeline.previsaoConclusao, 'MMM YYYY'))} (
                    {timeline.tempoPrevistoTratamento}m)
                  </p>
                </IfComponent>
              </span>
            </Col>
            <Col sm={{ size: 2 }} className='margin-left-40'>
              <span
                className='bg font-bold text-center text-sm float-right'
                style={{
                  backgroundColor: '#f7f9fc',
                  border: '1px solid #cfd5e3',
                  borderRadius: '7px',
                  width: '220px',
                  height: '67px'
                }}
              >
                <p className='mt-2 text-xsm font-light text-secondary text-uppercase'>Previsão Corrigida</p>
                <p className='mt-1 text-sm font-bold text-secondary-d4'>
                  {timeline.previsaoCorrigida && _.capitalize(dateFormat(timeline.previsaoCorrigida, 'MMM YYYY'))}
                </p>
              </span>
            </Col>
            <Col sm={{ size: 2 }} className='margin-left-40'>
              <span
                className='bg font-bold text-center text-sm float-right'
                style={{
                  backgroundColor: '#f7f9fc',
                  border: '1px solid #cfd5e3',
                  borderRadius: '7px',
                  width: '220px',
                  height: '67px'
                }}
              >
                <p className='mt-2 text-xsm font-light text-secondary text-uppercase'>Ritmo do Tratamento</p>
                <IfComponent conditional={t(timeline, 'ritimo.descricao').safeString}>
                  <p className='mt-1 text-sm font-bold text-secondary-d4'>
                    {t(timeline, 'ritimo.descricao').safeString}
                  </p>
                </IfComponent>

                <IfComponent conditional={!t(timeline, 'ritimo.descricao').safeString}>
                  <p className='mt-1 text-sm font-bold text-secondary-d4'>
                    <br />
                  </p>
                </IfComponent>

                <IfComponent conditional={t(timeline, 'ritimo.id').safeString !== 'DP'}>
                  <hr
                    className='ml-2'
                    style={{
                      marginTop: '6px',
                      width: '90%',
                      border: `2px solid ${ritimoColors[`${t(timeline, 'ritimo.id').safeString}`]}`
                    }}
                  />
                </IfComponent>
              </span>
            </Col>
            <IfComponent conditional={timeline.finalTratamento}>
              <Col sm={{ size: 2 }} className='margin-left-40'>
                <span
                  className='bg font-bold text-center text-sm float-right'
                  style={{
                    backgroundColor: '#f7f9fc',
                    border: '1px solid #cfd5e3',
                    borderRadius: '7px',
                    width: '220px',
                    height: '67px'
                  }}
                >
                  <p className='mt-2 text-xsm font-light text-secondary text-uppercase'>Final do Tratamento</p>
                  <p className='mt-1 text-sm font-bold text-secondary-d4'>
                    {timeline.finalTratamento && dateFormat(timeline.finalTratamento, 'MMM YYYY')} &nbsp; (
                    {timeline.tempoFinalTratamento}m) &nbsp;
                    <span style={{ color: timeline.tempoDiferenca > 0 ? 'red' : '' }}>
                      {timeline.tempoDiferenca > 0 ? '+' : ''}
                      {timeline.tempoDiferenca}m
                    </span>
                  </p>
                </span>
              </Col>
            </IfComponent>
            <IfComponent conditional={!timeline.finalTratamento}>
              <Col sm={{ size: 2 }} className='margin-left-40'>
                <span
                  className='bg font-bold text-center text-sm float-right'
                  style={{
                    backgroundColor: '#f7f9fc',
                    border: '1px solid #cfd5e3',
                    borderRadius: '7px',
                    width: '220px',
                    height: '67px'
                  }}
                >
                  <p className='mt-2 text-xsm font-light text-secondary text-uppercase'>Tempo de Tratamento</p>
                  <IfComponent conditional={timeline.inicio}>
                    <p className='mt-1 text-sm font-bold text-secondary-d4'>
                      <span style={{ color: tempoDecorrido > timeline.tempoPrevistoTratamento > 0 ? 'red' : '' }}>
                        {tempoDecorrido} {tempoDecorrido > 1 ? 'meses ' : 'mês '}
                      </span>{' '}
                      ({timeline.tempoPrevistoTratamento}m)
                    </p>
                  </IfComponent>
                </span>
              </Col>
            </IfComponent>
          </Row>
        </IfComponent>
        <TreatmentClincalRecordTimelineModal ref={modalRef} updateParent={() => update()} />
      </div>
    </>
  );
};

export default TimelineComponent;
