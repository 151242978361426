import {
  TREATMENT_FINANCIAL_ACCOUNT_MODAL,
  TREATMENT_FINANCIAL_ENTRIES_MODAL,
  TREATMENT_FINANCIAL_FILE_CLOSED_PAYMENT_PAGE,
  TREATMENT_FINANCIAL_FILE_DEBITS_MODAL,
  TREATMENT_FINANCIAL_FILE_DEBITS_PAGE,
  TREATMENT_FINANCIAL_FILE_MONTHLY_PAYMENT_PAGE,
  TREATMENT_FINANCIAL_FILE_PAGE,
  TREATMENT_FINANCIAL_LAUNCH_OTHER_PAYMENT_MODAL,
  TREATMENT_FINANCIAL_LAUNCH_PAYMENT_MODAL
} from './FinancialFile.action';

const initialState = {};

const TreatmentFinancialFileReducer = (state = initialState, action) => {
  switch (action.type) {
    case TREATMENT_FINANCIAL_FILE_PAGE: {
      return {
        ...state,
        page: {
          ...action.payload
        }
      };
    }
    case TREATMENT_FINANCIAL_ACCOUNT_MODAL: {
      return {
        ...state,
        accountModal: {
          ...action.payload
        }
      };
    }
    case TREATMENT_FINANCIAL_FILE_CLOSED_PAYMENT_PAGE: {
      return {
        ...state,
        closedPaymentModal: {
          ...action.payload
        }
      };
    }
    case TREATMENT_FINANCIAL_FILE_MONTHLY_PAYMENT_PAGE: {
      return {
        ...state,
        monthlyPaymentModal: {
          ...action.payload
        }
      };
    }
    case TREATMENT_FINANCIAL_FILE_DEBITS_PAGE: {
      return {
        ...state,
        debitsPage: {
          ...action.payload
        }
      };
    }
    case TREATMENT_FINANCIAL_FILE_DEBITS_MODAL: {
      return {
        ...state,
        debitsModal: {
          ...action.payload
        }
      };
    }
    case TREATMENT_FINANCIAL_ENTRIES_MODAL: {
      return {
        ...state,
        entriesPage: {
          ...action.payload
        }
      };
    }

    case TREATMENT_FINANCIAL_LAUNCH_PAYMENT_MODAL: {
      return {
        ...state,
        launchPayment: {
          ...action.payload
        }
      };
    }

    case TREATMENT_FINANCIAL_LAUNCH_OTHER_PAYMENT_MODAL: {
      return {
        ...state,
        otherLaunchPayment: {
          ...action.payload
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default TreatmentFinancialFileReducer;
