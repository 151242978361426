import RestTemplate from '../utils/restTemplate/rest.template';

class UserAPI {
  static getFullData = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/usuario/full/').then((response) => response.data);
  };

  static findAllDentistas = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/usuario/dentistas/').then((response) => response.data);
  };

  static getUserAccess = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/usuario/acessos/').then((response) => response.data);
  };

  static checkPassword = (password) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate
      .post('/usuario/check/password', {
        password
      })
      .then(() => true)
      .catch(() => false);
  };

  static findUsersByClinic = ({ pageNumber = 1, pageSize = 10 }) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/usuario/', {
      pageNumber,
      pageSize
    });
  };

  static saveUser = (data) => {
    const restTemplate = new RestTemplate(true);

    if (data.id) {
      return restTemplate.put('/usuario/', data);
    } else {
      return restTemplate.post('/usuario/', data);
    }
  };

  static savePermissions = (data) => {
    const restTemplate = new RestTemplate(true);

    return restTemplate.put('/usuario/acessos', data);
  };

  static findById = (id) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/usuario/${id}`);
  };

  static isMaster = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/usuario/master');
  };

  static changeMaster = (id, password) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.put('/usuario/master', { new_user_master: id, password });
  };

  static deleteUser = (id) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.delete(`/usuario/${id}`);
  };

  static getChairs = (id) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/usuario/cadeira/dentista/${id}`);
  };
  static getChairsEnabled = (id) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/usuario/cadeira/ativas/dentista/${id}`);
  };

  static getAssistants = (id) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/usuario/dentista/${id}/auxiliares`);
  };

  static saveChairs = ({ id, nome_cadeira, auxiliar_id, status, dentist }) => {
    const restTemplate = new RestTemplate(true);

    const postValue = {
      id,
      dentista_id: dentist,
      auxiliar_id,
      nome_cadeira,
      status
    };

    return restTemplate.post('/usuario/cadeira', postValue);
  };

  static findChairById = (id) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/usuario/cadeira/${id}`);
  };

  static deleteChairById = (id) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.delete(`/usuario/cadeira/${id}`);
  };

  static changePasswordWithChangePwd = ({ senha, novaSenha }) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.put('/usuario/alterarSenha/', { senha, nova_senha: novaSenha });
  };

  static findActivesUsers = () => {
    const restTemplate = new RestTemplate(true,true);
    return restTemplate.get('/usuario/ativos');
  }
  
}

export default UserAPI;
