import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import CashHomeMenu from './CashHomeMenu';
import CashFlowPage from './CashFlow.page';
import { getGriksSessionData, getUserData } from '../../utils/localStorage/SessionStorageUtils';
import CashAPI from '../../service/CashAPI';
import { showLoader, hideLoader } from '../../components/actions/Loader.actions';

import Icon from '../../../src/components/Icon.component';


import { connect } from 'react-redux';
import { compose } from 'recompose';
import ToastUtils from '../../utils/ToastUtils';
import { IfComponent } from '../../components/if.component';
import { t } from 'typy';
import ModalMessageConfirm from '../../components/ModalMessageConfirm.component';
import warning from '../../assets/img/icons/warning.svg';
import warn from '../../assets/img/icons/warn.svg';
import moment from 'moment';
import OpenCashModal from './modals/OpenCash.modal';
import CloseCashModal from './modals/CloseCash.modal';
import { cashCloseModal, cashOpenModal, cashReport } from './redux/cash';
import { Link } from 'react-router-dom';
import withSecurity from '../../config/security/security';
import { PERMISSOES } from '../../config/security/permissoes';
import { setHeader } from '../template/redux/Content.actions';
import machine from '../../assets/img/icons/machine.svg';

class CashHomePage extends Component {

  defaultState = {
    user: {},
    cash: {},
    displayModalFechamentoCaixa: false,
    fechamentoPendente: false
  };

  state = {
    ...this.defaultState
  };

  componentDidMount() {
    let userData = getUserData();
    this.setState({ user: userData });
    this.findCash();
    this.props.setHeader();
  }

  findCash = () => {
    const { showLoader, hideLoader } = this.props;

    showLoader();
    CashAPI.getCashOpenByUser()
      .then(({ data }) => {
          let displayModalFechamento = false;
          console.log(moment().diff(moment(data.aberto_em, 'YYYY-MM-DD'), 'days'));

          if (t(data.fechado_em).isUndefined && moment().diff(moment(data.aberto_em, 'YYYY-MM-DD'), 'days') > 0) {
            displayModalFechamento = true;
          }
          this.setState({
            cash: data,
            displayModalFechamentoCaixa: displayModalFechamento,
            fechamentoPendente: displayModalFechamento
          });
          hideLoader();
        }
      ).catch(err => {
      console.error(err);
      hideLoader();
      ToastUtils.error('Erro ao buscar caixa do usuário entre em contato com o suporte');
    });
  };

  cancelModalFechamentoCaixa = () => this.setState({ displayModalFechamentoCaixa: false });

  closeCash = () => {
    const { openCashCloseModal } = this.props;

    openCashCloseModal({ modal: true, usuario: this.state.user.usuarioCadastro });

  };

  printCash = async (id) => {
    // /cash/report?id=${cash.id}
    try {
      const { data } = await CashAPI.getCashById({ id });
      const griks = await getGriksSessionData();

      localStorage
        .setItem('report', JSON.stringify({ data, griks }));

      window.open('/cash/report');

    } catch(err){
      console.error(err);
      hideLoader();
      ToastUtils.error('Erro ao buscar caixa do usuário entre em contato com o suporte');
    }

  }

  render() {

    const { user, cash, displayModalFechamentoCaixa, fechamentoPendente } = this.state;
    const { openCashOpenModal, security } = this.props;
    console.log(user);
    const cashPerm = PERMISSOES.modules.caixa;

    const cashHasPermission = security.hasPermission(cashPerm.id, cashPerm.permissoes.operar_caixa);

    return [
      <div className="content-box-without-color" key={ 'home' }>

        <Container>

          <CashHomeMenu/>

          <Row className="margin-top-10 mb-1 pl-4">
            <Col sm="4">
              <span className={'text-default title-large'}>
                <strong className={'title-large font-bold'}>Caixa de</strong><span className={'font-light'}>{ user.usuarioCadastro }</span>
              </span>
              
            </Col>
            <Col sm="4">
              <IfComponent conditional={ cash && t(cash.fechado_em).isUndefined }>
                <IfComponent conditional={ cashHasPermission }>
                  <IfComponent conditional={ !fechamentoPendente }>
                    <div className="text-center" style={{marginTop: -5}}>
                      <button className="button-green border-0 title-medium-plus text-uppercase" onClick={ this.toggle }>Caixa Aberto</button>
                    </div>
                  </IfComponent>
                </IfComponent>
              </IfComponent>
              <IfComponent conditional={ fechamentoPendente }>
                <div  className="text-default alert alert-warn" onClick={ this.toggle }> 
                  <Icon className="icon icon-size-half mr-2" image={ warn } alt="warn"/>
                  <span className="text-uppercase">Caixa Aberto -&nbsp;</span> Fechamento Pendente
                </div>
              </IfComponent>
              <IfComponent conditional={ !cash || t(cash.fechado_em).isDefined }>
                <IfComponent conditional={ cashHasPermission }>
                  <div className="text-center" style={{marginTop: -5}}>
                    <button className="button-caixa-close border-0 title-medium-plus text-uppercase" onClick={ this.toggle }>Caixa Fechado</button>
                  </div>
                </IfComponent>
              </IfComponent>
            </Col>

            <IfComponent conditional={ !cash || t(cash.fechado_em).isDefined }>
              <Col className="btn-list" sm={ { size: 2, offset: 2 } }>
                <IfComponent conditional={ cashHasPermission }>
                  <button style={{marginTop: -5}} className="button-green button-caixa-open"
                          onClick={ () => openCashOpenModal({ modal: true, usuario: user.usuarioCadastro }) }>Abrir Caixa
                  </button>
                </IfComponent>
              </Col>
            </IfComponent>
            <IfComponent conditional={ cash && t(cash.fechado_em).isUndefined }>
              <Col className="btn-list" sm={{size: 2, offset: 2}}>
                <IfComponent conditional={ cashHasPermission }>
                  <button style={{marginTop: -5}} className="button-caixa-close" onClick={ this.closeCash }>Fechar Caixa</button>
                </IfComponent>
              </Col>
            </IfComponent>
          </Row>

          <CashFlowPage cash={ cash }/>

          <IfComponent conditional={ cash && !t(cash.fechado_em).isUndefined }>
            <Row className="margin-top-20">
              <Col className="btn-list" sm={ { size: 2, offset: 10 } }>
                <IfComponent conditional={ cashHasPermission }>
                  <button className={'button-tertiary'} onClick={() => this.printCash(cash.id)}>
                    {/*<Link to={`/cash/report?id=${cash.id}`} target={'_blank'} className={'button-tertiary'}>*/}
                    Imprimir
                  </button>
                </IfComponent>
              </Col>
            </Row>
          </IfComponent>

          <ModalMessageConfirm key={ 'modalConfirm' }
                               title={ <strong>Caixa Pendente de Fechamento</strong> }
                               message={
                                 <span>
                                  <p>Não é possível fazer lançamento neste Caixa, que é de um dia anterior. Feche este Caixa, para poder reabrir.</p>
                                </span>
                               }
                               openModal={ displayModalFechamentoCaixa }
                               icon={ warning }
                               close={ this.cancelModalFechamentoCaixa }
                               footer={
                                 <Row>
                                   <Col className="btn-list" sm="4">
                                     <button
                                       className="button-tertiary"
                                       onClick={ this.cancelModalFechamentoCaixa }
                                     >
                                       Cancelar
                                     </button>
                                   </Col>
                                   <Col className="btn-list" sm={ { size: 4, offset: 4 } }>
                                     <button className="button-primary" onClick={ this.closeCash }>
                                       Fechar o Caixa
                                     </button>
                                   </Col>
                                 </Row>
                               }
          />,

        </Container>
      </div>,
      <OpenCashModal key={ 'OpenCashModal' } updateParent={ this.findCash }/>,
      <CloseCashModal key={ 'CloseCashModal' } updateParent={ () => {
        this.setState({ displayModalFechamentoCaixa: false, fechamentoPendente: false });
        this.findCash();
      } }/>
    ]
      ;
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  setHeader: data => dispatch(setHeader(data)),
  openCashCloseModal: (data) => dispatch(cashCloseModal(data)),
  openCashOpenModal: (data) => dispatch(cashOpenModal(data)),
  cashReport: (data) => dispatch(cashReport(data))
});

const enhanced = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withSecurity
);

export default enhanced(CashHomePage);