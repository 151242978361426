import _ from 'lodash';

export default class DoubleClickInstance {
  onClicked = (action) => {
    // Array of debounced click events
    this.debouncedClickEvents = this.debouncedClickEvents || [];

    // Each click we store a debounce (a future execution scheduled in 250 milliseconds)
    const callback = _.debounce((_) => {
      action();
      this.debouncedClickEvents = [];
    }, 200);
    this.debouncedClickEvents.push(callback);

    // We call the callback, which has been debounced (delayed)
    callback();
  };

  onDoubleClicked = (action) => {
    // If there were click events registered we cancel them
    if (this.debouncedClickEvents.length > 0) {
      _.map(this.debouncedClickEvents, (debounce) => debounce.cancel());
      this.debouncedClickEvents = [];
    }
    action();
  };
}
