import React,{useEffect,useState,useImperativeHandle,forwardRef} from "react"
import { Button, ButtonGroup, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { hideLoader,showLoader } from "../../../../components/actions/Loader.actions";
import ScheduleAPI from '../../../../service/ScheduleAPI';

//import { connect } from 'react-redux';
//import { compose } from 'recompose';
//import { standardIntervalModal } from '../redux/ScheduleSettings.actions';
//import MessagesComponent from '../../../../components/Messages.component';

const SettingsScheduleStandardIntervalModal = (props,modalref) => {
  const [showModal, setShowModal] = useState(false);

  const [initForm, setInitForm] = useState(1);
  const [dentist, setDentist] = useState(1)
  const [interval, setInterval] = useState();
  const [intervalType, setIntervalType] = useState('S');
  const [message, setMessage] = useState('');
  const [messsageDisplay, setMessageDisplay] = useState(false);

  function closeModal() {
    setShowModal(false);
  }
  
  useImperativeHandle(modalref, () => ({
    async openModal(){
      setShowModal(true);
      setInterval(props.intervalo)
    }
  }));

  const saveStandardInterval=()=>{
    showLoader();
    try {
      ScheduleAPI.saveStandardInterval({id:props.id, dentist:props.idDentista, interval, intervalType});
      props.refreshPage();
      props.refreshPage()
      closeModal();
    
    } catch (e) {
      console.error(e);
      setMessageDisplay(true)
      setMessage('Erro ao salvar Intervalo Padrão')
    }
    hideLoader();
  }

  function semValor(){
    setInterval(0)
  }

  function Valor(){
    setInterval(4)
  }

  console.log('interval',interval)

  return(
    <Container>
      <Modal isOpen={showModal} toggle={closeModal}>
        <ModalHeader toggle={closeModal} style={{paddingRight:30,paddingLeft:30,paddingTop:30}}>
          <strong>Editar Intervalo Padrão de Agendamento</strong>
        </ModalHeader>
        
        <ModalBody style={{marginTop:10,paddingRight:30,paddingLeft:30}}>
          <Row>
            <Col sm='5' className="pl-0">
              <label>
                Intervalo<span>*</span>
              </label>
            </Col>
            <Col
              className='btn-group pl-0'
              sm={{ size: 12, offset: 0 }}
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                textAlign: 'center',
                margin: '0px 10px 10px 0px'
              }}
            >
              <button
                type={'button'}
                className={`btn btn-default-defaultInterval${(interval == 0) ? '-ativo' : ''} text-secondary font-light text-sm text-center`}
                style={{
                  backgroundColor:'forma_pagamento.value',
                  borderRadius: '5px',
                  width: '140px',
                  height: '43px',
                  margin: 3,
                  marginRight: 12,
                  marginLeft: 0
                }}
                onClick={semValor}
              >
                Sem Intervalo Fixo
              </button>

              <button
                type={'button'}
                className={`btn btn-default-defaultInterval${(interval == 4) ? '-ativo' : ''} text-secondary font-light text-sm text-center`}
                style={{
                  backgroundColor:'forma_pagamento.value',
                  borderRadius: '5px',
                  width: '140px',
                  height: '43px',
                  margin: 3,
                  marginLeft: 12,
                  marginRight: 0
                }}
                onClick={Valor}
              >
                Intervalo de 4 Semanas
              </button>
            </Col>
          </Row>
        </ModalBody>
        
        <ModalFooter style={{paddingRight:30,paddingLeft:30,paddingBottom:30}}>
          <Row>
            <Col sm={{ size: 3, offset: 6 }} style={{marginLeft:205,marginRight:10}}>
              <button className='button-tertiary' onClick={closeModal} >
                Cancelar
              </button>
            </Col>
            <Col sm='3'>
              <button className='button-primary' style={{background:'#7041b9',border:'1px solid #7041b9'}} onClick={saveStandardInterval}>
                Salvar
              </button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </Container> 
  )
}
export default forwardRef(SettingsScheduleStandardIntervalModal);
