import RestTemplate from '../utils/restTemplate/rest.template';
import moment from 'moment';

export default class ScheduleAPI {
  static officeHours = (dentist) => {
    const restTemplate = new RestTemplate(true);

    return restTemplate.get(`/agenda/configuracao/horarioAtendimento/dentista/${dentist}`);
  };

  static saveOfficeHours = ({ dentist, data }) => {
    const request = data;

    const restTemplate = new RestTemplate(true);
    return restTemplate.post(`/agenda/configuracao/horarioAtendimento/dentista/${dentist}`, request);
  };

  static config = (dentist) => {
    const restTempalte = new RestTemplate(true);
    return restTempalte.get(`/agenda/configuracao/dentista/${dentist}`);
  };

  static configControlReturn = (dentist) => {
    const restTempalte = new RestTemplate(true);
    return restTempalte.get(`/agenda/configuracao/dentista/${dentist}/controle-retorno`);
  };

  static saveAttendenceInterval = ({ dentist, data }) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post(`/agenda/configuracao/dentista/${dentist}/intervaloAtendimento`, data);
  };

  static saveStandardInterval = ({ id, dentist, interval, intervalType }) => {
    const request = {
      id,
      intervalo_padrao: interval,
      intervalo_padrao_tipo: intervalType
    };

    const restTempalte = new RestTemplate(true);

    let promise = null;

    if (id) {
      promise = restTempalte.put(`/agenda/configuracao/dentista/${dentist}/intervaloPadrao`, request);
    } else {
      promise = restTempalte.post(`/agenda/configuracao/dentista/${dentist}/intervaloPadrao`, request);
    }

    return promise;
  };

  static saveStandardProcedure = ({ dentista, id, procedimento_id }) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post(`/agenda/configuracao/dentista/${dentista}/procedimentoPadrao`, { id, procedimento_id });
  };

  static findScheduleDay({ dentist, date }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/agenda/dentista/${dentist}/${date}`);
  }

  static updateScheduleTime({ dentist, schedule, time, chair }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post(`/agenda/dentista/${dentist}/consulta/${schedule}`, {
      cadeira: chair,
      horario_inicio: time.start,
      horario_fim: time.end
    });
  }

  static saveEvent({ id, chair, date, desc, start, end }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post('/agenda/evento', {
      id,
      cadeira: chair,
      data_evento: date,
      compromisso: desc,
      horario_inicio: start,
      horario_fim: end
    });
  }

  static deleteEvent(id) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.delete(`/agenda/evento/${id}`);
  }

  static findNextSchedule({ paciente }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/agenda/consulta/${paciente}/proxima`);
  }

  static saveSchedule({
    id,
    chair,
    patient,
    date,
    start,
    end,
    procedure,
    pendence,
    annotation,
    pendenceAnnotation,
    treatment,
    controleRetorno
  }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post('/agenda/consulta', {
      id,
      cadeira: chair,
      paciente: patient,
      data_consulta: date,
      horario_inicio: start,
      horario_fim: end,
      procedimento: procedure,
      pendencia_laboratorio: pendence,
      compromisso: annotation,
      descricao_pendencia_laboratorio: pendenceAnnotation,
      tratamento: treatment,
      controleRetorno
    });
  }

  static getSchedule(id) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/agenda/consulta/${id}?data=${moment().format()}`);
  }

  static changeStatusSchedule({ consulta, status }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.put(`/agenda/${consulta}/status/${status}`);
  }

  static appointmentRedial({ id, date, start, end, redialBy, chair }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.put(`/agenda/consulta/${id}/remarcar`, {
      remarcado_por: redialBy
    });
  }

  static uncheckAppointment({ id, uncheckBy, procedure, comments }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.put(`/agenda/consulta/${id}/desmarcar`, {
      desmarcado_por: uncheckBy,
      procedimento: procedure,
      observacao: comments
    });
  }

  static appointmentMissing({ id, comments }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.put(`/agenda/consulta/${id}/falta`, {
      observacao: comments
    });
  }

  static appointmentDelete({ id }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.delete(`/agenda/${id}`);
  }

  static sendToAppointmentAndRedial({ id, procedure, reason, obs }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.put(`/agenda/${id}/agendar-reagendar`, {
      motivo: reason,
      procedimento: procedure,
      observacao: obs
    });
  }

  static findAppointmentHistory({ patient }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/agenda/paciente/${patient}/historico`);
  }

  static findAppointmentsForConfirmations({ dentist, data }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/agenda/dentista/${dentist}/consultas/agendadas/${data}`);
  }

  static changeStatusConfirmAppointment({ dentist, consulta, statusConfirmacao }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.put(`/agenda/dentista/${dentist}/consultas/agendadas/${consulta}/${statusConfirmacao}`);
  }

  static getScheduleRedial({ dentist }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/agenda/dentista/${dentist}/agendar-reagendar`);
  }

  static removeScheduleRedialList({ dentist, id }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.delete(`/agenda/dentista/${dentist}/agendar-reagendar/${id}`);
  }

  static addScheduleRedialList({ paciente, procedimento, dentista, observacao, motivo }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post('/agenda/agendar-reagendar/', {
      paciente,
      procedimento,
      dentista,
      observacao,
      motivo
    });
  }

  static allowSchedule({ scheduleId, chair, date, start, end }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.patch('/agenda/consulta/allow', {
      id: scheduleId,
      cadeira: chair,
      data_consulta: date.format('YYYY-MM-DD'),
      horario_inicio: start,
      horario_fim: end
    });
  }

  static saveProcedureForControlReturn(dentist, proceduresList) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post(
      `/agenda/configuracao/dentista/${dentist}/controle-retorno`,
      proceduresList.map((l) => ({
        procedimentoId: l.procedimento,
        tipoControleRetorno: l.tipoControleRetorno
      }))
    );
  }
}
