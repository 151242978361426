import React, { Component } from 'react';
import {
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Tooltip,
  UncontrolledTooltip
} from 'reactstrap';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import Select from 'react-select';
import { reactSelectHandleChange, reactSelectStyleIsError } from '../../../../utils/ReactSelectUtils';
import { selectStyles } from '../../../../../src/config/core.config';

import ReactTooltip from 'react-tooltip';

import Slider from 'rc-slider';

import 'rc-slider/assets/index.css';

import returnicon from '../../../../assets/img/icons/returnicon.svg';
import Icon from '../../../../components/Icon.component';
import { budgetModalTreatmentPaymentMantenanceModal } from '../redux/TreatmentBudget.actions';
import { hideLoader, showLoader } from '../../../../components/actions/Loader.actions';
import MessagesComponent from './TreatmentBudgeTretamentPlan.modal';
import { Form, Formik } from 'formik';
import { formatFloat } from '../../../../utils/Formatter';
import TreatmentBudgetApi from '../../../../service/TreatmentBudgetApi';
import InputDecimalComponent from '../../../../components/InputDecimalComponent';
import { t } from 'typy';
import ErrorFieldWithoutBootstrap from '../../../../components/ErrorFieldWithoutBootstrap.component';
import { IfComponent } from '../../../../components/if.component';
import * as Yup from 'yup';

class TreatmentBudgetPaymentMantenanceModal extends Component {
  state = {
    tooltipCents: false,
    error: {
      display: false,
      type: 'danger',
      message: ''
    },
    showInstallment: false
  };

  validationFormSchema = Yup.object().shape({
    quantidadeEstimada: Yup.string().required('Quantidade estimada Obrigatória')
  });

  _values = {};

  handleChange;

  closeModal = () => {
    const { closeModal } = this.props;

    this.setState({
      error: {
        display: false,
        type: 'danger',
        message: ''
      }
    });

    closeModal();
  };

  savePaymentMantenance = async (f, actions) => {
    const { showLoader, hideLoader, updateParent } = this.props;
    const { showInstallment } = this.state;

    if ((showInstallment && !f.parcelado) || f.parcelado === '0') {
      actions.setFieldError('parcelado', 'Campo Obrigatório caso seja pagamento a vista por favor preencha com 1');
    } else {
      if (!f.parcelado || f.parcelado === 0) {
        f.entrada = 0;
        f.parcelado = 0;
        f.parcela = 0;
        this.atualizarParcela();
      }

      showLoader();
      try {
        await TreatmentBudgetApi.savePaymentMantenance({
          orcamento: f.orcamento,
          idPagamento: f.idPagamento,
          id_mensalidade: f.id_mensalidade,
          tipo_aparelho: f.aparelho && f.aparelho.data && f.aparelho.data.id,
          valor_entrada: parseFloat(f.entrada),
          valor_total_entrada: parseFloat(f.valor),
          valor_desconto: parseFloat(f.desconto),
          parcelado_em: f.parcelado,
          dia_vencimento: f.diaVencimentoEntrada,
          valor_mensalidade: parseFloat(f.mensalidade),
          valor_desconto_mensalidade: parseFloat(f.descontoMensalidade),
          quantidade_estimada: f.quantidadeEstimada,
          dia_vencimento_mensalidade: f.mensalidadeDiaVencimento,
          valor_parcela_entrada: parseFloat(f.parcela)
        });
        updateParent();
        hideLoader();
        this.closeModal();
      } catch (e) {
        console.log(e);
        this.setState({
          error: {
            display: true,
            type: 'danger',
            message: 'Erro ao salvar Mensalidade / Manutenção'
          }
        });
      }
      hideLoader();
    }
  };

  atualizarParcela = () => {
    const valor = parseFloat(this._values.valor) || 0;
    const entrada = parseFloat(this._values.entrada) || 0;
    const desconto = parseFloat(this._values.desconto) || 0;
    const parcelado = this._values.parcelado || 0;

    let valorParcela = (valor - entrada - desconto) / parcelado;

    if (valorParcela === Infinity) {
      valorParcela = 0;
    }

    this.handleChange({
      target: {
        name: 'parcela',
        value: valorParcela
      }
    });
  };

  roundedInput = (values) => {
    this.handleChange({
      target: {
        name: 'parcela',
        value: Math.ceil(values.parcela)
      }
    });
  };

  selectProcedure = (handleChange, selectedOption) => {
    const { data } = selectedOption;
    console.log(data);
    handleChange({
      target: {
        name: 'aparelho',
        value: selectedOption
      }
    });
    handleChange({
      target: {
        name: 'valor',
        value: data.valorEntrada
      }
    });
    handleChange({
      target: {
        name: 'mensalidade',
        value: data.valorMensalidade
      }
    });
  };

  render() {
    const { tooltipCents, error } = this.state;
    const { modal, formLoad = {}, formInit } = this.props;
    const { procedures = [] } = formLoad;
    return (
      <Modal isOpen={modal} toggle={this.closeModal} className={this.props.className} size={'default'}>
        <ModalHeader
          className='title-primary'
          toggle={this.closeModal}
          close={
            <button className='close' onClick={this.closeModal}>
              &times;
            </button>
          }
        >
          <strong>Orçamento Mensalidade / Manutenção</strong>
        </ModalHeader>
        <Formik
          validationSchema={this.validationFormSchema}
          validateOnChange={false}
          initialValues={formInit}
          onSubmit={this.savePaymentMantenance}
        >
          {({ values, errors, handleChange }) => {
            this.handleChange = handleChange;
            this._values = values;

            return (
              <Form>
                <ModalBody>
                  <MessagesComponent display={error.display} type={error.type} message={error.message} />
                  <div>
                    <Row>
                      <Col sm='12'>
                        <h1 className='title-modal'>Entrada / Aparelho</h1>
                      </Col>
                    </Row>

                    <Row className='margin-top-10 pb-0'>
                      <Col sm='12'>
                        <label>
                          Aparelho - Tabela de Preços<span> *</span>
                        </label>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <Select
                          classNamePrefix='select-griks'
                          id={'aparelho'}
                          value={values.aparelho}
                          options={procedures.map((v) => ({ value: v.id, label: v.aparelho, data: v }))}
                          className={errors.aparelho ? 'is-invalid' : ''}
                          isSearchable={true}
                          ignoreAccents={true}
                          ignoreCase={true}
                          placeholder={''}
                          styles={reactSelectStyleIsError(errors.aparelho, 'aparelho', selectStyles)}
                          onChange={(selectedOption) => {
                            this.selectProcedure(handleChange, selectedOption);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm='6'>
                        <label>
                          Valor <span> *</span>
                        </label>
                        <InputGroup className={'reversed'}>
                          <InputDecimalComponent
                            name={'valor'}
                            value={values.valor}
                            handleChange={handleChange}
                            valueChange={() => this.atualizarParcela()}
                          />
                          <InputGroupAddon addonType='prepend'>R$</InputGroupAddon>
                        </InputGroup>
                      </Col>
                      <Col sm='6'>
                        <label>Desconto</label>
                        <InputGroup className={'reversed'}>
                          <InputDecimalComponent
                            name={'desconto'}
                            value={values.desconto}
                            handleChange={handleChange}
                            valueChange={() => this.atualizarParcela()}
                          />
                          <InputGroupAddon addonType='prepend'>R$</InputGroupAddon>
                        </InputGroup>
                      </Col>
                    </Row>

                    <Row className='margin-top-10 pb-0'>
                      <Col sm='12'>
                        <label>Valor com Desconto</label>
                      </Col>
                      <Col sm={6}>
                        <InputGroup className={'reversed'}>
                          <InputDecimalComponent
                            readOnly={true}
                            name={'valorComDesconto'}
                            value={(values.valor || 0) - (values.desconto || 0) || ''}
                            handleChange={handleChange}
                            fixedDecimalScale={2}
                          />
                          <InputGroupAddon addonType='prepend'>R$</InputGroupAddon>
                        </InputGroup>
                      </Col>
                      <Col sm={6}>
                        <input
                          type={'checkbox'}
                          className='form-checkbox'
                          style={{ marginTop: 11 }}
                          id='check-one'
                          checked={values.parcelado > 0}
                          onClick={async (e) => {
                            await handleChange({
                              target: {
                                name: 'parcelado',
                                value: e.target.checked ? 1 : 0
                              }
                            });
                            this.atualizarParcela();
                          }}
                        />{' '}
                        Parcelar
                      </Col>
                    </Row>
                    <IfComponent conditional={values.parcelado > 0}>
                      <Row className='margin-top-10'>
                        <Col sm='4'>
                          <label>Entrada</label>
                          <InputGroup style={{ width: 'calc(100% + 29px)' }} className={'reversed'}>
                            <InputDecimalComponent
                              name={'entrada'}
                              value={values.entrada}
                              handleChange={handleChange}
                              valueChange={() => this.atualizarParcela()}
                            />
                            <InputGroupAddon addonType='prepend'>R$</InputGroupAddon>
                          </InputGroup>
                        </Col>
                        <Col className='px-0' sm={1}>
                          <label>&nbsp;</label>
                          <span className={'absolute-center mt-2 font-bolder title-large pl-4'}>+</span>
                        </Col>
                        <Col className='px-0' sm={1}>
                          <label>&nbsp;</label>
                          <InputGroup>
                            <Input
                              className='rounded-sm input-secondary'
                              id={'parcelado'}
                              value={values.parcelado}
                              onChange={handleChange}
                              onKeyUp={() => this.atualizarParcela()}
                            />
                            <ErrorFieldWithoutBootstrap fieldName={'parcelado'} errors={errors} />
                          </InputGroup>
                        </Col>
                        <Col sm={2}>
                          <label>&nbsp;</label>
                          <span className={'absolute-center mt-2 font-bold w-100 pl-2'}>vezes de </span>
                        </Col>
                        <Col className='px-0' sm='3'>
                          <label style={{ marginLeft: -20 }}>Parcelas</label>
                          <InputGroup style={{ marginLeft: -20, width: 'calc(100% + 20px)' }} className='reversed'>
                            <InputDecimalComponent
                              readOnly={true}
                              name={'parcela'}
                              value={values.parcela}
                              handleChange={handleChange}
                              fixedDecimalScale={2}
                            />
                            <InputGroupAddon addonType='prepend'>R$</InputGroupAddon>
                          </InputGroup>
                        </Col>
                        <Col className='pl-0' sm={1}>
                          <a
                            href={'#'}
                            data-tip
                            data-for='cents'
                            className={'absolute-center'}
                            style={{ marginTop: 10 }}
                            onClick={() => this.roundedInput(values)}
                          >
                            <Icon className='icon icon-size-small' image={returnicon} />
                          </a>
                          <ReactTooltip id='cents' className='tooltip-griks'>
                            Arredondar os centavos
                          </ReactTooltip>
                        </Col>
                      </Row>
                    </IfComponent>
                    <Row className='margin-top-10'>
                      <Col sm={12}>Dia de Vencimento</Col>
                    </Row>
                    <Row>
                      <Col sm={10} style={{ paddingTop: 5 }}>
                        <Slider
                          min={1}
                          max={30}
                          value={values.diaVencimentoEntrada}
                          step={1}
                          onChange={(e) => {
                            handleChange({
                              target: {
                                name: 'diaVencimentoEntrada',
                                value: e
                              }
                            });
                          }}
                        />
                      </Col>
                      <Col sm={1} style={{ textAlign: 'center' }}>
                        <input
                          id
                          type={'text'}
                          size={7}
                          className='text-secondary'
                          value={values.diaVencimentoEntrada}
                          style={{ borderLeft: 0, borderTop: 0, borderRight: 0, textAlign: 'center' }}
                        />
                      </Col>
                    </Row>

                    <hr></hr>

                    <Row>
                      <Col sm='12'>
                        <h1 className='title-modal'>Mensalidades / Manutenção</h1>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm='6'>
                        <label>
                          Mensalidade <span> *</span>
                        </label>
                        <InputGroup className='reversed'>
                          <InputDecimalComponent
                            name={'mensalidade'}
                            value={values.mensalidade}
                            handleChange={handleChange}
                          />
                          <InputGroupAddon addonType='prepend'>R$</InputGroupAddon>
                        </InputGroup>
                      </Col>
                      <Col sm='6'>
                        <label>Desconto</label>
                        <InputGroup className='reversed'>
                          <InputDecimalComponent
                            name={'descontoMensalidade'}
                            value={values.descontoMensalidade}
                            handleChange={handleChange}
                          />
                          <InputGroupAddon addonType='prepend'>R$</InputGroupAddon>
                        </InputGroup>
                      </Col>
                    </Row>

                    <Row className='margin-top-10'>
                      <Col sm='6'>
                        <label>Valor com Desconto</label>
                        <InputGroup className='reversed'>
                          <InputDecimalComponent
                            readOnly={true}
                            name={'mensalidadeComDesconto'}
                            value={values.mensalidade - values.descontoMensalidade}
                            handleChange={handleChange}
                          />
                          <InputGroupAddon addonType='prepend'>R$</InputGroupAddon>
                        </InputGroup>
                      </Col>
                      <Col sm='6'>
                        <label>
                          Quantidade Estimada <span>*</span>
                        </label>
                        <InputDecimalComponent
                          name={'quantidadeEstimada'}
                          value={values.quantidadeEstimada}
                          handleChange={handleChange}
                          fixedDecimalScale={false}
                          errors={errors}
                          className='input-secondary'
                        />
                        <ErrorFieldWithoutBootstrap fieldName={'quantidadeEstimada'} errors={errors} />
                      </Col>
                    </Row>
                    <Row className='margin-top-10'>
                      <Col sm={12}>Dia de Vencimento</Col>
                    </Row>
                    <Row>
                      <Col sm={10} style={{ paddingTop: 5 }}>
                        <Slider
                          min={1}
                          max={30}
                          value={values.mensalidadeDiaVencimento}
                          step={1}
                          onChange={(e) => {
                            handleChange({
                              target: {
                                name: 'mensalidadeDiaVencimento',
                                value: e
                              }
                            });
                          }}
                        />
                      </Col>
                      <Col sm={1} style={{ textAlign: 'center' }}>
                        <input
                          id
                          type={'text'}
                          size={7}
                          className='text-secondary'
                          value={values.mensalidadeDiaVencimento}
                          style={{ borderLeft: 0, borderTop: 0, borderRight: 0, textAlign: 'center' }}
                        />
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Row>
                    <Col className='btn-list' sm={{ size: 6, offset: 6 }}>
                      <button className='button-primary'>Salvar</button>
                      <button className='button-tertiary' type={'button'} onClick={this.closeModal}>
                        Cancelar
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = ({ TreatmentBudgetReducer }) => {
  const { paymentMantenance = {} } = TreatmentBudgetReducer;
  const { formLoad = {} } = paymentMantenance;
  const { budget = {}, mensalidade = {}, procedures = [] } = formLoad;

  let aparelhoOptionValue = {};
  if (mensalidade.tipo_aparelho) {
    const aparelho = procedures.find((b) => b.id === mensalidade.tipo_aparelho.id);
    aparelhoOptionValue = { value: aparelho.id, label: aparelho.aparelho, data: aparelho };
  }

  let parcela = mensalidade.valor_parcela_entrada;

  if (!parcela) {
    parcela =
      (mensalidade.valor_total_entrada - mensalidade.valor_entrada - mensalidade.valor_desconto) /
      mensalidade.parcelado_em;
  }

  let quantidadeEstimada = null;
  if (t(budget, 'plano_tratamento.tempo_tratamento_ate').isDefined) {
    quantidadeEstimada = t(budget, 'plano_tratamento.tempo_tratamento_ate').safeNumber;
  } else if (t(budget, 'plano_tratamento.tempo_tratamento_de').isDefined) {
    quantidadeEstimada = t(budget, 'plano_tratamento.tempo_tratamento_de').safeNumber;
  }

  return {
    ...paymentMantenance,
    formInit: {
      orcamento: budget.id,
      idPagamento: budget.pagamento && budget.pagamento.id,
      id_mensalidade: mensalidade.id,
      aparelho: aparelhoOptionValue,
      valor: mensalidade.valor_total_entrada,
      desconto: mensalidade.valor_desconto,
      parcelado: mensalidade.parcelado_em,
      entrada: mensalidade.valor_entrada,
      diaVencimentoEntrada: mensalidade.dia_vencimento || 10,
      mensalidade: mensalidade.valor_mensalidade,
      descontoMensalidade: mensalidade.valor_desconto_mensalidade,
      quantidadeEstimada: mensalidade.quantidade_estimada || quantidadeEstimada,
      mensalidadeDiaVencimento: mensalidade.dia_vencimento_mensalidade || 10,
      parcela: parcela
    }
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(budgetModalTreatmentPaymentMantenanceModal({ modal: false })),
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader())
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(TreatmentBudgetPaymentMantenanceModal);
