import React, { Component } from 'react';
import { Col, Container, Row, Table } from 'reactstrap';
import Icon from '../../../components/Icon.component';
import people from '../../../assets/img/icons/people.svg';
import noavatar from '../../../assets/img/noavatar.png';
import { formatDate } from '../../../utils/DateUtils';
import PercentLabelComponent from '../../../components/PercentLabelComponent';
import { IoIosPerson } from 'react-icons/io';

class PatientScheduleAnotherMonth extends Component {
  render() {
    const { total, percent, patients = [] } = this.props;

    return (
      <Container>
        <Row>
          <Col
            sm={12}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              paddingBottom: '10px',
              display: 'flex',
              height: 50
            }}
          >
            <IoIosPerson
              className='icon-agendados-outro-mes'
              style={{ width: '34px', height: '34px', marginTop: '-3px' }}
              alt='people'
            />
            <span className='title-medium-plus text-uppercase text-secondary ml-3 mr-3' style={{ fontSize: '17px' }}>
              Pacientes Agendados em Outro Mês
            </span>{' '}
            <span
              className={'procedure-primary ml-2'}
              style={{
                backgroundColor: '#f4f7fd',
                width: 93,
                height: 28,
                color: '#000',
                borderColor: '#aeb6c5',
                borderStyle: 'solid',
                borderWidth: 1,
                textAlign: 'center',
                verticalAlign: 'middle'
              }}
            >
              <span style={{ fontSize: 16, fontWeight: 600, color: '#434c5e' }}> {total} </span>
              <span className='ml-1' style={{ fontSize: 13, fontWeight: 400, color: '#434c5e' }}>
                <PercentLabelComponent percent={percent} />
              </span>
            </span>
          </Col>
        </Row>
        <Row>
          <Col className={'tableResult'} style={{ marginTop: '-5px' }}>
            <Table className='table-header-fixed' responsive>
              <thead style={{ backgroundColor: '#ebecf2' }}>
                <tr className='row py-2' style={{ height: '36px ' }}>
                  <th className='col-sm-4 py-0'>
                    <span className='text-sm text-secondary-d4 font-weight-bold'>Paciente</span>
                  </th>
                  <th className='col-sm-2 pl-5 py-0'>
                    <span className='text-sm text-secondary-d4 font-weight-bold text-nowrap'>Último Atend</span>
                  </th>
                  <th className='col-sm-2'></th>
                  <th className='col-sm-2 py-0'>
                    <span className='text-sm text-secondary-d4 font-weight-bold text-nowrap'>Próximo Atend</span>
                  </th>
                </tr>
              </thead>
              <tbody style={{ maxHeight: 'calc(100vh - 320px)', overflow: 'unset' }}>
                {patients.map((p) => {
                  return (
                    <tr key={p.id} className={'row py-0 box-basic px-0'}>
                      <td className='col-sm-4'>
                        <Row className='pb-0'>
                          <Col sm={2}>
                            <div>
                              <img
                                className={'img-user-avatar' + ' img-xxldpi'}
                                src={p.imagem || noavatar}
                                alt='usuario'
                              />
                            </div>
                          </Col>
                          <Col sm={10} className='p-0'>
                            <div className='title-medium pl-4'>
                              <p className='text-sm font-bold text-secondary text-nowrap'>{p.nome}</p>
                              <p className='text-sm font-light text-secondary'>{p.idade}</p>
                            </div>
                          </Col>
                        </Row>
                      </td>
                      <td className='col-sm-2 pl-5'>
                        <span className='text-sm text-secondary'>{formatDate(p.ultimoAtendimento, 'DD/MM/YYYY')}</span>
                      </td>
                      <td className='col-sm-2'></td>
                      <td className='col-sm-2'>
                        <span className='text-sm text-secondary'>{formatDate(p.agendada, 'DD/MM/YYYY')}</span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default PatientScheduleAnotherMonth;
