import React, { Component } from 'react';
import { Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import logo from '../../assets/img/logo.jpg';
import { maskCNPJ, maskCPF } from '../../utils/Formatter';
import { ToastContainer } from 'react-toastify';
import Select from 'react-select';
import { selectStyles } from '../../config/core.config';
import { RadioButton, RadioGroup } from 'react-radio-buttons';

import states from '../../utils/states';
import { Form, Formik } from 'formik';
import { IfComponent } from '../../components/if.component';
import { reactSelectHandleChange, reactSelectStyleIsError } from '../../utils/ReactSelectUtils';
import InputMaskComponent from '../../components/InputMask.component';
import ClientApi from '../../service/ClientApi';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { showLoader, hideLoader } from '../../components/actions/Loader.actions';
import ToastUtils from '../../utils/ToastUtils';
import ErrorField from '../../components/ErrorField.component';
import ErrorFieldWithoutBootstrap from '../../components/ErrorFieldWithoutBootstrap.component';
import * as Yup from 'yup';
import moment from 'moment';
import { isCNPJ, isCPF } from 'brazilian-values';
import t from 'typy';

class SubscribeNFPage extends Component {
  state = {};

  validateFormSchema = Yup.object().shape({
    nome: Yup.string().required('Nome Obrigatório'),
    email: Yup.string().required('E-mail Obrigatório'),
    bairro: Yup.string().required('Bairro Obrigatório'),
    endereco: Yup.string().required('Endereço Obrigatório'),
    numero: Yup.string().required('Numero Obrigatório'),
    cidade: Yup.string().required('Cidade Obrigatório'),
    cep: Yup.string().required('CEP Obrigatório'),
    estado: Yup.object().shape({
      value: Yup.string().required('Estado Obrigatório')
    })
  });

  componentDidMount() {
    const { state = {} } = this.props.location;
    this.setState({ ...state });
  }

  next = (values, actions) => {
    let valid = true;
    if (values.clienteType === 'F' && !values.cpf) {
      actions.setFieldError('cpf', 'Campo CPF Obrigatório');
      valid = false;
    } else if (values.clienteType === 'F' && values.cpf && !isCPF(values.cpf)) {
      actions.setFieldError('cpf', 'CPF Inválido');
      valid = false;
    } else if (values.clienteType === 'J' && !values.cnpj) {
      actions.setFieldError('cnpj', 'Campo CNPJ Obrigatório');
      valid = false;
    } else if (values.clienteType === 'J' && values.cnpj && !isCNPJ(values.cnpj)) {
      actions.setFieldError('cnpj', 'CNPJ Inválido');
      valid = false;
    }

    if (valid) {
      const request = {
        login: values.login,

        cupom: t(values, 'cupom.id').isDefined ? values.cupom.id : null,

        //Plano
        totalUsuario: values.totalUsers,
        valorTotal: values.totalValue,
        tipoPagamento: values.paymentTypeCode === 'Y' ? 'A' : 'M',

        // Pagamento
        nomeCartao: values.nome_cartao,
        numeroCartao: values.numero_cartao,
        validadeAno: values.ano.value,
        validadeMes: values.mes.value,
        cdc: values.cdc,

        // NF
        tipo: values.clienteType,
        nome: values.nome,
        cpf: values.cpf,
        cnpj: values.cnpj,
        email: values.email,
        endereco: values.endereco,
        numero: values.numero,
        complemento: values.complemento,
        bairro: values.bairro,
        estado: values.estado.value,
        cidade: values.cidade,
        cep: values.cep
      };
      ClientApi.subscribe(request)
        .then((_) => {
          this.props.history.push('/trial/subscribe/success');
        })
        .catch((err) => {
          console.error(err);
          ToastUtils.error('Erro ao gravar dados, entre em contato com o suporte');
        });
    }
  };

  render() {
    return (
      <Formik
        validateOnChange={false}
        validationSchema={this.validateFormSchema}
        enableReinitialize={true}
        initialValues={{ clienteType: 'F', ...this.state }}
        onSubmit={this.next}
      >
        {({ errors, values, handleChange }) => {
          return (
            <Form>
              <Container className={'subscribe-nf'}>
                <div className='login-container'>
                  <div className={'subscribe-nf-content'}>
                    <Row className={'plans'} style={{ border: 0 }}>
                      <Col className={'content content-last'} sm={12}>
                        <div className={'content-header'}>
                          <div className={'content-title'}>
                            <img
                              src={logo}
                              alt='logo'
                              style={{
                                width: 125,
                                height: 34
                              }}
                            />
                          </div>
                          <div className={'content-subtitle'}>Dados para a emissão da Nota Fiscal</div>
                        </div>
                        <Row>
                          <Col sm='12'>
                            <RadioGroup
                              id='status'
                              name='clienteType'
                              className='radio-griks'
                              onChange={(e) => handleChange({ target: { name: 'clienteType', value: e } })}
                              horizontal
                              value={values.clienteType}
                            >
                              <RadioButton pointColor={'var(--primary-accent)'} value='F'>
                                Pessoa Física
                              </RadioButton>
                              <RadioButton pointColor={'var(--primary-accent)'} iconSize={20} value='J'>
                                Pessoa Jurídica
                              </RadioButton>
                            </RadioGroup>
                          </Col>
                        </Row>
                        <div className={'content-details w-100'}>
                          <Row>
                            <Col sm={12}>
                              <FormGroup>
                                <Label className='margin-top-20' for='examplePassword'>
                                  Nome
                                </Label>
                                <Input
                                  className='input-secondary'
                                  type='text'
                                  name='nome'
                                  value={values.nome}
                                  onChange={handleChange}
                                  invalid={errors.nome ? true : false}
                                />
                                <ErrorField errors={errors} fieldName={'nome'} />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <IfComponent conditional={values.clienteType === 'F'}>
                                <FormGroup>
                                  <Label className='margin-top-20' for='examplePassword'>
                                    CPF
                                  </Label>
                                  <InputMaskComponent
                                    className='input-secondary'
                                    mask={maskCPF()}
                                    type='text'
                                    name='cpf'
                                    onChange={handleChange}
                                    invalid={errors.cpf ? true : false}
                                  />
                                  <ErrorField errors={errors} fieldName={'cpf'} />
                                </FormGroup>
                              </IfComponent>
                              <IfComponent conditional={values.clienteType === 'J'}>
                                <FormGroup>
                                  <Label className='margin-top-20' for='examplePassword'>
                                    CNPJ
                                  </Label>
                                  <InputMaskComponent
                                    className='input-secondary'
                                    mask={maskCNPJ()}
                                    type='text'
                                    name='cnpj'
                                    onChange={handleChange}
                                    invalid={errors.cnpj ? true : false}
                                  />
                                  <ErrorField errors={errors} fieldName={'cnpj'} />
                                </FormGroup>
                              </IfComponent>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <Label className='margin-top-20' for='examplePassword'>
                                  E-mail
                                </Label>
                                <Input
                                  className='input-secondary'
                                  type='text'
                                  name='email'
                                  value={values.email}
                                  onChange={handleChange}
                                  invalid={errors.email ? true : false}
                                />
                                <ErrorField errors={errors} fieldName={'email'} />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm={9}>
                              <FormGroup>
                                <Label className='margin-top-20' for='examplePassword'>
                                  Endereço
                                </Label>
                                <Input
                                  className='input-secondary'
                                  type='text'
                                  name='endereco'
                                  onChange={handleChange}
                                  invalid={errors.endereco ? true : false}
                                />
                                <ErrorField errors={errors} fieldName={'endereco'} />
                              </FormGroup>
                            </Col>
                            <Col sm={3}>
                              <FormGroup>
                                <Label className='margin-top-20' for='examplePassword'>
                                  Número
                                </Label>
                                <Input
                                  className='input-secondary'
                                  type='text'
                                  name='numero'
                                  onChange={handleChange}
                                  invalid={errors.numero ? true : false}
                                />
                                <ErrorField errors={errors} fieldName={'numero'} />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm={7}>
                              <FormGroup>
                                <Label className='margin-top-20' for='examplePassword'>
                                  Complemento
                                </Label>
                                <Input
                                  className='input-secondary'
                                  type='text'
                                  name='complemento'
                                  onChange={handleChange}
                                />
                              </FormGroup>
                            </Col>
                            <Col sm={5}>
                              <FormGroup>
                                <Label className='margin-top-20' for='examplePassword'>
                                  Bairro
                                </Label>
                                <Input
                                  className='input-secondary'
                                  type='text'
                                  name='bairro'
                                  onChange={handleChange}
                                  invalid={errors.bairro ? true : false}
                                />
                                <ErrorField errors={errors} fieldName={'bairro'} />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row className='text-default pb-0'>
                            <Col sm={2}>
                              <Label className='margin-top-20'>UF</Label>
                            </Col>
                            <Col sm={6}>
                              <Label className='margin-top-20'>Cidade</Label>
                            </Col>
                            <Col sm={4}>
                              <Label className='margin-top-20'>CEP</Label>
                            </Col>
                          </Row>
                          <Row className='text-default pb-0'>
                            <Col sm={2} className={'pl-0'}>
                              <Select
                                onChange={(e) => reactSelectHandleChange(e, handleChange, 'estado')}
                                options={states.map((s) => ({
                                  label: s.sigla,
                                  value: s.sigla
                                }))}
                                styles={reactSelectStyleIsError(errors, 'estado', selectStyles)}
                              />
                              <ErrorFieldWithoutBootstrap errors={errors} fieldName={'estado.value'} />
                            </Col>
                            <Col sm={6}>
                              <Input
                                className='input-secondary'
                                type='text'
                                name='cidade'
                                onChange={handleChange}
                                invalid={errors.cidade ? true : false}
                              />
                              <ErrorFieldWithoutBootstrap errors={errors} fieldName={'cidade'} />
                            </Col>
                            <Col sm={4}>
                              <Input
                                className='input-secondary'
                                type={'text'}
                                name='cep'
                                onChange={handleChange}
                                invalid={errors.cep ? true : false}
                              />
                              <ErrorField errors={errors} fieldName={'cep'} />
                            </Col>
                          </Row>
                        </div>
                        <div className={'margin-top-20'}>
                          <button
                            className={'content-price-box-subscribe-button button-primary'}
                            style={{ width: 200 }}
                            type={'submit'}
                          >
                            Salvar
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
                <ToastContainer />
              </Container>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader())
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default SubscribeNFPage;
