import RestTemplate from '../utils/restTemplate/rest.template';
import { t } from 'typy';

class TreatmentClinicalRecordApi {
  static getClinicalRecordByTreatment = ({ treatment }) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/tratamento/${treatment}/ficha`);
  };

  static createClinicalRecord = ({ treatment }) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post(`/tratamento/${treatment}/ficha`);
  };

  static summarySave = ({
    tratamento,
    ficha,
    id,
    tipo_tratamento,
    plano_tratamento,
    tempo_tratamento_de,
    tempo_tratamento_ate,
    atencao,
    aparelho,
    nome_comercial,
    prescricao,
    slot,
    queixas
  }) => {
    const request = {
      id,
      tipo_tratamento,
      plano_tratamento,
      tempo_tratamento_de,
      tempo_tratamento_ate,
      atencao,
      aparelho,
      nome_comercial,
      prescricao,
      slot,
      queixas
    };

    const restTemplate = new RestTemplate(true);
    return restTemplate.put(`/tratamento/${tratamento}/ficha/${ficha}/sumario`, request);
  };

  static findAttendenceRecord = ({ treatmentId, recordId, page = 0, pageSize = 100 }) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(
      `/tratamento/${treatmentId}/ficha/${recordId}/registros?pageNumber=${page}&pageSize=${pageSize}`
    );
  };

  static findLastAttendence = ({ treatmentId, recordId }) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/tratamento/${treatmentId}/ficha/${recordId}/registro/ultimo`);
  };

  static saveAttendenceRecord = ({
    attendenceId,
    treatmentId,
    recordId,
    data_atendimento,
    consulta_emergencia,
    arco_superior,
    arco_inferior,
    anotacao,
    dentista,
    auxiliar,
    registrado_em_data_anterior,
    planejado,
    odontograma
  }) => {
    const data = {
      data_atendimento,
      consulta_emergencia,
      arco_superior,
      arco_inferior,
      anotacao,
      dentista,
      auxiliar,
      registrado_em_data_anterior,
      planejado: {
        id: t(planejado, 'id').safeObject,
        arco_superior: t(planejado, 'arco_superior').safeObject,
        arco_inferior: t(planejado, 'arco_inferior').safeObject,
        proximo_atendimento: t(planejado, 'proximo_atendimento').safeString
      },
      odontograma
    };

    const restTemplate = new RestTemplate(true);

    if (attendenceId) {
      data.id = attendenceId;
      return restTemplate.put(`/tratamento/${treatmentId}/ficha/${recordId}/registro`, data);
    } else {
      return restTemplate.post(`/tratamento/${treatmentId}/ficha/${recordId}/registro`, data);
    }
  };

  static deleteAttendenceRecord = ({ attendenceId, treatmentId, recordId }) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.delete(`/tratamento/${treatmentId}/ficha/${recordId}/registro/${attendenceId}`);
  };

  static createDiagnostics = ({ treatmentId, recordId }) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post(`/tratamento/${treatmentId}/ficha/${recordId}/diagnostico`);
  };

  static getDiagnostics = ({ treatmentId, recordId }) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/tratamento/${treatmentId}/ficha/${recordId}/diagnostico`);
  };

  static saveRatingAngleInDiagnostic = ({
    treatmentId,
    recordId,
    diagnosticId,
    id,
    classificacao_angle,
    dente_direito6,
    dente_direito3,
    dente_esquerdo3,
    dente_esquerdo6,
    classe_esqueletica,
    padrao_esqueletico
  }) => {
    const data = {
      id,
      classificacao_angle,
      dente_direito6,
      dente_direito3,
      dente_esquerdo3,
      dente_esquerdo6,
      classe_esqueletica,
      padrao_esqueletico
    };

    const restTemplate = new RestTemplate(true);
    return restTemplate.post(`/tratamento/${treatmentId}/ficha/${recordId}/diagnostico/${diagnosticId}`, data);
  };

  static saveDiagnosticProblems = ({ treatmentId, recordId, diagnosticId, problems = [] }) => {
    const data = problems.map((p) => ({
      problema: p
    }));

    const restTemplate = new RestTemplate(true);
    return restTemplate.post(`/tratamento/${treatmentId}/ficha/${recordId}/diagnostico/${diagnosticId}/problems`, data);
  };

  static saveDiagnosticOtherInformations = ({
    treatmentId,
    recordId,
    diagnosticId,
    fator_risco,
    limitacoes,
    outras_anotacoes
  }) => {
    const data = {
      fator_risco,
      limitacoes,
      outras_anotacoes
    };

    const restTemplate = new RestTemplate(true);
    return restTemplate.post(
      `/tratamento/${treatmentId}/ficha/${recordId}/diagnostico/${diagnosticId}/other-infos`,
      data
    );
  };

  static createTreatmentPlan = ({ treatmentId, recordId }) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post(`/tratamento/${treatmentId}/ficha/${recordId}/treatment-plan/create`);
  };

  static findTreatmentPlan = ({ treatmentId, recordId }) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/tratamento/${treatmentId}/ficha/${recordId}/treatment-plan/`);
  };

  static saveTreatmentPlanGoals = ({ treatmentId, recordId, planId, goals }) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post(`/tratamento/${treatmentId}/ficha/${recordId}/treatment-plan/${planId}/goals`, {
      goals: goals
    });
  };

  static saveTreatmentPlanStrategies = ({ treatmentId, recordId, planId, strategies }) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post(`/tratamento/${treatmentId}/ficha/${recordId}/treatment-plan/${planId}/strategy`, {
      strategies
    });
  };

  static saveTreatmentPlanOthersDetails = ({ treatmentId, recordId, planId, othersDetails }) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post(`/tratamento/${treatmentId}/ficha/${recordId}/treatment-plan/${planId}/others-detail`, {
      othersDetails
    });
  };

  static saveTreatmentPlanContention = ({ treatmentId, recordId, planId, contention }) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post(`/tratamento/${treatmentId}/ficha/${recordId}/treatment-plan/${planId}/contention`, {
      id: contention.id,
      contencao_superior: contention.superior,
      contencao_inferior: contention.inferior,
      contencao_superior_complementar: contention.superior_complementar
    });
  };

  static saveTreatmentPlanGadgets = ({ treatmentId, recordId, planId, gadgets }) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post(`/tratamento/${treatmentId}/ficha/${recordId}/treatment-plan/${planId}/gadgets`, gadgets);
  };

  static finalize = ({ treatmentId, recordId, tipo, anotacoes }) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post(`/tratamento/${treatmentId}/ficha/${recordId}/finalize`, {
      tipo,
      anotacoes
    });
  };

  static contationFinalize = ({ treatmentId, recordId, anotacao }) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post(`/tratamento/${treatmentId}/ficha/${recordId}/finalize/contention`, {
      anotacao
    });
  };

  static delete = ({ treatmentId, recordId }) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.delete(`/tratamento/${treatmentId}/ficha/${recordId}`);
  };

  static saveTags({ treatmentId, recordId, diagnostic, tags }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post(`/tratamento/${treatmentId}/ficha/${recordId}/diagnostico/${diagnostic}/tags`, [...tags]);
  }

  static savePlanoTratamentoSequenciaMaxila(treatment, record, plan, sequencies) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post(`/tratamento/${treatment}/ficha/${record}/treatment-plan/${plan}/maxila`, sequencies);
  }

  static savePlanoTratamentoSequenciaMandibula(treatment, record, plan, sequencies) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post(`/tratamento/${treatment}/ficha/${record}/treatment-plan/${plan}/mandibula`, sequencies);
  }

  static findTimeline(treatment, record) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/tratamento/${treatment}/ficha/${record}/timeline`);
  }

  static saveTimeline(treatment, record, timeline) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post(`/tratamento/${treatment}/ficha/${record}/timeline`, timeline);
  }
}

export default TreatmentClinicalRecordApi;
