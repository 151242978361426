import React, { Component } from 'react';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { setHeader } from '../../pages/template/redux/Content.actions';

class BaseComponent extends Component {
  setHeader = ({ data }) => {
    this.props.setHeader({ ...data });
  };
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    setHeader: (data) => dispatch(setHeader(data))
  };
};

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(BaseComponent);
