import { call, put } from 'redux-saga/effects';
import UserAPI from '../../../../service/User.service';
import { homeUsers } from '../redux/UserSettings.actions';
import { hideLoader } from '../../../../components/actions/Loader.actions';

export function* listAllUsers(state) {
  try {
    const { data } = yield call(UserAPI.findUsersByClinic, {
      pageNumber: state.payload.currentPage,
      pageSize: state.payload.pageSize
    });

    yield put(hideLoader());
    yield put(homeUsers(data));
  } catch (e) {
    yield put(hideLoader());
  }
}
