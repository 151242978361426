import React, { Component } from 'react';
import ScheduleMenuComponent from './ScheduleMenu.component';
import ListScheduleAndRedialPage from './ListScheduleAndRedial.page';
import ListScheduleMenu from './ListScheduleMenu.component';
import { Row, Col, Container } from 'reactstrap';
import ScheduleAPI from '../../service/ScheduleAPI';
import ToastUtils from '../../utils/ToastUtils';
import moment from 'moment';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { hideLoader, showLoader } from '../../components/actions/Loader.actions';
import { IfComponent } from '../../components/if.component';
import { scheduleAndRedialSagaPage, scheduleOrthodonticsFrequencyPrePage } from './redux/Schedule.actions';
import OrthodonticsFrequencyPage from './OrthodonticsFrequency.page';
import ScheduleControlReturnPage from './ScheduleControlReturn.page';

class ListSchedulePage extends Component {
  state = {
    tabSelected: '',
    dentistSelected: {}
  };

  componentDidMount() {
    this.setState({ tabSelected: 'A' });
  }

  selectDentist = (dentist) => {
    this.setState({ dentistSelected: dentist }, () => this.selectedTab(this.state.tabSelected));
  };

  selectedTab = (tab) => {
    const { dentistSelected } = this.state;

    switch (tab) {
      case 'A': {
        this.props.scheduleAndRedial({ dentist: dentistSelected });
        break;
      }
      case 'F': {
        this.props.scheduleFrequency({ dentist: dentistSelected });
        break;
      }
    }
    this.setState({ tabSelected: tab });
  };

  render() {
    const { tabSelected, dentistSelected } = this.state;

    return (
      <Container>
        <Row>
          <Col>
            <ScheduleMenuComponent
              key='ScheduleMenuComponent'
              dentistSelected={this.selectDentist}
              url={this.props.location.pathname}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <ListScheduleMenu selectedTab={this.selectedTab} />
          </Col>
        </Row>

        <IfComponent conditional={tabSelected === 'A'}>
          <Row className='w-100 p-0'>
            <Col className='w-100 p-0'>
              <ListScheduleAndRedialPage />
            </Col>
          </Row>
        </IfComponent>

        <IfComponent conditional={tabSelected === 'F'}>
          <Row className='w-100 p-0'>
            <Col className='w-100 p-0'>
              <OrthodonticsFrequencyPage />
            </Col>
          </Row>
        </IfComponent>

        <IfComponent conditional={tabSelected === 'CR'}>
          <Row className='w-100 p-0'>
            <Col className='w-100 p-0'>
              <ScheduleControlReturnPage dentist={dentistSelected} />
            </Col>
          </Row>
        </IfComponent>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  scheduleAndRedial: (data) => dispatch(scheduleAndRedialSagaPage(data)),
  scheduleFrequency: (data) => dispatch(scheduleOrthodonticsFrequencyPrePage(data))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(ListSchedulePage);
