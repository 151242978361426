import React, { Component } from 'react';
import calendar from '../assets/img/icons/calendar.svg';
import Icon from './Icon.component';

class ButtonDatePicker extends Component {
  render() {
    return (
      <a onClick={this.props.onClick} style={{ marginLeft: 30 }}>
        <Icon className='icon icon-size-small margin-left-10' image={calendar} alt='calendar' />
      </a>
    );
  }
}

export default ButtonDatePicker;
