import ScheduleAPI from '../../../service/ScheduleAPI';
import UserAPI from '../../../service/User.service';
import { all, call, put } from 'redux-saga/effects';
import { schedulePage } from '../redux/Schedule.actions';
import { showLoader, hideLoader } from '../../../components/actions/Loader.actions';

export function* scheduleSagaProcess({ payload: { dentist, day } }) {
  const { id } = dentist;
  try {
    yield put(showLoader());

    yield put(
      schedulePage({
        day
      })
    );

    yield put(hideLoader());
  } catch (e) {
    console.log(e);
  }
}
