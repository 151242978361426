import React, { Component } from 'react';
import { Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import Loader from '../../components/Loader.component';
import logo from '../../assets/img/logo.jpg';
import ErrorField from '../../components/ErrorField.component';
import { Link, withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import MessagesComponent from '../../components/Messages.component';
import { hideLoader, showLoader } from '../../components/actions/Loader.actions';
import { sendMessage } from '../../components/actions/ErrorMessage.actions';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import UserAPI from '../../service/User.service';

class ChangePasswordPage extends Component {
  state = {
    error: {
      display: false,
      type: 'danger',
      message: ''
    }
  };

  validateSchema = Yup.object().shape({
    senha: Yup.string().required('Senha Obrigatório').label('Senha'),
    novasenha: Yup.string().required('Nova Senha Obrigatório').label('Nova Senha'),
    cnovasenha: Yup.string().required('Confirma Nova Senha Obrigatório').label('Confirma Nova Senha')
  });

  salvarNovaSenha = async (values) => {
    this.props.showLoader();

    try {
      if (values.novasenha !== values.cnovasenha) {
        this.props.hideLoader();
        this.setState({
          error: {
            display: true,
            type: 'danger',
            message: 'Nova senha diferente de confirma senha'
          }
        });
      } else {
        await UserAPI.changePasswordWithChangePwd({ senha: values.senha, novaSenha: values.novasenha });
        this.props.history.push('/');
      }
    } catch (e) {
      console.error(e);

      let message = 'Erro ao alterar senha ';

      if (e.response.data.message) {
        message = e.response.data.message;
      }

      this.setState({
        error: {
          display: true,
          type: 'danger',
          message
        }
      });
    }

    this.props.hideLoader();
  };

  render() {
    const { error } = this.state;

    return (
      <Container>
        <Loader />
        <main className='main-container-overflow'>
          <div className='login-container'>
            <div className='login-box'>
              {/* <div className="login-box-left">
               <span>Conteúdo Publicitário do <strong>GRIKS</strong></span>
               </div> */}
              <div className='login-box-right'>
                <Row>
                  <Col sm='12'>
                    <img className='img-logo-login' src={logo} alt='logo' />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col sm='12'>
                    <span className={'text-big text-secondary font-bold'}>Alterar Senha</span>
                  </Col>
                </Row>
                <Row>
                  <Col sm={{ size: 6, offset: 3 }}>
                    <MessagesComponent display={error.display} type={error.type} message={error.message} />
                    <Formik
                      validationSchema={this.validateSchema}
                      onSubmit={this.salvarNovaSenha}
                      validateOnChange={false}
                    >
                      {({ errors, handleSubmit, handleChange }) => (
                        <form className='login-form' onSubmit={handleSubmit}>
                          <FormGroup>
                            <Label className='margin-top-20' for='examplePassword'>
                              Senha Atual
                            </Label>
                            <Input
                              type='password'
                              name='senha'
                              onChange={handleChange}
                              invalid={errors.senha ? true : false}
                            />
                            <ErrorField errors={errors} fieldName={'senha'} />
                          </FormGroup>
                          <FormGroup>
                            <Label className='margin-top-20' for='examplePassword'>
                              Nova Senha
                            </Label>
                            <Input
                              type='password'
                              name='novasenha'
                              onChange={handleChange}
                              invalid={errors.novasenha ? true : false}
                            />
                            <ErrorField errors={errors} fieldName={'novasenha'} />
                          </FormGroup>

                          <FormGroup>
                            <Label className='margin-top-20' for='examplePassword'>
                              Confirmar Nova Senha
                            </Label>
                            <Input
                              type='password'
                              name='cnovasenha'
                              onChange={handleChange}
                              invalid={errors.cnovasenha ? true : false}
                            />
                            <ErrorField errors={errors} fieldName={'cnovasenha'} />
                          </FormGroup>
                          <button type='submit' className='button-primary button-primary-w235'>
                            Salvar
                          </button>
                        </form>
                      )}
                    </Formik>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </main>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  sendMessage: (data) => dispatch(sendMessage(data))
});

const enhanced = compose(withRouter, connect(mapStateToProps, mapDispatchToProps));

export default enhanced(ChangePasswordPage);
