import React,{useEffect,useState,useImperativeHandle,forwardRef} from "react"
import {Col, Row} from 'reactstrap';
import whatsapp from '../../assets/img/icons/whatsapp_color.svg'
import ReactTooltip from 'react-tooltip';

const WhatsappHelpButton = (props) => {

    const suportMessage = () => {

        let number = '67999485062'    
      
        let message = `Olá, Você pode me ajudar?`

        let url;
        try{
            url = `https://web.whatsapp.com/send?phone=55${number}&text=${encodeURI(message.trim())}`;
            window.open(url).focus();
        } 
        catch (e) {
            url = `whatsapp://send/?phone=55${number}&text=${encodeURI(message.trim())}`;
            window.open(url).focus();
        }
      
        
    }

    return(
        <div className="mb-4 mr-4" style={{zIndex:500,position:'absolute',bottom:0,right:0}}>
            {               
                //<div className="mb-4 mr-4" style={{zIndex:9999,position:'absolute',bottom:0,right:0}}>
                //<div style={{zIndex:9999,position:'absolute',bottom:0,right:0,marginBottom:50,marginRight:30}}>

            }
            <button style={{background:'transparent',border:0}} onClick={suportMessage}>
                <a data-tip='Fale com o Suporte' data-for='suport'>
                    <img src={whatsapp} style={{width:64,float:'right'}}/>
                </a>
            </button>
            <ReactTooltip className='tooltip-griks' place='left' id='suport' type='gray'/>
        </div>
    )
}
export default forwardRef(WhatsappHelpButton);