import React, { Component } from 'react';
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  Table
} from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import agendada from '../../assets/img/icons/agendada.svg';
import phone from '../../assets/img/icons/phone.svg';
import whatsappazul from '../../assets/img/icons/whatsappazul.svg';
import desmarcar from '../../assets/img/icons/desmarcar.svg';
import remarcar from '../../assets/img/icons/remarcar.svg';
import whatsapp from '../../assets/img/icons/whatsapp-o.svg';
import talk from '../../assets/img/icons/talk.svg';
import noavatar from '../../assets/img/noavatar.png';
import arrowright from '../../assets/img/icons/arrowright.svg';
import arrowleft from '../../assets/img/icons/arrowleft.svg';
import calendar from '../../assets/img/icons/calendar.svg';
import confirmada from '../../assets/img/icons/confirmada.svg';
import Icon from '../../components/Icon.component';
import ScheduleMenuComponent from './ScheduleMenu.component';
import menuDots from '../../assets/img/menuDots.png';
import ScheduleAPI from '../../service/ScheduleAPI';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { hideLoader, showLoader } from '../../components/actions/Loader.actions';
import ToastUtils from '../../utils/ToastUtils';
import { t } from 'typy';
import { phoneFormat } from '../../utils/Formatter';
import AppointmentConfirmMessageModal from './modal/AppointmentConfirmMessage.modal';
import { appointmentMessageModal, appointmentRedialModal, uncheckAppointmentSagaModal } from './redux/Schedule.actions';
import ClinicAPI from '../../service/ClinicAPI';
import { getClinic } from '../../utils/localStorage/SessionStorageUtils';
import UncheckAppointmentModal from './modal/UncheckAppointment.modal';
import AppointmentRedialModal from './modal/AppointmentRedial.modal';
import { IfComponent } from '../../components/if.component';
import { PERMISSOES } from '../../config/security/permissoes';
import withSecurity from '../../config/security/security';
import DatePicker from 'react-datepicker';
import ButtonDatePicker from '../../components/ButtonDatePicker';
import PatinentViewModal from '../patinent/components/PatientViewModal.component';
import PacienteAPI from '../../service/PacienteAPI';
import AppointmentHistoryModal from './modal/AppointmentHistory.modal';
import { setHeader } from '../template/redux/Content.actions';
import NumberFormat from 'react-number-format';
import { capitalize } from 'lodash';

class ScheduleConfirmationsPage extends Component {
  state = {
    dentistSelected: null,
    date: moment(),
    confirmations: [],
    clinic: null,
    patientdata: {},
    displayPatientModalDetail: false
  };

  componentDidMount() {
    this.props.setHeader({
      title: 'Agenda',
      image: calendar
    });
    this.setState({
      date: moment(),
      clinic: getClinic()
    });
  }

  findConfirmations = (dentist) => {
    const { showLoader, hideLoader } = this.props;

    showLoader();
    this.setState({ dentistSelected: dentist });

    ScheduleAPI.findAppointmentsForConfirmations({
      dentist: dentist.value,
      data: this.state.date.format('YYYY-MM-DD')
    })
      .then(({ data }) => {
        hideLoader();

        let totalConfirmados = data.reduce((total, c) => {
          if (c.status === 'C') return total + 1;
          else return total;
        }, 0);

        const totalLista = data.length;
        const porcentagemConfirmados = (100 / totalLista) * totalConfirmados;

        this.setState({ confirmations: data, porcentagemConfirmados: porcentagemConfirmados });
      })
      .catch((err) => {
        hideLoader();
        ToastUtils.error('Erro ao buscar confirmações entre em contato com o suporte');
      });
  };

  sendViaWhats = (c, cont) => {
    let nomePaciente = '';
    if (c.paciente.nome_preferido) {
      nomePaciente = c.paciente.nome_preferido;
    } else {
      nomePaciente = c.paciente.nome;
    }

    let telefone = cont.telefone.replace('(', '');
    telefone = telefone.replace(')', '');
    telefone = telefone.replace('-', '');
    telefone = telefone.replace(' ', '');

    let boldMessage = `${nomePaciente.toUpperCase()}, ${moment(c.data).format('dddd [dia] DD/MM/YYYY ')} às ${
      c.horario
    } hs.`;
    let message = `Olá!\nConfirmamos a consulta de *${boldMessage}*\n - Responda SIM, para confirmar ou NÃO para Cancelar.\n - Para reagendar, responda a mensagem ou ligue (${this.state.clinic.ddd})${this.state.clinic.telefone}.\n\nObrigado,\n${this.state.clinic.nome}`;
    const clinica = getClinic();
    // let url = `https://wa.me/55${telefone}?text=${encodeURI(message.trim())}`;
    // if(clinica.id == 1 || clinica.id == 228){
    let url = `whatsapp://send/?phone=55${telefone}&text=${encodeURI(message.trim())}`;
    // }
    console.log(url);

    //foi alterado a variavel win para poder fazer o envio da mensagem

    let win = window.open(url, '_blank');
     //let win = window.open(`https://wa.me/55${telefone}?text=${encodeURI(message.trim())}`, '_blank');
    win.focus();

    this.changeStatusConfirmation('E', c.id);
  };

  changeStatusConfirmation = (status, appointment) => {
    if (this.props.security.hasPermission(PERMISSOES.modules.agenda.id, PERMISSOES.modules.agenda.permissoes.editar)) {
      const { showLoader, hideLoader } = this.props;
      const { dentistSelected } = this.state;

      showLoader();

      ScheduleAPI.changeStatusConfirmAppointment({
        dentist: dentistSelected.value,
        consulta: appointment,
        statusConfirmacao: status
      })
        .then(({ data }) => {
          hideLoader();
          this.findConfirmations(dentistSelected);
        })
        .catch((err) => {
          console.error(err);
          hideLoader();
          ToastUtils.error('Erro ao alterar status de confirmação');
        });
    } else {
      ToastUtils.error('Acesso Negado');
    }
  };

  getImage = (status) => {
    switch (status) {
      case 'A':
        return agendada;
      case 'E':
        return whatsappazul;
      case 'C':
        return confirmada;
      case 'O':
        return phone;
    }
  };

  showMessage = async (e) => {
    const clinic = getClinic();
    const clinicData = await ClinicAPI.findById(clinic.id);

    this.props.messageModal({
      modal: true,
      appointment: e,
      clinic: clinicData.data
    });
  };

  uncheckAppointment = (c) => {
    const { showLoader, hideLoader } = this.props;
    showLoader();
    ScheduleAPI.getSchedule(c.id)
      .then(({ data }) => {
        hideLoader();
        this.props.uncheckModal({ modal: true, schedule: data });
      })
      .catch((err) => {
        console.error(err);
        hideLoader();
        ToastUtils.error('Erro ao desmarcar consulta entre em contato com o suporte');
      });
  };

  redialAppointment = (c) => {
    const { showLoader, hideLoader } = this.props;
    showLoader();
    ScheduleAPI.getSchedule(c.id)
      .then(({ data }) => {
        hideLoader();
        if (moment(data.data_agendamento + 'T' + data.horario_de).diff(moment(), 'minutes') < 0) {
          ToastUtils.error('Remarcação não permitida consulta já iniciada');
        } else {
          this.props.redialModal({ modal: true, schedule: data });
          this.props.history.push('/schedule');
        }
      })
      .catch((err) => {
        console.error(err);
        hideLoader();
        ToastUtils.error('Erro ao remarcar consulta entre em contato com o suporte');
      });
  };

  plusDay = () => {
    this.setState(
      {
        date: this.state.date.add(1, 'days')
      },
      () => {
        this.findConfirmations(this.state.dentistSelected);
      }
    );
  };

  minusDay = () => {
    this.setState(
      {
        date: this.state.date.subtract(1, 'days')
      },
      () => {
        this.findConfirmations(this.state.dentistSelected);
      }
    );
  };

  selectedDay = (date) => {
    this.setState(
      {
        date: date
      },
      () => {
        this.findConfirmations(this.state.dentistSelected);
      }
    );
  };

  closePatientModalDetail = () => {
    this.setState({
      displayPatientModalDetail: false
    });
  };

  openPatientModalDetail = (patientId) => {
    this.props.showLoader();
    PacienteAPI.findById(patientId).then((data) => {
      this.setState({
        displayPatientModalDetail: true,
        patientdata: data
      });
      this.props.hideLoader();
    });
  };

  render() {
    const {
      confirmations,
      dentistSelected = {},
      date,
      displayPatientModalDetail,
      patientdata,
      porcentagemConfirmados
    } = this.state;

    let index = 999;
    return [
      <ScheduleMenuComponent
        key='ScheduleMenuComponent'
        dentistSelected={this.findConfirmations}
        url={this.props.location.pathname}
      />,
      <div className='content-box-without-color' key={'home'} style={{ zIndex: -2 }}>
        <Container>
          <Row className='w-100'>
            <Col sm='12'>
              <div className={'box-default'} style={{ marginTop: '-20px' }}>
                <Container>
                  <div className='d-flex align-items-center mt-0 py-0 mb-1'>
                    <div className='d-flex flex-grow-1 align-items-center'>
                      <div className='p-2'>
                        <strong className='title-large text-uppercase'>Confirmação de Consultas</strong>
                      </div>
                      <div className='p-2 ml-4'>
                        <strong className='title-large'>{date && date.format('dddd - DD MMM YYYY')}</strong>
                      </div>
                      <div className='p-2 ml-2'>
                        <a href='#' onClick={this.minusDay}>
                          <Icon className='icon icon-size-small' image={arrowleft} alt='arrowleft' />
                        </a>
                      </div>
                      <div className='p-2'>
                        <a href='#' onClick={this.plusDay}>
                          <Icon className='icon icon-size-small' image={arrowright} alt='arrowright' />
                        </a>
                      </div>
                      <div className='p-2 ml-n2'>
                        <a href='' className='button-grey' style={{ textDecoration: 'none' }}>
                          Hoje
                        </a>
                      </div>
                      {/* <div className="p-2 ml-n4">
                        <DatePicker
                          customInput={<ButtonDatePicker />}
                          selected={date}
                          onChange={(data) => {
                            this.selectedDay(data);
                          }} />
                      </div> */}
                    </div>
                    <div className='d-flex align-items-center'>
                      <div className='p-2'>
                        <button
                          className='btn btn-default text-xsm'
                          style={{
                            backgroundColor: '#f7f9fc',
                            border: '1px solid #cfd5e3',
                            cursor: 'default',
                            width: '110px',
                            height: '42px'
                          }}
                        >
                          <strong>
                            <NumberFormat
                              style={{
                                fontSize: '15px'
                              }}
                              value={porcentagemConfirmados}
                              displayType={'text'}
                              decimalScale={3}
                              allowLeadingZeros={true}
                              fixedDecimalScale={true}
                              format={porcentagemConfirmados === 100 ? '###' : '##'}
                            />
                            %
                          </strong>
                          <p className='mt-n1'>Confirmadas</p>
                        </button>
                      </div>
                    </div>
                  </div>

                  <Table className='table-header-fixed' responsive>
                    <thead className='bg-secondary-l5'>
                      <tr className='row py-2 px-2'>
                        <th className='col-sm-3 py-0'>
                          <span className='text-sm text-secondary-d4 font-bolder'>Paciente</span>
                        </th>
                        <th className='col-sm-1 py-0'>
                          <span className='text-sm text-secondary-d4 font-bolder'>Horário</span>
                        </th>
                        <th className='col-sm-2 py-0'>
                          <span className='text-sm text-secondary-d4 font-bolder px-0'>Procedimentos</span>
                        </th>
                        <th className='col-sm-3 py-0'>
                          <span className='text-sm text-secondary-d4 font-bolder'>Contato</span>
                        </th>
                        <th className='col-sm-3 icon-tab-status non-hover py-0'>
                          <div style={{ maxHeight: 20 }} className='float-right py-0 px-0'>
                            <a
                              className='btn'
                              data-tip
                              data-for={'statusConsulta'}
                              href='#!'
                              style={{ marginTop: -8, marginRight: 49 }}
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              <div className='position-relative' id={'statusConsulta'}>
                                <Icon className={' icon-size-one'} image={this.getImage('A')} alt='checkcolor' />
                              </div>
                            </a>
                            <a
                              className='btn'
                              data-tip
                              data-for={'whatsTooltip'}
                              href='#!'
                              style={{ marginTop: -8, marginRight: 1 }}
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              <Icon className='icon-size-one' image={whatsapp} alt='checkcolor' />
                            </a>
                            <a
                              className='btn'
                              data-tip
                              data-for={'desmarcarTooltip'}
                              href='#!'
                              style={{ marginTop: -8 }}
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              <Icon className='icon-size-one' image={desmarcar} alt='checkcolor' />
                            </a>
                            <a
                              className='btn'
                              data-tip
                              data-for={'remarcarTooltip'}
                              href='#!'
                              style={{ marginTop: -8, marginRight: -1 }}
                              rel='noopener noreferrer'
                            >
                              <Icon className='icon-size-one' image={remarcar} alt='checkcolor' />
                            </a>
                            <ReactTooltip id='statusConsulta' className='tooltip-griks' type='light' effect='solid'>
                              Alterar o Status
                            </ReactTooltip>
                            <ReactTooltip id='whatsTooltip' className='tooltip-griks' type='light' effect='solid'>
                              Enviar Mensagem via Whatsapp
                            </ReactTooltip>
                            <ReactTooltip id='desmarcarTooltip' className='tooltip-griks' type='light' effect='solid'>
                              Desmarcar Consulta
                            </ReactTooltip>
                            <ReactTooltip id='remarcarTooltip' className='tooltip-griks' type='light' effect='solid'>
                              Remarcar Consulta
                            </ReactTooltip>
                          </div>
                        </th>
                      </tr>
                    </thead>

                    <tbody style={{ maxHeight: 'calc(100vh - 320px)', overflow: 'unset' }} className={'w-100'}>
                      {confirmations.map((c) => {
                        index = index - 1;

                        return (
                          <tr key={c.id} className={'row px-2'}>
                            <td className='col-sm-3'>
                              <a href={'#'} onClick={() => this.openPatientModalDetail(t(c, 'paciente.id').safeNumber)}>
                                <img
                                  className={'img-user-avatar img-xxldpi float-left mr-3'}
                                  src={t(c, 'paciente.foto').safeString || noavatar}
                                  alt='usuario'
                                />
                              </a>
                              <div className='mt-1'>
                                <p className='text-sm font-bold text-secondary'>{t(c, 'paciente.nome').safeString}</p>
                                <p className='text-sm font-light text-secondary'>
                                  {t(c, 'paciente.numero_identificacao').isDefined &&
                                    '(' + t(c, 'paciente.numero_identificacao').safeString + ')'}{' '}
                                  {t(c, 'paciente.idade').safeString}
                                </p>
                              </div>
                            </td>
                            <td className='col-sm-1'>
                              <p className='text-sm font-bold ml-1 text-secondary'>{c.horario}</p>
                            </td>
                            <td className='col-sm-2' style={{ paddingTop: 8 }}>
                              <span
                                className='procedure-primary badge-proc'
                                style={{
                                  backgroundColor: c.procedimento_categoria_cor,
                                  color: 'var(--secondary)'
                                }}
                              >
                                {c.procedimento}
                              </span>
                            </td>
                            <td className='col-sm-3 pt-2'>
                              <div>
                                {t(c, 'paciente.contatos').isArray &&
                                  c.paciente.contatos
                                    .filter((c) => c.telefone !== '')
                                    .map((c) => (
                                      <p className='text-sm font-light text-secondary-d4' key={c.telefone}>
                                        {phoneFormat(c.telefone)} - {c.responsavel}
                                      </p>
                                    ))}
                              </div>
                            </td>
                            <td className='col-sm-3 icon-tab-status'>
                              <IfComponent conditional={moment(this.state.date).diff(moment(), 'days') >= 0}>
                                <div className='float-right'>
                                  <UncontrolledDropdown className={'dropdown-status mr-5'} direction='left'>
                                    <DropdownToggle>
                                      <div
                                        data-tip
                                        data-for={'tooltip-status-' + c.id}
                                        className='position-relative'
                                        id={'statusConsulta-' + c.id}
                                      >
                                        <Icon
                                          className={(c.status === 'O' ? 'icon-red' : '') + ' icon-size-one'}
                                          image={this.getImage(c.status)}
                                          alt='checkcolor'
                                        />
                                      </div>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem
                                        className={'text-default'}
                                        onClick={() => {
                                          // A
                                          this.changeStatusConfirmation('A', c.id);
                                        }}
                                      >
                                        <Icon
                                          className='icon-size-one mr-2'
                                          style={{ margin: 2, width: 21 }}
                                          image={agendada}
                                          alt='checkcolor'
                                        />{' '}
                                        Consulta Agendada
                                      </DropdownItem>
                                      <DropdownItem
                                        className={'text-default'}
                                        onClick={() => {
                                          // E
                                          this.changeStatusConfirmation('E', c.id);
                                        }}
                                      >
                                        <Icon
                                          className='icon-size-one mr-2'
                                          style={{ margin: 2, width: 21 }}
                                          image={whatsappazul}
                                          alt='checkcolor'
                                        />{' '}
                                        Mensagem Enviada
                                      </DropdownItem>
                                      <DropdownItem
                                        className={'text-default'}
                                        onClick={() => {
                                          // C
                                          this.changeStatusConfirmation('C', c.id);
                                        }}
                                      >
                                        <Icon
                                          className='icon-size-one mr-2'
                                          style={{ margin: 2, width: 19 }}
                                          image={confirmada}
                                          alt='checkcolor'
                                        />{' '}
                                        Consulta Confirmada
                                      </DropdownItem>
                                      <DropdownItem
                                        className={'text-default'}
                                        onClick={() => {
                                          // O
                                          this.changeStatusConfirmation('O', c.id);
                                        }}
                                      >
                                        <Icon
                                          className='icon-size-one mr-2 icon-red'
                                          style={{ margin: 2, width: 21 }}
                                          image={phone}
                                          alt='checkcolor'
                                        />{' '}
                                        Tel. Não Atende / Desligado
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                  <ReactTooltip id={'tooltip-status-' + c.id} className='tooltip-griks' type='light'>
                                    <IfComponent conditional={c.status == 'A'}>Consulta Agendada</IfComponent>
                                    <IfComponent conditional={c.status == 'E'}>Mensagem Enviada</IfComponent>
                                    <IfComponent conditional={c.status == 'C'}>Consulta Confirmada</IfComponent>
                                    <IfComponent conditional={c.status == 'O'}>Tel. Não Atende / Desligado</IfComponent>
                                  </ReactTooltip>
                                  <UncontrolledDropdown direction='down' className={'whatsapp'}>
                                    <DropdownToggle id={'whatsTooltip-' + c.id}>
                                      <Icon className='icon-size-one' image={whatsapp} alt='checkcolor' />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      {t(c, 'paciente.contatos').isArray &&
                                        c.paciente.contatos
                                          .filter((c) => c.telefone !== '')
                                          .map((cont) => (
                                            // <p className="text-sm font-light text-secondary-d4" key={ c.telefone }>{ phoneFormat(c.telefone) } - { c.responsavel }</p>
                                            <IfComponent conditional={cont.whatsapp === true}>
                                              <DropdownItem
                                                className='text-default font-light text-secondary-d4'
                                                onClick={() => {
                                                  this.sendViaWhats(c, cont);
                                                }}
                                              >
                                                {phoneFormat(cont.telefone)} - {cont.responsavel}
                                              </DropdownItem>
                                            </IfComponent>
                                          ))}
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                  <IfComponent
                                    conditional={moment(c.data + 'T' + c.horario).diff(moment(), 'minutes') > 0}
                                  >
                                    <a
                                      className='btn'
                                      id={'desmarcarTooltip-' + c.id}
                                      onClick={() => {
                                        this.uncheckAppointment(c);
                                      }}
                                      href='#!'
                                      rel='noopener noreferrer'
                                    >
                                      <Icon className='icon-size-one' image={desmarcar} alt='checkcolor' />
                                    </a>
                                    <a
                                      className='btn'
                                      id={'remarcarTooltip-' + c.id}
                                      onClick={() => {
                                        this.redialAppointment(c);
                                      }}
                                      rel='noopener noreferrer'
                                    >
                                      <Icon className='icon-size-one' image={remarcar} alt='checkcolor' />
                                    </a>
                                  </IfComponent>
                                </div>
                                {/* <Col sm={ '1' }>
                                <Row>
                                  <Col>
                                    <a href={ '#' } onClick={ () => this.showMessage(c) } style={ { zIndex: -1 } }>
                                      <Icon className="icon icon-size-two mt-2" image={ talk } alt="talk"/>
                                    </a>


                                  </Col>
                                </Row>
                              </Col>
                              <Col>
                                <Row>
                                  <Col>
                                    <div className="mt-2">


                                    </div>
                                  </Col>
                                </Row>
                              </Col> */}
                              </IfComponent>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Container>
              </div>
            </Col>
          </Row>
          {/*<Row>*/}
          {/*  <Col sm={ { size: 2, offset: 10 } }>*/}
          {/*    <Link*/}
          {/*      to={ `/schedule/confirmations/report?date=${ this.state.date.format('YYYY-MM-DD') }&dentist=${ t(dentistSelected, 'value').safeNumber }` }*/}
          {/*      target={ '_blank' }>*/}
          {/*      <button className="button-tertiary">Imprimir Relatório</button>*/}
          {/*    </Link>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
        </Container>
      </div>,
      <AppointmentConfirmMessageModal key={'AppointmentConfirmMessageModal'} />,
      <UncheckAppointmentModal
        key={'UncheckAppointmentModal'}
        updateParent={() => this.findConfirmations(dentistSelected)}
      />,
      <AppointmentRedialModal key={'AppointmentRedialModal'} />,
      <PatinentViewModal
        key={'PatinentViewModal'}
        redirect={this.closePatientModalDetail}
        showDetailModal={displayPatientModalDetail}
        patientData={patientdata}
        closeModal={this.closePatientModalDetail}
      />,
      <AppointmentHistoryModal key={'AppointmentHistoryModal'} />
    ];
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  messageModal: (data) => dispatch(appointmentMessageModal(data)),
  uncheckModal: (data) => dispatch(uncheckAppointmentSagaModal(data)),
  redialModal: (data) => dispatch(appointmentRedialModal(data)),
  setHeader: (data) => dispatch(setHeader(data))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withSecurity);

export default enhanced(ScheduleConfirmationsPage);
