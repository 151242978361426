import { createSelector } from 'reselect';
import t from 'typy';

const clinicalRecordSelector = (state) =>
  t(state, 'TreatmentClinicalRecordReducer.summary.formLoad.clinicalRecord').safeObject;
const treatmentSelector = (state) => t(state, 'TreatmentClinicalRecordReducer.summary.formLoad.treatment').safeObject;
const treatmentTypesSelector = (state) =>
  t(state, 'TreatmentClinicalRecordReducer.summary.formLoad.treatmentTypes').safeObject;
const complaintsSelector = (state) => t(state, 'TreatmentClinicalRecordReducer.summary.formLoad.complaints').safeObject;
const treatmentTimesSelector = (state) =>
  t(state, 'TreatmentClinicalRecordReducer.summary.formLoad.treatmentTimes').safeObject;
const bracesSelector = (state) => t(state, 'TreatmentClinicalRecordReducer.summary.formLoad.braces').safeObject;
const prescricoesSelector = (state) =>
  t(state, 'TreatmentClinicalRecordReducer.summary.formLoad.prescricoes').safeObject;
const slotsSelector = (state) => t(state, 'TreatmentClinicalRecordReducer.summary.formLoad.slots').safeObject;
const summarySelector = (state) => t(state, 'TreatmentClinicalRecordReducer.summary.formLoad.summary').safeObject;

export const formLoad = createSelector(
  treatmentSelector,
  treatmentTypesSelector,
  complaintsSelector,
  treatmentTimesSelector,
  bracesSelector,
  prescricoesSelector,
  slotsSelector,
  (
    treatment = {},
    treatmentTypes = [],
    complaints = [],
    treatmentTimes = [],
    braces = [],
    prescricoes = [],
    slots = []
  ) => {
    return {
      treatment: treatment.id,
      treatmentTypesOption: treatmentTypes.map((t) => ({ value: t.id, label: t.descricao, data: t })),
      complaintsOption: complaints.map((t) => ({ value: t.id, label: t.descricao, data: t })),
      treatmentTimesOption: [{}, ...treatmentTimes.map((t) => ({ value: t, label: t, data: t }))],
      bracesOption: braces.map((t) => ({ value: t.id, label: t.descricao, data: t })),
      prescricoesOption: prescricoes.map((t) => ({ value: t.id, label: t.descricao, data: t })),
      slotsOption: slots.map((t) => ({ value: t.id, label: t.descricao, data: t }))
    };
  }
);

export const formInit = createSelector(
  clinicalRecordSelector,
  treatmentSelector,
  treatmentTypesSelector,
  complaintsSelector,
  treatmentTimesSelector,
  bracesSelector,
  prescricoesSelector,
  slotsSelector,
  summarySelector,
  (
    clinicalRecord = {},
    treatment = {},
    treatmentTypes = [],
    complaints = [],
    treatmentTimes = [],
    braces = [],
    prescricoes = [],
    slots = [],
    summary = {}
  ) => {
    const { tipo_tratamento = {}, aparelho = {}, prescricao = {}, slot = {} } = summary;

    return {
      clinicalRecord: clinicalRecord.id,
      treatment: treatment.id,
      summary: summary.id,
      treatment_type: {
        value: tipo_tratamento.id,
        label: tipo_tratamento.descricao,
        data: tipo_tratamento
      },
      complaint: t(summary, 'queixas').isArray
        ? summary.queixas.map((q) => ({
            value: q.queixa.id,
            label: q.queixa.nome,
            data: q.queixa
          }))
        : [],
      treatment_plan: summary.plano_tratamento,
      tempoTratamentoDe: {
        value: summary.tempo_tratamento_de,
        label: summary.tempo_tratamento_de,
        data: summary.tempo_tratamento_de
      },
      tempoTratamentoAte: summary.tempo_tratamento_ate
        ? {
            value: summary.tempo_tratamento_ate,
            label: summary.tempo_tratamento_ate,
            data: summary.tempo_tratamento_ate
          }
        : {},
      atention: summary.atencao,
      brace: {
        value: aparelho.id,
        label: aparelho.descricao,
        data: aparelho
      },
      prescricao: {
        value: prescricao.id,
        label: prescricao.descricao,
        data: prescricao
      },
      slot: {
        value: slot.id,
        label: slot.descricao,
        data: slot
      },
      comercial_name: summary.nome_comercial
    };
  }
);
