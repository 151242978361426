import React, { Component } from 'react';
import { Col, Container, Row, Table } from 'reactstrap';
import Icon from '../../../components/Icon.component';
import people from '../../../assets/img/icons/people.svg';
import { formatDate } from '../../../utils/DateUtils';
import noavatar from '../../../assets/img/noavatar.png';
import { showToast } from '../TostPatient.component';
import { PERMISSOES } from '../../../config/security/permissoes';
import remarcar from '../../../assets/img/icons/remarcar.svg';
import { IfComponent } from '../../../components/if.component';
import withSecurity from '../../../config/security/security';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { scheduleAndRedialAddSaga, scheduleFromPatient } from '../redux/Schedule.actions';
import { withRouter } from 'react-router';
import AddListScheduleRedialModal from '../modal/AddListScheduleRedial.modal';
import { MdPlaylistAdd } from 'react-icons/md';
import { t } from 'typy';
import ClinicAPI from '../../../service/ClinicAPI';
import ScheduleAPI from '../../../service/ScheduleAPI';
import { hideLoader, showLoader } from '../../../components/actions/Loader.actions';
import NumberFormat from 'react-number-format';
import PercentLabelComponent from '../../../components/PercentLabelComponent';
import { IoIosPerson } from 'react-icons/io';
import ReactTooltip from 'react-tooltip';

class PatientFDS extends Component {
  schedule = async (scheduleFDS) => {
    const { showLoader, hideLoader, dentist } = this.props;
    let procedimento = {};
    showLoader();

    if (t(scheduleFDS, 'procedimento.id').isUndefined) {
      const configResponse = await ScheduleAPI.config(dentist.value);
      const config = configResponse.data;

      procedimento = {
        id: config.procedimento_id,
        codinome: config.procedimento_codinome,
        cor: config.procedimento_categoria_rgb
      };
    } else {
      procedimento = scheduleFDS.procedimento;
    }

    const schedule = {
      paciente: {
        id: scheduleFDS.id,
        nome: scheduleFDS.nome,
        numeroIdentificacao: scheduleFDS.numero,
        idade: scheduleFDS.idade,
        foto: scheduleFDS.imagem
      },
      procedimento: procedimento,
      data_agendamento: ''
    };

    showToast({
      schedule: schedule
    });

    this.props.scheduleFromPatient({
      schedule
    });
    hideLoader();
    this.props.history.push('/schedule');
  };

  render() {
    const { total, percent, patients = [], dentist } = this.props;

    return (
      <Container>
        <Row>
          <Col
            sm={12}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              paddingBottom: '10px',
              display: 'flex',
              height: 50
            }}
          >
            <IoIosPerson
              className='icon-fds'
              style={{ width: '34px', height: '34px', marginTop: '-3px' }}
              alt='people'
            />
            <span className='title-medium-plus text-uppercase text-secondary ml-3 mr-3' style={{ fontSize: '17px' }}>
              Pacientes Fora do Sistema (FDS)
            </span>{' '}
            <span
              className={'procedure-primary ml-2'}
              style={{
                backgroundColor: '#f4f7fd',
                width: 93,
                height: 28,
                color: '#000',
                borderColor: '#aeb6c5',
                borderStyle: 'solid',
                borderWidth: 1,
                textAlign: 'center',
                verticalAlign: 'middle'
              }}
            >
              <span style={{ fontSize: 16, fontWeight: 600, color: '#434c5e' }}> {total} </span>
              <span className='ml-1' style={{ fontSize: 13, fontWeight: 400, color: '#434c5e' }}>
                <PercentLabelComponent percent={percent} />
              </span>
            </span>
          </Col>
        </Row>
        <Row>
          <Col className={'tableResult'} style={{ marginTop: '-5px' }}>
            <Table className='table-header-fixed' responsive>
              <thead style={{ backgroundColor: '#ebecf2' }}>
                <tr className='row py-2' style={{ height: '36px ' }}>
                  <th className='col-sm-4 py-0'>
                    <span className='text-sm text-secondary-d4 font-weight-bold'>Paciente</span>
                  </th>
                  <th className='col-sm-2 pl-5 py-0'>
                    <span className='text-sm text-secondary-d4 font-weight-bold text-nowrap'>Último Atend</span>
                  </th>
                  <th className='col-sm-5'></th>
                  <th className='col-sm-1 icon-tab-schedu non-hover py-0 top'>
                    <div style={{ maxHeight: 20 }} className='float-right'>
                      <a data-tip data-for={'tooltipInserir'} href={'#'}>
                        <MdPlaylistAdd size={40} style={{ paddingBottom: 15 }} className='icon' alt='inseriricon' />{' '}
                        &nbsp;
                      </a>
                      <ReactTooltip id='tooltipInserir' className='tooltip-griks' type='light'>
                        Inseir na Lista A/R
                      </ReactTooltip>
                      <a data-tip data-for={'tooltipAgendar'} href={'#'}>
                        <Icon className='icon icon-size-one' image={remarcar} alt='returnicon' /> &nbsp;
                      </a>
                      <ReactTooltip id='tooltipAgendar' className='tooltip-griks' type='light'>
                        Agendar Consulta
                      </ReactTooltip>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody style={{ maxHeight: 'calc(100vh - 320px)', overflow: 'unset' }}>
                {patients.map((p) => {
                  return (
                    <tr key={p.id} className={'row py-0 box-basic px-0'}>
                      <td className='col-sm-4'>
                        <Row className='pb-0'>
                          <Col sm={2}>
                            <div>
                              <img
                                className={'img-user-avatar' + ' img-xxldpi'}
                                src={p.imagem || noavatar}
                                alt='usuario'
                              />
                            </div>
                          </Col>
                          <Col sm={10} className='p-0'>
                            <div className='title-medium pl-4'>
                              <p className='text-sm font-bold text-secondary text-nowrap'>{p.nome}</p>
                              <p className='text-sm font-light text-secondary'>{p.idade}</p>
                            </div>
                          </Col>
                        </Row>
                      </td>
                      <td className='col-sm-2 pl-5'>
                        <span className='text-sm text-secondary'>
                          {p.dataUltimoAgendamento && formatDate(p.dataUltimoAgendamento, 'DD/MM/YYYY')}
                        </span>
                      </td>
                      <td className='col-sm-5'></td>
                      <td className='col-sm-1 icon-tab-schedu'>
                        <IfComponent
                          conditional={this.props.security.hasPermission(
                            PERMISSOES.modules.agenda.id,
                            PERMISSOES.modules.agenda.permissoes.editar
                          )}
                        >
                          <div className='float-right'>
                            <a href={'#'} onClick={() => this.props.scheduleAndRedialAdd({ dentist, patient: p })}>
                              <MdPlaylistAdd
                                size={40}
                                style={{ paddingBottom: 15 }}
                                className='icon'
                                alt='inseriricon'
                              />{' '}
                              &nbsp;
                            </a>
                            <a href={'#'} onClick={() => this.schedule(p)}>
                              <Icon className='icon icon-size-one' image={remarcar} alt='returnicon' />
                            </a>
                          </div>
                        </IfComponent>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
        <AddListScheduleRedialModal key={'AddListScheduleRedialModal'} updateParent={this.props.updateParent} />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  scheduleFromPatient: (data) => dispatch(scheduleFromPatient(data)),
  scheduleAndRedialAdd: (data) => dispatch(scheduleAndRedialAddSaga(data))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withSecurity, withRouter);
export default enhanced(PatientFDS);
