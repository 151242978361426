import React, { Component } from 'react';
import { UncontrolledCollapse } from 'reactstrap';
import PropTypes from 'prop-types';
import './RestrictionsCollapse.css';

const RestrictionsCollapse = (props) => {
  const { restrictions, toggler, selected, id, onChange, ref } = props;

  let className = 'collapse-box';
  if (selected && selected.length > 0) {
    className += ' show';
  }

  return (
    <UncontrolledCollapse toggler={toggler} className={className} id={`${id}`}>
      <div id={id} className='restrictions-box' ref={ref}>
        <hr />
        Restrições
        <div className='restrictions-list'>
          {restrictions.map((r) => {
            return (
              <div className='restrictions-item'>
                <input
                  type={'checkbox'}
                  name={`permissions[mol_${r.id}].restrictions['rest_${r.value}']`}
                  id={`permissions[mol_${r.id}].restrictions['rest_${r.value}']`}
                  onChange={(e) => {
                    const data = {
                      target: {
                        name: e.target.name,
                        value: e.target.checked ? { restriction_id: r.value } : {}
                      }
                    };

                    onChange(data);
                  }}
                />{' '}
                {r.label}
              </div>
            );
          })}
        </div>
      </div>
    </UncontrolledCollapse>
  );
};

RestrictionsCollapse.propTypes = {
  restrictions: PropTypes.array.isRequired,
  toggler: PropTypes.string.isRequired,
  selected: PropTypes.array.isRequired
};

export default RestrictionsCollapse;
