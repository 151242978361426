import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import minus from '../../../../assets/img/icons/minus.svg';
import plus from '../../../../assets/img/icons/plus.svg';
import Icon from '../../../../components/Icon.component';
import { FieldArray, Form, Formik } from 'formik';
import TreatmentClinicalRecordApi from '../../../../service/TreatmentClinicalRecordApi';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../../../components/actions/Loader.actions';
import t from 'typy';
import ToastUtils from '../../../../utils/ToastUtils';

const TreatmentClinicalRecordMaxilaModal = ({ updateParent }, modalRef) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [sequencias, setSequencias] = useState([]);
  const [treatment, setTreatment] = useState(null);
  const [record, setRecord] = useState(null);
  const [plan, setPlan] = useState(null);

  useImperativeHandle(modalRef, () => ({
    openModal({ sequencias, treatment, record, plan }) {
      setShowModal(true);
      if (sequencias.length == 0) {
        sequencias = [''];
      }
      setSequencias(sequencias);
      setTreatment(treatment);
      setRecord(record);
      setPlan(plan);
    }
  }));

  function closeModal() {
    setShowModal(false);
  }

  function salvarSequencia(values) {
    dispatch(showLoader());
    TreatmentClinicalRecordApi.savePlanoTratamentoSequenciaMaxila(treatment, record, plan.id, values.sequencias)
      .then((_) => {
        dispatch(hideLoader());
        updateParent();
        closeModal();
      })
      .catch((err) => {
        console.error(err);
        dispatch(hideLoader());
        ToastUtils.error('Erro ao salvar dados de sequência');
      });
  }

  return (
    <Modal isOpen={showModal} toggle={closeModal}>
      <ModalHeader
        className='title-primary'
        toggle={closeModal}
        close={
          <button className='close' onClick={closeModal}>
            &times;
          </button>
        }
      >
        <strong>Adicionar</strong> Sequência Clínica
      </ModalHeader>
      <Formik
        initialValues={{
          sequencias: t(sequencias).safeArray.map((s) => s.sequencia)
        }}
        enableReinitialize={true}
        onSubmit={salvarSequencia}
      >
        {({ values, handleChange, errors }) => {
          console.log(values);
          return (
            <Form>
              <FieldArray
                name={'sequencias'}
                render={(arrayHelpers) => (
                  <>
                    <ModalBody>
                      <div className='margin-top-10'>
                        <Row>
                          <Col>
                            <h1 className='title-modal float-left'>NA MAXILA</h1>
                            <a
                              style={{ marginTop: -5 }}
                              className='float-left ml-2'
                              href={'#'}
                              onClick={() => arrayHelpers.push('')}
                            >
                              <Icon className='icon icon-size-two' image={plus} alt='plus' />
                            </a>
                          </Col>
                        </Row>
                        {values.sequencias.map((s, index) => {
                          return (
                            <div key={index}>
                              <Row className='margin-top-10'>
                                <Col sm='1'>
                                  <label className={'absolute-center'}>{index + 1}.</label>
                                </Col>
                                <Col sm={10}>
                                  <Input
                                    name={`sequencias.${index}`}
                                    value={values.sequencias[index]}
                                    className='input-secondary'
                                    type='text'
                                    onChange={handleChange}
                                    maxLength={70}
                                  ></Input>
                                </Col>
                                <Col sm='1'>
                                  <a href={'#'} onClick={() => arrayHelpers.remove(index)}>
                                    <Icon className='icon icon-size-two absolute-center' image={minus} alt='minus' />
                                  </a>
                                </Col>
                              </Row>
                            </div>
                          );
                        })}
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Row>
                        <Col sm={{ size: 3, offset: 6 }}>
                          <button className='button-primary'>Salvar</button>
                        </Col>
                        <Col sm='3'>
                          <button className='button-tertiary' type={'button'} onClick={closeModal}>
                            Cancelar
                          </button>
                        </Col>
                      </Row>
                    </ModalFooter>
                  </>
                )}
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default forwardRef(TreatmentClinicalRecordMaxilaModal);
