import { TREATMENT_CHANGE_SITUATION_MODAL, TREATMENT_REGISTER_MODAL } from './Treatment.action';

const initialState = {};

const TreatmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case TREATMENT_REGISTER_MODAL: {
      return {
        ...state,
        registerModal: {
          ...action.payload
        }
      };
    }
    case TREATMENT_CHANGE_SITUATION_MODAL: {
      return {
        ...state,
        changeSituationModal: {
          ...action.payload
        }
      };
    }
    default: {
      return state;
    }
  }
};

export default TreatmentReducer;
