import {
  TREATMENT_CLINICAL_ATTENDENCIE_MODAL,
  TREATMENT_CLINICAL_ATTENDENCIES,
  TREATMENT_CLINICAL_RECORD_ANTICIPATE_CONFIRM_MODAL,
  TREATMENT_CLINICAL_RECORD_DIAGNOSTIC,
  TREATMENT_CLINICAL_RECORD_DIAGNOSTIC_ANGLE_MODAL,
  TREATMENT_CLINICAL_RECORD_DIAGNOSTIC_OTHER_INFOS_MODAL,
  TREATMENT_CLINICAL_RECORD_DIAGNOSTIC_PROBLEMS_MODAL,
  TREATMENT_CLINICAL_RECORD_PAGE,
  TREATMENT_CLINICAL_RECORD_PLANS,
  TREATMENT_CLINICAL_RECORD_PLANS_CONTENTION_MODAL,
  TREATMENT_CLINICAL_RECORD_PLANS_GADGETS_MODAL,
  TREATMENT_CLINICAL_RECORD_PLANS_GOALS_MODAL,
  TREATMENT_CLINICAL_RECORD_PLANS_OTHERS_DETAILS_MODAL,
  TREATMENT_CLINICAL_RECORD_PLANS_STRATEGIES_MODAL,
  TREATMENT_CLINICAL_RECORD_SUMMARY_MODAL
} from './TreatmentClinicalRecord.action';

const initialState = {};

const TreatmentClinicalRecordReducer = (state = initialState, action) => {
  switch (action.type) {
    case TREATMENT_CLINICAL_RECORD_PAGE: {
      return {
        ...state,
        page: {
          ...action.payload
        }
      };
    }
    case TREATMENT_CLINICAL_RECORD_SUMMARY_MODAL: {
      return {
        ...state,
        summary: {
          ...action.payload
        }
      };
    }
    case TREATMENT_CLINICAL_ATTENDENCIES: {
      return {
        ...state,
        attendence: {
          ...action.payload
        }
      };
    }
    case TREATMENT_CLINICAL_ATTENDENCIE_MODAL: {
      return {
        ...state,
        attendenceModal: {
          ...action.payload
        }
      };
    }
    case TREATMENT_CLINICAL_RECORD_DIAGNOSTIC: {
      return {
        ...state,
        diagnostic: {
          ...action.payload
        }
      };
    }
    case TREATMENT_CLINICAL_RECORD_DIAGNOSTIC_ANGLE_MODAL: {
      return {
        ...state,
        diagnosticModal: {
          ...action.payload
        }
      };
    }
    case TREATMENT_CLINICAL_RECORD_DIAGNOSTIC_PROBLEMS_MODAL: {
      return {
        ...state,
        diagnosticProblemsModal: {
          ...action.payload
        }
      };
    }
    case TREATMENT_CLINICAL_RECORD_DIAGNOSTIC_OTHER_INFOS_MODAL: {
      return {
        ...state,
        diagnosticOtherInfosModal: {
          ...action.payload
        }
      };
    }
    case TREATMENT_CLINICAL_RECORD_PLANS: {
      return {
        ...state,
        treatmentPlanPage: {
          ...action.payload
        }
      };
    }
    case TREATMENT_CLINICAL_RECORD_PLANS_GOALS_MODAL: {
      return {
        ...state,
        treatmentPlanGoalModal: {
          ...action.payload
        }
      };
    }
    case TREATMENT_CLINICAL_RECORD_PLANS_STRATEGIES_MODAL: {
      return {
        ...state,
        treatmentPlanStrategiesModal: {
          ...action.payload
        }
      };
    }
    case TREATMENT_CLINICAL_RECORD_PLANS_OTHERS_DETAILS_MODAL: {
      return {
        ...state,
        treatmentPlanOtherDetailsModal: {
          ...action.payload
        }
      };
    }
    case TREATMENT_CLINICAL_RECORD_PLANS_CONTENTION_MODAL: {
      return {
        ...state,
        treatmentPlanContentionModal: {
          ...action.payload
        }
      };
    }
    case TREATMENT_CLINICAL_RECORD_PLANS_GADGETS_MODAL: {
      return {
        ...state,
        treatmentPlanGadgetsModal: {
          ...action.payload
        }
      };
    }
    case TREATMENT_CLINICAL_RECORD_ANTICIPATE_CONFIRM_MODAL: {
      return {
        ...state,
        anticipateDebit: {
          ...action.payload
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default TreatmentClinicalRecordReducer;
