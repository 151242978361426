import RestTemplate from '../utils/restTemplate/rest.template';

export default class AffiliatesApi {
  static getAffiliate(clinic) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/afiliados/clinica/${clinic}/getAfiliado/`).then(({ data }) => data);
  }

  static findCupons(affiliate) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/afiliados/${affiliate}/cupons/`).then(({ data }) => data);
  }

  static findReportData(affiliate) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/afiliados/${affiliate}/report`);
  }

  static saveAffiliate(affiliate) {
    const restTemplate = new RestTemplate(false);
    return restTemplate.post('/afiliados/', affiliate).then(({ data }) => data);
  }
}
