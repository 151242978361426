import React, { Component } from 'react';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { t } from 'typy';
import TreatmentImagesApi from '../../../../service/TreatmentImagesApi';
import ModalMessageConfirm from '../../../../components/ModalMessageConfirm.component';
import warning from '../../../../assets/img/icons/warning.svg';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { hideLoader, showLoader } from '../../../../components/actions/Loader.actions';
import ToastUtils from '../../../../utils/ToastUtils';
import { AiOutlineFolder } from 'react-icons/ai';
import { MdClose } from 'react-icons/md';
import ImageGallery from 'react-image-gallery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import env from '../../../../environment/env';

class TreatmentImageCarouselModel extends Component {
  state = {
    activeIndex: 0,
    confirmModal: false,
    itemSelected: {},
    itemName: ''
  };

  galeryRef;

  constructor(props) {
    super(props);
    this.galeryRef = React.createRef();
  }

  next = () => {
    if (this.props.images.length - 1 > this.state.activeIndex) {
      this.setState({
        activeIndex: this.state.activeIndex + 1
      });
    }
  };

  previous = () => {
    if (this.state.activeIndex > 0) {
      this.setState({
        activeIndex: this.state.activeIndex - 1
      });
    }
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (nextProps.activeIndex !== this.props.activeIndex) {
      if (t(nextProps, 'activeIndex').isDefined) {
        setTimeout(() => this.galeryRef.current.slideToIndex(nextProps.activeIndex - 1), 10);
      }
    }
    return true;
  }

  openConfirmDelete = (itemSelected) => {
    this.setState({
      confirmModal: true,
      itemSelected
    });
  };

  render() {
    const { confirmModal, itemSelected } = this.state;

    return (
      <div>
        <Modal isOpen={this.props.open} size={'lg'} contentClassName={'border-radius-15'}>
          <ModalBody style={{ backgroundColor: 'black', paddingLeft: 30, paddingRight: 30 }}>
            <ModalHeader
              className={'w-100 m-0 p-0 mb-2'}
              toggle={this.props.closeModal}
              close={
                <a href={'#'} onClick={this.props.closeModal}>
                  <MdClose color='#fff' size={24} />
                </a>
              }
            >
              <div className={'text-center w-100'}>
                <AiOutlineFolder style={{ marginTop: '-3px' }} color='#fff' size={24} />
                &nbsp;&nbsp;{' '}
                <span
                  style={{
                    color: '#ffffff',
                    fontSize: 15,
                    fontWeight: 400
                  }}
                >
                  {t(this.props, 'folder.folder_name').safeString}&nbsp;&nbsp; &nbsp; - &nbsp;&nbsp; &nbsp;
                  {this.galeryRef.current != null &&
                    t(this.props.images[this.galeryRef.current.getCurrentIndex()], 'name').safeString}
                </span>
              </div>
            </ModalHeader>
            <ImageGallery
              ref={this.galeryRef}
              items={t(this.props, 'images').safeArray.map((item) => {
                return {
                  original: `${env.imaginary.baseUrl}/resize?width=750&height=500&type=jpeg&url=${item.url}`,
                  thumbnail: item.thumbnail,
                  fullscreen: item.url,
                  originalTitle: item.name
                };
              })}
              renderLeftNav={(onClick, disabled) => {
                return <div className='image-gallery-custom-left-nav' disabled={disabled} />;
              }}
              renderRightNav={(onClick, disabled) => {
                return <div className='image-gallery-custom-right-nav' disabled={disabled} />;
              }}
              renderPlayPauseButton={(onClick, isPlaying) => {
                return (
                  <a
                    href={'#'}
                    className={'image-gallery-icon image-gallery-play-button'}
                    onClick={() => {
                      this.openConfirmDelete(this.props.images[this.galeryRef.current.getCurrentIndex()]);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} color={'#fff'} size={'lg'} />
                  </a>
                );
              }}
              onImageLoad={(item) => this.setState({ itemName: item.name })}
            />
            {/*<Carousel*/}
            {/*  activeIndex={ this.state.activeIndex }*}
            {/*  next={ this.next }*/}
            {/*  previous={ this.previous }*/}
            {/*  interval={ '1000000' }*/}
            {/*  className={ 'w-100 d-flex justify-content-center flex-column align-items-center' }*/}
            {/*>*/}
            {/*  /!*<CarouselIndicators items={t(this.props, 'images').safeArray} activeIndex={this.state.activeIndex} onClickHandler={this.goToIndex}  className={'p-4'}/>*/}

            {/*  { t(this.props, 'images').safeArray.map((item) => {*/}
            {/*    return (*/}
            {/*      <CarouselItem key={ item.order } slide={ false } className={ 'text-center' }>*/}
            {/*        <img src={ `http://images.griks.com.br/resize?width=750&height=500&type=jpeg&url=${item.url}` } style={ { width: 750, height: 500 } }/>*/}
            {/*        <div className={ 'w-100 d-flex justify-content-lg-between' } style={{width: 750,}}>*/}
            {/*          <div className={ 'text-center p-2 font-light w-100 ml-5' } style={ { color: '#fff', fontSize:'15px' } }>*/}
            {/*            { item.name }*/}
            {/*          </div>*/}
            {/*          <div className={ 'd-flex align-items-center mr-4' } style={{zIndex: 999}}>*/}
            {/*            <a href={ '#' } onClick={ () => this.openConfirmDelete(item) } >*/}
            {/*              <FontAwesomeIcon*/}
            {/*                icon={ faTrashAlt } color={ '#fff' } size={ 'lg' }/>*/}
            {/*            </a>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </CarouselItem>*/}
            {/*    );*/}
            {/*  }) }*/}
            {/*  <CarouselControl direction="prev" directionText="Previous" onClickHandler={ this.previous }/>*/}
            {/*  <CarouselControl direction="next" directionText="Next" onClickHandler={ this.next }/>*/}
            {/*</Carousel> }*/}
          </ModalBody>
        </Modal>
        <ModalMessageConfirm
          title={'Confirma a Exclusão'}
          message={
            <div className={'text-center text-secondary'} style={{ fontSize: '14px' }}>
              Confirma a Exclusão da imagem {itemSelected.name} ?
            </div>
          }
          openModal={confirmModal}
          close={this._closeModalConfirm}
          icon={warning}
          footer={
            <Row>
              <Col sm='3'>
                <button className='button-tertiary text-secondary' onClick={this._closeModalConfirm}>
                  Não
                </button>
              </Col>
              <Col sm={{ size: 3, offset: 6 }}>
                <button className='button-primary' onClick={this._deleteImage}>
                  Sim
                </button>
              </Col>
            </Row>
          }
        />
      </div>
    );
  }

  _closeModalConfirm = () => {
    this.setState({
      confirmModal: false,
      itemSelected: {}
    });
  };

  _deleteImage = async () => {
    const { treatment, closeModal, showLoader, hideLoader } = this.props;
    const { itemSelected } = this.state;
    try {
      showLoader();
      await TreatmentImagesApi.deleteImage(treatment, itemSelected.id);
      this.galeryRef.current.slideToIndex(this.galeryRef.current.getCurrentIndex() - 1);
      this.props.updateParent();
      this._closeModalConfirm();
      closeModal();
      hideLoader();
    } catch (e) {
      console.error(e);
      hideLoader();
      ToastUtils.error('Erro ao deletar imagem, entre em contato com o suporte');
    }
  };
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader())
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(TreatmentImageCarouselModel);
