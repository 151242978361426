import React, { Component } from 'react';
import { Alert, Col, Row, UncontrolledDropdown, UncontrolledTooltip } from 'reactstrap';
import edit from '../../../assets/img/icons/edit.svg';
import Icon from '../../../components/Icon.component';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { t } from 'typy';
import {
  clinicalRecordDiagnosticOtherInfosModal,
  clinicalRecordDiagnosticPreLoadModal,
  clinicalRecordDiagnosticProblemsModal
} from './redux/TreatmentClinicalRecord.action';
import TreatmentClinicalRecordDiagnosticAngleModal from './modals/TreatmentClinicalRecordDiagnosticAngle.modal';
import TreatmentClinicalRecordDiagnosticProblemsModal from './modals/TreatmentClinicalRecordDiagnosticProblems.modal';
import TreatmentClinicalRecordDiagnosticOtherInformationModal from './modals/TreatmentClinicalRecordDiagnosticOtherInformation.modal';
import { IfComponent } from '../../../components/if.component';
import withSecurity from '../../../config/security/security';
import { PERMISSOES } from '../../../config/security/permissoes';
import { IoIosHelpCircle } from 'react-icons/io';
import { FiEdit } from 'react-icons/fi';
import { AiOutlineTag } from 'react-icons/ai';
import TreatmentClinicalRecordKeywordsModal from './modals/TreatmentClinicalRecordKeywords.modal';

class TreatmentClinicalRecordDiagnosticPage extends Component {
  tagRefModal;

  constructor(props) {
    super(props);
    this.tagRefModal = React.createRef();
  }

  render() {
    const {
      diagnostic,
      angleModal,
      treatment,
      record,
      updateParent,
      error,
      problems,
      otherInfos,
      enabledEdit,
      security
    } = this.props;

    const clinicalRecordPermission = PERMISSOES.modules.tratamento_ficha_clinica;
    const hasPermission =
      security.hasPermission(clinicalRecordPermission.id, clinicalRecordPermission.permissoes.editar) ||
      security.hasPermission(clinicalRecordPermission.id, clinicalRecordPermission.permissoes.editar_restricao.id);

    return [
      <Row className='margin-top-10' key={'page'}>
        <Col sm='12'>
          {error !== '' ? (
            <div className='box-default'>
              <Alert color={'danger'}>{error}</Alert>
            </div>
          ) : (
            <div className='box-default'>
              <Row>
                <Col sm='7'>
                  <h2 className='title-medium-plus text-secondary text-uppercase mb-3'>
                    Classificação de Angle
                    <IfComponent conditional={enabledEdit && hasPermission}>
                      <a
                        href={'#'}
                        onClick={() => {
                          angleModal({ modal: true, diagnostic, treatment, record });
                        }}
                      >
                        <Icon className='icon icon-size-small margin-left-10' image={edit} alt='edit' />
                      </a>
                    </IfComponent>
                  </h2>
                  <h3 className='title-small text-center text-default text-sm'>
                    <strong className='ml-3'>Classificação de Angle</strong>{' '}
                    {t(diagnostic, 'classificacaoAngle.classificacao_angle.descricao').safeString}
                  </h3>
                  <Row className='margin-top-20'>
                    <Col className='margin-top-20 text-right' sm='1'>
                      Dir
                    </Col>
                    <Col sm='10'>
                      <Row>
                        <Col sm='6' className='border-bottom border-right p-1'>
                          <Row className='text-center'>
                            <Col sm='6'>6</Col>
                            <Col sm='6'>3</Col>
                          </Row>
                        </Col>
                        <Col sm='6' className='border-bottom p-1'>
                          <Row className='text-center'>
                            <Col sm='6'>3</Col>
                            <Col sm='6'>6</Col>
                          </Row>
                        </Col>
                        <Col sm='6' className='border-right p-1'>
                          <Row className='text-center'>
                            <Col sm='6'>
                              {t(diagnostic, 'classificacaoAngle.dente_direito6.descricao').safeString}&nbsp;
                            </Col>
                            <Col sm='6'>{t(diagnostic, 'classificacaoAngle.dente_direito3.descricao').safeString}</Col>
                          </Row>
                        </Col>
                        <Col sm='6' className='p-1'>
                          <Row className='text-center'>
                            <Col sm='6'>{t(diagnostic, 'classificacaoAngle.dente_esquerdo3.descricao').safeString}</Col>
                            <Col sm='6'>{t(diagnostic, 'classificacaoAngle.dente_esquerdo6.descricao').safeString}</Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col className='margin-top-20' sm='1'>
                      Esq
                    </Col>
                  </Row>
                  <Row className='margin-top-10'>
                    <Col sm='12'>
                      <p className='text-default text-sm text-center mb-2'>
                        <strong>Classificação esquelética AP</strong>{' '}
                        {t(diagnostic, 'classificacaoAngle.classe_esqueletica.descricao').safeString}
                      </p>
                      <p className='text-default text-sm text-center mb-2'>
                        <strong>Padrão Esquelético-Cefálico</strong>{' '}
                        {t(diagnostic, 'classificacaoAngle.padrao_esqueletico.descricao').safeString}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col sm={{ size: 5 }}>
                  <h2 className='title-medium-plus text-secondary text-uppercase mb-3'>
                    Keywords - Tags
                    <a id='help' href={'#'}>
                      <IoIosHelpCircle className='icon icon-size-small margin-left-10' color='#646874' size={20} />
                    </a>
                    <UncontrolledTooltip
                      className='tooltip-white text-xsm font-weight-light'
                      placement='top'
                      target='help'
                    >
                      Crie palavras chaves, relacionadas ao caso, para que possa depois localizar, através da ferramenta
                      de busca.
                    </UncontrolledTooltip>
                    <a
                      href={'javascript:'}
                      onClick={() => this.tagRefModal.current.showModal(diagnostic, treatment, record)}
                    >
                      <Icon className='icon icon-size-small margin-left-10 mb-1' image={edit} alt='edit' />
                    </a>
                  </h2>
                  <div className={'margin-top-10'}>
                    <p className=''>
                      {t(diagnostic, 'tags').safeArray.map((tag) => {
                        return (
                          <p className={'w-100 border-bottom px-1 py-2'} key={tag.id}>
                            <span>
                              <AiOutlineTag className='icon-rotate-90' color='#646874' size={20} />
                              <span className='ml-2 text-secondary'>{t(tag, 'tag.nome').safeString}</span>
                            </span>
                          </p>
                        );
                      })}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className='margin-top-10'>
                <Col sm='12'>
                  <h2 className='text-md-plus text-secondary text-uppercase font-bold'>
                    Outras Anotações
                    <IfComponent conditional={enabledEdit && hasPermission}>
                      <a
                        href={'#'}
                        onClick={() => {
                          otherInfos({ modal: true, diagnostic, treatment, record });
                        }}
                      >
                        <Icon className='icon icon-size-lab ml-2' image={edit} alt='edit' />
                      </a>
                    </IfComponent>
                  </h2>
                  {/*<p><strong>Fatos de Risco:</strong> { t(diagnostic, 'fator_risco').safeString }</p>*/}
                  {/*<p><strong>Limitações e*/}
                  {/*  Compromissos:</strong> { t(diagnostic, 'limitacoes_compromissos').safeString }*/}
                  {/*</p>*/}
                  <p className='text-default'>
                    <strong className='text-sm text-secondary'>Anotações</strong>{' '}
                    {t(diagnostic, 'outras_anotacoes').safeString}
                  </p>
                </Col>
              </Row>
            </div>
          )}
        </Col>
      </Row>,
      <TreatmentClinicalRecordDiagnosticAngleModal
        key={'TreatmentClinicalRecordDiagnosticAngleModal'}
        updateParent={updateParent}
      />,
      <TreatmentClinicalRecordDiagnosticProblemsModal
        key={'TreatmentClinicalRecordDiagnosticProblemsModal'}
        updateParent={updateParent}
      />,
      <TreatmentClinicalRecordDiagnosticOtherInformationModal
        key={'TreatmentClinicalRecordDiagnosticOtherInformationModal'}
        updateParent={updateParent}
      />,

      <TreatmentClinicalRecordKeywordsModal
        key={'TreatmentClinicalRecordKeywordsModal'}
        ref={this.tagRefModal}
        updateParent={updateParent}
      />
    ];
  }
}

const mapStateToProps = ({ TreatmentClinicalRecordReducer: { diagnostic } }) => {
  return {
    ...diagnostic
  };
};

const mapDispatchToProps = (dispatch) => ({
  angleModal: (data) => dispatch(clinicalRecordDiagnosticPreLoadModal(data)),
  problems: (data) => dispatch(clinicalRecordDiagnosticProblemsModal(data)),
  otherInfos: (data) => dispatch(clinicalRecordDiagnosticOtherInfosModal(data))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withSecurity);

export default enhanced(TreatmentClinicalRecordDiagnosticPage);
