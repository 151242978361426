import React, { Component } from 'react';
import Calendar from '../../components/Calendar.component';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { setHeader } from '../template/redux/Content.actions';
import { schedulePageSaga } from './redux/Schedule.actions';
import moment from 'moment';
import ScheduleEventModal from './modal/ScheduleEvent.modal';
import ScheduleAppointmentSelectPatientModal from './modal/ScheduleAppointmentSelectPatient.modal';
import ScheduleAppointmentSelectTreatmentModal from './modal/ScheduleAppointmentSelectTreatment.modal';
import ScheduleAppointmentDetailModal from './modal/ScheduleAppointmentDetail.modal';
import ScheduleMenuComponent from './ScheduleMenu.component';
import calendar from '../../assets/img/icons/calendar3.svg';

class ScheduleHomePage extends Component {
  state = {};

  componentDidMount() {
    this.props.setHeader({
      title: 'Agenda',
      image: calendar
    });
  }

  loadSchedule = (dentist, day = moment()) => {
    this.setState({ dentistSelected: dentist.data });
  };

  updateSchedule = (day) => {
    this.loadSchedule(this.state.dentistSelected, day);
  };

  render() {
    return [
      <ScheduleMenuComponent
        key='ScheduleMenuComponent'
        dentistSelected={this.loadSchedule}
        url={this.props.location.pathname}
      />,
      <div className={'content-box-without-color'} key={'home'}>
        <Row>
          <Col sm={12}>
            <div className='box-default top-rec'>
              <Calendar updateParent={this.updateSchedule} dentistSelected={this.state.dentistSelected || {}} />
            </div>
          </Col>
        </Row>
      </div>,
      <ScheduleAppointmentSelectPatientModal key={'ScheduleAppointmentSelectPatientModal'} />,
      <ScheduleAppointmentSelectTreatmentModal key={'ScheduleAppointmentSelectTreatmentModal'} />
      // <ScheduleAppointmentSelectPatientNew key={'ScheduleAppointmentSelectPatientNew'} />
    ];
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  setHeader: (data) => dispatch(setHeader(data))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(ScheduleHomePage);
