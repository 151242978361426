import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Select from 'react-select';
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Table,
  UncontrolledTooltip
} from 'reactstrap';
import { compose } from 'recompose';
import { t } from 'typy';
import { selectStyles } from '../../../../src/config/core.config';
import calendar from '../../../assets/img/icons/calendar.svg';
import calendar2 from '../../../assets/img/icons/calendar2.svg';
import danger from '../../../assets/img/icons/danger1.svg';
import edit from '../../../assets/img/icons/edit.svg';
import excluir from '../../../assets/img/icons/excluir.svg';
import success from '../../../assets/img/icons/success.svg';
import whats from '../../../assets/img/icons/whatsapp_color.svg';
import noavatar from '../../../assets/img/noavatar.png';
import { hideLoader, showLoader } from '../../../components/actions/Loader.actions';
import Icon from '../../../components/Icon.component';
import { IfComponent } from '../../../components/if.component';
import MessagesComponent from '../../../components/Messages.component';
import ToastCustomMessageComponent from '../../../components/ToastCustomMessage.component';
import { PERMISSOES } from '../../../config/security/permissoes';
import withSecurity from '../../../config/security/security';
import ScheduleAPI from '../../../service/ScheduleAPI';
import TreatmentClinicalRecordApi from '../../../service/TreatmentClinicalRecordApi';
import TreatmentFinancialFileApi from '../../../service/TreatmentFinancialFileApi';
import { formatDate } from '../../../utils/DateUtils';
import { phoneFormat } from '../../../utils/Formatter';
import ToastUtils from '../../../utils/ToastUtils';
import TreatmentClinicalRecordAttendenceModal from '../../treatment/clinicalRecord/modals/TreatmentClinicalRecordAttendence.modal';
import { clinicalRecordAttendanciePreModal } from '../../treatment/clinicalRecord/redux/TreatmentClinicalRecord.action';
import TreatmentFinancialLaunchPaymentModal from '../../treatment/financialFile/modals/TreatmentFinancialLaunchPayment.modal';
import { financialLaunchPaymentSagaModal } from '../../treatment/financialFile/redux/FinancialFile.action';
import {
  appointmentDelete,
  appointmentHistorySagaModal,
  appointmentMissing,
  appointmentRedialModal,
  scheduleAppointmentDetailModal,
  scheduleAppointmentDetailSagaModal,
  scheduleAppointmentPatientSagaModal,
  scheduleNextAppointment,
  sendToAppointmentAndRedialSaga,
  uncheckAppointmentSagaModal
} from '../redux/Schedule.actions';
import { scheduleAppointmentDetailReducerToProps } from '../selectors/ScheduleAppointmentDetail.selector';
import { showToast } from '../TostPatient.component';
import AppointmentDeleteModal from './AppointmentDelete.modal';
import AppointmentHistoryModal from './AppointmentHistory.modal';
import AppointmentMissingModal from './AppointmentMissing.modal';
import AppointmentRedialModal from './AppointmentRedial.modal';
import ScheduleControlReturnAddModal from './ScheduleControlReturnAddModal';
import SendToAppointmentAndRedialModal from './SendToAppointmentAndRedial.modal';
import UncheckAppointmentModal from './UncheckAppointment.modal';
import UserAPI from '../../../service/User.service';
import { getDentistSelected } from '../../../utils/localStorage/SessionStorageUtils';

class ScheduleAppointmentDetailModal extends Component {
  state = {
    scheduleStatusSelected: {},
    changeStatus: false,
    configs:{},
    dentistSelected: null,
    dentists: [],
    dentistsOptions: [],
    error: {
      display: false,
      type: '',
      text: ''
    }
  };

  controlReturnRef;

  constructor(props, context) {
    super(props, context);
    this.controlReturnRef = React.createRef();
  }

  scheduleEdit = () => {
    const { editSchedule, showLoader, hideLoader, schedule } = this.props;

    showLoader();
    editSchedule({
      date: moment(schedule.data_agendamento),
      modal: true,
      dentist: { id: schedule.dentista_id },
      chair: { id: schedule.cadeira_id },
      start: moment(schedule.horario_de, 'HH:mm'),
      end: moment(schedule.horario_ate, 'HH:mm'),
      patient: schedule.paciente,
      treatment: t(schedule, 'tratamento').safeObject,
      schedule
    });

    hideLoader();

    this.closeModal();
  };

  changeStatus = (scheduleStatusSelected) => {
    const { schedule, showLoader, hideLoader, updateModal, updateParent } = this.props;

    showLoader();
    ScheduleAPI.changeStatusSchedule({
      consulta: schedule.id,
      status: scheduleStatusSelected
    })
      .then((d) => {
        updateModal({
          modal: true,
          schedule
        });
        updateParent(moment(schedule.data_agendamento).add(2, 'm'));

        hideLoader();
        this.setState({
          changeStatus: false
          // error: {
          //   display: true,
          //   type: 'info',
          //   text: 'Status alterado com sucesso'
          // }
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          error: {
            display: true,
            type: 'danger',
            text: 'Erro ao alterar status entre em contato com o suporte'
          }
        });

        hideLoader();
      });
  };

  checkin = () => {
    if (this.props.security.hasPermission(PERMISSOES.modules.agenda.id, PERMISSOES.modules.agenda.permissoes.editar)) {
      this.changeStatus('AG');
    } else {
      ToastUtils.error('Acesso Negado');
    }
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (t(this.props, 'scheduleStatus.data.id').safeString !== t(nextProps, 'scheduleStatus.data.id').safeString) {
      this.setState({ scheduleStatusSelected: nextProps.scheduleStatus });
    }
    return true;
  }

  closeModal = () => {
    this.props.closeModal();
  };

  appointmentRedial = () => {
    const { redialModal, schedule } = this.props;

    redialModal({ modal: true, schedule });
  };

  uncheckAppointment = () => {
    const { uncheckModal, schedule = {} } = this.props;
    uncheckModal({ modal: true, schedule });
  };

  setMissing = () => {
    const { appointmentMissing, schedule = {} } = this.props;
    appointmentMissing({ modal: true, schedule });
  };

  scheduleNextAppointment = () => {
    const { config = {}, officeHours = [], schedule = {}, updateParent, closeModal, nextAppointment, configurations } = this.props;
    const scheduleDate = moment(schedule.data_agendamento);
    const { intervalo_padrao = configurations.config.intervalo_padrao, intervalo_padrao_tipo = 'Semanas' } = config;

    let intervalDefault = 'S';

    if (intervalo_padrao) {
      if (intervalo_padrao_tipo.toUpperCase() === 'DIAS') {
        intervalDefault = 'd';
      } else if (intervalo_padrao_tipo.toUpperCase() === 'SEMANAS') {
        intervalDefault = 'w';
      } else if (intervalo_padrao_tipo.toUpperCase() === 'MESES') {
        intervalDefault = 'M';
      }
    }

    scheduleDate.add(intervalo_padrao, intervalDefault);

    // Adicionar um dia até achar o proximo dia util da agenda do dentista
    while (!t(officeHours.find((o) => o.dia_semana === scheduleDate.weekday())).isObject) {
      scheduleDate.add(1, 'd');
    }

    console.log(officeHours.find((o) => o.dia_semana === scheduleDate.weekday()));
    // updateParent(scheduleDate);
    this.props.changeDay(scheduleDate);
    closeModal();

    showToast({
      schedule,
      onClose: () => nextAppointment({}),
      onCancel: () => nextAppointment({})
    });

    nextAppointment({ schedule });
  };

  sendToAppointmentAndRedial = () => {
    const { schedule, sendToAppointmentAndRedial } = this.props;

    sendToAppointmentAndRedial({
      modal: true,
      schedule
    });
  };

  sendToControlAndReturn = () => {
    const { schedule } = this.props;
    this.controlReturnRef.current.openModal({ dentist: { value: schedule.dentista_id }, patient: schedule.paciente });
  };

  recordAttendence = () => {
    const { schedule, attendenceModal } = this.props;

    TreatmentClinicalRecordApi.getClinicalRecordByTreatment({
      treatment: schedule.tratamento.id
    })
      .then(({ data, status }) => {
        if (status === 204) {
          this.setState({
            error: {
              display: true,
              type: 'danger',
              text: 'Ficha clinica não criada, não é possível criar um registro de atendimento'
            }
          });
        } else {
          return data;
        }
      })
      .then((data) => {
        attendenceModal({
          modal: true,
          treatment: schedule.tratamento.id,
          record: data.id
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          error: {
            display: true,
            type: 'danger',
            text: 'Erro ao registrar atendimento entre em contato com o suporte'
          }
        });
      });
  };

  closeClinicalRecordAttendenceModal = () => {
    ToastUtils.success(
      <ToastCustomMessageComponent iconImage={success} title={'Atendimento Registrado com Sucesso !'} />,
      {
        autoClose: 6000,
        hideProgressBar: true,
        position: 'top-center'
      }
    );
    this.props.updateParent();
  };

  closeLaunchPaymentModal = () => {
    ToastUtils.success('Lançamento realizado com sucesso', {
      autoClose: 2000,
      position: 'top-center'
    });
    this.props.updateParent();
  };

  launchPayment = () => {
    const { showLoader, hideLoader, schedule = {}, launchPaymentModal } = this.props;

    showLoader();

    TreatmentFinancialFileApi.findByTreatment({
      treatment: schedule.tratamento.id
    })
      .then(({ data }) => {
        const { id } = data;

        launchPaymentModal({
          financialFile: id,
          treatment: schedule.tratamento.id
        });
        hideLoader();
      })
      .catch((err) => {
        hideLoader();
        if (t(err, 'response.data.message').safeString == 'Ficha Financeira nao encontrada') {
          ToastUtils.time(
            <ToastCustomMessageComponent
              message={'Crie uma Ficha Financeira para Lançar Pagamento'}
              iconImage={danger}
              title={'Este Tratamento não tem Orçamento Aprovado!'}
            />,
            {
              autoClose: 8000,
              hideProgressBar: true,
              position: 'top-center'
            }
          );
        } else {
          ToastUtils.error('Erro ao buscar ficha financeira para lançar pagamento');
        }
      });
  };

  _goToTretament(paciente) {
    this.closeModal();
    this.props.history.push(`/patient/${paciente.id}/treatment/`);
  }
  
  render() {
    const { changeStatus, error, scheduleStatusSelected} = this.state;
    const {
      modal,
      schedule = {},
      scheduleStatusOption,
      appointmentDelete,
      appointmentHistoryModal,
      security
    } = this.props;

    const modulePermission = PERMISSOES.modules.agenda;
    
    const { paciente = {} } = schedule;
    return [
      <Modal
        key={'detail'}
        isOpen={modal}
        toggle={this.closeModal}
        className={this.props.className + ' modal-mlarge'}
        size={'lg'}
      >
        <ModalHeader
          className='title-primary'
          toggle={this.closeModal}
          close={
            <button className='close' onClick={this.closeModal}>
              &times;
            </button>
          }
          cssModule={{ 'modal-title': 'w-100' }}
        >
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <strong>Detalhes da Consulta</strong>
            <IfComponent
              conditional={schedule.status_consulta === 'Agendada' || schedule.status_consulta === 'Confirmada'}
            >
              <a
                style={{
                  marginTop: -15,
                  padding: 15
                }}
                href='#'
                onClick={this.checkin}
                className='btn-checkin'
              >
                <span
                  style={{
                    fontSize: 15
                  }}
                >
                  Check-in
                </span>
              </a>
            </IfComponent>
          </span>
        </ModalHeader>

        <div className='box-secondary bg-secondary-l5' style={{ paddingTop: 5, paddingBottom: 5 }}>
          <div className='text-left'>
            <Row className='pb-0'>
              <Col sm='3'>
                <img
                  style={{ width: 70 }}
                  className='img-patient img-circle'
                  src={paciente.foto ? paciente.foto : noavatar}
                  alt='usuario'
                />
              </Col>
              <Col className='pt-2' sm='5'>
                <p className='text-default'>
                  <strong className={'font-bold'}>{paciente.nome}</strong>
                  <br />
                  <span className={'text-sm'}>
                    <span className={paciente.numero_identificacao ? '' : 'm-0'}>
                      {paciente.numero_identificacao ? '(' + paciente.numero_identificacao + ') ' : ''}
                    </span>
                    {paciente.idade}
                  </span>
                </p>
              </Col>
              <Col className='pt-2' sm='4'>
                {t(paciente, 'telefones').isArray &&
                  paciente.telefones.map((t) => {
                    return (
                      <p className='text-sm' key={t.id}>
                        {phoneFormat(t.telefone)} - {t.responsavel} -
                        <IfComponent conditional={t.whatsapp}>
                          {' '}
                          <img src={whats} style={{ width: 15 }} />
                        </IfComponent>
                      </p>
                    );
                  })}
              </Col>
            </Row>
          </div>
        </div>
        <ModalBody>
          <MessagesComponent display={error.display} type={error.type} message={error.text} style={{ padding: 10 }} />
          <div className='nav-modal'>
            <div style={{ display: 'flex' }}>
              <IfComponent
                conditional={security.hasPermission(modulePermission.id, modulePermission.permissoes.editar)}
              >
                <IfComponent conditional={t(schedule, 'meta.hasEditar').safeBoolean}>
                  <div className={'nav-modal'}>
                    <a
                      href='#'
                      onClick={this.scheduleEdit}
                      className={'nav-link text-capitalize  text-xsm'}
                      style={{
                        lineHeight: 1
                      }}
                    >
                      Editar
                      <Icon className='icon icon-size-smaller margin-left-10' image={edit} alt='edit' />
                    </a>
                  </div>
                </IfComponent>
                <IfComponent conditional={t(schedule, 'meta.hasRemarcar').safeBoolean}>
                  <div className={'nav-modal'}>
                    <a
                      className={'nav-link text-capitalize  text-xsm'}
                      href='#'
                      onClick={this.appointmentRedial}
                      style={{
                        lineHeight: 1
                      }}
                    >
                      Remarcar
                      <Icon className='icon icon-size-smaller margin-left-10' image={calendar} alt='calendar' />
                    </a>
                  </div>
                </IfComponent>
                <IfComponent conditional={t(schedule, 'meta.hasDesmarcar').safeBoolean}>
                  <div className={'nav-modal'}>
                    <a
                      className={'nav-link text-capitalize  text-xsm'}
                      href='#'
                      onClick={this.uncheckAppointment}
                      style={{
                        lineHeight: 1
                      }}
                    >
                      Desmarcar
                      <Icon className='icon icon-size-smaller margin-left-10' image={calendar2} alt='calendar2' />
                    </a>
                  </div>
                </IfComponent>
                <IfComponent conditional={t(schedule, 'meta.hasFalta').safeBoolean}>
                  <div className={'nav-modal'}>
                    <a
                      className={'nav-link text-capitalize  text-xsm'}
                      href='#'
                      onClick={this.setMissing}
                      style={{
                        lineHeight: 1
                      }}
                    >
                      Falta
                      <Icon className='icon icon-size-smaller margin-left-10' image={calendar} alt='calendar' />
                    </a>
                  </div>
                </IfComponent>
                <IfComponent conditional={t(schedule, 'meta.hasExcluir').safeBoolean}>
                  <div className={'nav-modal'}>
                    <a
                      className={'nav-link text-capitalize text-xsm'}
                      href='#'
                      onClick={() => appointmentDelete({ modal: true, schedule })}
                      style={{
                        lineHeight: 1
                      }}
                    >
                      Excluir
                      <Icon className='icon icon-size-smaller margin-left-10' image={excluir} alt='excluir' />
                    </a>
                  </div>
                </IfComponent>
              </IfComponent>
            </div>
          </div>
          <Row>
            <Col sm={8}>
              <Table borderless size='sm'>
                <tbody>
                  <IfComponent conditional={t(schedule, 'tratamento.especialidade').isDefined}>
                    <tr>
                      <td className={'text-right'}>
                        <strong>Tratamento</strong>
                      </td>
                      <td>
                        {t(schedule, 'tratamento.especialidade').safeString}{' '}
                        {t(schedule, 'tratamento.codigo_tratamento').safeNumber} - Ortodontia
                      </td>
                    </tr>
                  </IfComponent>
                  <tr>
                    <td className={'text-right'}>
                      <strong>Data</strong>
                    </td>
                    <td>{formatDate(schedule.data_agendamento, 'DD/MM/YYYY - dddd')}</td>
                  </tr>
                  <tr>
                    <td className={'text-right'}>
                      <strong>Horário</strong>
                    </td>
                    <td>
                      {schedule.horario_de} às {schedule.horario_ate} (
                      {schedule.horario_de &&
                        moment(schedule.horario_ate, 'HH:mm').diff(moment(schedule.horario_de, 'HH:mm'), 'm')}
                      min)
                    </td>
                  </tr>
                  <tr>
                    <td className={'text-right'}>
                      <strong>Procedimento</strong>
                    </td>
                    <td>
                      <span
                        className='procedure-primary badge-proc'
                        style={{
                          backgroundColor: t(schedule, 'procedimento.categoria.rgb').safeString
                        }}
                        id={'procedureTooltip'}
                      >
                        {t(schedule, 'procedimento.codinome').safeString}
                      </span>
                      <UncontrolledTooltip placement='top' target='procedureTooltip' className='tooltip-white'>
                        {t(schedule, 'procedimento.nome').safeString}
                      </UncontrolledTooltip>
                    </td>
                  </tr>
                  <IfComponent conditional={t(schedule.observacao).isDefined}>
                    <tr>
                      <td className={'text-right'}>
                        <strong>Observações</strong>
                      </td>
                      <td>
                        <i>{schedule.observacao}</i>
                      </td>
                    </tr>
                  </IfComponent>
                  <IfComponent conditional={t(schedule.dentista_nome).isDefined}>
                    <tr>
                      <td className={'text-right'}>
                        <strong>Doutor(a)</strong>
                      </td>
                      <td>{schedule.dentista_nome}</td>
                    </tr>
                  </IfComponent>

                  <IfComponent
                    conditional={t(schedule.is_pendencia_laboratorio).isDefined && schedule.is_pendencia_laboratorio}
                  >
                    <tr>
                      <td className={'text-right'}>
                        <strong>Pendência de Laboratório</strong>
                      </td>
                      <td>{schedule.pendencia_laboratorio}</td>
                    </tr>
                  </IfComponent>

                  <tr>
                    <td className={'text-right'}>
                      <strong>Status da Consulta</strong>
                    </td>
                    <td>
                      <IfComponent conditional={!t(scheduleStatusSelected, 'label').isDefined}>
                        <span
                          className='procedure-primary text-secondary'
                          style={{
                            backgroundColor: this.props.scheduleStatusRGB
                          }}
                        >
                          {this.props.scheduleStatusLabel}
                        </span>
                      </IfComponent>

                      <IfComponent conditional={t(scheduleStatusSelected, 'label').isDefined}>
                        {changeStatus == false ? (
                          <div>
                            <span
                              className='procedure-primary text-secondary'
                              style={{
                                backgroundColor: t(scheduleStatusSelected, 'data.rgb').safeString
                              }}
                            >
                              {t(scheduleStatusSelected, 'label').safeString}
                            </span>
                            <IfComponent
                              conditional={security.hasPermission(
                                modulePermission.id,
                                modulePermission.permissoes.editar
                              )}
                            >
                              <a
                                href='#'
                                onClick={() => this.setState({ changeStatus: !changeStatus })}
                                style={{ selfAlign: 'center' }}
                              >
                                <Icon className='icon ml-2 icon-size-small' image={edit} alt='edit' />
                              </a>
                            </IfComponent>
                          </div>
                        ) : (
                          <Row>
                            <Col sm={8}>
                              <Select
                                styles={selectStyles}
                                value={scheduleStatusSelected}
                                options={scheduleStatusOption}
                                onChange={(s) => {
                                  this.setState({ scheduleStatusSelected: s });
                                }}
                              />
                            </Col>
                            <Col className='pt-2' sm={2}>
                              <a
                                className='ml-2'
                                href='#'
                                onClick={() => this.changeStatus(scheduleStatusSelected.value)}
                              >
                                <Icon
                                  className='icon icon-size-two'
                                  style={{ width: '25px', height: '25px', marginTop: '-0.5px' }}
                                  image={success}
                                  alt='edit'
                                />
                              </a>
                            </Col>
                          </Row>
                        )}
                      </IfComponent>
                    </td>
                  </tr>

                  <IfComponent conditional={t(schedule, 'proxima_consulta.consulta').isDefined}>
                    <tr>
                      <td className={'text-right'}>
                        <strong>Consultas Futuras</strong>
                      </td>
                      <td>
                        {formatDate(t(schedule, 'proxima_consulta.consulta').safeObject, 'DD/MM/YYYY')} (
                        {t(schedule, 'proxima_consulta.dentista').safeString})
                      </td>
                    </tr>
                  </IfComponent>
                </tbody>
              </Table>
            </Col>
            <Col sm={4} style={{ display: 'flex', alignItems: 'center' }}>
              <div className='nav-modal w-100'>
                <IfComponent
                  conditional={security.hasPermission(modulePermission.id, modulePermission.permissoes.editar)}
                >
                  <Nav vertical>
                    <NavItem style={{ width: '100%', textAlign: 'center', fontSize: '12px' }}>
                      <NavLink href='#' onClick={this.scheduleNextAppointment}>
                        Agendar a Próxima Consulta
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ width: '100%', textAlign: 'center', fontSize: '12px' }}>
                      <NavLink href='#' onClick={this.sendToAppointmentAndRedial}>
                        Enviar para Lista Agendar / Reagendar
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ width: '100%', textAlign: 'center', fontSize: '12px' }}>
                      <NavLink href='#' onClick={this.sendToControlAndReturn}>
                        Enviar para Lista Controle e Contenção
                      </NavLink>
                    </NavItem>
                    {/*<IfComponent conditional={ t(schedule.tratamento, 'id').isDefined }>*/}
                    {/*  <NavItem style={ { width: '100%', textAlign: 'center' } }>*/}
                    {/*    <NavLink href="#" onClick={ this.launchPayment }>*/}
                    {/*      Lançar Pagamento*/}
                    {/*    </NavLink>*/}
                    {/*  </NavItem>*/}
                    {/*  <NavItem style={ { width: '100%', textAlign: 'center' } }>*/}
                    {/*    <NavLink href="#" onClick={ this.recordAttendence }>*/}
                    {/*      Registrar Atendimento*/}
                    {/*    </NavLink>*/}
                    {/*  </NavItem>*/}
                    {/*</IfComponent>*/}
                  </Nav>
                </IfComponent>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col className='btn-list px-0'>
              <IfComponent conditional={security.hasModuleAccess(PERMISSOES.modules.pacientes.id)}>
                <button
                  onClick={() => {
                    this.props.history.push(`/patient/${paciente.id}/registration-form/`);
                  }}
                  className='button-tertiary'
                >
                  Ficha de Cadastro
                </button>
              </IfComponent>
              <button
                className='button-tertiary'
                onClick={() => {
                  appointmentHistoryModal({
                    modal: true,
                    patient: schedule.paciente
                  });
                }}
              >
                Histórico de Consultas
              </button>
              <IfComponent
                conditional={
                  this.props.security.hasModuleAccess(PERMISSOES.modules.tratamento_avaliacoes.id) ||
                  this.props.security.hasModuleAccess(PERMISSOES.modules.tratamento_ficha_clinica.id) ||
                  this.props.security.hasModuleAccess(PERMISSOES.modules.tratamento_ficha_financeira.id) ||
                  this.props.security.hasModuleAccess(PERMISSOES.modules.tratamento_orcamentos.id)
                }
              >
                <button
                  onClick={() => {
                    this._goToTretament(paciente);
                  }}
                  className='button-tertiary'
                >
                  Tratamentos
                </button>
              </IfComponent>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>,
      <AppointmentRedialModal key={'AppointmentRedialModal'} />,
      <UncheckAppointmentModal key={'UncheckAppointmentModal'} updateParent={this.props.updateParent} />,
      <AppointmentMissingModal key={'AppointmentMissingModal'} updateParent={this.props.updateParent} />,
      <AppointmentDeleteModal key={'AppointmentDeleteModal'} updateParent={this.props.updateParent} />,
      <AppointmentHistoryModal key={'AppointmentHistoryModal'} updateParent={this.props.updateParent} />,
      <SendToAppointmentAndRedialModal
        key={'SendToAppointmentAndRedialModal'}
        updateParent={this.props.updateParent}
      />,
      <TreatmentClinicalRecordAttendenceModal
        key={'TreatmentClinicalRecordAttendenceModal'}
        updateParent={this.closeClinicalRecordAttendenceModal}
      />,
      <TreatmentFinancialLaunchPaymentModal
        key={'TreatmentFinancialLaunchPaymentModal'}
        updateParent={() => this.closeLaunchPaymentModal()}
      />,
      <ScheduleControlReturnAddModal
        key={'ScheduleControlReturnAddModal'}
        ref={this.controlReturnRef}
        callback={() => {
          this.closeModal();
          this.props.updateParent();
        }}
      />
    ];
  }
}

const mapStateToProps = (state) => {
  return {
    ...scheduleAppointmentDetailReducerToProps(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  closeModal: () => dispatch(scheduleAppointmentDetailModal({ modal: false })),
  updateModal: (data) => dispatch(scheduleAppointmentDetailSagaModal(data)),
  editSchedule: (data) => dispatch(scheduleAppointmentPatientSagaModal(data)),
  redialModal: (data) => dispatch(appointmentRedialModal(data)),
  uncheckModal: (data) => dispatch(uncheckAppointmentSagaModal(data)),
  appointmentMissing: (data) => dispatch(appointmentMissing(data)),
  appointmentDelete: (data) => dispatch(appointmentDelete(data)),
  nextAppointment: (data) => dispatch(scheduleNextAppointment(data)),
  sendToAppointmentAndRedial: (data) => dispatch(sendToAppointmentAndRedialSaga(data)),
  attendenceModal: (data) => dispatch(clinicalRecordAttendanciePreModal(data)),
  appointmentHistoryModal: (data) => dispatch(appointmentHistorySagaModal(data)),
  launchPaymentModal: (data) => dispatch(financialLaunchPaymentSagaModal(data))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withSecurity, withRouter);

export default enhanced(ScheduleAppointmentDetailModal);
