import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IfComponent } from './if.component';
import InputComponent from './InputComponent';
import ErrorFieldWithoutBootstrap from './ErrorFieldWithoutBootstrap.component';
import _ from 'lodash';
import DoubleClickInstance from '../utils/DoubleClickInstance';

class InputInlineEditComponent extends Component {
  state = {
    show: false,
    inputText: '',
    error: null
  };

  inlineDoubleClickInstance = new DoubleClickInstance();

  analiseKeyPress = (e) => {
    if (e.key === 'Escape') {
      this.setState({ show: false, error: null, inputText: this.props.text });
      if (this.props.cancel) this.props.cancel();
    } else if (e.key === 'Enter') {
      if (this.state.inputText !== '') {
        this.setState({ show: false, error: null });
        this.props.save(this.state.inputText);
      } else {
        this.setState({ error: 'Campo Obrigatório' });
      }
    }
  };

  componentDidMount() {
    this.setState({
      inputText: this.props.text,
      show: this.props.show || false,
      error: null
    });
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.props.text !== nextProps.text) {
      this.setState({
        inputText: nextProps.text
      });
    }

    if (this.props.show !== nextProps.show) {
      this.setState({
        show: nextProps.show
      });
    }

    return true;
  }

  render() {
    const { show, inputText, error } = this.state;
    const { textStyle, text, textClassName } = this.props;

    return (
      <span className={'input-edit-inline'}>
        <IfComponent conditional={!show}>
          <a
            href={'#'}
            onClick={() => this.inlineDoubleClickInstance.onClicked(this.props.onClick)}
            style={textStyle}
            className={textClassName}
            onDoubleClick={() => this.inlineDoubleClickInstance.onDoubleClicked(() => this.setState({ show: true }))}
          >
            {inputText}
          </a>
        </IfComponent>
        <IfComponent conditional={show}>
          <input
            id={'inputText'}
            type={'text'}
            className={error !== null ? 'p1 is-invalid form-control' : 'p-1'}
            value={inputText}
            autoFocus={show}
            onBlur={this._saveBlur}
            onKeyUp={this.analiseKeyPress}
            onChange={(e) => this.setState({ inputText: e.target.value })}
          />
          <IfComponent conditional={error !== null}>
            <div style={{ fontSize: 10, color: 'red' }}>{error}</div>
          </IfComponent>
        </IfComponent>
      </span>
    );
  }

  _saveBlur = () => {
    let name = this.state.inputText;
    if (name == '') {
      name = 'Nova Pasta';
    }
    this.props.save(name);
  };
}

export default InputInlineEditComponent;
