import { call, put } from 'redux-saga/effects';
import { financialLaunchOtherPaymentModal, financialLaunchPaymentModal } from '../redux/FinancialFile.action';
import DomainsAPI from '../../../../service/DomainsAPI';
import { toast } from 'react-toastify';
import TreatmentFinancialFileApi from '../../../../service/TreatmentFinancialFileApi';
import { t } from 'typy';
import ClientApi from '../../../../service/ClientApi';
import ClinicAPI from '../../../../service/ClinicAPI';

export function* otherLaunchPaymentModalSaga({ payload: { financialFile, treatment, entry } }) {
  try {
    const paymentTypes = yield call(DomainsAPI.getAllPaymentTypes);
    const entryTypes = yield call(DomainsAPI.getAllEntryTypes);
    const clinicalConfigRQ = yield call(TreatmentFinancialFileApi.findByTreatment, { treatment });

    if (entry) {
      const reasons = yield call(DomainsAPI.getAllReasonsOfEntryType, t(entry, 'tipo_lancamento.id').safeNumber);
      entry.reason = reasons && reasons.find((m) => m.motivo === entry.descricao);
    }

    yield put(
      financialLaunchOtherPaymentModal({
        modal: true,
        financialFile,
        paymentTypes,
        receipt: t(clinicalConfigRQ.data, 'informacoes_conta.comprovante'),
        entry,
        entryTypes
      })
    );
  } catch (e) {
    console.error(e);
    toast.error('Erro ao abrir modal de lançamento, entre em contato com o suporte');
  }
}
