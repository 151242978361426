import React, { Component } from 'react';

import HeaderPage from './Header.page';
import MenuPage from './Menu.page';
import ContentPage from './Content.page';
import env from '../../environment/env';
import { IfComponent } from '../../components/if.component';
import WhatsappHelpButton from './WhatsappHelpButton';

class TemplatePage extends Component {
  state = {
    title: 'Sem Titulo',
    image: '',
    action: () => {}
  };

  render() {
    return (
      <div key='root' className='App'>
        <IfComponent conditional={!env.pathNotRenderTemplate.includes(this.props.location.pathname)}>
          <WhatsappHelpButton />
          <HeaderPage />
          <MenuPage {...this.props} />
          <ContentPage>{this.props.children}</ContentPage>
        </IfComponent>
        <IfComponent conditional={env.pathNotRenderTemplate.includes(this.props.location.pathname)}>
          {this.props.children}
        </IfComponent>
      </div>
    );
  }
}

export default TemplatePage;
