import { call, put } from 'redux-saga/effects';
import { financialLaunchPaymentModal } from '../redux/FinancialFile.action';
import DomainsAPI from '../../../../service/DomainsAPI';
import { toast } from 'react-toastify';
import TreatmentFinancialFileApi from '../../../../service/TreatmentFinancialFileApi';
import { t } from 'typy';
import ClinicAPI from '../../../../service/ClinicAPI';

export function* launchPaymentModalSaga({ payload: { financialFile, treatment, entry, paymentEntries } }) {
  try {
    const paymentTypes = yield call(DomainsAPI.getAllPaymentTypes);
    const clinicalConfigRQ = yield call(TreatmentFinancialFileApi.findByTreatment, { treatment });
    const referencesByEmission = yield call(ClinicAPI.getReferencesByEmission);

    yield put(
      financialLaunchPaymentModal({
        modal: true,
        financialFile,
        paymentTypes,
        receipt: t(clinicalConfigRQ.data, 'informacoes_conta.comprovante'),
        entry,
        referencesByEmission: referencesByEmission.data,
        paymentEntries
      })
    );
  } catch (e) {
    console.error(e);
    toast.error('Erro ao abrir modal de lançamento, entre em contato com o suporte');
  }
}
