import { t } from 'typy';
import { createSelector } from 'reselect';

const ListScheduleAndRedialPageSelector = (state) => t(state, 'ScheduleReducer.scheduelRedialPage').safeObject;

export const listScheduleAndRedialPageSelectorToProps = createSelector(
  ListScheduleAndRedialPageSelector,
  ({ dentist, listScheduleRedial } = {}) => {
    return {
      dentist,
      listScheduleRedial
    };
  }
);
