import RestTemplate from '../utils/restTemplate/rest.template';
import t from 'typy';

export default class ControleRetornoAPI {
  static buscarTodosControlesERetornos(dentista) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/controle-retorno/dentista/${dentista.value}`);
  }

  static deleteControleERetorno(id) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.delete(`/controle-retorno/${id}`);
  }

  static getProcedimentoDoControleERetorno(dentista, id) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/controle-retorno/${id}/dentista/${dentista}/procedimento`);
  }

  static saveControleRetorno({ paciente, dentista, tipoControleRetorno, mesAlvo, anotacoes }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post('/controle-retorno/', {
      paciente,
      dentista,
      tipoControleRetorno,
      mesAlvo,
      anotacoes
    });
  }

  static getReturnTypeOptions() {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/controle-retorno/tipos');
  }

  static fetchFilteredPatients = (idDentista, tipo, mes_alvo) => {
    const request = {};

    console.log(tipo);

    // fetching only by tipo
    if (tipo) {
      if (tipo.length === 1) {
        request.tipo = tipo;
      }
    }

    // fetching only by mes_alvo
    if (mes_alvo) {
      request.mes_alvo = mes_alvo;
    }

    // if has multiple tipos and mes_alvo is not selected (mes_alvo is blank) then fetching all tipos
    if (tipo.length > 1 && !mes_alvo) {
      delete request.tipo;
    }

    const restTemplate = new RestTemplate(true);

    return restTemplate.get(`/controle-retorno/dentista/${idDentista}`, request);
  };
}
