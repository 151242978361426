import { all } from 'redux-saga/effects';
import { watchRegisterPatientSaga } from '../../pages/patinent/sagas/patient.saga';
import {
  watchEditContactsSaga,
  watchFindAllContactsSaga,
  watchSaveContactsSaga
} from '../../pages/contacts/sagas/contacts.sagas';
import { watchSettings } from '../../pages/settings/clinic/saga/Settings.saga';
import { watchUserSettings } from '../../pages/settings/users/sagas/userSettings.sagas';
import { watchScheduleSettingsSaga } from '../../pages/settings/schedule/sagas/SettingsSchedule.sagas';
import { watchTreatmentSaga } from '../../pages/treatment/sagas/Treatment.sagas';
import { watchTreatmentEvaluationSagas } from '../../pages/treatment/evaluation/sagas/TreatmentEvaluation.sagas';
import { watchTreatmentBudgetSagas } from '../../pages/treatment/budget/sagas/TreatmentBudget.sagas';
import { watchTreatmentClinicalRecordSagas } from '../../pages/treatment/clinicalRecord/sagas/ClinicalRecord.sagas';
import { watchTreatmentFinancialFileSagas } from '../../pages/treatment/financialFile/saga/TreatmentFinancialFile.sagas';
import { watchScheduleSagas } from '../../pages/schedule/saga/Schedules.sagas';
import { watchSettingsEmissionReferences } from '../../pages/settings/clinic/saga/settings_emission_references_sagas';
import { watchTreatmentImages } from '../../pages/treatment/images/sagas/TreatmentImagesSagas';
import { watchSharedDentits } from '../../shared/dentists_store';
import { watchPatientReport } from '../../pages/reports/patients/store/PatientReportSagas';

export default function* sagaConfig() {
  yield all([
    // Patients
    watchRegisterPatientSaga(),

    //Contacts
    watchFindAllContactsSaga(),
    watchSaveContactsSaga(),
    watchEditContactsSaga(),

    // Settings
    watchSettings(),

    // Settings User
    watchUserSettings(),

    // Settings Schedules
    watchScheduleSettingsSaga(),

    //Treatment
    watchTreatmentSaga(),

    //Treatment Evaluation
    watchTreatmentEvaluationSagas(),

    // Treatment budget
    watchTreatmentBudgetSagas(),

    // Treatment Clinical Record
    watchTreatmentClinicalRecordSagas(),

    // Treatment Financial File
    watchTreatmentFinancialFileSagas(),

    //Schedule
    watchScheduleSagas(),

    //Settings Emission References
    watchSettingsEmissionReferences(),

    // Images
    watchTreatmentImages(),

    //Reports
    watchPatientReport(),

    // Shareds
    watchSharedDentits()
  ]);
}
