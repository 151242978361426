import { call, put } from 'redux-saga/effects';
import { hideLoader, showLoader } from '../../../../components/actions/Loader.actions';
import { treatmentEvaluationModalAttendence } from '../redux/TreatmentEvaluation.action';
import UserAPI from '../../../../service/User.service';

export function* modalAttendenceregister({ payload: { modal, formLoad = {} } }) {
  yield put(showLoader());

  const dentists = yield call(UserAPI.findAllDentistas);

  formLoad.formData = {
    dentists
  };

  yield put(treatmentEvaluationModalAttendence({ modal, formLoad }));

  yield put(hideLoader());
}
