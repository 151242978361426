import { all, call, put } from 'redux-saga/effects';
import { scheduleAppointmentDetailModal } from '../redux/Schedule.actions';
import ScheduleAPI from '../../../service/ScheduleAPI';
import DomainsAPI from '../../../service/DomainsAPI';
import { toast } from 'react-toastify';

export function* scheduleDetailModal({ payload: { modal, schedule, officeHours, config } }) {
  try {
    const response = yield all([call(ScheduleAPI.getSchedule, schedule.id), call(DomainsAPI.getAllScheduleStatus)]);

    const scheduleData = response[0];
    const scheduleStatus = response[1];

    const statusEnabled = ['A', 'C', 'AG', 'EA', 'F'];

    yield put(
      scheduleAppointmentDetailModal({
        modal,
        schedule: scheduleData.data,
        scheduleStatus: scheduleStatus.filter((s) => statusEnabled.includes(s.id)),
        officeHours,
        config
      })
    );
  } catch (e) {
    console.log(e);
    toast.error('Erro ao buscar dados da agenda entre em contato com o suporte', {
      autoClose: false,
      position: 'top-center'
    });
  }
}
