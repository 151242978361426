import t from 'typy';
import { createSelector } from 'reselect';

const treatmentPlanStrategiesModalSelector = (state) =>
  t(state, 'TreatmentClinicalRecordReducer.treatmentPlanStrategiesModal').safeObject;
const treatmentPlanStrategiesSelector = (state) =>
  t(state, 'TreatmentClinicalRecordReducer.treatmentPlanStrategiesModal.plan.estrategias').safeObject;

export const propsModalSelector = createSelector(
  treatmentPlanStrategiesModalSelector,
  treatmentPlanStrategiesSelector,
  (treatmentPlanGoalModal = {}, estrategias = []) => {
    const { modal, treatment, record, plan = {} } = treatmentPlanGoalModal;

    const strategies = estrategias.map((o) => o.estregia);

    if (strategies.length == 0) strategies.push('');

    return {
      modal,
      treatment,
      record,
      plan: plan.id,
      strategies
    };
  }
);
