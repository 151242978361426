import React, { Component } from 'react';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap';
import edit from '../../../assets/img/icons/edit.svg';
import Icon from '../../../components/Icon.component';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import Select from 'react-select';
// import { reactSelectHandleChange } from '../../../utils/ReactSelectUtils';
import { IfComponent } from '../../../components/if.component';
import {
  uncheckAppointmentFormInit,
  uncheckAppointmentStateToProps
} from '../selectors/UnchekAppointmentModal.selector';
import {
  appointmentRedialCalendar,
  scheduleAppointmentDetailModal,
  uncheckAppointmentModal
} from '../redux/Schedule.actions';
import { showLoader, hideLoader } from '../../../components/actions/Loader.actions';
import { RadioButton, RadioGroup } from 'react-radio-buttons';
import { Form, Formik } from 'formik';
import { reactSelectHandleChange, reactSelectStyleIsError } from '../../../utils/ReactSelectUtils';
import ProceduresAPI from '../../../service/ProceduresAPI';
import MessagesComponent from '../../../components/Messages.component';
import * as Yup from 'yup';
import ErrorFieldWithoutBootstrap from '../../../components/ErrorFieldWithoutBootstrap.component';
import ScheduleAPI from '../../../service/ScheduleAPI';
import moment from 'moment';
import { t } from 'typy';

class UncheckAppointmentModal extends Component {
  validateFormSchema = Yup.object().shape({
    procedure: Yup.object().shape({
      value: Yup.number().required('Procedimento Obrigatório')
    })
  });

  defaultState = {
    changeProcedure: false,
    procedures: [],
    message: {
      display: false,
      type: '',
      text: ''
    }
  };

  state = {
    ...this.defaultState
  };

  closeModal = () => {
    const { closeModal } = this.props;
    closeModal();
    this.setState({
      ...this.defaultState
    });
  };

  uncheckSave = (values) => {
    console.log(values);
    const { modal, schedule = {}, showLoader, hideLoader, closeModal, updateParent, closeModalDetail } = this.props;

    showLoader();
    ScheduleAPI.uncheckAppointment({
      id: schedule.id,
      uncheckBy: values.type,
      procedure: values.procedure.data.id,
      comments: values.comments
    })
      .then(() => {
        updateParent(moment(schedule.data_agendamento).add(2, 's'));
        hideLoader();
        closeModal();
        closeModalDetail();
      })
      .catch((err) => {
        console.log(err);
        hideLoader();

        if (t(err, 'response.data.exception').safeString.includes('Consulta já iniciada')) {
          this.setState({
            message: {
              display: true,
              type: 'danger',
              text: t(err, 'response.data.exception').safeString
            }
          });
        } else {
          this.setState({
            message: {
              display: true,
              type: 'danger',
              text: 'Erro ao desmarcar consulta entre em contato com o suporte'
            }
          });
        }
      });
  };

  findProcedures = (group) => {
    const { showLoader, hideLoader } = this.props;
    showLoader();
    ProceduresAPI.getAllProcedures(group.id, true)
      .then(({ data }) => {
        const procedures = data.map((p) => ({
          label: p.nome,
          value: p.id,
          data: p
        }));
        this.setState({ procedures });
        hideLoader();
      })
      .catch((c) => {
        hideLoader();
        this.setState({
          message: {
            display: true,
            type: 'danger',
            text: 'Erro ao buscar procedimentos entre em contato com o suporte'
          }
        });
      });
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.state.procedures.length === 0 && nextProps.procedures.length > 0) {
      this.setState({
        procedures: nextProps.procedures
      });
    }

    return true;
  }

  render() {
    const { modal, schedule = {}, formInit, groups } = this.props;
    const { changeProcedure, procedures, message } = this.state;

    const { paciente = {} } = schedule;

    return (
      <Modal isOpen={modal} toggle={this.closeModal} className={this.props.className + ' modal-small'}>
        <ModalHeader
          className='title-primary'
          toggle={this.closeModal}
          close={
            <button className='close' onClick={this.closeModal}>
              &times;
            </button>
          }
        >
          <strong>Desmarcar Consulta</strong>
          {/* <span className="font-light title-medium-plus">{ paciente.nome }</span>  */}
        </ModalHeader>
        <Formik
          initialValues={formInit}
          onSubmit={this.uncheckSave}
          validationSchema={this.validateFormSchema}
          validateOnChange={false}
        >
          {({ errors, values, handleChange }) => {
            return (
              <Form>
                <ModalBody>
                  <MessagesComponent display={message.display} type={message.type} message={message.text} />
                  <div className='margin-top-10'>
                    <Row>
                      <Col sm='12'>
                        <p className='text-default text-md'>
                          A Consulta <span className='text-uppercase'>desmarcada</span> irá para a
                          <strong>
                            {' '}
                            Lista{' '}
                            <IfComponent conditional={t(schedule, 'meta.hasControleRetorno').safeBoolean}>
                              Controle/Retorno
                            </IfComponent>
                            <IfComponent conditional={!t(schedule, 'meta.hasControleRetorno').safeBoolean}>
                              Agendar/Reagendar
                            </IfComponent>
                          </strong>
                        </p>
                      </Col>
                    </Row>
                  </div>
                  <div className='margin-top-10'>
                    <Row className='margin-top-10'>
                      <Col sm='12'>
                        <span className='text-default text-sm'>
                          <strong className='mr-0'>Paciente</strong>
                          {paciente.nome}
                        </span>
                      </Col>
                    </Row>
                  </div>
                  <div className='margin-top-10'>
                    <Row>
                      <Col sm={3}>
                        <label className='w-100 pt-2'>Desmarcado por</label>
                      </Col>
                      <Col>
                        <RadioGroup
                          className='radio-griks'
                          name='type'
                          value={values.type}
                          horizontal
                          onChange={(e) =>
                            handleChange({
                              target: {
                                name: 'type',
                                value: e
                              }
                            })
                          }
                        >
                          <RadioButton pointColor={'#522068'} iconSize={20} value='P'>
                            <span className='px-3'>Paciente</span>
                          </RadioButton>
                          <RadioButton pointColor={'#522068'} iconSize={20} value='C'>
                            <span className='px-3'>Clinica</span>
                          </RadioButton>
                        </RadioGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className='margin-top-10'>
                    <IfComponent conditional={!changeProcedure}>
                      <Row className='margin-top-10'>
                        <Col sm='12'>
                          <p>
                            <strong>Procedimento</strong>
                            <span
                              className='procedure-primary badge-proc ml-2'
                              style={{
                                backgroundColor: values.group.data.rgb,
                                color: '#000'
                              }}
                              id={'procedure'}
                            >
                              {values.procedure.data.codinome}
                            </span>
                            <UncontrolledTooltip className='tooltip-white' placement='bottom' target='procedure'>
                              {values.procedure.label}
                            </UncontrolledTooltip>
                            {/* <a href={ '#' } onClick={ () => this.setState({ changeProcedure: !changeProcedure }) }>
                              <Icon className="icon icon-size-small" image={ edit } alt="edit"/>
                            </a> */}
                          </p>
                        </Col>
                      </Row>
                    </IfComponent>
                    <IfComponent conditional={changeProcedure}>
                      <Row className='margin-top-10'>
                        <Col sm='6'>
                          <label>
                            Grupo<span>*</span>
                          </label>
                        </Col>
                        <Col sm='6'>
                          <label>Procedimento</label>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm='6'>
                          <Select
                            value={values.group}
                            options={groups}
                            onChange={(c) => {
                              reactSelectHandleChange(c, handleChange, 'group');
                              reactSelectHandleChange({}, handleChange, 'procedure');
                              this.findProcedures(c.data);
                            }}
                          />
                        </Col>
                        <Col sm='6'>
                          <Select
                            value={values.procedure}
                            options={procedures}
                            onChange={(p) => reactSelectHandleChange(p, handleChange, 'procedure')}
                            styles={reactSelectStyleIsError(errors, 'procedure.value')}
                          />
                          <ErrorFieldWithoutBootstrap fieldName={'procedure.value'} errors={errors} />
                        </Col>
                      </Row>
                    </IfComponent>
                  </div>
                  <div className='margin-top-10'>
                    <Row>
                      <Col sm='12'>
                        <form>
                          <label>Observações</label>
                          <textarea
                            rows='1'
                            cols='50'
                            className='form-control'
                            name={'comments'}
                            value={values.comments}
                            onChange={handleChange}
                          />
                        </form>
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Row>
                    <Col className='btn-list' sm={{ size: 7, offset: 5 }}>
                      <button className='button-primary'>Desmarcar</button>
                      <button className='button-tertiary' type={'button'} onClick={this.closeModal}>
                        Cancelar
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...uncheckAppointmentStateToProps(state),
    formInit: uncheckAppointmentFormInit(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  closeModal: () => dispatch(uncheckAppointmentModal({ modal: false })),
  closeModalDetail: () => dispatch(scheduleAppointmentDetailModal({ modal: false }))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(UncheckAppointmentModal);
