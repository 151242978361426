import React, { Component } from 'react';
import { Col, Container, Row, Table } from 'reactstrap';
import Icon from '../../../components/Icon.component';
import { formatDate } from '../../../utils/DateUtils';
import noavatar from '../../../assets/img/noavatar.png';
import receipt from '../../../assets/img/icons/receipt.svg';
import { showToast } from '../TostPatient.component';
import ScheduleAPI from '../../../service/ScheduleAPI';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { scheduleFromPatient } from '../redux/Schedule.actions';
import warning from '../../../assets/img/icons/warning.svg';
import ModalMessageConfirm from '../../../components/ModalMessageConfirm.component';
import { PERMISSOES } from '../../../config/security/permissoes';
import remarcar from '../../../assets/img/icons/remarcar.svg';
import close from '../../../assets/img/icons/close.svg';
import { IfComponent } from '../../../components/if.component';
import withSecurity from '../../../config/security/security';
import PercentLabelComponent from '../../../components/PercentLabelComponent';
import { IoIosPerson } from 'react-icons/io';
import ReactTooltip from 'react-tooltip';

class PatientScheduleScheduleAndRedial extends Component {
  state = {
    confirmDelete: {
      display: false
    },
    schedule: {}
  };

  schedule = (scheduleRedial) => {
    const { dentist } = this.props;
    const schedule = {
      paciente: scheduleRedial.paciente,
      procedimento: scheduleRedial.procedimento,
      data_agendamento: ''
    };

    showToast({
      schedule: schedule,
      onClose: () => ScheduleAPI.removeScheduleRedialList({ dentist: dentist.value, id: scheduleRedial.id })
    });

    this.props.scheduleFromPatient({
      schedule
    });

    this.props.history.push('/schedule');
  };

  _closeConfirmModal = () => {
    this.setState({
      confirmDelete: {
        display: false
      }
    });
  };

  _openConfirmModal = (schedule) => {
    this.setState({
      confirmDelete: {
        display: true
      },
      schedule
    });
  };

  _confirmDelete = async () => {
    const { schedule } = this.state;
    const { dentist, callbackUpdate } = this.props;
    await ScheduleAPI.removeScheduleRedialList({ dentist: dentist.value, id: schedule.id });

    this._closeConfirmModal();
    callbackUpdate();
  };

  render() {
    const { total, percent, patients = [] } = this.props;
    const { confirmDelete } = this.state;

    return [
      <Container key={'page'}>
        <Row>
          <Col
            sm={12}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              paddingBottom: '10px',
              display: 'flex',
              height: 50
            }}
          >
            <IoIosPerson
              className='icon-agendar-reagendar'
              style={{ width: '34px', height: '34px', marginTop: '-3px' }}
              alt='people'
            />
            <span className='title-medium-plus text-uppercase text-secondary ml-3 mr-3' style={{ fontSize: '17px' }}>
              Pacientes Lista Agendar / Reagendar
            </span>{' '}
            <span
              className={'procedure-primary ml-2'}
              style={{
                backgroundColor: '#f4f7fd',
                width: 93,
                height: 28,
                color: '#000',
                borderColor: '#aeb6c5',
                borderStyle: 'solid',
                borderWidth: 1,
                textAlign: 'center',
                verticalAlign: 'middle'
              }}
            >
              <span style={{ fontSize: 16, fontWeight: 600, color: '#434c5e' }}> {total} </span>
              <span className='ml-1' style={{ fontSize: 13, fontWeight: 400, color: '#434c5e' }}>
                <PercentLabelComponent percent={percent} />
              </span>
            </span>
          </Col>
        </Row>
        <Row>
          <Col className={'tableResult'} style={{ marginTop: '-5px' }}>
            <Table className='table-header-fixed' responsive>
              <thead style={{ backgroundColor: '#ebecf2' }}>
                <tr className='row py-2' style={{ height: '36px ' }}>
                  <th className='col-sm-4 py-0'>
                    <span className='text-sm text-secondary-d4 font-weight-bold'>Paciente</span>
                  </th>
                  <th className='col-sm-2 pl-5 py-0'>
                    <span className='text-sm text-secondary-d4 font-weight-bold text-nowrap'>Último Atend</span>
                  </th>
                  <th className='col-sm-5'></th>
                  <th className='col-sm-1 icon-tab-schedu non-hover py-0 top'>
                    <div style={{ maxHeight: 20 }} className='float-right'>
                      <a data-tip data-for={'tooltipAgendar'} href={'#'}>
                        <Icon className='icon icon-size-one' image={remarcar} alt='returnicon' /> &nbsp;
                      </a>
                      <ReactTooltip id='tooltipAgendar' className='tooltip-griks' type='light'>
                        Agendar Consulta
                      </ReactTooltip>
                      <a href={'#'} data-tip data-for={'tooltipExcluir'}>
                        <Icon className='icon icon-size-one' image={close} alt='returnicon' />
                      </a>
                      <ReactTooltip className='tooltip-griks' id='tooltipExcluir' type='light'>
                        Excluir
                      </ReactTooltip>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody style={{ maxHeight: 'calc(100vh - 320px)', overflow: 'unset' }}>
                {patients.map((pa) => {
                  const p = pa.paciente;
                  return (
                    <tr key={p.id} className={'row py-0 box-basic px-0'}>
                      <td className='col-sm-4'>
                        <Row className='pb-0'>
                          <Col sm={2}>
                            <div>
                              <img
                                className={'img-user-avatar' + ' img-xxldpi'}
                                src={p.foto || noavatar}
                                alt='usuario'
                              />
                            </div>
                          </Col>
                          <Col sm={10} className='p-0'>
                            <div className='title-medium pl-4'>
                              <p className='text-sm font-bold text-secondary text-nowrap'>{p.nome}</p>
                              <p className='text-sm font-light text-secondary'>{p.idade}</p>
                            </div>
                          </Col>
                        </Row>
                      </td>
                      <td className='col-sm-2 pl-5'>
                        <span className='text-sm text-secondary'>
                          {formatDate(pa.dataUltimoAgendamento, 'DD/MM/YYYY')}
                        </span>
                      </td>
                      <td className='col-sm-5'></td>
                      <td className='col-sm-1 icon-tab-schedu'>
                        <IfComponent
                          conditional={this.props.security.hasPermission(
                            PERMISSOES.modules.agenda.id,
                            PERMISSOES.modules.agenda.permissoes.editar
                          )}
                        >
                          <div className='float-right'>
                            <a href={'#'} onClick={() => this.schedule(pa)}>
                              <Icon className='icon icon-size-one' image={remarcar} alt='returnicon' /> &nbsp;
                            </a>
                            <a
                              href={'#'}
                              style={{ marginTop: 2 }}
                              onClick={() => {
                                this._openConfirmModal(pa);
                              }}
                            >
                              <Icon className='icon icon-size-one' image={close} alt='returnicon' />
                            </a>
                          </div>
                        </IfComponent>
                        {/*<UncontrolledDropdown direction="left">*/}
                        {/*  <DropdownToggle>*/}
                        {/*    <img src={ menuDots } style={ { width: 20 } }/>*/}
                        {/*  </DropdownToggle>*/}
                        {/*  <DropdownMenu>*/}
                        {/*    <DropdownItem onClick={ () => this.schedule(pa) }>Agendar esta Consulta</DropdownItem>*/}
                        {/*    <DropdownItem onClick={ () => this._openConfirmModal(pa) }>Remover da Lista</DropdownItem>*/}
                        {/*  </DropdownMenu>*/}
                        {/*</UncontrolledDropdown>*/}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>,
      <ModalMessageConfirm
        key={'confirmModal'}
        title={<strong>Confirma Exclusão?</strong>}
        message={
          <span>
            <p>Confirma a Exclusão da lista de agendar e reagendar ?</p>
          </span>
        }
        openModal={confirmDelete.display}
        icon={warning}
        close={this._closeConfirmModal}
        footer={
          <Row>
            <Col sm='3'>
              <button className='button-tertiary' onClick={this._closeConfirmModal}>
                Não
              </button>
            </Col>
            <Col sm={{ size: 3, offset: 6 }}>
              <button className='button-primary' onClick={this._confirmDelete}>
                Sim
              </button>
            </Col>
          </Row>
        }
      />
    ];
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  scheduleFromPatient: (data) => dispatch(scheduleFromPatient(data))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withSecurity);

export default enhanced(PatientScheduleScheduleAndRedial);
