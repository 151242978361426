import { call, put } from 'redux-saga/effects';
import { clinicalRecordTreatmentGadgetsModal } from '../redux/TreatmentClinicalRecord.action';
import { showLoader, hideLoader } from '../../../../components/actions/Loader.actions';
import DomainsAPI from '../../../../service/DomainsAPI';

export function* clinicalRecordGadgetsPreLoadModal({ payload: { modal, treatment, record, plan } }) {
  yield put(showLoader());

  const gadgets = yield call(DomainsAPI.findAllDevices);

  yield put(clinicalRecordTreatmentGadgetsModal({ modal, treatment, record, plan, gadgets }));
  yield put(hideLoader());
}
