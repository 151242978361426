import t from 'typy';
import { createSelector } from 'reselect';

const appointmentMissingModalSelector = (state) => t(state, 'ScheduleReducer.appointmentMissingModal').safeObject;

export const appointmentMissingModalSelectorToProps = createSelector(
  appointmentMissingModalSelector,
  ({ modal, schedule } = {}) => {
    return {
      modal,
      schedule
    };
  }
);
