import React from 'react';

import PrivateRoute from '../components/PrivateRoute.component';
import CashHomePage from '../pages/cash/CashHome.page';
import CashFlowReportPage from '../pages/cash/CashFlowReport.page';

const CashRouter = (propsRouter) => {
  return [
    <PrivateRoute key='CashHome' path='/cash' exact render={(props) => <CashHomePage {...props} {...propsRouter} />} />,
    <PrivateRoute
      key='CashFlowReport'
      exact
      path='/cash/report/'
      render={(props) => <CashFlowReportPage {...props} />}
    />
  ];
};

export default CashRouter;
