import React, { Component } from 'react';
import {
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Container,
  Card,
  CardSubtitle,
  CardBody,
  CardText
} from 'reactstrap';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { hideLoader, showLoader } from '../../../components/actions/Loader.actions';
import UserAPI from '../../../service/User.service';
import MessagesComponent from '../../../components/Messages.component';
import ToastUtils from '../../../utils/ToastUtils';
import moment from 'moment';
import noavatar from '../../../assets/img/noavatar.png';

class OrthodonticsFrequencyHelpModal extends Component {
  initialState = {
    message: {
      display: false,
      type: '',
      text: ''
    }
  };

  state = {
    ...this.initialState
  };

  render() {
    const { modal, closeModal, usuario } = this.props;
    const { message } = this.state;
    return (
      <Modal isOpen={modal} toggle={closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={closeModal}
          close={
            <button className='close' onClick={closeModal}>
              &times;
            </button>
          }
        ></ModalHeader>
        <ModalBody className='mt-n4'>
          <Container>
            <Col sm='12'>
              <Card className={'text-left'}>
                <CardBody className={'pb-0 pt-0'} style={{ border: '0px' }}>
                  <CardSubtitle className={'text-secondary text-md font-bolder mb-4'}>
                    <span className={'pt-0'}>ENTENDA AS LISTAS DO GPS</span>
                    <br />
                    <span className={'text-sm font-light'}>
                      Aparecerão nestas listas apenas pacientes que estejam em Tratamento Ortodôntico Ativo.
                    </span>
                    <br />
                  </CardSubtitle>
                  <CardText className={'text-default text-sm text-secondary mb-4'}>
                    <strong className={'text-secondary font-bold'}>JÁ ATENDIDOS</strong>- Pacientes que já tiveram uma
                    consulta atendida neste mês.
                  </CardText>

                  <CardText className={'text-default text-sm text-secondary-d4 mb-4'}>
                    <strong className={'text-secondary-d4 font-bold'}>AGENDADOS ESTE MÊS</strong>- Pacientes que ainda
                    não foram atendidos, mas já estão agendados este mês.
                  </CardText>

                  <CardText className={'text-default text-sm text-secondary-d4 mb-4'}>
                    <strong className={'text-secondary-d4 font-bold'}>AGENDADOS EM OUTRO MÊS</strong>- Pacientes que não
                    foram atendidos este mês. Não tem consulta agendada este mês. Mas, tem consulta agendada em outro
                    mês futuro.
                  </CardText>

                  <CardText className={'text-default text-sm text-secondary-d4 mb-4'}>
                    <strong className={'text-secondary-d4 font-bold'}>NA LISTA AGENDAR - REAGENDAR</strong>- Paciente
                    não se enquadra em nenhuma das situações anteriores e está na Lista Agendar Reagendar.
                  </CardText>

                  <CardText className={'text-default text-sm text-secondary-d4 mb-4'}>
                    <strong className={'text-secondary-d4 font-bold'}>FORA DO SISTEMA</strong>- Paciente não se enquadra
                    em nenhuma das situações anteriores. Está fora do sistema de agendamento.
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Container>
        </ModalBody>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader())
});

const enhanced = compose(connect(mapDispatchToProps));

export default enhanced(OrthodonticsFrequencyHelpModal);
