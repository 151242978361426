import React, { Component } from 'react';

import { Container, Row, Col, Tooltip, UncontrolledTooltip } from 'reactstrap';
import plus from '../../../assets/img/icons/plus.svg';
import Icon from '../../../components/Icon.component';
import noavatar from '../../../assets/img/noavatar.png';
import SugestionBoxComponent from '../../../components/SuggestionBoxComponent';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import Radium, { StyleRoot } from 'radium';
import { fadeIn } from 'react-animations';
import ContactsAPI from '../../../service/ContactsAPI';

import { connect } from 'react-redux';
import { compose } from 'recompose';

import { contactsDetail, contactsShowHideSave } from '../redux/contacts.actions';
import { t } from 'typy';
import withSecurity from '../../../config/security/security';
import { PERMISSOES } from '../../../config/security/permissoes';
import { IfComponent } from '../../../components/if.component';

class ContactsSearchComponent extends Component {
  state = {
    suggestionsData: [],
    value: ''
  };

  selectedContacts = (contactSelected) => {
    this.props.showDetail(contactSelected.object);
  };

  renderSuggestion(suggestion = {}, { query }) {
    const suggestionText = `${suggestion.name}`;
    const matches = AutosuggestHighlightMatch(suggestionText, query);
    const parts = AutosuggestHighlightParse(suggestionText, matches);
    if (suggestion.id) {
      return (
        <span className={'suggestion-content'}>
          <img className={'img-circle'} src={suggestion.image} style={{ maxWidth: 62, maxHeight: 62 }} alt='' />
          <span className='name'>
            {parts.map((part, index) => {
              const className = part.highlight ? 'highlight' : null;

              return (
                <span className={className} key={index}>
                  {part.text}
                </span>
              );
            })}
          </span>
        </span>
      );
    } else {
      return (
        <span className={'suggestion-content'}>
          <span className='name'>
            <span className={''}>
              {'Nenhum contato encontrado com o nome'} {<span className='highlight'>({query})</span>}
            </span>
          </span>
        </span>
      );
    }
  }

  renderSuggestionsContainer({ containerProps, children, query }) {
    return (
      <StyleRoot>
        <div
          {...containerProps}
          style={{
            animation: 'x 0.9s',
            animationName: Radium.keyframes(fadeIn, 'fadeIn')
          }}
        >
          {children}
        </div>
      </StyleRoot>
    );
  }

  findContacts = async (searchTerm) => {
    if (searchTerm.length > 2) {
      try {
        const { data: contacts } = await ContactsAPI.pescInc(searchTerm);
        if (contacts.length === 0) {
          contacts.push({
            nome: 'Nenhum contato encontrado'
          });
        }

        this.setState({
          suggestionsData: contacts.map((c) => {
            return {
              id: c.id,
              name: `${t(c.titulo).safeString} ${c.nome}`,
              image: c.foto || noavatar,
              object: c
            };
          })
        });
      } catch (err) {
        this.setState({
          suggestionsData: []
        });
      }
    } else {
      this.setState({
        suggestionsData: []
      });
    }
  };

  renderInputComponent = (inputProps) => (
    <div className='shadow-small'>
      <input
        {...inputProps}
        className='input-box-search '
        type='text'
        name='firstname'
        placeholder='Buscar Contatos'
        autoComplete='rutjfkde'
        spellCheck='false'
      />
    </div>
  );

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue
    });
  };

  render() {
    const { value, suggestionsData } = this.state;
    const { showNewContact, security } = this.props;

    const inputProps = {
      placeholder: '',
      value,
      onChange: this.onChange
    };

    const modulePermission = PERMISSOES.modules.contatos;

    return (
      <Container>
        <Row>
          <Col sm='6'>
            <div className='mr-1'>
              <SugestionBoxComponent
                renderSuggestionsContainer={this.renderSuggestionsContainer}
                renderSuggestion={this.renderSuggestion}
                renderInputComponent={this.renderInputComponent}
                findSuggestions={this.findContacts}
                selectedSuggestion={this.selectedContacts}
                suggestionsData={suggestionsData}
                inputProps={inputProps}
              />
            </div>
          </Col>
          <Col sm='2'>
            <IfComponent
              conditional={
                security.hasPermission(modulePermission.id, modulePermission.permissoes.editar) ||
                security.hasPermission(modulePermission.id, modulePermission.permissoes.editar_restricao.id)
              }
            >
              <a
                href='#'
                id='tooltipAddContact'
                onClick={() => {
                  showNewContact();
                }}
              >
                <div className='box-search-add'>
                  <Icon
                    className='box-search-add-contact 7d7e86icon-primary icon icon-size-medium mrt-1'
                    image={plus}
                    alt='plus'
                  />
                </div>
              </a>
              <div></div>
              <UncontrolledTooltip
                placement='top'
                target='tooltipAddContact'
                className='tooltip-white tooltip-force-center'
              >
                Adicionar Novo Contato
              </UncontrolledTooltip>
            </IfComponent>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ ContactsReducer = {} }) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    showDetail: (data) => dispatch(contactsDetail({ open: true, contact: data })),
    showNewContact: (data) => dispatch(contactsShowHideSave({ open: true, contact: {} }))
  };
};

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withSecurity);

export default enhanced(ContactsSearchComponent);
