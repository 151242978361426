export const SAGA_FIND_CLINIC_SETTINGS_BY_USER = 'SAGA_FIND_CLINIC_SETTINGS_BY_USER';
export const FIND_CLINIC_SETTINGS_BY_USER = 'FIND_CLINIC_SETTINGS_BY_USER';
export const EDIT_CLINIC_SETTINGS = 'EDIT_CLINIC_SETTINGS';
export const EDIT_CLINIC_ADDRESS_SETTINGS = 'EDIT_CLINIC_ADDRESS_SETTINGS';
export const SAGA_SAVE_SETTINGS = 'SAGA_SAVE_SETTINGS';
export const SAGA_SAVE_ADDRESS_SETTINGS = 'SAGA_SAVE_ADDRESS_SETTINGS';
export const EDIT_CLINIC_TIME_SETTINGS = 'EDIT_CLINIC_TIME_SETTINGS ';
export const SAGA_SAVE_CLINIC_TIME_SETTINGS = 'SAGA_SAVE_CLINIC_TIME_SETTINGS';

export const clinicSettings = (data) => {
  return {
    type: FIND_CLINIC_SETTINGS_BY_USER,
    clinic: data
  };
};

export const editClinicSettings = (data) => {
  return {
    type: EDIT_CLINIC_SETTINGS,
    clinic: data.clinic
  };
};

export const editClinicAddressSettings = (data) => {
  return {
    type: EDIT_CLINIC_ADDRESS_SETTINGS,
    clinic: data.clinic
  };
};

export const editClinicTimeSettings = ({ data, showModal }) => {
  return {
    type: EDIT_CLINIC_TIME_SETTINGS,
    clinic: {
      data,
      showModal
    }
  };
};

export const sagaSaveClinicSettings = (data) => {
  return {
    type: SAGA_SAVE_SETTINGS,
    data
  };
};

export const sagaSaveAddressClinicSettings = (data) => {
  return {
    type: SAGA_SAVE_ADDRESS_SETTINGS,
    data
  };
};

export const sagaSaveClinicTimeSettings = (data) => {
  return {
    type: SAGA_SAVE_CLINIC_TIME_SETTINGS,
    data
  };
};
