import React, { Component } from 'react';
import BaseComponent from '../../config/component/BaseComponent';
import PatientSearch from './components/PatientSearch.component';

import PatientList from './components/PatientList.component';
import PatinentViewModal from './components/PatientViewModal.component';
import PacienteAPI from '../../service/PacienteAPI';
import ErrorMessage from '../../components/ErrorMessage.component';

import { Row } from 'reactstrap';
import { PatientAddModal } from './modals/PatientAdd.modal';

import { compose } from 'recompose';
import { connect } from 'react-redux';

import patient from '../../assets/img/icons/user.svg';

import { savePatient, showHideModalRegisterPatient } from './redux/patient.actions';
import { setHeader } from '../template/redux/Content.actions';

import { showLoader, hideLoader } from '../../components/actions/Loader.actions';

import { sendMessage } from '../../components/actions/ErrorMessage.actions';
import PatientEditModal from './modals/PatientEdit.modal';
import { showPatientEditModal } from './redux/patient.actions';
import AppointmentHistoryModal from '../schedule/modal/AppointmentHistory.modal';
import withSecurity from '../../config/security/security';

const defaultState = {
  patientData: {},
  messageDisplay: false,
  type: '',
  message: '',
  showModalAdd: false,
  displayDetailModal: false,
  image: ''
};

class PatientPage extends Component {
  state = {
    ...defaultState
  };

  componentDidMount() {
    this.props.setHeader({
      title: 'Pacientes',
      image: patient
    });
  }

  selectPatientAndShowModal = (p) => {
    const { showLoader, hideLoader } = this.props;

    showLoader();
    PacienteAPI.findById(p.id)
      .then((data) => {
        hideLoader();
        this.setState({
          displayDetailModal: true,
          patientData: data
        });
      })
      .catch((error) => {
        hideLoader();
        this.props.sendMessage({
          messageDisplay: true,
          type: 'danger',
          message: `Erro ao buscar dados do pacientes (${error.message})`
        });
      });
  };

  openPatientRegisterModal = () => {
    this.props.showHideModalRegisterPatient(true);
  };

  closeModalDetail = () => {
    this.setState({ displayDetailModal: false });
  };

  closeRegister = () => {
    this.props.hideLoader();
    this.props.showHideModalRegisterPatient(false);
  };

  render() {
    const { patientData, displayDetailModal } = this.state;

    const {
      displayModalRegister,
      showHideModalRegisterPatient,
      showLoader,
      hideLoader,
      messageDisplay = false,
      type = '',
      message = ''
    } = this.props;

    return (
      <div className='content-box-without-color patient-container'>
        <PatientSearch
          {...this.props}
          selectPatientAndShowModal={this.selectPatientAndShowModal}
          showHideModalRegisterPatient={showHideModalRegisterPatient}
        />

        <ErrorMessage display={messageDisplay} type={type} message={message} id={'patientList'} />

        <PatientList
          {...this.props}
          selectPatientAndShowModal={this.selectPatientAndShowModal}
          showLoader={showLoader}
          hideLoader={hideLoader}
        />

        <PatinentViewModal
          {...this.props}
          showDetailModal={displayDetailModal}
          patientData={patientData}
          closeModal={this.closeModalDetail}
        />

        <PatientAddModal
          show={displayModalRegister}
          close={this.closeRegister}
          {...this.props}
          showLoader={showLoader}
          hideLoader={hideLoader}
          openPatientRegisterModal={this.openPatientRegisterModal}
        />

        <PatientEditModal />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { displayModalRegister } = state.PatientReducer;
  const { messageDisplay, type, message } = state.ErrorMessage;

  return {
    displayModalRegister,
    messageDisplay,
    type,
    message
  };
};

const mapDispatchToProps = (dispatch) => ({
  savePatient: (data) => dispatch(savePatient(data)),
  setHeader: (data) => dispatch(setHeader(data)),
  showLoader: (data) => dispatch(showLoader(data)),
  hideLoader: (data) => dispatch(hideLoader(data)),
  sendMessage: (data) => dispatch(sendMessage(data)),
  showPatientEditModal: (data) => dispatch(showPatientEditModal(data)),
  showHideModalRegisterPatient: (data) => dispatch(showHideModalRegisterPatient(data))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withSecurity);

export default enhanced(PatientPage);
