import React from 'react';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import warning from '../../../../assets/img/icons/warning.svg';
import Icon from '../../../../components/Icon.component';
import PropTypes from 'prop-types';

const SettingsProcedureWarningStandardProcedureModal = ({ modal, closeModal }) => {
  return (
    <Modal isOpen={modal} toggle={closeModal}>
      <ModalHeader
        className='title-primary'
        toggle={closeModal}
        close={
          <button className='close' onClick={closeModal}>
            &times;
          </button>
        }
      >
        Procedimento Padrão da Agenda
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col sm='12'>
            <div className='modal-icon'>
              <Icon className='icon-red icon-size-big' image={warning} alt='warning' />
            </div>
          </Col>
        </Row>
        <div className='margin-top-20'>
          <Row>
            <Col sm='12'>
              <span>
                <p className='text-secondary text-center text-md'>
                  Este procedimento não pode ser Inativado ou Excluido, <br />
                  porque é o <strong>Procedimento Padrão da Agenda</strong>.<br /> <br />
                  Para excluir ou inativar, antes configure outro <br />
                  Procedimento Padrão para a Agenda.
                </p>
              </span>
            </Col>
          </Row>
        </div>
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col className={'btn-list'} sm={{ size: 4, offset: 8 }}>
            <button className='button-tertiary' type={'button'} onClick={closeModal}>
              Entendi
            </button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

SettingsProcedureWarningStandardProcedureModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default SettingsProcedureWarningStandardProcedureModal;
