import t from 'typy';
import { createSelector } from 'reselect';

const budgetSelector = (state) => t(state, 'TreatmentBudgetReducer.paymentClosedPrice.formLoad.budget').safeObject;
const precofechadoSelector = (state) =>
  t(state, 'TreatmentBudgetReducer.paymentClosedPrice.formLoad.preco_fechado').safeObject;

export const formInit = createSelector(budgetSelector, precofechadoSelector, (budget = {}, preco_fechado = {}) => {
  return {
    budget: budget.id,
    paymentId: t(budget, 'pagamento.id').safeObject,
    closedPriceId: preco_fechado.id,
    valorTotal: preco_fechado.valor_total,
    desconto: preco_fechado.valor_desconto,
    parcelas: preco_fechado.parcelado_em,
    diaVencimento: preco_fechado.dia_vencimento || 10,
    valorComDesconto: preco_fechado.valor_total - preco_fechado.valor_desconto,
    entrada: preco_fechado.valor_entrada,
    valorParcelas: preco_fechado.valor_parcela
  };
});
