import { t } from 'typy';
import { createSelector } from 'reselect';
import states from '../../../../utils/states';

const settingsEmissionReferenceSelector = (state) =>
  t(state, 'SettingsEmissionReferenceReducer.editarModal').safeObject;
const voucher_type_options = [
  { label: 'Recibo', value: 'R' },
  { label: 'Nota Fiscal', value: 'NF' }
];
const emission_type_options = [
  { label: 'Fixa', value: 'F' },
  { label: 'Variável', value: 'V' }
];
const states_options = states.map((s) => ({ label: s.sigla, value: s.sigla }));

export const settingsEmissionReferenceModalStateToProps = createSelector(
  settingsEmissionReferenceSelector,
  ({ formLoad = {} } = {}) => {
    const dentists = t(formLoad, 'dentists').safeArray;
    const dentistsOptions = dentists.map((d) => ({
      label: d.nome_completo,
      value: d.id,
      data: d
    }));
    const administratorDentist = dentistsOptions.find((d) => d.data.tipo_dentista === 'Dentista Administrador');

    const defaultValues = {
      razao_social: t(formLoad, 'clinica.nome').safeString,
      cnpj: t(formLoad, 'clinica.cnpj').safeString,
      dentist: administratorDentist,
      cpf: t(administratorDentist, 'data.cpf').safeString,
      cro: t(administratorDentist, 'data.cro').safeString,
      cro_uf: states_options.find((s) => s.value === t(administratorDentist, 'data.cro_uf').safeString),
      voucher_type: voucher_type_options[0],
      emission_type: emission_type_options[0]
    };

    let formData = defaultValues;

    if (t(formLoad, 'references.voucher_type.id').isDefined) {
      formData = {
        razao_social: t(formLoad, 'references.razao_social').safeString || defaultValues.razao_social,
        cnpj: t(formLoad, 'references.cnpj').safeString || defaultValues.cnpj,
        voucher_type: voucher_type_options.find(
          (vt) => vt.value === t(formLoad, 'references.voucher_type.id').safeString
        ),
        emission_type: emission_type_options.find(
          (et) => et.value === t(formLoad, 'references.emission_type.id').safeString
        ),
        dentist: dentistsOptions.find((d) => d.data.id === t(formLoad, 'references.dentist.id').safeNumber),
        cpf: t(formLoad, 'references.cpf').safeString,
        cro: t(formLoad, 'references.cro').safeString,
        cro_uf: states_options.find((s) => s.value === t(formLoad, 'references.cro_uf').safeString),
        signature: t(formLoad, 'references.signature').safeString
      };
    }
    console.log(formData);

    return {
      showModal: t(formLoad, 'showModal').safeBoolean,
      dentistsOptions,
      voucher_type_options,
      emission_type_options,
      states_options,
      formData
    };
  }
);
