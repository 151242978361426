import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';

const FinancialReportsMenu = (props) => {
  const { menuSelected, selectTab } = props;
  return (
    <Row>
      <Col sm={12}>
        <nav className='nav-inline border-bottom-0'>
          <ul>
            <li className={menuSelected === 'RE' ? 'active' : ''}>
              <a href='javascript:' onClick={() => selectTab('RE')}>
                {' '}
                Recibos Emitidos{' '}
              </a>
            </li>
            {/*<li className={ menuSelected === 'DV' ? 'active' : '' }><a href="javascript:" onClick={() => selectTab('DV')}> Débitos Vencidos </a></li>*/}
            <li className={menuSelected === 'RF' ? 'active' : ''}>
              <a href='javascript:' onClick={() => selectTab('RF')}>
                {' '}
                Faturamento{' '}
              </a>
            </li>
            {/*<li className={ menuSelected === 'FO' ? 'active' : ''}><a href="javascript:" onClick={() => selectTab('FO')}> Frequência em Ortodontia </a></li>*/}
          </ul>
        </nav>
        <hr style={{ marginTop: '-16px' }}></hr>
      </Col>
    </Row>
  );
};

export default FinancialReportsMenu;
