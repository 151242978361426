export const FILL_MODAL_ADD_PATIENT = 'FILL_MODAL_ADD_PATIENT';
export const SAVE_PATIENT = 'SAVE_PATIENT';
export const SHOW_HIDE_MODAL_REGISTER = 'SHOW_HIDE_MODAL_REGISTER';
export const REFRESH_LIST = 'REFRESH_LIST';
export const SHOW_PATIENT_EDIT_MODAL = 'SHOW_PATIENT_EDIT_MODAL';

export const savePatient = (data) => {
  return {
    type: SAVE_PATIENT,
    data
  };
};

export const showHideModalRegisterPatient = (data) => {
  return {
    type: SHOW_HIDE_MODAL_REGISTER,
    payload: { display: data }
  };
};

export const showPatientEditModal = (data) => {
  return {
    type: SHOW_PATIENT_EDIT_MODAL,
    payload: {
      ...data,
      patient: {
        ...data.patient,
        sexo: data.patient.sexo ? data.patient.sexo.substring(0, 1) : ''
      }
    }
  };
};

export const refreshList = (data) => {
  return {
    type: REFRESH_LIST,
    payload: {
      refreshList: true
    }
  };
};
