// Action Types
import { Sagas } from '../sagas/TreatmentImagesSagas';

export const Types = {
  LOAD_HOME_PAGE: 'treatment/images/home'
};

//Reducer
const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.LOAD_HOME_PAGE:
      return {
        ...state,
        homePageLoad: {
          ...action.payload
        }
      };
    default:
      return state;
  }
}

// Actions
export function openHomeLoadPageSaga({ treatment }) {
  return {
    type: Sagas.ImagesLoadPage,
    payload: {
      treatment
    }
  };
}

export function openHomeLoadPage(formLoad) {
  return {
    type: Types.LOAD_HOME_PAGE,
    payload: {
      formLoad
    }
  };
}
