import React, { Component } from 'react';
import * as _ from 'lodash';
import { Col, Container, Row } from 'reactstrap';
import { t } from 'typy';
import PropTypes from 'prop-types';

class OdontogramaComponent extends Component {
  state = {
    selectedItens: {}
  };

  odontograma = {
    esquerdo: {
      superior: _.range(11, 18),
      inferior: _.range(41, 48)
    },
    direito: {
      superior: _.range(21, 28),
      inferior: _.range(31, 38)
    }
  };

  constructor(props) {
    super(props);

    const q = {};
    const r = {};

    props.quebras.forEach((quebra) => {
      q[quebra] = true;
    });
    props.recolagens.forEach((recolagem) => {
      r[recolagem] = true;
    });
    this.state = {
      selectedItens: {
        r,
        q
      }
    };
  }

  tooglerItem = (type, index) => {
    const typeSelected = this.state.selectedItens[type] || {};
    const selectedItem = typeSelected[index] || false;
    this.setState(
      {
        selectedItens: {
          ...this.state.selectedItens,
          [type]: {
            ...t(this.state, `selectedItens.${type}`).safeObject,
            [index]: !selectedItem
          }
        }
      },
      () => {
        if (this.props.daysSelected) {
          const daysObject = this.state.selectedItens[type];
          const days = [];

          Object.keys(daysObject).forEach((d) => {
            if (daysObject[d]) {
              days.push(d);
            }
          });
          this.props.daysSelected(type, days);
        }
      }
    );
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      JSON.stringify(this.props.quebras) !== JSON.stringify(nextProps.quebras) ||
      JSON.stringify(this.props.recolagens) !== JSON.stringify(nextProps.recolagens)
    ) {
      const q = {};
      const r = {};
      nextProps.quebras.forEach((quebra) => {
        q[quebra] = true;
      });
      nextProps.recolagens.forEach((recolagem) => {
        r[recolagem] = true;
      });
      this.setState({
        ...this.state,
        selectedItens: {
          ...this.state.selectedItens,
          r,
          q
        }
      });
    }
  }

  render() {
    console.log(this.state);
    const { esquerdo, direito } = this.odontograma;
    const { showQuebras = true, showRecolagens = true } = this.props;

    return this.props.show ? (
      <Container className={'odontograma'}>
        {showQuebras && (
          <Row className='w-100'>
            <Col sm={12}>
              <Row>
                <Col sm={12}>
                  <span className={'title quebras'}>Quebras</span>
                </Col>
              </Row>
              <Row>
                {esquerdo.superior
                  .slice(0)
                  .reverse()
                  .map((se) => (
                    <Col
                      className={[
                        'dentes quebra',
                        t(this.state, `selectedItens.q.${se}`).safeBoolean ? 'selected' : ''
                      ]}
                      onClick={() => this.tooglerItem('q', se)}
                    >
                      {se}
                    </Col>
                  ))}
                <div className={'separadorLateral'}></div>
                {direito.superior.map((sd) => (
                  <Col
                    className={['dentes quebra', t(this.state, `selectedItens.q.${sd}`).safeBoolean ? 'selected' : '']}
                    onClick={() => this.tooglerItem('q', sd)}
                  >
                    {sd}
                  </Col>
                ))}
              </Row>
              <Row>
                <Col sm={12} className={'separador'}></Col>
              </Row>
              <Row>
                {esquerdo.inferior
                  .slice(0)
                  .reverse()
                  .map((ie) => (
                    <Col
                      className={[
                        'dentes quebra',
                        t(this.state, `selectedItens.q.${ie}`).safeBoolean ? 'selected' : ''
                      ]}
                      onClick={() => this.tooglerItem('q', ie)}
                    >
                      {ie}
                    </Col>
                  ))}
                <div className={'separadorLateral'}></div>
                {direito.inferior.map((id) => (
                  <Col
                    className={['dentes quebra', t(this.state, `selectedItens.q.${id}`).safeBoolean ? 'selected' : '']}
                    onClick={() => this.tooglerItem('q', id)}
                  >
                    {id}
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        )}
        {showQuebras && showRecolagens && (
          <Row className={'margin-top-10 w-100'}>
            <Col className={'separador'}></Col>
          </Row>
        )}
        {showRecolagens && (
          <Row className={'w-100'}>
            <Col sm={12}>
              <Row>
                <Col sm={12}>
                  <span className={'title recolagens'}>Recolagens</span>
                </Col>
              </Row>
              <Row>
                {esquerdo.superior
                  .slice(0)
                  .reverse()
                  .map((se) => (
                    <Col
                      className={[
                        'dentes recolagens',
                        t(this.state, `selectedItens.r.${se}`).safeBoolean ? 'selected' : ''
                      ]}
                      onClick={() => this.tooglerItem('r', se)}
                    >
                      {se}
                    </Col>
                  ))}
                <div className={'separadorLateral'}></div>
                {direito.superior.map((sd) => (
                  <Col
                    className={[
                      'dentes recolagens',
                      t(this.state, `selectedItens.r.${sd}`).safeBoolean ? 'selected' : ''
                    ]}
                    onClick={() => this.tooglerItem('r', sd)}
                  >
                    {sd}
                  </Col>
                ))}
              </Row>
              <Row>
                <Col sm={12} className={'separador'}></Col>
              </Row>
              <Row>
                {esquerdo.inferior
                  .slice(0)
                  .reverse()
                  .map((ie) => (
                    <Col
                      className={[
                        'dentes recolagens',
                        t(this.state, `selectedItens.r.${ie}`).safeBoolean ? 'selected' : ''
                      ]}
                      onClick={() => this.tooglerItem('r', ie)}
                    >
                      {ie}
                    </Col>
                  ))}
                <div className={'separadorLateral'}></div>
                {direito.inferior.map((id) => (
                  <Col
                    className={[
                      'dentes recolagens',
                      t(this.state, `selectedItens.r.${id}`).safeBoolean ? 'selected' : ''
                    ]}
                    onClick={() => this.tooglerItem('r', id)}
                  >
                    {id}
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        )}
      </Container>
    ) : null;
  }
}

OdontogramaComponent.propTypes = {
  show: PropTypes.bool.isRequired,
  quebras: PropTypes.array,
  recolagens: PropTypes.array,
  daysSelected: PropTypes.func,
  showQuebras: PropTypes.bool,
  showRecolagens: PropTypes.bool
};

export default OdontogramaComponent;
