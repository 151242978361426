import React, { Component } from 'react';

import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Tooltip } from 'reactstrap';
import ReactTooltip from 'react-tooltip';

import question from '../../../../assets/img/icons/question.svg';
import Icon from '../../../../components/Icon.component';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { hideLoader, showLoader } from '../../../../components/actions/Loader.actions';
import Select from 'react-select';
import * as _ from 'lodash';
import { budgetModalTreatmentPlan } from '../redux/TreatmentBudget.actions';
import { Form, Formik } from 'formik';
import plus from '../../../../assets/img/icons/add-circular-outlined-button.svg';
import minus from '../../../../assets/img/icons/minus.svg';
import TreatmentBudgetApi from '../../../../service/TreatmentBudgetApi';
import MessagesComponent from '../../../../components/Messages.component';

import * as Yup from 'yup';
import { selectStyles } from '../../../../config/core.config';
import { reactSelectStyleIsError } from '../../../../utils/ReactSelectUtils';
import Creatable from 'react-select/creatable/dist/react-select.esm';

class TreatmentBudgeTretamentPlanModal extends Component {
  state = {
    modal: true,
    error: {
      display: false,
      type: 'danger',
      message: ''
    },
    isSubmit: false
  };

  validationFormSchema = Yup.object().shape({
    tipoTratamento: Yup.object().shape({
      value: Yup.number().required('Campo Obrigatório')
    }),
    tempoTratamentoDe: Yup.object().shape({
      value: Yup.number().required('Campo Obrigatório')
    })
    // aparelho: Yup.object().shape({
    //   value: Yup.number().required('Aparelho/Técnica obrigatório')
    // })
  });

  saveTreatmentPlan = async (values) => {
    const { showLoader, hideLoader, updateParent } = this.props;

    showLoader();
    try {
      await TreatmentBudgetApi.saveTreatmentPlan({
        orcamento: values.orcamento,
        id: values.id,
        tipo_tratamento: values.tipoTratamento && values.tipoTratamento.data && values.tipoTratamento.data.id,
        plano_tratamento: values.plano,
        tempo_tratamento_de: values.tempoTratamentoDe && values.tempoTratamentoDe.data,
        tempo_tratamento_ate: values.tempoTratamentoAte && values.tempoTratamentoAte.data,
        aparelho: values.aparelho.data && values.aparelho.data.id,
        nome_comercial: values.comercial
      });
      updateParent();
      this.closeModal();
    } catch (e) {
      console.log(e);
      this.setState({
        error: {
          display: true,
          type: 'danger',
          message: 'Erro ao salvar orçamento entre em contato com o suporte'
        }
      });
    }

    hideLoader();
  };

  closeModal = () => {
    this.setState({
      error: {
        display: false,
        type: 'danger',
        message: ''
      },
      isSubmit: false
    });
    this.props.closeModal();
  };

  render() {
    const { modal, formLoad = {}, formInit } = this.props;
    const { braces = [], treatmentTypes = [], submit = false } = formLoad;

    const { tooltip = {}, error, isSubmit } = this.state;
    let treatmentTimeOptions = [{}, ..._.range(6, 54, 6).map((h) => ({ value: h, label: h, data: h }))];

    return (
      <Modal isOpen={modal} toggle={this.closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={this.closeModal}
          close={
            <button className='close' onClick={this.closeModal}>
              &times;
            </button>
          }
        >
          <strong>Editar Plano de Tratamento</strong>
        </ModalHeader>
        <Formik
          initialValues={formInit}
          onSubmit={this.saveTreatmentPlan}
          validationSchema={this.validationFormSchema}
          validateOnChange={false}
        >
          {({ values, errors, handleChange, submitForm }) => {
            console.log(isSubmit, submit);
            console.log(!isSubmit && submit);
            // if (!isSubmit && submit) {
            //   this.setState({
            //     isSubmit: true
            //   }, () => submitForm());
            // }

            return (
              <Form>
                <ModalBody>
                  <MessagesComponent display={error.display} type={error.type} message={error.message} />
                  <div className='margin-top-10'>
                    <Row className='margin-top-10'>
                      <Col sm='12'>
                        <label>
                          Tipo de Tratamento / Fase <span>*</span>
                        </label>
                      </Col>
                      <Col sm={12}>
                        <Select
                          id={'tipoTratamento'}
                          options={treatmentTypes.map((t) => ({ value: t.id, label: t.descricao, data: t }))}
                          value={values.tipoTratamento}
                          isSearchable={true}
                          ignoreAccents={true}
                          ignoreCase={true}
                          placeholder={''}
                          styles={reactSelectStyleIsError(errors, 'tipoTratamento', selectStyles)}
                          onChange={(selectedOption) => {
                            handleChange({
                              target: {
                                name: 'tipoTratamento',
                                value: selectedOption
                              }
                            });
                          }}
                        />
                        {errors.tipoTratamento && errors.tipoTratamento.value ? (
                          <div style={{ fontSize: 10, color: 'red' }}>{errors.tipoTratamento.value}</div>
                        ) : null}
                      </Col>
                    </Row>

                    <Row className='margin-top-10'>
                      <Col sm='12'>
                        <label>Plano de Tratamento</label>
                        <a href='#' data-tip data-for='attemp'>
                          <Icon className='icon icon-size-smaller ml-2' image={question} />
                        </a>
                        <input
                          className='input-secondary'
                          type='text'
                          name='plano'
                          value={values.plano}
                          placeholder={'Exemplo: Classe II com avanço de mandíbula'}
                          onChange={handleChange}
                        />
                        <ReactTooltip className='tooltip-griks' id='attemp' type='light'>
                          Título resumido do Plano de Tratamento. Ex: Classe II sem extrações
                        </ReactTooltip>
                      </Col>
                    </Row>

                    <Row className='margin-top-10 pb-0'>
                      <Col sm='12'>
                        <label>
                          Tempo de Tratamento<span>*</span>
                        </label>
                      </Col>
                      <Col sm={5}>
                        <label>&nbsp;</label>
                      </Col>
                      {values.tempoTratamentoAte && (
                        <Col sm={5}>
                          <label>&nbsp;</label>
                        </Col>
                      )}
                      <Col sm={2}></Col>
                    </Row>
                    <Row>
                      <Col className='pr-2' sm={4}>
                        <Select
                          options={treatmentTimeOptions}
                          value={values.tempoTratamentoDe}
                          isSearchable={true}
                          ignoreAccents={true}
                          ignoreCase={true}
                          placeholder={''}
                          styles={reactSelectStyleIsError(errors, 'tempoTratamento', selectStyles)}
                          onChange={(selectedOption) => {
                            handleChange({
                              target: {
                                name: 'tempoTratamentoDe',
                                value: selectedOption
                              }
                            });
                          }}
                        />
                      </Col>
                      {values.tempoTratamentoAte && (
                        <Col sm='1' className='pt-1 text-center font-bold text-md-plus'>
                          a
                        </Col>
                      )}
                      {values.tempoTratamentoAte && (
                        <Col sm={4}>
                          <Creatable
                            allowCreateWhileLoading={true}
                            formatCreateLabel={(value) => `Criar ${value}`}
                            options={treatmentTimeOptions}
                            value={values.tempoTratamentoAte}
                            // isSearchable={ true }
                            // ignoreAccents={ true }
                            // ignoreCase={ true }
                            placeholder={''}
                            styles={reactSelectStyleIsError(errors, 'tempoTratamentoAte', selectStyles)}
                            onChange={(selectedOption) => {
                              if (!selectedOption.data) {
                                selectedOption.data = selectedOption.value;
                              }

                              handleChange({
                                target: {
                                  name: 'tempoTratamentoAte',
                                  value: selectedOption
                                }
                              });
                            }}
                          />
                        </Col>
                      )}
                      <Col sm='2'>
                        <label className='mt-2'>Meses</label>
                      </Col>
                      <Col sm='1' className='pl-2 pt-2'>
                        {!values.tempoTratamentoAte && (
                          <a
                            href={'#'}
                            className={''}
                            onClick={() => {
                              handleChange({
                                target: {
                                  name: 'tempoTratamentoAte',
                                  value: {}
                                }
                              });
                            }}
                          >
                            <Icon className='icon icon-size-two' image={plus} style={{ width: 22 }} alt='plus' />
                          </a>
                        )}

                        {values.tempoTratamentoAte && (
                          <a
                            href={'#'}
                            className={''}
                            onClick={() => {
                              handleChange({
                                target: {
                                  name: 'tempoTratamentoAte',
                                  value: null
                                }
                              });
                            }}
                            style={{ alignSelf: 'center' }}
                          >
                            <Icon
                              className='icon icon-size-two absolute-center'
                              image={minus}
                              style={{ width: 24 }}
                              alt='plus'
                            />
                          </a>
                        )}
                      </Col>
                    </Row>

                    {errors.tempoTratamentoDe && errors.tempoTratamentoDe.value ? (
                      <Row>
                        <Col sm={12}>
                          <div style={{ fontSize: 10, color: 'red' }}>{errors.tempoTratamentoDe.value}</div>
                        </Col>
                      </Row>
                    ) : null}

                    <Row className='margin-top-10'>
                      <Col sm='12'>
                        <label>Aparelho/Técnica</label>
                      </Col>
                      <Col sm='12'>
                        <Select
                          options={braces.map((b) => ({ value: b.id, label: b.descricao, data: b }))}
                          value={values.aparelho}
                          isSearchable={true}
                          ignoreAccents={true}
                          ignoreCase={true}
                          placeholder={''}
                          styles={reactSelectStyleIsError(errors, 'aparelho', selectStyles)}
                          onChange={(selectedOption) => {
                            handleChange({
                              target: {
                                name: 'aparelho',
                                value: selectedOption
                              }
                            });
                          }}
                        />
                        {errors.aparelho && errors.aparelho.value ? (
                          <div style={{ fontSize: 10, color: 'red' }}>{errors.aparelho.value}</div>
                        ) : null}
                      </Col>
                    </Row>

                    <Row className='margin-top-10'>
                      <Col sm='12'>
                        <label>Nome Comercial</label>
                        <input
                          className='input-secondary'
                          type='text'
                          name='comercial'
                          value={values.comercial}
                          onChange={handleChange}
                        ></input>
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Row>
                    <Col className='btn-list' sm={{ size: 6, offset: 6 }}>
                      <button className='button-primary' type='submit'>
                        Salvar
                      </button>
                      <button className='button-tertiary' type={'button'} onClick={this.closeModal}>
                        Cancelar
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = ({ TreatmentBudgetReducer }) => {
  const { treatmentPlan = {} } = TreatmentBudgetReducer;
  const { formLoad: { budget = {}, treatmentTypes = [], braces = [], evaluation = {} } = {} } = treatmentPlan;
  const { plano_tratamento = {} } = budget;

  let tipoTratamentoOption = {};

  if (plano_tratamento.tipo_tratamento) {
    const type = treatmentTypes.find((t) => plano_tratamento.tipo_tratamento.id === t.id);
    tipoTratamentoOption = { value: type.id, label: type.descricao, data: type };
  }

  let aparelhoOption = {};
  if (plano_tratamento.aparelho) {
    const type = braces.find((t) => plano_tratamento.aparelho.id === t.id);
    aparelhoOption = { value: type.id, label: type.descricao, data: type };
  } else if (evaluation.aparelho) {
    const brache = braces.find((t) => evaluation.aparelho.id === t.id);
    aparelhoOption = { value: brache.id, label: brache.descricao, data: brache };
  }

  return {
    ...treatmentPlan,
    formInit: {
      orcamento: budget.id,
      id: plano_tratamento.id,
      tipoTratamento: tipoTratamentoOption,
      plano: plano_tratamento.plano_tratamento,
      tempoTratamentoDe: plano_tratamento.tempo_tratamento_de && {
        value: plano_tratamento.tempo_tratamento_de,
        label: plano_tratamento.tempo_tratamento_de,
        data: plano_tratamento.tempo_tratamento_de
      },
      tempoTratamentoAte: plano_tratamento.tempo_tratamento_ate && {
        value: plano_tratamento.tempo_tratamento_ate,
        label: plano_tratamento.tempo_tratamento_ate,
        data: plano_tratamento.tempo_tratamento_ate
      },
      aparelho: aparelhoOption,
      comercial: plano_tratamento.nome_comercial
    }
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(budgetModalTreatmentPlan({ modal: false })),
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader())
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(TreatmentBudgeTretamentPlanModal);
