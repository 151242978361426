import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import logo from '../../assets/img/logo.jpg';
import ForgotPasswordForm from './ForgotPassword.form';
import Loader from '../../components/Loader.component';
import ErrorMessage from '../../components/ErrorMessage.component';
import { forgotPassword } from '../../service/Login.service';
import { showLoader, hideLoader } from '../../components/actions/Loader.actions';
import { connect } from 'react-redux';

class ForgotPasswordPage extends Component {
  state = {
    error: {
      display: false,
      message: '',
      type: ''
    }
  };

  forgotPasswordSend = (values) => {
    this.props.showLoader();
    forgotPassword(values)
      .then((response) => {
        let message =
          'Senha reiniciada com sucesso, em breve você receberá um e-mail com o link para alteração da senha';

        if (response.status !== 204) {
          message = 'Ocorreu um erro entre em contato com o administrador';
        }

        this.props.hideLoader();
        this.setState({
          error: {
            display: true,
            message,
            type: 'info'
          }
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          const { message } = error.response.data;
          this.props.hideLoader();

          this.setState({
            error: {
              display: true,
              message: message,
              type: 'danger'
            }
          });
        } else {
          this.props.hideLoader();

          this.setState({
            error: {
              display: true,
              message: 'Erro ao recuperar sua senha entre em contato com o administrador',
              type: 'danger'
            }
          });
        }
      });
  };

  render() {
    const { error } = this.state;
    const { display, message, type } = error;

    return (
      <Container>
        <Loader />
        <main className='main-container-overflow'>
          <div className='login-container'>
            <div className='login-box'>
              {/* <div className="login-box-left">
                <span>Conteúdo Publicitário do <strong>GRIKS</strong></span>
              </div> */}
              <div className='login-box-right'>
                <Row>
                  <Col sm='12'>
                    <img className='img-logo-login' src={logo} alt='logo' />
                  </Col>
                </Row>
                <Row>
                  <Col sm={{ size: 8, offset: 2 }}>
                    <ErrorMessage display={display} type={type} message={message} />
                    <ForgotPasswordForm submitCallback={this.forgotPasswordSend} />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </main>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader())
  };
};

const enhanced = compose(withRouter, connect(mapStateToProps, mapDispatchToProps));

export default enhanced(ForgotPasswordPage);
