import { call, put } from 'redux-saga/effects';
import DomainsAPI from '../../../../service/DomainsAPI';
import { treatmentEvaluationComplaint } from '../redux/TreatmentEvaluation.action';
import { hideLoader, showLoader } from '../../../../components/actions/Loader.actions';

export function* modalEvaluationComplaint({ payload: { modal, formLoad = {} } }) {
  yield put(showLoader());
  const complaint = yield call(DomainsAPI.findAllComplaint);
  const patientInterests = yield call(DomainsAPI.findAllInterestsOfPatient);
  const data = {
    modal,
    formLoad: {
      ...formLoad,
      complaints: complaint,
      patientInterests
    }
  };

  yield put(treatmentEvaluationComplaint(data));
  yield put(hideLoader());
}
