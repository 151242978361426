import React, { Component } from 'react';
import { ButtonDropdown, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';

import ReactTooltip from 'react-tooltip';

import Clock from 'react-live-clock';

import * as moment from 'moment';
import 'moment/locale/pt-br';

import off from '../../assets/img/icons/off.svg';
import help from '../../assets/img/icons/help.svg';
import user from '../../assets/img/icons/avatar.svg';
import search from '../../assets/img/icons/search.svg';
import Icon from '../../components/Icon.component';
import logo from '../../assets/img/logo-partial.jpg';

import { getClinic, getUserData, logout } from '../../utils/localStorage/SessionStorageUtils';

import PatientAPI from '../../service/PacienteAPI';

import Radium, { StyleRoot } from 'radium';
import { fadeIn } from 'react-animations';
import noavatar from '../../assets/img/noavatar.png';

import SugestionBoxComponent from '../../components/SuggestionBoxComponent';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import PatinentViewModal from '../patinent/components/PatientViewModal.component';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { hideLoader, showLoader } from '../../components/actions/Loader.actions';
import { sendMessage } from '../../components/actions/ErrorMessage.actions';
import ToastUtils from '../../utils/ToastUtils';
import { IfComponent } from '../../components/if.component';
import t from 'typy';
import { withRouter } from 'react-router';
import MyAccountModal from '../user_home/MyAccountModal';
import StringUtils from '../../utils/StringUtils';

class HeaderPage extends Component {
  state = {
    dropdownUserOpen: false,
    tooltipOpen: false,
    weekDay: '',
    day: '',
    suggestionsData: [],
    value: '',
    displayDetailModal: false,
    patientData: {},
    user: {},
    cliente: {}
  };

  myAccountref;

  constructor() {
    super();
    moment.locale('pt-BR');
    const now = moment();
    this.state.weekDay = now.format('dddd');
    this.state.day = now.format('DD MMM');
    this.myAccountref = React.createRef();
  }

  async componentDidMount() {
    const user = await getUserData();
    const clinic = await getClinic();
    this.setState({
      user,
      clinic
    });
  }

  openDropDownUser = () => {
    this.setState({
      dropdownUserOpen: !this.state.dropdownUserOpen,
      tooltipOpen: false
    });
  };

  selectedPatient = (patientSelected) => {
    this.selectPatientAndShowModal(patientSelected);
  };

  renderSuggestionsContainer({ containerProps, children, query }) {
    console.log('children',children)
    
    return (
      <StyleRoot>
        <div
          {...containerProps}
          style={{
            animation: 'x 0.9s',
            animationName: Radium.keyframes(fadeIn, 'fadeIn'),
            zIndex: 999999,
            maxHeight: 590,
            overflowY: 'auto',
            width: 400
          }}
        >
          {children}
        </div>
      </StyleRoot>
    );
  }

  findPatients = async (searchTerm) => {
    if (searchTerm.length > 2) {
      try {
        const p = await PatientAPI.pescInc(searchTerm);
        console.log('p',p)
        if (p.length === 0) {
          p.push({
            nome: 'Nenhum paciente encontrado'
          });
        }

        this.setState({
          suggestionsData: p.map((pt) => {
            return {
              id: pt.id,
              name: pt.nome,
              image: pt.foto || noavatar
            };
          })
        });
      } catch (err) {
        this.setState({
          suggestionsData: []
        });
      }
    } else {
      this.setState({
        suggestionsData: []
      });
    }
  };

  renderInputComponent = (inputProps) => (
    <div style={{ padding: 5 }}>
      <div className='img-search'>
        <Icon className='icon-search icon-size-one' image={search} alt='search' />
      </div>
      <input
        {...inputProps}
        className='input-box-search-header'
        type='text'
        name='firstname'
        placeholder='Buscar Paciente'
        autoComplete='off'
        data-lpignore='true'
        spellCheck='false'
      />
    </div>
  );

  selectPatientAndShowModal = (p) => {
    const { showLoader, hideLoader } = this.props;

    showLoader();

    PatientAPI.findById(p.id)
      .then((data) => {
        hideLoader();
        this.setState({
          displayDetailModal: true,
          patientData: data,
          value: ''
        });
      })
      .catch((error) => {
        console.error(error);
        hideLoader();
        ToastUtils.error('Erro ao buscar dados do pacientes');
      });
  };

  renderSuggestion(suggestion, { query }) {
    const suggestionText = `${suggestion.name}`;
    const matches = AutosuggestHighlightMatch(suggestionText, query);
    const parts = AutosuggestHighlightParse(suggestionText, matches);
    console.log('suggestionText',suggestionText)
    console.log('matches',matches)
    console.log('parts',parts)
    if (suggestion.id) {
      return (
        <span className={'suggestion-content'}>
          <img className={'img-circle'} src={suggestion.image} style={{ maxWidth: 62, maxHeight: 62 }} alt='' />
          <span className='name' style={{ fontSize: '14px' }}>
            {parts.map((part, index) => {
              const className = part.highlight ? 'highlight' : null;

              return (
                <span className={className} key={index}>
                  {part.text.length > 30 ?
                    <a data-tip data-for={part.text}>
                      {part.text.match(/.{1,30}/g)[0]}...
                      <ReactTooltip className='tooltip-griks' id={part.text} type='gray'>
                        {suggestionText}
                      </ReactTooltip>
                    </a>
                    :
                    part.text
                  }
                </span>
              );
            })}
          </span>
        </span>
      );
    } else {
      return (
        <span className={'suggestion-content'}>
          <span className='name'>
            <span className={''}>
              {'Nenhum paciente encontrado com o nome'} {<span className='highlight'>({query})</span>}
            </span>
          </span>
        </span>
      );
    }
  }

  closeModalDetail = () => {
    this.setState({ displayDetailModal: false });
  };

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue
    });
  };

  _getUserName() {
    const { user } = this.state;

    if (user.usuarioCadastro) {
      let name = user.usuarioCadastro.toUpperCase() || user.nome.toUpperCase();
      return StringUtils.abbreviateString(name);
      // name = name.replace('DR', '');
      // // eslint-disable-next-line no-useless-escape
      // name = name.replace('\.', '');
      // name = name.replace('Dra', '');
      // // eslint-disable-next-line no-useless-escape
      // name = name.replace('\.', '');
      //
      // let names = name.trim().split(' ');
      //
      // if (names.length > 1) {
      //   return (names[0].substring(0, 1) + names[names.length - 1].substring(0, 1)).toUpperCase();
      // } else {
      //   return names[0].substring(0, 2).toUpperCase();
      // }
    }
  }

  _isTrial = () => {
    return t(this.state, 'clinic.cliente.status').safeString === 'T';
  };

  _trialTime = () => {
    if (t(this.state, 'clinic.cliente.data_inicio_trial').isDefined) {
      var dateStartTrial = moment(this.state.clinic.cliente.data_inicio_trial);

      dateStartTrial = dateStartTrial.add(10, 'd');
      return dateStartTrial.diff(moment(), 'd');
    }
  };

  render() {
    const {
      weekDay,
      day,
      dropdownUserOpen,
      value,
      suggestionsData,
      displayDetailModal,
      patientData,
      user
    } = this.state;

    const inputProps = {
      placeholder: '',
      value,
      onChange: this.onChange
    };

    return (
      <Container>
        <header className='header-section'>
          <main className='main-container'>
            <Row className='header-height'>
              <Col sm='2'>
                <img
                  className='img-logo vertical-center'
                  src={logo}
                  alt='logo'
                  style={{
                    width: 90,
                    height: 34,
                    marginLeft: 90
                  }}
                />
              </Col>
              <Col sm='4'>
                <form className='rec-form' autoComplete='off' >
                  <SugestionBoxComponent
                    renderSuggestionsContainer={this.renderSuggestionsContainer}
                    renderSuggestion={this.renderSuggestion}
                    renderInputComponent={this.renderInputComponent}
                    findSuggestions={this.findPatients}
                    selectedSuggestion={this.selectedPatient}
                    suggestionsData={suggestionsData}
                    inputProps={inputProps}
                  />
                </form>
              </Col>
              <Col sm='1'>
                <div className='time-system'>
                  <span>
                    <Clock format={'HH:mm'} ticking={true} />
                  </span>
                  <p>
                    {weekDay} | {day}
                  </p>
                </div>
              </Col>
              <Col sm='3'>
                <IfComponent conditional={this._isTrial()}>
                  <div onClick={this.subscribeNow} className='alert alert-trial' role='alert'>
                    O Período de Testes Termina em {this._trialTime()} DIAS <br />
                    <a href='#'> ASSINE JÁ </a>
                  </div>
                </IfComponent>
              </Col>

              <Col className='px-0' sm='2'>
                <div className='user-out float-right'>
                  <a data-tip='Logoff' href='#' onClick={logout}>
                    <Icon className='icon-alert icon-size-small' image={off} alt='off' />
                  </a>
                </div>
                <div className='icon-info spaced float-right'>
                  <a data-tip='Ajuda' href='https://intercom.help/griks/pt-BR' target={'_blank'}>
                    <Icon className='icon-alert icon-size-small' image={help} alt='Help' />
                  </a>
                </div>

                <div className='user-area float-right mr-2'>
                  <ButtonDropdown isOpen={dropdownUserOpen} toggle={this.openDropDownUser}>
                    <DropdownToggle>
                      <div className='icon-fake float-right'>
                        <a data-tip data-for={'user'} href='#'>
                          {this._getUserName()}
                        </a>
                      </div>
                    </DropdownToggle>
                    <DropdownMenu style={{ marginLeft: -35 }}>
                      <DropdownItem disabled>
                        <span className='text-sm'>{(user && user.usuarioCadastro) || user.nome}</span>
                      </DropdownItem>
                      <DropdownItem disabled>
                        {' '}
                        <span className='text-sm'>{user && user.login}</span>
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem>
                        {' '}
                        <span className='text-sm'>Minha Assinatura</span>
                      </DropdownItem>
                      <DropdownItem onClick={() => this.myAccountref.current.showModal()}>
                        {' '}
                        <span className='text-sm'>Minha Conta</span>
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem onClick={logout}>
                        <span className='text-sm'>SAIR</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                  <ReactTooltip effect='solid' className='tooltip-griks' type='light' id='user'>
                    {(user && user.usuarioCadastro) || user.nome}
                  </ReactTooltip>
                </div>
                <ReactTooltip effect='solid' className='tooltip-griks' type='light' />
              </Col>
            </Row>
          </main>
        </header>
        <PatinentViewModal
          redirect={this.closeModalDetail}
          {...this.props}
          showDetailModal={displayDetailModal}
          patientData={patientData}
          closeModal={this.closeModalDetail}
        />
        <MyAccountModal ref={this.myAccountref} />
      </Container>
    );
  }

  subscribeNow = async () => {
    const user = await getUserData();
    this.props.history.push('/trial/subscribe-now', { email: user.login });
  };
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (data) => dispatch(showLoader(data)),
    hideLoader: (data) => dispatch(hideLoader(data)),
    sendMessage: (data) => dispatch(sendMessage(data))
  };
};

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withRouter);

export default enhanced(HeaderPage);
