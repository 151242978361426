import React, { Component } from 'react';

import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  formInitSelector,
  gadgetsSelector,
  propsModalSelector
} from '../selectors/TreatmentClinicalRecordTreatmentGadgetsModalSelector';
import { clinicalRecordTreatmentGadgetsModal } from '../redux/TreatmentClinicalRecord.action';
import MessagesComponent from '../../../../components/Messages.component';
import { t } from 'typy';
import { showLoader, hideLoader } from '../../../../components/actions/Loader.actions';
import TreatmentClinicalRecordApi from '../../../../service/TreatmentClinicalRecordApi';

class TreatmentClinicalRecordTreatmentPlanGadgetsModal extends Component {
  state = {
    message: {
      display: false,
      type: 'danger',
      text: ''
    }
  };

  closeModal = () => {
    this.props.closeModal();
    this.setState({
      error: {
        display: false,
        type: 'danger',
        message: ''
      }
    });
  };

  createGroup = (g) => {
    this.props.showLoader();
    const group = [this.createHeader(g)];
    group.push(g.dispositivos.flatMap((d) => this.createBody(d)));
    this.props.hideLoader();
    return group;
  };

  createHeader = (g) => {
    return (
      <thead key={g.id}>
        <tr>
          <th>{g.nome}</th>
          <th width='5%'></th>
        </tr>
      </thead>
    );
  };

  createBody = (dispositivo) => {
    const { gadgetsSelected } = this.state;
    return (
      <tr key={dispositivo.id}>
        <td>{dispositivo.nome}</td>
        <td>
          <Col sm='12'>
            <input
              type='checkbox'
              className='form-checkbox'
              id='check-one'
              onClick={() => {
                this.clickedGadged(dispositivo);
              }}
              checked={gadgetsSelected && gadgetsSelected[dispositivo.id]}
            />
          </Col>
        </td>
      </tr>
    );
  };

  clickedGadged = (d) => {
    const { gadgetsSelected = {} } = this.state;
    this.setState({
      gadgetsSelected: {
        ...gadgetsSelected,
        [d.id]: !gadgetsSelected[d.id]
      }
    });
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const actual = t(this.props, 'formInit.aparelhos_dispositivos').isArray;
    const next = t(this.nextProps, 'formInit.aparelhos_dispositivos').isArray;
    if (
      actual !== next &&
      this.props.formInit.aparelhos_dispositivos.length !== nextProps.formInit.aparelhos_dispositivos.length
    ) {
      let gadget = {};

      nextProps.formInit.aparelhos_dispositivos.forEach((d) => {
        gadget[d.id] = true;
      });

      this.setState({
        gadgetsSelected: gadget
      });
    }
    return true;
  }

  saveGadgets = async () => {
    const { treatment, record, plan, updateParent, showLoader, hideLoader } = this.props;
    const { gadgetsSelected } = this.state;

    try {
      showLoader();
      const gadgets = Object.keys(gadgetsSelected);
      const requestSave = gadgets
        .map((g) => {
          return gadgetsSelected[g] ? { aparelho_dispositivo: g } : null;
        })
        .filter((g) => g !== null);

      await TreatmentClinicalRecordApi.saveTreatmentPlanGadgets({
        treatmentId: treatment,
        recordId: record,
        planId: plan,
        gadgets: requestSave
      });
      updateParent();
      this.closeModal();
    } catch (e) {
      console.log(e);
      this.setState({
        message: {
          display: true,
          type: 'danger',
          text: 'Erro ao salvar Aparelhos e Outros Dispositivos entre em contato com o suporte'
        }
      });
    }
    hideLoader();
  };

  render() {
    const { modal, gadgets = [] } = this.props;
    const { message } = this.state;

    return (
      <Modal isOpen={modal} toggle={this.closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={this.closeModal}
          close={
            <button className='close' onClick={this.closeModal}>
              &times;
            </button>
          }
        >
          <strong>Adicionar</strong> Aparelhos e Outros Dispositivos
        </ModalHeader>
        <ModalBody>
          <div className='margin-top-10'>
            <MessagesComponent display={message.display} type={message.type} message={message.text} />
            <Row>
              <Col sm='12' style={{ overflow: 'auto', height: 490 }}>
                <Table responsive>{gadgets.map((g) => this.createGroup(g))}</Table>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col sm={{ size: 3, offset: 6 }}>
              <button className='button-primary' onClick={this.saveGadgets}>
                Salvar
              </button>
            </Col>
            <Col sm='3'>
              <button className='button-tertiary' onClick={this.closeModal}>
                Cancelar
              </button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...propsModalSelector(state),
    gadgets: gadgetsSelector(state),
    formInit: formInitSelector(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  closeModal: () => dispatch(clinicalRecordTreatmentGadgetsModal({ modal: false }))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(TreatmentClinicalRecordTreatmentPlanGadgetsModal);
