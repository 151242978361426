import React, { Component } from 'react';

import Autosuggest from 'react-autosuggest';

import './SimpleSuggestionBox.css';

import PropTypes from 'prop-types';
import _ from 'lodash';

class SimpleSuggestionBoxComponent extends Component {
  static propTypes = {
    selectSuggestion: PropTypes.func.isRequired,
    clearSuggestions: PropTypes.func.isRequired,
    suggestions: PropTypes.array.isRequired,
    findSuggestions: PropTypes.func.isRequired,
    placeholder: PropTypes.string
  };

  state = {
    value: '',
    alreadyTyped: false,
    selected: false
  };

  onChange = (event, { newValue, method }) => {
    if (newValue === '') this.props.selectSuggestion({});
    this.setState({
      value: newValue,
      alreadyTyped: true,
      selected: false
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setSearchTerm(value, 500);
  };

  setSearchTerm = _.debounce((searchTerm) => {
    this.props.findSuggestions(searchTerm);
  }, 500);

  render() {
    const {
      suggestions,
      placeholder = '',
      selectSuggestion,
      className = '',
      clearSuggestions,
      value,
      defaultValue = {}
    } = this.props;

    let valueSuggestion =
      this.state.value === undefined ? (value.name === undefined ? '' : value.name) : this.state.value;

    if (!this.state.alreadyTyped) {
      valueSuggestion = defaultValue.name || '';
    }

    const inputProps = {
      placeholder: placeholder,
      value: valueSuggestion,
      onChange: this.onChange,
      className: className
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsClearRequested={clearSuggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        getSuggestionValue={(value) => {
          this.setState({ selected: true });
          selectSuggestion(value);
        }}
        renderSuggestion={(suggestion) => <span>{suggestion.name}</span>}
        inputProps={inputProps}
      />
    );
  }
}

export default SimpleSuggestionBoxComponent;
