import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import ReportsMenu from './ReportsMenu';
import PrivateRouter from '../../components/PrivateRoute.component';
import PatientsReportsPage from './patients/PatientsReportsPage';
import { IfComponent } from '../../components/if.component';
import Icon from '../../components/Icon.component';
import relatorios from '../../assets/img/icons/reports.svg';
import { FaRegChartBar } from 'react-icons/fa';
import patient from '../../assets/img/icons/user.svg';
import { savePatient, showHideModalRegisterPatient, showPatientEditModal } from '../patinent/redux/patient.actions';
import { setHeader } from '../template/redux/Content.actions';
import { hideLoader, showLoader } from '../../components/actions/Loader.actions';
import { sendMessage } from '../../components/actions/ErrorMessage.actions';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import withSecurity from '../../config/security/security';
import FinancialReportsPage from './financial/FinancialReportsPage';

class ReportsPage extends Component {
  state = {
    menu: 'p'
  };

  componentDidMount() {
    this.props.setHeader({
      title: ''
    });
  }

  _selectMenu = (menu) => {
    const { loadPatientReport, loadFinancialReport } = this.props;

    this.setState({
      menu
    });

    switch (menu) {
      case 'p':
        /*this.props.loadPatientOverviewReport();*/
        break;
      case 'f':
        /*this.pros.loadFinancialReport();*/
        break;
    }
    return false;
  };

  render() {
    const { menu } = this.state;
    return (
      <div className='content-box-without-color' style={{ marginTop: '-20px' }}>
        <Container>
          <div>
            <Row>
              <Col sm='6'>
                <Row>
                  <Col sm='6'>
                    <div className='icon-header'>
                      <FaRegChartBar color='#979797' size={25} />
                    </div>
                    <div className='title-content'>Relatórios</div>
                  </Col>
                </Row>
              </Col>
              <Col sm='6'>
                <ReportsMenu selectMenu={this._selectMenu} menuSelected={menu} />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <IfComponent conditional={menu === 'p'}>
                  <PatientsReportsPage />
                </IfComponent>
                <IfComponent conditional={menu === 'f'}>
                  <FinancialReportsPage />
                </IfComponent>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  setHeader: (data) => dispatch(setHeader(data)),
  showLoader: (data) => dispatch(showLoader(data)),
  hideLoader: (data) => dispatch(hideLoader(data)),
  loadPatientReport: (data) => dispatch(),
  loadFinancialReport: (data) => dispatch()
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withSecurity);

export default enhanced(ReportsPage);
