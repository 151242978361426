import React from 'react';
import PrivateRouter from '../components/PrivateRoute.component';
import PatientPage from '../pages/patinent/patient.page';
import PatientDeletedList from '../pages/patinent/patientDeletedList.page';
import PatientRegistrationFormPage from '../pages/patinent/PatientRegistrationForm.page';

const PatientsRouters = (props) => {
  return [
    <PrivateRouter key='patientHome' exact path='/patient' render={(props) => <PatientPage {...props} />} />,
    <PrivateRouter
      key='patientGps'
      exact
      path='/patient/deleted'
      render={(props) => <PatientDeletedList {...props} />}
    />,
    <PrivateRouter
      key='patientRegistrationForm'
      exact
      path='/patient/:patientId/registration-form/'
      render={(props) => <PatientRegistrationFormPage {...props} />}
    />
  ];
};

export default PatientsRouters;
