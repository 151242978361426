import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import noavatar from '../../../assets/img/noavatar.png';
import PropTypes from 'prop-types';
import { IfComponent } from '../../../components/if.component';
import { t } from 'typy';

class PatientHeaderComponent extends Component {
  render() {
    const { id, foto, nome, numero_identificacao, idade, botao, history } = this.props;

    return (
      <Row className='backin register-container-top'>
        <Col sm='12'>
          <div>
            <Row>
              <Col sm='1'>
                <div style={{ height: 94 }} className='img-user-search'>
                  <img
                    style={{ width: 80, height: 80, marginTop: -10 }}
                    className={'img-circle'}
                    src={foto || noavatar}
                    alt='usuario'
                  />
                </div>
              </Col>
              <Col sm='3'>
                <div className='title-medium pl-2'>
                  <p className='mt-2'>{nome}</p>
                  <p className='text-default text-md'>
                    <IfComponent conditional={t(numero_identificacao).safeString !== ''}>
                      ({numero_identificacao}) &nbsp;
                    </IfComponent>
                    {idade}
                  </p>
                </div>
              </Col>
              <Col sm={{ size: 4, offset: 4 }} className={'btn-list text-right pt-2 pr-4'}>
                <button
                  onClick={() => {
                    history.push(`/patient/${id}/treatment`);
                  }}
                  className={'float-right ' + (botao === 'TRATAMENTO' ? 'button-primary' : 'button-tertiary')}
                >
                  Tratamentos
                </button>
                <button
                  onClick={() => {
                    history.push(`/patient/${id}/registration-form/`);
                  }}
                  className={'float-right ' + (botao === 'CADASTRO' ? 'button-primary' : 'button-tertiary')}
                >
                  Cadastro
                </button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    );
  }
}

PatientHeaderComponent.propTypes = {
  foto: PropTypes.string,
  nome: PropTypes.string.isRequired,
  numero_identificacao: PropTypes.string.isRequired,
  idade: PropTypes.string.isRequired,
  botao: PropTypes.string.isRequired
};

export default PatientHeaderComponent;
