import React, { Component } from 'react';
import { Nav, NavItem, NavLink, NavbarBrand } from 'reactstrap';
import Tooltip from 'react-tooltip';
import Icon from '../../components/Icon.component';
import { PERMISSOES } from '../../config/security/permissoes';
import withSecurity from '../../config/security/security';
import { compose } from 'recompose';
import TostPatientComponent, { closeToast } from '../schedule/TostPatient.component';
import { connect } from 'react-redux';
import { appointmentRedialCalendar } from '../schedule/redux/Schedule.actions';
import TostMessageComponent from '../../components/ToastMessage.component';
import AffiliatesApi from '../../service/AffiliatesApi';
import { getClinic, isAffiliate } from '../../utils/localStorage/SessionStorageUtils';

import logoIcon from '../../assets/img/logo-icon.png';
import calendar from '../../assets/img/icons/calendar.svg';
import flow from '../../assets/img/icons/flow1.svg';
import user from '../../assets/img/icons/user.svg';
import contact from '../../assets/img/icons/contact.svg';
import machine from '../../assets/img/icons/machine.svg';
import reports from '../../assets/img/icons/reports.svg';
import affiliates from '../../assets/img/icons/affiliates.svg';
import settings from '../../assets/img/icons/settings.svg';

const menu = {
  selectedItem: '',
  items: [
    {
      id: 'agenda',
      title: 'Agenda',
      icon: calendar,
      link: '/schedule',
      css: 'icon-nav icon-size-one',
      permission: PERMISSOES.modules.agenda.id
    },
    {
      id: 'fluxo',
      title: 'Fluxo',
      icon: flow,
      link: '/flow',
      css: 'icon-nav icon-size-one',
      permission: () => true
    },
    {
      id: 'paciente',
      title: 'Pacientes',
      icon: user,
      link: '/patient',
      css: 'icon-nav icon-size-one',
      permission: PERMISSOES.modules.pacientes.id
    },
    {
      id: 'treatment',
      title: 'Contatos',
      icon: contact,
      link: '/contacts',
      css: 'icon-nav icon-size-six',
      permission: PERMISSOES.modules.contatos.id
    },
    {
      id: 'machine',
      title: 'Caixa',
      icon: machine,
      link: '/cash',
      css: 'icon-nav icon-size-one caixa',
      permission: PERMISSOES.modules.caixa.id
    },
    {
      id: 'reports',
      title: 'Relatórios',
      icon: reports,
      link: '/reports',
      css: 'icon-nav icon-size-one',
      permission: PERMISSOES.modules.configuracoes.id //PERMISSOES.modules.reports.id
    },
    {
      id: 'affiliates',
      title: 'Afiliados',
      icon: affiliates,
      link: '/affiliates',
      css: 'icon-nav icon-size-one',
      permission: () => isAffiliate()
    },
    {
      id: 'settings',
      title: 'Configurações',
      icon: settings,
      link: '/settings',
      css: 'icon-nav icon-size-one',
      permission: PERMISSOES.modules.configuracoes.id
    }
  ]
};

class MenuPage extends Component {
  state = {
    ...menu
  };

  goPage = (item) => {
    this.props.history.push(item.link);
    closeToast();
  };

  render() {
    const { selectedItem, items } = this.state;
    const { security, location } = this.props;
    return (
      <main className='main-container'>
        <TostPatientComponent />
        <div className='nav-primary bg-primary-d5'>
          <Nav vertical>
            <NavbarBrand href='/'>
              <img style={{ width: 39 }} src={logoIcon} alt='' />
            </NavbarBrand>
            {items
              .filter((i) => {
                if (typeof i.permission === 'function') {
                  return i.permission();
                } else {
                  return security.hasModuleAccess(i.permission);
                }
              })
              .map((i) => {
                return (
                  <NavItem
                    data-tip={i.title}
                    data-place='right'
                    key={i.id}
                    active={location.pathname !== '/' ? location.pathname.includes(i.link) : i.id === 'agenda'}
                  >
                    <NavLink href='#' onClick={() => this.goPage(i)}>
                      <Icon className={i.css} image={i.icon} alt={i.id} />
                    </NavLink>
                  </NavItem>
                );
              })}
          </Nav>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  appointmentRedialCalendar: (data) => dispatch(appointmentRedialCalendar(data))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withSecurity);

export default enhanced(MenuPage);
