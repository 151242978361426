import { SETTINGS_USERS_LIST_ALL, SETTINGS_USERS_MODAL_SUCCESS, SETTINGS_USERS_REGISTER } from './UserSettings.actions';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS_USERS_LIST_ALL:
      return {
        ...state,
        all: {
          ...action.data
        }
      };
    case SETTINGS_USERS_REGISTER:
      return {
        ...state,
        ...action.payload
      };
    case SETTINGS_USERS_MODAL_SUCCESS:
      return {
        ...state,
        success: {
          ...action.payload
        }
      };

    default:
      return state;
  }
};
