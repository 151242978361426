import React, { Component } from 'react';

import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { appointmentMissingModalSelectorToProps } from '../selectors/AppointmentMissingModal.selector';
import { appointmentMissing, scheduleAppointmentDetailModal } from '../redux/Schedule.actions';
import { t } from 'typy';
import ScheduleAPI from '../../../service/ScheduleAPI';
import { showLoader, hideLoader } from '../../../components/actions/Loader.actions';
import MessagesComponent from '../../../components/Messages.component';
import moment from 'moment';
import { IfComponent } from '../../../components/if.component';

class AppointmentMissingModal extends Component {
  state = {
    comments: '',
    message: {
      display: false,
      type: 'danger',
      text: ''
    }
  };

  closeModal = () => {
    this.props.closeModal();
  };

  setComments = ({ target: { value } }) => {
    this.setState({ comments: value });
  };

  saveAppoingmentMissing = () => {
    const { schedule = {}, showLoader, hideLoader, updateParent, closeDetailModal } = this.props;
    const { comments } = this.state;

    showLoader();
    ScheduleAPI.appointmentMissing({ id: schedule.id, comments })
      .then((d) => {
        updateParent(moment(schedule.data_agendamento));
        hideLoader();
        closeDetailModal();
        this.closeModal();
      })
      .catch((err) => {
        this.setState({
          message: {
            display: true,
            type: 'danger',
            text: 'Erro ao lançar falta na consulta, entre em contato com o suporte'
          }
        });
      });
  };

  render() {
    const { modal, schedule = {} } = this.props;
    const { paciente = {}, procedimento = {} } = schedule;
    const { message } = this.state;

    return (
      <Modal isOpen={modal} toggle={this.closeModal} className={this.props.className + 'modal-small'}>
        <ModalHeader
          className='title-primary'
          toggle={this.closeModal}
          close={
            <button className='close' onClick={this.closeModal}>
              &times;
            </button>
          }
        >
          <strong>Lançar Falta</strong>
          <span className='font-light'>{paciente.nome}</span>
        </ModalHeader>
        <ModalBody>
          <MessagesComponent display={message.display} type={message.type} message={message.text} />
          <div className='margin-top-10'>
            <Row>
              <Col sm='12'>
                <p className='text-default text-md'>
                  A Consulta faltada irá para a{' '}
                  <strong>
                    Lista{' '}
                    <IfComponent conditional={t(schedule, 'meta.hasControleRetorno').safeBoolean}>
                      Controle/Retorno
                    </IfComponent>
                    <IfComponent conditional={!t(schedule, 'meta.hasControleRetorno').safeBoolean}>
                      Agendar/Reagendar
                    </IfComponent>
                  </strong>
                </p>
              </Col>
            </Row>
          </div>
          <div className='margin-top-10'>
            <Row>
              <Col sm='12'>
                <p>
                  <strong>Procedimento</strong>
                  <span
                    className='procedure-primary ml-2 text-light badge-proc'
                    style={{ backgroundColor: t(procedimento, 'categoria.rgb').safeString }}
                  >
                    {procedimento.codinome}
                  </span>
                </p>
              </Col>
            </Row>
          </div>
          <div className='margin-top-10'>
            <Row>
              <Col sm='12'>
                <form>
                  <label>Observações</label>
                  <textarea rows='1' cols='50' className='form-control' onChange={this.setComments} />
                </form>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col sm={{ size: 3, offset: 6 }}>
              <button className='button-primary' onClick={this.saveAppoingmentMissing}>
                Salvar
              </button>
            </Col>
            <Col sm='3'>
              <button className='button-tertiary' onClick={this.closeModal}>
                Cancelar
              </button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...appointmentMissingModalSelectorToProps(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  closeModal: () => dispatch(appointmentMissing({ modal: false })),
  closeDetailModal: () => dispatch(scheduleAppointmentDetailModal({ modal: false }))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(AppointmentMissingModal);
