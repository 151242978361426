import React, { Component } from 'react';

import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';

import { Formik } from 'formik';

import MaskedInput from 'react-text-mask';
import { maskTime } from '../../../utils/Formatter';

import { connect } from 'react-redux';
import { compose } from 'recompose';

import { showLoader } from '../../../components/actions/Loader.actions';

import { editClinicTimeSettings, sagaSaveClinicTimeSettings } from './redux/settings.actions';
import ErrorField from '../../../components/ErrorField.component';
import * as Yup from 'yup';
import { timeValidate } from '../../../utils/Validations';
import t from 'typy';
import ErrorFieldWithoutBootstrap from '../../../components/ErrorFieldWithoutBootstrap.component';

class SettingsClinicTimePage extends Component {
  state = {
    dias_atendimento: [],
    dias_atendimento_original: []
  };

  dayOfWeek = [
    {
      dia_semana: 1,
      diaSemanaNome: 'Segunda-Feira',
      diaSemanaApresentacao: 'Seg'
    },
    {
      dia_semana: 2,
      diaSemanaNome: 'Terça-Feira',
      diaSemanaApresentacao: 'Ter'
    },
    {
      dia_semana: 3,
      diaSemanaNome: 'Quarta-Feira',
      diaSemanaApresentacao: 'Qua'
    },
    {
      dia_semana: 4,
      diaSemanaNome: 'Quinta-Feira',
      diaSemanaApresentacao: 'Qui'
    },
    {
      dia_semana: 5,
      diaSemanaNome: 'Sexta-Feira',
      diaSemanaApresentacao: 'Sex'
    },
    {
      dia_semana: 6,
      diaSemanaNome: 'Sabado-Feira',
      diaSemanaApresentacao: 'Sab'
    },
    {
      dia_semana: 7,
      diaSemanaNome: 'Domingo-Feira',
      diaSemanaApresentacao: 'Dom'
    }
  ];

  validationSchema = Yup.object().shape({
    horario_inicio: Yup.string().required('Início Obrigatório'),
    horario_fim: Yup.string().required('Fim Obrigatório')
  });

  settingsTimeSave = (values, actions) => {
    let valid = true;
    if (!timeValidate(values.horario_inicio)) {
      actions.setFieldError('horario_inicio', 'Horário inválido');
      valid = false;
    }
    if (!timeValidate(values.horario_fim)) {
      actions.setFieldError('horario_fim', 'Horário inválido');
      valid = false;
    }

    if (parseInt(values.horario_inicio.replace(':', '')) > parseInt(values.horario_fim.replace(':', ''))) {
      actions.setFieldError('horario_inicio', 'Horário de inicio maior que final');
      actions.setFieldError('horario_fim', 'Horário inválido');
      valid = false;
    }

    if (
      !t(this.state.dias_atendimento).isDefined ||
      (t(this.state.dias_atendimento).isArray && this.state.dias_atendimento.length === 0)
    ) {
      actions.setFieldError('dias_semana', 'Selecione pelo menos 1 dia na semana');
      valid = false;
    }

    if (valid) {
      const dias = this.state.dias_atendimento;

      const saveData = {
        id: this.props.settingsTimes.id,
        ...values,
        dias_atendimento: dias
      };

      this.props.showLoader();
      this.props.save(saveData);
    }
  };

  isDayOfWeek = (day) => {
    if (this.state.dias_atendimento) {
      const dayFound = this.state.dias_atendimento.find((d) => d.dia_semana === day);
      if (dayFound) {
        return 'nav-week-item active';
      }
    }
    return 'nav-week-item';
  };

  selectedDay = (day) => {
    let diasAtendimento = this.state.dias_atendimento;

    let daySelected = diasAtendimento.find((d) => d.dia_semana === day);

    if (daySelected) {
      diasAtendimento = diasAtendimento.filter((d) => d.dia_semana !== day);
    } else {
      diasAtendimento.push(this.dayOfWeek.find((d) => d.dia_semana === day));
    }

    this.setState({
      dias_atendimento: diasAtendimento
    });
  };

  shouldComponentUpdate = (nextProps, nextState, nextContext) => {
    const { settingsTimes } = nextProps;
    const { horario_funcionamento = {} } = settingsTimes;

    if (
      horario_funcionamento.dias_atendimento &&
      horario_funcionamento.dias_atendimento !== this.state.dias_atendimento_original
    ) {
      const dias = horario_funcionamento.dias_atendimento.map((d) => {
        return this.dayOfWeek.find((dof) => dof.dia_semana === d.dia_semana);
      });
      this.setState({
        dias_atendimento: dias,
        dias_atendimento_original: horario_funcionamento.dias_atendimento
      });
    }
    return true;
  };

  render() {
    const { closeModal, settingsTimes = {} } = this.props;
    const { horario_funcionamento = {}, showModal = false } = settingsTimes;

    return (
      <Formik
        enableReinitialize={true}
        initialValues={horario_funcionamento}
        validationSchema={this.validationSchema}
        validateOnChange={false}
        onSubmit={this.settingsTimeSave}
      >
        {({ errors, handleSubmit, handleChange, values }) => {
          return (
            <Modal isOpen={showModal} toggle={closeModal} className={this.props.className}>
              <form onSubmit={handleSubmit}>
                <ModalHeader
                  className='title-primary'
                  toggle={closeModal}
                  close={
                    <button className='close' type={'button'} onClick={closeModal}>
                      &times;
                    </button>
                  }
                >
                  <strong>Editar Horário de Acesso ao Sistema</strong>
                </ModalHeader>
                <ModalBody>
                  <div className='margin-top-10'>
                    <Row>
                      <Col sm='12'>
                        <div className='text-sm font-bold text-secondary pb-2'>
                          Horário com permissão de Acesso ao Sistema, para Usuários com "Horário Restrito".
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm='6'>
                        <label>
                          Início <span>*</span>
                        </label>
                        <MaskedInput
                          id='horario_inicio'
                          name='horario_inicio'
                          mask={maskTime()}
                          defaultValue={values.horario_inicio ? values.horario_inicio.replace(':', '') : ''}
                          onChange={handleChange}
                          render={(ref, props) => (
                            <Input
                              className='input-secondary'
                              innerRef={ref}
                              invalid={errors.horario_inicio ? true : false}
                              {...props}
                            />
                          )}
                        />

                        <ErrorField errors={errors} fieldName={'horario_inicio'} />
                      </Col>
                      <Col sm='6'>
                        <label>
                          Final <span>*</span>
                        </label>
                        <MaskedInput
                          id='horario_fim'
                          name='horario_fim'
                          mask={maskTime()}
                          defaultValue={values.horario_fim ? values.horario_fim.replace(':', '') : ''}
                          onChange={(e) => handleChange(e)}
                          render={(ref, props) => (
                            <Input
                              className='input-secondary'
                              innerRef={ref}
                              invalid={errors.horario_fim ? true : false}
                              {...props}
                            />
                          )}
                        />
                        <ErrorField errors={errors} fieldName={'horario_fim'} />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm='12'>
                        <div style={{ padding: 10 }}>
                          <ErrorFieldWithoutBootstrap errors={errors} fieldName={'dias_semana'} />
                        </div>
                        <p>
                          <strong>Dias da Semana</strong>
                          <div className='nav-week margin-left-10'>
                            <ul>
                              {this.dayOfWeek.map((d) => {
                                let cname = this.state.dias_atendimento.find(
                                  (dstage) => dstage.dia_semana === d.dia_semana
                                )
                                  ? 'active'
                                  : '';
                                return (
                                  <li key={d.dia_semana}>
                                    <a
                                      onClick={() => this.selectedDay(d.dia_semana)}
                                      className={`nav-week-item ${cname}`}
                                      href='#'
                                    >
                                      {d.diaSemanaApresentacao}
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </p>
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Row>
                    <Col className='btn-list' sm={{ size: 6, offset: 6 }}>
                      <button className='button-primary' type={'submit'}>
                        Salvar
                      </button>
                      <button className='button-tertiary' type='button' onClick={closeModal}>
                        Cancelar
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
              </form>
            </Modal>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = ({ SettingsReducer = {} }) => {
  const { time = {} } = SettingsReducer;
  return {
    settingsTimes: {
      ...time.clinic,
      showModal: time.showModal
    }
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: () => dispatch(showLoader()),
    closeModal: () => dispatch(editClinicTimeSettings({ showModal: false })),
    save: (data) => dispatch(sagaSaveClinicTimeSettings(data))
  };
};

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(SettingsClinicTimePage);
