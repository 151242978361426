import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  Col,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledTooltip
} from 'reactstrap';
import { formatReal } from '../../utils/Formatter';
import arrowUp from '../../assets/img/icons/arrowup.svg';
import arrowDown from '../../assets/img/icons/arrow.svg';
import Icon from '../../components/Icon.component';
import { getUserData } from '../../utils/localStorage/SessionStorageUtils';
import t from 'typy';
import { Form, Formik } from 'formik';
import InputComponent from '../../components/InputComponent';
import * as Yup from 'yup';
import { changePassword } from '../../service/Login.service';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../components/actions/Loader.actions';
import ToastUtils from '../../utils/ToastUtils';
import { IoIosHelpCircle } from 'react-icons/io';

const MyAccountModal = (props, modalRef) => {
  const formSchemaValidator = Yup.object().shape({
    atual: Yup.string().required('Senha Atual Obrigatória'),
    new: Yup.string().required('Senha Atual Obrigatória'),
    confirm_new: Yup.string()
      .oneOf([Yup.ref('new'), null], 'Senhas não conferem')
      .required('Confirmar senha obrigatório')
  });
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [alterarSenhaShow, setAlterarSenhaShow] = useState(false);
  const [usuario, setUsuario] = useState({});

  useImperativeHandle(modalRef, () => ({
    showModal() {
      setShowModal(true);
    }
  }));

  useEffect(() => {
    const user = getUserData();
    setUsuario(user);
  }, []);

  function closeModal() {
    setShowModal(false);
  }

  function saveNewPassword(values) {
    dispatch(showLoader());
    changePassword({
      newPWD: values.new,
      current: values.atual
    })
      .then((_) => {
        dispatch(hideLoader());
        setShowModal(false);
        setUsuario({});
      })
      .catch((err) => {
        if (err.response.data.message === 'Senha atual inválida') {
          ToastUtils.error('Senha atual inválida');
          dispatch(hideLoader());
        }
      });
  }

  return (
    <Modal isOpen={showModal} toggle={closeModal} size={'small'}>
      <ModalHeader
        className='ml-4 mt-3'
        toggle={closeModal}
        close={
          <button className='close' onClick={closeModal}>
            &times;
          </button>
        }
      >
        <span className='text-secondary font-bold' style={{ fontSize: '21px' }}>
          Minha Conta
        </span>
      </ModalHeader>
      <Formik validationSchema={formSchemaValidator} onSubmit={saveNewPassword} validateOnChange={false}>
        {({ values, errors, handleChange }) => {
          return (
            <Form>
              <ModalBody>
                <div>
                  <Row>
                    <Col sm={{ size: 12 }} className='ml-4'>
                      <h2 className='text-md font-light text-secondary-l1'>Nome</h2>
                      <p className='font-bold text-secondary' style={{ fontSize: '16px' }}>
                        {t(usuario, 'usuarioCadastro').safeString}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={{ size: 12 }} className='ml-4 mt-2'>
                      <h2 className='text-md font-light text-secondary-l1'>
                        Nome de Usuário
                        <span className={'pl-2'}>
                          <IoIosHelpCircle
                            size={16}
                            color='#616e88'
                            style={{ cursor: 'help' }}
                            alt='help'
                            id='questionDesc'
                            data-tip
                            data-for={'questionDesc'}
                          />
                        </span>
                        <UncontrolledTooltip className={'tooltip-white'} placement='top' target='questionDesc'>
                          Forma reduzida (até 19 caracteres) como o nome do usuário aparecerá no sistema. Ex. “Dr. Paulo
                          Lopes”
                        </UncontrolledTooltip>
                      </h2>
                      <p className='font-bold text-secondary' style={{ fontSize: '16px' }}>
                        {t(usuario, 'usuarioCadastro').safeString}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={{ size: 12 }} className='ml-4 mt-2'>
                      <h2 className='text-md font-light text-secondary-l1'>Email</h2>
                      <p className='font-bold text-secondary' style={{ fontSize: '16px' }}>
                        {t(usuario, 'login').safeString}
                      </p>
                    </Col>
                  </Row>
                  <h2 className='title-medium margin-top-20 ml-4 text-secondary' style={{ fontSize: '16px' }}>
                    ALTERAR SENHA
                    <a href={'javascript:'} onClick={() => setAlterarSenhaShow(!alterarSenhaShow)}>
                      <Icon
                        className='icon icon-size-small margin-left-10'
                        image={alterarSenhaShow ? arrowUp : arrowDown}
                        alt='arrow'
                      />
                    </a>
                  </h2>
                  <Collapse isOpen={alterarSenhaShow}>
                    <Row>
                      <Col sm={{ size: 12 }} className='ml-4 mt-2'>
                        <h2 className='text-md font-light text-secondary-l1'>Senha Atual</h2>
                        <p className='text-md font-bold text-secondary pr-4'>
                          <InputComponent
                            className='input-border-bottom'
                            type={'password'}
                            placeholder={'••••••••'}
                            name={'atual'}
                            errors={errors}
                            handler={handleChange}
                          />
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={{ size: 12 }} className='ml-4 mt-2'>
                        <h2 className='text-md font-light text-secondary-l1'>Nova Senha</h2>
                        <p className='text-md font-bold text-secondary pr-4'>
                          <InputComponent
                            className='input-border-bottom'
                            type={'password'}
                            placeholder={'••••••••'}
                            name={'new'}
                            errors={errors}
                            handler={handleChange}
                          />
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={{ size: 12 }} className='ml-4 mt-2'>
                        <h2 className='text-md font-light text-secondary-l1'>Confirmar Nova Senha</h2>
                        <p className='text-md font-bold text-secondary pr-4'>
                          <InputComponent
                            className='input-border-bottom'
                            type={'password'}
                            placeholder={'••••••••'}
                            name={'confirm_new'}
                            errors={errors}
                            handler={handleChange}
                          />
                        </p>
                      </Col>
                    </Row>
                  </Collapse>
                </div>
              </ModalBody>
              <ModalFooter>
                <Row>
                  <Col className='btn-list' sm={{ size: 6, offset: 6 }}>
                    <button className='button-primary' type={'submit'}>
                      Salvar
                    </button>
                    <button className='button-tertiary' onClick={closeModal}>
                      Cancelar
                    </button>
                  </Col>
                </Row>
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default forwardRef(MyAccountModal);
