import { all, call, put } from 'redux-saga/effects';
import { hideLoader, showLoader } from '../../../../components/actions/Loader.actions';
import TreatmentFinancialFileApi from '../../../../service/TreatmentFinancialFileApi';
import {
  financialClosedPaymentModal,
  financialDebitsPage,
  financialEntriesModal,
  financialFileAccountModal,
  financialFileCreateModal,
  financialMonthlyPaymentModal
} from '../redux/FinancialFile.action';
import DomainsAPI from '../../../../service/DomainsAPI';
import CashAPI from '../../../../service/CashAPI';
import { t } from 'typy';
import ToastUtils from '../../../../utils/ToastUtils';

export function* findTreatmentFinancialFilePage({ payload: { treatment } }) {
  yield put(showLoader());
  try {
    const financialFileData = yield call(TreatmentFinancialFileApi.findByTreatment, { treatment: treatment.id });
    const financialFile = financialFileData.data;

    yield put(financialFileCreateModal({ treatment, financialFile: financialFile }));
    yield put(financialClosedPaymentModal({ treatment, financialFile: financialFile }));
    yield put(financialMonthlyPaymentModal({ treatment, financialFile: financialFile }));
    yield put(
      financialDebitsPage({
        treatment,
        financialFile: financialFile,
        expandDebits: !financialFile.negociacao_finalizada || financialFile.negociacao_finalizada === 'N'
      })
    );

    if (t(financialFile, 'id').isDefined) {
      const financialEntriesData = yield call(CashAPI.findEntriesByFinancialFile, { financialFile: financialFile.id });
      yield put(financialEntriesModal({ treatment, financialFile: financialFile, entries: financialEntriesData.data }));
    }
  } catch (e) {
    console.log(e);
    ToastUtils.error('Erro ao buscar ficha financeira');
  }

  yield put(hideLoader());
}

export function* treatmentFinancialAccountInfoModal({ payload: { treatment, financialFile, modal } }) {
  try {
    yield put(showLoader());

    const domains = yield all([
      call(DomainsAPI.findResponsibleTypes),
      call(DomainsAPI.findAllTreatmentTypes),
      call(DomainsAPI.findAllVoucherType)
    ]);

    const formLoad = {
      responsibleTypes: domains[0].data,
      treatmentTypes: domains[1],
      voucherTypes: domains[2]
    };

    yield put(financialFileAccountModal({ treatment, financialFile, modal, formLoad }));

    yield put(hideLoader());
  } catch (e) {
    console.log(e);
    ToastUtils.error('Erro ao buscar ficha financeira');
  }
}
