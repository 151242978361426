import React, { Component } from 'react';

import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown
} from 'reactstrap';
import plus from '../../../assets/img/icons/plus.svg';
import Icon from '../../../components/Icon.component';
import menuDots from '../../../assets/img/menuDots.png';
import SettingsProcedureClosedPriceModal from './modals/SettingsProcedureClosedPrice.modal';
import SettingsProcedurePriceMaintenanceModal from './modals/SettingsProcedurePriceMaintenance.modal';
import ProceduresAPI from '../../../service/ProceduresAPI';
import { formatReal } from '../../../utils/Formatter';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { procedureTablePrice, procedureTablePriceMonthly } from './redux/SettingsProcedureTablePrice.action';
import warning from '../../../assets/img/icons/warning.svg';
import ModalMessageConfirm from '../../../components/ModalMessageConfirm.component';
import MessagesComponent from '../../../components/Messages.component';
import { PERMISSOES } from '../../../config/security/permissoes';
import withSecurity from '../../../config/security/security';
import { IfComponent } from '../../../components/if.component';
import SettingsAPI from '../../../service/SettingsAPI';
import ClinicAPI from '../../../service/ClinicAPI';

class SettingsProcedurePriceListHomePage extends Component {
  state = {
    closedPriceCategorySelected: {},
    closedPrice: {
      categories: [],
      procedures: []
    },
    monthly: [],
    errorMessage: {
      display: false,
      type: 'danger',
      message: ''
    },
    displayClosedConfirmModal: false,
    procedureClosedPriceSelected: {},

    displayMonthlyConfirmModal: false,
    monthlySelected: {}
  };

  componentDidMount = () => {
    this.findAllCategories();
    this.findProceduresMonthly();
  };

  findAllCategories = () => {
    const { showLoader, hideLoader } = this.props;
    showLoader();

    ProceduresAPI.getCategoriesOfClosedPrice()
      .then(({ data }) => {
        this.setState({
          closedPrice: {
            ...this.state.closedPrice,
            categories: data
          }
        });
        hideLoader();
        this.findProcedure(data[0]);
      })
      .catch(({ data }) => {
        console.error(data);
        this.setState({
          errorMessage: {
            display: true,
            type: 'danger',
            message: 'Erro ao buscar categorias'
          }
        });
        hideLoader();
      });
  };

  findProcedure = (c) => {
    const { showLoader, hideLoader } = this.props;

    showLoader();

    this.setState({ closedPriceCategorySelected: c });
    ProceduresAPI.getProcedureOfCategory(c.id)
      .then(({ data }) => {
        this.setState({
          closedPrice: {
            ...this.state.closedPrice,
            procedures: data
          }
        });
        hideLoader();
      })
      .catch(({ data }) => {
        console.error(data);
        this.setState({
          errorMessage: {
            display: true,
            type: 'danger',
            message: 'Erro ao buscar Procedimentos'
          }
        });
        hideLoader();
      });
  };

  findProceduresMonthly = () => {
    const { showLoader, hideLoader } = this.props;

    showLoader();

    ProceduresAPI.getProcedureMonthly()
      .then(({ data }) => {
        this.setState({
          monthly: data
        });
        hideLoader();
      })
      .catch(({ data }) => {
        console.error(data);
        this.setState({
          errorMessage: {
            display: true,
            type: 'danger',
            message: 'Erro ao buscar Procedimentos'
          }
        });
        hideLoader();
      });
  };

  updateStatusProcedure = async (p) => {
    const { showLoader, hideLoader } = this.props;

    showLoader();

    ProceduresAPI.updateStatusProcedure(p.id, p.status === 'ATIVO' ? 'I' : 'A')
      .then(({ data }) => {
        this.findProcedure(this.state.closedPriceCategorySelected);
        hideLoader();
      })
      .catch(({ data }) => {
        console.error(data);
        this.setState({
          errorMessage: {
            display: true,
            type: 'danger',
            message: 'Erro ao buscar Procedimentos'
          }
        });
        hideLoader();
      });
  };

  confirmDeleteProcedureClosedPrice = (p) => {
    this.setState({ displayClosedConfirmModal: true, procedureClosedPriceSelected: p });
  };

  closeModalConfirmClosedProcedure = () => this.setState({ displayClosedConfirmModal: false });

  confirmDeleteClosedPriceProcedure = () => {
    const { showLoader, hideLoader } = this.props;

    showLoader();
    ProceduresAPI.deleteProcedureClosedPrice(this.state.procedureClosedPriceSelected.id)
      .then(({ data }) => {
        hideLoader();
        this.closeModalConfirmClosedProcedure();
        this.findProcedure(this.state.closedPriceCategorySelected);
      })
      .catch(({ data }) => {
        console.error(data);
        this.setState({
          errorMessage: {
            display: true,
            type: 'danger',
            message: 'Erro ao buscar Procedimentos'
          }
        });
        hideLoader();
      });
  };

  closeModalConfirmMonthly = () => this.setState({ displayMonthlyConfirmModal: false });

  deleteConfirmMonthly = (t) => this.setState({ displayMonthlyConfirmModal: true, monthlySelected: t });

  deleteMonthly = () => {
    const { showLoader, hideLoader } = this.props;

    showLoader();

    ProceduresAPI.deleteMonthlyDeviceType(this.state.monthlySelected.id)
      .then(() => {
        hideLoader();
        this.closeModalConfirmMonthly();
        this.findProceduresMonthly();
        hideLoader();
      })
      .catch(({ data }) => {
        console.error(data);
        this.closeModalConfirmMonthly();
        this.setState({
          errorMessage: {
            display: true,
            type: 'danger',
            message: 'Erro ao deletar Mensalidade / Manutenção'
          }
        });
        hideLoader();
      });
  };

  _disabledUpdateStatus = (p) => {
    const { security } = this.props;
    const settingsPerm = PERMISSOES.modules.configuracoes;
    if (security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar)) {
      return false;
    } else if (
      security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar_restricao.id) &&
      security.hasRestriction(
        settingsPerm.id,
        settingsPerm.permissoes.editar_restricao.id,
        settingsPerm.permissoes.editar_restricao.restricoes.editar_tabela_preco
      )
    ) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    const { showLoader, hideLoader, procedureTablePriceMonthly, security } = this.props;
    const {
      closedPrice: { categories, procedures },
      closedPriceCategorySelected,
      monthly,
      displayClosedConfirmModal,
      errorMessage,
      displayMonthlyConfirmModal,
      procedureClosedPriceSelected,
      monthlySelected
    } = this.state;

    const settingsPerm = PERMISSOES.modules.configuracoes;

    return [
      <Container key={'container'}>
        <MessagesComponent display={errorMessage.display} type={errorMessage.type} message={errorMessage.message} />
        <div className='box-default w-100'>
          <Row style={{ paddingLeft: 5 }}>
            <Col sm={12}>
              <h2 className='text-secondary-d4 title-medium-plus text-uppercase'>ORTODONTIA - PREÇO FECHADO</h2>
            </Col>
          </Row>
          <Row className='margin-top-10'>
            <Col sm='4'>
              <Row>
                <Col sm='12'>
                  <div className='content-overflow-default' style={{ maxHeight: 600 }}>
                    <h2 className='text-secondary-d4 title-medium text-uppercase'>Categorias</h2>
                    <Table className='table-header-fixed table-header-medium table-body-large' responsive>
                      <thead>
                        <tr>
                          <th width='100%'>Descrição</th>
                        </tr>
                      </thead>
                      <tbody style={{ maxHeight: 380 }}>
                        {categories.map((c) => {
                          const style = {
                            cursor: 'pointer',
                            background: closedPriceCategorySelected.id === c.id ? 'var(--primary-l5)' : ''
                          };

                          return (
                            <tr className='row pb-0' key={c.id}>
                              <td className='col-sm-12' onClick={() => this.findProcedure(c)} style={style}>
                                {c.nome}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col sm='8'>
              <Row>
                <Col sm='12'>
                  <h2 className='text-secondary-d4 title-medium text-uppercase' style={{ height: 18 }}>
                    Procedimentos
                    <IfComponent
                      conditional={
                        security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar) ||
                        (security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar_restricao.id) &&
                          security.hasRestriction(
                            settingsPerm.id,
                            settingsPerm.permissoes.editar_restricao.id,
                            settingsPerm.permissoes.editar_restricao.restricoes.editar_tabela_preco
                          ))
                      }
                    >
                      <a
                        href={'#'}
                        onClick={() =>
                          this.props.procedureTablePrice({
                            modal: true,
                            formData: { category: closedPriceCategorySelected }
                          })
                        }
                      >
                        <Icon className='icon icon-size-small margin-left-10' image={plus} alt='plus' />
                      </a>
                    </IfComponent>
                  </h2>
                  <div className='content-overflow-default' style={{ maxHeight: 600 }}>
                    <Table className='table-header-fixed table-header-medium table-body-large' responsive>
                      <thead>
                        <tr className='row pb-0'>
                          <th className='col-sm-6'>Descrição</th>
                          <th className='col-sm-3'>Valor - R$</th>
                          <th className='col-sm-2'>On/Off</th>
                          <th className='col-sm-1'></th>
                        </tr>
                      </thead>
                      <tbody style={{ maxHeight: 380 }}>
                        {procedures.map((p) => {
                          return (
                            <tr className='row pb-0' key={p.id}>
                              <td className='col-sm-6'>{p.nome}</td>
                              <td className='col-sm-3'>{formatReal(p.valorProcedimento)}</td>
                              <td className='col-sm-2'>
                                <Col sm='12'>
                                  <input
                                    type='checkbox'
                                    onClick={() => this.updateStatusProcedure(p)}
                                    disabled={this._disabledUpdateStatus(p)}
                                    className='form-checkbox'
                                    id='check-one'
                                    style={{ marginTop: -4 }}
                                    checked={p.status === 'ATIVO'}
                                  />
                                </Col>
                              </td>
                              <td className='col-sm-1 text-right pr-4'>
                                <IfComponent
                                  conditional={
                                    security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar) ||
                                    (security.hasPermission(
                                      settingsPerm.id,
                                      settingsPerm.permissoes.editar_restricao.id
                                    ) &&
                                      security.hasRestriction(
                                        settingsPerm.id,
                                        settingsPerm.permissoes.editar_restricao.id,
                                        settingsPerm.permissoes.editar_restricao.restricoes.editar_tabela_preco
                                      ))
                                  }
                                >
                                  <UncontrolledDropdown direction='left'>
                                    <DropdownToggle style={{ marginTop: -9 }}>
                                      <img src={menuDots} style={{ width: 20 }} />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem
                                        onClick={() => {
                                          this.props.procedureTablePrice({
                                            modal: true,
                                            formData: {
                                              category: this.state.closedPriceCategorySelected,
                                              procedure: p.nome,
                                              valor: p.valorProcedimento,
                                              id: p.id
                                            }
                                          });
                                        }}
                                      >
                                        Editar
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() => {
                                          this.confirmDeleteProcedureClosedPrice(p);
                                        }}
                                      >
                                        Excluir
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </IfComponent>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <div className='box-default w-100 mt-3'>
          <Row style={{ paddingLeft: 5 }}>
            <Col sm={12}>
              <h2 className='text-secondary-d4 title-medium-plus text-uppercase'>
                ORTODONTIA - MENSALIDADES / MANUTENÇÃO
                <IfComponent
                  conditional={
                    security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar) ||
                    (security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar_restricao.id) &&
                      security.hasRestriction(
                        settingsPerm.id,
                        settingsPerm.permissoes.editar_restricao.id,
                        settingsPerm.permissoes.editar_restricao.restricoes.editar_tabela_preco
                      ))
                  }
                >
                  <a href='#' onClick={() => procedureTablePriceMonthly({ modal: true })}>
                    <Icon className='icon icon-size-small margin-left-10' image={plus} alt='plus' />
                  </a>
                </IfComponent>
              </h2>
            </Col>
          </Row>
          <Row className='margin-top-10'>
            <Col sm='12'>
              <div className='content-overflow-default'>
                <Table className='table-header-fixed table-header-medium' responsive>
                  <thead>
                    <tr className='row pb-0'>
                      <th className='col-sm-4'>Tipo de Aparelho</th>
                      <th className='col-sm-3'>Entrada/Aparelho</th>
                      <th className='col-sm-3'>Mensalidade/Manutenção</th>
                      <th className='col-sm-2'></th>
                    </tr>
                  </thead>
                  <tbody style={{ height: 340 }}>
                    {monthly.map((m) => {
                      return (
                        <tr className='row pb-0' key={m.id}>
                          <td className='col-sm-4'>{m.aparelho}</td>
                          <td className='col-sm-3'>{formatReal(m.valorEntrada)}</td>
                          <td className='col-sm-3'>{formatReal(m.valorMensalidade)}</td>
                          <td className='col-sm-2 text-right pr-4' style={{ paddingTop: 8, paddingBottom: 2 }}>
                            <IfComponent
                              conditional={
                                security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar) ||
                                (security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar_restricao.id) &&
                                  security.hasRestriction(
                                    settingsPerm.id,
                                    settingsPerm.permissoes.editar_restricao.id,
                                    settingsPerm.permissoes.editar_restricao.restricoes.editar_tabela_preco
                                  ))
                              }
                            >
                              <UncontrolledDropdown direction='left'>
                                <DropdownToggle>
                                  <img src={menuDots} style={{ width: 20 }} />
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem
                                    onClick={() => {
                                      procedureTablePriceMonthly({
                                        modal: true,
                                        formData: {
                                          id: m.id,
                                          tipoAparelho: m.aparelho,
                                          valorEntrada: m.valorEntrada,
                                          valorMensalidade: m.valorMensalidade
                                        }
                                      });
                                    }}
                                  >
                                    Editar
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {
                                      this.deleteConfirmMonthly(m);
                                    }}
                                  >
                                    Excluir
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </IfComponent>{' '}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>
      </Container>,
      <SettingsProcedureClosedPriceModal
        key={'SettingsProcedureClosedPriceModal'}
        refreshPage={this.findProcedure}
        showLoader={showLoader}
        hideLoader={hideLoader}
      />,
      <SettingsProcedurePriceMaintenanceModal
        key={'SettingsProcedurePriceMaintenanceModal'}
        showLoader={showLoader}
        hideLoader={hideLoader}
        refreshPage={this.findProceduresMonthly}
      />,

      <ModalMessageConfirm
        key={'ModalMessageConfirm'}
        title={<strong>Confirma Exclusão?</strong>}
        message={
          <span>
            <p>
              Confirma a Exclusão do <strong>{procedureClosedPriceSelected.nome}</strong>?
            </p>
          </span>
        }
        openModal={displayClosedConfirmModal}
        icon={warning}
        close={this.closeModalConfirmClosedProcedure}
        footer={
          <Row>
            <Col sm='3'>
              <button className='button-tertiary' onClick={this.closeModalConfirmClosedProcedure}>
                Não
              </button>
            </Col>
            <Col sm={{ size: 3, offset: 6 }}>
              <button className='button-primary' onClick={this.confirmDeleteClosedPriceProcedure}>
                Sim
              </button>
            </Col>
          </Row>
        }
      />,
      <ModalMessageConfirm
        key={'modalConfirm'}
        title={<strong>Confirma Exclusão?</strong>}
        message={
          <span>
            <p>
              Confirma a Exclusão de <strong>{monthlySelected.aparelho}</strong> ?
            </p>
          </span>
        }
        openModal={displayMonthlyConfirmModal}
        icon={warning}
        close={this.closeModalConfirmMonthly}
        footer={
          <Row>
            <Col sm='3'>
              <button className='button-tertiary' onClick={this.closeModalConfirmMonthly}>
                Não
              </button>
            </Col>
            <Col sm={{ size: 3, offset: 6 }}>
              <button className='button-primary' onClick={this.deleteMonthly}>
                Sim
              </button>
            </Col>
          </Row>
        }
      />
    ];
  }
}

const mapStateToProps = (state) => {};

const mapDispathToProps = (dispatch) => {
  return {
    procedureTablePrice: (data) => dispatch(procedureTablePrice(data)),
    procedureTablePriceMonthly: (data) => dispatch(procedureTablePriceMonthly(data))
  };
};

const enhanced = compose(connect(mapStateToProps, mapDispathToProps), withSecurity);

export default enhanced(SettingsProcedurePriceListHomePage);
