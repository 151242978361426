import { all, call, put } from 'redux-saga/effects';
import { scheduleAppointmentPatientModal } from '../redux/Schedule.actions';
import { hideLoader, showLoader } from '../../../components/actions/Loader.actions';
import PacienteAPI from '../../../service/PacienteAPI';
import ScheduleAPI from '../../../service/ScheduleAPI';
import ProceduresAPI from '../../../service/ProceduresAPI';
import { t } from 'typy';

export function* scheduleAppointPatientSagaProcess({
  payload: {
    modal,
    dentist,
    chair,
    date,
    start,
    end,
    patient,
    treatment,
    schedule,
    redialBy,
    onSave,
    procedure,
    controleRetorno
  }
}) {
  try {
    yield put(showLoader());

    const domains = yield all([
      call(PacienteAPI.findById, patient.id),
      call(ScheduleAPI.findNextSchedule, { paciente: patient.id }),
      call(ScheduleAPI.config, dentist.id),
      call(ProceduresAPI.getAllCategories)
    ]);

    const scheduleConfig = domains[2].data;
    let procedures = [];

    if (t(schedule, 'procedimento.categoria.id').isDefined) {
      procedures = yield call(ProceduresAPI.getAllProcedures, schedule.procedimento.categoria.id, true);
    } else if (procedure) {
      procedures = yield call(ProceduresAPI.getAllProcedures, procedure.categoria.id, true);
    } else if (t(scheduleConfig, 'procedimento_categoria_id').isDefined) {
      procedures = yield call(ProceduresAPI.getAllProcedures, scheduleConfig.procedimento_categoria_id, true);
    }

    yield put(
      scheduleAppointmentPatientModal({
        modal,
        dentist,
        chair,
        date,
        start,
        end,
        patient: domains[0],
        treatment,
        nextSchedule: domains[1].data,
        scheduleConfig,
        categories: domains[3].data,
        procedures: procedures.data,
        schedule,
        redialBy,
        onSave,
        procedure,
        controleRetorno
      })
    );

    yield put(hideLoader());
  } catch (e) {
    console.log(e);
    yield put(hideLoader());
  }
}
