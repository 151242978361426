import React, { Component } from 'react';
import { Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { maskCNPJ, maskCPF } from '../../../utils/Formatter';
import SelectComponent from '../../../components/SelectComponent';
import question from '../../../assets/img/icons/question.svg';
import Icon from '../../../components/Icon.component';
import ReactTooltip from 'react-tooltip';
import InputComponent from '../../../components/InputComponent';
import edit from '../../../assets/img/icons/edit.svg';
import { IfComponent } from '../../../components/if.component';
import { t } from 'typy';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  settingsEmissionReferenceModal,
  settingsEmissionReferenceModalStateToProps
} from './selectors/SettingsEmissionReferencesModalSelector';
import { Form, Formik } from 'formik';
import { openSettingsEmissionsReferences } from './store/settings_emission_references_store';
import * as Yup from 'yup';
import SettingsAPI from '../../../service/SettingsAPI';
import { hideLoader, showLoader } from '../../../components/actions/Loader.actions';
import ToastUtils from '../../../utils/ToastUtils';
import moment from 'moment';
import UserAPI from '../../../service/User.service';

class SettingsEmissionReferencesModal extends Component {
  state = {
    voucher_type: null,
    image: ''
  };

  validationSchema = Yup.object().shape({
    voucher_type: Yup.object().shape({
      value: Yup.string().required('Tipo de Comprovante Obrigatório')
    })
  });

  _savePreferencesVoucher = async (values, actions) => {
    let valid = true;

    if (values.voucher_type.value === 'R') {
      if (!t(values, 'emission_type.value').isDefined) {
        actions.setFieldError('emission_type.value', 'Preferência de Emissão Obrigatório');
        valid = false;
      }

      if (!t(values, 'dentist.value').isDefined) {
        actions.setFieldError('dentist.value', 'Selecione um dentista');
        valid = false;
      }

      if (t(values, 'cpf').isEmptyString) {
        actions.setFieldError('cpf', 'CPF Obrigatório');
        valid = false;
      }

      if (t(values, 'cro').isEmptyString) {
        actions.setFieldError('cro', 'CRO Obrigatório');
        valid = false;
      }

      if (!t(values, 'cro_uf').isDefined) {
        actions.setFieldError('cro_uf.value', 'UF Obrigatório');
        valid = false;
      }
    } else {
      if (t(values, 'razao_social').isEmptyString) {
        actions.setFieldError('razao_social', 'Razão Social Obrigatório');
        valid = false;
      }

      if (t(values, 'cnpj').isEmptyString) {
        actions.setFieldError('cnpj', 'CNPJ Obrigatório');
        valid = false;
      }
    }

    if (valid) {
      try {
        this.props.showLoader();

        if (values.signatureFile) {
          const imageUpload = await SettingsAPI.uploadImageAssign(values.signatureFile, 'jpg');
          values.signature = imageUpload.data.path_signature;
        }
        await SettingsAPI.savePreferencesVoucherEmission(values.voucher_type.value, values);
        this.props.updateParent();
        this.props.closeModal();
        this.props.hideLoader();
      } catch (e) {
        console.log(e);
        ToastUtils.error('Erro ao salvar preferências, entre em contato com o suporte');
        this.props.hideLoader();
      }
    }
  };

  _uploadFile = (event, handleChange) => {
    const file = event.target.files[0];
    // Convert image to Base 64
    const reader = new FileReader();
    reader.onload = (event) => {
      console.log(event.target.result);
      handleChange({
        target: {
          name: 'signature',
          value: event.target.result
        }
      });
      handleChange({
        target: {
          name: 'signatureFile',
          value: event.target.result
        }
      });
    };
    reader.readAsDataURL(file);
  };

  _selectDentist = async (dentist, handleChange) => {
    console.log(dentist.data);
    // const dentistData = await UserAPI.findById(dentist.data.id);
    // const data = dentistData.data;
    const data = dentist.data;

    handleChange({
      target: {
        name: 'cpf',
        value: data.cpf || ''
      }
    });

    handleChange({
      target: {
        name: 'cro',
        value: data.cro || ''
      }
    });

    handleChange({
      target: {
        name: 'cro_uf',
        value: { label: data.cro_uf, value: data.cro_uf }
      }
    });

    handleChange({
      target: {
        name: 'signature',
        value: ''
      }
    });
  };

  render() {
    const {
      showModal,
      closeModal,
      dentistsOptions,
      formData,
      voucher_type_options,
      emission_type_options,
      states_options
    } = this.props;

    return (
      <Modal isOpen={showModal} toggle={closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={closeModal}
          close={
            <button className='close' type={'button'} onClick={closeModal}>
              &times;
            </button>
          }
        >
          <strong>Preferências para Recibos e Notas Fiscais</strong>
        </ModalHeader>
        <Formik
          validateOnChange={false}
          validationSchema={this.validationSchema}
          initialValues={formData}
          onSubmit={this._savePreferencesVoucher}
        >
          {({ values, handleChange, errors }) => {
            return (
              <Form>
                <ModalBody>
                  <div className='margin-top-10'>
                    <Row>
                      <Col sm={12}>
                        <Label>
                          Tipo de Comprovante <span>*</span>
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <SelectComponent
                          name={'voucher_type'}
                          values={values}
                          options={voucher_type_options}
                          handleChange={handleChange}
                          errors={errors}
                          callbackOnChange={(voucher_type) => {
                            handleChange({
                              target: {
                                name: 'emission_type',
                                value: {}
                              }
                            });
                          }}
                        />
                      </Col>
                    </Row>

                    <Row className='margin-top-10'>
                      <Col sm={12}>
                        <Label>
                          Preferência de Emissão{' '}
                          <IfComponent conditional={t(values.voucher_type, 'value').safeString !== 'NF'}>
                            <span>*</span>
                          </IfComponent>
                          <Icon
                            className='icon icon-size-smaller ml-2'
                            image={question}
                            data-tip
                            data-for={'preferencia_emissao'}
                            data-type={'light'}
                          />
                          <ReactTooltip id={'preferencia_emissao'}>
                            <div style={{ width: 400 }}>
                              <Row>
                                <Col>
                                  <span className={'text-dark font-weigth-bold'}>FIXA</span>{' '}
                                  <span className={'font-weight-normal text-dark'}>
                                    - Todos os Recibos ou Notas Fiscais serão emitidos no nome do "Emitente Padrão".
                                    Abaixo descriminado
                                  </span>
                                </Col>
                              </Row>
                              <IfComponent conditional={t(values.voucher_type, 'value').safeString === 'R'}>
                                <br />
                                <Row>
                                  <Col>
                                    <span className={'text-dark font-weigth-bold'}>VARIÁVEL</span>{' '}
                                    <span className={'font-weight-normal text-dark'}>
                                      - Recibos / NF serão emitidos em nome do "Emitente Padrão", mas o emitente poderá
                                      ser alterado no momento da emissão
                                    </span>
                                  </Col>
                                </Row>
                              </IfComponent>
                            </div>
                          </ReactTooltip>
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <IfComponent conditional={t(values.voucher_type, 'value').safeString !== 'NF'}>
                          <SelectComponent
                            name={'emission_type'}
                            options={
                              t(values.voucher_type, 'value').safeString === 'NF'
                                ? emission_type_options.filter((e) => e.value === 'F')
                                : emission_type_options
                            }
                            handleChange={handleChange}
                            values={values}
                            errors={errors}
                          />
                        </IfComponent>
                        <IfComponent conditional={t(values.voucher_type, 'value').safeString === 'NF'}>
                          <div
                            className={'p-2'}
                            style={{
                              backgroundColor: '#f3f4f6',
                              borderSize: 1,
                              borderType: 'solid',
                              borderColor: '#cccccc',
                              borderRadius: 3
                            }}
                          >
                            Fixa
                          </div>
                        </IfComponent>
                      </Col>
                    </Row>
                    <IfComponent conditional={values.voucher_type}>
                      <Row className='margin-top-10'>
                        <Col sm={12}>
                          <div className='text-sm font-bold text-secondary pb-2'>DADOS DO EMITENTE PADRÃO</div>
                        </Col>
                      </Row>
                    </IfComponent>
                    <IfComponent conditional={t(values.voucher_type, 'value').safeString === 'NF'}>
                      <Row>
                        <Col sm={12}>
                          <Label>
                            Razão Social <span>*</span>
                          </Label>
                          <InputComponent
                            type={'text'}
                            name={'razao_social'}
                            handler={handleChange}
                            values={values}
                            errors={errors}
                          />
                        </Col>
                      </Row>

                      <Row className='margin-top-10'>
                        <Col sm={12}>
                          <Label>
                            CNPJ <span>*</span>
                          </Label>
                          <InputComponent
                            type={'text'}
                            name={'cnpj'}
                            handler={handleChange}
                            values={values}
                            errors={errors}
                            mask={maskCNPJ()}
                          />
                        </Col>
                      </Row>
                    </IfComponent>

                    <IfComponent conditional={t(values.voucher_type, 'value').safeString === 'R'}>
                      <Row>
                        <Col sm={12}>
                          <Label>
                            Nome <span>*</span>
                          </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <SelectComponent
                            name={'dentist'}
                            options={dentistsOptions}
                            handleChange={handleChange}
                            values={values}
                            errors={errors}
                            callbackOnChange={(value) => this._selectDentist(value, handleChange)}
                          />
                        </Col>
                      </Row>
                      <Row className='margin-top-10'>
                        <Col sm={5}>
                          <Label>
                            CPF <span>*</span>
                          </Label>
                        </Col>
                        <Col sm={4}>
                          <Label>
                            CRO <span>*</span>
                          </Label>
                        </Col>
                        <Col sm={3}>
                          <Label>
                            UF <span>*</span>
                          </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={5}>
                          <InputComponent
                            type={'text'}
                            name={'cpf'}
                            handler={handleChange}
                            values={values}
                            errors={errors}
                            mask={maskCPF()}
                          />
                        </Col>
                        <Col sm={4}>
                          <InputComponent
                            type={'text'}
                            name={'cro'}
                            handler={handleChange}
                            values={values}
                            errors={errors}
                          />
                        </Col>
                        <Col sm={3}>
                          <SelectComponent
                            name={'cro_uf'}
                            options={states_options}
                            handleChange={handleChange}
                            values={values}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                      <Row className='margin-top-10'>
                        <Col sm={12}>
                          <Label>Assinatura</Label>
                        </Col>
                      </Row>
                      <Row className={'border p-0'} style={{ borderRadius: 5 }}>
                        <Col className={'p-0'}>
                          <Row>
                            <Col className={'text-right p-1'}>
                              <a
                                href='#'
                                onClick={() => {
                                  this.upload.click();
                                }}
                              >
                                <Icon className='icon icon-size-small ml-2' image={edit} />
                                <input
                                  type='file'
                                  id='file'
                                  ref={(ref) => (this.upload = ref)}
                                  style={{ display: 'none' }}
                                  onChange={(event) => this._uploadFile(event, handleChange)}
                                />
                              </a>
                            </Col>
                          </Row>
                          <Row>
                            <Col className={'text-right mb-4'}>
                              <img src={values.signature} style={{ maxHeight: 70 }} />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </IfComponent>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Row>
                    <Col className='btn-list' sm={{ size: 6, offset: 6 }}>
                      <button className='button-primary' type={'submit'}>
                        Salvar
                      </button>
                      <button className='button-tertiary' type='button' onClick={closeModal}>
                        Cancelar
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return settingsEmissionReferenceModalStateToProps(state);
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(openSettingsEmissionsReferences({ formLoad: { showModal: false } })),
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader())
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(SettingsEmissionReferencesModal);
