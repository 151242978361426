const errorResponseInterceptor = (axios) => {
  axios.interceptors.response.use(
    (response) => {
      if (typeof response !== 'undefined') {
        const msg = response.data.message || '';
        if (response.status === 403 || msg.includes('JWT expired')) {
          const redirectTo = window.location.pathname;
          window.location.href = `/login?message=Sessão Expirada!&redirect=${redirectTo}`;
          console.log('Token Expire', response);
          return Promise.reject(response);
        } else if (response.status > 299) {
          console.log('API Promise Reject', response);
          return Promise.reject(response);
        }
      } else {
        console.log('API Promise Reject', response);
        return Promise.reject(response);
      }

      return response;
    },
    (error) => {
      if (typeof error !== 'undefined') {
        let response = error.response || { data: {} };
        const msg = response.data.message || '';
        if ((response !== undefined && response.status === 403) || msg.includes('JWT expired')) {
          const redirectTo = window.location.pathname;
          window.location.href = `/login?message=Sessão Expirada!&redirect=${redirectTo}`;
          console.log('Token Expire', response);
          return Promise.reject(response.data);
        } else {
          console.log('API Promise Reject', error);
          return Promise.reject(error);
        }
      } else {
        console.log('API Promise Reject', error);
        return Promise.reject(error);
      }
    }
  );
};

export default errorResponseInterceptor;
