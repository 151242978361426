import React, { Component } from 'react';

import { Row, Col } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { registerModal } from '../redux/SettingsLibraries.actions';
import MessagesComponent from '../../../../components/Messages.component';
import SettingsAPI from '../../../../service/SettingsAPI';
import { IfComponent } from '../../../../components/if.component';

class SettingsLibrariesRegisterModal extends Component {
  state = {
    library: null,
    message: {
      display: false,
      type: 'danger',
      text: ''
    }
  };

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps = ({ formData = {} }, nextContext) => {
    if (formData.libraryItem) {
      this.setState({
        library: formData.libraryItem.nome
      });
    } else {
      this.setState({
        library: null
      });
    }
  };

  handlerLibrary = (e) => {
    const {
      target: { value }
    } = e;
    this.setState({ library: value });
  };

  closeModal = () => {
    this.props.closeModal();
    this.setState({
      library: null,
      message: {
        display: false,
        type: 'danger',
        text: ''
      }
    });
  };

  saveLibrary = async () => {
    const { library } = this.state;
    const { formData = {}, showLoader, hideLoader, refreshPage } = this.props;
    const { id, nome, descricao } = formData.library;
    const { libraryItem = {} } = formData;
    showLoader();

    try {
      if (library) {
        const librarySave = {
          id: libraryItem.id,
          name: library,
          position: libraryItem.posicao
        };

        await SettingsAPI.saveLibrary({ library: id, libraryItem: librarySave });

        refreshPage();
        this.closeModal();
      } else {
        this.setState({
          message: {
            display: true,
            type: 'danger',
            text: `Campo ${descricao} obrigatório`
          }
        });
      }
    } catch (e) {
      console.error(e);
      this.setState({
        message: {
          display: true,
          type: 'danger',
          text: `Erro ao salvar ${nome}`
        }
      });
    }

    hideLoader();
  };

  render() {
    const { modal, formData = {} } = this.props;
    const { message, library } = this.state;

    const { library: { nome, descricao } = {} } = formData;
    const { display, type, text } = message;

    return (
      <Modal isOpen={modal} toggle={this.closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={this.closeModal}
          close={
            <button className='close' onClick={this.closeModal}>
              &times;
            </button>
          }
        >
          <strong>Adicionar</strong> {nome}
        </ModalHeader>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.saveLibrary();
          }}
        >
          <ModalBody>
            <MessagesComponent display={display} type={type} message={text} />
            <div className='margin-top-10'>
              <Row className='margin-top-10'>
                <Col sm='12'>
                  <form>
                    <IfComponent conditional={descricao === 'Arco Superior e Inferior'}>
                      <label>
                        {'Abreviação'}
                        <span>*</span>
                      </label>
                      <input
                        className='input-secondary'
                        type='text'
                        name='biblioteca'
                        value={library}
                        onChange={this.handlerLibrary}
                        maxLength={9}
                      ></input>
                    </IfComponent>

                    <IfComponent conditional={descricao !== 'Arco Superior e Inferior'}>
                      <label>
                        {descricao}
                        <span>*</span>
                      </label>
                      <input
                        className='input-secondary'
                        type='text'
                        name='biblioteca'
                        value={library}
                        onChange={this.handlerLibrary}
                      ></input>
                    </IfComponent>
                  </form>
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col sm={{ size: 3, offset: 6 }}>
                <button className='button-primary'>Salvar</button>
              </Col>
              <Col sm='3'>
                <button className='button-tertiary' type={'button'} onClick={this.closeModal}>
                  Cancelar
                </button>
              </Col>
            </Row>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = ({ SettingsLibrariesReducer }) => {
  const { register } = SettingsLibrariesReducer;
  return {
    ...register
  };
};

const mapDispathToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(registerModal({ modal: false, formData: {} }))
  };
};

const enhanced = compose(connect(mapStateToProps, mapDispathToProps));

export default enhanced(SettingsLibrariesRegisterModal);
