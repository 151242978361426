import React, { useState } from 'react';
import { Col, Container, Row, Label } from 'reactstrap';
import Loader from '../../components/Loader.component';
import logo from '../../assets/img/logo.jpg';
import { ToastContainer } from 'react-toastify';
import InputComponent from '../../components/InputComponent';
import { Form, Formik } from 'formik';
import { maskCEP, maskCPF, maskDate, maskPhoneDefine } from '../../utils/Formatter';
import bancos from '../../utils/bancos.json';
import SelectComponent from '../../components/SelectComponent';
import * as Yup from 'yup';
import moment from 'moment';
import AffiliatesApi from '../../service/AffiliatesApi';
import ToastUtils from '../../utils/ToastUtils';

const AffiliateRegister = () => {
  const formValidation = Yup.object().shape({
    nome: Yup.string().required('Nome Obrigatório'),
    email: Yup.string().required('E-mail Obrigatório').email('E-mail inválido'),
    ocupacao: Yup.string().required('Ocupação Obrigatório'),
    cpf: Yup.string().required('Cpf Obrigatório'),
    endereco: Yup.string().required('Endereço Obrigatório'),
    numero: Yup.string().required('Número Obrigatório'),
    estado: Yup.string().required('Estado Obrigatório'),
    cidade: Yup.string().required('Cidade Obrigatória'),
    bairro: Yup.string().required('Bairro Obrigatório'),
    cep: Yup.string().required('CEP Obrigatório'),
    banco: Yup.object().shape({
      value: Yup.string().required('Banco Obrigatório')
    }),
    agencia: Yup.string().required('Agencia Obrigatório'),
    conta: Yup.string().required('Conta Obrigatória'),
    tipo_conta: Yup.object().shape({
      value: Yup.string().required('Tipo de conta Obrigatório')
    })
  });
  const [formInit, setFormInit] = useState({});

  async function saveAffiliate(values) {
    const request = {
      nome: values.nome,
      email: values.email,
      ocupacao: values.ocupacao,
      dataNascimento: values.dataNascimento && moment(values.dataNascimento, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      cpf: values.cpf,
      cep: values.cep,
      telefone: values.telefone,
      endereco: values.endereco,
      numero: values.numero,
      complemento: values.complemento,
      bairro: values.bairro,
      estado: values.estado,
      cidade: values.cidade,
      resposta: '',
      dadosBancarios: {
        banco: values.banco.value,
        agencia: values.agencia,
        conta: values.conta,
        tipoConta: values.tipo_conta.value
      }
    };
    await AffiliatesApi.saveAffiliate(request);
    ToastUtils.success('Solicitação registrada com sucesso');
    setFormInit({
      nome: '',
      email: '',
      ocupacao: '',
      cpf: '',
      endereco: '',
      numero: '',
      telefone: '',
      complemento: '',
      estado: '',
      cidade: '',
      bairro: '',
      cep: '',
      banco: {},
      agencia: '',
      conta: '',
      tipo_conta: {}
    });
  }

  return (
    <Container>
      <Loader />
      <main className='affiliate-main-container-overflow'>
        <div className='affiliate-container'>
          <div className='affiliate-box'>
            <div className='affiliate-box-right'>
              <Row>
                <Col sm='12' className={'text-center'}>
                  <img className='img-logo-login' style={{ width: 145 }} src={logo} alt='logo' />
                </Col>
              </Row>
              <br />
              <h3 className={'text-center'}>Cadastro de Afiliado</h3>
              <br />
              <Row>
                <Col sm={12} style={{ padding: 10 }}>
                  <Formik
                    validateOnChange={false}
                    enableReinitialize={true}
                    initialValues={formInit}
                    validationSchema={formValidation}
                    onSubmit={saveAffiliate}
                  >
                    {({ values, errors, handleChange }) => {
                      return (
                        <Form>
                          <h4 className={'text-left mt-2 mb-4'}>Dados Pessoais</h4>

                          <Row>
                            <Col sm={6}>
                              <Label>Nome</Label>
                              <InputComponent
                                type={'text'}
                                name={'nome'}
                                values={values}
                                errors={errors}
                                handler={handleChange}
                                className={'mb-2'}
                              />
                            </Col>
                            <Col sm={6}>
                              <Label>E-mail</Label>
                              <InputComponent
                                type={'text'}
                                name={'email'}
                                values={values}
                                errors={errors}
                                handler={handleChange}
                                className={'mb-2'}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <Label>Ocupação</Label>
                              <InputComponent
                                type={'text'}
                                name={'ocupacao'}
                                values={values}
                                errors={errors}
                                handler={handleChange}
                                className={'mb-2'}
                              />
                            </Col>
                            <Col sm={6}>
                              <Label>Data de Nascimento</Label>
                              <InputComponent
                                type={'text'}
                                name={'data_nascimento'}
                                values={values}
                                errors={errors}
                                handler={handleChange}
                                mask={maskDate()}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12}>
                              <Label>CPF</Label>
                              <InputComponent
                                type={'text'}
                                name={'cpf'}
                                values={values}
                                errors={errors}
                                handler={handleChange}
                                mask={maskCPF()}
                              />
                            </Col>
                          </Row>
                          <br />
                          <br />
                          <h4 className={'text-left mt-2 mb-4'}>Endereço</h4>
                          <Row>
                            <Col sm={10}>
                              <Label>Endereço</Label>
                              <InputComponent
                                type={'text'}
                                name={'endereco'}
                                values={values}
                                errors={errors}
                                handler={handleChange}
                                className={'mb-2'}
                              />
                            </Col>
                            <Col sm={2}>
                              <Label>Número</Label>
                              <InputComponent
                                type={'text'}
                                name={'numero'}
                                values={values}
                                errors={errors}
                                handler={handleChange}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <Label>Telefone</Label>
                              <InputComponent
                                type={'text'}
                                name={'telefone'}
                                values={values}
                                errors={errors}
                                handler={handleChange}
                                mask={maskPhoneDefine()}
                              />
                            </Col>
                            <Col sm={6}>
                              <Label>Complemento</Label>
                              <InputComponent
                                type={'text'}
                                name={'complemento'}
                                values={values}
                                errors={errors}
                                handler={handleChange}
                                className={'mb-2'}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <Label>Estado</Label>
                              <InputComponent
                                type={'text'}
                                name={'estado'}
                                values={values}
                                errors={errors}
                                handler={handleChange}
                                mask={[/[a-zA-Z]/, /[a-zA-Z]/]}
                              />
                            </Col>
                            <Col sm={6}>
                              <Label>Cidade</Label>
                              <InputComponent
                                type={'text'}
                                name={'cidade'}
                                values={values}
                                errors={errors}
                                handler={handleChange}
                                className={'mb-2'}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <Label>Bairro</Label>
                              <InputComponent
                                type={'text'}
                                name={'bairro'}
                                values={values}
                                errors={errors}
                                handler={handleChange}
                              />
                            </Col>
                            <Col sm={6}>
                              <Label>Cep</Label>
                              <InputComponent
                                type={'text'}
                                name={'cep'}
                                values={values}
                                errors={errors}
                                handler={handleChange}
                                mask={maskCEP()}
                              />
                            </Col>
                          </Row>
                          <br />
                          <br />
                          <h4 className={'text-left mt-2 mb-4'}>Dados Bancários</h4>
                          <Row>
                            <Col sm={6}>
                              <Label>Banco</Label>
                            </Col>
                            <Col sm={6}>
                              <Label>Agencia</Label>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <SelectComponent
                                name={'banco'}
                                options={bancos
                                  .sort((a, b) => {
                                    if (a.value > b.value) return 1;
                                    if (a.value < b.value) return -1;
                                    return 0;
                                  })
                                  .map((b) => ({
                                    label: b.value + ' - ' + b.label,
                                    value: b.value
                                  }))}
                                handleChange={handleChange}
                                values={values}
                                errors={errors}
                              />
                            </Col>
                            <Col sm={6}>
                              <InputComponent
                                type={'text'}
                                name={'agencia'}
                                values={values}
                                errors={errors}
                                handler={handleChange}
                                className={'mb-2'}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <Label>Conta</Label>
                            </Col>
                            <Col sm={6}>
                              <Label>Tipo de Conta</Label>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <InputComponent
                                type={'text'}
                                name={'conta'}
                                values={values}
                                errors={errors}
                                handler={handleChange}
                              />
                            </Col>
                            <Col sm={6}>
                              <SelectComponent
                                name={'tipo_conta'}
                                handleChange={handleChange}
                                options={[{ label: 'Conta corrente', value: 'CONTA_CORRENTE' }]}
                                values={values}
                                errors={errors}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={9}></Col>
                            <Col sm={3} className='px-4 pt-3'>
                              <button type='submit' style={{ height: 39 }} className='button-primary w-100'>
                                Salvar
                              </button>
                            </Col>
                          </Row>
                        </Form>
                      );
                    }}
                  </Formik>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </main>
      <ToastContainer />
    </Container>
  );
};

export default AffiliateRegister;
