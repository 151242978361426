import React, { Component,useState} from 'react';
import { Col, Container, Row, Modal, ModalHeader } from 'reactstrap';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import UserAPI from '../../service/User.service';
import PropTypes from 'prop-types';
import { selectStyles } from '../../../src/config/core.config';
import Cake from '../../assets/img/icons/birthday_cake.svg'
import Cake2 from '../../assets/img/icons/Birthday_cake2.svg'
import Cake3 from '../../assets/img/icons/Birthday_cake3.svg'
import BModal from './ScheduleModalBday'
import ReactTooltip from 'react-tooltip';
import ClinicAPI from '../../service/ClinicAPI';


class ScheduleMenuComponent extends Component {

  constructor(props){
    super(props);
    this.componetModal = React.createRef();
  }

  state = {
    dentistSelected: {},
    dentistsOption: [],
    quantidadeBday:0
  };
  

  async componentDidMount() {
    const quantidade = await ClinicAPI.getBirthdayToday()
    this.setState({
      quantidadeBday: quantidade.data.length
    })
    const dentists = await UserAPI.findAllDentistas();
    const dentistsOptions = dentists.map((d) => {
      return {
        value: d.id,
        label: d.nome,
        data: d
      };
    });


    const dentistSelectedStorage = sessionStorage.getItem('dentistSelected');

    let dentistSelected = dentistsOptions[0];

    if (dentistSelectedStorage) {
      dentistSelected = JSON.parse(dentistSelectedStorage);
    } else {
      sessionStorage.setItem('dentistSelected', JSON.stringify(dentistSelected));
    }

    this.setState({
      dentistsOptions,
      dentistSelected,
    });

    if (this.props.dentistSelected) this.props.dentistSelected(dentistSelected);
  }

  selectDentist = (d) => {
    this.setState({ dentistSelected: d });

    sessionStorage.setItem('dentistSelected', JSON.stringify(d));

    if (this.props.dentistSelected) {
      this.props.dentistSelected(d);
    }
  };
  

  clickHandler = () =>{
    this.componetModal.current.openModal();
  }


  render() {

    const { dentistsOptions, dentistSelected } = this.state;
    console.log(this.props.url);

    return (
      <div className={'content-box-without-color'} key={'home'}>
        <Container>
          <Row>
            <Col sm={{ size: 11, offset: 1 }}>
              <div className={'schedule-menu'}>
                <span className='schedule-doctor'>
                  <div className='schedule-select-doctor'>
                    <Select
                      className='select-component'
                      styles={selectStyles}
                      value={dentistSelected}
                      options={dentistsOptions}
                      onChange={this.selectDentist}
                    />
                  </div>
                </span>
                
                <span
                  className='d-flex pr-3'
                  >
                    <button className='BtnCake' onClick={this.clickHandler} style={{border:0,background:'transparent',}}>
                        <a data-tip='Aniversariantes' data-for='BtnCake-ToollTip'>
                          <img src={Cake3} style={{width:25,height:25,marginBottom:9,filter:'brightness(0) saturate(100%) invert(26%) sepia(23%) saturate(3078%) hue-rotate(250deg) brightness(93%) contrast(95%)'}}/> 
                        </a>
                    </button> 
                    <span className='btn active btn-circle' style={{marginLeft:18,bottom:45,position:'absolute' ,cursor: 'default',background:'#8139FC',color:'#fff',width:17,height:17 }}>
                      {this.state.quantidadeBday}
                    </span>
                </span>
                
                <span
                  className={
                    this.props.url === '/schedule' || this.props.url === '/' ? 'schedule-sub active' : ' schedule-sub'
                  }
                >
                  <Link to={'/schedule'}>Dia</Link>
                </span>
                <span
                  className={this.props.url === '/schedule/confirmations' ? 'schedule-sub active' : 'schedule-sub '}
                >
                  <Link to={'/schedule/confirmations'}>Confirmações</Link>
                </span>
                <span className={this.props.url === '/schedule/list' ? 'schedule-sub active' : ' schedule-sub'}>
                  <Link to={'/schedule/list'}>Listas de Agendamento</Link>
                </span>
                {/*<span className={ 'schedule-sub' }>Config</span>*/}
              </div>
            </Col>

            <ReactTooltip className='tooltip-griks' id='BtnCake-ToollTip' place='top' type='light'/>

          </Row>
        </Container>
        <BModal ref={this.componetModal}/>
      </div>
      
    );
  }
}

ScheduleMenuComponent.propTypes = {
  dentistSelected: PropTypes.func
};

export default ScheduleMenuComponent;
