import {
  EDIT_CLINIC_ADDRESS_SETTINGS,
  EDIT_CLINIC_SETTINGS,
  FIND_CLINIC_SETTINGS_BY_USER,
  EDIT_CLINIC_TIME_SETTINGS
} from './settings.actions';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case FIND_CLINIC_SETTINGS_BY_USER: {
      return {
        settings: {
          all: {
            ...action.clinic
          }
        }
      };
    }
    case EDIT_CLINIC_SETTINGS: {
      return {
        ...state,
        settings: {
          ...state.settings,
          clinic: {
            ...action.clinic
          }
        }
      };
    }
    case EDIT_CLINIC_ADDRESS_SETTINGS: {
      return {
        ...state,
        settings: {
          ...state.settings,
          address: {
            ...action.clinic
          }
        }
      };
    }
    case EDIT_CLINIC_TIME_SETTINGS: {
      const { clinic = {} } = action;
      return {
        ...state,
        time: {
          clinic: {
            ...clinic.data
          },
          showModal: clinic.showModal
        }
      };
    }
    default:
      return state;
  }
};
