import t from 'typy';
import { createSelector } from 'reselect';

const appointmentHistoryModalSelector = (state) => t(state, 'ScheduleReducer.appointmentHistoryModal').safeObject;

export const appointmentHistoryStateToProps = createSelector(
  appointmentHistoryModalSelector,
  ({ modal, patient, history = [] } = {}) => {
    const totalConsultas = t(history).safeArray.length;

    const totalAtendidas = t(history).safeArray.reduce((total, h) => {
      if (h.status === 'Atendida') {
        total = total + 1;
      }
      return total;
    }, 0);

    const totalRemarcadas = t(history).safeArray.reduce((total, h) => {
      if (h.status === 'Remarcada Clínica') {
        total = total + 1;
      }
      return total;
    }, 0);

    const totalDesmarcada = t(history).safeArray.reduce((total, h) => {
      if (h.status === 'Desmarcada Clínica') {
        total = total + 1;
      }
      return total;
    }, 0);

    const frequencia = (totalAtendidas / (totalConsultas - (totalRemarcadas + totalDesmarcada))) * 100;
    // const frequencia = (100 / (120 - (10 + 10))) * 100;

    return {
      modal,
      patient,
      history,
      frequencia
    };
  }
);
