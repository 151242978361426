import React, { Component } from 'react';
import Loader from '../../components/Loader.component';
import { Col, Container, Input, Label, Row } from 'reactstrap';
import logo from '../../assets/img/logo.jpg';
import ErrorField from '../../components/ErrorField.component';
import stopwatchIcon from '../../assets/img/stopwatch-icon.svg';
import Icon from '../../components/Icon.component';
import { t } from 'typy';
import ConfirmarEmail from '../../assets/img/ConfirmarEmail.svg';

class SubscribeSuccessPage extends Component {
  state = {
    nome: '',
    pending: true
  };

  componentDidMount() {
    this.setState({
      nome: t(this.props, 'location.state.nome').safeString
    });
  }

  render() {
    const { pending } = this.state;
    return (
      <Container>
        <Loader />
        <main className='main-container-overflow'>
          <div className='login-container'>
            <div className='login-box'>
              <div className='login-box-right' style={{ paddingLeft: 100, paddingRight: 100 }}>
                <Row>
                  <Col sm='12'>
                    <img className='img-logo-login' src={logo} alt='logo' />
                  </Col>
                </Row>
                <br />
                <Container>
                  <Row>
                    <Col sm='12' className={'text-md font-light'} style={{ padding: 20 }}>
                      Parabéns por escolher o Griks !<br />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      sm={12}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <Icon image={ConfirmarEmail} style={{ width: 70 }} />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <div
                        style={{
                          padding: 20,
                          fontSize: 14
                        }}
                      >
                        Sua Nota Fiscal será enviada por E-mail
                      </div>
                    </Col>
                  </Row>
                  <Row className={'align-content-center'}>
                    <Col sm={{ size: 10, offset: 1 }}>
                      <button
                        type='submit'
                        className='button-primary'
                        onClick={() => this.props.history.push('/login')}
                        style={{ width: 230, heigth: 37 }}
                      >
                        Login
                      </button>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </main>
      </Container>
    );
  }
}

export default SubscribeSuccessPage;
