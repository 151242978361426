import React, { Component } from 'react';
import { t } from 'typy';
import { Col, Container, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import moment from 'moment';
import ImageApi from '../../../../service/ImageApi';
import TreatmentImagesApi from '../../../../service/TreatmentImagesApi';
import ToastUtils from '../../../../utils/ToastUtils';
import { hideLoader, showLoader } from '../../../../components/actions/Loader.actions';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { IfComponent } from '../../../../components/if.component';
import DatePicker from 'react-datepicker';
import { Progress } from 'reactstrap';
import logo from '../../../../assets/img/logo-partial.jpg';
import 'react-step-progress-bar/styles.css';
import { ProgressBar, Step } from 'react-step-progress-bar';

class TreatmentImagesUploadImagesModal extends Component {
  defaultState = {
    imagesName: moment(),
    nameNotFound: false,
    percent: 0,
    resolved: 0,
    openProgress: false,
    openModal: false,
    filesEvent: []
  };

  state = {
    ...this.defaultState
  };

  _close = () => {
    this.setState({
      ...this.defaultState
    });
  };

  _incrementPercent = () => {
    this.setState({
      percent: this.state.percent + 0.1
    });
    if (this.state.percent < 99) {
      setTimeout(this._incrementPercent, 1000);
    }
  };

  _uploadFile = async (e) => {
    if (e.target.files.length > 0) this.setState({ filesEvent: e.target.files, openModal: true });
  };

  uploadImages = () => {
    // Enlarge
    // http://localhost:9000/enlarge?width=3000&height=3000&url=https://www.rollingstone.com/wp-content/uploads/2018/06/rs-181321-85217128_c.jpg?resize=900,600&w=450&type=jpeg&nocrop=true

    // Resized
    // http://localhost:9000/resize?width=1500&height=1000&url=https://consequenceofsound.net/wp-content/uploads/2019/10/Foo-Fighters.jpg&type=jpeg&stripmeta=true

    // Thumbnail
    // http://localhost:9000/thumbnail?width=225&height=150&url=http://forgetoday.com/wp-content/uploads/2017/10/Foo-Fighters.jpg&type=jpeg

    try {
      const { treatment, showLoader, hideLoader, folderSelected, updateParent } = this.props;
      const { imagesName, filesEvent } = this.state;
      if (!imagesName) {
        this.setState({
          nameNotFound: true
        });
        return;
      }
      const files = [];

      let uploads = [];
      if (t(filesEvent).isDefined != null) {
        const filesLength = filesEvent.length;
        this.setState({
          resolved: filesLength,
          percent: 1,
          openProgress: true,
          openModal: false
        });

        setTimeout(this._incrementPercent, 1000);

        for (let i = 0; i < filesLength; i++) {
          const f = filesEvent[i];
          files.push(f);
          uploads.push(
            ImageApi.convertImage(f).then((data) => {
              return data;
            })
          );
        }

        Promise.all(uploads)
          .then(async (data) => {
            let imagesUpload = [];
            for (let i = 0; i < data.length; i++) {
              const thumbnail = await ImageApi.thumbnailImage(files[i], 225, 150);
              const item = TreatmentImagesApi.saveImageInFolder(
                treatment,
                imagesName.format('DD/MM/YYYY'),
                folderSelected.folder_id,
                data[i],
                folderSelected.images.length + (i + 1),
                folderSelected.images.filter((i) => i.name.includes(imagesName)).length + (i + 1),
                thumbnail
              );
              item.then((data) => {
                this.setState({
                  resolved: this.state.resolved - 1,
                  percent: this.state.percent + 100 / this.state.resolved
                });
                return data;
              });
              imagesUpload.push(item);
            }

            Promise.all(imagesUpload)
              .then((data) => {
                updateParent();
                this._close();
              })
              .catch((err) => {
                console.error(err);
                hideLoader();
                this._close();
                ToastUtils.error('Erro ao fazer upload das imagens entre em contato com o suporte');
              });
          })
          .catch((err) => {
            console.error(err);
            this._close();
            ToastUtils.error('Erro ao fazer upload das imagens entre em contato com o suporte');
          });
      }
    } catch (e) {
      console.error(e);
      this._close();
      ToastUtils.error('Erro ao fazer upload das imagens entre em contato com o suporte');
    }
  };

  openFileUpload = () => this.upload.click();

  componentDidMount() {
    const quotes = [
      'O que sabemos é uma gota; o que ignoramos é um oceano. — Isaac Newton',
      'Não existem métodos fáceis para resolver problemas difíceis. — René Descartes',
      'Viver é enfrentar um problema atrás do outro. O modo como você o encara é que faz a diferença. — Benjamin Franklin',
      'Imaginar é mais importante que saber, pois o conhecimento é limitado enquanto a imaginação abraça o Universo. — Albert Einstein'
    ];
    const randIndex = Math.floor(Math.random() * quotes.length);
    const randomQuote = quotes[randIndex];
    this.setState({ randomQuote: randomQuote });
  }

  render() {
    const { className } = this.props;
    const { imagesName, nameNotFound, resolved, percent, openProgress, openModal, randomQuote } = this.state;

    return (
      <div className='content-box-without-color'>
        <input
          type='file'
          id='file'
          ref={(ref) => (this.upload = ref)}
          style={{ display: 'none' }}
          onChange={(event) => this._uploadFile(event)}
          multiple={true}
        />
        <Container>
          <Modal isOpen={openModal} toggle={this._close} size='small' className={className}>
            <ModalHeader
              className='title-primary'
              toggle={this._close}
              close={
                <button className='close' onClick={this._close}>
                  &times;
                </button>
              }
            >
              <strong>Adicionar Novas Imagens</strong>
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col className={'d-flex flex-column justify-content-center'}>
                  <Label>
                    Data das Imagens <span>*</span>
                  </Label>
                </Col>
                <Col sm={8}>
                  <DatePicker
                    name={'dataOrcamento'}
                    selected={imagesName}
                    onChange={(value) => this.setState({ imagesName: value })}
                    useWeekdaysShort={true}
                    locale='pt-br-griks'
                    className={['input-secondary', 'dateInput', nameNotFound ? 'is-invalid form-control' : '']}
                    style={{ width: 300 }}
                    placeholderText=''
                  />
                  <IfComponent conditional={nameNotFound}>
                    <div style={{ fontSize: 10, color: 'red' }}>{'Data das Imagens Obrigatória'}</div>
                  </IfComponent>
                  {/*<InputComponent type={ 'text' } name={ 'images' }*/}
                  {/*                value={ imagesName }*/}
                  {/*                handler={ (e) => this.setState({ imagesName: e.target.value }) } mask={ maskDate() }*/}
                  {/*                invalid={ nameNotFound }*/}
                  {/*/>*/}
                  {/*<IfComponent conditional={ nameNotFound }>*/}
                  {/*  <div style={ { fontSize: 10, color: 'red' } }>{ 'Data das Imagens Obrigatória' }</div>*/}
                  {/*</IfComponent>*/}
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter className={'text-center'}>
              <button
                className='button-primary mt-2 mb-3'
                onClick={() => {
                  if (!imagesName) {
                    this.setState({
                      nameNotFound: true
                    });
                  } else {
                    this.setState({
                      nameNotFound: false
                    });
                    this.uploadImages();
                  }
                }}
              >
                Carregar Imagens
              </button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={openProgress}>
            <ModalHeader className={'pt-lg-5 pl-lg-5 pr-lg-5 mb-2 mt-1 ml-5'}>
              <span className='text-secondary'>
                Preparando suas imagens...{percent > 100 ? 99 : Math.round(percent)}%
              </span>
            </ModalHeader>
            <ModalBody>
              <div className={'pb-lg-4 ml-3 mr-3'}>
                <ProgressBar
                  height='15px'
                  width='100%'
                  filledBackground='linear-gradient(to right, #b83cef, #6f2f84)'
                  percent={percent}
                ></ProgressBar>
              </div>
              <Row>
                <Col sm='12' className='pb-lg-4 pl-4 pr-4'>
                  <p className='text-sm font-bold text-center text-wrap' style={{ fontColor: '#596377' }}>
                    {randomQuote}
                  </p>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader())
});

const enhanced = compose(
  connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true
  })
);

export default enhanced(TreatmentImagesUploadImagesModal);
