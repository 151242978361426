import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';

const MessagesComponent = (props) => {
  const { display, type, message } = props;

  return (
    <div>
      {display ? (
        <Alert color={type} {...props}>
          <span dangerouslySetInnerHTML={{ __html: message }} />
        </Alert>
      ) : null}
    </div>
  );
};

MessagesComponent.propTypes = {
  display: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
};

export default MessagesComponent;
