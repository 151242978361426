import React from 'react';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';
import noavatar from '../../assets/img/noavatar.png';
import t from 'typy';

let toastInstance = null;
let close = () => {};
let onCancelCallback = () => {};
const PatientToast = ({ closeToast, schedule }) => {
  const { paciente, procedimento, data_agendamento } = schedule;
  return (
    <Row>
      <Col className={'pt-1'} sm={4}>
        <img src={paciente.foto || noavatar} className='img-mdpi img-circle mt-2' />
      </Col>
      <Col sm={8}>
        <div className={'text-sm font-light text-secondary mt-2'}>CONSULTA EM AGENDAMENTO</div>
        <div className={'text-sm font-bold text-secondary mt-2'}>{paciente.nome}</div>
        <div
          className='procedure-primary badge-proc float-left mt-2'
          style={{
            marginLeft: 0,
            backgroundColor: t(procedimento, 'categoria.rgb').isDefined
              ? procedimento.categoria.rgb
              : t(procedimento, 'cor').safeString,
            color: 'var(--secondary)',
            fontSize: 13
          }}
        >
          <a id={'procedureToast'}>{t(procedimento, 'codinome').safeString}</a>
          <UncontrolledTooltip className='tooltip-white tooltip-zindexUp' placement='top' target='procedureToast'>
            {t(procedimento, 'nome').safeString}
          </UncontrolledTooltip>
        </div>
        <Row>
          {/* <Col sm={ 7 } className={ 'w-25' }>{ data_agendamento && formatDate(data_agendamento, 'DD/MM/YYYY') }</Col> */}
          <Col sm={12}>
            <br />
            <button
              className={
                'button-tertiary text-xsm btn-xsm btn-small-height float-right mt-2 text-secondary-l2 border-secondary-l3'
              }
              onClick={() => {
                closeToastCancel();
              }}
            >
              Cancelar
            </button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export const showToast = (props) => {
  const { schedule, onClose, onCancel = () => {} } = props;
  toastInstance = toast(<PatientToast schedule={schedule} />, {
    closeButton: false,
    onClose: () => {
      close();
    }
    // ...props
  });
  close = onClose;
  onCancelCallback = onCancel;
};

export const closeToast = () => {
  toast.update(toastInstance, {
    autoClose: 1
  });
  close();
};

export const closeToastCancel = () => {
  toast.update(toastInstance, {
    autoClose: 1
  });
  onCancelCallback();
  close = () => {};
};

const TostPatientComponent = (props) => {
  return (
    <div style={{ zIndex: 10000 }}>
      <ToastContainer
        className='toast-patient'
        position='bottom-left'
        autoClose={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnVisibilityChange
        draggable={false}
        style={{ width: 350 }}
      />
    </div>
  );
};

export default TostPatientComponent;
