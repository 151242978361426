import React, { Component, useEffect, useState, useRef } from 'react';
import { Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import noavatar from '../../../assets/img/noavatar.png';
import { AiOutlineSortAscending } from 'react-icons/ai';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { t } from 'typy';
import DomainsAPI from '../../../service/DomainsAPI';
import { hideLoader, showLoader } from '../../../components/actions/Loader.actions';
import { arrayToSelectItems, reactSelectHandleChange } from '../../../utils/ReactSelectUtils';
import MultiSelect from '@khanacademy/react-multi-select';
import _ from 'lodash';
import { Form, Formik } from 'formik';
import { IfComponent } from '../../../components/if.component';
import { AiOutlinePrinter } from 'react-icons/ai';
import ReactTooltip from 'react-tooltip';
import DentistsSharedReducer, { loadDentists } from '../../../shared/dentists_store';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import SelectComponent from '../../../components/SelectComponent';
import moment from 'moment';
import ptBR from 'date-fns/locale/pt-BR';
import styled from 'styled-components';
import * as Yup from 'yup';
import CashAPI from '../../../service/CashAPI';
import { formatReal } from '../../../utils/Formatter';
import ToastUtils from '../../../utils/ToastUtils';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

const FinancialOverdueDebitsPage = (props) => {
  let colors = ['orange', 'red', 'blue', 'purple'];

  return (
    <div className='box-default report-box-default' style={{ minHeight: '60vh' }}>
      <Row>
        <Col sm={12}>
          <strong className='title-medium-plus text-uppercase'>Pacientes com Débitos Vencidos</strong>
        </Col>
      </Row>
      <Formik
        initialValues={{
          periodo: { label: 'Especialidade', value: null }
        }}
        validationSchema={''}
        onSubmit={''}
        validateOnChange={false}
      >
        {({ values, handleChange, errors, resetForm, submitForm }) => {
          return (
            <Form>
              <Container>
                <Row>
                  <Col sm={{ size: 3 }}>
                    <div style={{ marginLeft: 0, marginTop: 10, marginBottom: 10 }}>
                      <span className='report-doctor'>
                        <span className='report-doctor-select'>
                          <MultiSelect
                            options={colors}
                            selected={t(values.dentistas).safeArray}
                            onSelectedChanged={(selected) =>
                              reactSelectHandleChange(selected, handleChange, 'dentistas')
                            }
                            disableSearch={true}
                            overrideStrings={{
                              selectSomeItems: 'Doutor(a)',
                              allItemsAreSelected: 'Todos os dentistas',
                              selectAll: 'Selecionar Todos',
                              search: 'Buscar'
                            }}
                          />
                        </span>
                      </span>
                    </div>
                  </Col>
                  <Col sm={{ size: 3 }} className='ml-2'>
                    <div style={{ marginLeft: 0, marginTop: 10, marginBottom: 10 }}>
                      <span className='report-doctor'>
                        <span className='report-doctor-select'>
                          <MultiSelect
                            options={colors}
                            selected={t(values.situacoes).safeArray}
                            disableSearch={true}
                            onSelectedChanged={(selected) =>
                              reactSelectHandleChange(selected, handleChange, 'situacoes')
                            }
                            overrideStrings={{
                              selectSomeItems: 'Especialidade',
                              allItemsAreSelected: 'Todas as especialidades',
                              selectAll: 'Selecionar Todas',
                              search: 'Buscar'
                            }}
                          />
                        </span>
                      </span>
                    </div>
                  </Col>
                  <Col sm={{ size: 1 }} className='ml-2'>
                    <button
                      style={{ marginTop: 9, width: 105, height: 35 }}
                      type={'submit'}
                      className='btn btn-secondary-l5 text-sm border border-secondary-l4'
                    >
                      {t(values.dentistas).safeArray.length > 0 ? 'Filtrar' : 'Exibir Todos'}
                    </button>
                  </Col>
                  <Col sm={{ size: 2 }} className='ml-4'>
                    <IfComponent conditional={t(values.dentistas).safeArray.length > 0}>
                      <button
                        style={{ marginTop: 9, width: 105, height: 35, marginLeft: 0 }}
                        className='btn btn-outline-restartfilter text-sm font-light'
                        type={'button'}
                        onClick={() => {
                          resetForm({
                            periodo: { label: 'Especialidade', value: null }
                          });
                        }}
                      >
                        Limpar Filtro
                      </button>
                    </IfComponent>
                  </Col>
                  <Col sm={{ size: 1 }}>
                    <IfComponent conditional={t(values.dentistas).safeArray.length > 0}>
                      <div>
                        <span
                          style={{ marginTop: 9, width: 50, height: 35 }}
                          className='btn btn-secondary-l5 text-sm border border-secondary-l4 ml-n5'
                        >
                          <a data-tip data-for={'tooltipPrint'}>
                            <AiOutlinePrinter size={22} />
                          </a>
                          <ReactTooltip id='tooltipPrint' className='tooltip-griks' type='light'>
                            Imprimir
                          </ReactTooltip>
                        </span>
                      </div>
                    </IfComponent>
                  </Col>
                </Row>
                <Row>
                  <Col sm={{ size: 2 }}>
                    <span
                      className='btn btn-default mt-2'
                      style={{
                        backgroundColor: '#f7f9fc',
                        border: '1px solid #cfd5e3',
                        borderRadius: '5px',
                        width: '190px',
                        height: '50px',
                        textAlign: 'left',
                        cursor: 'default'
                      }}
                    >
                      <p className='mt-2 text-xsm font-bold text-secondary ml-3'>Débito Corrente</p>
                      <p className='mt-n1 text-md font-bolder text-secondary-d4 ml-3'>R$ 14.456,00</p>
                    </span>
                  </Col>
                  <Col sm={{ size: 2 }}>
                    <span
                      className='btn btn-default mt-2 ml-2'
                      style={{
                        backgroundColor: '#f7f9fc',
                        border: '1px solid #cfd5e3',
                        borderRadius: '5px',
                        width: '190px',
                        height: '50px',
                        textAlign: 'left',
                        cursor: 'default'
                      }}
                    >
                      <p className='mt-2 text-xsm font-bold text-secondary ml-3'>Débito Vencido</p>
                      <p className='mt-n1 text-md font-bolder text-secondary-d4 ml-3'>R$ 14.456,00</p>
                    </span>
                  </Col>
                  <Col sm={{ size: 2 }}>
                    <span
                      className='btn btn-default mt-2 ml-3'
                      style={{
                        backgroundColor: '#f7f9fc',
                        border: '1px solid #cfd5e3',
                        borderRadius: '5px',
                        width: '190px',
                        height: '50px',
                        textAlign: 'left',
                        cursor: 'default'
                      }}
                    >
                      <p className='mt-2 text-xsm font-bold text-secondary ml-3'>Débito Total</p>
                      <p className='mt-n1 text-md font-bolder text-secondary-d4 ml-3'>R$ 14.456,00</p>
                    </span>
                  </Col>
                </Row>
              </Container>

              <Table className='table-header-fixed p-2 mt-2' responsive>
                <thead>
                  <tr className='row py-2'>
                    <th className='col-sm-3 py-0'>
                      <a
                        className='text-sm text-secondary-d4 font-weight-bold'
                        style={{ textDecoration: 'none' }}
                        href={'javascript:'}
                        onClick={() => {
                          reactSelectHandleChange(values.order === 'A' ? 'D' : 'A', handleChange, 'order');
                        }}
                      >
                        {' '}
                        Paciente{' '}
                      </a>
                      {/*<IfComponent conditional={ values.order === 'A' }>*/}
                      <MdKeyboardArrowDown size={'21'} />
                      {/*</IfComponent>*/}
                      <IfComponent conditional={values.order === 'D'}>
                        <MdKeyboardArrowUp size={'21'} />
                      </IfComponent>
                    </th>
                    <th className='col-sm-2 py-0'>
                      <span className='text-sm text-secondary-d4 font-weight-bold'>Doutor</span>
                    </th>
                    <th className='col-sm-2 py-0'>
                      <span className='text-sm text-secondary-d4 font-weight-bold'>Especialidade</span>
                    </th>
                    <th className='col-sm-2 py-0 pl-5'>
                      <span className='text-sm text-secondary-d4 font-weight-bold'>Débito Corrente</span>
                    </th>
                    <th className='col-sm-2 py-0'>
                      <span className='text-sm text-secondary-d4 font-weight-bold'>Débito Vencido</span>
                    </th>
                    <th className='col-sm-1 py-0 ml-n5'>
                      <span className='text-sm text-secondary-d4 font-weight-bold'>Total</span>
                    </th>
                  </tr>
                </thead>
                <tbody style={{ maxHeight: 'calc(100vh - 400px)' }} onScroll={(e) => this._loadMore(e, values)}>
                  <tr className={'row py-0 box-basic px-0 report-overview-result-tr'}>
                    <td className='col-sm-3'>
                      <Row className='pb-0'>
                        <Col sm={2}>
                          <div>
                            <img
                              onClick={() => this.selectPatientAndShowModal('')}
                              className={'img-user-avatar img-xxldpi cursor-pointer'}
                              src={noavatar}
                              alt='usuario'
                            />
                          </div>
                        </Col>
                        <Col sm={10} className='p-0'>
                          <div className='title-medium pl-4'>
                            <p className='text-sm font-bold text-secondary'>Alexis Sanches</p>
                            <p className='text-sm font-light text-secondary'>30a 2m</p>
                          </div>
                        </Col>
                      </Row>
                    </td>
                    <td className='col-sm-2' valign={'middle'}>
                      <div className='text-sm text-secondary h-100'>Dr. Admin Silva</div>
                    </td>
                    <td className='col-sm-2'>
                      <div className='text-sm font-light text-secondary'>Ortodontia</div>
                    </td>
                    <td className='col-sm-2 pl-5'>
                      <div className='text-sm font-light text-secondary'>R$232,21</div>
                    </td>
                    <td className='col-sm-2'>
                      <div className='text-sm font-light text-secondary text-danger'>R$90,10</div>
                    </td>
                    <td className='col-sm-1'>
                      <div className='text-sm font-light text-secondary ml-n5 font-bold'>R$1000,99</div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default FinancialOverdueDebitsPage;
