import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import numero_por_extenso from 'numero-por-extenso';
import moment from 'moment';
import hash from 'object-hash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { hideLoader, showLoader } from '../../components/actions/Loader.actions';
import ToastUtils from '../../utils/ToastUtils';
import { IfComponent } from '../../components/if.component';
import CashAPI from '../../service/CashAPI';
import { getClinic } from '../../utils/localStorage/SessionStorageUtils';
import { t } from 'typy';
import { formatReal } from '../../utils/Formatter';
import StringUtils from '../../utils/StringUtils';

class VoucherReportsPage extends Component {
  state = {
    error: false,
    launch: {},
    clinic: {}
  };

  componentDidMount() {
    this._loadPage();
  }

  _loadPage = async () => {
    const { showLoader, hideLoader, match } = this.props;
    const { id } = match.params;

    if (id == null) {
      ToastUtils.error('Dados obrigatórios não informados');
      this.setState({ error: true });
    } else {
      try {
        showLoader();
        const launch = await CashAPI.getLaunchForVoucher(id);
        const clinic = await getClinic();
        this.setState({
          launch: launch.data,
          clinic
        });
        hideLoader();
        setTimeout(() => window.print(), 500);
      } catch (e) {
        hideLoader();
        ToastUtils.error('Erro ao recuperar recibo, entre em contato com o suporte');
      }
    }
  };

  render() {
    const { error } = this.state;
    return (
      <div className={'voucher'}>
        <IfComponent conditional={error}>Erro entre em contato com o suporte</IfComponent>
        <IfComponent conditional={!error}>
          <div className={'m-0 p-4 w-100 h-100'} style={{ overflowY: 'auto', position: 'absolute' }}>
            {this._makeVoucher()}
            <br />
            <br />
            <br />
            {this._makeVoucher()}
          </div>
        </IfComponent>
      </div>
    );
  }

  _makeVoucher() {
    const { launch, clinic } = this.state;
    const { paciente, fichaFinanceira } = launch;
    // let pacienteNome = '';
    // let responsavelNome = '';
    // let responsavelCPF = '';
    // if (paciente) {
    //   if (t(paciente, 'nome').safeString !== t(launch, 'recibo.nomeRecibo').safeString) {
    //     responsavelNome = `de ${ t(launch, 'recibo.nomeRecibo').safeString }`;
    //     pacienteNome = paciente.nome;
    //     responsavelCPF = t(launch, 'recibo.cpfRecibo').safeString;
    //   } else {
    //     responsavelNome = 'próprio';
    //     pacienteNome = t(launch, 'recibo.nomeRecibo').safeString;
    //     responsavelCPF = t(launch, 'recibo.cpfRecibo').safeString;
    //   }
    // }

    return (
      <div className={'d-flex flex-column align-items-center m-lg-4 p-2'}>
        <div
          className={'p-lg-4'}
          style={{ border: '1px solid #64686E', backgroundColor: 'white', minWidth: 900, minHeight: 550 }}
        >
          <br />
          <Row>
            <Col sm={12}>
              <Row>
                <Col sm={6} style={{ padding: 30 }}>
                  <span className={'font-bold'} style={{ fontSize: '17px' }}>
                    {clinic.nome && clinic.nome.toUpperCase()}
                  </span>{' '}
                  <br />
                  <span style={{ fontSize: '17px' }}>
                    {clinic.endereco}, {clinic.numero}
                    <br />
                    {clinic.cidade} - {clinic.uf} - {clinic.cep}
                  </span>
                </Col>
                <Col className={'text-right text-big font-bolder'} style={{ padding: 30 }}>
                  Nr {StringUtils.leftPad(t(launch, 'recibo.numeroRecibo').safeNumber, 4)}
                  <br />
                  <div style={{ marginTop: 10 }}>{formatReal(launch.valorLancamento)}</div>
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <IfComponent conditional={t(launch, 'recibo.tipoResponsavel').safeString === 'P'}>
              <Col sm={12}>
                <Row>
                  <Col sm={12} style={{ padding: 30, fontSize: '17px' }}>
                    Recebemos de {t(launch, 'recibo.nomePaciente').safeString}, CPF{' '}
                    {t(launch, 'recibo.cpfPaciente').safeString}, a quantia de {formatReal(launch.valorLancamento)} (
                    {numero_por_extenso.porExtenso(launch.valorLancamento || 0, 'monetario')}). <br />
                    Referente ao Tratamento Odontológico próprio.
                  </Col>
                </Row>
              </Col>
            </IfComponent>
            <IfComponent conditional={t(launch, 'recibo.tipoResponsavel').safeString === 'R'}>
              <Col sm={12}>
                <Row>
                  <Col sm={12} style={{ padding: 30, fontSize: '17px' }}>
                    Recebemos de <span className='font-bold'>{t(launch, 'recibo.nomeResponsavel').safeString}</span>,
                    CPF {t(launch, 'recibo.cpfResponsavel').safeString}, a quantia de{' '}
                    {formatReal(launch.valorLancamento)} (
                    {numero_por_extenso.porExtenso(launch.valorLancamento || 0, 'monetario')}). <br />
                    Referente ao Tratamento Odontológico de {t(launch, 'recibo.nomePaciente').safeString}, CPF{' '}
                    {t(launch, 'recibo.cpfPaciente').safeString}.
                  </Col>
                </Row>
              </Col>
            </IfComponent>
          </Row>
          <br />
          <Row>
            <Col className={'text-center'} style={{ fontSize: '17px' }}>
              {clinic.cidade}, {moment(t(launch, 'recibo.dataEmissao').safeString).format('DD [de] MMMM [de] YYYY')}
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col className={'d-flex flex-column align-items-center'}>
              <IfComponent conditional={t(launch, 'recibo.assinatura').isDefined}>
                <img src={t(launch, 'recibo.assinatura').safeString} style={{ width: 200 }} />
              </IfComponent>
            </Col>
          </Row>
          <Row>
            <Col className={'d-flex flex-column align-items-center'} style={{ paddingBottom: 30 }}>
              <div className={'font-bolder'} style={{ fontSize: '17px' }}>
                {t(launch, 'recibo.nomeEmitente').safeString}
              </div>
              <span style={{ fontSize: '17px' }}>
                CRO{t(launch, 'recibo.croUf').safeString} {t(launch, 'recibo.cro').safeString}&nbsp;&nbsp;&nbsp; CPF{' '}
                {t(launch, 'recibo.cpf').safeString}
              </span>
              <br />
              <br />
              <div>
                <span style={{ fontSize: '17px' }}>Autenticação Digital:</span>{' '}
                <span style={{ fontSize: '17px' }}>{hash(t(launch, 'recibo.nomeEmitente').safeString)}</span>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader())
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(VoucherReportsPage);
