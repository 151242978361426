import { takeEvery } from 'redux-saga/effects';
import {
  SAGA_SETTINGS_SCHEDULE_CHAIRS_MODAL,
  SAGA_SETTINGS_SCHEDULE_STANDARD_PROCEDURE_MODAL
} from '../redux/ScheduleSettings.actions';
import { modalScheduleChairs } from './SettingsScheduleChairs.sagas';
import { modalScheduleProcedure } from './SettingsScheduleProcedure.sagas';

export function* watchScheduleSettingsSaga() {
  yield takeEvery(SAGA_SETTINGS_SCHEDULE_CHAIRS_MODAL, modalScheduleChairs);
  yield takeEvery(SAGA_SETTINGS_SCHEDULE_STANDARD_PROCEDURE_MODAL, modalScheduleProcedure);
}
