import { t } from 'typy';
import { createSelector } from 'reselect';
import { arrayToSelectItems } from '../../../../utils/ReactSelectUtils';

const PatientReportSelector = (state) => t(state, 'PatientReportReducer').safeObject;
const DentitstSelector = (state) => t(state, 'DentistsSharedReducer.dentists').safeObject;

export const patientnOverviewStateToProps = createSelector(
  DentitstSelector,
  PatientReportSelector,
  (dentistsSelector = {}, patientReport = {}) => {
    const dentistsOptions = arrayToSelectItems(t(dentistsSelector, 'dentists').safeArray, 'nome', 'id');
    return {
      dentistsOptions,
      situationsOptions: arrayToSelectItems(t(patientReport, 'patientReport.status').safeArray, 'descricao', 'id'),
      patientResult: t(patientReport, 'patientReportResult.patients.data').safeArray,
      patientPages: t(patientReport, 'patientReportResult.patients.totalPages').safeNumber,
      patientTotal: t(patientReport, 'patientReportResult.patients.totalElements').safeNumber,
      showLoadMore: t(patientReport, 'patientReportResultInfinitScroll.loaderMoreResult').safeBoolean
    };
  }
);
