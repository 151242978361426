import React, { Component } from 'react';

import { Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Form, Formik } from 'formik';
import UserAPI from '../../../../service/User.service';
import TreatmentClinicalRecordApi from '../../../../service/TreatmentClinicalRecordApi';
import * as Yup from 'yup';
import ErrorField from '../../../../components/ErrorField.component';
import MessagesComponent from '../../../../components/Messages.component';

class TreatmentClinicalRecordContentionFinalizeModal extends Component {
  state = {
    message: {
      display: false,
      type: 'danger',
      text: ''
    }
  };

  closeModal = () => {
    this.props.closeModal();
    this.setState({
      message: {
        display: false,
        type: 'danger',
        text: ''
      }
    });
  };

  formValidate = Yup.object().shape({
    senha: Yup.string().required('Senha Obrigatória')
  });

  finalizeContation = (values) => {
    console.log(values);
    const { treatment, clinicalRecord, showLoader, hideLoader, updateParent } = this.props;
    showLoader();
    UserAPI.checkPassword(values.senha)
      .then(async (data) => {
        if (data) {
          try {
            await TreatmentClinicalRecordApi.contationFinalize({
              treatmentId: treatment,
              recordId: clinicalRecord,
              anotacao: values.anotacao
            });

            this.closeModal();
            updateParent();
          } catch (e) {
            this.setState({
              message: {
                display: true,
                type: 'danger',
                text: 'Erro ao finalizar contenção da ficha clinica entre em contato com o suporte'
              }
            });
          }
        } else {
          this.setState({
            message: {
              display: true,
              type: 'danger',
              text: 'Senha Inválida'
            }
          });
        }

        hideLoader();
      })
      .catch(() => {
        this.setState({
          message: {
            display: true,
            type: 'danger',
            text: 'Senha Inválida'
          }
        });
        hideLoader();
      });
  };

  render() {
    const { modal } = this.props;
    const { message } = this.state;
    return (
      <Modal isOpen={modal} toggle={this.closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={this.closeModal}
          close={
            <button className='close' onClick={this.closeModal}>
              &times;
            </button>
          }
        >
          <strong>Finalizar</strong> Contenção
        </ModalHeader>
        <Formik validateOnChange={false} validationSchema={this.formValidate} onSubmit={this.finalizeContation}>
          {({ errors, values, handleChange }) => {
            return (
              <Form>
                <ModalBody>
                  <MessagesComponent display={message.display} type={message.type} message={message.text} />
                  <div className='margin-top-10'>
                    <Row>
                      <Col sm='12'>
                        <p className='text-default' style={{ fontSize: '14px' }}>
                          Ao finalizar a fase de Contenção, o paciente terá Alta Final e ingressará na
                          <strong> Fase de Pós Contenção / Alta</strong>
                        </p>
                      </Col>
                    </Row>
                  </div>
                  <Row className='margin-top-10'>
                    <Col sm='12'>
                      <form>
                        <label>Anotações</label>
                        <textarea
                          className='form-control'
                          rows='2'
                          cols='50'
                          onChange={handleChange}
                          name={'anotacao'}
                          value={values.anotacao}
                        ></textarea>
                      </form>
                    </Col>
                  </Row>
                  <div className='margin-top-10'>
                    <Row>
                      <Col className='input-float' sm={{ size: 6, offset: 3 }}>
                        <Input
                          id='senha'
                          className='input-secondary'
                          type='password'
                          name='senha'
                          value={values.senha}
                          onChange={handleChange}
                          invalid={errors.senha ? true : false}
                          placeholder='Senha'
                        ></Input>
                        <label htmlFor='senha'>Senha</label>
                        <ErrorField fieldName={'senha'} errors={errors} />
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Row>
                    <Col sm={{ size: 3, offset: 6 }}>
                      <button className='button-cancel'>Finalizar</button>
                    </Col>
                    <Col sm='3'>
                      <button className='button-tertiary' type={'button'} onClick={this.closeModal}>
                        Cancelar
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default TreatmentClinicalRecordContentionFinalizeModal;
