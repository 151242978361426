import t from 'typy';
import { createSelector } from 'reselect';

const financialFileLaunchPaymentSelector = (state) =>
  t(state, 'TreatmentFinancialFileReducer.launchPayment').safeObject;

export const launchPaymentToProps = createSelector(financialFileLaunchPaymentSelector, (launchPayment = {}) => {
  const {
    modal,
    financialFile,
    paymentTypes = [],
    receipt = {},
    referencesByEmission = {},
    paymentEntries = []
  } = launchPayment;

  // const paymentsTypeOptions = paymentTypes.map(p => ({ label: p.nome, value: p.id, data: p }))
  // paymentsTypeOptions.unshift({ label: '', value: null, data: {} });

  return {
    modal,
    financialFile,
    // paymentTypesOptions: paymentsTypeOptions,
    receipt: receipt.input,
    referencesByEmission: referencesByEmission,
    paymentTypes,
    paymentEntries
  };
});

export const launchPaymentFormInit = createSelector(financialFileLaunchPaymentSelector, (launchPayment = {}) => {
  const { financialFile, entry = {}, referencesByEmission = {}, paymentEntries = [] } = launchPayment;

  const { dentist = {}, cpf, cro, cro_uf } = referencesByEmission;

  const formaPagamento = {
    value: t(entry, 'forma_pagamento.id').safeObject,
    label: t(entry, 'forma_pagamento.nome').safeString,
    data: entry.forma_pagamento
  };

  let voucher = {
    dentist: { label: dentist.nome, value: dentist.id },
    cpf: cpf,
    cro: cro,
    cro_uf: { label: cro_uf, value: cro_uf }
  };

  if (t(entry, 'voucher').isDefined) {
    voucher = {
      dentist: { label: t(entry, 'voucher.dentist.nome').safeString, value: t(entry, 'voucher.dentist.id').safeNumber },
      emissionName: t(entry, 'voucher.emission').safeString,
      cpf: t(entry, 'voucher.cpf').safeString,
      cro: t(entry, 'voucher.cro').safeString,
      cro_uf: { label: t(entry, 'voucher.cro_uf').safeString, value: t(entry, 'voucher.cro_uf').safeString },
      cnpj: t(entry, 'voucher.cnpj').safeString
    };
  }

  return {
    financialFile,
    id: entry.id,
    anotacao: entry.anotacao,
    referencia: entry.referencia,
    numero_lancamento: entry.numero_lancamento,
    forma_pagamento: formaPagamento,
    valor: !t(paymentEntries).isEmptyArray
      ? paymentEntries.reduce((total, element) => (total += element.valor_lancamento), 0)
      : entry.valor_lancamento,
    // dentist: { label: dentist.nome, value: dentist.id },
    ...voucher,
    referencesByEmission
  };
});
