import React, { Component } from 'react';

import {
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip
} from 'reactstrap';
import Slider from 'rc-slider';
import { RadioButton, RadioGroup } from 'react-radio-buttons';
import 'rc-slider/assets/index.css';

import _ from 'lodash';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { registerModal } from '../redux/SettingsProcedures.action';
import * as Yup from 'yup';
import ErrorField from '../../../../components/ErrorField.component';
import ProceduresAPI from '../../../../service/ProceduresAPI';
import MessagesComponent from '../../../../components/Messages.component';
import question from '../../../../assets/img/icons/question.svg';
import Icon from '../../../../components/Icon.component';

class SettingsProcedureRegisterPage extends Component {
  state = {
    modal: true,
    time: 10,
    dataTime: [],
    errorDisplay: false,
    errorType: 'danger',
    errorMessage: ''
  };

  formValidation = Yup.object().shape({
    procedure: Yup.string().required('Procedimento Obrigatório'),
    codinome: Yup.string().required('Codinome Obrigatório')
  });
  validationSchema = Yup.object().shape({
    titulo: Yup.string().required('Titulo Obrigatório'),
    nome: Yup.string().required('Nome Obrigatório'),
    id_categoria: Yup.string().required('Categoria Obrigatória')
  });

  componentDidMount = () => {
    this.setState({ dataTime: this.time() });
  };

  time = () => {
    const times = _.range(10, 65, 5);

    let dataTime = {};

    times.forEach((t) => {
      dataTime[t] = t;
    });

    return dataTime;
  };

  setTime = (value) => {
    let dataTime = this.state.dataTime;

    if (value > 60) {
      dataTime[value] = value;
    }

    this.setState({
      time: value,
      dataTime
    });
  };

  saveProcedure = async (values) => {
    const { showLoader, hideLoader, closeModal, refreshList } = this.props;
    const { id = null, group = {} } = this.props.register.formData;
    const { procedure, codinome, time, pendencia, lembrete } = values;

    showLoader();
    try {
      await ProceduresAPI.saveProcedure({
        id,
        nome: procedure,
        time: time || 15,
        codinome,
        pendencia,
        lembrete,
        status: 'A',
        categoria: group.id
      });

      refreshList();
      closeModal();
      hideLoader();
    } catch (e) {
      console.error(e);
      this.setState({
        errorDisplay: true,
        errorType: 'danger',
        errorMessage: 'Erro ao salvar Procedimento'
      });
      hideLoader();
    }
  };

  render() {
    const { errorDisplay, errorType, errorMessage } = this.state;
    const { register: { modal = false, formData = {} } = {}, closeModal } = this.props;
    const { id, group = {} } = formData;

    return (
      <div className='content-box-without-color'>
        <Container>
          <Modal isOpen={modal} toggle={closeModal} className={this.props.className}>
            <ModalHeader
              className='title-primary'
              toggle={closeModal}
              close={
                <button className='close' onClick={closeModal}>
                  &times;
                </button>
              }
            >
              <strong>{id ? 'Editar' : 'Adicionar'} Procedimento</strong>
            </ModalHeader>
            <Formik
              validationSchema={this.formValidation}
              initialValues={formData}
              validateOnChange={false}
              onSubmit={this.saveProcedure}
            >
              {({ errors, handleSubmit, handleBlur, handleChange, values }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <ModalBody>
                      <MessagesComponent display={errorDisplay} type={errorType} message={errorMessage} />
                      <div className='margin-top-10'>
                        <Row className='margin-top-10'>
                          <Col className='text-left' sm='12'>
                            <p>
                              <strong>Grupo</strong> &nbsp; {group.nome}
                            </p>
                          </Col>
                        </Row>
                        <Row className='margin-top-10'>
                          <Col sm='12'>
                            <label>
                              Procedimento<span>*</span>
                            </label>
                            <Input
                              className='input-secondary'
                              type='text'
                              name='procedure'
                              onChange={handleChange}
                              invalid={errors.procedure ? true : false}
                              value={values.procedure}
                              maxLength={45}
                            />
                            <ErrorField errors={errors} fieldName={'procedure'} />
                          </Col>
                        </Row>
                        <Row className='margin-top-10'>
                          <Col sm='12'>
                            <label>
                              Codinome<span>*</span>
                            </label>
                            <Input
                              className='input-secondary'
                              type='text'
                              name='codinome'
                              onChange={(e) => {
                                handleChange({
                                  target: {
                                    name: 'codinome',
                                    value: e.target.value.toUpperCase()
                                  }
                                });
                              }}
                              invalid={errors.codinome ? true : false}
                              value={values.codinome}
                              maxLength={12}
                            />
                            <ErrorField errors={errors} fieldName={'codinome'} />
                          </Col>
                        </Row>
                        <Row className='margin-top-10'>
                          <Col sm={12}>
                            <label>Tempo</label>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm='12'>
                            <Row>
                              <Col sm={10} style={{ paddingTop: 15 }}>
                                <Slider
                                  min={15}
                                  max={120}
                                  value={values.time || 15}
                                  step={5}
                                  onChange={(e) => {
                                    handleChange({
                                      target: {
                                        name: 'time',
                                        value: e
                                      }
                                    });

                                    this.setTime(e);
                                  }}
                                />
                              </Col>
                              <Col sm={2} style={{ textAlign: 'center' }}>
                                <input
                                  id
                                  type={'text'}
                                  size={7}
                                  value={(values.time || 15) + ' min'}
                                  style={{
                                    borderLeft: 0,
                                    borderTop: 0,
                                    borderRight: 0,
                                    textAlign: 'center'
                                  }}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col></Col>
                        </Row>
                        <hr></hr>
                        <Row>
                          <Col sm={12}>
                            <label>Pendência de Laboratório</label>
                            <RadioGroup
                              id='pendencia'
                              name='pendencia'
                              className='radio-griks'
                              value={values.pendencia || 'N'}
                              onChange={(e) => {
                                handleChange({
                                  target: {
                                    name: 'pendencia',
                                    value: e
                                  }
                                });
                              }}
                              horizontal
                            >
                              <RadioButton pointColor={'#522068'} iconSize={20} value='S'>
                                Sim
                              </RadioButton>
                              <RadioButton pointColor={'#522068'} iconSize={20} value='N'>
                                Não
                              </RadioButton>
                            </RadioGroup>
                          </Col>
                        </Row>
                        {values.pendencia === 'S' && (
                          <Row className='margin-top-10'>
                            <Col sm={12}>
                              <label>Lembrete</label>
                              <input
                                className='input-secondary'
                                type='text'
                                value={values.lembrete}
                                name='lembrete'
                                maxLength={35}
                                placeholder='Lembrete'
                                onChange={handleChange}
                              ></input>
                            </Col>
                          </Row>
                        )}
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Row>
                        <Col className='btn-list' sm={{ size: 6, offset: 6 }}>
                          <button className='button-primary' type={'submit'}>
                            {' '}
                            Salvar
                          </button>
                          <button type={'button'} className='button-tertiary' onClick={closeModal}>
                            {' '}
                            Cancelar
                          </button>
                        </Col>
                      </Row>
                    </ModalFooter>
                  </form>
                );
              }}
            </Formik>
          </Modal>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ SettingsProceduresReducer: { register } }) => {
  return {
    register
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(registerModal({ modal: false, formData: {} }))
  };
};

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(SettingsProcedureRegisterPage);
