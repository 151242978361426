import React, { Component } from 'react';

import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap';

import plus from '../../../../assets/img/icons/plus.svg';
import Icon from '../../../../components/Icon.component';
import Select from 'react-select';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { treatmentEvaluationComplaint } from '../redux/TreatmentEvaluation.action';
import { Formik } from 'formik';
import TreatmentEvaluationApi from '../../../../service/TreatmentEvaluationApi';
import { hideLoader, showLoader } from '../../../../components/actions/Loader.actions';
import { registerModal } from '../../../settings/libraries/redux/SettingsLibraries.actions';
import SettingsLibrariesRegisterModal from '../../../settings/libraries/modal/SettingsLibrariesRegister.modal';
import DomainsAPI from '../../../../service/DomainsAPI';
import * as Yup from 'yup';
import MessagesComponent from '../../../../components/Messages.component';
import { selectStyles } from '../../../../config/core.config';
import ErrorFieldWithoutBootstrap from '../../../../components/ErrorFieldWithoutBootstrap.component';
import { t } from 'typy';

class TreatmentEvaluationPrincipalComplaintModal extends Component {
  state = {
    complaints: [],
    message: {
      display: false,
      type: 'danger',
      text: ''
    }
  };

  saveComplaint = async (values) => {
    const { formLoad = {}, closeModal, showLoader, hideLoader, updateParent } = this.props;
    const {
      evaluation = {},
      complaint: { id, queixas = [], interesse_paciente = {}, complaints = [] } = {}
    } = formLoad;
    showLoader();

    try {
      await TreatmentEvaluationApi.saveComplaint({
        id,
        avaliacaoId: evaluation.avaliacao_id,
        interessePacienteId: values.interesse_paciente && values.interesse_paciente.value,
        queixas: values.queixas && values.queixas.map((q) => ({ queixaId: q.value }))
      });

      updateParent();
      closeModal();
    } catch (e) {
      console.log(e);
      this.setState({
        message: {
          display: true,
          type: 'danger',
          text: 'Erro ao salvar queixa e interesse entre em contato com o suporte'
        }
      });
    }

    hideLoader();
  };

  componentWillReceiveProps = (nextProps, nextContext) => {
    const { formLoad = {} } = this.props;
    if (nextProps.formLoad && nextProps.formLoad.complaints !== formLoad.complaints) {
      this.setState({ complaints: nextProps.formLoad.complaints });
    }
  };

  findAllComplaints = async () => {
    const { showLoader, hideLoader } = this.props;
    showLoader();
    const complaintData = await DomainsAPI.findAllComplaint();
    console.log(complaintData);
    this.setState({ complaints: complaintData });
    hideLoader();
  };

  render() {
    const { complaints = [], message } = this.state;
    const {
      modal = false,
      formInit = {},
      formLoad: { patientInterests = [] } = {},
      closeModal,
      addNewComplaint,
      showLoader,
      hideLoader
    } = this.props;

    const patientInterestsOptions = [{ label: ' ', value: null }].concat(
      patientInterests.map((i) => ({
        value: i.id,
        label: i.descricao
      }))
    );

    console.log(complaints);

    return (
      <Modal isOpen={modal} toggle={closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={closeModal}
          close={
            <button className='close' onClick={closeModal}>
              &times;
            </button>
          }
        >
          <strong>Editar Queixa Principal e Motivação</strong>
        </ModalHeader>
        <Formik validateOnChange={false} initialValues={formInit} onSubmit={this.saveComplaint}>
          {({ errors, handleSubmit, handleBlur, handleChange, values }) => (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <div>
                  <Row>
                    <Col sm='12'>
                      <label>Queixa Principal</label>
                      <br />
                    </Col>
                    {message.display && (
                      <Col sm={12}>
                        <MessagesComponent display={message.display} type={message.type} message={message.text} />
                      </Col>
                    )}
                    <Col sm={11}>
                      <Select
                        defaultValue={values.queixas}
                        className='react-select-container'
                        classNamePrefix='react-select'
                        closeMenuOnSelect={true}
                        onChange={(selectedOption) => {
                          handleChange({
                            target: {
                              name: 'queixas',
                              value: selectedOption
                            }
                          });
                        }}
                        isMulti
                        isSearchable
                        ignoreAccents={true}
                        ignoreCase={true}
                        options={complaints.map((c) => ({ value: c.id, label: c.nome.trim() }))}
                        placeholder={''}
                        styles={selectStyles}
                      />
                    </Col>
                    <Col sm='1'>
                      <a
                        id='tooltip-add-queixa'
                        href={'#'}
                        onClick={() => {
                          addNewComplaint({
                            modal: true,
                            formData: {
                              library: { id: complaints[0].biblioteca, descricao: 'QUEIXA PRINCIPAL' }
                            }
                          });
                        }}
                      >
                        <Icon className='icon-size-two icon absolute-center' image={plus} alt='plus' />
                      </a>
                      <UncontrolledTooltip className={'tooltip-white'} placement='top' target='tooltip-add-queixa'>
                        Acrescentar Nova Queixa à Lista
                      </UncontrolledTooltip>
                    </Col>
                  </Row>

                  <Row className='margin-top-10'>
                    <Col sm='12'>
                      <label>Interesse do Paciente</label>
                    </Col>
                    <Col sm={12}>
                      <Select
                        value={values.interesse_paciente}
                        isSearchable={true}
                        ignoreAccents={true}
                        ignoreCase={true}
                        options={patientInterestsOptions}
                        placeholder={''}
                        onChange={(selectedOption) => {
                          handleChange({
                            target: {
                              name: 'interesse_paciente',
                              value: selectedOption
                            }
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </ModalBody>
              <ModalFooter>
                <Row>
                  <Col className='btn-list' sm={{ size: 6, offset: 6 }}>
                    <button className='button-primary'>Salvar</button>
                    <button className='button-tertiary' type={'button'} onClick={closeModal}>
                      Cancelar
                    </button>
                  </Col>
                </Row>
              </ModalFooter>
            </form>
          )}
        </Formik>
        <SettingsLibrariesRegisterModal
          showLoader={showLoader}
          hideLoader={hideLoader}
          refreshPage={this.findAllComplaints}
        />
      </Modal>
    );
  }
}

const mapStateToProps = ({ TreatmentEvaluationReducer: { evaluationComplaintModal = {} } = {} }) => {
  const { formLoad = {} } = evaluationComplaintModal;
  const { complaint: { queixas = [], interesse_paciente = {}, complaints = [] } = {} } = formLoad;

  return {
    ...evaluationComplaintModal,
    formInit: {
      queixas: queixas.map((q) => ({ value: parseInt(q.queixa_id), label: q.queixa_nome })),
      interesse_paciente: t(interesse_paciente, 'id').isDefined
        ? {
            value: parseInt(interesse_paciente.id),
            label: interesse_paciente.descricao
          }
        : {}
    }
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(treatmentEvaluationComplaint({ modal: false })),
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  addNewComplaint: (data) => dispatch(registerModal(data))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(TreatmentEvaluationPrincipalComplaintModal);
