import React, { Component } from 'react';

import { Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import PatientEditModalForm from './PatientEdit.modal.form';

import { refreshList, showPatientEditModal } from '../redux/patient.actions';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { hideLoader, showLoader } from '../../../components/actions/Loader.actions';

import PacienteAPI from '../../../service/PacienteAPI';
import { sendMessage } from '../../../components/actions/ErrorMessage.actions';
import MessagesComponent from '../../../components/Messages.component';

import success from '../../../assets/img/icons/success.svg';
import ToastUtils from '../../../utils/ToastUtils';
import ToastCustomMessageComponent from '../../../components/ToastCustomMessage.component';
import danger from '../../../assets/img/icons/danger1.svg';

export class PatientEditModal extends Component {
  state = {
    display: false,
    error: {
      type: '',
      display: false,
      message: ''
    }
  };
  savePatient;

  closeModal = () => {
    this.props.showPatientEditModal({ display: false, patient: {} });
  };

  savePatient = (values, foto, extension) => {
    this.props.showLoader();

    if (foto) {
      PacienteAPI.uploadAvatar(values.paciente_id, foto.replace(/\bdata:.*,/g, ''), extension)
        .then(({ data }) => {
          values.foto = data.path_avatar;
          this.saveCompletePatient(values);
        })
        .catch((err) => {
          console.log(err);
          this.props.hideLoader();
          this.setState({
            error: {
              display: true,
              type: 'danger',
              message: 'Erro ao salvar Paciente'
            }
          });
        });
    } else {
      this.saveCompletePatient(values);
    }
  };

  saveCompletePatient(values) {
    PacienteAPI.savePatient(values)
      .then(() => {
        this.props.hideLoader();
        this.props.showPatientEditModal({ display: false, patient: values });
        this.props.refreshList();
        ToastUtils.success(
          <ToastCustomMessageComponent iconImage={success} title={`${values.nome}`} message={'Salvo com Sucesso !'} />,
          {
            autoClose: 7000,
            hideProgressBar: true,
            position: 'top-center'
          }
        );

        if (this.props.updateComponent) {
          this.props.updateComponent();
        }
      })
      .catch((err) => {
        console.log(err);
        let message = 'Erro ao salvar Paciente';
        if (err.response && err.response.data.exception) {
          if (err.response.data.message.includes('CD_CPF_UNIQUE')) {
            ToastUtils.time(
              <ToastCustomMessageComponent
                message={
                  'Já existe um paciente com este CPF, no sistema.  Insira um CPF correto ou deixe em branco, para salvar.'
                }
                iconImage={danger}
                title={'CPF repetido ! '}
              />,
              {
                autoClose: 8000,
                hideProgressBar: true,
                position: 'top-center'
              }
            );
            this.props.hideLoader();
            return;
          } else {
            message = err.response.data.exception;
          }
        }

        ToastUtils.time(
          <ToastCustomMessageComponent title={'Erro ao salvar paciente'} message={message} iconImage={danger} />,
          {
            autoClose: 8000,
            hideProgressBar: true,
            position: 'top-center'
          }
        );

        this.props.hideLoader();
      });
  }

  setErrorMessage = (error) => {
    this.setState({
      error
    });

    setTimeout(() => {
      this.setState({
        error: {
          display: false
        }
      });
    }, 10000);
  };

  render() {
    const { error } = this.state;
    const { display, className, patient, showLoader, hideLoader } = this.props;

    return (
      <div className='content-box-without-color'>
        <Container>
          <Modal id='editPatient' isOpen={display} toggle={this.closeModal} className='modal-default' size={'lg'}>
            <ModalHeader
              className='title-primary'
              toggle={this.closeModal}
              close={
                <button className='close' onClick={this.closeModal}>
                  &times;
                </button>
              }
            >
              <strong>Editar Cadastro do Paciente</strong>
            </ModalHeader>
            <div>
              <Modal isOpen={error.display}>
                <ModalBody>
                  <div style={{ padding: 20 }}>
                    <MessagesComponent
                      id={'patientEdit'}
                      display={error.display}
                      type={error.type}
                      message={error.message}
                    />
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Row>
                    <Col>
                      <button
                        className='button-primary'
                        onClick={() => {
                          this.setState({
                            error: {
                              display: false,
                              type: '',
                              message: ''
                            }
                          });
                        }}
                      >
                        Fechar
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
              </Modal>
              <PatientEditModalForm
                formInitValues={patient}
                showLoader={showLoader}
                hideLoader={hideLoader}
                savePatient={this.savePatient}
                closeModal={this.closeModal}
                setErrorMessage={this.setErrorMessage}
                scrollTop={this.scrollTop}
              />
            </div>
          </Modal>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { display, patient } = state.PatientReducer;
  return {
    display,
    patient
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showPatientEditModal: (data) => dispatch(showPatientEditModal(data)),
    showLoader: (data) => dispatch(showLoader(data)),
    hideLoader: (data) => dispatch(hideLoader(data)),
    refreshList: (data) => dispatch(refreshList(data)),
    sendMessage: (data) => dispatch(sendMessage(data))
  };
};

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(PatientEditModal);
