import React, { Component } from 'react';
import Loader from '../../components/Loader.component';
import { Col, Container, Input, Label, Row } from 'reactstrap';
import logo from '../../assets/img/logo.jpg';
import ErrorField from '../../components/ErrorField.component';
import stopwatchIcon from '../../assets/img/stopwatch-icon.svg';
import Icon from '../../components/Icon.component';
import { t } from 'typy';

class ConfirmTrialSuccessPage extends Component {
  state = {
    nome: '',
    pending: true
  };

  componentDidMount() {
    this.setState({
      nome: t(this.props, 'location.state.nome').safeString
    });

    setTimeout(() => this.setState({ pending: false }), 3000);
  }

  render() {
    const { pending } = this.state;
    return (
      <Container>
        <Loader />
        <main className='main-container-overflow'>
          <div className='login-container'>
            <div className='login-box'>
              <div className='login-box-right' style={{ paddingLeft: 100, paddingRight: 100 }}>
                <Row>
                  <Col className='pb-4' sm='12'>
                    <img className='img-logo-login' src={logo} alt='logo' />
                  </Col>
                </Row>
                <br />
                {pending ? (
                  <Container>
                    <Row
                      className={'h-50 align-content-center'}
                      style={{ paddingTop: 50, paddingBottom: 35, marginLeft: 20, marginRight: 20 }}
                    >
                      <Col sm={2}>
                        <Icon image={stopwatchIcon} style={{ width: 80 }} />
                      </Col>
                      <Col sm={10}>
                        <div
                          style={{
                            padding: 20,
                            textAlign: 'left',
                            fontSize: 14
                          }}
                        >
                          Aguarde alguns segundos, enquanto formatamos a sua conta...
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className='absolute-center'>
                          {/* <div class="bigg">Loading...</div> */}
                          <div className='lds-ring'>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                          <div className='large progress'>
                            <div>Loading…</div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                ) : (
                  <Container>
                    <Row>
                      <Col sm='12' className={'font-bold text-md-plus'}>
                        Bem-vindo {this.state.nome} !
                      </Col>
                    </Row>
                    <Row>
                      <Col sm='12' className={'text-md font-light'} style={{ padding: 20 }}>
                        Parabéns por escolher o Griks,
                        <br />o melhor Sistema de Gestão para Ortodontia.
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <div
                          style={{
                            padding: 20,
                            fontSize: 14
                          }}
                        >
                          Tudo Pronto, agora é só fazer o login.
                        </div>
                      </Col>
                    </Row>
                    <Row className={'align-content-center'}>
                      <Col sm={{ size: 10, offset: 1 }}>
                        <button
                          type='submit'
                          className='button-primary'
                          onClick={() => this.props.history.push('/login')}
                          style={{ width: 230, height: 37 }}
                        >
                          Login
                        </button>
                      </Col>
                    </Row>
                  </Container>
                )}
              </div>
            </div>
          </div>
        </main>
      </Container>
    );
  }
}

export default ConfirmTrialSuccessPage;
