import { call, put } from 'redux-saga/effects';
import { clinicalRecordAttendancieModal, clinicalRecordAttendancies } from '../redux/TreatmentClinicalRecord.action';
import { hideLoader, showLoader } from '../../../../components/actions/Loader.actions';
import TreatmentClinicalRecordApi from '../../../../service/TreatmentClinicalRecordApi';
import DomainsAPI from '../../../../service/DomainsAPI';
import UserAPI from '../../../../service/User.service';
import { t } from 'typy';
import { toast } from 'react-toastify';
import TreatmentAPI from '../../../../service/TreatmentAPI';

export function* clinicalRecordAttendenciesSaga({ payload: { data = {} } }) {
  const { treatment, record, page = 0, pageSize = 10 } = data;

  yield put(showLoader());
  try {
    const attendenciesData = yield call(TreatmentClinicalRecordApi.findAttendenceRecord, {
      treatmentId: treatment,
      recordId: record,
      page,
      pageSize
    });
    data.attendencies = attendenciesData.data;
  } catch (e) {
    console.log('Erro ao buscar atendimento', e);
    data.error = 'Erro ao buscar atendimento entre em contato com o Suporte';
  }

  yield put(clinicalRecordAttendancies({ data }));
  yield put(hideLoader());
}

export function* clinicalRecordAttendenceModalSaga(props) {
  try {
    const {
      payload: { modal = {}, attendence = {}, treatment, record }
    } = props;

    yield put(showLoader());

    const queixaPrincipal = yield call(DomainsAPI.findAllComplaint);
    const arcos = yield call(DomainsAPI.findAllArch);
    const dentistas = yield call(UserAPI.findAllDentistas);

    let lastAttendenceData = {};

    if (t(attendence, 'id').isUndefined) {
      lastAttendenceData = yield call(TreatmentClinicalRecordApi.findLastAttendence, {
        treatmentId: treatment,
        recordId: record
      });
    }

    const treatmentData = yield call(TreatmentAPI.getTreatmentById, treatment);

    const formLoad = {
      queixaPrincipal,
      arcos,
      dentistas,
      lastAttendence: lastAttendenceData.data,
      attendence
    };

    yield put(clinicalRecordAttendancieModal({ modal, treatment: treatmentData.data, record, formLoad }));
    yield put(hideLoader());
  } catch (e) {
    console.log(e);
    toast.error('Erro ao abrir tela de registrar atendimento', { autoClose: false, position: 'top-center' });
    yield put(hideLoader());
  }
}
