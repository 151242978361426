import React, { Component } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import ErrorField from '../../components/ErrorField.component';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

class ResetPasswordForm extends Component {
  static propTypes = {
    submitCallback: PropTypes.func.isRequired
  };

  validateSchema = Yup.object().shape({
    senha: Yup.string().required('Senha Obrigatória'),
    csenha: Yup.string()
      .required('Confirmar Senha Obrigatório')
      .oneOf([Yup.ref('senha'), null], 'Senha diferente de Confirma Senha')
  });

  render() {
    const { submitCallback } = this.props;

    return (
      <Formik validationSchema={this.validateSchema} onSubmit={submitCallback} validateOnChange={false}>
        {({ errors, handleSubmit, handleChange }) => (
          <form className='login-form' onSubmit={handleSubmit}>
            <FormGroup>
              <Label for='senha'>Senha</Label>
              <Input type='password' name='senha' onChange={handleChange} invalid={errors.senha ? true : false} />
              <ErrorField errors={errors} fieldName={'senha'} />
            </FormGroup>
            <FormGroup>
              <Label className='margin-top-20' for='csenha'>
                Confirmar Senha
              </Label>
              <Input type='password' name='csenha' onChange={handleChange} invalid={errors.csenha ? true : false} />
              <ErrorField errors={errors} fieldName={'csenha'} />
            </FormGroup>
            <button type='submit' className='button-primary'>
              Alterar Senha
            </button>
          </form>
        )}
      </Formik>
    );
  }
}

export default ResetPasswordForm;
