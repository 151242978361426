import { setLocale } from 'yup';
import moment from 'moment';

moment.updateLocale('pt-br-griks', {
  months: 'janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro'.split('_'),
  monthsShort: 'jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez'.split('_'),
  weekdays: 'Domingo_Segunda-Feira_Terça-Feira_Quarta-Feira_Quinta-Feira_Sexta-Feira_Sábado'.split('_'),
  weekdaysShort: 'dom_seg_ter_qua_qui_sex_sáb'.split('_'),
  weekdaysMin: 'dom_2ª_3ª_4ª_5ª_6ª_sáb'.split('_'),
  longDateFormat: {
    LT: 'HH:mm',
    L: 'DD/MM/YYYY',
    LL: 'D [de] MMMM [de] YYYY',
    LLL: 'D [de] MMMM [de] YYYY [às] LT',
    LLLL: 'dddd, D [de] MMMM [de] YYYY [às] LT'
  },
  calendar: {
    sameDay: '[Hoje às] LT',
    nextDay: '[Amanhã às] LT',
    nextWeek: 'dddd [às] LT',
    lastDay: '[Ontem às] LT',
    lastWeek: function () {
      return this.day() === 0 || this.day() === 6
        ? '[Último] dddd [às] LT' // Saturday + Sunday
        : '[Última] dddd [às] LT'; // Monday - Friday
    },
    sameElse: 'L'
  },
  relativeTime: {
    future: 'em %s',
    past: '%s atrás',
    s: 'segundos',
    m: 'um minuto',
    mm: '%d minutos',
    h: 'uma hora',
    hh: '%d horas',
    d: 'um dia',
    dd: '%d dias',
    M: 'um mês',
    MM: '%d meses',
    y: 'um ano',
    yy: '%d anos'
  },
  ordinal: '%dº',
  week: {
    dow: 1
  }
});

export const localeConfig = () => {
  setLocale({
    mixed: {
      default: 'Não é válido',
      required: 'Por favor preencha o campo ${label}'
    },
    number: {
      min: 'Deve ser maior que ${min}'
    }
  });
};

export const selectStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'var(--primary-accent)' : state.isFocused ? 'var(--primary-l4)' : ''
  }),
  container: (base, state) => ({
    ...base
    // zIndex: 99999999
  }),
  control: (base, state) => ({
    ...base,
    minHeight: 33,
    // height:33,
    boxShadow: state.isFocused ? null : base.boxShadow,
    border: state.isFocused ? '1px solid var(--primary-accent) !important' : '1px solid #D4D9E0',
    '&:hover': {
      border: state.isFocused ? '1px solid var(--primary-accent) !important' : base.boxShadow
    },
    '>div': {
      marginTop: -3
    }
  }),
  indicatorSeparator: (base, state) => ({
    ...base,

    display: 'none'
  })
};
