import React, { Component } from 'react';

import { Col, Container, Row, UncontrolledTooltip } from 'reactstrap';

import edit from '../../../assets/img/icons/edit.svg';
import Icon from '../../../components/Icon.component';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import {
  editClinicAddressSettings,
  editClinicSettings,
  editClinicTimeSettings,
  SAGA_FIND_CLINIC_SETTINGS_BY_USER
} from './redux/settings.actions';

import { hideLoader, showLoader } from '../../../components/actions/Loader.actions';
import SettingsClinicPage from './SettingsClinic.page';
import { setHeader } from '../../template/redux/Content.actions';
import settings from '../../../assets/img/icons/settings.svg';
import SettingsAddressPage from './SettingsAddress.page';
import { phoneFormat } from '../../../utils/Formatter';
import SettingsClinicTimePage from './SettingsClinicTime.page';
import question from '../../../assets/img/icons/question.svg';
import withSecurity from '../../../config/security/security';
import { IfComponent } from '../../../components/if.component';
import { PERMISSOES } from '../../../config/security/permissoes';
import { t } from 'typy';
import SettingsEmissionReferencesModal from './SettingsEmissionReferencesModal';
import { openSettingsEmissionsSaga } from './store/settings_emission_references_store';

class SettingsClinicHomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltipOpen: false
    };
  }

  componentDidMount = () => {
    this.props.showLoader();
    this.props.findSettings();
    this.props.setHeader({
      title: 'Configurações',
      image: settings
    });
  };

  editClinic = (clinica) => {
    this.props.editClinicSettings({
      clinic: {
        ...clinica,
        show: true
      }
    });
  };

  editAddressClinic = (clinica) => {
    this.props.editClinicAddressSettings({
      clinic: {
        ...clinica,
        show: true
      }
    });
  };

  render() {
    const { clinica = {} } = this.props.settings;
    const {
      nome,
      razao_social,
      cnpj,
      tecnico = {},
      endereco = {},
      horario_funcionamento = {},
      referencia_emissao = {}
    } = clinica;
    const { nome: tec_nome, cpf, rg, emissor, cro, cro_uf } = tecnico;
    const {
      nome: end_nome,
      numero,
      complemento,
      bairro,
      uf,
      cidade,
      ddd = 0,
      telefone = 0,
      email,
      website,
      cep
    } = endereco;
    const { horario_inicio, horario_fim, dias_atendimento = [] } = horario_funcionamento;

    const { security } = this.props;

    const settingsPerm = PERMISSOES.modules.configuracoes;

    return (
      <div className='content-box-without-color settings mt-3'>
        <Container>
          <Row className='w-100'>
            <Col sm='6'>
              <div className='box-default'>
                <Row>
                  <Col sm='12'>
                    <h2 className='title-medium-plus text-uppercase text-secondary font-bold'>
                      Clínica
                      <IfComponent
                        conditional={
                          security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar) ||
                          (security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar_restricao.id) &&
                            security.hasRestriction(
                              settingsPerm.id,
                              settingsPerm.permissoes.editar_restricao.id,
                              settingsPerm.permissoes.editar_restricao.restricoes.editar_clinica
                            ))
                        }
                      >
                        <a href='#' onClick={() => this.editClinic(clinica)}>
                          <Icon className='icon icon-size-small margin-left-10' image={edit} alt='edit' />
                        </a>
                      </IfComponent>
                    </h2>
                  </Col>
                  <Col sm='12'>
                    <p className='text-default text-sm text-secondary-d4'>
                      <strong className='text-secondary-d4'>Nome da Clínica</strong> {nome}
                    </p>
                    <p className='text-default text-sm text-secondary-d4'>
                      <strong className='text-secondary-d4'>Razão Social</strong> {razao_social}{' '}
                    </p>
                    <p className='text-default text-sm text-secondary-d4'>
                      <strong className='text-secondary-d4'>CNPJ</strong> {cnpj}
                    </p>
                  </Col>
                </Row>
                <hr></hr>
                <Row>
                  <Col sm='12'>
                    <p className='text-default text-sm text-secondary-d4'>
                      <strong className='text-secondary-d4'>Responsável Técnico</strong> {tec_nome}
                    </p>
                    <p className='text-default text-sm text-secondary-d4'>
                      <strong className='text-secondary-d4'>CPF</strong> {cpf}
                    </p>
                    <p className='text-default text-sm text-secondary-d4'>
                      <strong className='text-secondary-d4'>RG/Emissor</strong> {rg}/{emissor}
                    </p>
                    <p className='text-default text-sm text-secondary-d4'>
                      <strong className='text-secondary-d4'>CRO/{cro_uf}</strong> {cro}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col sm='6'>
              <div className='box-default'>
                <Row>
                  <Col sm='12'>
                    <h2 className='title-medium-plus text-uppercase text-secondary font-bold'>
                      Endereço e Contato
                      <IfComponent
                        conditional={
                          security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar) ||
                          (security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar_restricao.id) &&
                            security.hasRestriction(
                              settingsPerm.id,
                              settingsPerm.permissoes.editar_restricao.id,
                              settingsPerm.permissoes.editar_restricao.restricoes.editar_clinica
                            ))
                        }
                      >
                        <a href='#' onClick={() => this.editAddressClinic(clinica)}>
                          <Icon className='icon icon-size-small margin-left-10' image={edit} alt='edit' />
                        </a>
                      </IfComponent>
                    </h2>
                  </Col>
                  <Col sm='12'>
                    <p className='text-default text-sm text-secondary-d4'>
                      <strong className='text-secondary-d4'>Endereço</strong> {end_nome} {numero} {complemento}
                    </p>
                    <p className='text-default text-sm text-secondary-d4'>
                      <strong className='text-secondary-d4'>Bairro</strong> {bairro}
                    </p>
                    <p className='text-default text-sm text-secondary-d4'>
                      <strong className='text-secondary-d4'>Cidade - UF</strong> {cidade} - {uf}
                    </p>
                    <p className='text-default text-sm text-secondary-d4'>
                      <strong className='text-secondary-d4'>CEP</strong> {cep}
                    </p>
                  </Col>
                </Row>
                <hr></hr>
                <Row>
                  <Col sm='12'>
                    <p className='text-default text-sm text-secondary-d4'>
                      <strong className='text-secondary-d4'>Telefone</strong> {phoneFormat(ddd + '' + telefone)}
                    </p>
                    <p className='text-default text-sm text-secondary-d4'>
                      <strong className='text-secondary-d4'>E-mail</strong> {email}
                    </p>
                    <p className='text-default text-sm text-secondary-d4'>
                      <strong className='text-secondary-d4'>Website</strong> {website}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row className='w-100'>
            <Col sm='6'>
              <div className='box-default margin-top-20'>
                <Row>
                  <Col sm='12'>
                    <h2 className='title-medium-plus text-uppercase text-secondary font-bold'>
                      ACESSO AO SISTEMA - PARA USUÁRIOS COM ACESSO RESTRITO
                      <IfComponent
                        conditional={
                          security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar) ||
                          (security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar_restricao.id) &&
                            security.hasRestriction(
                              settingsPerm.id,
                              settingsPerm.permissoes.editar_restricao.id,
                              settingsPerm.permissoes.editar_restricao.restricoes.editar_clinica
                            ))
                        }
                      >
                        <a
                          href='#'
                          onClick={() => this.props.editClinicTimeSettings({ data: clinica, showModal: true })}
                        >
                          <Icon className='icon icon-size-small ml-2' image={edit} />
                        </a>
                      </IfComponent>
                      <Icon className='icon icon-size-small ml-1' image={question} id={'question'} />
                      <UncontrolledTooltip className='tooltip-white' placement='top' target='question'>
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        Horário no qual, os Usuários com "Acesso Restrito" poderão acessar o Sistema.
                      </UncontrolledTooltip>
                    </h2>
                  </Col>
                  <Col sm='12'>
                    <p className='text-default text-sm text-secondary-d4'>
                      <strong className='text-secondary-d4'>Horário Inicial</strong> {horario_inicio} hs
                    </p>
                    <p className='text-default text-sm text-secondary-d4'>
                      <strong className='text-secondary-d4'>Horário Final</strong> {horario_fim} hs
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col sm='12'>
                    <p className='text-default text-sm text-secondary-d4 '>
                      <strong className='text-secondary-d4'>Dias da Semana</strong>
                      <div className='nav-week '>
                        <ul>
                          <li>
                            <a
                              className={`nav-week-item ${
                                dias_atendimento.find((d) => d.dia_semana === 1) ? 'active' : ''
                              }`}
                              href='#'
                            >
                              Seg
                            </a>
                          </li>
                          <li>
                            <a
                              className={`nav-week-item ${
                                dias_atendimento.find((d) => d.dia_semana === 2) ? 'active' : ''
                              }`}
                              href='#'
                            >
                              Ter
                            </a>
                          </li>
                          <li>
                            <a
                              className={`nav-week-item ${
                                dias_atendimento.find((d) => d.dia_semana === 3) ? 'active' : ''
                              }`}
                              href='#'
                            >
                              Qua
                            </a>
                          </li>
                          <li>
                            <a
                              className={`nav-week-item ${
                                dias_atendimento.find((d) => d.dia_semana === 4) ? 'active' : ''
                              }`}
                              href='#'
                            >
                              Qui
                            </a>
                          </li>
                          <li>
                            <a
                              className={`nav-week-item ${
                                dias_atendimento.find((d) => d.dia_semana === 5) ? 'active' : ''
                              }`}
                              href='#'
                            >
                              Sex
                            </a>
                          </li>
                          <li>
                            <a
                              className={`nav-week-item ${
                                dias_atendimento.find((d) => d.dia_semana === 6) ? 'active' : ''
                              }`}
                              href='#'
                            >
                              Sáb
                            </a>
                          </li>
                          <li>
                            <a
                              className={`nav-week-item ${
                                dias_atendimento.find((d) => d.dia_semana === 7) ? 'active' : ''
                              }`}
                              href='#'
                            >
                              Dom
                            </a>
                          </li>
                        </ul>
                      </div>
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col sm='6'>
              <div className='box-default margin-top-20'>
                <Row className={'margin-bottom-10'}>
                  <Col sm='12'>
                    <h2 className='title-medium-plus text-uppercase text-secondary font-bold settingsClinicHeader'>
                      PREFERÊNCIAS PARA RECIBOS E NOTAS FISCAIS
                      <a
                        href='#'
                        onClick={() => this.props.editEmissionReferencesModal({ data: clinica, showModal: true })}
                      >
                        <Icon className='icon icon-size-small ml-2' image={edit} />
                      </a>
                    </h2>

                    <p className='text-default text-sm text-secondary-d4'>
                      <strong className='text-secondary-d4'>Tipo de Comprovante</strong>{' '}
                      {t(referencia_emissao, 'voucher_type.descricao').safeString}
                    </p>
                    <p className='text-default text-sm text-secondary-d4'>
                      <strong className='text-secondary-d4'>Preferência de Emissão</strong>{' '}
                      {t(referencia_emissao, 'emission_type.descricao').safeString}
                    </p>
                    <IfComponent conditional={t(referencia_emissao, 'voucher_type.id').safeString === 'R'}>
                      <p className='text-default text-sm text-secondary-d4'>
                        <strong className='text-secondary-d4'>Emitente Padrão</strong>{' '}
                        {t(referencia_emissao, 'dentist.nome_completo').safeString}
                      </p>
                      <p className='text-default text-sm text-secondary-d4'>
                        <strong className='text-secondary-d4'>CPF</strong>{' '}
                        <span className={'mr-4'}>{t(referencia_emissao, 'cpf').safeString}</span>
                        <strong className='text-secondary-d4'>
                          CRO{t(referencia_emissao, 'cro_uf').safeString}
                        </strong>{' '}
                        {t(referencia_emissao, 'cro').safeString}
                      </p>
                    </IfComponent>
                    <IfComponent conditional={t(referencia_emissao, 'voucher_type.id').safeString === 'NF'}>
                      <p className='text-default text-sm text-secondary-d4'>
                        <strong className='text-secondary-d4'>Razão Social</strong>{' '}
                        {t(referencia_emissao, 'razao_social').safeString}
                      </p>
                      <p className='text-default text-sm text-secondary-d4'>
                        <strong className='text-secondary-d4'>CNPJ</strong> {t(referencia_emissao, 'cnpj').safeString}
                      </p>
                    </IfComponent>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
        <SettingsClinicPage />
        <SettingsAddressPage />
        <SettingsClinicTimePage />
        <SettingsEmissionReferencesModal updateParent={this.props.findSettings} />
      </div>
    );
  }
}

const mapStateToProps = ({ SettingsReducer }) => {
  let data = SettingsReducer.settings || {};
  return {
    settings: data.all || {}
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setHeader: (data) => dispatch(setHeader(data)),
    showLoader: (data) => dispatch(showLoader()),
    hideLoader: (data) => dispatch(hideLoader()),
    findSettings: (data) => dispatch({ type: SAGA_FIND_CLINIC_SETTINGS_BY_USER }),
    editClinicSettings: (data) => dispatch(editClinicSettings(data)),
    editClinicAddressSettings: (data) => dispatch(editClinicAddressSettings(data)),
    editClinicTimeSettings: (data) => dispatch(editClinicTimeSettings(data)),
    editEmissionReferencesModal: (data) => dispatch(openSettingsEmissionsSaga(data))
  };
};

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withSecurity);

export default enhanced(SettingsClinicHomePage);
