import React from 'react';
import { getPermissions, getUserData } from '../../utils/localStorage/SessionStorageUtils';

let userAccess;
let isMaster;

const getModule = (moduleId) => {
  return userAccess.find((a) => a.modulo_id === moduleId);
};

const getPermission = (module, permissionId) => {
  return module.permissoes.find((p) => p.permissao_id === permissionId);
};

const security = {
  hasModuleAccess: (moduleId) => {
    if (isMaster) return true;

    userAccess = getPermissions();
    return undefined !== getModule(moduleId);
  },
  hasPermission: (moduleId, permissionId) => {
    if (isMaster) return true;

    userAccess = getPermissions();
    let hasPermission = false;
    const module = getModule(moduleId);

    if (module) {
      hasPermission = getPermission(module, permissionId) !== undefined;
    }

    return hasPermission;
  },
  hasRestriction: (moduleId, permissionId, restrictionId) => {
    if (isMaster) return false;

    userAccess = getPermissions();
    let hasRestriction = false;
    const module = getModule(moduleId);
    if (module) {
      const permission = getPermission(module, permissionId);
      if (permission) {
        const restriction = permission.restricoes.find((r) => r.restricao_id === restrictionId);
        if (restriction) {
          hasRestriction = true;
        }
      }
    }
    return hasRestriction;
  }
};

const withSecurity = (WrappedComponent) => {
  userAccess = getPermissions();
  isMaster = getUserData().isMaster;

  const hocSecurity = (props) => {
    return <WrappedComponent security={security} {...props} />;
  };

  hocSecurity.propTypes = {};

  return hocSecurity;
};

export default withSecurity;
