import React, { Component } from 'react';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { Formik } from 'formik';

import MessagesComponent from '../../../../components/Messages.component';
import TreatmentBudgetApi from '../../../../service/TreatmentBudgetApi';

class TreatmentBudgetOtherNotesModal extends Component {
  state = {
    error: {
      display: false,
      type: 'danger',
      message: ''
    }
  };

  otherNotesSave = async ({ anotacoes }) => {
    const { closeModal, budget, showLoader, hideLoader, updateParent } = this.props;

    try {
      showLoader();
      await TreatmentBudgetApi.saveOtherNotes({ budgetId: budget.id, notes: anotacoes });
      updateParent();
      hideLoader();
    } catch (e) {
      console.log(e);
      this.setState({
        error: {
          display: true,
          type: 'danger',
          message: 'Erro ao salvar outras anotações, entre em contato com o suporte'
        }
      });
    }
    closeModal();
  };

  render() {
    const { modal, notes, closeModal } = this.props;
    const { error } = this.state;

    return (
      <Modal isOpen={modal} toggle={closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={closeModal}
          close={
            <button className='close' onClick={closeModal}>
              &times;
            </button>
          }
        >
          <strong>Outras</strong>
          Anotações
        </ModalHeader>
        <Formik
          onSubmit={this.otherNotesSave}
          initialValues={{ anotacoes: notes }}
          validationSchema={this.validationSchema}
          validateOnChange={false}
        >
          {({ errors, handleSubmit, handleBlur, handleChange, values }) => {
            return (
              <form onSubmit={handleSubmit} autoComplete='off'>
                <ModalBody>
                  <MessagesComponent display={error.display} type={error.type} message={error.message} />
                  <Row>
                    <Col sm={12}>
                      <textarea rows='5' cols='50' name='anotacoes' onChange={handleChange} value={values.anotacoes} />
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Row>
                    <Col sm={{ size: 3, offset: 6 }}>
                      <button className='button-primary' type={'submit'}>
                        Salvar
                      </button>
                    </Col>
                    <Col sm='3'>
                      <button className='button-tertiary' type={'button'} onClick={closeModal}>
                        Cancelar
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default TreatmentBudgetOtherNotesModal;
