import React, { Component } from 'react';

import { Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap';

import question from '../../../../assets/img/icons/question.svg';
import Icon from '../../../../components/Icon.component';
import Select from 'react-select';
import { Form, Formik } from 'formik';
import { reactSelectHandleChange, reactSelectStyleIsError } from '../../../../utils/ReactSelectUtils';
import * as Yup from 'yup';
import ErrorFieldWithoutBootstrap from '../../../../components/ErrorFieldWithoutBootstrap.component';
import ErrorField from '../../../../components/ErrorField.component';
import TreatmentClinicalRecordApi from '../../../../service/TreatmentClinicalRecordApi';
import UserAPI from '../../../../service/User.service';
import MessagesComponent from '../../../../components/Messages.component';
import { selectStyles } from '../../../../config/core.config';
import { IfComponent } from '../../../../components/if.component';
import { Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle, Button } from 'reactstrap';

class TreatmentClinicalRecordFinalizeModal extends Component {
  state = {
    message: {
      display: false,
      type: 'danger',
      text: ''
    }
  };

  formValidate = Yup.object().shape({
    tipo_finalizacao: Yup.object().shape({
      value: Yup.string().required('Tipo de Finalização Obrigatório')
    }),
    senha: Yup.string().required('Senha Obrigatória')
  });

  closeModal = () => {
    this.props.closeModal();
    this.setState({
      message: {
        display: false,
        type: 'danger',
        text: ''
      }
    });
  };

  tipoFinalizacaoOption = [
    { value: 'IC', label: 'Iniciar Contenção' },
    { value: 'AF', label: 'Alta Final' },
    { value: 'T', label: 'Transferência' },
    { value: 'I', label: 'Inativar' }
  ];

  finalizeTreatment = async (values) => {
    console.log(values);
    const { treatment, clinicalRecord, showLoader, hideLoader, updateParent } = this.props;
    showLoader();
    UserAPI.checkPassword(values.senha)
      .then(async (data) => {
        if (data) {
          try {
            await TreatmentClinicalRecordApi.finalize({
              treatmentId: treatment.id,
              recordId: clinicalRecord,
              tipo: values.tipo_finalizacao.value,
              anotacoes: values.anotacao
            });

            this.closeModal();
            updateParent();
          } catch (e) {
            this.setState({
              message: {
                display: true,
                type: 'danger',
                text: 'Erro ao finalizar ficha clinica entre em contato com o suporte'
              }
            });
          }
        } else {
          this.setState({
            message: {
              display: true,
              type: 'danger',
              text: 'Senha Inválida'
            }
          });
        }

        hideLoader();
      })
      .catch(() => {
        this.setState({
          message: {
            display: true,
            type: 'danger',
            text: 'Senha Inválida'
          }
        });
        hideLoader();
      });
  };

  render() {
    const { modal } = this.props;
    const { message } = this.state;

    return (
      <Modal isOpen={modal} toggle={this.closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={this.closeModal}
          close={
            <button className='close' onClick={this.closeModal}>
              &times;
            </button>
          }
        >
          <strong>Finalizar</strong> Tratamento
        </ModalHeader>
        <Formik validateOnChange={false} validationSchema={this.formValidate} onSubmit={this.finalizeTreatment}>
          {({ errors, values, handleChange }) => {
            return (
              <Form>
                <ModalBody>
                  <MessagesComponent display={message.display} type={message.type} message={message.text} />
                  <div className='margin-top-10'>
                    <Row className='margin-top-10'>
                      <Col sm='12'>
                        <label>
                          Tipo de Finalização<span>*</span>
                        </label>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={11}>
                        <Select
                          options={this.tipoFinalizacaoOption}
                          value={values.tipo_finalizacao}
                          isSearchable={false}
                          ignoreAccents={true}
                          ignoreCase={true}
                          placeholder={''}
                          styles={reactSelectStyleIsError(errors, 'tipo_finalizacao.value', selectStyles)}
                          onChange={(data) => reactSelectHandleChange(data, handleChange, 'tipo_finalizacao')}
                        />
                        <ErrorFieldWithoutBootstrap errors={errors} fieldName={'tipo_finalizacao.value'} />
                      </Col>
                      <Col sm='1'>
                        <a
                          href='#'
                          onClick={() => {
                            handleChange({
                              target: {
                                name: 'displayTip',
                                value: !values.displayTip
                              }
                            });
                            console.log(values.displayTip);
                          }}
                        >
                          <Icon
                            className='icon icon-size-small absolute-center '
                            image={question}
                            id='tipoFinalizacao'
                            alt='question'
                          />
                        </a>
                      </Col>
                      <Col sm={{ size: 12 }}>
                        <IfComponent conditional={values.displayTip === true}>
                          <div>
                            <Card className={'border text-left'}>
                              <CardBody className={'pb-2'}>
                                <CardSubtitle className={'text-center text-default font-bolder text-secondary-d4 mb-2'}>
                                  <Icon
                                    className='icon icon-size-smaller'
                                    image={question}
                                    id='tipoFinalizacao'
                                    alt='question'
                                  />
                                  <span className={'pt-1'}>Tipos de Finalização</span>
                                </CardSubtitle>
                                <CardText className={'text-default text-sm text-secondary-d4 mb-2'}>
                                  <strong className={'text-secondary-d4'}>Contenção</strong>- Encerra o Tratamento ativo
                                  e inicia a Fase de Contenção. Mensalidades param de ser lançadas automaticamente a
                                  cada mês.
                                </CardText>
                                <CardText className={'text-default text-sm text-secondary-d4 mb-2'}>
                                  <strong className={'text-secondary-d4'}>Alta final</strong>- Paciente ingressa na
                                  situação "Pós-Contenção/Alta".
                                </CardText>
                                <CardText className={'text-default text-sm text-secondary-d4 mb-2'}>
                                  <strong className={'text-secondary-d4'}>Transferência</strong>- Finalização em razão
                                  da Transferência do paciente para outro profissional.
                                </CardText>
                                <CardText className={'text-default text-sm text-secondary-d4 mb-2'}>
                                  <strong className={'text-secondary-d4'}>Inativar</strong>- Tratamento encerrado, sem a
                                  devida conclusão. Paciente ingresso na Situação "Inativado". Desistência e Abandono
                                  são as causas mais comuns.
                                </CardText>
                              </CardBody>
                            </Card>
                          </div>
                        </IfComponent>
                      </Col>
                    </Row>
                    <Row className='margin-top-10'>
                      <Col sm='12'>
                        <form>
                          <label>Anotação</label>
                          <textarea
                            className={'form-control'}
                            rows='2'
                            cols='50'
                            onChange={handleChange}
                            name={'anotacao'}
                            value={values.anotacao}
                          ></textarea>
                        </form>
                      </Col>
                    </Row>

                    <Row className='margin-top-10'>
                      <Col className={'input-float'} sm={{ size: 6, offset: 3 }}>
                        <Input
                          className='input-secondary'
                          type='password'
                          name='senha'
                          placeholder='Senha'
                          value={values.senha}
                          onChange={handleChange}
                          invalid={errors.senha ? true : false}
                        ></Input>
                        <label htmlFor='senha'>Senha</label>
                        <ErrorField fieldName={'senha'} errors={errors} />
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Row>
                    <Col sm={{ size: 6, offset: 6 }} className={'btn-list'}>
                      <button className='button-primary'>Finalizar</button>
                      <button className='button-tertiary' type={'button'} onClick={this.closeModal}>
                        Cancelar
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default TreatmentClinicalRecordFinalizeModal;
