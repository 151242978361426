import { takeEvery } from 'redux-saga/effects';

import {
  TREATMENT_EVALUATION_OPEN_PRE_MODAL_ATTENDENCE_REGISTER,
  TREATMENT_EVALUATION_PRE_OPEN_MODAL_ANGLE_RATING,
  TREATMENT_EVALUATION_PRE_OPEN_MODAL_COMPLAINT,
  TREATMENT_EVALUATION_PRE_OPEN_MODAL_DOCS,
  TREATMENT_EVALUATION_PRE_OPEN_MODAL_RECEPTION_AND_REGISTER,
  TREATMENT_EVALUATION_PRE_OPEN_MODAL_RESULT
} from '../redux/TreatmentEvaluation.action';

import { modalEvaluationComplaint } from './TreatmentEvaluationComplaint.saga';
import { modalEvaluationReceptionRegister } from './TreatmentoEvaluationReceptionRegister.saga';
import { modalAngleRating } from './TreatmentEvaluationAngleRating.saga';
import { modalEvaluationResult } from './TreatmentEvaluationResult.saga';
import { modalEvaluationOrthoDocs } from './TreatmentEvaluationOrthoDocs.saga';
import { modalAttendenceregister } from './TreatmentEvaluationAttendence.saga';

export function* watchTreatmentEvaluationSagas() {
  yield takeEvery(TREATMENT_EVALUATION_PRE_OPEN_MODAL_RECEPTION_AND_REGISTER, modalEvaluationReceptionRegister);
  yield takeEvery(TREATMENT_EVALUATION_PRE_OPEN_MODAL_COMPLAINT, modalEvaluationComplaint);
  yield takeEvery(TREATMENT_EVALUATION_PRE_OPEN_MODAL_ANGLE_RATING, modalAngleRating);
  yield takeEvery(TREATMENT_EVALUATION_PRE_OPEN_MODAL_RESULT, modalEvaluationResult);
  yield takeEvery(TREATMENT_EVALUATION_PRE_OPEN_MODAL_DOCS, modalEvaluationOrthoDocs);
  yield takeEvery(TREATMENT_EVALUATION_OPEN_PRE_MODAL_ATTENDENCE_REGISTER, modalAttendenceregister);
}
