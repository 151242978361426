import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import t from 'typy';
import userDefaultAvatar from '../../assets/img/noavatar.png';
import StringUtils from '../../utils/StringUtils';
import scheduleConfirmedIcon from '../../assets/img/confirmada.png';
import scheduleMissedIcon from '../../assets/img/falta.png';
import { FaArrowRight } from 'react-icons/fa/index';
import ReactTooltip from 'react-tooltip';
import FlowApi from '../../service/FlowApi';
import { IfComponent } from '../../components/if.component';
import moment from 'moment';

const CardFlowSchedules = ({ fluxo, callback, selectedDate }) => {
  async function startFlow(schedule) {
    await FlowApi.startFlow(schedule.agendamentoId);
    callback();
  }
  return (
    <div className='row'>
      <div className='col-sm-12'>
        <div className='mb-1'>
          <div className='card-body pt-2 pb-0 pl-0 pr-0' style={{ maxHeight: '77px' }}>
            <div className='row'>
              <div className='col-sm-12'>
                <div className='row'>
                  <div className='col-sm-2'>
                    <img
                      className='img-circle img-ldpi img-user-avatar'
                      style={{ minWidth: '55px', minHeight: '55px' }}
                      src={
                        t(fluxo, 'pacienteFoto').isDefined ? t(fluxo, 'pacienteFoto').safeString : userDefaultAvatar
                      }
                      alt='avatar'
                    />
                  </div>
                  <div className='col-sm-8 p-1 px-1'>
                    <div className='row p-0'>
                      <div className='px-3'>
                        <p className='text-sm font-bold text-secondary'>{t(fluxo, 'pacienteNome').safeString}</p>
                      </div>
                    </div>
                    <div className='row px-3 py-1'>
                      <ReactTooltip id='tooltipProcedimento' className='tooltip-griks' type='light'>
                        {t(fluxo, 'procedimentoNome').safeString}
                      </ReactTooltip>
                      <span
                        data-tip
                        data-for={'tooltipProcedimento'}
                        className='procedure badge-proc align-self-center px-2'
                        style={{
                          backgroundColor: t(fluxo, 'procedimentoCategoriaRGB').safeString,
                          fontSize: '10px'
                        }}
                      >
                        {t(fluxo, 'procedimentoCodiNome').safeString}
                      </span>
                      <ReactTooltip id='tooltipDentista' className='tooltip-griks' type='light'>
                        {fluxo.dentistaNome}
                      </ReactTooltip>
                      <a
                        data-tip
                        data-for={'tooltipDentista'}
                        href={'#'}
                        className='receipt-03'
                        style={{
                          fontSize: '10px',
                          backgroundColor: '#ebecf2',
                          border: '1px solid #ebecf2',
                          textDecoration: 'none',
                          cursor: 'default'
                        }}
                      >
                        {StringUtils.abbreviateString(fluxo.dentistaNome)}
                      </a>

                      <ReactTooltip id='tooltipCadeira' className='tooltip-griks' type='light'>
                        {fluxo.cadeira}
                      </ReactTooltip>
                      <a
                        data-tip
                        data-for={'tooltipCadeira'}
                        href={'#'}
                        className='receipt-03'
                        style={{
                          fontSize: '10px',
                          backgroundColor: '#ebecf2',
                          border: '1px solid #ebecf2',
                          textDecoration: 'none',
                          cursor: 'default'
                        }}
                      >
                        {StringUtils.abbreviateString(fluxo.cadeira)}
                      </a>
                      <span>
                        <IfComponent conditional={fluxo.statusConsulta === 'Confirmada'}>
                          <ReactTooltip id='tooltipConfirmada' className='tooltip-griks' type='light'>
                            Confirmada
                          </ReactTooltip>
                          <a data-tip data-for={'tooltipConfirmada'} href={'#'} style={{ cursor: 'default' }}>
                            <img src={scheduleConfirmedIcon} className='icon icon-size-small ml-1' />
                          </a>
                        </IfComponent>

                        <IfComponent conditional={fluxo.statusConsulta === 'Falta'}>
                          <ReactTooltip id='tooltipFalta' className='tooltip-griks' type='light'>
                            Falta
                          </ReactTooltip>
                          <a data-tip data-for={'tooltipFalta'} href={'#'} style={{ cursor: 'default' }}>
                            <img src={scheduleMissedIcon} className='icon icon-size-small ml-1' />
                          </a>
                        </IfComponent>
                      </span>
                    </div>
                  </div>
                  <div className='col-sm-2 p-0 d-flex flex-column justify-content-center align-items-center card-divider'>
                    <div className='row'>
                      <strong className='text-md-plus'>{t(fluxo, 'horario_de').safeString}</strong>
                    </div>
                    <div className='row'>
                      <IfComponent conditional={moment().isSame(selectedDate, 'day')}>
                        <IfComponent conditional={fluxo.statusConsulta !== 'Falta'}>
                          <a className='mr-2' href={'#'} onClick={() => startFlow(fluxo)}>
                            <FaArrowRight className='cursor-pointer icon-effect-r' size='20px' />
                          </a>
                        </IfComponent>
                      </IfComponent>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardFlowSchedules;
