export const SAGA_SETTINGS_SCHEDULE_CHAIRS_MODAL = 'SAGA_SETTINGS_SCHEDULE_CHAIRS_MODAL';
export const SETTINGS_SCHEDULE_CHAIRS_MODAL = 'SETTINGS_SCHEDULE_CHAIRS_MODAL';
export const SETTINGS_SCHEDULE_STANDARD_INTERVAL_MODAL = 'SETTINGS_SCHEDULE_STANDARD_INTERVAL_MODAL';
export const SAGA_SETTINGS_SCHEDULE_STANDARD_PROCEDURE_MODAL = 'SAGA_SETTINGS_SCHEDULE_STANDARD_PROCEDURE_MODAL';
export const SETTINGS_SCHEDULE_STANDARD_PROCEDURE_MODAL = 'SETTINGS_SCHEDULE_STANDARD_PROCEDURE_MODAL';
export const SETTINGS_SCHEDULE_OFFICE_HOURS_MODAL = 'SETTINGS_SCHEDULE_OFFICE_HOURS_MODAL';

export function chairsModalSaga({ modal, dentist, initForm }) {
  return {
    type: SAGA_SETTINGS_SCHEDULE_CHAIRS_MODAL,
    payload: {
      modal,
      dentist,
      initForm
    }
  };
}

export function chairsModal({ modal, initForm, dataForm }) {
  return {
    type: SETTINGS_SCHEDULE_CHAIRS_MODAL,
    payload: {
      modal,
      initForm,
      dataForm
    }
  };
}

export function standardIntervalModal({ modal, initForm = {} }) {
  const standardInterval = initForm.intervalo_padrao_tipo && initForm.intervalo_padrao_tipo.substr(0, 1);

  return {
    type: SETTINGS_SCHEDULE_STANDARD_INTERVAL_MODAL,
    payload: {
      modal,
      initForm: {
        ...initForm,
        ['intervalo_padrao_tipo']: standardInterval
      }
    }
  };
}

export function standardProcedureModalSaga({ configs, dentist, initForm }) {
  return {
    type: SAGA_SETTINGS_SCHEDULE_STANDARD_PROCEDURE_MODAL,
    payload: {
      dentist,
      initForm
    }
  };
}

export function standardProcedureModal({ modal, dentist, initForm, dataForm }) {
  return {
    type: SETTINGS_SCHEDULE_STANDARD_PROCEDURE_MODAL,
    payload: {
      modal,
      dentist,
      initForm,
      dataForm
    }
  };
}

export function officeHour({ modal, dentist, initForm }) {
  return {
    type: SETTINGS_SCHEDULE_OFFICE_HOURS_MODAL,
    payload: {
      modal,
      dentist,
      initForm
    }
  };
}
