import React, { Component } from 'react';

import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import DomainsAPI from '../../../service/DomainsAPI';

import UserAPI from '../../../service/User.service';
import { Formik } from 'formik';
import UserSettingsFormRegisterCadastroForm from './UserSettingsFormRegisterCadastro.form';
import UserSettingsFormRegisterPermissoesForm from './UserSettingsFormRegisterPermissoes.form';

import { modalSuccess, settingsRegisterUserModalForm, usersFindAllSaga } from './redux/UserSettings.actions';

import { connect } from 'react-redux';
import { compose } from 'recompose';

import MessagesComponent from '../../../components/Messages.component';
import { t } from 'typy';
import { values } from 'lodash';

class UserSettingsFormRegisterModal extends Component {
  state = {
    isChange: false,
    modal: true,
    checked: false,
    profiles: [],
    tab: 'cadastro',
    usuarioId: null,
    errorMessage: {
      display: false,
      type: 'danger',
      message: ''
    },
    form: {
      register: null
    }
  };

  componentDidMount = async () => {
    try {
      const profiles = await DomainsAPI.findAllUsersProfiles();
      const dentists = await UserAPI.findAllDentistas();

      this.setState({
        profiles: profiles.data,
        dentists
      });
    } catch (e) {
      console.log(e);
    }
  };

  saveData = async (values) => {
    const { showLoader, hideLoader, usersFindAllSaga, formData } = this.props;

    //values são os valores da api
    console.log('values',values)
    console.log('formData',formData)

    if (formData.initForm.usuario_id) {
      values.id = formData.initForm.usuario_id;
      this.setState({ usuarioId: formData.initForm.usuario_id });
    }

    if (this.state.tab === 'cadastro') {
      if (this.validateFormCadastro(values)) {
        try {
          showLoader();

          //está ocorrendo algum erro no post do back !!!!!!!!
          const userSaved = await UserAPI.saveUser(values);
          console.log('userSavedaaaaaaaaaaaaaaaaaa',userSaved)
          const usuarioId = userSaved.data.usuario_id;
          this.setState({ usuarioId });
          hideLoader();

          this.setState({
            tab: 'permissoes'
          });
        } catch (error) {
          hideLoader();
          const { response = {} } = error;
          const { data = {} } = response;


          const messageError = data.message || '';

          console.log(data);

          let message = 'Erro ao salvar usuário';

          console.log('messageError',messageError)
          if (messageError.includes('UC_USERNAME')) {
            message = 'E-mail já cadastrado por favor selecione outro';
          }

          if (messageError.includes('Já existe um usuário Dentista Master')) {
            message = messageError;
          }

          this.setState({
            errorMessage: {
              display: true,
              type: 'danger',
              message
            }
          });
        }
      }
    } else {
      try {
        const { permissions = [] } = values;
        let acessos = [];

        Object.values(permissions).forEach((p) => {
          let restricoes = [];

          if (p.hasRestrictions) {
            if (!p.restrictions) {
              throw Error('restricaoObrigatoria');
            }

            Object.values(p.restrictions).forEach((r) => {
              if (r.restriction_id) {
                restricoes.push({
                  id: r.restriction_id
                });
              }
            });
          }

          if (p.permission_id) {
            acessos.push({
              modulo_id: p.module_id,
              permissoes: [
                {
                  id: p.permission_id,
                  restricoes
                }
              ]
            });
          }
        });

        const requestPermissions = {
          usuario_id: this.state.usuarioId,
          acessos
        };

        showLoader();
        await UserAPI.savePermissions(requestPermissions);

        usersFindAllSaga({ currentPage: 1, pageSize: 10 });
        const profiles = await DomainsAPI.findAllUsersProfiles();

        console.log(values);

        let user = {};

        if ([1, 2].includes(values.perfil_id)) {
          const userData = await UserAPI.findById(this.state.usuarioId);
          user = userData.data;
        }

        this.props.showModalSuccess({
          modal: true,
          data: {
            usuario_cadastro: values.usuario_cadastro,
            nome: values.nome,
            perfil: {
              id: values.perfil_id,
              nome:
                values.perfil_id == 1
                  ? 'Administrador Master'
                  : profiles.data.find((p) => p.id === parseInt(values.perfil_id)).nome
            },
            status: values.status,
            user
          }
        });
        this.closeModal();
        hideLoader();
      } catch (e) {
        console.log(e);
        hideLoader();
        let msg = 'Erro ao salvar permissoes de usuário';
        if (e.message === 'restricaoObrigatoria') {
          msg = 'Restrição Obrigatória';
        }

        this.setState({
          errorMessage: {
            display: true,
            type: 'danger',
            message: msg
          }
        });
      }
    }
  };

  closeModal = () => {
    this.clearState();
    this.props.closeModal();
  };

  clearState = () => {
    this.setState({
      isChange: false,
      modal: true,
      checked: false,
      tab: 'cadastro',
      usuarioId: null,
      errorMessage: {
        display: false,
        type: 'danger',
        message: ''
      },
      form: {
        register: null
      }
    });
  };

  validateFormCadastro(values) {
    let errors = [];
    const { nome, usuario_cadastro, login, perfil_id, dentista_acessos } = values;

    if (!perfil_id) {
      errors.push('Categoria/Perfil Obrigatório');
    }

    if (!nome) {
      errors.push('Nome Obrigatório');
    }

    if (!usuario_cadastro) {
      errors.push('Nome de Usuário Obrigatório');
    }

    // if (![1,2].includes(perfil_id) && !dentista_acessos) {
    //   errors.push('Selecione um dentista para acesso');
    // }

    if (!login) {
      errors.push('E-mail Obrigatório');
    } else {
      const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!regex.test(login)) {
        errors.push('E-mail Inválido');
      }
    }

    if (errors.length > 0) {
      let message = 'Por favor preencha corretamente o os campos: <br /> <lu>';
      errors.forEach((e) => (message += `<li>${e}</li>`));
      message += '</ul>';

      this.setState({
        errorMessage: {
          display: true,
          type: 'danger',
          message
        }
      });
      return false;
    } else {
      this.setState({
        errorMessage: {
          display: false,
          type: 'danger',
          message: ''
        }
      });
      return true;
    }
  }

  changeTab = (type) => {
    if (this.state.form.register || this.props.formData.initForm.usuario_id) {
      this.setState({
        tab: type
      });
    }
  };

  handleChange = (checked) => {
    this.setState({ checked });
  };

  render() {
    const { usuarioId, profiles = [], dentists = [], tab, errorMessage } = this.state;
    const { display, type, message } = errorMessage;
    const { formData = {}, modal = false } = this.props;

    return (
      <Modal isOpen={modal} toggle={this.closeModal} size={'default'} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={this.closeModal}
          close={
            <button className='close' onClick={this.closeModal}>
              &times;
            </button>
          }
        >
          <strong>
            {t(formData.initForm, 'usuario_id').isDefined ? 'Editar ' : 'Adicionar '}
            Usuário
          </strong>
        </ModalHeader>
        <Formik initialValues={formData.initForm} validateOnChange={false} onSubmit={this.saveData}>
          {({ errors, handleSubmit, handleChange, handleBlur, values }) => (
            <form onSubmit={handleSubmit}>
              {console.log('values',values)}
              <ModalBody className='pt-0'>
                <div>
                  <Row>
                    <Col sm='12'>
                      <nav className='nav-inline border-bottom-0'>
                        <ul>
                          <li className={tab === 'cadastro' ? 'active' : ''}>
                            <a href='#' onClick={() => this.changeTab('cadastro')}>
                              Cadastro
                            </a>
                          </li>
                          <li className={tab === 'permissoes' ? 'active' : ''}>
                            <a href='#' onClick={() => this.changeTab('permissoes')}>
                              Permissões
                            </a>
                          </li>
                        </ul>
                      </nav>
                      <hr style={{ marginTop: -19, marginBottom: 8 }}></hr>
                    </Col>
                  </Row>
                  <MessagesComponent display={display} type={type} message={message} />

                  <UserSettingsFormRegisterCadastroForm
                    profiles={profiles}
                    dentistas={dentists}
                    display={tab === 'cadastro'}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    values={values}
                    handleBlur={handleBlur}
                  />
                  <UserSettingsFormRegisterPermissoesForm
                    display={tab === 'permissoes'}
                    profiles={profiles}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    values={values}
                    usuarioId={usuarioId}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <Row>
                  {tab === 'permissoes' && (
                    <Col sm={'3'}>
                      <button className='button-tertiary' type={'button'} onClick={() => this.changeTab('cadastro')}>
                        Voltar
                      </button>
                    </Col>
                  )}
                  <Col className='btn-list' sm={{ size: 6, offset: tab === 'cadastro' ? 6 : 3 }}>
                    <button className='button-primary' type='submit'>
                      {tab === 'cadastro' ? 'Próximo' : 'Salvar'}
                    </button>
                    <button className='button-tertiary' onClick={this.closeModal} type={'button'}>
                      Cancelar
                    </button>
                  </Col>
                </Row>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = ({ UserSettingsReducer }) => {
  const { formData, modal } = UserSettingsReducer;
  let initForm = {};
  if (formData) {
    initForm = {
      usuario_id: formData.id,
      clinicas_acesso: [
        {
          id: formData.clinicas[0].id
        }
      ],
      cpf: formData.cpf,
      cro: formData.cro,
      cro_uf: formData.cro_uf,
      dentista_acessos: formData.dentista_acessos,
      login: formData.login,
      nome: formData.nome,
      telefone_one: formData.telefone_one,
      nome_telefone_one: formData.nome_telefone_one,
      telefone_one_whatsapp: formData.telefone_one_whatsapp,
      telefone_two: formData.telefone_two,
      nome_telefone_two: formData.nome_telefone_two,
      telefone_two_whatsapp: formData.telefone_two_whatsapp,
      perfil_id: formData.perfil.id,
      rg: formData.rg,
      rg_emissor: formData.rg_emissor,
      tipo_horario_acesso: formData.tipo_horario_acesso,
      troca_senha: formData.troca_senha,
      status: formData.status,
      usuario_cadastro: formData.usuario_cadastro,
      acessos: formData.acessos
    };
  } else {
    initForm.tipo_horario_acesso = 'E';
  }

  return {
    formData: {
      initForm
    },
    modal
  };
};

const mapDispathToProps = (dispatch) => {
  return {
    closeModal: (data) => dispatch(settingsRegisterUserModalForm({ modal: false })),
    usersFindAllSaga: (data) => dispatch(usersFindAllSaga(data)),
    showModalSuccess: (data) => dispatch(modalSuccess(data))
  };
};

const enhanced = compose(connect(mapStateToProps, mapDispathToProps));

export default enhanced(UserSettingsFormRegisterModal);
