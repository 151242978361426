import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, FormFeedback } from 'reactstrap';
import { IfComponent } from './if.component';
import MaskedInput from 'react-text-mask';
import ErrorFieldWithoutBootstrap from './ErrorFieldWithoutBootstrap.component';

class InputComponent extends Component {
  render() {
    const { id, type, name, handler, errors = {}, values = {}, value, mask = null, invalid = false } = this.props;
    return [
      <span key={name + '_span'} style={{}}>
        <IfComponent conditional={mask}>
          <MaskedInput
            key={name}
            type={type}
            name={name}
            onChange={handler}
            invalid={invalid || errors[name] ? true : false}
            defaultValue={!value ? values[name] : value}
            value={!value ? values[name] : value}
            mask={mask}
            className={
              invalid || errors[name] ? 'is-invalid form-control-sm form-control' : 'form-control-sm form-control'
            }
            {...this.props}
          >
            {this.props.children}
          </MaskedInput>
        </IfComponent>
        <IfComponent conditional={!mask}>
          <Input
            key={name}
            type={type}
            name={name}
            onChange={handler}
            size={'sm'}
            invalid={invalid || errors[name] ? true : false}
            defaultValue={!value ? values[name] : value}
            id={id}
            value={!value ? values[name] : value}
            {...this.props}
          >
            {this.props.children}
          </Input>
        </IfComponent>
        <ErrorFieldWithoutBootstrap fieldName={name} errors={errors} key={name + '_error'} />
      </span>
    ];
  }
}

InputComponent.propTypes = {
  value: PropTypes.object,
  values: PropTypes.array,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
  errors: PropTypes.array,
  mask: PropTypes.string,
  invalid: PropTypes.bool
};

export default InputComponent;
