import React, { Component } from 'react';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import PropTypes from 'prop-types';

const ModalConfirmNegotiation = (props) => {
  const { openModal, closeModal, confirmCallback } = props;

  return (
    <Modal isOpen={openModal} toggle={closeModal} size='small' className={' patientMessage'}>
      <ModalHeader
        className='title-primary'
        toggle={closeModal}
        close={
          <button className='close' onClick={closeModal}>
            &times;
          </button>
        }
      >
        Fechar a Negociação
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col className='text-default text-secondary' sm='12'>
            Ao Fechar a Negociação, o sistema irá salvar todos os valores e automaticamente lançará na Ficha de
            Pagamentos, a cada mês.
            <br />
            <br />
            Por padrão,
            <strong className='text-secondary'>
              os Débitos são lançados na Ficha de Pagamento no dia 1&#170; de seu mês de vencimento
            </strong>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Row className='mt-2'>
          <Col className='btn-list' sm={{ size: 3 }}>
            <button className='button-tertiary' onClick={closeModal}>
              Cancelar
            </button>
          </Col>
          <Col className='btn-list' sm={{ size: 5, offset: 4 }}>
            <button className='button-primary' onClick={confirmCallback}>
              Fechar a Negociação
            </button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

ModalConfirmNegotiation.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  confirmCallback: PropTypes.func.isRequired
};

export default ModalConfirmNegotiation;
