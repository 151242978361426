import React from 'react';
import PrivateRouter from '../components/PrivateRoute.component';
import VoucherReportsPage from '../pages/reports/VoucherReportsPage';
import PatientsReportsPage from '../pages/reports/patients/PatientsReportsPage';
import ReportsPage from '../pages/reports/ReportsPage';
import { Route } from 'react-router';
import FinancialOverviewReportPrintPage from '../pages/reports/financial/FinancialOverviewReportPrintPage';

const ReportsRouters = (props) => {
  return [
    <PrivateRouter key='PatientReportsPage' exact path='/reports' render={(props) => <ReportsPage {...props} />} />
  ];
};

export default ReportsRouters;
