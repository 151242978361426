import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/Griks.css';

import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';

import { store } from './config/redux/redux.config';
import AppRouting from './routers/app.router';
import { localeConfig } from './config/core.config';

localeConfig();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <div>
        <Switch>
          <AppRouting />
        </Switch>
      </div>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
