import t from 'typy';
import { createSelector } from 'reselect';

const appointmentRedialModalSelector = (state) => t(state, 'ScheduleReducer.appointmentRedialModal').safeObject;

export const appointmentRedialModalReducerToProps = createSelector(
  appointmentRedialModalSelector,
  ({ modal, schedule } = {}) => {
    return {
      modal,
      schedule
    };
  }
);
