export const TREATMENT_BUDGET_CREATE_MODAL = 'TREATMENT_BUDGET_CREATE_MODAL';
export const TREATMENT_BUDGET_PAGE = 'TREATMENT_BUDGET_PAGE';
export const TREATMENT_BUDGET_PRE_MODAL_INFORMATIONS = 'TREATMENT_BUDGET_PRE_MODAL_INFORMATIONS';
export const TREATMENT_BUDGET_MODAL_INFORMATIONS = 'TREATMENT_BUDGET_MODAL_INFORMATIONS';
export const TREATMENT_BUDGET_PRE_MODAL_TREATMENT_PLAN = 'TREATMENT_BUDGET_PRE_MODAL_TREATMENT_PLAN';
export const TREATMENT_BUDGET_MODAL_TREATMENT_PLAN = 'TREATMENT_BUDGET_MODAL_TREATMENT_PLAN';
export const TREATMENT_BUDGET_MODAL_PRE_PAYMENT_MANTENANCE = 'TREATMENT_BUDGET_MODAL_PRE_PAYMENT_MANTENANCE';
export const TREATMENT_BUDGET_MODAL_PAYMENT_MANTENANCE = 'TREATMENT_BUDGET_MODAL_PAYMENT_MANTENANCE';
export const TREATMENT_BUDGET_MODAL_PAYMENT_CLOSED_PRICE = 'TREATMENT_BUDGET_MODAL_PAYMENT_CLOSED_PRICE';

export function budgetCreateModal({ modal, formLoad }) {
  return {
    type: TREATMENT_BUDGET_CREATE_MODAL,
    payload: {
      modal,
      formLoad
    }
  };
}

export function budgetPageModal({ show, formLoad }) {
  return {
    type: TREATMENT_BUDGET_PAGE,
    payload: {
      show,
      formLoad
    }
  };
}

export function budgetPreModalInformations({ modal, formLoad }) {
  return {
    type: TREATMENT_BUDGET_PRE_MODAL_INFORMATIONS,
    payload: {
      modal,
      formLoad
    }
  };
}

export function budgetModalInformations({ modal, formLoad }) {
  return {
    type: TREATMENT_BUDGET_MODAL_INFORMATIONS,
    payload: {
      modal,
      formLoad
    }
  };
}

export function budgetPreModalTreatmentPlan({ modal, formLoad }) {
  return {
    type: TREATMENT_BUDGET_PRE_MODAL_TREATMENT_PLAN,
    payload: {
      modal,
      formLoad
    }
  };
}

export function budgetModalTreatmentPlan({ modal, formLoad }) {
  return {
    type: TREATMENT_BUDGET_MODAL_TREATMENT_PLAN,
    payload: {
      modal,
      formLoad
    }
  };
}
export function budgetPreModalTreatmentPaymentMantenanceModal({ modal, formLoad }) {
  return {
    type: TREATMENT_BUDGET_MODAL_PRE_PAYMENT_MANTENANCE,
    payload: {
      modal,
      formLoad
    }
  };
}

export function budgetModalTreatmentPaymentMantenanceModal({ modal, formLoad }) {
  return {
    type: TREATMENT_BUDGET_MODAL_PAYMENT_MANTENANCE,
    payload: {
      modal,
      formLoad
    }
  };
}
export function budgetModalTreatmentPaymentClosedPrice({ modal, formLoad }) {
  return {
    type: TREATMENT_BUDGET_MODAL_PAYMENT_CLOSED_PRICE,
    payload: {
      modal,
      formLoad
    }
  };
}
