export const SCHEDULE_SAGA_PAGE = 'SCHEDULE_SAGA_PAGE';
export const SCHEDULE_PAGE = 'SCHEDULE_PAGE';
export const SCHEDULE_EVENT_MODAL = 'SCHEDULE_EVENT_MODAL';
export const SCHEDULE_SCHEDULE_APPOINTMENT_MODAL = 'SCHEDULE_SCHEDULE_APPOINTMENT_MODAL';
export const SCHEDULE_SCHEDULE_APPOINTMENT_SELECT_TREATMENT_SAGA_MODAL =
  'SCHEDULE_SCHEDULE_APPOINTMENT_SELECT_TREATMENT_SAGA_MODAL';
export const SCHEDULE_SCHEDULE_APPOINTMENT_SELECT_TREATMENT_MODAL =
  'SCHEDULE_SCHEDULE_APPOINTMENT_SELECT_TREATMENT_MODAL';
export const SCHEDULE_SCHEDULE_APPOINTMENT_PATIENT_SAGA_MODAL = 'SCHEDULE_SCHEDULE_APPOINTMENT_PATIENT_SAGA_MODAL';
export const SCHEDULE_SCHEDULE_APPOINTMENT_PATIENT_MODAL = 'SCHEDULE_SCHEDULE_APPOINTMENT_PATIENT_MODAL';

export const SCHEDULE_APPOINTMENT_DETAIL_SAGA_MODAL = 'SCHEDULE_APPOINTMENT_DETAIL_SAGA_MODAL';
export const SCHEDULE_APPOINTMENT_DETAIL_MODAL = 'SCHEDULE_APPOINTMENT_DETAIL_MODAL';

export const APPOINTMENT_REDIAL_MODAL = 'APPOINTMENT_REDIAL_MODAL';
export const APPOINTMENT_REDIAL_CALENDAR = 'APPOINTMENT_REDIAL_CALENDAR';

export const UNCHECK_APPOINTMENT_SAGA_MODAL = 'UNCHECK_APPOINTMENT_SAGA_MODAL';
export const UNCHECK_APPOINTMENT_MODAL = 'UNCHECK_APPOINTMENT_MODAL';

export const APPOINTMENT_MISSING_MODAL = 'APPOINTMENT_MISSING_MODAL';
export const APPOINTMENT_DELETE_MODAL = 'APPOINTMENT_DELETE_MODAL';
export const SCHEDULE_NEXT_APPOINTMENT = 'SCHEDULE_NEXT_APPOINTMENT';

export const SEND_TO_APPOINTMENT_AND_REDIAL_SAGA = 'SEND_TO_APPOINTMENT_AND_REDIAL_SAGA';
export const SEND_TO_APPOINTMENT_AND_REDIAL = 'SEND_TO_APPOINTMENT_AND_REDIAL';

export const APPOINTMENT_HISTORY_SAGA_MODAL = 'APPOINTMENT_HISTORY_SAGA_MODAL';
export const APPOINTMENT_HISTORY_MODAL = 'APPOINTMENT_HISTORY_MODAL';

export const APPOINTMENT_MESSAGE_MODAL = 'APPOINTMENT_MESSAGE_MODAL';

export const SCHEDULE_AND_REDIAL_SAGA_PAGE = 'SCHEDULE_AND_REDIAL_SAGA_PAGE';
export const SCHEDULE_AND_REDIAL_PAGE = 'SCHEDULE_AND_REDIAL_PAGE';

export const SCHEDULE_FROM_PATIENT = 'SCHEDULE_FROM_PATIENT';

export const SCHEDULE_AND_REDIAL_ADD_SAGA_MODAL = 'SCHEDULE_AND_REDIAL_ADD_SAGA_MODAL';
export const SCHEDULE_AND_REDIAL_ADD_MODAL = 'SCHEDULE_AND_REDIAL_ADD_MODAL';

export const SCHEDULE_ORTHODONTICSFREQUENCY_SAGA_PAGE = 'SCHEDULE_ORTHODONTICSFREQUENCY_SAGA_PAGE';
export const SCHEDULE_ORTHODONTICSFREQUENCY_PAGE = 'SCHEDULE_ORTHODONTICSFREQUENCY_PAGE';

export function schedulePageSaga({ dentist, day }) {
  return {
    type: SCHEDULE_SAGA_PAGE,
    payload: {
      dentist,
      day
    }
  };
}

export function schedulePage({ dentist, config, officeHours, chairs, schedules, day }) {
  return {
    type: SCHEDULE_PAGE,
    payload: {
      dentist,
      config,
      officeHours,
      schedules,
      day,
      chairs
    }
  };
}

export function scheduleEventModal({ modal, dentist, chair, date, event }) {
  return {
    type: SCHEDULE_EVENT_MODAL,
    payload: {
      date,
      modal,
      dentist,
      chair,
      event
    }
  };
}

export function scheduleAppointmentModal({ modal, dentist, chair, date, start, end }) {
  return {
    type: SCHEDULE_SCHEDULE_APPOINTMENT_MODAL,
    payload: {
      date,
      modal,
      dentist,
      chair,
      start,
      end
    }
  };
}

export function scheduleAppointmentSelectTreatmentSagaModal({
  modal,
  dentist,
  chair,
  date,
  start,
  end,
  patient,
  procedure,
  controleRetorno
}) {
  return {
    type: SCHEDULE_SCHEDULE_APPOINTMENT_SELECT_TREATMENT_SAGA_MODAL,
    payload: {
      date,
      modal,
      dentist,
      chair,
      start,
      end,
      patient,
      procedure,
      controleRetorno
    }
  };
}

export function scheduleAppointmentSelectTreatmentModal({
  modal,
  dentist,
  chair,
  date,
  start,
  end,
  patient,
  treatments,
  procedure,
  controleRetorno
}) {
  return {
    type: SCHEDULE_SCHEDULE_APPOINTMENT_SELECT_TREATMENT_MODAL,
    payload: {
      date,
      modal,
      dentist,
      chair,
      start,
      end,
      patient,
      treatments,
      procedure,
      controleRetorno
    }
  };
}

export function scheduleAppointmentPatientSagaModal({
  modal,
  dentist,
  chair,
  date,
  start,
  end,
  patient,
  treatment,
  schedule,
  redialBy,
  onSave,
  procedure,
  controleRetorno
}) {
  return {
    type: SCHEDULE_SCHEDULE_APPOINTMENT_PATIENT_SAGA_MODAL,
    payload: {
      date,
      modal,
      dentist,
      chair,
      start,
      end,
      patient,
      treatment,
      schedule,
      redialBy,
      onSave,
      procedure,
      controleRetorno
    }
  };
}

export function scheduleAppointmentPatientModal({
  modal,
  dentist,
  chair,
  date,
  start,
  end,
  patient,
  treatment,
  nextSchedule,
  scheduleConfig,
  categories,
  procedures,
  schedule,
  redialBy,
  onSave,
  procedure,
  controleRetorno
}) {
  return {
    type: SCHEDULE_SCHEDULE_APPOINTMENT_PATIENT_MODAL,
    payload: {
      date,
      modal,
      dentist,
      chair,
      start,
      end,
      patient,
      treatment,
      nextSchedule,
      scheduleConfig,
      categories,
      procedures,
      schedule,
      redialBy,
      onSave,
      procedure,
      controleRetorno
    }
  };
}

export function scheduleAppointmentDetailSagaModal({ modal, schedule, officeHours, config }) {
  return {
    type: SCHEDULE_APPOINTMENT_DETAIL_SAGA_MODAL,
    payload: {
      modal,
      schedule,
      officeHours,
      config
    }
  };
}

export function scheduleAppointmentDetailModal({ modal, schedule, scheduleStatus, officeHours, config }) {
  return {
    type: SCHEDULE_APPOINTMENT_DETAIL_MODAL,
    payload: {
      modal,
      schedule,
      scheduleStatus,
      officeHours,
      config
    }
  };
}

export function appointmentRedialModal({ modal, schedule }) {
  return {
    type: APPOINTMENT_REDIAL_MODAL,
    payload: {
      modal,
      schedule
    }
  };
}

export function appointmentRedialCalendar({ schedule, type }) {
  return {
    type: APPOINTMENT_REDIAL_CALENDAR,
    payload: {
      schedule,
      type
    }
  };
}

export function uncheckAppointmentSagaModal({ schedule, modal }) {
  return {
    type: UNCHECK_APPOINTMENT_SAGA_MODAL,
    payload: {
      schedule,
      modal
    }
  };
}

export function uncheckAppointmentModal({ schedule, modal, groups, procedures }) {
  return {
    type: UNCHECK_APPOINTMENT_MODAL,
    payload: {
      schedule,
      modal,
      groups,
      procedures
    }
  };
}

export function appointmentMissing({ modal, schedule }) {
  return {
    type: APPOINTMENT_MISSING_MODAL,
    payload: {
      modal,
      schedule
    }
  };
}

export function appointmentDelete({ modal, schedule }) {
  return {
    type: APPOINTMENT_DELETE_MODAL,
    payload: {
      modal,
      schedule
    }
  };
}

export function scheduleNextAppointment({ schedule }) {
  return {
    type: SCHEDULE_NEXT_APPOINTMENT,
    payload: {
      schedule
    }
  };
}

export function sendToAppointmentAndRedialSaga({ modal, schedule }) {
  return {
    type: SEND_TO_APPOINTMENT_AND_REDIAL_SAGA,
    payload: {
      modal,
      schedule
    }
  };
}

export function sendToAppointmentAndRedial({ modal, schedule, groups, procedures }) {
  return {
    type: SEND_TO_APPOINTMENT_AND_REDIAL,
    payload: {
      modal,
      schedule,
      groups,
      procedures
    }
  };
}

export function appointmentHistorySagaModal({ modal, patient }) {
  return {
    type: APPOINTMENT_HISTORY_SAGA_MODAL,
    payload: {
      modal,
      patient
    }
  };
}

export function appointmentHistoryModal({ modal, patient, history }) {
  return {
    type: APPOINTMENT_HISTORY_MODAL,
    payload: {
      modal,
      patient,
      history
    }
  };
}

export function appointmentMessageModal({ modal, appointment, clinic }) {
  return {
    type: APPOINTMENT_MESSAGE_MODAL,
    payload: {
      modal,
      appointment,
      clinic
    }
  };
}

export function scheduleAndRedialSagaPage({ dentist, order }) {
  return {
    type: SCHEDULE_AND_REDIAL_SAGA_PAGE,
    payload: {
      dentist,
      order
    }
  };
}

export function scheduleAndRedialPage({ dentist, listScheduleRedial }) {
  return {
    type: SCHEDULE_AND_REDIAL_PAGE,
    payload: {
      dentist,
      listScheduleRedial
    }
  };
}

export function scheduleFromPatient({ schedule }) {
  return {
    type: SCHEDULE_FROM_PATIENT,
    payload: {
      schedule
    }
  };
}

export function scheduleAndRedialAddSaga({ dentist, patient }) {
  return {
    type: SCHEDULE_AND_REDIAL_ADD_SAGA_MODAL,
    payload: {
      dentist,
      patient
    }
  };
}

export function scheduleAndRedialAdd({ modal, dentist, groups, patient }) {
  return {
    type: SCHEDULE_AND_REDIAL_ADD_MODAL,
    payload: {
      modal,
      dentist,
      groups,
      patient
    }
  };
}

export function scheduleOrthodonticsFrequencyPrePage({ dentist }) {
  return {
    type: SCHEDULE_ORTHODONTICSFREQUENCY_SAGA_PAGE,
    payload: {
      dentist
    }
  };
}

export function scheduleOrthodonticsFrequencyPage({ dentist, frequency }) {
  return {
    type: SCHEDULE_ORTHODONTICSFREQUENCY_PAGE,
    payload: {
      dentist,
      frequency
    }
  };
}
