import {
  APPOINTMENT_DELETE_MODAL,
  APPOINTMENT_HISTORY_MODAL,
  APPOINTMENT_MESSAGE_MODAL,
  APPOINTMENT_MISSING_MODAL,
  APPOINTMENT_REDIAL_CALENDAR,
  APPOINTMENT_REDIAL_MODAL,
  SCHEDULE_AND_REDIAL_ADD_MODAL,
  SCHEDULE_AND_REDIAL_PAGE,
  SCHEDULE_APPOINTMENT_DETAIL_MODAL,
  SCHEDULE_EVENT_MODAL,
  SCHEDULE_FROM_PATIENT,
  SCHEDULE_NEXT_APPOINTMENT,
  SCHEDULE_ORTHODONTICSFREQUENCY_PAGE,
  SCHEDULE_PAGE,
  SCHEDULE_SCHEDULE_APPOINTMENT_MODAL,
  SCHEDULE_SCHEDULE_APPOINTMENT_PATIENT_MODAL,
  SCHEDULE_SCHEDULE_APPOINTMENT_SELECT_TREATMENT_MODAL,
  SEND_TO_APPOINTMENT_AND_REDIAL,
  UNCHECK_APPOINTMENT_MODAL
} from './Schedule.actions';

const initialState = {};

const ScheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SCHEDULE_PAGE: {
      return {
        ...state,
        page: {
          ...action.payload
        }
      };
    }
    case SCHEDULE_EVENT_MODAL: {
      return {
        ...state,
        eventModal: {
          ...action.payload
        }
      };
    }
    case SCHEDULE_SCHEDULE_APPOINTMENT_MODAL: {
      return {
        ...state,
        scheduleAppointmentPatientSelectModal: {
          ...action.payload
        }
      };
    }

    case SCHEDULE_SCHEDULE_APPOINTMENT_SELECT_TREATMENT_MODAL: {
      return {
        ...state,
        scheduleAppointmentTreatmentSelectModal: {
          ...action.payload
        }
      };
    }
    case SCHEDULE_SCHEDULE_APPOINTMENT_PATIENT_MODAL: {
      return {
        ...state,
        scheduleAppointmentPatientModal: {
          ...action.payload
        }
      };
    }
    case SCHEDULE_APPOINTMENT_DETAIL_MODAL: {
      return {
        ...state,
        scheduleAppointmentDetailModal: {
          ...action.payload
        }
      };
    }

    case APPOINTMENT_REDIAL_MODAL: {
      return {
        ...state,
        appointmentRedialModal: {
          ...action.payload
        }
      };
    }
    case APPOINTMENT_REDIAL_CALENDAR: {
      return {
        ...state,
        redialCalendar: {
          ...action.payload
        }
      };
    }
    case UNCHECK_APPOINTMENT_MODAL: {
      return {
        ...state,
        uncheckAppointmentModal: {
          ...action.payload
        }
      };
    }

    case APPOINTMENT_MISSING_MODAL: {
      return {
        ...state,
        appointmentMissingModal: {
          ...action.payload
        }
      };
    }

    case APPOINTMENT_DELETE_MODAL: {
      return {
        ...state,
        appointmentDeleteModal: {
          ...action.payload
        }
      };
    }

    case SCHEDULE_NEXT_APPOINTMENT: {
      return {
        ...state,
        nextAppointment: {
          ...action.payload
        }
      };
    }
    case SEND_TO_APPOINTMENT_AND_REDIAL: {
      return {
        ...state,
        sendToAppointmentAndRedial: {
          ...action.payload
        }
      };
    }
    case APPOINTMENT_HISTORY_MODAL: {
      return {
        ...state,
        appointmentHistoryModal: {
          ...action.payload
        }
      };
    }

    case APPOINTMENT_MESSAGE_MODAL: {
      return {
        ...state,
        appointmentMessageModal: {
          ...action.payload
        }
      };
    }

    case SCHEDULE_AND_REDIAL_PAGE: {
      return {
        ...state,
        scheduelRedialPage: {
          ...action.payload
        }
      };
    }

    case SCHEDULE_FROM_PATIENT: {
      return {
        ...state,
        scheduleFromPatient: {
          ...action.payload
        }
      };
    }

    case SCHEDULE_AND_REDIAL_ADD_MODAL: {
      return {
        ...state,
        scheduleRedialModal: {
          ...action.payload
        }
      };
    }

    case SCHEDULE_ORTHODONTICSFREQUENCY_PAGE: {
      return {
        ...state,
        scheduleFrequency: {
          ...action.payload
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default ScheduleReducer;
