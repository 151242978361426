import React, { Component } from 'react';

import {
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledTooltip
} from 'reactstrap';

import ReactTooltip from 'react-tooltip';

import plus from '../../../../assets/img/icons/add-circular-outlined-button.svg';
import excluir from '../../../../assets/img/icons/excluir.svg';
import Icon from '../../../../components/Icon.component';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import TreatmentAddGadgetsModal from './TreatmentAddGadgets.modal';
import ProceduresAPI from '../../../../service/ProceduresAPI';
import MessagesComponent from '../../../../components/Messages.component';
import { budgetModalTreatmentPaymentClosedPrice } from '../redux/TreatmentBudget.actions';
import { formatReal } from '../../../../utils/Formatter';
import { formInit } from './selector/TreatmentBudgetPaymentClosedPriceFormSelector';
import returnicon from '../../../../assets/img/icons/returnicon.svg';
import { Form, Formik } from 'formik';
import TreatmentBudgetApi from '../../../../service/TreatmentBudgetApi';
import { hideLoader, showLoader } from '../../../../components/actions/Loader.actions';

import * as Yup from 'yup';
import ErrorFieldWithoutBootstrap from '../../../../components/ErrorFieldWithoutBootstrap.component';
import InputDecimalComponent from '../../../../components/InputDecimalComponent';
import { IfComponent } from '../../../../components/if.component';

class TreatmentBudgetPaymentClosedPriceModal extends Component {
  state = {
    modalGadgets: false,
    gadgets: [],
    gadgetsSelected: [],
    error: {
      display: false,
      type: 'danger',
      message: ''
    },
    tooltipCents: false
  };

  handleFormChange;
  valuesForm;

  validationSchema = Yup.object().shape({
    valorTotal: Yup.number().required('Valor total obrigatório')
  });

  componentWillReceiveProps(nextProps, nextContext) {
    const { formLoad = {} } = this.props;
    const { preco_fechado = {} } = formLoad;
    if (nextProps.formLoad && nextProps.formLoad.preco_fechado) {
      if (preco_fechado.categorias_procedimentos !== nextProps.formLoad.preco_fechado.categorias_procedimentos) {
        let gadgets = [];
        nextProps.formLoad.preco_fechado.categorias_procedimentos.forEach((p) => gadgets.push(...p.procedimentos));

        this.setState({
          gadgetsSelected: gadgets
        });
      }
    }
  }

  closeAddGadget = () => {
    this.setState({ modalGadgets: false });
  };

  openGadgets = async () => {
    const { gadgets } = this.state;

    if (!gadgets || !gadgets.length) {
      try {
        const proceduresData = await ProceduresAPI.getAllProceduresOfClosedPrice();
        gadgets.push(...proceduresData.data);
        this.setState({ modalGadgets: true, gadgets: gadgets.filter((g) => g.procedimentos.length > 0) });
      } catch (e) {
        console.log(e);
        this.setState({
          error: {
            display: true,
            type: 'danger',
            message: 'Erro ao buscar procedimentos'
          }
        });
      }
    } else {
      this.setState({ modalGadgets: true });
    }
  };

  addGadgets = (selected) => {
    this.totalValueCalculate(selected);

    this.setState({
      modalGadgets: false,
      gadgetsSelected: selected
    });
  };

  totalValueCalculate = async (selected) => {
    const totalValue = selected.reduce((totalValue, selected) => (totalValue += selected.valor_tratamento), 0);

    await this.handleFormChange({
      target: {
        name: 'valorTotal',
        value: parseFloat(totalValue)
      }
    });
    this.updateValues();
  };

  removeGadget = (p) => {
    this.setState(
      {
        gadgetsSelected: this.state.gadgetsSelected.filter((g) => g.id !== p.id)
      },
      () => this.totalValueCalculate(this.state.gadgetsSelected)
    );
  };

  closeModal = () => {
    const { closeModal } = this.props;
    closeModal();
    this.setState({
      gadgets: [],
      gadgetsSelected: [],
      error: {
        display: false,
        type: 'danger',
        message: ''
      }
    });
  };

  saveClosedPayment = async (values, actions) => {
    const { gadgetsSelected } = this.state;
    const { showLoader, hideLoader, updateParent } = this.props;

    if (values.parcelas === 0) {
      values.valorParcelas = 0;
      values.entrada = 0;
    }

    showLoader();
    try {
      await TreatmentBudgetApi.savePaymentClosedPrice({
        budgetId: values.budget,
        paymentId: values.paymentId,
        closedPriceId: values.closedPriceId,
        valor_total: values.valorTotal,
        valor_desconto: values.desconto,
        parcelado_em: values.parcelas,
        dia_vencimento: values.diaVencimento,
        valor_entrada: values.entrada,
        valor_parcela: values.valorParcelas,
        procedimentos: gadgetsSelected.map((g) => ({
          procedimento: g.id,
          valor_tratamento: g.valor_tratamento
        }))
      });
      updateParent();
      this.closeModal();
    } catch (e) {
      console.log(e);
      this.setState({
        error: {
          display: true,
          type: 'danger',
          message: 'Erro ao salvar modalidade de pagamento preço fechado entre em contato com o suporte'
        }
      });
    }

    hideLoader();
  };

  updateValues = async () => {
    const valorComDesconto = this.valuesForm.valorTotal - (this.valuesForm.desconto ? this.valuesForm.desconto : 0);
    const parcela = (valorComDesconto - (this.valuesForm.entrada || 0)) / (this.valuesForm.parcelas || 1);

    await this.handleFormChange({
      target: {
        name: 'valorComDesconto',
        value: valorComDesconto
      }
    });
    await this.handleFormChange({
      target: {
        name: 'valorParcelas',
        value: parcela
      }
    });
  };

  roundedInput = async (values) => {
    await this.handleFormChange({
      target: {
        name: 'valorParcelas',
        value: Math.ceil(values.valorParcelas)
      }
    });
  };

  render() {
    const { modalGadgets, error, gadgets, gadgetsSelected, tooltipCents } = this.state;
    const { modal, formInit } = this.props;

    return (
      <Modal isOpen={modal} toggle={this.closeModal} className={this.props.className} size={'default'}>
        <ModalHeader
          className='title-primary'
          toggle={this.closeModal}
          close={
            <button className='close' onClick={this.closeModal}>
              &times;
            </button>
          }
        >
          <strong>Orçamento Preço Fechado</strong>
        </ModalHeader>
        <Formik
          validateOnChange={false}
          validationSchema={this.validationSchema}
          initialValues={formInit}
          onSubmit={this.saveClosedPayment}
        >
          {({ values, errors, handleChange }) => {
            this.handleFormChange = handleChange;
            this.valuesForm = values;
            return (
              <Form>
                <ModalBody>
                  <MessagesComponent display={error.display} type={error.type} message={error.message} />
                  <div>
                    <Row className='margin-top-10 text-left'>
                      <Col sm='12'>
                        <h1 className='title-modal'>
                          Discriminação
                          {gadgetsSelected.length > 0 && (
                            <a href={'#'} onClick={() => this.openGadgets()}>
                              <Icon className='icon icon-size-small margin-left-10' image={plus} alt='plus' />
                            </a>
                          )}
                        </h1>

                        {!gadgetsSelected.length > 0 && (
                          <p>
                            Adicionar Custos (Procedimentos)
                            <a href={'#'} onClick={() => this.openGadgets()}>
                              <Icon className='icon icon-size-small margin-left-10' image={plus} alt='plus' />
                            </a>
                          </p>
                        )}
                        {gadgetsSelected.length > 0 && (
                          <div style={{ overflow: 'auto', maxHeight: 180 }}>
                            <Table responsive>
                              <thead>
                                <tr>
                                  <th width='75%'>Procedimento / Custo</th>
                                  <th width='20%'>Valor</th>
                                  <th width='5%'></th>
                                </tr>
                              </thead>
                              <tbody>
                                {gadgetsSelected.map((p) => {
                                  return (
                                    <tr key={p.id}>
                                      <td>{p.descricao}</td>
                                      <td>
                                        <Col sm='12'>{formatReal(p.valor_tratamento)}</Col>
                                      </td>
                                      <td>
                                        <Col sm='12'>
                                          <a href={'#'} onClick={() => this.removeGadget(p)}>
                                            <Icon
                                              className='icon icon-size-small icon-alert'
                                              image={excluir}
                                              alt='excluir'
                                            />
                                          </a>
                                        </Col>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </div>
                        )}
                      </Col>
                    </Row>

                    <hr className='mt-0 mb-2'></hr>

                    <Row>
                      <Col sm='12'>
                        <h1 className='title-modal'>Totalização</h1>
                      </Col>
                    </Row>

                    <Row className='margin-top-10'>
                      <Col sm='6'>
                        <label>
                          Valor Total <span> *</span>
                        </label>
                        <InputGroup className='reversed' invalid={errors.valorTotal ? true : false}>
                          <InputDecimalComponent
                            value={values.valorTotal}
                            handleChange={handleChange}
                            name={'valorTotal'}
                            errors={errors}
                            valueChange={() => this.updateValues()}
                          />
                          <InputGroupAddon addonType='prepend'>R$</InputGroupAddon>
                        </InputGroup>
                        <ErrorFieldWithoutBootstrap fieldName='valorTotal' errors={errors} />
                      </Col>
                      <Col sm='6'>
                        <label>Desconto </label>
                        <InputGroup className='reversed'>
                          <InputDecimalComponent
                            value={values.desconto}
                            handleChange={handleChange}
                            name={'desconto'}
                            errors={errors}
                            valueChange={() => this.updateValues()}
                          />
                          <InputGroupAddon addonType='prepend'>R$</InputGroupAddon>
                        </InputGroup>
                      </Col>
                    </Row>

                    <Row className='margin-top-10'>
                      <Col sm='12'>
                        <label>Valor com Desconto</label>
                      </Col>
                      <Col sm='6'>
                        <InputGroup className='reversed'>
                          <InputDecimalComponent
                            readOnly={true}
                            value={values.valorComDesconto}
                            handleChange={handleChange}
                            name={'valorComDesconto'}
                            errors={errors}
                            fixedDecimalScale={2}
                          />
                          <InputGroupAddon addonType='prepend'>R$</InputGroupAddon>
                        </InputGroup>
                      </Col>
                      <Col sm='6'>
                        <input
                          type={'checkbox'}
                          style={{ marginTop: 11 }}
                          className='form-checkbox'
                          id='check-one'
                          checked={values.parcelas > 0}
                          onClick={async (e) => {
                            await handleChange({
                              target: {
                                name: 'parcelas',
                                value: e.target.checked ? 1 : 0
                              }
                            });
                            await this.updateValues();
                          }}
                        />{' '}
                        Parcelar
                      </Col>
                    </Row>
                    <IfComponent conditional={values.parcelas > 0}>
                      <Row className='margin-top-10'>
                        <Col sm='4'>
                          <label>Entrada</label>
                          <InputGroup style={{ width: 'calc(100% + 29px)' }} className='reversed'>
                            <InputDecimalComponent
                              value={values.entrada}
                              handleChange={handleChange}
                              name={'entrada'}
                              errors={errors}
                              valueChange={() => this.updateValues()}
                            />
                            <InputGroupAddon addonType='prepend'>R$</InputGroupAddon>
                          </InputGroup>
                        </Col>
                        <Col className='px-0' sm={1}>
                          <label>&nbsp;</label>
                          <span className={'absolute-center mt-2 font-bolder title-large pl-4'}>+</span>
                        </Col>
                        <Col className='px-0' sm={1}>
                          <label>&nbsp;</label>
                          <Input
                            name={'parcelas'}
                            className='input-secondary '
                            value={values.parcelas}
                            onChange={async (e) => {
                              await handleChange(e);
                              await this.updateValues();
                            }}
                            size={4}
                          />
                          <ErrorFieldWithoutBootstrap fieldName={'parcelas'} errors={errors} />
                        </Col>
                        <Col sm={2}>
                          <label>&nbsp;</label>
                          <span className={'absolute-center mt-2 font-bold w-100 pl-2'}>vezes de </span>
                        </Col>
                        <Col className='px-0' sm='3'>
                          <label style={{ marginLeft: -20 }}>Parcelas</label>
                          <InputGroup style={{ marginLeft: -20, width: 'calc(100% + 20px)' }} className='reversed'>
                            <InputDecimalComponent
                              name={'valorParcelas'}
                              value={values.valorParcelas}
                              prefix={'R$ '}
                              errors={errors}
                              readOnly={true}
                              handleChange={handleChange}
                            />
                            <InputGroupAddon addonType='prepend'>R$</InputGroupAddon>
                          </InputGroup>
                        </Col>
                        <Col sm={1}>
                          <a
                            href={'#'}
                            data-tip
                            data-for='cents'
                            className={'absolute-center'}
                            style={{ marginTop: 10 }}
                            onClick={() => this.roundedInput(values)}
                          >
                            <Icon className='icon icon-size-small' image={returnicon} />
                          </a>
                          <ReactTooltip id='cents' className='tooltip-griks'>
                            Arredondar os centavos
                          </ReactTooltip>
                        </Col>
                      </Row>
                    </IfComponent>
                    <Row className='margin-top-10'>
                      <Col sm={12}>
                        <label>Dia de Vencimento</label>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={10} style={{ paddingTop: 5 }}>
                        <Slider
                          min={1}
                          max={30}
                          value={values.diaVencimento}
                          step={1}
                          onChange={(e) => {
                            handleChange({
                              target: {
                                name: 'diaVencimento',
                                value: e
                              }
                            });
                          }}
                        />
                      </Col>
                      <Col sm={1} style={{ textAlign: 'center' }}>
                        <input
                          id
                          type={'text'}
                          className='text-secondary'
                          size={7}
                          value={values.diaVencimento}
                          style={{ borderLeft: 0, borderTop: 0, borderRight: 0, textAlign: 'center' }}
                        />
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Row>
                    <Col sm={{ size: 3, offset: 6 }}>
                      <button className='button-primary' type={'submit'}>
                        Salvar
                      </button>
                    </Col>
                    <Col sm='3'>
                      <button className='button-tertiary' type={'button'} onClick={this.closeModal}>
                        Cancelar
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
        <TreatmentAddGadgetsModal
          selected={gadgetsSelected}
          updateParent={() => {}}
          modal={modalGadgets}
          closeModal={this.closeAddGadget}
          gadgets={gadgets}
          addGadgets={this.addGadgets}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { TreatmentBudgetReducer } = state;
  const { paymentClosedPrice } = TreatmentBudgetReducer;

  return {
    ...paymentClosedPrice,
    formInit: formInit(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(budgetModalTreatmentPaymentClosedPrice({ modal: false })),
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader())
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(TreatmentBudgetPaymentClosedPriceModal);
