import t from 'typy';
import { createSelector } from 'reselect';

const treatmentPlanGoalsModalSelector = (state) =>
  t(state, 'TreatmentClinicalRecordReducer.treatmentPlanGoalModal').safeObject;
const treatmentPlanGoalsSelector = (state) =>
  t(state, 'TreatmentClinicalRecordReducer.treatmentPlanGoalModal.plan.objetivos').safeObject;

export const propsModalSelector = createSelector(
  treatmentPlanGoalsModalSelector,
  treatmentPlanGoalsSelector,
  (treatmentPlanGoalModal = {}, objetivos = []) => {
    const { modal, treatment, record, plan = {} } = treatmentPlanGoalModal;

    const goals = objetivos.map((o) => o.objetivo);

    if (goals.length == 0) goals.push('');

    return {
      modal,
      treatment,
      record,
      plan: plan.id,
      goals
    };
  }
);
