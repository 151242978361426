import t from 'typy';
import { createSelector } from 'reselect';

const financialFileSelector = (state) => t(state, 'TreatmentFinancialFileReducer.accountModal').safeObject;
const financialFileDataSelector = (state) =>
  t(state, 'TreatmentFinancialFileReducer.accountModal.financialFile').safeObject;
const financialFileFormLoadSelector = (state) =>
  t(state, 'TreatmentFinancialFileReducer.accountModal.formLoad').safeObject;

export const propsAccountDataSelector = createSelector(financialFileSelector, (accountModal = {}) => {
  return {
    modal: accountModal.modal,
    treatment: accountModal.treatment,
    financialFile: t(accountModal, 'financialFile').safeObject
  };
});

export const formLoadSelector = createSelector(financialFileFormLoadSelector, (formLoad = {}) => {
  const responsibleTypes = t(formLoad, 'responsibleTypes').isArray
    ? formLoad.responsibleTypes.map((r) => ({
        value: r.id,
        label: r.nome,
        data: r
      }))
    : [];
  const treatmentTypes = t(formLoad, 'treatmentTypes').isArray
    ? formLoad.treatmentTypes.map((t) => ({
        value: t.id,
        label: t.descricao,
        data: t
      }))
    : [];
  const voucherTypes = t(formLoad, 'voucherTypes').isArray
    ? formLoad.voucherTypes.map((v) => ({
        value: v.sigla,
        label: v.descricao,
        color: v.color,
        data: v
      }))
    : [];

  return {
    responsibleTypes,
    treatmentTypes,
    voucherTypes
  };
});

export const formInitSelector = createSelector(financialFileDataSelector, (accountModal = {}) => {
  const { informacoes_conta = {} } = accountModal;

  const tipo_tratamento = t(informacoes_conta, 'tipo_tratamento').safeObject;
  const tipo_responsavel = t(informacoes_conta, 'tipo_responsavel').safeObject;
  const tipo_modalidade = t(informacoes_conta, 'tipo_modalidade').safeObject;
  const comprovante = t(informacoes_conta, 'comprovante').safeObject;

  const formInit = {
    id: accountModal.id,
    data_contrato: accountModal.data_contrato,
    treatment_type: tipo_tratamento
      ? {
          value: tipo_tratamento.id,
          label: tipo_tratamento.descricao,
          data: tipo_tratamento
        }
      : {},
    voucher_type: comprovante
      ? {
          value: comprovante.sigla,
          label: comprovante.descricao,
          color: comprovante.color,
          data: comprovante
        }
      : {},
    cpf_paciente: informacoes_conta.cpf,
    rg_paciente: informacoes_conta.rg,
    modality_type: tipo_modalidade,
    responsible_type: tipo_responsavel
      ? {
          value: tipo_responsavel.id,
          label: tipo_responsavel.descricao,
          data: tipo_responsavel
        }
      : {},
    nome_responsavel: informacoes_conta.nome_responsavel,
    cpf_responsavel: informacoes_conta.cpf_responsavel,
    rg_responsavel: informacoes_conta.rg_responsavel
  };

  return {
    ...formInit
  };
});
