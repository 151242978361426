import React from 'react';

import { Col, Row, Table, Container } from 'reactstrap';
import _ from 'lodash';
import moment from 'moment';
import { formatReal } from '../../../utils/Formatter';
import { getClinic } from '../../../utils/localStorage/SessionStorageUtils';

const FinancialOverviewReportPrintPage = (props) => {
  const data = localStorage.getItem('overviewData');
  console.log(JSON.parse(data));
  const { vouchers, total, periodo } = JSON.parse(data);
  const clinica = getClinic();
  return (
    <div className='show-scroll box-default report-box-default' style={{ minHeight: '60vh' }}>
      <Container>
        <Row>
          <Col sm='12'>
            <strong className='title-medium-plus text-uppercase'>{clinica.nome}</strong>
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col sm='12'>
            <strong className='title-medium-plus text-uppercase'>Recibos Emitidos</strong>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col sm='12'>
            <strong className='title-medium-plus'>
              Período de {moment(periodo.startDate).format('DD/MM/YYYY')} a{' '}
              {moment(periodo.endDate).format('DD/MM/YYYY')}
            </strong>
          </Col>
        </Row>
      </Container>

      <Table className='p-2' responsive>
        <thead>
          <tr>
            <th>
              <span className='text-md text-secondary-d4 font-weight-bold'>Recibo</span>
            </th>
            <th>
              <span className='text-md text-secondary-d4 font-weight-bold'>Data</span>
            </th>
            <th>
              <span className='text-md text-secondary-d4 font-weight-bold'>Doutor(a)</span>
            </th>
            <th>
              <span className='text-md text-secondary-d4 font-weight-bold'>Paciente</span>
            </th>
            <th>
              <span className='text-md text-secondary-d4 font-weight-bold'>CPF Paciente</span>
            </th>
            <th>
              <span className='text-md text-secondary-d4 font-weight-bold'>Responsável</span>
            </th>
            <th>
              <span className='text-md text-secondary-d4 font-weight-bold'>CPF Responsável</span>
            </th>
            <th>
              <span className='text-md text-secondary-d4 font-weight-bold'>Valor R$</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {vouchers.map((v) => {
            return (
              <tr className={'py-0 box-basic px-0'} key={v.id}>
                <td>
                  <span className='text-md font-light text-secondary'>#{_.padStart(v.numeroRecibo, 4, '0')}</span>
                </td>
                <td>
                  <span className='text-md font-light text-secondary'>
                    {moment(v.dataEmissao).format('DD/MM/YYYY')}
                  </span>
                </td>
                <td>
                  <span className='text-md font-light text-secondary'>{v.dentista.nome}</span>
                </td>
                <td>
                  <span className='text-md font-light text-secondary'>{v.nomePaciente}</span>
                </td>
                <td>
                  <span className='text-md font-light text-secondary'>{v.cpfPaciente}</span>
                </td>
                <td>
                  <span className='text-md font-light text-secondary'>{v.nomeResponsavel}</span>
                </td>
                <td>
                  <span className='text-md font-light text-secondary'>{v.cpfResponsavel}</span>
                </td>
                <td>
                  <span className='text-md font-light text-secondary'>{formatReal(v.valor)}</span>
                </td>
              </tr>
            );
          })}
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className='text-right text-md text-secondary-d4'>
              <strong>Total</strong>
            </td>
            <td className='text-md text-secondary-d4'>
              <strong>{formatReal(total)}</strong>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default FinancialOverviewReportPrintPage;
