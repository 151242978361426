import React, { Component } from 'react';
import { Col, Container, Row, Table } from 'reactstrap';
import moment from 'moment';
import { formatDate } from '../../utils/DateUtils';
import { formatRealNoShowDolarSign } from '../../utils/Formatter';

const CashFlowPage = ({ cash }) => {
  const totalrecebido = (cash.valores_recebidos && Object.values(cash.valores_recebidos).reduce((s, v) => s + v)) || 0;

  let total = totalrecebido;

  if (cash.valor_inicial) {
    total = totalrecebido + cash.valor_inicial;
  }

  return (
    <Container>
      <Row>
        <Col sm='8'>
          <div className='box-default w-100' style={{ height: '99%' }}>
            <Row>
              <Col sm='4'>
                <p className={'title-medium-plus text-secondary-d4 text-uppercase'}>
                  <strong className={'text-secondary-d4'}>Movimento de caixa</strong>
                </p>
              </Col>
              <Col sm='4'>
                <p className={'text-default text-sm text-secondary-d4'}>
                  <strong className={'text-secondary-d4'}>Abertura</strong>{' '}
                  {formatDate(cash.aberto_em, 'DD/MM/YYYY HH:mm')}
                </p>
              </Col>
              <Col sm='4'>
                <p className={'text-default text-sm text-secondary-d4'}>
                  <strong className={'text-secondary-d4'}>Fechamento</strong>{' '}
                  {cash.fechado_em && formatDate(cash.fechado_em, 'DD/MM/YYYY HH:mm')}
                </p>
              </Col>
            </Row>
            <hr style={{ marginTop: 10, marginBottom: 20 }} />
            <Row>
              <Col sm='12'>
                <h2 className='text-secondary text-default text-uppercase font-bold'>Recebido</h2>
                <div className='content-overflow-default'>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th width='15%'>Data</th>
                        <th width='15%'>Hora</th>
                        <th width='30%'>Paciente</th>
                        <th width='25%'>Forma de Pagamento</th>
                        <th width='15%'>Valor R$</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cash.recebidos &&
                        cash.recebidos.map((r) => (
                          <tr key={r.id}>
                            <td>{formatDate(r.data, 'DD/MM/YYYY')}</td>
                            <td>{formatDate(r.data, 'HH:mm')}</td>
                            <td>{r.paciente}</td>
                            <td>{r.forma_pagamento}</td>
                            <td>{formatRealNoShowDolarSign(r.valor)}</td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className='margin-top-10' sm='12'>
                <h2 className='text-secondary text-default text-uppercase font-bold'>Ajustes</h2>
                <div className='content-overflow-default'>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th width='15%'>Data</th>
                        <th width='15%'>Hora</th>
                        <th width='30%'>Paciente</th>
                        <th width='25%'>Tipo de Ajuste</th>
                        <th width='15%'>Valor R$</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cash.ajustes &&
                        cash.ajustes.map((r) => (
                          <tr key={r.id}>
                            <td>{formatDate(r.data, 'DD/MM/YYYY')}</td>
                            <td>{formatDate(r.data, 'HH:mm')}</td>
                            <td>{r.paciente}</td>
                            <td>{r.descricao}</td>
                            <td className='text-alert'>{formatRealNoShowDolarSign(r.valor)}</td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </div>
        </Col>

        <Col sm='4'>
          <div className='box-default w-100'>
            <Row>
              <Col sm='12'>
                <Table responsive className='table-default'>
                  <thead>
                    <tr>
                      <th className='pt-0' colSpan='2'>
                        <div className='text-default text-secondary text-uppercase font-bold'>Totalização</div>
                      </th>
                    </tr>
                    <tr>
                      <th width='75%'>(=) SALDO INICIAL</th>
                      <th width='25%'></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='font-bold'>Troco em Dinheiro</td>
                      <td>
                        <strong>{formatRealNoShowDolarSign(cash.valor_inicial)}</strong>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>

            <Row>
              <Col sm='12'>
                <Table responsive className='table-default'>
                  <thead>
                    <tr>
                      <th width='75%'>(+) VALORES RECEBIDOS</th>
                      <th width='25%'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {cash.valores_recebidos &&
                      Object.keys(cash.valores_recebidos).map((k) => (
                        <tr key={k}>
                          <td>{k}</td>
                          <td>{formatRealNoShowDolarSign(cash.valores_recebidos[k])}</td>
                        </tr>
                      ))}
                    <tr>
                      <td className='font-bold'>Total Recebido</td>
                      <td>{cash.valores_recebidos && formatRealNoShowDolarSign(totalrecebido)}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>

            <Row>
              <Col sm='12'>
                <Table responsive className='table-default'>
                  <thead>
                    <tr>
                      <th width='75%'>(=) SALDO FINAL</th>
                      <th width='25%'></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='font-bold'>Saldo Total FInal</td>
                      <td>
                        <strong>{formatRealNoShowDolarSign(total)}</strong>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>

          <div className='box-search text-center'>
            <Row>
              <Col sm='12'>
                <p className={'text-default text-sm text-secondary-d4'}>
                  <strong className={'text-secondary-d4'}>Caixa de</strong>
                  {cash.usuario_nome}
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <p className={'text-default text-sm text-secondary-d4'}>
                  <strong className={'text-secondary-d4'}>Data/Hora</strong>
                  {formatDate(moment(), 'DD/MM/YY HH:mm')}
                </p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CashFlowPage;
