import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { PatientNewForm } from './PatientNewForm';
import { savePatient } from '../redux/patient.actions';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import ScheduleAppointmentDefaultTreatmentNew from '../../schedule/modal/ScheduleAppointmentDefaultTreatmentNew';

const PatientNewModal = (props, modalShowRef) => {
  const [showModal, setShowModal] = useState(false);
  const [formModalAddValues, setFormModalAddValues] = useState({});
  const [treatmentModal, setTreatmentModal] = useState(false);
  const modalRef = useRef();

  useImperativeHandle(modalShowRef, () => ({
    showModal() {
      setShowModal(true);
    }
  }));

  const closeModal = () => {
    setShowModal(false);
  };

  const formSubmitSave = (values) => {
    modalRef.current.openModal();
    setFormModalAddValues(values);
    savePatient(formModalAddValues);
    setTreatmentModal(true);
  };

  useEffect(() => {
    console.log(formModalAddValues);
  }, [formModalAddValues]);

  return (
    <>
      <Modal isOpen={showModal} toggle={closeModal} className='modal-default'>
        <ModalHeader toggle={closeModal}>Adicionar Novo Paciente</ModalHeader>
        <PatientNewForm closeModal={closeModal} formSubmitSave={formSubmitSave} />
        <ScheduleAppointmentDefaultTreatmentNew ref={modalRef} formSubmitSave={formSubmitSave} />
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    savePatient: (data) => dispatch(savePatient(data))
  };
};

const enhanced = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps, null, { forwardRef: true }));

export default enhanced(forwardRef(PatientNewModal));
