import React, { Component, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { scheduleAppointmentReducerToProps } from '../selectors/ScheduleAppointmentModal.selector';
import { scheduleAppointmentModal, scheduleAppointmentSelectTreatmentSagaModal } from '../redux/Schedule.actions';
import SugestionBoxComponent from '../../../components/SuggestionBoxComponent';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import Radium, { StyleRoot } from 'radium';
import { fadeIn } from 'react-animations';
import PatientAPI from '../../../service/PacienteAPI';
import noavatar from '../../../assets/img/noavatar.png';
import { t } from 'typy';
import MessagesComponent from '../../../components/Messages.component';
import $ from 'jquery';
import PatientNewModal from '../../patinent/modals/PatientNewModal';

class ScheduleAppointmentSelectPatientModal extends Component {
  modalShowRef;

  constructor(props) {
    super(props);
    this.modalShowRef = React.createRef(false);
  }

  closeModal = () => {
    this.props.closeModal();
    this.setState({
      focus: false,
      suggestionsData: [],
      patientSelected: null,
      value: '',
      message: {
        display: false,
        text: ''
      },
      checkbox: false
    });
  };

  state = {
    focus: false,
    suggestionsData: [],
    value: '',
    patientSelected: null,
    message: {
      display: false,
      text: ''
    },
    checkbox: false,
    show: false
  };

  handleChange = (e) => {
    let isChecked = e.target.checked;
    // console.log(isChecked);
    this.setState({
      checkbox: isChecked
    });
  };

  showModal = () => {
    this.setState({
      show: true
    });
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (nextProps.modal && !this.state.focus) {
      setTimeout(() => {
        $('#patientInput').focus();
        this.setState({
          focus: true
        });
      }, 200);
    }
    return true;
  }

  selectedPatient = (patientSelected) => {
    this.setState({ patientSelected });
  };

  renderSuggestion(suggestion, { query }) {
    const suggestionText = `${suggestion.name}`;
    const matches = AutosuggestHighlightMatch(suggestionText, query);
    const parts = AutosuggestHighlightParse(suggestionText, matches);
    if (suggestion.id) {
      return (
        <span className={'suggestion-content'}>
          <img src={suggestion.image} style={{ maxWidth: 62, maxHeight: 62 }} alt='' />
          <span className='name'>
            {parts.map((part, index) => {
              const className = part.highlight ? 'highlight' : null;

              return (
                <span className={className} key={index}>
                  {part.text}
                </span>
              );
            })}
          </span>
        </span>
      );
    } else {
      return (
        <span className={'suggestion-content'}>
          <span className='name'>
            <span className={''}>
              {'Nenhum paciente encontrado com o nome'} {<span className='highlight'>({query})</span>}
            </span>
          </span>
        </span>
      );
    }
  }

  renderSuggestionsContainer({ containerProps, children, query }) {
    return (
      <StyleRoot>
        <div
          {...containerProps}
          style={{
            animation: 'x 0.9s',
            animationName: Radium.keyframes(fadeIn, 'fadeIn')
          }}
        >
          {children}
        </div>
      </StyleRoot>
    );
  }

  findPatients = async (searchTerm) => {
    if (searchTerm.length > 2) {
      try {
        const p = await PatientAPI.pescInc(searchTerm.trim());
        if (p.length === 0) {
          p.push({
            nome: 'Nenhum paciente encontrado'
          });
        }

        this.setState({
          suggestionsData: p.map((pt) => {
            return {
              id: pt.id,
              name: pt.nome,
              image: pt.foto || noavatar
            };
          })
        });
      } catch (err) {
        this.setState({
          suggestionsData: []
        });
      }
    } else {
      this.setState({
        suggestionsData: []
      });
    }
  };

  renderInputComponent = (inputProps) => (
    <div className=''>
      <input
        id={'patientInput'}
        {...inputProps}
        className='input-secondary'
        type='text'
        name='firstname'
        autoComplete='off'
        data-lpignore='true'
        spellCheck='false'
      />
    </div>
  );

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue
    });
  };

  createNewPatient = () => {
    this.modalShowRef.current.showModal();
  };

  nextStep = () => {
    const { date, start, end, dentist, chair, next } = this.props;

    const { patientSelected, checkbox } = this.state;

    if (checkbox === true) {
      this.modalShowRef = true;
    } else {
      if (t(patientSelected, 'id').isDefined) {
        next({
          modal: true,
          date,
          start,
          end,
          dentist,
          chair,
          patient: patientSelected
        });
        this.setState({
          checkbox: false
        });
        this.closeModal();
      } else {
        this.setState({
          message: {
            display: true,
            text: 'Selecione um paciente'
          }
        });
      }
    }
  };

  inputRef = (autosuggest) => {
    if (autosuggest !== null) {
      // this.input = autosuggest.input;
      autosuggest.input.focus();
    }
  };

  render() {
    const { value, suggestionsData, message, checkbox } = this.state;
    const { modal } = this.props;
    const inputProps = {
      placeholder: '',
      value: value !== '' ? value : ' ',
      onChange: this.onChange
    };
    return (
      <>
        <Modal isOpen={modal} toggle={this.closeModal} className={this.props.className + ' modal-small'}>
          <ModalHeader
            className='title-primary'
            toggle={this.closeModal}
            close={
              <button className='close' onClick={this.closeModal}>
                &times;
              </button>
            }
          >
            <strong>Agendar Consulta</strong>
          </ModalHeader>
          <ModalBody>
            <MessagesComponent display={message.display} type={'danger'} message={message.text} />
            <div className='margin-top-10'>
              <Row>
                <Col sm='12'>
                  <label>
                    Paciente<span>*</span>
                  </label>
                  <form autoComplete='off' className='rec-form patient'>
                    <SugestionBoxComponent
                      renderSuggestionsContainer={this.renderSuggestionsContainer}
                      renderSuggestion={this.renderSuggestion}
                      renderInputComponent={this.renderInputComponent}
                      findSuggestions={this.findPatients}
                      selectedSuggestion={this.selectedPatient}
                      suggestionsData={suggestionsData}
                      inputProps={inputProps}
                      className='form-control'
                    />
                  </form>
                </Col>
              </Row>
              {/*<Row className='margin-top-10'>*/}
              {/*  <Col sm='12' className='m-0 p-0 d-flex align-items-center'>*/}
              {/*    <input*/}
              {/*      type='checkbox'*/}
              {/*      name='checkbox'*/}
              {/*      className='form-checkbox'*/}
              {/*      defaultValue={checkbox}*/}
              {/*      value={checkbox}*/}
              {/*      onChange={this.handleChange}*/}
              {/*    />*/}
              {/*    <span className='text-secondary font-bold'>Criar Novo Paciente</span>*/}
              {/*  </Col>*/}
              {/*</Row>*/}
            </div>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col sm={{ size: 6, offset: 6 }} className={'btn-list'}>
                <button className='button-tertiary btn-default' onClick={this.closeModal}>
                  Cancelar
                </button>
                {!checkbox ? (
                  <button
                    className='button-tertiary btn-default text-light'
                    style={{ backgroundColor: '#793491' }}
                    onClick={this.nextStep}
                  >
                    Próximo
                  </button>
                ) : (
                  <button
                    className='button-tertiary btn-default text-light'
                    style={{ backgroundColor: '#793491' }}
                    onClick={this.createNewPatient}
                  >
                    Criar
                  </button>
                )}
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
        <PatientNewModal ref={this.modalShowRef} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...scheduleAppointmentReducerToProps(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(scheduleAppointmentModal({ modal: false })),
  next: (data) => dispatch(scheduleAppointmentSelectTreatmentSagaModal(data))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(ScheduleAppointmentSelectPatientModal);
