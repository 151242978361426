import React from 'react';
import PrivateRouter from '../components/PrivateRoute.component';
import FlowHomePage from '../pages/flow/FlowHomePage';
import { Route } from 'react-router';

const FlowRouters = (props) => {
  return [<PrivateRouter key='FlowHomePage' exact path='/flow' render={(props) => <FlowHomePage {...props} />} />];
};

export default FlowRouters;
