import t from 'typy';
import { createSelector } from 'reselect';

const cashOpenSelector = (state) => t(state, 'CashReducer.openModal').safeObject;

export const cashOpenModalSelectorToProps = createSelector(cashOpenSelector, (openModal = {}) => {
  return {
    modal: openModal.modal,
    usuario: openModal.usuario
  };
});
