import React, { Component } from 'react';

import { Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap';

import MaskedInput from 'react-text-mask';
import noavatar from '../../../assets/img/noavatar.png';
import { AvatarModal } from '../../../components/Avatar.modal';
import Icon from '../../../components/Icon.component';

import whats from '../../../assets/img/icons/whats.svg';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { contactsSave, contactsShowHideSave, findAllContacts } from '../redux/contacts.actions';
import DomainsAPI from '../../../service/DomainsAPI';
import { Formik } from 'formik';
import ErrorMessageComponent from '../../../components/ErrorMessage.component';
import { dateFormat, maskPhoneDefine } from '../../../utils/Formatter';
import * as Yup from 'yup';
import ErrorField from '../../../components/ErrorField.component';
import { IfComponent } from '../../../components/if.component';
import { t } from 'typy';
import ContactsAPI from '../../../service/ContactsAPI';
import ModalMessageConfirm from '../../../components/ModalMessageConfirm.component';
import warning from '../../../assets/img/icons/warning.svg';
import ToastUtils from '../../../utils/ToastUtils';
import withSecurity from '../../../config/security/security';
import { PERMISSOES } from '../../../config/security/permissoes';

import success from '../../../assets/img/icons/success.svg';
import ClinicAPI from '../../../service/ClinicAPI';
import { getClinic } from '../../../utils/localStorage/SessionStorageUtils';
import ToastCustomMessageComponent from '../../../components/ToastCustomMessage.component';
import { IoLogoWhatsapp } from 'react-icons/io';

class ContactsSaveModal extends Component {
  state = {
    displayAvatarModal: false,
    categories: [],
    clinic: {
      ddd: ''
    },
    uploadFoto: {
      image: ''
    },
    confirmDelete: {
      display: false,
      data: {}
    }
  };

  shouldComponentUpdate = (nextProps, nextState, nextContext) => {
    if (nextProps.openModal && nextProps.openModal !== this.props.openModal) {
      this.findCategories();
      this.setState({
        uploadFoto: {
          image: null,
          extension: null
        }
      });
    }

    return true;
  };

  componentDidMount = () => {
    this.props.showLoader();
    const clinicPromisse = ClinicAPI.findByUser().then(({ data }) => {
      this.setState({
        clinic: {
          ddd: data.ddd
        }
      });
    });
  };

  async findCategories() {
    try {
      this.props.showLoader();
      const { data } = await DomainsAPI.findContactsCategories();
      this.setState({ categories: data });
      this.props.hideLoader();
    } catch (e) {
      this.props.hideLoader();
      console.log(e);
    }
  }

  uploadImage = () => {
    this.setState({
      displayAvatarModal: true
    });
  };

  closeAvatarModal = () => {
    this.setState({
      displayAvatarModal: false
    });
  };

  callbackUploadImage = (image, extension) => {
    this.setState({
      uploadFoto: {
        image,
        extension
      }
    });
  };

  _deleteContact = () => {
    this.setState({
      confirmDelete: {
        display: true,
        data: this.props.contact
      }
    });
  };

  closeConfirmModal = () => {
    this.setState({
      confirmDelete: {
        display: false,
        data: {}
      }
    });
  };

  confirmDelete = () => {
    this.props.showLoader();
    ContactsAPI.delete(this.state.confirmDelete.data.id)
      .then((_) => {
        this.props.hideLoader();
        this.props.findAll();
        ToastUtils.success(
          <ToastCustomMessageComponent
            iconImage={success}
            message={'Excluído com Sucesso !'}
            title={t(this.state.confirmDelete.data.nome).safeString}
          />,
          {
            autoClose: 6000,
            hideProgressBar: true,
            position: 'top-center'
          }
        );
        this.closeConfirmModal();
        this.props.closeModal();
      })
      .catch((_) => {
        console.log(_);
        this.closeConfirmModal();
        this.props.hideLoader();
        ToastUtils.error('Erro ao deletar contato entre em contato com o suporte');
      });
  };

  initForm() {
    return this.props.contact;
  }

  validationSchema = Yup.object().shape({
    nome: Yup.string().required('Nome Obrigatório'),
    id_categoria: Yup.string().required('Categoria Obrigatória')
  });

  saveContact = (values) => {
    this.props.showLoader();
    const { image, extension } = this.state.uploadFoto;

    const dataSave = {
      ...values,
      imageUpload: {
        image,
        extension
      }
    };

    this.props.save(dataSave);
    ToastUtils.success(
      <ToastCustomMessageComponent iconImage={success} message={'Salvo com Sucesso !'} title={`${values.nome}`} />,
      {
        autoClose: 6000,
        hideProgressBar: true,
        position: 'top-center'
      }
    );
  };

  render() {
    const { displayAvatarModal, categories, uploadFoto = {} } = this.state;
    const { openModal, closeModal, security } = this.props;

    const modulePermission = PERMISSOES.modules.contatos;

    const { close, onSubmitForm, errorDisplay } = this.props;

    return (
      <div>
        <Modal id='contactSave' className='modal-default' isOpen={openModal} toggle={closeModal}>
          <ModalHeader
            className='title-primary pb-0'
            toggle={closeModal}
            close={
              <button className='close' onClick={closeModal}>
                &times;
              </button>
            }
          >
            <strong>{this.props.contact && this.props.contact.id ? 'Editar Contato' : 'Adicionar Contato'}</strong>
          </ModalHeader>

          <Formik
            enableReinitialize={true}
            initialValues={this.initForm()}
            validationSchema={this.validationSchema}
            validateOnChange={false}
            onSubmit={this.saveContact}
          >
            {({ errors, handleSubmit, handleBlur, handleChange, values }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <input type='hidden' name='id' value={values.id} />
                  <input type='hidden' name='foto' value={values.foto} />
                  <ModalBody className={'mt-0'}>
                    <div className='margin-top-10 mb-3'>
                      <Row>
                        <Col sm='12'>
                          <ErrorMessageComponent id={'contactSave'} />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm='4'>
                          <div className='img-user'>
                            <a onClick={this.uploadImage}>
                              <img
                                className='img-circle big'
                                src={uploadFoto.image || values.foto || noavatar}
                                name='foto'
                                alt='foto'
                              />
                            </a>
                          </div>
                        </Col>
                        <Col sm='8'>
                          <Row>
                            <Col sm='2'>
                              <label>Pref.</label>
                              <select
                                name='titulo'
                                id='soflow'
                                onChange={handleChange}
                                value={values.titulo}
                                className={errors.titulo ? 'is-invalid form-control' : 'form-control'}
                              >
                                <option />
                                <option>Dr.</option>
                                <option>Dra.</option>
                              </select>
                              <ErrorField errors={errors} fieldName={'titulo'} />
                            </Col>
                            <Col sm='10'>
                              <label>
                                Nome <span>*</span>
                              </label>
                              <Input
                                className='input-secondary'
                                type='text'
                                name='nome'
                                value={values.nome}
                                onChange={handleChange}
                                invalid={errors.nome ? true : false}
                              />
                              <ErrorField errors={errors} fieldName={'nome'} />
                            </Col>
                          </Row>
                          <div className='margin-top-10'>
                            <Row>
                              <Col sm='8'>
                                <label>
                                  Categoria <span>*</span>
                                </label>
                                <select
                                  id='soflow'
                                  name='id_categoria'
                                  onChange={handleChange}
                                  value={values.id_categoria}
                                  className={errors.id_categoria ? 'is-invalid form-control' : 'form-control'}
                                >
                                  <option />
                                  {categories.map((c) => {
                                    return (
                                      <option key={c.id} value={c.id}>
                                        {c.description}
                                      </option>
                                    );
                                  })}
                                </select>
                                <ErrorField errors={errors} fieldName={'id_categoria'} />
                              </Col>
                              <Col sm='4'>
                                <label>Data Nasc</label>
                                <MaskedInput
                                  id='birthDate'
                                  name='nascimento'
                                  mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                  value={values.nascimento}
                                  onChange={handleChange}
                                  render={(ref, props) => (
                                    <Input
                                      className='input-secondary'
                                      innerRef={ref}
                                      // invalid={errors.birthDate ? true : false}
                                      {...props}
                                    />
                                  )}
                                />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <br />
                      <hr />
                    </div>
                    <div>
                      <Row>
                        <Col sm='3'>
                          <label>Telefone 1</label>
                          <MaskedInput
                            id='telefone_one'
                            name='telefone_one'
                            mask={maskPhoneDefine(values.telefone_one)}
                            value={values.telefone_one}
                            onChange={handleChange}
                            guide={false}
                            render={(ref, props) => (
                              <Input
                                className='input-secondary'
                                innerRef={ref}
                                // invalid={errors.birthDate ? true : false}
                                {...props}
                              />
                            )}
                          />
                        </Col>
                        <Col sm='8'>
                          <label>Descrição</label>
                          <input
                            className='input-secondary'
                            type='text'
                            name='telefone_one_nome'
                            onChange={handleChange}
                            value={values.telefone_one_nome}
                          />
                        </Col>
                        <Col sm='1'>
                          <label id='tooltipWhats'>Tipo</label>
                          <UncontrolledTooltip placement='top' target='tooltipWhats' className='tooltip-white'>
                            Este telefone tem whatsapp?
                          </UncontrolledTooltip>

                          <a
                            href={'#'}
                            onClick={() => {
                              handleChange({
                                target: {
                                  name: 'telefone_one_tipo',
                                  value:
                                    t(values.telefone_one_tipo).isUndefined || values.telefone_one_tipo === 'telefone'
                                      ? 'whatsapp'
                                      : 'telefone'
                                }
                              });
                            }}
                          >

                            <IfComponent
                              conditional={!values.telefone_one_tipo || values.telefone_one_tipo === 'telefone'}
                            >
                              <IoLogoWhatsapp className='icon icon-whats' alt='whats' size={'28'} />
                            </IfComponent>
                            <IfComponent conditional={values.telefone_one_tipo === 'whatsapp'}>
                              <IoLogoWhatsapp className='icon icon-whats whats-on' alt='whats' size={'28'} />
                            </IfComponent>
                          </a>
                        </Col>
                      </Row>
                    </div>
                    <div className='margin-top-10'>
                      <Row>
                        <Col sm='3'>
                          <label>Telefone 2</label>
                          <MaskedInput
                            id='telefone_two'
                            name='telefone_two'
                            mask={maskPhoneDefine(values.telefone_two)}
                            value={values.telefone_two}
                            onChange={handleChange}
                            guide={false}
                            render={(ref, props) => (
                              <Input
                                className='input-secondary'
                                innerRef={ref}
                                // invalid={errors.birthDate ? true : false}
                                {...props}
                              />
                            )}
                          />
                        </Col>
                        <Col sm='8'>
                          <label>Descrição</label>
                          <input
                            className='input-secondary'
                            type='text'
                            name='telefone_two_nome'
                            onChange={handleChange}
                            value={values.telefone_two_nome}
                          />
                        </Col>
                        <Col sm='1'>
                          <a
                            href={'#'}
                            onClick={() => {
                              handleChange({
                                target: {
                                  name: 'telefone_two_tipo',
                                  value:
                                    t(values.telefone_two_tipo).isUndefined || values.telefone_two_tipo === 'telefone'
                                      ? 'whatsapp'
                                      : 'telefone'
                                }
                              });
                            }}
                          >
                            <IfComponent
                              conditional={!values.telefone_two_tipo || values.telefone_two_tipo === 'telefone'}
                            >
                              <IoLogoWhatsapp className='icon icon-whats' alt='whats' size={'28'} />
                            </IfComponent>
                            <IfComponent conditional={values.telefone_two_tipo === 'whatsapp'}>
                              <IoLogoWhatsapp className='icon icon-whats whats-on' alt='whats' size={'28'} />
                            </IfComponent>
                          </a>
                        </Col>
                      </Row>
                    </div>
                    <div className='margin-top-10'>
                      <Row>
                        <Col sm='12'>
                          <label>E-mail</label>
                          <input
                            className='input-secondary'
                            type='text'
                            name='email'
                            onChange={handleChange}
                            value={values.email}
                          />
                        </Col>
                      </Row>
                    </div>
                    <div className='margin-top-10'>
                      <Row>
                        <Col sm='12'>
                          <label>Anotações</label>
                          <textarea
                            className='form-control'
                            rows='2'
                            cols='50'
                            name='anotacoes'
                            onChange={handleChange}
                            value={values.anotacoes}
                          />
                        </Col>
                      </Row>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Row>
                      <Col sm='3'>
                        <IfComponent
                          conditional={
                            t(this.props, 'contact.id').isDefined &&
                            (security.hasPermission(modulePermission.id, modulePermission.permissoes.editar) ||
                              !security.hasRestriction(
                                modulePermission.id,
                                modulePermission.permissoes.editar_restricao.id,
                                modulePermission.permissoes.editar_restricao.restricoes.nao_excluir_contato
                              ))
                          }
                        >
                          <button className='button-cancel' type='button' onClick={this._deleteContact}>
                            Excluir
                          </button>
                        </IfComponent>
                      </Col>
                      <Col className='btn-list' sm={{ size: 5, offset: 4 }}>
                        <button className='button-primary' type='submit'>
                          Salvar
                        </button>
                        <button className='button-tertiary' type='button' onClick={closeModal}>
                          Cancelar
                        </button>
                      </Col>
                    </Row>
                  </ModalFooter>
                </form>
              );
            }}
          </Formik>
        </Modal>
        <AvatarModal
          display={displayAvatarModal}
          closeModal={this.closeAvatarModal}
          callbackImage={this.callbackUploadImage}
          onlyUpload={true}
        />
        <ModalMessageConfirm
          key={'confirmModal'}
          title={<strong>Confirmar Exclusão?</strong>}
          message={
            <span className='text-secondary'>
              <p>
                Confirmar a Exclusão do Contato <strong>{t(this.state.confirmDelete, 'data.nome').safeString}</strong> ?
              </p>
            </span>
          }
          openModal={this.state.confirmDelete.display}
          icon={warning}
          close={this.closeConfirmModal}
          footer={
            <Row>
              <Col sm='3'>
                <button className='button-tertiary' onClick={this.closeConfirmModal}>
                  Não
                </button>
              </Col>
              <Col sm={{ size: 3, offset: 6 }}>
                <button className='button-primary' onClick={this.confirmDelete}>
                  Sim
                </button>
              </Col>
            </Row>
          }
        />
        ,
      </div>
    );
  }
}

const mapStateToProps = ({ ContactsReducer = {} }) => {
  const { contactModalSave = {} } = ContactsReducer;
  const { openModal = false, data = {} } = contactModalSave;
  const clinic = getClinic();
  return {
    openModal,
    contact: {
      ...data,
      nascimento: dateFormat(data.nascimento, 'DD/MM/YYYY'),
      id_categoria: data.categoria ? data.categoria.id : '',
      telefone_one: data.telefone_one || clinic.ddd,
      telefone_two: data.telefone_two || clinic.ddd
    }
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: (data) => dispatch(contactsShowHideSave({ open: false, contact: {} })),
    save: (data) => dispatch(contactsSave(data)),
    findAll: (data) => dispatch(findAllContacts({ page: 1, totalPage: 10 }))
  };
};

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withSecurity);

export default enhanced(ContactsSaveModal);
