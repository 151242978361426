import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../components/actions/Loader.actions';
import AffiliatesApi from '../../service/AffiliatesApi';
import { getAffiliate } from '../../utils/localStorage/SessionStorageUtils';
import { dateFormat, formatReal } from '../../utils/Formatter';

const AffiliatesReports = (props) => {
  const [report, setReport] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showLoader());
    const affiliate = getAffiliate();
    AffiliatesApi.findReportData(affiliate.id).then(({ data }) => {
      setReport(data);
      dispatch(hideLoader());
    });
  }, []);

  console.log(report);
  return (
    <div className='box-default report-box-default' style={{ minHeight: '60vh' }}>
      <Row>
        <Col sm={12}>
          <strong className='title-medium-plus text-uppercase'>Relatório de Comissão</strong>
        </Col>
      </Row>
      <Table>
        <thead>
          <th>Cliente</th>
          <th>Cupom</th>
          <th>Comissão</th>
          <th>Valor Comissao</th>
          <th>Expira em</th>
          <th>Status</th>
        </thead>
        <tbody>
          {report.map((d) => {
            return (
              <tr key={d.id}>
                <td>{d.cliente_nome}</td>
                <td>{d.numero_cupom}</td>
                <td>{d.tipo_comissao === 'PORCENTAGEM' ? `${d.comissao * 100}%` : formatReal(d.comissao)}</td>
                <td>{formatReal(d.comissao_valor)}</td>
                <td>{dateFormat(d.expira_em, 'DD/MM/YYYY')}</td>
                <td>{d.status_comissao}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default AffiliatesReports;
