import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Select from 'react-select';
import { reactSelectHandleChange, reactSelectStyleIsError } from '../../../utils/ReactSelectUtils';
import { selectStyles } from '../../../../src/config/core.config';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Formik } from 'formik';
import { editClinicSettings, sagaSaveClinicSettings } from './redux/settings.actions';
import states from '../../../utils/states';
import * as Yup from 'yup';
import ErrorField from '../../../components/ErrorField.component';
import { showLoader, hideLoader } from '../../../components/actions/Loader.actions';
import InputMaskComponent from '../../../components/InputMask.component';
import { maskCNPJ, maskCPF, maskRG } from '../../../utils/Formatter';
import ErrorFieldWithoutBootstrap from '../../../components/ErrorFieldWithoutBootstrap.component';
import { isCNPJ, formatToCNPJ, isCPF } from 'brazilian-values';
import { t } from 'typy';

class SettingsClinicPage extends Component {
  state = {
    settingsClinicEdit: {
      show: false
    }
  };

  validationSchema = Yup.object().shape({
    nome: Yup.string().required('Nome Obrigatório')
  });

  closeModal = () => {
    this.props.editClinicSettings({
      clinic: {
        show: false
      }
    });
  };

  saveSettings = (values, actions) => {
    this.props.showLoader();

    let formValid = true;

    if (t(values, 'tecnico.cpf').isDefined && !isCPF(t(values, 'tecnico.cpf').safeString)) {
      actions.setFieldError('tecnico.cpf', 'CPF inválido');
      formValid = false;
    }

    if (formValid) {
      const saveData = {
        ...this.props.settingsClinicEdit,
        ['nome']: values.nome,
        ['razao_social']: values.razao_social,
        ['cnpj']: values.cnpj,
        ['tecnico']: {
          ...values.tecnico
        }
      };
      this.props.saveClinicSettings(saveData);
    } else {
      this.props.hideLoader();
    }
  };

  render() {
    const { settingsClinicEdit = {} } = this.props;
    const { show = false } = settingsClinicEdit;
    return (
      <Modal isOpen={show} toggle={this.closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={this.closeModal}
          close={
            <button className='close' onClick={this.closeModal}>
              &times;
            </button>
          }
        >
          <strong>Editar Informações da Clínica</strong>
        </ModalHeader>
        <Formik
          enableReinitialize={true}
          initialValues={settingsClinicEdit}
          validationSchema={this.validationSchema}
          validateOnChange={false}
          onSubmit={this.saveSettings}
        >
          {({ errors, handleSubmit, handleBlur, handleChange, values }) => {
            const { tecnico = {} } = values;
            return (
              <form onSubmit={handleSubmit}>
                <ModalBody>
                  <div>
                    <Row>
                      <Col sm='12'>
                        <h1 className='title-modal'>Informações da Clínica</h1>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm='12'>
                        <label>
                          Nome da Clínica <span>*</span>
                        </label>
                        <Input
                          className='input-secondary'
                          type='text'
                          onChange={handleChange}
                          name='nome'
                          value={values.nome}
                          invalid={errors.nome ? true : false}
                        />
                        <ErrorField errors={errors} fieldName={'nome'} />
                      </Col>
                    </Row>
                    <Row className='margin-top-10'>
                      <Col sm='7'>
                        <label>Razão Social</label>
                        <input
                          className='input-secondary'
                          type='text'
                          onChange={handleChange}
                          name='razao_social'
                          value={values.razao_social}
                        ></input>
                      </Col>
                      <Col sm='5'>
                        <label>CNPJ</label>
                        <InputMaskComponent
                          guide={false}
                          onChange={handleChange}
                          invalid={errors['cnpj']}
                          value={values.cnpj}
                          name={'cnpj'}
                          id={'cnpj'}
                          mask={maskCNPJ()}
                        />
                        <ErrorFieldWithoutBootstrap fieldName={'cnpj'} errors={errors} />
                      </Col>
                    </Row>

                    <hr></hr>

                    <Row>
                      <Col sm='6'>
                        <label>Responsável Técnico</label>
                        <input
                          className='input-secondary'
                          type='text'
                          onChange={handleChange}
                          name='tecnico.nome'
                          value={tecnico.nome}
                        ></input>
                      </Col>
                      <Col sm='6'>
                        <Row>
                          <Col sm='7'>
                            <label>RG</label>
                            {/*<input className="input-secondary" type="text" onChange={ handleChange } name="tecnico.rg"*/}
                            {/*       value={ tecnico.rg }></input>*/}
                            <Input
                              className='input-secondary'
                              onChange={handleChange}
                              invalid={errors['tecnico.rg']}
                              value={tecnico.rg}
                              name={'tecnico.rg'}
                              id={'tecnico.rg'}
                            />
                            <ErrorFieldWithoutBootstrap fieldName={'tecnico.rg'} errors={errors} />
                          </Col>
                          <Col sm='5'>
                            <label>Emissor</label>
                            <input
                              className='input-secondary'
                              type='text'
                              onChange={handleChange}
                              name='tecnico.emissor'
                              value={tecnico.emissor}
                            ></input>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className='margin-top-10'>
                      <Col sm='6'>
                        <label>CPF</label>
                        <InputMaskComponent
                          guide={false}
                          onChange={handleChange}
                          invalid={errors['tecnico.cpf']}
                          value={tecnico.cpf}
                          name={'tecnico.cpf'}
                          id={'tecnico.cpf'}
                          mask={maskCPF()}
                        />
                        <ErrorFieldWithoutBootstrap fieldName={'tecnico.cpf'} errors={errors} />
                      </Col>
                      <Col sm='4'>
                        <label>CRO</label>
                        <input
                          className='input-secondary'
                          type='text'
                          onChange={handleChange}
                          name='tecnico.cro'
                          value={tecnico.cro}
                        ></input>
                      </Col>
                      <Col sm='2'>
                        <label className='w-100'>UF</label>
                        <Select
                          options={states.map((s) => {
                            return { value: s.sigla, label: s.sigla };
                          })}
                          ignoreCase={true}
                          placeholder={''}
                          defaultValue={states.map((s) => {
                            if (s.sigla === tecnico.cro_uf) {
                              return { value: s.sigla, label: s.sigla };
                            }
                          })}
                          onChange={(e) => reactSelectHandleChange(e.value, handleChange, 'tecnico.cro_uf')}
                          styles={reactSelectStyleIsError(errors, 'tecnico.cro_uf.value', selectStyles)}
                        />
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Row>
                    <Col sm={{ size: 3, offset: 6 }}>
                      <button className='button-primary'>Salvar</button>
                    </Col>
                    <Col sm='3'>
                      <button className='button-tertiary' onClick={this.closeModal}>
                        Cancelar
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = ({ SettingsReducer }) => {
  const settings = SettingsReducer.settings || {};

  return {
    settingsClinicEdit: settings.clinic
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (data) => dispatch(showLoader(data)),
    hideLoader: (data) => dispatch(hideLoader(data)),
    editClinicSettings: (data) => dispatch(editClinicSettings(data)),
    saveClinicSettings: (data) => dispatch(sagaSaveClinicSettings(data))
  };
};

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(SettingsClinicPage);
