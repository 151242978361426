import React, { Component } from 'react';

import { Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';

import { parsePhoneNumber } from 'libphonenumber-js';

import noavatar from '../../../assets/img/noavatar.png';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { appointmentHistorySagaModal } from '../../schedule/redux/Schedule.actions';
import { PERMISSOES } from '../../../config/security/permissoes';
import { IfComponent } from '../../../components/if.component';
import withSecurity from '../../../config/security/security';
import { phoneFormat } from '../../../utils/Formatter';
import AppointmentHistoryModal from '../../schedule/modal/AppointmentHistory.modal';

class PatinentViewModal extends Component {
  render() {
    const { showDetailModal, patientData, closeModal } = this.props;
    const {
      paciente_id,
      nome,
      foto,
      numero_identificacao,
      idade,
      tratamentos_ativos = [],
      telefones = [],
      nome_responsavel,
      tipo_responsavel_id,
      tipo_responsavel_nome
    } = patientData;

    return [
      <Modal
        key='modal-view-patient'
        id='modal-view-patient'
        isOpen={showDetailModal}
        className={'modal-default'}
        toggle={closeModal}
        size={'lg'}
      >
        <ModalHeader
          className='title-primary'
          close={
            <button className='close position-relative' style={{ zIndex: 9 }} onClick={closeModal}>
              &times;
            </button>
          }
        >
          {/* <strong>{ nome }</strong> */}
        </ModalHeader>
        <ModalBody>
          <Container>
            <div className='margin-top-10'>
              <Row>
                <Col sm='4'>
                  <div className='modal-img-user'>
                    <div className='absolute-center'>
                      <img
                        src={foto || noavatar}
                        style={{ width: 180, height: 180 }}
                        className='img-circle'
                        alt='usuario'
                      />
                    </div>
                  </div>
                </Col>
                <Col className='pl-3' sm={'8'}>
                  <Row>
                    <Col style={{ fontSize: 15 }}>
                      <strong className='title-modal name-cap'>{nome}</strong>
                    </Col>
                  </Row>
                  <Row>
                    <Col className='text-modal'>
                      <span>
                        {numero_identificacao && '(' + numero_identificacao + ')'} {idade}
                      </span>
                    </Col>
                  </Row>
                  {tratamentos_ativos.length > 0 && (
                    <div>
                      <Row>
                        <Col>
                          <strong className='subtitle-modal'>Tratamentos Ativos</strong>
                        </Col>
                      </Row>
                      <Row>
                        <Col className='text-modal'>
                          {tratamentos_ativos.map((t) => {
                            return (
                              <div key={t.id}>
                                {t.dentista} ({t.especialidade})
                              </div>
                            );
                          })}
                        </Col>
                      </Row>
                    </div>
                  )}

                  <Row>
                    <Col>
                      <strong className='subtitle-modal'>Contatos</strong>
                    </Col>
                  </Row>
                  <Row>
                    <Col className='text-modal'>
                      {telefones.map((t) => {
                        if (t.telefone) {
                          return (
                            <div>
                              {phoneFormat(t.telefone)} {t.nome_dono_telefone}
                            </div>
                          );
                        }
                      })}
                    </Col>
                  </Row>
                  {tipo_responsavel_id && (
                    <div>
                      <Row>
                        <Col className='text-modal'>
                          <strong className='subtitle-modal'>Responsável </strong>
                          {tipo_responsavel_id === 1 ? (
                            <span>{tipo_responsavel_nome}</span>
                          ) : (
                            <span>
                              {nome_responsavel} ( {tipo_responsavel_nome} ){' '}
                            </span>
                          )}
                        </Col>
                      </Row>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col sm='12' className='btn-list'>
              <button
                className='button-tertiary'
                onClick={() => {
                  if (this.props.redirect) {
                    this.props.redirect();
                  }
                  this.props.history.push(`/patient/${paciente_id}/registration-form/`);
                }}
              >
                Ficha de Cadastro
              </button>
              <button
                className='button-tertiary mx-3'
                onClick={() =>
                  this.props.appointmentHistoryModal({
                    modal: true,
                    patient: {
                      id: paciente_id,
                      nome,
                      foto
                    }
                  })
                }
              >
                Histórico de Consultas
              </button>
              <IfComponent
                conditional={
                  this.props.security.hasModuleAccess(PERMISSOES.modules.tratamento_avaliacoes.id) ||
                  this.props.security.hasModuleAccess(PERMISSOES.modules.tratamento_ficha_clinica.id) ||
                  this.props.security.hasModuleAccess(PERMISSOES.modules.tratamento_ficha_financeira.id) ||
                  this.props.security.hasModuleAccess(PERMISSOES.modules.tratamento_orcamentos.id)
                }
              >
                <button
                  className='button-tertiary'
                  type={'button'}
                  onClick={() => {
                    if (this.props.redirect) {
                      this.props.redirect();
                    }
                    this.props.history.push(`/patient/${paciente_id}/treatment`);
                    this.props.history.replace(`/patient/${paciente_id}/treatment`);
                  }}
                >
                  Tratamentos
                </button>
              </IfComponent>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    ];
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  appointmentHistoryModal: (data) => dispatch(appointmentHistorySagaModal(data))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withRouter, withSecurity);

export default enhanced(PatinentViewModal);
