import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import MessagesComponent from '../../../components/Messages.component';
import UserAPI from '../../../service/User.service';

class UserSettingsChangeMasterUserModal extends Component {
  initState = {
    user: null,
    password: null,
    messageDisplay: { display: false, type: 'danger', message: '' }
  };

  state = this.initState;

  handlerChange = (e) => {
    const {
      target: { name, value }
    } = e;

    this.setState({
      ...this.state,
      [name]: value
    });
  };

  changeMasterUser = async () => {
    const { showLoader, hideLoader, closeModal, refreshList, userSelected } = this.props;
    try {
      let { user, password } = this.state;

      if (!user) {
        user = userSelected;
      }

      if (parseInt(user) === 0) {
        this.setState({
          messageDisplay: {
            display: true,
            type: 'danger',
            message: 'Por favor Selecione um usuário'
          }
        });
      } else if (!password) {
        this.setState({
          messageDisplay: {
            display: true,
            type: 'danger',
            message: 'Por favor digite sua senha'
          }
        });
      } else {
        showLoader();
        await UserAPI.changeMaster(user, password);
        hideLoader();
        refreshList({ currentPage: 1, pageSize: 10 });
        closeModal();
      }
    } catch (error) {
      console.log(error);
      const { response: { data = {} } = {} } = error;

      const messageError = data.message || '';

      hideLoader();
      this.setState({
        messageDisplay: { display: true, type: 'danger', message: messageError }
      });
    }
  };

  closeModal = () => {
    this.props.closeModal();
    this.setState(this.initState);
  };

  render() {
    const { users, modal, userSelected } = this.props;
    const { messageDisplay: { display, type, message } = {}, user } = this.state;

    const closeBtn = (
      <button className='close' onClick={this.closeModal}>
        &times;
      </button>
    );
    return (
      <Modal isOpen={modal} toggle={this.closeModal} className={this.props.className}>
        <ModalHeader className='title-primary' toggle={this.closeModal} close={closeBtn}>
          <strong>Trocar Administrador</strong>
        </ModalHeader>
        <ModalBody>
          <div className='margin-top-10'>
            <Row>
              <Col className='text-center' sm='12'>
                <p className='text-md'>Tem certeza que deseja trocar o administrador?</p>
                <p className='text-md'>Escolha na lista um usuário para ser o nome administrador master</p>
                <p className='text-md'>Você terá sua categoria alterada para Dentista Administrador</p>
              </Col>
            </Row>
            <MessagesComponent display={display} type={type} message={message} />
            <Row className='margin-top-10'>
              <Col sm={{ size: 8, offset: 2 }}>
                <label>Novo Administrador Master</label>
                <select id='soflow' onChange={this.handlerChange} name='user' value={user || userSelected}>
                  <option value={0}></option>
                  {users.map((u) => {
                    return <option value={u.id}>{u.nome}</option>;
                  })}
                </select>
              </Col>
            </Row>
            <Row className='margin-top-10'>
              <Col sm={{ size: 8, offset: 2 }}>
                <div className='input-float'>
                  <input
                    className='input-secondary'
                    type='password'
                    name='password'
                    placeholder='Senha'
                    onChange={(e) => this.handlerChange}
                  />
                  <label htmlFor='senha text-sm font-bold'>Senha</label>
                </div>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col sm={{ size: 3, offset: 6 }}>
              <button className='button-primary' onClick={this.changeMasterUser}>
                Salvar
              </button>
            </Col>
            <Col sm='3'>
              <button className='button-tertiary' onClick={this.closeModal}>
                Cancelar
              </button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}

export default UserSettingsChangeMasterUserModal;
