import React, { Component } from 'react';

import { compose } from 'recompose';
import { connect } from 'react-redux';

class Loader extends Component {
  render() {
    const { display = false } = this.props;

    return (
      <div className='loader-container' style={{ display: display ? 'block' : 'none', zIndex: 999999 }}>
        {/* <svg className="uniSvg" viewBox="0 0 150 150">
          <g>
            <path d="M 50,100 A 1,1 0 0 1 50,0"/>
          </g>
          <g>
            <path d="M 50,75 A 1,1 0 0 0 50,-25"/>
          </g>
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" style={ {stopColor: "var(--primary)", stopOpacity: 1}} />
              <stop offset="100%" style={ {stopColor: "var(--primary-l3)", stopOpacity: 1} } />
            </linearGradient>
          </defs>
        </svg> */}
        <div className='absolute-center'>
          {/* <div class="bigg">Loading...</div> */}
          <div className='lds-ring'>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className='large progress'>
            <div>Loading…</div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { display } = state.LoaderReducer;
  return {
    display
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(Loader);

export function withLoader(WrappedComponent) {
  const hocLoader = (props) => {
    return (
      <div>
        <Loader />
        <WrappedComponent {...props} />
      </div>
    );
  };

  return hocLoader;
}
