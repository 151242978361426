import {
  TREATMENT_EVALUATION_CREATE_MODAL,
  TREATMENT_EVALUATION_MODAL_RECEPTION_AND_REGISTER,
  TREATMENT_EVALUATION_OPEN_MODAL_ANGLE_RATING,
  TREATMENT_EVALUATION_OPEN_MODAL_COMPLAINT,
  TREATMENT_EVALUATION_PAGE,
  TREATMENT_EVALUATION_OPEN_MODAL_PROBLEMS,
  TREATMENT_EVALUATION_OPEN_MODAL_RESULT,
  TREATMENT_EVALUATION_OPEN_MODAL_DOCS,
  TREATMENT_EVALUATION_OPEN_MODAL_OTHER_ANNOTATIONS,
  TREATMENT_EVALUATION_OPEN_MODAL_ATTENDENCE_REGISTER
} from './TreatmentEvaluation.action';

const initialState = {};

const TreatmentEvaluationReducer = (state = initialState, action) => {
  switch (action.type) {
    case TREATMENT_EVALUATION_CREATE_MODAL: {
      return {
        ...state,
        createModal: {
          ...action.payload
        }
      };
    }
    case TREATMENT_EVALUATION_PAGE: {
      return {
        ...state,
        evaluationPage: {
          ...action.payload
        }
      };
    }
    case TREATMENT_EVALUATION_MODAL_RECEPTION_AND_REGISTER: {
      return {
        ...state,
        evaluationReceptionRegisterModal: {
          ...action.payload
        }
      };
    }
    case TREATMENT_EVALUATION_OPEN_MODAL_COMPLAINT: {
      return {
        ...state,
        evaluationComplaintModal: {
          ...action.payload
        }
      };
    }
    case TREATMENT_EVALUATION_OPEN_MODAL_ANGLE_RATING: {
      return {
        ...state,
        evaluationAngleRating: {
          ...action.payload
        }
      };
    }
    case TREATMENT_EVALUATION_OPEN_MODAL_PROBLEMS: {
      return {
        ...state,
        evaluationProblems: {
          ...action.payload
        }
      };
    }
    case TREATMENT_EVALUATION_OPEN_MODAL_RESULT: {
      return {
        ...state,
        evaluationResult: {
          ...action.payload
        }
      };
    }
    case TREATMENT_EVALUATION_OPEN_MODAL_DOCS: {
      return {
        ...state,
        evaluationOrthoDocs: {
          ...action.payload
        }
      };
    }
    case TREATMENT_EVALUATION_OPEN_MODAL_OTHER_ANNOTATIONS: {
      return {
        ...state,
        evaluationOtherAnnotations: {
          ...action.payload
        }
      };
    }
    case TREATMENT_EVALUATION_OPEN_MODAL_ATTENDENCE_REGISTER: {
      return {
        ...state,
        evaluationAttendence: {
          ...action.payload
        }
      };
    }
    default: {
      return state;
    }
  }
};

export default TreatmentEvaluationReducer;
