import React, { Component } from 'react';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Tooltip } from 'reactstrap';

import question from '../../../../assets/img/icons/question.svg';
import Icon from '../../../../components/Icon.component';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { treatmentEvaluationModalResult } from '../redux/TreatmentEvaluation.action';
import Select from 'react-select';
import { Form, Formik } from 'formik';
import { hideLoader, showLoader } from '../../../../components/actions/Loader.actions';
import TreatmentEvaluationApi from '../../../../service/TreatmentEvaluationApi';
import MessagesComponent from '../../../../components/Messages.component';
import { selectStyles } from '../../../../../src/config/core.config';
import * as Yup from 'yup';
import ErrorFieldWithoutBootstrap from '../../../../components/ErrorFieldWithoutBootstrap.component';
import { reactSelectStyleIsError } from '../../../../utils/ReactSelectUtils';
class TreatmentEvaluationResultModal extends Component {
  state = {
    tooltipOpen: false,
    message: {
      display: false,
      type: 'danger',
      text: ''
    }
  };

  formValidate = Yup.object().shape({
    resultado: Yup.object().shape({
      value: Yup.string().nullable().required('Resultado Obrigatório')
    })
  });

  saveEvaluationResult = async (values) => {
    const { showLoader, hideLoader, closeModal, updateParent, formLoad } = this.props;
    const { evaluationResult, evaluation } = formLoad;
    try {
      showLoader();
      await TreatmentEvaluationApi.saveEvaluationResult({
        id: evaluationResult.id,
        avaliacao: evaluation.avaliacao_id,
        resultado_avaliacao: values.resultado.value,
        complexidade_avaliacao: values.complexidade && values.complexidade.value,
        interesse_clinica: values.interesse && values.interesse.value,
        aparelho: values.aparelho && values.aparelho.value
      });

      updateParent();
      closeModal();
    } catch (e) {
      console.log(e);
      this.setState({
        message: {
          display: true,
          type: 'danger',
          text: 'Erro ao salvar resultado da avaliação entre em contato com o suporte'
        }
      });
    }

    hideLoader();
  };

  selectOption = (data, handleChange, name) => {
    handleChange({
      target: {
        name: name,
        value: data
      }
    });
  };

  render() {
    const { message } = this.state;
    const {
      modal,
      closeModal,
      resultsOptions = [],
      clinicInterestsOptions = [],
      complexitiesOptions = [],
      bracesOptions = [],
      formInit
    } = this.props;

    return (
      <Modal isOpen={modal} toggle={closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={closeModal}
          close={
            <button className='close' onClick={closeModal}>
              &times;
            </button>
          }
        >
          <strong>Editar</strong> Resultado da Avaliação
        </ModalHeader>
        <Formik
          validationSchema={this.formValidate}
          validateOnChange={false}
          initialValues={formInit}
          onSubmit={this.saveEvaluationResult}
        >
          {({ errors, values, handleChange }) => (
            <Form>
              <ModalBody>
                <MessagesComponent display={message.display} type={message.type} message={message.text} />
                <div className='mt-2'>
                  <Row className='mt-2'>
                    <Col sm='12'>
                      <label>
                        Resultado da Avaliação<span>*</span>
                      </label>
                    </Col>
                    <Col sm='12'>
                      <Select
                        value={values.resultado}
                        isSearchable={true}
                        ignoreAccents={true}
                        ignoreCase={true}
                        options={resultsOptions}
                        placeholder={''}
                        styles={reactSelectStyleIsError(errors, 'resultado.value', selectStyles)}
                        onChange={(data) => this.selectOption(data, handleChange, 'resultado')}
                      />
                      <ErrorFieldWithoutBootstrap errors={errors} fieldName={'resultado.value'} />
                    </Col>
                  </Row>
                  <Row className='mt-2'>
                    <Col sm='12'>
                      <label>Complexidade do Caso</label>
                    </Col>
                    <Col sm='12'>
                      <Select
                        value={values.complexidade}
                        isSearchable={true}
                        ignoreAccents={true}
                        ignoreCase={true}
                        options={complexitiesOptions}
                        placeholder={''}
                        onChange={(data) => this.selectOption(data, handleChange, 'complexidade')}
                      />
                    </Col>
                  </Row>
                  <Row className='mt-2'>
                    <Col sm='12'>
                      <label>Interesse da Clínica </label>
                      <div>
                        <a href='#' id='question'>
                          <Icon cla className='icon icon-size-smaller ml-2' image={question} alt='question' />
                        </a>
                        <Tooltip
                          placement='top'
                          className='tooltip-white text-xsm font-weight-light'
                          isOpen={this.state.tooltipOpen}
                          target='question'
                          toggle={() => this.setState({ tooltipOpen: !this.state.tooltipOpen })}
                        >
                          Interesse que você tem em tratar este caso
                        </Tooltip>
                      </div>
                    </Col>
                    <Col sm='12'>
                      <Select
                        value={values.interesse}
                        isSearchable={true}
                        ignoreAccents={true}
                        ignoreCase={true}
                        options={clinicInterestsOptions}
                        placeholder={''}
                        styles={selectStyles}
                        onChange={(data) => this.selectOption(data, handleChange, 'interesse')}
                      />
                    </Col>
                  </Row>
                  <Row className='margin-top-10'>
                    <Col sm='12'>
                      <label>Aparelho Preferido pelo Paciente</label>
                    </Col>
                    <Col sm='12'>
                      <Select
                        value={values.aparelho}
                        isSearchable={true}
                        ignoreAccents={true}
                        ignoreCase={true}
                        options={bracesOptions}
                        placeholder={''}
                        styles={selectStyles}
                        onChange={(data) => this.selectOption(data, handleChange, 'aparelho')}
                      />
                    </Col>
                  </Row>
                </div>
              </ModalBody>
              <ModalFooter>
                <Row>
                  <Col className='btn-list' sm={{ size: 6, offset: 6 }}>
                    <button className='button-primary'>Salvar</button>
                    <button className='button-tertiary' type={'button'} onClick={closeModal}>
                      Cancelar
                    </button>
                  </Col>
                </Row>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = ({ TreatmentEvaluationReducer: { evaluationResult = {} } } = {}) => {
  const { formLoad = {} } = evaluationResult;
  const { formData = {} } = formLoad;
  const { results = [], clinicInterests = [], complexities = [], braces = [] } = formData;

  const data = formLoad.evaluationResult || {};

  const defaultOption = { value: null, label: '' };
  const resultsOptions = results.map((t) => ({ value: t.id, label: t.descricao, color: t.rgb }));
  const clinicInterestsOptions = clinicInterests.map((t) => ({ value: t.id, label: t.descricao }));
  const complexitiesOptions = complexities.map((t) => ({ value: t.id, label: t.descricao }));
  const bracesOptions = braces.map((t) => ({ value: t.id, label: t.descricao }));

  resultsOptions.unshift(defaultOption);
  clinicInterestsOptions.unshift(defaultOption);
  complexitiesOptions.unshift(defaultOption);
  bracesOptions.unshift(defaultOption);

  return {
    ...evaluationResult,
    formInit: {
      resultado: data.resultado_avaliacao && resultsOptions.find((r) => r.value === data.resultado_avaliacao.id),
      complexidade:
        data.complexidade_avaliacao && complexitiesOptions.find((r) => r.value === data.complexidade_avaliacao.id),
      interesse: data.interesse_clinica && clinicInterestsOptions.find((r) => r.value === data.interesse_clinica.id),
      aparelho: data.aparelho && bracesOptions.find((r) => r.value === data.aparelho.id)
    },
    resultsOptions,
    clinicInterestsOptions,
    complexitiesOptions,
    bracesOptions
  };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  closeModal: () => dispatch(treatmentEvaluationModalResult({ modal: false }))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(TreatmentEvaluationResultModal);
