import RestTemplate from '../utils/restTemplate/rest.template';

import * as moment from 'moment';
import { pickBy, identity } from 'lodash';
import { t } from 'typy';

export default class PacienteAPI {
  static findAllPatients = (page = 1, totalPage = 10) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate
      .get('/paciente/', {
        pageNumber: page,
        pageSize: totalPage
      })
      .then((response) => response.data);
  };

  static findAllDeletedPatient = (page = 1, totalPage = 10) => {
    const restTemplate = new RestTemplate(true);

    return restTemplate
      .get('/paciente/deleted', {
        pageNumber: page,
        pageSize: totalPage
      })
      .then((response) => response.data);
  };

  static pescInc = async (name) => {
    if (name.length > 2) {
      const restTemplate = new RestTemplate(true);
      return restTemplate.get(`/paciente/pescinc/${name}`).then((response) => response.data);
    } else {
      return Promise.resolve();
    }
  };

  static findById = (id) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/paciente/${id}`).then((response) => response.data);
  };

  static saveNote = (patient, note) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate
      .post(`/paciente/${patient}/anotacao`, {
        data_anotacao: note.data,
        anotacao: note.text
      })
      .then((response) => response.data);
  };

  static deleteNote = (patientId, noteId) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.delete(`/paciente/${patientId}/anotacao/${noteId}`);
  };

  static updateNote = (patient, note) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate
      .put(`/paciente/${patient}/anotacao/${note.id}`, {
        data_anotacao: note.data,
        anotacao: note.text
      })
      .then((response) => response.data);
  };

  static deletePatient = (id) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.delete(`/paciente/${id}`);
  };

  static saveSimplePatient = ({
    name,
    birthDate,
    email,
    whoIndicated,
    indicationName,
    indicationType,
    phone1Name,
    phone1 = '',
    phone1Type,
    phone2Name,
    phone2 = '',
    phone2Type
  }) => {
    const restTemplate = new RestTemplate(true);
    let phone1Number = phone1.replace(/[^a-zA-Z0-9]/g, '');
    let phone2Number = phone2.replace(/[^a-zA-Z0-9]/g, '');
    const model = {
      data_nascimento: birthDate && moment(birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      email_contato: email,
      indicacao: {
        nome: indicationName,
        tipo_indicacao_id: indicationType
      },
      nome: name,
      telefones: [
        {
          dono: phone1Name,
          telefone: phone1Number,
          tipo_telefone: phone1Type
        },
        {
          dono: phone2Name,
          telefone: phone2Number,
          tipo_telefone: phone2Type
        }
      ]
    };

    if(t(whoIndicated, 'id').isDefined) {
      model.indicacao.indicacao_id = whoIndicated.id;
    }

    return restTemplate.post('/paciente/', model);
  };

  static savePatient = ({
    paciente_id,
    cpf,
    data_nascimento,
    email_contato,
    foto,
    fotoUpload,
    indicacao_relacao,
    tipo_indicacao_nome,
    tipo_indicacao_id,
    nome,
    nome_preferido,
    nome_indicacao,
    numero_identificacao,
    profissao,
    cpf_responsavel,
    nome_responsavel,
    rg_responsavel,
    orgaoExpedidorResponsavel,
    tipo_responsavel_id,
    rg,
    orgaoExpedidor,
    sexo,
    telefones,
    whoIndicated = {}
  }) => {
    const dataNascimentoFormatada = moment(data_nascimento).format('YYYY-MM-DD') || null;

    const restTemplate = new RestTemplate(true);
    const dataSave = pickBy(
      {
        id: paciente_id,
        cpf,
        data_nascimento: dataNascimentoFormatada,
        email_contato,
        foto,
        fotoUpload,
        indicacao: pickBy(
          {
            indicacao_id: whoIndicated && whoIndicated.id, // Adicionar o tipo de indicacao do auto complete
            nome: nome_indicacao,
            tipo_indicacao_id
          },
          identity
        ),
        nome,
        nome_preferido,
        numero_identificacao,
        profissao,
        responsavel: pickBy(
          {
            cpf: cpf_responsavel,
            nome: nome_responsavel,
            rg: rg_responsavel && rg_responsavel.replace('.', ''),
            tipo_responsavel: tipo_responsavel_id,
            orgaoExpedidor: orgaoExpedidorResponsavel
          },
          identity
        ),
        rg: rg && rg.replace('.', ''),
        orgaoExpedidor,
        sexo: sexo && sexo.substr(0, 1),
        telefones: telefones.map((t) => {
          return {
            id: t.id,
            dono: t.nome_dono_telefone,
            telefone: t.telefone,
            tipo_telefone: t.tipo_telefone
          };
        })
      },
      identity
    );
    return restTemplate.put('/paciente/', dataSave);
  };

  static uploadAvatar = (id, image, extension) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post('/paciente/avatar/upload', {
      patient_id: id,
      image,
      extension: extension.replace('.', '')
    });
  };

  static findPatientReportOverview = (orderBy, dentists, status, page = 1, totalPage = 15) => {
    const restTemplate = new RestTemplate(true);
    let param = '';
    let separator = '?';
    if (!t(dentists).isNullOrUndefined && !t(dentists).isEmptyArray) {
      param += `${separator}dentists=${dentists.join(',')}`;
      separator = '&';
    }

    if (!t(status).isNullOrUndefined && !t(status).isEmptyArray) {
      param += `${separator}status=${status.join(',')}`;
      separator = '&';
    }

    if (orderBy) {
      param += `${separator}orderBy=${orderBy}&page=${page}&totalPerPage=${totalPage}`;
    }

    return restTemplate.get(`/paciente/report/overview${param}`);
  };
}
