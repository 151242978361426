import MultiSelect from '@khanacademy/react-multi-select';
import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { MdKeyboardArrowUp } from 'react-icons/all';
import { FiPlusCircle } from 'react-icons/fi';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import ReactTooltip from 'react-tooltip';
import { Col, Container, Row, Table, UncontrolledTooltip } from 'reactstrap';
import t from 'typy';
import close from '../../assets/img/icons/close.svg';
import notes from '../../assets/img/icons/notes.svg';
import remarcar from '../../assets/img/icons/remarcar.svg';
import warning from '../../assets/img/icons/warning.svg';
import noavatar from '../../assets/img/noavatar.png';
import { hideLoader, showLoader } from '../../components/actions/Loader.actions';
import Icon from '../../components/Icon.component';
import { IfComponent } from '../../components/if.component';
import ModalMessageConfirm from '../../components/ModalMessageConfirm.component';
import MonthPicker from '../../components/MonthPicker';
import ControleRetornoAPI from '../../service/ControleRetornoAPI';
import { phoneFormat } from '../../utils/Formatter';
import ToastUtils from '../../utils/ToastUtils';
import PatinentViewModal from '../patinent/components/PatientViewModal.component';
import ScheduleControlReturnAddModal from './modal/ScheduleControlReturnAddModal';
import ScheduleControlReturnEditModal from './modal/ScheduleControlReturnEditModal';
import { scheduleFromPatient } from './redux/Schedule.actions';
import { showToast } from './TostPatient.component';

import ToastCustomMessageComponent from '../../components/ToastCustomMessage.component';
import danger from '../../assets/img/icons/danger1.svg'
import edit from '../../assets/img/icons/edit.svg' 


const ScheduleControlReturnPage = ({ dentist, history }) => {
  const dispatch = useDispatch();
  const modalAddControlReturnRef = useRef();
  const modalEditControlReturnRef = useRef();
  const [pacientesControleRetorno, setPacientesControleRetorno] = useState([]);
  const [totalPatients, setTotalPatients] = useState(0);
  const [confirmaExclusaoModal, setConfirmaExclusaoModal] = useState(false);
  const [pacienteSelecionado, setPacienteSelecionado] = useState(null);
  const [pacienteSchedule, setPacienteSchedule] = useState(null);
  const [sortList, setSortList] = useState({type:null,sort:null});
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [patientSelected, setPatientSelected] = useState({});
  const [returnType, setReturnType] = useState([]);
  const [returnTypeOptions, setReturnTypeOptions] = useState([]);
  const [controlAndReturnCleanData, setControlAndReturnCleanData] = useState({});
  const [totalPatientsCleanValue, setTotalPatientsCleanValue] = useState(0);
  var DataInicial = moment().format('MMM YYYY')
  const [selectedDate, setSelectedDate] = useState(moment().format('MM/YYYY'));
  const [selectDateSec, setSelectDateSec] = useState(DataInicial[0].toUpperCase()+DataInicial.substring(1))
  const [patientId, setPatientId] = useState();
  const [anotacoes, setAnotacoes] = useState('');
  const [mesAlvo, setMesAlvo] = useState('');
  const componentRef = useRef(null);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    setHeight(componentRef.current ? componentRef.current.offsetHeight : 0);
  }, []);
  
  useEffect(() => {
    findAllPacientes();
    getAllReturnTypeOptions();
  }, [dentist]);

  useEffect(() => {
    if (pacienteSchedule != null) {
      schedule(pacienteSchedule);
    }
  }, [pacienteSchedule]);

  const closeModalConfirm = () => {
    setPacienteSelecionado(null);
    setConfirmaExclusaoModal(false);
  };


  const deletarPacienteDaLista = () => {
    try {
      dispatch(showLoader());
      ControleRetornoAPI.deleteControleERetorno(pacienteSelecionado.id).then(() => {
        dispatch(hideLoader());
        closeModalConfirm();
        findAllPacientes();
      });
    } catch (e) {
      console.error(e);
      dispatch(hideLoader());
      ToastUtils.error('Erro ao deletar paciente, entre em contato com o suporte');
    }
  };

  const findAllPacientes = () => {
    dispatch(showLoader());
    ControleRetornoAPI.buscarTodosControlesERetornos(dentist)
      .then(({ data }) => {
        //setPacientesControleRetorno(data);// foi tirado para iniciar c o mes atual
        setControlAndReturnCleanData(data);
        setTotalPatientsCleanValue(data.length);
        handleControlAndReturn()// foi add para iniciar no mes atual
        //setTotalPatients(data.length);// for tirado para iniciar c o tamanho filtrado (do mes atual)
        dispatch(hideLoader());
      })
      .catch((err) => {
        dispatch(hideLoader());
        ToastUtils.error('Erro ao buscar controle e retorno, entre em contato com o suporte');
        console.error(err);
      });
  };

  const schedule = async (pacienteSelecionado) => {
    try {
      dispatch(showLoader());

      const { data: procedimento } = await ControleRetornoAPI.getProcedimentoDoControleERetorno(
        dentist.value,
        pacienteSelecionado.id
      );

      const scheduleData = {
        paciente: {
          ...pacienteSelecionado.paciente,
          id: pacienteSelecionado.paciente.paciente_id
        },
        procedimento: procedimento,
        data_agendamento: '',
        controleRetorno: pacienteSelecionado.id
      };

      showToast({
        schedule: scheduleData,
        onClose: () => {
          // ScheduleAPI.removeScheduleRedialList({ dentist: dentist.value, id: scheduleRedial.id });
          // this.props.scheduleFromPatient({ schedule: {} });
        },
        onCancel: () => dispatch(scheduleFromPatient({ schedule: {} }))
      });

      dispatch(scheduleFromPatient({ schedule: scheduleData }));
      history.push('/schedule');
      dispatch(hideLoader());
    } catch (e) {
      console.error(e);
      ToastUtils.error('Erro ao buscar dados para agendamento, entre em contato com o suporte');
      dispatch(hideLoader());
    }
  };

  function sort(type) {
    switch (type) {
      case 'P': {
        if (sortList.type == 'P' && sortList.sort == 'asc') {
          pacientesControleRetorno.sort((a, b) => {
            return b.paciente.nome.localeCompare(a.paciente.nome);
          });
          setSortList({ type: 'P', sort: 'desc' });
        } else {
          pacientesControleRetorno.sort((a, b) => {
            return a.paciente.nome.localeCompare(b.paciente.nome);
          });
          setSortList({ type: 'P', sort: 'asc' });
        }
        break;
      }
      case 'M': {
        if (sortList.type == 'M' && sortList.sort == 'asc') {
          pacientesControleRetorno.sort((a, b) => {
            const momentA = moment(a.mesAlvo, 'MM/YYYY');
            const momentB = moment(b.mesAlvo, 'MM/YYYY');
            return momentA.isAfter(momentB) ? 1 : -1;
          });
          setSortList({ type: 'M', sort: 'desc' });
        } else {
          pacientesControleRetorno.sort((a, b) => {
            const momentA = moment(a.mesAlvo, 'MM/YYYY');
            const momentB = moment(b.mesAlvo, 'MM/YYYY');
            return momentA.isBefore(momentB) ? 1 : -1;
          });
          setSortList({ type: 'M', sort: 'asc' });
        }
        break;
      }
      case 'T': {
        if (sortList.type == 'T' && sortList.sort == 'asc') {
          pacientesControleRetorno.sort((a, b) => {
            return b.tipoRetorno.descricao.localeCompare(a.tipoRetorno.descricao);
          });
          setSortList({ type: 'T', sort: 'desc' });
        } else {
          pacientesControleRetorno.sort((a, b) => {
            return a.tipoRetorno.descricao.localeCompare(b.tipoRetorno.descricao);
          });
          setSortList({ type: 'T', sort: 'asc' });
        }
        break;
      }
    }
    setPacientesControleRetorno(pacientesControleRetorno);
  }

  function showModalDetail(patient) {
    setPatientSelected(patient);
    setShowDetailModal(true);
  }

  function closeDetailModal() {
    setPatientSelected({});
    setShowDetailModal(false);
  }

  function getAllReturnTypeOptions() {
    dispatch(showLoader());
    ControleRetornoAPI.getReturnTypeOptions()
      .then(({ data }) => {
        dispatch(hideLoader());
        setReturnTypeOptions(
          data.map((c) => {
            return {
              label: c.description,
              value: c.type
            };
          })
        );
      })
      .catch((err) => {
        dispatch(hideLoader());
        ToastUtils.error('Não foi possível encontrar os Tipo de Retorno! Por favor, entre em contato com o suporte.', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        console.error(err);
      });
  }

  async function handleControlAndReturn() {
    dispatch(hideLoader());

    try {
      const { data } = await ControleRetornoAPI.fetchFilteredPatients(
        dentist.data.id,
        returnType.toString(),
        selectedDate
      );

      if (data.length === 0) {
        ToastUtils.time(
          <ToastCustomMessageComponent
            message={'Nenhum paciente encontrado! Por favor, realize uma nova busca'}
            iconImage={danger}
          />,
          {
            autoClose: 8000,
            hideProgressBar: true,
            position: 'top-center'
          }
        );
        return;
      }
      setPacientesControleRetorno(data);
      setTotalPatients(data.length);
    } catch (err) {
      dispatch(hideLoader());
      console.error(err);
      ToastUtils.error('Erro ao filtrar a lista, entre em contato com o suporte.');
    }
  }

  return (
    <div className={'content-box-without-color mt-0'} key={'control-return'}>
      <Row className='mt-0'>
        <Col sm={12} className='mt-0'>
          <div className='box-default mt-0' style={{ minHeight: '60vh' }}>
            <Container className='mt-0'>
              <Row style={{ display: 'flex', alignItems: 'center' }} className='mt-0'>
                <Col sm='auto' className='p-2'>
                  <strong className='title-large text-uppercase'>Controle e Contenção</strong>
                </Col>
                <Col sm='auto'>
                  <a
                    href='javascript:'
                    id='AddListScheduleControlReturn'
                    onClick={() => modalAddControlReturnRef.current.openModal({ dentist })}
                  >
                    <FiPlusCircle color='#616e88' size={22} data-tip data-for={'tooltipAdicionar'} />
                  </a>
                  <UncontrolledTooltip
                    placement='top'
                    target='AddListScheduleControlReturn'
                    className='tooltip-griks'
                    type='light'
                  >
                    Adicionar Paciente à Lista
                  </UncontrolledTooltip>
                </Col>
              </Row>

              <Formik
                initialValues={{
                  order: 'A'
                }}
                validateOnChange={false}
              >
                {({ values, errors, handleChange, isSubmitting, resetForm, submitForm, dirty }) => {
                  return (
                    <Form>
                      <Container>
                        <Row className='mt-2 mr-0'>
                          <Col sm='2'>
                            <MultiSelect
                              options={t(returnTypeOptions).safeArray}
                              selected={returnType}
                              onSelectedChanged={(selected) => setReturnType(selected)}
                              disableSearch={true}
                              overrideStrings={{
                                selectSomeItems: 'Tipo de Retorno',
                                allItemsAreSelected: 'Todos os Tipos de Retorno',
                                selectAll: 'Selecionar Todos',
                                search: 'Buscar'
                              }}
                            />
                          </Col>
                          <Col sm='2'>
                            <MonthPicker
                              anoInicial={10}
                              valueHowMoment={true}
                              name={'targetMonth'}
                              handleChange={handleChange}
                              values={values}
                              placeholder={selectDateSec}
                              callbackOnChange={(value) => setSelectedDate(value._i.padStart(7,'0'))}
                            />
                          </Col>

                          <Col sm='1' className='d-flex align-items-center justify-content-center'>
                            
                              <button
                                type={'button'}
                                style={{ minWidth: 103.05, height: 38 }}
                                className='btn btn-secondary-l5 border border-secondary-l4 text-sm'
                                onClick={handleControlAndReturn}
                              >
                                Filtrar
                              </button>
                            
                          </Col>
                          <Col sm='1'>
                            
                              <button
                                type={'button'}
                                style={{ minWidth: 103.05, height: 38 }}
                                className='btn btn-outline-restartfilter text-sm'
                                onClick={() => {
                                  resetForm({ order: 'A' });
                                  setReturnType([]);
                                  setSelectedDate('');
                                  setSelectDateSec('Mes Alvo')
                                  setPacientesControleRetorno(controlAndReturnCleanData);
                                  setTotalPatients(totalPatientsCleanValue);
                                }}
                              >
                                Limpar Filtro
                              </button>
                            
                          </Col>

                          <Col sm='6'>
                            <button
                              style={{
                                minWidth: 103.05,
                                height: 38,
                                cursor: 'unset'
                              }}
                              className='btn btn-secondary-l5 border border-secondary-l4 text-sm font-bold float-right'
                            >
                              Total:&nbsp;{totalPatients}
                            </button>
                          </Col>
                        </Row>
                      </Container>
                    </Form>
                  );
                }}
              </Formik>

              <Table className='table-header-fixed mt-2' responsive>
                <thead>
                  <tr className='row py-2'>
                    <th className='col-sm-3 py-0 cursor-pointer' onClick={() => sort('P')}>
                      <a className='text-sm text-secondary-d4'>
                        Paciente
                        <IfComponent conditional={sortList.type === null && sortList.sort === null}>
                          <MdKeyboardArrowDown size={'21'} />
                        </IfComponent>
                        <IfComponent conditional={sortList.type === 'P' && sortList.sort === 'asc'}>
                          <MdKeyboardArrowDown size={'21'} />
                        </IfComponent>
                        <IfComponent conditional={sortList.type === 'P' && sortList.sort === 'desc'}>
                          <MdKeyboardArrowUp size={'21'} />
                        </IfComponent>
                      </a>
                    </th>
                    <th className='col-sm-1 py-0 p-0 cursor-pointer' onClick={() => sort('M')}>
                      <a className='text-sm text-secondary-d4'>
                        Mês Alvo
                        <IfComponent conditional={sortList.type === null && sortList.sort === null}>
                          <MdKeyboardArrowDown size={'21'} />
                        </IfComponent>
                        <IfComponent conditional={sortList.type === 'M' && sortList.sort === 'asc'}>
                          <MdKeyboardArrowDown size={'21'} />
                        </IfComponent>
                        <IfComponent conditional={sortList.type === 'M' && sortList.sort === 'desc'}>
                          <MdKeyboardArrowUp size={'21'} />
                        </IfComponent>
                      </a>
                    </th>
                    <th className='col-sm-2 py-0'>
                      <a
                        className='text-sm text-secondary-d4 pl-3 cursor-pointer text-nowrap'
                        onClick={() => sort('T')}
                      >
                        Tipo de Retorno
                        <IfComponent conditional={sortList.type === null && sortList.sort === null}>
                          <MdKeyboardArrowDown size={'21'} />
                        </IfComponent>
                        <IfComponent conditional={sortList.type === 'T' && sortList.sort === 'asc'}>
                          <MdKeyboardArrowDown size={'21'} />
                        </IfComponent>
                        <IfComponent conditional={sortList.type === 'T' && sortList.sort === 'desc'}>
                          <MdKeyboardArrowUp size={'21'} />
                        </IfComponent>
                      </a>
                    </th>
                    <th className='col-sm-1 py-0'>
                      <span className='text-sm text-secondary-d4 text-nowrap'>Último Atend</span>
                    </th>
                    <th className='col-sm-1 py-0 pl-5'>
                      <span className='text-sm text-secondary-d4 font-weight-bold text-nowrap'>Anotações</span>
                    </th>
                    <th className='col-sm-3 py-0 pl-5'>
                      <span className='text-sm text-secondary-d4 font-weight-bold'>Contato</span>
                    </th>
                    <th className='col-sm-1 icon-tab-schedu non-hover py-0 top'>
                      <div style={{ maxHeight: 20 }} className='float-right'>
                        {
                          /*
                          <a href={'#'} style={{ cursor: 'default' }}>
                          <Icon className='icon icon-size-one' image={edit} alt='calendar icon' /> &nbsp;
                          </a> 
                          */
                        }

                        <a href={'#'} style={{ cursor: 'default' }}>
                          <Icon className='icon icon-size-one' image={remarcar} alt='calendar icon' /> &nbsp;
                        </a>

                        <a href={'#'} style={{ cursor: 'default' }}>
                          <Icon className='icon icon-size-one' image={close} alt='close icon' />
                        </a>
                      </div>
                    </th>
                  </tr>
                </thead>
                {
                  //<tbody style={{ height:100,maxHeight: 'calc(100vh - 400px)' }} ref={componentRef}>
                }
                <tbody style={{ height:'calc(100vh - 400px)',maxHeight: 'calc(100vh - 400px)' }} ref={componentRef}>
                  {pacientesControleRetorno.map((p) => {
                    var estilo = (pacientesControleRetorno.length * 62 > height) ? 0 : 17
                    return (
                      <tr className={'row py-0 box-basic px-0'} key={p.id} >
                        <td className='col-sm-3'>
                          <Row className='pb-0 cursor-pointer'>
                            <Col sm={2}>
                              <img
                                className={'img-user-avatar img-xxldpi cursor-pointer'}
                                src={t(p, 'paciente.foto').safeString || noavatar}
                                onClick={() => showModalDetail(p.paciente)}
                                alt='usuario'
                              />
                            </Col>
                            <Col sm={10} className='p-0'>
                              <div className='title-medium pl-3'>
                                <p className='text-sm font-bold text-secondary text-nowrap'>
                                  {(t(p, 'paciente.nome').safeString.length > 30) ?
                                    <a data-tip data-for={`name_${p.id}`}>
                                      
                                      {t(p, 'paciente.nome').safeString.match(/.{1,30}/g)[0]}...
                                      
                                      <ReactTooltip className='tooltip-griks' id={`name_${p.id}`} type='gray'>
                                        {t(p, 'paciente.nome').safeString}
                                      </ReactTooltip>
                                    </a> 
                                    : 
                                    t(p, 'paciente.nome').safeString
                                  }
                                </p>
                                <p className='text-sm font-light text-secondary text-nowrap'>
                                  {t(p, 'paciente.idade').safeString}
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </td>
                        <td className='col-sm-1 pr-0 pl-0'>
                          <span className='text-sm text-secondary'>
                            {moment(t(p, 'mesAlvo').safeString, 'MM/YYYY').format('MMM YYYY')}
                          </span>
                        </td>
                        <td className='col-sm-2'>
                          <span className='text-sm text-secondary pl-3 text-nowrap'>
                            {t(p, 'tipoRetorno.descricao').safeString}
                          </span>
                        </td>
                        <td className='col-sm-1'>
                          <span className='text-sm text-secondary text-nowrap'>
                            <IfComponent conditional={t(p, 'ultimoAtendimento').isDefined}>
                              {moment(t(p, 'ultimoAtendimento').safeString, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                            </IfComponent>
                          </span>
                        </td>
                        {p.anotacoes ? (
                          <td className='col-sm-1 pl-5'>
                            <span className='text-sm text-secondary text-center'>
                              <Icon
                                className='icon icon-size-one'
                                image={notes}
                                alt='notes'
                                data-tip
                                data-for={`notes_${p.id}`}
                              />
                              <ReactTooltip className='tooltip-griks' id={`notes_${p.id}`} type='light'>
                                {p.anotacoes}
                              </ReactTooltip>
                            </span>
                          </td>
                        ) : (
                          <td className='col-sm-1 pl-5'></td>
                        )}
                        <td className='col-sm-3 pl-5'>
                          {t(p, 'paciente.telefones')
                            .safeArray.filter((t) => t.telefone !== '')
                            .map((tel) => {
                              return (
                                <p key={tel.id} className='font-light text-sm text-secondary text-nowrap'>
                                  {' '}
                                  {phoneFormat(t(tel, 'telefone').safeString, 'BR')} -{' '}
                                  {t(tel, 'nome_dono_telefone').safeString}{' '}
                                </p>
                              );
                            })}
                        </td>
                        <td className='col-sm-1 icon-tab-schedu'>
                          <div className='float-right'>
                            {
                              /*
                              <a href={'#'} data-tip data-for={'editToolTip'}>
                              <Icon 
                                className='icon icon-size-one'
                                image={edit}
                                onClick={() => {
                                  setPatientId(p.id)
                                  setAnotacoes(p.anotacoes)
                                  setMesAlvo(p.mesAlvo)
                                  modalEditControlReturnRef.current.openModal();
                                }}
                              />
                            </a>
                            <ReactTooltip className='tooltip-griks' id='editToolTip' type='gray'>
                              Editar
                            </ReactTooltip>
 
                              */
                            }
                            <a
                              href={'javascript:'}
                              data-tip
                              data-for={'scheduleTooltip'}
                              onClick={() => setPacienteSchedule(p)}
                            >
                              <Icon className='icon icon-size-one' image={remarcar} alt='calendar icon' /> &nbsp;
                            </a>
                            <ReactTooltip id='scheduleTooltip' className='tooltip-griks' type='gray'>
                              Agendar Consulta
                            </ReactTooltip>

                            <a href={'#'} style={{ marginTop: 2,marginRight:estilo}} data-tip data-for={'deleteToolTip'}>
                              <Icon
                                className='icon icon-size-one'
                                image={close}
                                alt='close icon'
                                onClick={() => {
                                  setConfirmaExclusaoModal(true);
                                  setPacienteSelecionado(p);
                                }}
                              />
                              <ReactTooltip className='tooltip-griks' id='deleteToolTip' type='gray'>
                                Excluir
                              </ReactTooltip>
                            </a>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </Container>
            <ModalMessageConfirm
              key={'confirmModal'}
              title={<strong>Confirmar Exclusão?</strong>}
              message={
                <span className='text-secondary'>
                  <p>
                    Confirmar a Exclusão <strong>{t(pacienteSelecionado, 'paciente.nome').safeString}</strong> ?
                  </p>
                </span>
              }
              openModal={confirmaExclusaoModal}
              icon={warning}
              close={closeModalConfirm}
              footer={
                <Row>
                  <Col sm='3'>
                    <button className='button-tertiary' onClick={closeModalConfirm}>
                      Não
                    </button>
                  </Col>
                  <Col sm={{ size: 3, offset: 6 }}>
                    <button className='button-primary' onClick={deletarPacienteDaLista}>
                      Sim
                    </button>
                  </Col>
                </Row>
              }
            />
          </div>
        </Col>
      </Row>
      <ScheduleControlReturnAddModal ref={modalAddControlReturnRef} callback={findAllPacientes} />
      <ScheduleControlReturnEditModal ref={modalEditControlReturnRef} patientId={patientId} anotacoes={anotacoes} mesAlvo={mesAlvo} pacientes={pacientesControleRetorno}/>
      
      <PatinentViewModal
        showDetailModal={showDetailModal}
        patientData={patientSelected}
        closeModal={closeDetailModal}
      />
    </div>
  );
};

export default withRouter(ScheduleControlReturnPage);
