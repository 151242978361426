export const TREATMENT_CLINICAL_RECORD_PAGE = 'TREATMENT_CLINICAL_RECORD_PAGE';
export const TREATMENT_CLINICAL_RECORD_SUMMARY_PRE_MODAL = 'TREATMENT_CLINICAL_RECORD_SUMMARY_PRE_MODAL';
export const TREATMENT_CLINICAL_RECORD_SUMMARY_MODAL = 'TREATMENT_CLINICAL_RECORD_SUMMARY_MODAL';
export const TREATMENT_CLINICAL_PRE_LOAD_ATTENDENCIES = 'TREATMENT_CLINICAL_PRE_LOAD_ATTENDENCIES';
export const TREATMENT_CLINICAL_ATTENDENCIES = 'TREATMENT_CLINICAL_ATTENDENCIES';
export const TREATMENT_CLINICAL_ATTENDENCIE_PRE_MODAL = 'TREATMENT_CLINICAL_ATTENDENCIE_PRE_MODAL';
export const TREATMENT_CLINICAL_ATTENDENCIE_MODAL = 'TREATMENT_CLINICAL_ATTENDENCIE_MODAL';

// Diagnostic
export const TREATMENT_CLINICAL_RECORD_PRE_LOAD_DIAGNOSTIC = 'TREATMENT_CLINICAL_RECORD_PRE_LOAD_DIAGNOSTIC';
export const TREATMENT_CLINICAL_RECORD_DIAGNOSTIC = 'TREATMENT_CLINICAL_RECORD_DIAGNOSTIC';
export const TREATMENT_CLINICAL_RECORD_DIAGNOSTIC_ANGLE_PRE_LOAD_MODAL =
  'TREATMENT_CLINICAL_RECORD_DIAGNOSTIC_ANGLE_PRE_LOAD_MODAL';
export const TREATMENT_CLINICAL_RECORD_DIAGNOSTIC_ANGLE_MODAL = 'TREATMENT_CLINICAL_RECORD_DIAGNOSTIC_ANGLE_MODAL';
export const TREATMENT_CLINICAL_RECORD_DIAGNOSTIC_PROBLEMS_MODAL =
  'TREATMENT_CLINICAL_RECORD_DIAGNOSTIC_PROBLEMS_MODAL';
export const TREATMENT_CLINICAL_RECORD_DIAGNOSTIC_OTHER_INFOS_MODAL =
  'TREATMENT_CLINICAL_RECORD_DIAGNOSTIC_OTHER_INFOS_MODAL';

// Treatment Plan
export const TREATMENT_CLINICAL_RECORD_PRELOAD_PLANS = 'TREATMENT_CLINICAL_RECORD_PRELOAD_PLANS';
export const TREATMENT_CLINICAL_RECORD_PLANS = 'TREATMENT_CLINICAL_RECORD_PLANS';
export const TREATMENT_CLINICAL_RECORD_PLANS_GOALS_MODAL = 'TREATMENT_CLINICAL_RECORD_PLANS_GOALS_MODAL';
export const TREATMENT_CLINICAL_RECORD_PLANS_STRATEGIES_MODAL = 'TREATMENT_CLINICAL_RECORD_PLANS_STRATEGIES_MODAL';
export const TREATMENT_CLINICAL_RECORD_PLANS_OTHERS_DETAILS_MODAL =
  'TREATMENT_CLINICAL_RECORD_PLANS_OTHERS_DETAILS_MODAL';
export const TREATMENT_CLINICAL_RECORD_PLANS_CONTENTION_MODAL = 'TREATMENT_CLINICAL_RECORD_PLANS_CONTENTION_MODAL';
export const TREATMENT_CLINICAL_RECORD_PLANS_GADGETS_PRE_MODAL = 'TREATMENT_CLINICAL_RECORD_PLANS_GADGETS_PRE_MODAL';
export const TREATMENT_CLINICAL_RECORD_PLANS_GADGETS_MODAL = 'TREATMENT_CLINICAL_RECORD_PLANS_GADGETS_MODAL';

export const TREATMENT_CLINICAL_RECORD_ANTICIPATE_CONFIRM_MODAL = 'TREATMENT_CLINICAL_RECORD_ANTICIPATE_CONFIRM_MODAL';

export function clinicalRecordPage({ show, formLoad }) {
  return {
    type: TREATMENT_CLINICAL_RECORD_PAGE,
    payload: {
      show,
      formLoad
    }
  };
}

export function clinicalRecordSummaryPreModal({ modal, formLoad }) {
  return {
    type: TREATMENT_CLINICAL_RECORD_SUMMARY_PRE_MODAL,
    payload: {
      modal,
      formLoad
    }
  };
}

export function clinicalRecordSummaryModal({ modal, formLoad }) {
  return {
    type: TREATMENT_CLINICAL_RECORD_SUMMARY_MODAL,
    payload: {
      modal,
      formLoad
    }
  };
}

export function clinicalRecordPreAttendancies({ data }) {
  return {
    type: TREATMENT_CLINICAL_PRE_LOAD_ATTENDENCIES,
    payload: {
      data
    }
  };
}

export function clinicalRecordAttendancies({ data }) {
  return {
    type: TREATMENT_CLINICAL_ATTENDENCIES,
    payload: {
      data
    }
  };
}

export function clinicalRecordAttendanciePreModal({ modal, treatment, record, attendence }) {
  return {
    type: TREATMENT_CLINICAL_ATTENDENCIE_PRE_MODAL,
    payload: {
      modal,
      treatment,
      record,
      attendence
    }
  };
}

export function clinicalRecordAttendancieModal({ modal, formLoad, treatment, record }) {
  return {
    type: TREATMENT_CLINICAL_ATTENDENCIE_MODAL,
    payload: {
      modal,
      formLoad,
      treatment,
      record
    }
  };
}

export function clinicalRecordDiagnosticPreLoad({ treatment, record }) {
  return {
    type: TREATMENT_CLINICAL_RECORD_PRE_LOAD_DIAGNOSTIC,
    payload: {
      treatment,
      record
    }
  };
}

export function clinicalRecordDiagnosticPage({ diagnostic, treatment, record, error }) {
  return {
    type: TREATMENT_CLINICAL_RECORD_DIAGNOSTIC,
    payload: {
      diagnostic,
      treatment,
      record,
      error
    }
  };
}

export function clinicalRecordDiagnosticPreLoadModal({ modal, diagnostic, treatment, record }) {
  return {
    type: TREATMENT_CLINICAL_RECORD_DIAGNOSTIC_ANGLE_PRE_LOAD_MODAL,
    payload: {
      modal,
      diagnostic,
      treatment,
      record
    }
  };
}

export function clinicalRecordDiagnosticModal({ modal, diagnostic, treatment, record, formLoad }) {
  return {
    type: TREATMENT_CLINICAL_RECORD_DIAGNOSTIC_ANGLE_MODAL,
    payload: {
      modal,
      diagnostic,
      treatment,
      record,
      formLoad
    }
  };
}

export function clinicalRecordDiagnosticProblemsModal({ modal, diagnostic, treatment, record }) {
  return {
    type: TREATMENT_CLINICAL_RECORD_DIAGNOSTIC_PROBLEMS_MODAL,
    payload: {
      modal,
      diagnostic,
      treatment,
      record
    }
  };
}

export function clinicalRecordDiagnosticOtherInfosModal({ modal, diagnostic, treatment, record }) {
  return {
    type: TREATMENT_CLINICAL_RECORD_DIAGNOSTIC_OTHER_INFOS_MODAL,
    payload: {
      modal,
      diagnostic,
      treatment,
      record
    }
  };
}

export function clinicalRecordTreatmentPlanPreLoad({ show, treatment, record }) {
  return {
    type: TREATMENT_CLINICAL_RECORD_PRELOAD_PLANS,
    payload: {
      show,
      treatment,
      record
    }
  };
}

export function clinicalRecordTreatmentPlan({ show, treatment, record, plan, error, diagnostic }) {
  return {
    type: TREATMENT_CLINICAL_RECORD_PLANS,
    payload: {
      show,
      treatment,
      record,
      plan,
      error,
      diagnostic
    }
  };
}

export function clinicalRecordTreatmentPlanModal({ modal, treatment, record, plan }) {
  return {
    type: TREATMENT_CLINICAL_RECORD_PLANS_GOALS_MODAL,
    payload: {
      modal,
      treatment,
      record,
      plan
    }
  };
}

export function clinicalRecordTreatmentStrategiesModal({ modal, treatment, record, plan }) {
  return {
    type: TREATMENT_CLINICAL_RECORD_PLANS_STRATEGIES_MODAL,
    payload: {
      modal,
      treatment,
      record,
      plan
    }
  };
}

export function clinicalRecordTreatmentOthersDetailsModal({ modal, treatment, record, plan }) {
  return {
    type: TREATMENT_CLINICAL_RECORD_PLANS_OTHERS_DETAILS_MODAL,
    payload: {
      modal,
      treatment,
      record,
      plan
    }
  };
}

export function clinicalRecordTreatmentContentionModal({ modal, treatment, record, plan }) {
  return {
    type: TREATMENT_CLINICAL_RECORD_PLANS_CONTENTION_MODAL,
    payload: {
      modal,
      treatment,
      record,
      plan
    }
  };
}

export function clinicalRecordTreatmentGadgetsPreModal({ modal, treatment, record, plan }) {
  return {
    type: TREATMENT_CLINICAL_RECORD_PLANS_GADGETS_PRE_MODAL,
    payload: {
      modal,
      treatment,
      record,
      plan
    }
  };
}

export function clinicalRecordTreatmentGadgetsModal({ modal, treatment, record, plan, gadgets }) {
  return {
    type: TREATMENT_CLINICAL_RECORD_PLANS_GADGETS_MODAL,
    payload: {
      modal,
      treatment,
      record,
      plan,
      gadgets
    }
  };
}

export function clinicalRecordTreatmentAnticipateModal({ modal, treatment, record, debit }) {
  return {
    type: TREATMENT_CLINICAL_RECORD_ANTICIPATE_CONFIRM_MODAL,
    payload: {
      modal,
      treatment,
      record,
      debit
    }
  };
}
