import React, { Component } from 'react';

import { Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import Icon from './Icon.component';

export class ModalMessageConfirm extends Component {
  static propTypes = {
    title: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired,
    openModal: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    icon: PropTypes.string.isRequired,
    footer: PropTypes.object.isRequired
  };

  render() {
    const { title, message, openModal, close, icon, className, footer } = this.props;

    return (
      <div className='content-box-without-color'>
        <Container>
          <Modal isOpen={openModal} toggle={close} size='small' className={className + ' patientMessage'}>
            <ModalHeader
              className='title-primary'
              toggle={close}
              close={
                <button className='close' onClick={close}>
                  &times;
                </button>
              }
            >
              {title}
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col sm='12'>
                  <div className='modal-icon'>
                    <Icon className='icon-alert icon-size-big' image={icon} alt='warning' />
                  </div>
                </Col>
              </Row>
              <div className='margin-top-20'>
                <Row>
                  <Col sm='12 px-4'>{message}</Col>
                </Row>
              </div>
            </ModalBody>
            <ModalFooter>{footer}</ModalFooter>
          </Modal>
        </Container>
      </div>
    );
  }
}

export default ModalMessageConfirm;
