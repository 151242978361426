import React, { Component } from 'react';

import { Container, Row, Col } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import warning from '../../../../assets/img/icons/warning.svg';
import Icon from '../../../../components/Icon.component';

import PropTypes from 'prop-types';
import UserAPI from '../../../../service/User.service';
import MessagesComponent from '../../../../components/Messages.component';

class SettingsSheduleChairsDeleteModal extends Component {
  state = {
    pwd: '',
    message: {
      display: false,
      text: ''
    }
  };

  static propTypes = {
    chair: PropTypes.object.isRequired,
    dentist: PropTypes.number.isRequired,
    modal: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    refreshPage: PropTypes.func.isRequired
  };

  setPassword = (e) => {
    const {
      target: { value }
    } = e;
    this.setState({
      pwd: value
    });
  };

  deleteChair = async () => {
    console.group('deleteChair');

    const { chair, showLoader, hideLoader, closeModal, refreshPage } = this.props;

    showLoader();

    try {
      const { pwd } = this.state;
      console.log('pwd', pwd);
      const isValid = await UserAPI.checkPassword(pwd);

      if (!isValid) {
        this.addMessage(true, 'Senha Inválida');
      } else {
        this.addMessage(false, '');

        await UserAPI.deleteChairById(chair.id);
        refreshPage();
        closeModal();
      }

      console.log('isValid', isValid);
    } catch (e) {
      console.log('Exception: ', e);
      this.addMessage(false, 'Erro ao deletar Cadeira ');
    }
    hideLoader();
    console.groupEnd();
  };

  addMessage(display, text) {
    this.setState({
      message: {
        display,
        text
      }
    });
  }

  render() {
    const { chair = {}, modal, closeModal } = this.props;
    const { display: messageDisplay, text: messageText } = this.state.message;

    return (
      <div className='content-box-without-color'>
        <Container>
          <Modal isOpen={modal} toggle={closeModal} className={this.props.className + ' delete-chair'}>
            <ModalHeader
              className='title-primary pb-0'
              toggle={closeModal}
              close={
                <button className='close' onClick={closeModal}>
                  &times;
                </button>
              }
            >
              <strong>Excluir Cadeira</strong>
            </ModalHeader>
            <ModalBody className='pt-0'>
              <div className='margin-top-20'>
                <MessagesComponent display={messageDisplay} type={'danger'} message={messageText} />
              </div>
              <Row>
                <Col sm='12'>
                  <div className='modal-icon'>
                    <Icon className='icon-alert icon-size-big' image={warning} alt='warning' />
                  </div>
                </Col>
              </Row>
              <div>
                <Row>
                  <Col sm='12'>
                    <p className={'text-default text-md text-secondary text-center'}>
                      Tem certeza que deseja excluir a cadeira{' '}
                      <strong className='font-bold'>{chair.nome_cadeira}</strong>?
                    </p>
                  </Col>
                </Row>
              </div>
              <div>
                <Row>
                  <Col sm='12'>
                    <p className={'text-md text-secondary text-center'}>
                      Ao excluir, a Cadeira será apagada da Agenda, no futuro e no passado e todas suas consultas serão
                      definitivamente perdidas.
                    </p>
                  </Col>
                </Row>
              </div>
              <div className='margin-top-10'>
                <Row>
                  <Col sm={{ size: 6, offset: 3 }}>
                    <div className='input-float'>
                      <input
                        className='input-secondary'
                        type='password'
                        id='senha'
                        name='senha'
                        placeholder='Senha'
                        onChange={this.setPassword}
                        autocomplete='new-password'
                      />
                      <label htmlFor='senha' className='text-sm font-bold'>
                        Senha
                      </label>
                    </div>
                  </Col>
                </Row>
              </div>
            </ModalBody>
            <ModalFooter>
              <Row>
                <Col sm={{ size: 3, offset: 6 }}>
                  <button className='button-cancel' onClick={this.deleteChair}>
                    Excluir
                  </button>
                </Col>
                <Col sm='3'>
                  <button className='button-tertiary' onClick={closeModal}>
                    Cancelar
                  </button>
                </Col>
              </Row>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    );
  }
}

export default SettingsSheduleChairsDeleteModal;
