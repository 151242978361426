import React, { Component } from 'react';
import ErrorFieldWithoutBootstrap from './ErrorFieldWithoutBootstrap.component';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { t } from 'typy';
import { selectStyles } from '../config/core.config';

// const selectStyles = {
//   option: (provided, state) => ({
//     ...provided,
//     backgroundColor: state.isSelected ? '#dce1ee' : state.isFocused ? '#c0c0c0' : ''
//   }),
//   control: (base, state) => ({
//     ...base,
//
//     boxShadow: state.isFocused ? '0 0 0 1px #229ba5 !important' : base.boxShadow,
//     border: state.isFocused ? '1px solid #229ba5 !important' : base.border,
//     '&:hover': {
//       border: state.isFocused ? '1px solid #229ba5 !important' : base.boxShadow
//     }
//   })
// };

const _reactSelectHandleChange = (data, { handleChange, name, callbackOnChange }) => {
  if (handleChange) {
    handleChange({
      target: {
        name: name,
        value: data
      }
    });
  }

  if (callbackOnChange) callbackOnChange(data);
};

const reactSelectStyleIsError = ({ name, styles = {}, errors }) => {
  if (t(errors, `${name}.value`).isDefined) {
    return {
      ...styles,
      ...selectStyles,
      control: (styles) => {
        styles = {
          ...styles,
          borderColor: 'red'
        };
        return styles;
        // return ({ ...selectStyles.control, borderColor: 'red' })
      }
    };
  } else {
    return {
      ...styles,
      ...selectStyles
    };
  }
};

const SelectComponent = (props) => {
  const { name, values, value, options, errors, isMulti = false, closeMenuOnSelect = true, placeholder } = props;
  return [
    <Select
      key={'select'}
      closeMenuOnSelect={closeMenuOnSelect}
      value={value ? value : t(values, name).safeObject}
      options={options}
      onChange={(p) => _reactSelectHandleChange(p, props)}
      styles={reactSelectStyleIsError(props)}
      isMulti={isMulti}
      placeholder={placeholder}
      {...props}
    />,

    <ErrorFieldWithoutBootstrap key={'error'} fieldName={`${name}.value`} errors={errors} />
  ];
};

SelectComponent.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.object,
  value: PropTypes.object,
  options: PropTypes.array.isRequired,
  callbackOnChange: PropTypes.func,
  styles: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  isMulti: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  placeholder: PropTypes.string
};

export default SelectComponent;
