import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { compose } from 'recompose';
import logo from '../../assets/img/logo.jpg';
import ErrorMessage from '../../components/ErrorMessage.component';
import { resetPasswordValidate, resetPassword } from '../../service/Login.service';
import queryString from 'query-string';
import ResetPasswordForm from './ResetPassword.form';
import { connect } from 'react-redux';
import { sendMessage } from '../../components/actions/ErrorMessage.actions';
import { showLoader, hideLoader } from '../../components/actions/Loader.actions';

class ResetPasswordPage extends Component {
  state = {
    validatedToken: false,
    token: '',
    error: {
      display: false,
      message: '',
      type: ''
    }
  };

  componentDidMount = () => {
    const { t: token } = queryString.parse(this.props.location.search);
    this.props.showLoader();

    resetPasswordValidate(token)
      .then((response) => {
        this.props.hideLoader();
        this.setState({
          validatedToken: true,
          token
        });
      })
      .catch((err) => {
        this.props.hideLoader();
        this.setState({
          error: {
            display: true,
            message: 'Token Expirado, por favor refaça o processo',
            type: 'danger'
          }
        });
      });
  };

  postForm = (values) => {
    const { senha } = values;
    const { token } = this.state;

    this.props.showLoader();

    resetPassword({
      password: senha,
      token: token
    })
      .then((data) => {
        if (data.status === 202) {
          this.props.hideLoader();
          this.props.sendMessage({
            display: true,
            message: 'Senha alterada com sucesso',
            type: 'info'
          });

          this.props.history.push('/');
        } else {
          this.props.hideLoader();
          this.setState({
            error: {
              display: true,
              message: 'Error ao alterar senha',
              type: 'danger'
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
        let message = 'Erro ao alterar senha entre em contato com o administrador';

        if (error.response) {
          const erroMessage = error.response.data.message;
          if (erroMessage.includes('senha para mesma senha')) {
            message = erroMessage;
          } else {
            message += ` e informando o erro [${erroMessage}]`;
          }
        }
        this.props.hideLoader();
        this.setState({
          error: {
            display: true,
            message: message,
            type: 'danger'
          }
        });
      });
  };

  render() {
    const { error, validatedToken } = this.state;
    const { display, message, type } = error;

    return (
      <Container>
        <main className='main-container-overflow'>
          <div className='login-container'>
            <div className='login-box'>
              {/* <div className="login-box-left">
                <span>Conteúdo Publicitário do <strong>GRIKS</strong></span>
              </div> */}
              <div className='login-box-right'>
                <Row>
                  <Col sm='12'>
                    <img className='img-logo-login' src={logo} alt='logo' />
                  </Col>
                </Row>
                <Row>
                  <Col sm={{ size: 8, offset: 2 }}>
                    <ErrorMessage display={display} type={type} message={message} style={{ marginTop: 20 }} />
                    {validatedToken ? (
                      <ResetPasswordForm submitCallback={this.postForm} />
                    ) : (
                      <button type='button' onClick={() => this.props.history.push('/')} className='button-primary'>
                        Voltar
                      </button>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </main>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    sendMessage: (data) => dispatch(sendMessage(data)),
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader())
  };
};

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(ResetPasswordPage);
