import React, { Component } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FormGroup, Label, Input, Col } from 'reactstrap';
import ErrorField from '../../components/ErrorField.component';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default class ForgotPasswordForm extends Component {
  static propTypes = {
    submitCallback: PropTypes.func.isRequired
  };

  validateSchema = Yup.object().shape({
    email: Yup.string().required('E-mail Obrigatório').email('E-mail Inválido')
  });

  render() {
    const { submitCallback } = this.props;

    return (
      <Formik validationSchema={this.validateSchema} validateOnChange={false} onSubmit={submitCallback}>
        {({ errors, handleSubmit, handleChange }) => (
          <form className='login-form' onSubmit={handleSubmit}>
            <h2 className='title-large'>Esqueceu sua Senha?</h2>
            <p className='title-small margin-top-20'>
              Uma mensagem será enviada para seu e-mail. Siga as orientações para recebida para recuperar sua senha.
            </p>
            <FormGroup>
              <Label className='margin-top-20' for='examplePassword'>
                E-mail
              </Label>
              <Input name='email' onChange={handleChange} invalid={errors.email ? true : false} />
              <ErrorField errors={errors} fieldName={'email'} />
            </FormGroup>
            <Col sm={{ size: 8, offset: 2 }}>
              <button type='submit' className='button-primary'>
                Recuperar senha
              </button>
            </Col>
            <Col sm={{ size: 8, offset: 2 }}>
              <Link to={'/login'}>
                <button type='submit' className='button-underline margin-top-20'>
                  Retornar ao Login
                </button>
              </Link>
            </Col>
          </form>
        )}
      </Formik>
    );
  }
}
