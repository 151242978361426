import t from 'typy';
import { createSelector } from 'reselect';

const financialFileSelector = (state) => t(state, 'TreatmentFinancialFileReducer.monthlyPaymentModal').safeObject;

export const propsMonthlyPaymentSelector = createSelector(financialFileSelector, (page = {}) => {
  return {
    monthly: t(page, 'financialFile.pagamento.mensalidade').safeObject,
    contractDate: t(page, 'financialFile.data_contrato').safeString
  };
});
