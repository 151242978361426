import React, { Component } from 'react';
import {
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Card,
  CardText,
  CardBody,
  CardSubtitle,
  UncontrolledTooltip,
  Table
} from 'reactstrap';
import { Form, Formik } from 'formik';
import ErrorFieldWithoutBootstrap from '../../../../components/ErrorFieldWithoutBootstrap.component';
import Select from 'react-select';
import { reactSelectHandleChange, reactSelectStyleIsError } from '../../../../utils/ReactSelectUtils';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { hideLoader, showLoader } from '../../../../components/actions/Loader.actions';
import { financialLaunchOtherPaymentModal } from '../redux/FinancialFile.action';
import {
  launchOtherPaymentFormInit,
  launchOtherPaymentToProps
} from '../selectors/FinancialFileEntriesLaunchOtherPaymentModal.selector';
import * as Yup from 'yup';
import DomainsAPI from '../../../../service/DomainsAPI';
import ToastUtils from '../../../../utils/ToastUtils';
import { IfComponent } from '../../../../components/if.component';
import CashAPI from '../../../../service/CashAPI';
import moment from 'moment';
import { t } from 'typy';
import withSecurity from '../../../../config/security/security';
import { PERMISSOES } from '../../../../config/security/permissoes';
import search from '../../../../assets/img/icons/search.svg';
import TreatmentAddGadgetsModal from '../../budget/modals/TreatmentAddGadgets.modal';
import ProceduresAPI from '../../../../service/ProceduresAPI';
import { formatRealNoShowDolarSign } from '../../../../utils/Formatter';
import DatePicker from 'react-datepicker/es';
import Constants from '../../../../utils/Constants';
import { selectStyles } from '../../../../config/core.config';
import InputDecimalComponent from '../../../../components/InputDecimalComponent';
import { IoIosHelpCircle } from 'react-icons/io';
import { formatDate } from '../../../../utils/DateUtils';
import FormikUtils from '../../../../utils/FormikUtils';

class TreatmentFinancialOtherLaunchModal extends Component {
  defaultState = {
    reasonOptions: [],
    othersErrors: {},
    modalGadgets: false,
    gadgets: [],
    entriesSelected: [],
    reasonsSelected: false,
    showParcialMessage: false,
    showNoSelectEntries: false
  };

  state = {
    ...this.defaultState
  };

  schemaValidation = Yup.object().shape({
    valor: Yup.number().required('Valor Obrigatório'),
    tipo_lancamento: Yup.object().shape({
      value: Yup.number().required('Tipo de Lançamento Obrigatório')
    })
  });

  _handleChange = {};

  handleChange = (e) => {
    console.log('aqui');
  };

  closeModal = () => {
    const { closeModal } = this.props;
    this.setState({entriesSelected: []});
    closeModal();
  };

  getAllReasons = (entryTypeId) => {
    const { showLoader, hideLoader, security } = this.props;

    const otherLaunchPerm = PERMISSOES.modules.tratamento_ficha_financeira;

    showLoader();
    DomainsAPI.getAllReasonsOfEntryType(entryTypeId)
      .then((reasons) => {
        if (reasons) {
          let reasonOptions = reasons.map((r) => ({
            label: r.motivo,
            value: r.id,
            data: r
          }));

          // Verificar como ativar isso
          if (
            security.hasRestriction(
              otherLaunchPerm.id,
              otherLaunchPerm.permissoes.editar_restricao.id,
              otherLaunchPerm.permissoes.editar_restricao.restricoes.nao_lancar_desconto
            )
          ) {
            reasonOptions = reasonOptions.filter((r) => r.value !== 3);
          }

          this.setState({ reasonOptions });
        }

        hideLoader();
      })
      .catch((err) => {
        hideLoader();
        console.error(err);
        ToastUtils.error('Erro ao Buscar Motivos do lançamento entre em contato com o suporte');
      });
  };

  launchOtherPayment = (values, actions) => {
    const { showLoader, hideLoader, updateParent } = this.props;
    const { entriesSelected } = this.state;

    let formValid = true;
    showLoader();
    const isAjusteDeCredito = values.tipo_lancamento.value === 3;
    const isDebito = values.tipo_lancamento.value === 2;

    if (isDebito) {
      if (t(values, 'motivo.label').isUndefined || t(values, 'motivo.label').safeString === '') {
        actions.setFieldError('motivo.label', 'Descrição Obrigatória');
        formValid = false;
      }

      if (t(values, 'data_vencimento').isUndefined) {
        actions.setFieldError('data_vencimento', 'Data de Vencimento Obrigatória');
        formValid = false;
      }
    } else if (isAjusteDeCredito) {
      if(entriesSelected.length === 0) {
        this.setState({showNoSelectEntries: true});
        formValid = false;
      }
    }

    if (formValid) {
      // this.setState({
      //   formaPagamentoMessage: {
      //     display: false,
      //     message: ''
      //   }
      // });

      CashAPI.launchPayment({
        id: values.id,
        tipo_lancamento: values.tipo_lancamento.data.id,
        numero_lancamento: values.numero_lancamento,
        data_lancamento:
          values.tipo_lancamento.data.id === 2 ? values.data_vencimento : moment().format('YYYY-MM-DDTHH:mm:ss'),
        descricao: values.motivo.label,
        anotacao: values.anotacao,
        forma_pagamento: t(values, 'forma_pagamento.value').safeObject,
        referencia: values.referencia,
        valor_lancamento: values.valor,
        ficha_financeira: values.financialFile,
        paymentEntries: entriesSelected,
      })
        .then((d) => {
          updateParent();
          hideLoader();
          this.closeModal();
          ToastUtils.info('Lançamento registrado com sucesso', { autoClose: 2000, position: 'top-center' });
        })
        .catch((err) => {
          console.error(err);
          hideLoader();

          if (t(err, 'response.data.message').safeString.includes('Nenhuma caixa aberto')) {
            ToastUtils.error('Caixa Fechado por favor abra seu caixa para fazer um lançamento');
          } else {
            ToastUtils.error('Erro ao lançar outro pagamento entre em contato com o suporte');
          }
        });
    } else {
      hideLoader();
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(this.props.formInit.tipo_lancamento) !== JSON.stringify(nextProps.formInit.tipo_lancamento)) {
      this.getAllReasons(nextProps.formInit.tipo_lancamento.value);
    }

    return true;
  }

  _openModalGadgets = async () => {
    const { gadgets } = this.state;

    if (!gadgets || !gadgets.length) {
      try {
        const proceduresData = await ProceduresAPI.getAllProceduresOfClosedPrice();
        gadgets.push(...proceduresData.data);
        this.setState({
          modalGadgets: true,
          gadgets: gadgets.filter((g) => g.procedimentos.length > 0)
        });
      } catch (e) {
        console.error(e);
        ToastUtils.error('Erro ao buscar procedimentos, entre em contato com suporte');
      }
    } else {
      this.setState({ modalGadgets: true });
    }
  };

  _addGadget = (gadgets) => {
    console.log(gadgets);
    this._closeAddGadget();
    this._handleChange({
      target: {
        name: 'motivo.label',
        value: gadgets[0].descricao
      }
    });
    this._handleChange({
      target: {
        name: 'valor',
        value: gadgets[0].valor_tratamento
      }
    });
  };

  _closeAddGadget = () => {
    this.setState({
      modalGadgets: false
    });
  };

  selectEntryForPaid = (entry) => {
    let entries = this.state.entriesSelected;

    if (entries.includes(entry)) {
      entries = entries.filter((e) => e !== entry);
    } else {
      if (entries.length == 0) {
        this.setState({showNoSelectEntries: false});
        entries.push(entry);
      } else {
        ToastUtils.info('Só é permitido selecionar um debito');
      }
    }
    this.setState({
      entriesSelected: entries
    });
  };

  isChecked = (entry) => {
    let entries = this.state.entriesSelected;

    if (entries.includes(entry)) {
      return true;
    } else {
      return false;
    }
  };

  handleReasonsSelected = (e) => {
    console.log(e);
    this.setState({
      reasonsSelected: true
    });
  };

  render() {
    const { modal, formInit = {}, entryTypesOptions = [], security, lancamentos } = this.props;
    const {
      reasonOptions,
      modalGadgets,
      gadgets,
      expandCard,
      reasonsSelected,
      showParcialMessage,
      entriesSelected,
      showNoSelectEntries
    } = this.state;

    let entryTypesOptionsTmp = entryTypesOptions;

    // console.log('reason', reasonOptions);

    const otherLaunchPerm = PERMISSOES.modules.tratamento_ficha_financeira;

    if (
      security.hasRestriction(
        otherLaunchPerm.id,
        otherLaunchPerm.permissoes.editar_restricao.id,
        otherLaunchPerm.permissoes.editar_restricao.restricoes.nao_lancar_ajuste_credito
      )
    ) {
      entryTypesOptionsTmp = entryTypesOptionsTmp.filter(
        (r) => r.value !== Constants.TIPO_LANCAMENTO_AJUSTE_DE_CREDITO
      );
    }

    const MAX_LENGTH = 20;

    return [
      <Modal isOpen={modal} toggle={this.closeModal} className={this.props.className} key={'ModalOtherEntries'}>
        <ModalHeader
          className='title-primary'
          toggle={this.closeModal}
          close={
            <button className='close' onClick={this.closeModal}>
              &times;
            </button>
          }
        >
          <strong>Outros Lançamentos</strong>
        </ModalHeader>
        <Formik
          initialValues={formInit}
          onSubmit={this.launchOtherPayment}
          validationSchema={this.schemaValidation}
          validateOnChange={false}
        >
          {({ errors, values, handleChange }) => {
            this._handleChange = handleChange;
            const isAjusteDeCredito = values.tipo_lancamento.value === 3;
            const isDebito = values.tipo_lancamento.value === 2;
            const isDesconto = values.motivo.data;

            return (
              <Form>
                <ModalBody>
                  <div className=''>
                    <Row className='mt-2 pb-0'>
                      <Col sm='12'>
                        <label>
                          Tipo de Lançamento <span>*</span>
                          <a
                            data-tip='Clique Para Saber Mais'
                            // style={{ marginLeft: -10 }}
                            // className='ml-2'
                            onClick={() => {
                              this.setState({ expandCard: !this.state.expandCard });
                            }}
                          >
                            <IoIosHelpCircle
                              size={16}
                              id='tipoLancamento'
                              className='ml-1'
                              color='#616e88'
                              style={{ cursor: 'help' }}
                              alt='help'
                            />
                          </a>
                          <UncontrolledTooltip placement='top' target='tipoLancamento' className='tooltip-white'>
                            Clique para saber mais
                          </UncontrolledTooltip>
                        </label>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm='12'>
                        <Select
                          options={entryTypesOptionsTmp}
                          value={values.tipo_lancamento}
                          isSearchable={true}
                          ignoreAccents={true}
                          ignoreCase={true}
                          placeholder={''}
                          styles={reactSelectStyleIsError(errors, 'tipo_lancamento', {
                            ...selectStyles,
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isSelected
                                ? 'var(--primary-accent)'
                                : state.isFocused
                                ? 'var(--primary-l4)'
                                : '',
                              color: state.data.color,
                              fontWeight: 600
                            })
                          })}
                          onChange={(e) => {
                            this.getAllReasons(e.value);
                            reactSelectHandleChange(e, handleChange, 'tipo_lancamento');
                          }}
                        />
                      </Col>
                      <IfComponent conditional={this.state.expandCard}>
                        <Col sm='12'>
                          <Card className={'border text-left'}>
                            <CardBody className={'pb-2'}>
                              <CardSubtitle
                                className={'text-center text-default text-sm font-bold text-secondary mb-2'}
                              >
                                <span className={'pt-1'}>Tipos de Lançamento</span>
                              </CardSubtitle>
                              <CardText className={'text-default text-sm text-secondary-d4 mb-2'}>
                                <strong className={'text-secondary-d4 font-bold'}>Débito (Despesas)</strong>-
                                Procedimentos e outros custos não incluídos no orçamento inicial.
                              </CardText>
                              <hr />
                              <CardText className={'text-default text-sm text-secondary-d4 mb-2'}>
                                <strong className={'text-secondary-d4 font-bold'}>Ajuste de Crédito</strong>- Ajustes
                                que geram crédito na conta do paciente, mas não são pagamentos. Ex: Desconto.
                              </CardText>
                              <hr />
                              <CardText className={'text-default text-sm text-secondary-d4 mb-2'}>
                                <strong className={'text-secondary-d4 font-bold'}>Ajuste de Débito</strong>- Ajustes que
                                geram débito na conta do paciente, mas não são novas despesas. Ex: Cheque Devolvido
                              </CardText>
                            </CardBody>
                          </Card>
                        </Col>
                      </IfComponent>
                    </Row>

                    <IfComponent conditional={isDebito}>
                      <Row className='margin-top-10 pb-0'>
                        <Col sm='12'>
                          <label>
                            Data de Vencimento<span>*</span>
                          </label>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm='12'>
                          <DatePicker
                            name={'data_vencimento'}
                            value={values.data_vencimento}
                            selected={values.data_vencimento}
                            locale={'pt-br-griks'}
                            useWeekdaysShort={true}
                            className='input-secondary'
                            style={reactSelectStyleIsError(errors, 'data_vencimento', { width: 300 })}
                            onChange={(value) => {
                              handleChange({
                                target: {
                                  name: 'data_vencimento',
                                  value: value
                                }
                              });
                            }}
                          />
                        </Col>
                      </Row>
                    </IfComponent>

                    <IfComponent conditional={reasonOptions.length > 0 || isDebito}>
                      <Row className='margin-top-10 pb-0'>
                        <Col sm='12'>
                          <label>
                            Descrição <span>*</span>
                          </label>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm='12'>
                          <IfComponent conditional={!isDebito}>
                            <Select
                              options={reasonOptions}
                              value={values.motivo}
                              isSearchable={true}
                              ignoreAccents={true}
                              ignoreCase={true}
                              placeholder={''}
                              styles={reactSelectStyleIsError(errors, 'motivo', selectStyles)}
                              onChange={(e) => {
                                this.handleReasonsSelected(e.value);
                                reactSelectHandleChange(e, handleChange, 'motivo');
                              }}
                            />
                          </IfComponent>

                          <IfComponent conditional={isDebito}>
                            <InputGroup className='reversed'>
                              <Input
                                name={'motivo.label'}
                                invalid={errors['motivo.label']}
                                value={values.motivo.label}
                                onChange={handleChange}
                              />
                              <InputGroupAddon
                                style={{ cursor: 'pointer' }}
                                onClick={this._openModalGadgets}
                                addonType='prepend'
                              >
                                <InputGroupText>
                                  <img src={search} className={'icon icon-size-small'} />
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                            <ErrorFieldWithoutBootstrap fieldName={'motivo.label'} errors={errors} />
                          </IfComponent>
                        </Col>
                      </Row>
                    </IfComponent>

                    <IfComponent conditional={isAjusteDeCredito && reasonsSelected}>
                      <div className='content-overflow-default mt-4'>
                        <span className='text-secondary text-uppercase font-bold'>Selecione um Débito</span>
                        <IfComponent conditional={showNoSelectEntries}>
                          <Row>
                            <Col sm='12' className='p-1 text-xsm text-danger font-bold'>
                              Atenção: Selecione um débito
                            </Col>
                          </Row>
                        </IfComponent>
                        <Table responsive className='table-default mt-3 mb-0'>
                          <tbody className='text-secondary'>
                            <tr>
                              {lancamentos.map((e) => {
                                return (
                                  <tr key={e.id} className='text-secondary'>
                                    <IfComponent
                                      conditional={
                                        (t(e, 'tipo_lancamento.id').safeNumber == 2 ||
                                          t(e, 'tipo_lancamento.id').safeNumber == 4) &&
                                        !t(e, 'pago').safeBoolean
                                      }
                                    >
                                      <td>
                                        <input
                                          type='checkbox'
                                          className='form-checkbox'
                                          checked={this.isChecked(e)}
                                          onClick={() => {
                                            this.selectEntryForPaid(e);
                                            FormikUtils.setValueField(handleChange, 'valor', e.valor_debito);
                                          }}
                                        />
                                      </td>
                                      <td width='2%'>{e.numero_lancamento}</td>
                                      <td>{formatDate(e.data_lancamento, 'DD/MM/YYYY')}</td>

                                      {e.descricao.length > MAX_LENGTH ? (
                                        <td>{`${e.descricao.substring(0, MAX_LENGTH)}...`}</td>
                                      ) : (
                                        <td>{e.descricao}</td>
                                      )}

                                      <td className='text-danger'>{formatRealNoShowDolarSign(e.valor_debito)}</td>
                                    </IfComponent>
                                  </tr>
                                );
                              })}
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </IfComponent>

                    <Row className='margin-top-10'>
                      <Col sm='6'>
                        <label>
                          Valor <span>*</span>
                        </label>
                        <InputGroup className='reversed'>
                          <InputDecimalComponent
                            name={'valor'}
                            handleChange={handleChange}
                            invalid={errors.valor ? true : false}
                            value={values.valor}
                            valueChange={(value) => {
                              if (isAjusteDeCredito) {
                                if (value.floatValue < t(entriesSelected[0], 'valor_debito').safeNumber) {
                                  this.setState({ showParcialMessage: true });
                                } else {
                                  this.setState({ showParcialMessage: false });
                                }
                              }
                            }}
                            style={{ fontWeight: '600' }}
                          />
                          <InputGroupAddon addonType='prepend'>R$</InputGroupAddon>
                        </InputGroup>
                        <ErrorFieldWithoutBootstrap fieldName={'valor'} errors={errors} />
                      </Col>
                    </Row>
                    <IfComponent conditional={showParcialMessage}>
                      <Row>
                        <Col sm='12' className='p-1 text-xsm text-danger font-bold'>
                          Atenção: Pagamento Parcial
                        </Col>
                      </Row>
                    </IfComponent>

                    <IfComponent conditional={!isDebito}>
                      <Row className='margin-top-10'>
                        <Col sm='12'>
                          <label>Nr Referência </label>
                          <input
                            className='input-secondary'
                            type='text'
                            name='referencia'
                            value={values.referencia}
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>

                      <Row className='margin-top-10'>
                        <Col sm='12'>
                          <label>Anotação</label>
                          <textarea
                            className='form-control'
                            rows='2'
                            cols='50'
                            value={values.anotacao}
                            name={'anotacao'}
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>
                    </IfComponent>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Row>
                    <Col sm='12'>
                      <button type='submit' className='button-primary float-right'>
                        Salvar
                      </button>
                      <button className='button-tertiary float-right mr-3' type='button' onClick={this.closeModal}>
                        Cancelar
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </Modal>,
      <TreatmentAddGadgetsModal
        key={'TreatmentAddGadgetsModal'}
        selected={[]}
        updateParent={() => {}}
        modal={modalGadgets}
        closeModal={this._closeAddGadget}
        gadgets={gadgets}
        addGadgets={this._addGadget}
        singleSelection={true}
      />
    ];
  }
}

const mapStateToProps = (state) => {
  return {
    ...launchOtherPaymentToProps(state),
    formInit: launchOtherPaymentFormInit(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  closeModal: () => dispatch(financialLaunchOtherPaymentModal({ modal: false }))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withSecurity);

export default enhanced(TreatmentFinancialOtherLaunchModal);
