import { call, put } from 'redux-saga/effects';
import {
  scheduleAppointmentPatientSagaModal,
  scheduleAppointmentSelectTreatmentModal
} from '../redux/Schedule.actions';
import { hideLoader, showLoader } from '../../../components/actions/Loader.actions';
import TreatmentAPI from '../../../service/TreatmentAPI';
import ToastUtils from '../../../utils/ToastUtils';

export function* scheduleAppointTreatmentSagaProcess({
  payload: { modal, dentist, chair, date, start, end, patient, procedure, controleRetorno }
}) {
  yield put(showLoader());
  try {
    const treatmentsData = yield call(TreatmentAPI.getAllTreatmentByDentist, patient.id, dentist.id);

    const treatments = treatmentsData.data;

    if (treatments.length > 0) {
      const situacoesBloqueadas = ['TN', 'T', 'I'];
      yield put(
        scheduleAppointmentSelectTreatmentModal({
          modal,
          dentist,
          chair,
          date,
          start,
          end,
          patient,
          treatments: treatments.filter((t) => !situacoesBloqueadas.includes(t.situacao_id)),
          procedure,
          controleRetorno
        })
      );
    } else {
      yield put(
        scheduleAppointmentPatientSagaModal({
          date,
          modal: true,
          dentist,
          chair,
          start,
          end,
          patient,
          treatment: { id: 0 },
          procedure,
          controleRetorno
        })
      );
    }
  } catch (e) {
    console.error(e);
    ToastUtils.error('Erro ao carregar modal de tratamentos entre em contato com o suporte');
  }

  yield put(hideLoader());
}
