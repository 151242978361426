import { clinicalRecordDiagnosticModal, clinicalRecordDiagnosticPage } from '../redux/TreatmentClinicalRecord.action';
import { all, call, put } from 'redux-saga/effects';
import TreatmentClinicalRecordApi from '../../../../service/TreatmentClinicalRecordApi';
import DomainsAPI from '../../../../service/DomainsAPI';

export function* clinicalRecordDiagnosticSaga({ payload: { treatment, record } }) {
  let error = '';
  let diagnostic = {};
  try {
    let diagnosticData = yield call(TreatmentClinicalRecordApi.getDiagnostics, {
      treatmentId: treatment,
      recordId: record
    });

    if (diagnosticData.status === 204) {
      diagnosticData = yield call(TreatmentClinicalRecordApi.createDiagnostics, {
        treatmentId: treatment,
        recordId: record
      });
    }

    diagnostic = diagnosticData.data;
  } catch (e) {
    error = 'Erro ao buscar os dados de dignostico por favor entre em contato com o suporte';
  }

  yield put(clinicalRecordDiagnosticPage({ diagnostic, treatment, record, error }));
}

export function* clinicalRecordDiagnisticModalSaga({ payload: { modal, diagnostic, treatment, record } }) {
  const domains = yield all([
    call(DomainsAPI.findAllAngles),
    call(DomainsAPI.findAllTeeth),
    call(DomainsAPI.findAllSkeletalClass),
    call(DomainsAPI.findAllSkeletalPatterns)
  ]);

  const formLoad = {
    data: {
      angles: domains[0],
      teeth: domains[1],
      skeletalClass: domains[2],
      skeletalPatterns: domains[3]
    }
  };

  yield put(clinicalRecordDiagnosticModal({ modal, diagnostic, treatment, record, formLoad }));
}
