import React, { Component } from 'react';

import { Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { standardProcedureModal } from '../redux/ScheduleSettings.actions';
import ProceduresAPI from '../../../../service/ProceduresAPI';
import ScheduleAPI from '../../../../service/ScheduleAPI';
import MessagesComponent from '../../../../components/Messages.component';

class SettingsScheduleStandardProcedureModal extends Component {
  state = {
    category: null,
    procedure: null,
    procedures: [],
    displayMessage: false,
    message: ''
  };

  setCategory = (e) => {
    const {
      target: { value }
    } = e;
    this.setState({
      category: value,
      procedure: null
    });
    this.getAllProcedures(value);
  };

  setProcedure = (e) => {
    const {
      target: { value }
    } = e;
    this.setState({
      procedure: value
    });
  };

  getAllProcedures = async (category) => {
    try {
      let procedures = [];
      if (category) {
        const proceduresResponse = await ProceduresAPI.getAllProcedures(category, false);
        procedures = proceduresResponse.data;
      }
      this.setState({
        procedures
      });
    } catch (e) {
      console.error(e);
    }
  };

  saveStandardProcedure = async () => {
    const { initForm, dataForm, showLoader, hideLoader, refreshPage, closeModal } = this.props;
    const { procedure } = this.state;
    showLoader();
    try {
      if (procedure) {
        await ScheduleAPI.saveStandardProcedure({
          id: initForm.id,
          dentista: dataForm.dentist,
          procedimento_id: procedure
        });
        refreshPage();
        closeModal();
      } else {
        this.setState({
          displayMessage: true,
          message: 'Por favor selecione um procedimento'
        });
      }
    } catch (e) {
      console.error(e);
      this.setState({
        displayMessage: true,
        message: 'Erro ao atualizar Procedimento Padrão'
      });
    }
    hideLoader();
  };

  componentWillReceiveProps(nextProps, nextContext) {
    const initForm = nextProps.initForm;
    console.log(initForm);
    if (initForm) {
      this.getAllProcedures(initForm.procedimento_categoria_id);

      this.setState({
        category: initForm.procedimento_categoria_id,
        procedure: initForm.procedimento_id,
        displayMessage: false
      });
    }
  }

  render() {
    const { closeModal, modal, initForm = {}, dataForm: { categories = [] } = {} } = this.props;
    const { procedures, procedure, category, displayMessage, message } = this.state;
    const closeBtn = (
      <button className='close' onClick={closeModal}>
        &times;
      </button>
    );

    return (
      <div className='content-box-without-color'>
        <Container>
          <Modal isOpen={modal} toggle={closeModal} className={this.props.className}>
            <ModalHeader className='title-primary' toggle={closeModal} close={closeBtn}>
              <strong>Editar Procedimento Padrão da Agenda</strong>
            </ModalHeader>
            <ModalBody>
              <MessagesComponent display={displayMessage} type='danger' message={message} />
              <div className='margin-top-10'>
                <Row>
                  <Col sm='12'>
                    <p>
                      <strong>Procedimento</strong>
                      <span
                        className='procedure-primary ml-2'
                        style={{ backgroundColor: initForm.procedimento_categoria_rgb }}
                      >
                        {initForm.procedimento_codinome}
                      </span>
                      <span>{initForm.procedimento_nome}</span>
                    </p>
                  </Col>
                </Row>
                <Row className='margin-top-10'>
                  <Col sm='6'>
                    <form>
                      <label>
                        Grupo do Procedimento <span>*</span>
                      </label>
                      <select id='soflow' onChange={this.setCategory} value={category}>
                        <option></option>
                        {categories.map((c) => {
                          return <option value={c.id}>{c.nome}</option>;
                        })}
                      </select>
                    </form>
                  </Col>
                  <Col sm='6'>
                    <form>
                      <label>Nome do Procedimento</label>
                      <select id='soflow' onChange={this.setProcedure} value={procedure}>
                        <option></option>
                        {procedures.map((p) => {
                          return <option value={p.id}>{p.nome}</option>;
                        })}
                      </select>
                    </form>
                  </Col>
                </Row>
              </div>
            </ModalBody>
            <ModalFooter>
              <Row>
                <Col className='btn-list' sm={{ size: 6, offset: 6 }}>
                  <button className='button-primary' onClick={this.saveStandardProcedure}>
                    Salvar
                  </button>
                  <button className='button-tertiary' onClick={closeModal}>
                    Cancelar
                  </button>
                </Col>
              </Row>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ ScheduleSettingsReducer }) => {
  const { procedure } = ScheduleSettingsReducer;
  return { ...procedure };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(standardProcedureModal({ modal: false }))
  };
};

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(SettingsScheduleStandardProcedureModal);
