import React, { Component } from 'react';

import Autosuggest from 'react-autosuggest';
import _ from 'lodash';
import PropTypes from 'prop-types';

import '../assets/css/components/SugestionBoxComponent.css';

class SugestionBoxComponent extends Component {
  static propTypes = {
    inputProps: PropTypes.object.isRequired,
    renderSuggestionsContainer: PropTypes.func.isRequired,
    renderSuggestion: PropTypes.func.isRequired,
    renderInputComponent: PropTypes.func.isRequired,
    findSuggestions: PropTypes.func.isRequired,
    selectedSuggestion: PropTypes.func.isRequired,
    suggestionsData: PropTypes.array.isRequired
  };

  state = {
    value: ''
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setSearchTerm(value, 500);
  };

  setSearchTerm = _.debounce((searchTerm) => {
    this.props.findSuggestions(searchTerm);
  }, 500);

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  getSuggestionValue = (suggestion) => {
    if (suggestion.id) {
      this.props.selectedSuggestion(suggestion);
      return `${suggestion.name}`;
    } else {
      return '';
    }
  };

  render() {
    const {
      inputProps,
      renderSuggestionsContainer,
      renderSuggestion,
      renderInputComponent,
      suggestionsData,
      ref
    } = this.props;

    return (
      <Autosuggest
        suggestions={suggestionsData}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        renderInputComponent={renderInputComponent}
        renderSuggestionsContainer={renderSuggestionsContainer}
        ref={ref}
      />
    );
  }
}

export default SugestionBoxComponent;
