import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'typy';

const ErrorFieldWithoutBootstrap = ({ errors, fieldName }) => {
  return (
    <div>
      {t(errors, fieldName).isString ? (
        <div style={{ fontSize: 10, color: 'red' }}>{t(errors, fieldName).safeString}</div>
      ) : null}
    </div>
  );
};

ErrorFieldWithoutBootstrap.propTypes = {
  errors: PropTypes.object,
  fieldName: PropTypes.string.isRequired
};

export default ErrorFieldWithoutBootstrap;
