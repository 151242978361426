import { CONTACTS_RESULT, CONTACT_DETAIL_MODAL, CONTACT_SAVE_MODAL } from './contacts.actions';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONTACTS_RESULT:
      return action.payload;
    case CONTACT_DETAIL_MODAL:
      return {
        ...state,
        contactDetail: action.contactDetail
      };
    case CONTACT_SAVE_MODAL:
      return {
        ...state,
        contactModalSave: action.contactModalSave
      };
    default:
      return state;
  }
};
