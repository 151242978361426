import RestTemplate from '../utils/restTemplate/rest.template';
import _ from 'lodash';
import t from 'typy';

class FlowApi {
  static findFlow(date, doctor, chair) {
    const restTemplate = new RestTemplate(true);
    return restTemplate
      .get('/fluxo-atendimento/', {
        date,
        dentist: t(doctor).safeArray.length > 0 ? _.join(doctor, ',') : null,
        chair: t(chair).safeArray.length > 0 ? _.join(chair, ',') : null
      })
      .then(({ data }) => data);
  }

  static startFlow(scheduleId) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post(`/fluxo-atendimento/agendamento/${scheduleId}/start`);
  }

  static nextFlow(scheduleId) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.put(`/fluxo-atendimento/next/${scheduleId}`);
  }
}

export default FlowApi;
