import t from 'typy';
import { createSelector } from 'reselect';

const financialFileEntriesSelector = (state) => t(state, 'TreatmentFinancialFileReducer.entriesPage').safeObject;

export const financialFileEntriesToProps = createSelector(financialFileEntriesSelector, (entriesPage = {}) => {
  let negociacaoFinalizada = t(entriesPage, 'financialFile.negociacao_finalizada');

  // Adicionando esse condicional pois agora o usuário pode lançar pagamentos
  // sem ter um orçamento aberto
  if (t(entriesPage, 'financialFile.debitos').safeArray.length == 0) {
    negociacaoFinalizada = t('S');
  }

  return {
    treatment: t(entriesPage, 'treatment.id').safeObject,
    financialFile: t(entriesPage, 'financialFile.id').safeObject,
    enabledEntries: negociacaoFinalizada.isDefined && negociacaoFinalizada.safeString === 'S',
    entries: t(entriesPage, 'entries').safeObject
  };
});
