import React, { Component } from 'react';

import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { formInitSelector, propsModalSelector } from '../selectors/TreatmentClinicalRecordOtherInfosModalSelector';
import { clinicalRecordDiagnosticOtherInfosModal } from '../redux/TreatmentClinicalRecord.action';
import MessagesComponent from '../../../../components/Messages.component';
import { Form, Formik } from 'formik';
import TreatmentClinicalRecordApi from '../../../../service/TreatmentClinicalRecordApi';
import { showLoader, hideLoader } from '../../../../components/actions/Loader.actions';

class TreatmentClinicalRecordDiagnosticOtherInformationModal extends Component {
  state = {
    error: {
      display: false,
      type: 'danger',
      message: ''
    }
  };

  closeModal = () => {
    const { closeModal } = this.props;

    closeModal();

    this.setState({
      error: {
        display: false,
        type: 'error',
        message: ''
      }
    });
  };

  saveOtherInformations = async (values) => {
    console.log(values);
    const { showLoader, hideLoader, updateParent, treatment, record, diagnostic } = this.props;
    showLoader();
    try {
      await TreatmentClinicalRecordApi.saveDiagnosticOtherInformations({
        treatmentId: treatment,
        recordId: record,
        diagnosticId: diagnostic.id,
        fator_risco: values.fator_risco,
        limitacoes: values.limitacoes_compromissos,
        outras_anotacoes: values.outras_anotacoes
      });
      updateParent();
      this.closeModal();
    } catch (e) {
      console.log(e);
      this.setState({
        error: {
          display: true,
          type: 'danger',
          message: 'Erro ao salvar outras informações por favor entre em contato com o suporte'
        }
      });
    }

    hideLoader();
  };

  render() {
    const { modal, formInit } = this.props;
    const { error } = this.state;

    return (
      <Modal isOpen={modal} toggle={this.closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={this.closeModal}
          close={
            <button className='close' onClick={this.closeModal}>
              &times;
            </button>
          }
        >
          <strong>Editar</strong> Outras Anotações
        </ModalHeader>
        <Formik initialValues={formInit} validateOnChange={false} onSubmit={this.saveOtherInformations}>
          {({ errors, values, handleChange }) => (
            <Form>
              <ModalBody>
                <div className='margin-top-10'>
                  <MessagesComponent display={error.display} type={error.type} message={error.message} />
                  <Row className='margin-top-10'>
                    <Col sm='12'>
                      <label>Anotações</label>
                      <textarea
                        rows='2'
                        cols='50'
                        name={'outras_anotacoes'}
                        value={values.outras_anotacoes}
                        onChange={handleChange}
                      ></textarea>
                    </Col>
                  </Row>
                </div>
              </ModalBody>
              <ModalFooter>
                <Row>
                  <Col sm={{ size: 3, offset: 6 }}>
                    <button className='button-primary'>Salvar</button>
                  </Col>
                  <Col sm='3'>
                    <button className='button-tertiary' onClick={this.closeModal}>
                      Cancelar
                    </button>
                  </Col>
                </Row>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...propsModalSelector(state),
    formInit: formInitSelector(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  closeModal: () => dispatch(clinicalRecordDiagnosticOtherInfosModal({ modal: false }))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(TreatmentClinicalRecordDiagnosticOtherInformationModal);
