import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

import Icon from '../../components/Icon.component';
import { connect } from 'react-redux';

class ContentPage extends Component {
  render() {
    const { title, image, action } = this.props;

    return (
      <main className='main-container-overflow'>
        <Row>
          <Col sm='12'>
            <div className='content-header'>
              {image ? (
                <a href='#' onClick={action}>
                  <Icon className='icon-header icon-size-one' image={image} />
                </a>
              ) : null}
              <div className='title-content'>{title}</div>
            </div>
          </Col>
        </Row>
        {this.props.children}
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return state.ContentReducer || {};
};

export default connect(mapStateToProps)(ContentPage);
