import { LOADER_MESSAGE } from '../actions/Loader.actions';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADER_MESSAGE:
      return action.payload;
    default:
      return state;
  }
};
