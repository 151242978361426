import { call, put } from 'redux-saga/effects';
import { hideLoader, showLoader } from '../../../../components/actions/Loader.actions';
import DomainsAPI from '../../../../service/DomainsAPI';
import { treatmentEvaluationModalOrthoDocs } from '../redux/TreatmentEvaluation.action';

export function* modalEvaluationOrthoDocs({ payload: { modal, formLoad = {} } }) {
  yield put(showLoader());

  const docs = yield call(DomainsAPI.findAllOrthoDocs);

  formLoad.formData = {
    docs
  };

  yield put(treatmentEvaluationModalOrthoDocs({ modal, formLoad }));

  yield put(hideLoader());
}
