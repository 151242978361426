import t from 'typy';
import { createSelector } from 'reselect';

const scheduleEventModalSelector = (state) =>
  t(state, 'ScheduleReducer.scheduleAppointmentPatientSelectModal').safeObject;

export const scheduleAppointmentReducerToProps = createSelector(
  scheduleEventModalSelector,
  (scheduleAppointmentPatientSelectModal = {}) => {
    const { date, modal, dentist, chair, start, end } = scheduleAppointmentPatientSelectModal;

    return {
      date,
      modal,
      dentist,
      chair,
      start,
      end
    };
  }
);
