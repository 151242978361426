export const SETTINGS_PROCEDURES_TABLE_PRICE_REGISTER_PAGE = 'SETTINGS_PROCEDURES_TABLE_PRICE_REGISTER_PAGE';
export const SETTINGS_PROCEDURES_TABLE_PRICE_MONTHLY_REGISTER_PAGE =
  'SETTINGS_PROCEDURES_TABLE_PRICE_MONTHLY_REGISTER_PAGE';

export function procedureTablePrice({ modal, formData }) {
  return {
    type: SETTINGS_PROCEDURES_TABLE_PRICE_REGISTER_PAGE,
    payload: {
      modal,
      formData
    }
  };
}

export function procedureTablePriceMonthly({ modal, formData }) {
  return {
    type: SETTINGS_PROCEDURES_TABLE_PRICE_MONTHLY_REGISTER_PAGE,
    payload: {
      modal,
      formData
    }
  };
}
