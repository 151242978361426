import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row, Table } from 'reactstrap';
import { AiOutlinePrinter } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'typy';
import { hideLoader, showLoader } from '../../../components/actions/Loader.actions';
import { arrayToSelectItems, reactSelectHandleChange } from '../../../utils/ReactSelectUtils';
import MultiSelect from '@khanacademy/react-multi-select';
import { Form, Formik } from 'formik';
import { IfComponent } from '../../../components/if.component';
import ReactTooltip from 'react-tooltip';
import { loadDentists } from '../../../shared/dentists_store';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import SelectComponent from '../../../components/SelectComponent';
import moment from 'moment';
import CashAPI from '../../../service/CashAPI';
import { formatReal } from '../../../utils/Formatter';
import ToastUtils from '../../../utils/ToastUtils';
import { FiPercent } from 'react-icons/fi';
import ModalPeriod from '../../../components/modal_period.modal';

const FinancialBillingPage = (props) => {
  const comboref = useRef();
  const periodRef = useRef();
  const dispatch = useDispatch();

  const { dentists } = useSelector(({ DentistsSharedReducer }) => DentistsSharedReducer.dentists);
  const [periodo, setPeriodo] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  });
  const [periodoOptions, setPeriodoOptions] = useState([
    { label: 'Período', value: null },
    { label: 'Hoje', value: 'h' },
    { label: 'Essa semana', value: 'w' },
    { label: 'Este mês', value: 'm' },
    { label: 'Mês passado', value: 'p' },
    { label: 'Selecionar Período', value: 'c' }
  ]);
  const dentistsOptions = dentists && arrayToSelectItems(dentists, 'nome', 'id');
  const [reportData, setReportData] = useState([]);
  const [totalGeral, setTotalGeral] = useState(0);
  const [showPercents, setShowPercents] = useState(false);

  useEffect(() => {
    dispatch(loadDentists());
  }, []);

  function buscarDados(values) {
    let start, end;
    dispatch(showLoader());
    const toDay = moment();
    const lastMonth = moment().subtract(1, 'month');

    switch (values.periodo.value) {
      case 'h':
        start = toDay.format('YYYY-MM-DD');
        end = toDay.format('YYYY-MM-DD');
        break;
      case 'w':
        start = toDay.startOf('week').format('YYYY-MM-DD');
        end = toDay.endOf('week').format('YYYY-MM-DD');
        break;
      case 'm':
        start = toDay.startOf('month').format('YYYY-MM-DD');
        end = toDay.endOf('month').format('YYYY-MM-DD');
        break;
      case 'p':
        start = lastMonth.startOf('month').format('YYYY-MM-DD');
        end = lastMonth.endOf('month').format('YYYY-MM-DD');
        break;
      case 'c':
        start = moment(periodo.startDate).format('YYYY-MM-DD');
        end = moment(periodo.endDate).format('YYYY-MM-DD');
        break;
    }

    CashAPI.findReportBillings(values.dentistas, start, end)
      .then(({ data }) => {
        dispatch(hideLoader());
        setReportData(data);

        const valorTotal = data.reduce(
          (total, report) => report.totalPorFormaDePagamento.reduce((total, dado) => (total += dado.total), total),
          0
        );
        setTotalGeral(valorTotal);
      })
      .catch((err) => {
        console.error(err);
        dispatch(hideLoader());
        ToastUtils.error('Erro ao buscar recibos, entre em contato com o suporte');
      });
  }

  function getTotalByPaymentType(data, type) {
    const total = t(
      data.find((d) => d.formaPagamento.id == type),
      'total'
    ).safeNumber;
    return total ? formatReal(total) : 'R$ 0,00';
  }

  function calculateTotalByType(data, type) {
    return data.reduce((total, report) => {
      return report.totalPorFormaDePagamento
        .filter((f) => f.formaPagamento.id == type)
        .reduce((total, dado) => {
          return (total += dado.total);
        }, total);
    }, 0);
  }

  function calculatePercent(data, total, type) {
    const totalType = calculateTotalByType(data, type);

    return ((totalType / total) * 100).toFixed(1);
  }

  return (
    <div className='box-default report-box-default' style={{ minHeight: '60vh' }}>
      <Row>
        <Col sm={12}>
          <strong className='title-medium-plus text-uppercase'>Relatório de Faturamento</strong>
        </Col>
      </Row>
      <Formik
        initialValues={{
          periodo: { label: 'Período', value: null }
        }}
        validationSchema={''}
        onSubmit={buscarDados}
        validateOnChange={false}
      >
        {({ values, handleChange, errors, resetForm, submitForm }) => {
          return (
            <Form>
              <Container>
                <Row>
                  <Col sm={{ size: 3 }}>
                    <div style={{ marginLeft: 0, marginTop: 10, marginBottom: 10 }}>
                      <span className='report-doctor'>
                        <span className='report-doctor-select'>
                          <MultiSelect
                            options={dentistsOptions}
                            selected={t(values.dentistas).safeArray}
                            onSelectedChanged={(selected) =>
                              reactSelectHandleChange(selected, handleChange, 'dentistas')
                            }
                            disableSearch={true}
                            overrideStrings={{
                              selectSomeItems: 'Doutor(a)',
                              allItemsAreSelected: 'Todos os dentistas',
                              selectAll: 'Selecionar Todos',
                              search: 'Buscar'
                            }}
                          />
                        </span>
                      </span>
                    </div>
                  </Col>

                  <Col sm={{ size: 3 }} className='ml-2'>
                    <div style={{ marginLeft: 0, marginTop: 10, marginBottom: 10 }}>
                      <span className='report-doctor'>
                        <span className='report-doctor-select'>
                          <SelectComponent
                            name={'periodo'}
                            values={values}
                            options={periodoOptions}
                            handleChange={handleChange}
                            placeholder={'Período'}
                            callbackOnChange={(p) => {
                              if (p.value === 'c') {
                                periodRef.current.openModal();
                              }
                            }}
                            errors={errors}
                          />
                        </span>
                      </span>
                    </div>
                  </Col>
                  <Col sm={{ size: 1 }} className='ml-1'>
                    <span style={{ marginTop: 9, width: 50, height: 35 }} className='btn btn-default text-sm'>
                      <a data-tip data-for={'tooltipPercent'} onClick={() => setShowPercents(!showPercents)}>
                        <FiPercent size={19} style={{ marginTop: '0.5rem' }} />
                      </a>
                      <ReactTooltip id='tooltipPercent' className='tooltip-griks' type='light'>
                        Calcular Valor Percentual
                      </ReactTooltip>
                    </span>
                  </Col>
                  <Col sm={{ size: 1 }} className='ml-n4'>
                    <button
                      style={{ marginTop: 9, width: 105, height: 35 }}
                      type={'submit'}
                      className='btn btn-default text-sm'
                    >
                      {t(values.dentistas).safeArray.length > 0 ? 'Filtrar' : 'Exibir Todos'}
                    </button>
                  </Col>
                  <Col sm={{ size: 2 }} className='ml-3'>
                    <IfComponent conditional={t(values.dentistas).safeArray.length > 0}>
                      <button
                        style={{ marginTop: 9, width: 105, height: 35, marginLeft: 0 }}
                        className='btn btn-outline-restartfilter text-sm font-light'
                        type={'button'}
                        onClick={() => {
                          resetForm({
                            periodo: { label: 'Período', value: null }
                          });
                        }}
                      >
                        Limpar Filtro
                      </button>
                      <span
                        style={{ marginTop: 9, width: 50, height: 35 }}
                        className='btn btn-default text-sm border ml-4'
                      >

                        {
                          /*
                          <a
                            data-tip
                            data-for={'tooltipPrint'}
                            onClick={() => {
                              localStorage.setItem('overviewDatateste', JSON.stringify({ periodo }));
                              window.open("teste.js");
                            }}
                            
                            //href={'/teste.js'}
                            target={'_blank'}
                            rel='noopener noreferrer'
                          >
                            <AiOutlinePrinter size={22} />
                          </a>
                          */
                        }

                        <a data-tip data-for={'tooltipPrint'}>
                          <AiOutlinePrinter className='text-center mt-1' size={22} />
                        </a> 


                        <ReactTooltip id='tooltipPrint' className='tooltip-griks' type='light'>
                          Imprimir
                        </ReactTooltip>
                      </span>
                    </IfComponent>
                  </Col>

                  <Col sm={{ size: 1 }}>
                    <span
                      style={{
                        marginTop: 9,
                        marginLeft: '3.8rem',
                        backgroundColor: '#f7f9fc',
                        border: '1px solid #cfd5e3',
                        width: 145,
                        height: 35,
                        borderRadius: '0.25rem'
                      }}
                      className='bg border font-bold text-center text-sm float-left'
                    >
                      <p className='p-2 text-secondary'>
                        Total: <strong className='font-bolder'>{formatReal(totalGeral)}</strong>
                      </p>
                    </span>
                  </Col>
                </Row>
              </Container>

              <Table className='table-header-fixed p-2' responsive>
                <thead style={{ backgroundColor: '#ebecf2' }}>
                  <tr className='row py-2'>
                    <th className='col-sm-2 py-0'>
                      <span className='text-sm text-secondary-d4 font-weight-bold text-nowrap'>Doutor(a)</span>
                    </th>
                    <th className='col-sm-2 py-0'>
                      <span className='text-sm text-secondary-d4 font-weight-bold text-nowrap pl-5'>
                        Cartão de Crédito
                      </span>
                    </th>
                    <th className='col-sm-2 py-0'>
                      <span className='text-sm text-secondary-d4 font-weight-bold text-nowrap'>Cartão de Débito</span>
                    </th>
                    <th className='col-sm-1 py-0'>
                      <span className='text-sm text-secondary-d4 font-weight-bold text-nowrap ml-n5'>Cheque</span>
                    </th>
                    <th className='col-sm-1 py-0'>
                      <span className='text-sm text-secondary-d4 font-weight-bold text-nowrap ml-n4'>Dinheiro</span>
                    </th>
                    <th className='col-sm-1 py-0'>
                      <span className='text-sm text-secondary-d4 font-weight-bold text-nowrap ml-n3'>
                        Transferência
                      </span>
                    </th>
                    <th className='col-sm-1 py-0'>
                      <span className='text-sm text-secondary-d4 font-weight-bold text-nowrap pl-4'>Boleto</span>
                    </th>
                    <th className='col-sm-1 py-0'>
                      <span className='text-sm text-secondary-d4 font-weight-bold text-nowrap pl-5'>Total</span>
                    </th>
                  </tr>
                </thead>
                <tbody style={{ maxHeight: 'calc(100vh - 400px)' }}>
                  {reportData.map((r) => {
                    const valorTotal = r.totalPorFormaDePagamento.reduce((total, data) => {
                      return (total += data.total);
                    }, 0);

                    return (
                      <tr
                        className={'row py-0 box-basic px-0'}
                        style={{ borderBottom: '1px solid #dee2e6' }}
                        key={r.id}
                      >
                        <td className='col-sm-2'>
                          <div className='text-sm font-light text-secondary'>{r.dentista.nome_completo}</div>
                        </td>
                        <td className='col-sm-2'>
                          <div className='text-sm font-light text-secondary pl-5 text-nowrap'>
                            {getTotalByPaymentType(r.totalPorFormaDePagamento, 'CC')}
                          </div>
                        </td>
                        <td className='col-sm-2'>
                          <div className='text-sm font-light text-secondary text-nowrap'>
                            {getTotalByPaymentType(r.totalPorFormaDePagamento, 'CD')}
                          </div>
                        </td>
                        <td className='col-sm-1'>
                          <div className='text-sm font-light text-secondary text-nowrap ml-n5'>
                            {getTotalByPaymentType(r.totalPorFormaDePagamento, 'C')}
                          </div>
                        </td>
                        <td className='col-sm-1'>
                          <div className='text-sm font-light text-secondary text-nowrap ml-n4'>
                            {getTotalByPaymentType(r.totalPorFormaDePagamento, 'D')}
                          </div>
                        </td>
                        <td className='col-sm-1'>
                          <div className='text-sm font-light text-secondary text-nowrap ml-n3'>
                            {getTotalByPaymentType(r.totalPorFormaDePagamento, 'T')}
                          </div>
                        </td>
                        <td className='col-sm-1'>
                          <div className='text-sm font-light text-secondary text-nowrap pl-4'>
                            {getTotalByPaymentType(r.totalPorFormaDePagamento, 'B')}
                          </div>
                        </td>
                        <td className='col-sm-1'>
                          <div className='text-sm font-bold text-secondary text-nowrap pl-5'>
                            {formatReal(valorTotal)}
                          </div>
                        </td>
                      </tr>
                    );
                  })}

                  <IfComponent conditional={reportData.length > 1}>
                    <tr className={'row py-0 px-0 box-basic'}>
                      <td className='col-sm-2'>
                        <div className='text-sm font-bold text-secondary'>TOTAL - R$</div>
                      </td>
                      <td className='col-sm-2'>
                        <div className='text-sm font-bold text-secondary pl-5 text-nowrap'>
                          {formatReal(calculateTotalByType(reportData, 'CC'))}
                        </div>
                      </td>
                      <td className='col-sm-2'>
                        <div className='text-sm font-bold text-secondary text-nowrap'>
                          {formatReal(calculateTotalByType(reportData, 'CD'))}
                        </div>
                      </td>
                      <td className='col-sm-1'>
                        <div className='text-sm font-bold text-secondary text-nowrap ml-n5'>
                          {formatReal(calculateTotalByType(reportData, 'C'))}
                        </div>
                      </td>
                      <td className='col-sm-1'>
                        <div className='text-sm font-bold text-secondary text-nowrap ml-n4'>
                          {formatReal(calculateTotalByType(reportData, 'D'))}
                        </div>
                      </td>
                      <td className='col-sm-1'>
                        <div className='text-sm font-bold text-secondary text-nowrap ml-n3'>
                          {formatReal(calculateTotalByType(reportData, 'T'))}
                        </div>
                      </td>
                      <td className='col-sm-1'>
                        <div className='text-sm font-bold text-secondary text-nowrap pl-4'>
                          {formatReal(calculateTotalByType(reportData, 'B'))}
                        </div>
                      </td>
                      <td className='col-sm-2'>
                        <div className='text-sm font-bold text-secondary text-nowrap pl-5'>
                          {formatReal(totalGeral)}
                        </div>
                      </td>
                    </tr>
                  </IfComponent>

                  <IfComponent conditional={reportData.length > 0 && showPercents}>
                    <tr className={'row py-0 box-basic px-0'}>
                      <td className='col-sm-2'>
                        <div className='text-sm font-light text-secondary'>
                          <IfComponent conditional={reportData.length == 1}>
                            {t(reportData[0], 'dentista.nome_completo').safeString} - %
                          </IfComponent>

                          <IfComponent conditional={reportData.length > 1}>
                            <div className='text-sm font-bold text-secondary'>TOTAL - %</div>
                          </IfComponent>
                        </div>
                      </td>
                      <td className='col-sm-2'>
                        <div className='text-sm font-light text-secondary pl-5 text-nowrap'>
                          {t(calculatePercent(reportData, totalGeral, 'CC')).safeString.replace('.', ',')}%
                        </div>
                      </td>
                      <td className='col-sm-2'>
                        <div className='text-sm font-light text-secondary text-nowrap'>
                          {t(calculatePercent(reportData, totalGeral, 'CD')).safeString.replace('.', ',')}%
                        </div>
                      </td>
                      <td className='col-sm-1'>
                        <div className='text-sm font-light text-secondary text-nowrap ml-n5'>
                          {t(calculatePercent(reportData, totalGeral, 'C')).safeString.replace('.', ',')}%
                        </div>
                      </td>
                      <td className='col-sm-1'>
                        <div className='text-sm font-light text-secondary text-nowrap ml-n4'>
                          {t(calculatePercent(reportData, totalGeral, 'D')).safeString.replace('.', ',')}%
                        </div>
                      </td>
                      <td className='col-sm-1'>
                        <div className='text-sm font-light text-secondary text-nowrap ml-n3'>
                          {t(calculatePercent(reportData, totalGeral, 'T')).safeString.replace('.', ',')}%
                        </div>
                      </td>
                      <td className='col-sm-1'>
                        <div className='text-sm font-light text-secondary text-nowrap pl-4'>
                          {t(calculatePercent(reportData, totalGeral, 'B')).safeString.replace('.', ',')}%
                        </div>
                      </td>
                      <td className='col-sm-2'>
                        <div className='text-sm font-light text-secondary text-nowrap pl-5'>100%</div>
                      </td>
                    </tr>
                  </IfComponent>
                </tbody>
              </Table>
            </Form>
          );
        }}
      </Formik>
      <ModalPeriod
        ref={periodRef}
        onSelected={(item) => {
          setPeriodo(item);
          periodoOptions.map((p) => {
            if (p.value === 'c') {
              p.label = `${moment(item.startDate).format('DD/MM/YYYY')} a ${moment(item.endDate).format('DD/MM/YYYY')}`;
            }
          });
        }}
      />
    </div>
  );
};

export default FinancialBillingPage;
