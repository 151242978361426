import RestTemplate from '../utils/restTemplate/rest.template';

class FrequencyOrthodonticsAPI {
  static findFrequency({ dentist }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/frequencia/dentista/${dentist}/`);
  }

  static findFrequencyScheduleAnotherMonth({ dentist }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/frequencia/dentista/${dentist}/scheduleAnotherMonth`);
  }

  static findFrequencyFDS({ dentist }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/frequencia/dentista/${dentist}/patients/fds`);
  }

  static getScheduleRedial({ dentist }) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/frequencia/dentista/${dentist}/patients/schedule-redial`);
  }
}

export default FrequencyOrthodonticsAPI;
