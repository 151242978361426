import { all, call, put } from 'redux-saga/effects';
import { budgetModalTreatmentPlan } from '../redux/TreatmentBudget.actions';
import DomainsAPI from '../../../../service/DomainsAPI';
import { showLoader, hideLoader } from '../../../../components/actions/Loader.actions';

export function* modalTreatmentPlan({ payload: { modal, formLoad = {} } }) {
  yield put(showLoader());
  const domains = yield all([call(DomainsAPI.findAllBraces), call(DomainsAPI.findAllTreatmentTypes)]);

  formLoad.braces = domains[0];
  formLoad.treatmentTypes = domains[1];

  yield put(budgetModalTreatmentPlan({ modal, formLoad }));
  yield put(hideLoader());
}
