import React, { Component } from 'react';

import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { hideLoader, showLoader } from '../../../../components/actions/Loader.actions';
import MessagesComponent from '../../../../components/Messages.component';
import { Form, Formik } from 'formik';
import { treatmentEvaluationModalAttendence } from '../redux/TreatmentEvaluation.action';
import TreatmentEvaluationApi from '../../../../service/TreatmentEvaluationApi';
import moment from 'moment';
import * as Yup from 'yup';
import ErrorFieldWithoutBootstrap from '../../../../components/ErrorFieldWithoutBootstrap.component';
import { t } from 'typy';

class TreatmentEvaluationAttendenceRegisterModal extends Component {
  state = {
    message: {
      display: false,
      type: 'danger',
      text: ''
    }
  };

  formValidationSchema = Yup.object().shape({
    dataAtendimento: Yup.string().required('Data de atendimento obrigatória'),
    dentista: Yup.object().required('Doutor(a) obrigatório')
  });

  saveAttendenceRegister = async (values) => {
    const { closeModal, formLoad = {}, showLoader, hideLoader, updateParent } = this.props;
    const { attendence, evaluation } = formLoad;

    showLoader();
    try {
      await TreatmentEvaluationApi.saveAttendenceRegister({
        id: attendence && attendence.id,
        avaliacao: evaluation.avaliacao_id,
        data_atendimento: values.dataAtendimento,
        anotacao: values.anotacao,
        proxima_consulta: values.proximaConsulta,
        dentista: values.dentista && values.dentista.value
      });
      updateParent();
      closeModal();
    } catch (e) {
      console.log(e);
      this.setState({
        message: {
          display: true,
          type: 'danger',
          text: 'Erro ao salvar atendimento entre em contato com o suporte'
        }
      });
    }
    hideLoader();
  };

  render() {
    const { message } = this.state;
    const { modal = false, closeModal, dentistOptions, formInit } = this.props;

    return (
      <Modal isOpen={modal} toggle={closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={closeModal}
          close={
            <button className='close' onClick={closeModal}>
              &times;
            </button>
          }
        >
          <strong>Adicionar</strong> Atendimento
        </ModalHeader>
        <Formik
          validationSchema={this.formValidationSchema}
          validateOnChange={false}
          initialValues={formInit}
          onSubmit={this.saveAttendenceRegister}
        >
          {({ errors, values, handleChange }) => {
            return (
              <Form>
                <ModalBody>
                  <MessagesComponent display={message.display} type={message.type} message={message.text} />
                  <div className='margin-top-10'>
                    <Row className='margin-top-10'>
                      <Col sm='5'>
                        <label>
                          Data de Atendimento <span> *</span>
                        </label>
                      </Col>
                      <Col sm='7'>
                        <label>
                          Doutor(a) <span> *</span>
                        </label>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={5}>
                        <DatePicker
                          selected={values.dataAtendimento}
                          onChange={(d) => {
                            handleChange({
                              target: {
                                name: 'dataAtendimento',
                                value: d || ''
                              }
                            });
                          }}
                          locale='pt-br'
                          className={
                            errors.dataAtendimento ? 'input-secondary is-invalid form-control' : 'input-secondary'
                          }
                          placeholderText=''
                        />
                        <ErrorFieldWithoutBootstrap errors={errors} fieldName={'dataAtendimento'} />
                      </Col>
                      <Col sm={7}>
                        <Select
                          className={errors.dentista ? 'is-invalid' : ''}
                          value={values.dentista}
                          isSearchable={true}
                          ignoreAccents={true}
                          ignoreCase={true}
                          options={dentistOptions}
                          placeholder={''}
                          styles={{
                            control: (styles) => ({ ...styles, ...(errors.dentista ? { borderColor: 'red' } : {}) })
                          }}
                          onChange={(selectedOption) => {
                            handleChange({
                              target: {
                                name: 'dentista',
                                value: selectedOption
                              }
                            });
                          }}
                        />
                        <ErrorFieldWithoutBootstrap errors={errors} fieldName={'dentista'} />
                      </Col>
                    </Row>
                    <Row className='margin-top-10'>
                      <Col sm='12'>
                        <form>
                          <label>Anotações</label>
                          <textarea id={'anotacao'} rows='2' cols='50' onChange={handleChange}>
                            {values.anotacao}
                          </textarea>
                        </form>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm='12'>
                        <label>Próxima Consulta</label>
                        <textarea id={'proximaConsulta'} rows='2' cols='50' onChange={handleChange}>
                          {values.proximaConsulta}
                        </textarea>
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Row>
                    <Col sm={{ size: 3, offset: 6 }}>
                      <button className='button-primary'>Salvar</button>
                    </Col>
                    <Col sm='3'>
                      <button className='button-tertiary' type={'button'} onClick={closeModal}>
                        Cancelar
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = ({ TreatmentEvaluationReducer: { evaluationAttendence = {} } } = {}) => {
  const { formLoad = {} } = evaluationAttendence;
  const { formData: { dentists = [] } = {}, attendence = {} } = formLoad;
  const dentistOptions = dentists.map((d) => ({ value: d.id, label: d.nome }));
  const defaultOption = { value: null, label: '' };
  dentistOptions.unshift(defaultOption);

  let dentista = {};

  if (attendence.dentista) {
    dentista = dentistOptions.find((d) => d.value === attendence.dentista.id);
  } else if (t(formLoad, 'treatment.dentista').isDefined) {
    dentista = dentistOptions.find((d) => d.label === formLoad.treatment.dentista);
  }

  return {
    ...evaluationAttendence,
    dentistOptions,
    formInit: {
      dataAtendimento: attendence.dataAtendimento ? moment(attendence.dataAtendimento) : moment(),
      anotacao: attendence.anotacao,
      proximaConsulta: attendence.proximaConsulta,
      dentista
    }
  };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  closeModal: () => dispatch(treatmentEvaluationModalAttendence({ modal: false }))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(TreatmentEvaluationAttendenceRegisterModal);
