import t from 'typy';
import { createSelector } from 'reselect';

const scheduleRedialModalSelector = (state) => t(state, 'ScheduleReducer.scheduleRedialModal').safeObject;

export const scheduleRedialModalToProps = createSelector(
  scheduleRedialModalSelector,
  ({ modal, dentist, groups, patient = {} } = {}) => {
    return {
      modal,
      dentist,
      groups,
      patient,
      reasons: [
        { label: 'Saiu sem Agendar', value: 'S', data: 'Saiu sem Agendar' },
        { label: 'Fora do Sistema (FDS)', value: 'F', data: 'Fora do Sistema (FDS)' },
        { label: 'Iniciar Tratamento', value: 'I', data: 'Iniciar Tratamento' },
        // {label: 'Controle Pré-Tratamento', value: 'P', data: 'Controle Pré-Tratamento'},
        // {label: 'Contenção', value: 'C', data: 'Contenção'},

        { label: 'Outros', value: 'O', data: 'Outros' }
      ]
    };
  }
);
