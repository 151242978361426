import { Form, Formik } from 'formik';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal, ModalHeader, Row, Col, ModalBody, UncontrolledTooltip, ModalFooter } from 'reactstrap';
import { IfComponent } from '../../../components/if.component';
import MessagesComponent from '../../../components/Messages.component';
import { dateFormat } from '../../../utils/Formatter';
import edit from '../../../assets/img/icons/edit.svg';
import Icon from '../../../components/Icon.component';
import Select from 'react-select';
import ErrorFieldWithoutBootstrap from '../../../components/ErrorFieldWithoutBootstrap.component';
import { RadioButton, RadioGroup } from 'react-radio-buttons';
import noavatar from '../../../assets/img/noavatar.png';

const ScheduleAppointmentModalNew = (props, ref) => {
  const [showNotes, setShowNotes] = useState(false);
  const [changeProcedure, setChangeProcedure] = useState(false);
  const [showPendence, setShowPendence] = useState(false);
  const [procedures, setProcedures] = useState([]);
  const [message, setMessage] = useState({ display: false, text: '' });
  const [showModal, setShowModal] = useState(false);

  useImperativeHandle(ref, () => ({
    openModal() {
      setShowModal(true);
      console.log(showModal);
    }
  }));

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Modal isOpen={showModal} toggle={closeModal} size={'mdplus'}>
        <ModalHeader
          className='title-primary'
          toggle={closeModal}
          close={
            <button className='close' onClick={closeModal}>
              &times;
            </button>
          }
        ></ModalHeader>
        <Formik>
          {({ error, values, handleChange }) => {
            //   this.errors = errors;
            return (
              <Form>
                <div>
                  <MessagesComponent display={message.display} type={'danger'} message={message.text} />
                </div>
                <div className='box-secondary' style={{ paddingTop: 5, paddingBottom: 5 }}>
                  <div className='text-left'>
                    <Row className='text-default pb-0'>
                      <Col sm='3'>
                        <div>
                          <img style={{ width: 70 }} className='img-patient' alt='usuario' src={noavatar} />
                        </div>
                      </Col>
                      <Col sm='5'>
                        <p className='mt-2'>
                          <strong className={'font-bold'}>Nome do Paciente</strong>
                          <br />
                          <strong className='p-0 m-0'>455545</strong> 27a 6m
                        </p>
                      </Col>
                      <Col sm='4'>
                        <p className='mt-2'></p>
                      </Col>
                    </Row>
                  </div>
                </div>
                <ModalBody>
                  <Row className='mt-2'>
                    <IfComponent>
                      <Col sm='6'>
                        <p className='text-right'>
                          <strong>Tratamento</strong>
                        </p>
                      </Col>
                      <Col sm='6'>
                        <p className='text-left'>aaaa</p>
                      </Col>
                    </IfComponent>
                    <Col sm='6'>
                      <p className='text-right'>
                        <strong>Data</strong>
                      </p>
                    </Col>
                    <Col sm='6'>
                      <p className='text-left'>06/05/2021</p>
                    </Col>
                    <Col sm='6'>
                      <p className='text-right'>
                        <strong>Horário</strong>
                      </p>
                    </Col>
                    <Col sm='6'>
                      <p className='text-left'>start procedimento_tempo</p>
                    </Col>
                    <Col sm='6'>
                      <p className='text-right'>
                        <strong>Procedimento</strong>
                      </p>
                    </Col>
                    <Col sm='6'>
                      <p className='text-left'>
                        <span
                          className='procedure-primary'
                          id={'procedure'}
                          style={{
                            backgroundColor: values.procedimento_categoria_rgb,
                            color: '#454c5e',
                            fontSize: 13
                          }}
                        >
                          {values.procedimento_codinome}
                        </span>
                        <a href={'#'} onClick={() => this._clearSelectProcedure(handleChange)}>
                          <Icon className='icon icon-size-small' image={edit} alt='edit' />
                        </a>
                        <UncontrolledTooltip className='tooltip-white' placement='top' target='procedure'>
                          {values.procedimento_nome}
                        </UncontrolledTooltip>
                      </p>
                    </Col>
                  </Row>
                  <IfComponent conditional={changeProcedure}>
                    <Row>
                      <Col sm='6'>
                        <label>
                          Grupo<span>*</span>
                        </label>
                      </Col>
                      <Col sm='6'>
                        <label>
                          Procedimento<span>*</span>
                        </label>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm='6'>
                        <Select value={values.category} onChange={(c) => {}} />
                      </Col>
                      <Col sm='6'>
                        <Select
                          placeholder={''}
                          value={values.procedure}
                          options={procedures}
                          onChange={(p) => {
                            this._selectProcedure(p, handleChange);
                          }}
                        />
                        <ErrorFieldWithoutBootstrap fieldName={'procedure'} />
                      </Col>
                    </Row>
                  </IfComponent>
                  <IfComponent conditional={!showNotes}>
                    <Row>
                      <Col sm='6'>
                        <p className='text-right'>
                          <strong>Observações</strong>
                        </p>
                      </Col>
                      <Col sm='6'>
                        <p className='text-left'>
                          <a href={'#'} onClick={() => this.setState({ showNotes: !showNotes })}>
                            <Icon className='icon icon-size-small mr-2' image={edit} alt='edit' />
                          </a>
                          <IfComponent conditional={values.anotacao !== ''}>
                            <i>
                              <span>{values.anotacao}</span>
                            </i>
                          </IfComponent>
                        </p>
                      </Col>
                    </Row>
                  </IfComponent>
                  <IfComponent conditional={showNotes}>
                    <Row>
                      <Col sm={{ size: 12 }}>
                        <label>Observações</label>
                        <textarea
                          rows='1'
                          cols='1'
                          onChange={handleChange}
                          value={values.anotacao}
                          name={'anotations'}
                          className='form-control mb-2'
                        />
                      </Col>
                    </Row>
                  </IfComponent>
                  <Row>
                    <Col sm='12'>
                      <form>
                        <Row className='p-0'>
                          <Col sm={{ size: 6 }} className='p-0'>
                            <label className='float-right'>Pendência de Laboratório</label>
                          </Col>
                          <Col sm={{ size: 6 }}>
                            <RadioGroup
                              pointColor='var(--primary)'
                              className='radio-griks height-sm'
                              name='pendencia'
                              value={values.pendencia}
                              selectedValue={values.pendencia}
                              horizontal
                              onChange={(e) => {
                                handleChange({
                                  target: { name: 'pendencia', value: e === 'true' ? 'true' : 'false' }
                                });
                                handleChange({
                                  target: {
                                    name: 'pendencia_obs',
                                    value: e === 'true' ? values.pendencia_obs : ''
                                  }
                                });
                              }}
                            >
                              <RadioButton
                                rootColor={'var(--secondary-l3)'}
                                pointColor={'var(--primary)'}
                                iconSize={10}
                                value='false'
                              >
                                Não
                              </RadioButton>
                              <RadioButton
                                rootColor={'var(--secondary-l3)'}
                                pointColor={'var(--primary)'}
                                iconSize={10}
                                value='true'
                              >
                                Sim
                              </RadioButton>
                            </RadioGroup>
                          </Col>
                        </Row>
                      </form>
                    </Col>
                  </Row>
                  <IfComponent conditional={values.pendencia === 'true'}>
                    <Row>
                      <Col sm='12'>
                        <label>Pendência</label>
                        <input
                          className='input-secondary'
                          type='text'
                          name='pendencia_obs'
                          value={values.pendencia_obs}
                          onChange={handleChange}
                        />
                      </Col>
                    </Row>
                  </IfComponent>
                  <div>
                    <Row>
                      <Col sm='6'>
                        <p className='text-right'>
                          <strong>Consultas Futuras</strong>
                        </p>
                      </Col>
                      <Col sm='6'>
                        <p className='text-left'>nextSchedule.proximaConsulta</p>
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Row>
                    <Col sm='12'>
                      <button type='button' className='button-primary float-right'>
                        Salvar
                      </button>
                      <button className='button-tertiary float-right mr-3' type='button' onClick={closeModal}>
                        Cancelar
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default forwardRef(ScheduleAppointmentModalNew);
