import t from 'typy';
import { createSelector } from 'reselect';

const treatmentPlanGadgetsModalSelector = (state) =>
  t(state, 'TreatmentClinicalRecordReducer.treatmentPlanGadgetsModal').safeObject;
const treatmentPlanGadgetslSelector = (state) =>
  t(state, 'TreatmentClinicalRecordReducer.treatmentPlanGadgetsModal.gadgets').safeObject;

export const propsModalSelector = createSelector(
  treatmentPlanGadgetsModalSelector,
  (treatmentPlanGadgetsModal = {}) => {
    const { modal, treatment, record, plan = {} } = treatmentPlanGadgetsModal;
    return {
      modal,
      treatment,
      record,
      plan: plan.id
    };
  }
);

export const gadgetsSelector = createSelector(treatmentPlanGadgetslSelector, (gadgetsSelector = []) => {
  return gadgetsSelector;
});

export const formInitSelector = createSelector(treatmentPlanGadgetsModalSelector, (treatmentPlanGadgetsModal = {}) => {
  const { plan: { aparelhos_dispositivos = [] } = {} } = treatmentPlanGadgetsModal;

  return {
    aparelhos_dispositivos: aparelhos_dispositivos.flatMap((a) => a.aparelho_dispositivo)
  };
});
