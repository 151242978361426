import React, { Component } from 'react';
import Webcam from 'react-webcam';
import { Button, Row, Col } from 'reactstrap';
export class TakePictureComponent extends Component {
  setRef = (webcam) => {
    this.webcam = webcam;
  };

  capture = () => {
    const imageSrc = this.webcam.getScreenshot();
    this.props.setImage(imageSrc);
  };

  render() {
    const videoConstraints = {
      facingMode: 'user'
    };

    const { audio, heigth, width, format = 'image/jpg' } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
        className='content-box-without-color'
      >
        <div>
          <Webcam
            audio={audio}
            height={heigth}
            ref={this.setRef}
            screenshotFormat={format}
            width={width}
            videoConstraints={videoConstraints}
            screenshotQuality={1}
            screenshotWidth={200}
          />
          <Row>
            <Col sm='12'>
              <button className='button-primary' onClick={this.capture}>
                Capturar Foto
              </button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default TakePictureComponent;
