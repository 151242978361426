import React, { Component } from 'react';

import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Input } from 'reactstrap';
import MaskedInput from 'react-text-mask';
import { cepFormat, maskCEP, maskPhoneDefine } from '../../../utils/Formatter';

import Select from 'react-select';
import { reactSelectHandleChange, reactSelectStyleIsError } from '../../../utils/ReactSelectUtils';
import { selectStyles } from '../../../../src/config/core.config';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { editClinicAddressSettings, sagaSaveAddressClinicSettings } from './redux/settings.actions';
import { Formik } from 'formik';
import { showLoader } from '../../../components/actions/Loader.actions';

import states from '../../../utils/states';
import * as Yup from 'yup';
import ErrorField from '../../../components/ErrorField.component';
import SelectComponent from '../../../components/SelectComponent';

class SettingsAddressPage extends Component {
  saveSettings = (values) => {
    this.props.showLoader();
    const formData = {
      ...values,
      id: this.props.address.id
    };
    this.props.save(formData);
  };

  validationSchema = Yup.object().shape({
    telefone_completo: Yup.string().required('Telefone Obrigatório')
  });

  render() {
    const { address = {}, closeModal } = this.props;
    const { show = false, endereco = {} } = address;
    return (
      <Formik
        enableReinitialize={true}
        initialValues={endereco}
        validateOnChange={false}
        validationSchema={this.validationSchema}
        onSubmit={this.saveSettings}
      >
        {({ errors, handleSubmit, handleBlur, handleChange, values }) => {
          return (
            <Modal isOpen={show} toggle={closeModal} className={this.props.className}>
              <form onSubmit={handleSubmit}>
                <ModalHeader
                  className='title-primary'
                  toggle={closeModal}
                  close={
                    <button className='close' type={'button'} onClick={closeModal}>
                      &times;
                    </button>
                  }
                >
                  <strong>Editar Endereço e Contato</strong>
                </ModalHeader>
                <ModalBody>
                  <div className='margin-top-10'>
                    <Row>
                      <Col sm='4'>
                        <label>CEP</label>
                        <MaskedInput
                          id='cep'
                          name='cep'
                          mask={maskCEP()}
                          value={cepFormat(values.cep)}
                          onChange={handleChange}
                          render={(ref, props) => (
                            <Input
                              className='input-secondary'
                              innerRef={ref}
                              // invalid={errors.birthDate ? true : false}
                              {...props}
                            />
                          )}
                        />
                      </Col>
                      <Col sm='6'>
                        <label>Endereço</label>
                        <input
                          className='input-secondary'
                          type='text'
                          name='nome'
                          value={values.nome}
                          onChange={handleChange}
                        ></input>
                      </Col>
                      <Col sm='2'>
                        <label>N°</label>
                        <input
                          className='input-secondary'
                          type='text'
                          name='numero'
                          value={values.numero}
                          onChange={handleChange}
                        ></input>
                      </Col>
                    </Row>
                    <Row className='margin-top-10'>
                      <Col sm='5'>
                        <label>Complemento</label>
                        <input
                          className='input-secondary'
                          type='text'
                          name='complemento'
                          value={values.complemento}
                          onChange={handleChange}
                        ></input>
                      </Col>
                      <Col sm='7'>
                        <label>Bairro</label>
                        <input
                          className='input-secondary'
                          type='text'
                          name='bairro'
                          value={values.bairro}
                          onChange={handleChange}
                        ></input>
                      </Col>
                    </Row>
                    <Row className='margin-top-10'>
                      <Col sm='9'>
                        <label>Cidade</label>
                        <input
                          className='input-secondary'
                          type='text'
                          name='cidade'
                          value={values.cidade}
                          onChange={handleChange}
                        ></input>
                      </Col>
                      <Col sm='3'>
                        <label className='w-100'>UF</label>
                        <SelectComponent
                          name={'uf'}
                          options={states.map((s) => {
                            return { value: s.sigla, label: s.sigla };
                          })}
                          handleChange={handleChange}
                          values={values}
                        />
                        {/*<Select*/}
                        {/*  options={  }*/}
                        {/*  ignoreCase={ true }*/}
                        {/*  placeholder={ '' }*/}
                        {/*  defaultValue={*/}
                        {/*    states.map(s => {*/}
                        {/*      if (s.sigla === values.uf) {*/}
                        {/*        return (*/}
                        {/*          { value: s.sigla, label: s.sigla }*/}
                        {/*        );*/}
                        {/*      }*/}
                        {/*    })*/}
                        {/*  }*/}
                        {/*  onChange={ (e) => reactSelectHandleChange(e.value, handleChange, 'values.uf') }*/}
                        {/*  styles={ reactSelectStyleIsError(errors, 'values.uf.value', selectStyles) }*/}
                        {/*/>*/}
                      </Col>
                    </Row>

                    <hr></hr>

                    <Row>
                      <Col sm='6'>
                        <label>
                          Telefone<span>*</span>
                        </label>
                        <MaskedInput
                          id='telefone_completo'
                          name='telefone_completo'
                          guide={false}
                          mask={maskPhoneDefine(values.telefone_completo)}
                          defaultValue={values.telefone_completo}
                          onChange={handleChange}
                          render={(ref, props) => (
                            <Input
                              className='input-secondary'
                              innerRef={ref}
                              invalid={errors.telefone_completo ? true : false}
                              {...props}
                            />
                          )}
                        />
                        <ErrorField errors={errors} fieldName={'telefone_completo'} />
                      </Col>
                      <Col sm='6'>
                        <label>E-mail</label>
                        <input
                          className='input-secondary'
                          type='text'
                          name='email'
                          value={values.email}
                          onChange={handleChange}
                        ></input>
                      </Col>
                    </Row>
                    <Row className='margin-top-10'>
                      <Col sm='12'>
                        <label>Website</label>
                        <input
                          className='input-secondary'
                          type='text'
                          name='website'
                          value={values.website}
                          onChange={handleChange}
                        ></input>
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Row>
                    <Col sm={{ size: 3, offset: 6 }}>
                      <button className='button-primary' type='submit'>
                        Salvar
                      </button>
                    </Col>
                    <Col sm='3'>
                      <button className='button-tertiary' type={'button'} onClick={closeModal}>
                        Cancelar
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
              </form>
            </Modal>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = ({ SettingsReducer = {} }) => {
  const { settings = {} } = SettingsReducer;
  const { address = {} } = settings;

  let endereco = address.endereco || {};
  const telefone_completo = endereco.ddd + endereco.telefone;

  return {
    address: {
      ...address,
      endereco: {
        ...endereco,
        uf: { label: endereco.uf, value: endereco.uf },
        telefone_completo: telefone_completo || ''
      }
    }
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (data) => dispatch(showLoader()),
    closeModal: (data) => dispatch(editClinicAddressSettings({ clinic: { show: false } })),
    save: (data) => dispatch(sagaSaveAddressClinicSettings(data))
  };
};

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(SettingsAddressPage);
