import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FormGroup, Label, Input, Col } from 'reactstrap';
import ErrorField from '../../components/ErrorField.component';
import PropTypes from 'prop-types';

class LoginForm extends Component {
  static propTypes = {
    submitCallback: PropTypes.func.isRequired
  };

  validateSchema = Yup.object().shape({
    login: Yup.string().required('E-mail Obrigatório').email('E-mail Inválido'),
    senha: Yup.string().required('Senha Obrigatório').label('Senha')
  });

  render() {
    const { submitCallback } = this.props;
    return (
      <Formik validationSchema={this.validateSchema} onSubmit={submitCallback} validateOnChange={false}>
        {({ errors, values, handleSubmit, handleChange }) => (
          <form className='login-form mt-5' onSubmit={handleSubmit}>
            <FormGroup>
              <Label for='examplePassword'>E-mail</Label>
              <Input
                name='login'
                onChange={handleChange}
                invalid={errors.login ? true : false}
                style={{ height: 39 }}
                value={values.login}
              />
              <ErrorField errors={errors} fieldName={'login'} />
            </FormGroup>
            <FormGroup>
              <Label className='margin-top-20' for='examplePassword'>
                Senha
              </Label>
              <Input
                type='password'
                name='senha'
                onChange={handleChange}
                invalid={errors.senha ? true : false}
                style={{ height: 39 }}
              />
              <ErrorField errors={errors} fieldName={'senha'} />
            </FormGroup>

            <div className='login-forgot-password'>
              <Link to='/forgotpassword' className='link-primary'>
                Esqueceu sua Senha?
              </Link>
            </div>

            <Col sm={{ size: 8, offset: 2 }} className='px-4 pt-3'>
              <button type='submit' style={{ height: 39 }} className='button-primary w-100'>
                Entrar
              </button>
            </Col>
          </form>
        )}
      </Formik>
    );
  }
}

export default LoginForm;
