import React, { Component } from 'react';
import { Container, Row, Col, UncontrolledTooltip } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import box from '../../../assets/img/icons/box.svg';
import plus from '../../../assets/img/icons/plus.svg';
import noavatar from '../../../assets/img/noavatar.png';
import Icon from '../../../components/Icon.component';
import { Link } from 'react-router-dom';
import PatientAPI from '../../../service/PacienteAPI';

import SugestionBoxComponent from '../../../components/SuggestionBoxComponent';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';

import { fadeIn } from 'react-animations';
import Radium, { StyleRoot } from 'radium';

import './PatientSearch.css';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import withSecurity from '../../../config/security/security';
import { IfComponent } from '../../../components/if.component';
import { PERMISSOES } from '../../../config/security/permissoes';

class PatientSearch extends Component {
  constructor(props) {
    super(props);
    this.componenteRef = React.createRef();
  }
  state = {
    suggestionsData: [],
    value: '',
    tamanho:null
  };

  componentDidMount() {
    window.addEventListener('load', this.handleLoad);
  }

  selectedPatient = (patientSelected) => {
    this.props.selectPatientAndShowModal(patientSelected);
  };

  renderSuggestion(suggestion, { query }) {
    const suggestionText = `${suggestion.name}`;
    const matches = AutosuggestHighlightMatch(suggestionText, query);
    const parts = AutosuggestHighlightParse(suggestionText, matches);
    if (suggestion.id) {
      return (
        <span className={'suggestion-content'}>
          <img className={'img-circle'} src={suggestion.image} style={{ maxWidth: 62, maxHeight: 62 }} alt='' />
          <span className='name'>
            {parts.map((part, index) => {
              const className = part.highlight ? 'highlight' : null;

              return (
                <span className={className} key={index}>
                  {part.text}
                </span>
              );
            })}
          </span>
        </span>
      );
    } else {
      return (
        <span className={'suggestion-content'}>
          <span className='name'>
            <span className={''}>
              {'Nenhum paciente encontrado com o nome'} {<span className='highlight'>({query})</span>}
            </span>
          </span>
        </span>
      );
    }
  }

  renderSuggestionsContainer({ containerProps, children, query }) {
    return (
      <StyleRoot>
        <div
          {...containerProps}
          style={{
            animation: 'x 0.9s',
            animationName: Radium.keyframes(fadeIn, 'fadeIn'),
            maxHeight: 450,
            overflowY: 'auto',
          }}
        >
          {children}
        </div>
      </StyleRoot>
    );
  }

  findPatients = async (searchTerm) => {
    if (searchTerm.length > 2) {
      try {
        const p = await PatientAPI.pescInc(searchTerm);
        if (p.length === 0) {
          p.push({
            nome: 'Nenhum paciente encontrado'
          });
        }

        this.setState({
          suggestionsData: p.map((pt) => {
            return {
              id: pt.id,
              name: pt.nome,
              image: pt.foto || noavatar
            };
          })
        });
      } catch (err) {
        this.setState({
          suggestionsData: []
        });
      }
    } else {
      this.setState({
        suggestionsData: []
      });
    }
  };

  renderInputComponent = (inputProps) => (
    <div className=''>
      <input
        {...inputProps}
        className='input-box-search '
        type='text'
        name='firstname'
        placeholder='Buscar Paciente'
        autoComplete='off'
        data-lpignore='true'
        spellCheck='false'
      />
    </div>
  );

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue
    });
  };

  componentDidMount() {
    if (this.componenteRef.current) {
      const tamanho = this.componenteRef.current.getBoundingClientRect();
      console.log('Tamanho do componente:', tamanho);
      console.log(tamanho.width)
      this.setState({
        tamanho:tamanho.width
      })
    }
  }

  render() {
    const { value, suggestionsData } = this.state;
    const { showHideModalRegisterPatient, security } = this.props;

    const inputProps = {
      placeholder: '',
      value,
      onChange: this.onChange
    };

    const modulePermission = PERMISSOES.modules.pacientes;

    return (
      <Container>
        <Row className='pb-reg'>
          <Col sm='0' style={{paddingLeft:5}}>
            <div className='mt-1 rec-form' >
              <form autoComplete='off' style={{width:this.state.tamanho - 84}}>
                <SugestionBoxComponent
                  renderSuggestionsContainer={this.renderSuggestionsContainer}
                  renderSuggestion={this.renderSuggestion}
                  renderInputComponent={this.renderInputComponent}
                  findSuggestions={this.findPatients}
                  selectedSuggestion={this.selectedPatient}
                  suggestionsData={suggestionsData}
                  inputProps={inputProps}
                />
              </form>
            </div>
          </Col>
          <Col sm='6'>
            <div ref={this.componenteRef}>
              <IfComponent
                conditional={
                  security.hasPermission(modulePermission.id, modulePermission.permissoes.editar) ||
                  security.hasPermission(modulePermission.id, modulePermission.permissoes.editar_restricao.id)
                }
              >
                <a
                  href='#!'
                  className='box-search-add'
                  onClick={() => {
                    showHideModalRegisterPatient(true);
                  }}
                >
                  <div className='box-search-add-caixa'>
                    <Icon
                      data-tip
                      data-for={'patient-add'}
                      className='box-search-add-contact icon icon-size-two'
                      image={plus}
                      alt='plus'
                    />
                  </div>
                </a>
                <ReactTooltip className='tooltip-griks' id='patient-add' type='light'>
                  Adicionar Novo Paciente
                </ReactTooltip>
              </IfComponent>
            </div>
            
            {/*<div className="box-search-add float-right">*/}
            {/*  <Link to={ '/patient/deleted' }>*/}
            {/*    <Icon*/}
            {/*      id="patient-deleted"*/}
            {/*      className="box-search-add-contact icon-primary icon-size-one"*/}
            {/*      image={ box }*/}
            {/*      alt="box"*/}
            {/*      title="Paciente Excluídos"*/}
            {/*    />*/}
            {/*    <UncontrolledTooltip placement="top" className="tooltip-white" target="patient-deleted">*/}
            {/*      Pacientes Excluídos*/}
            {/*    </UncontrolledTooltip>*/}
            {/*  </Link>*/}
            {/*</div>*/}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {};
};

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withSecurity);

export default enhanced(PatientSearch);
