import t from 'typy';
import { createSelector } from 'reselect';

const treatmentRegisterSelector = (state) => t(state, 'TreatmentReducer.registerModal').safeObject;

export const treatmentRegisterModalReducerToProps = createSelector(treatmentRegisterSelector, (registerModal = {}) => {
  const { formLoad = {} } = registerModal;

  const dentistOptions =
    formLoad.dentists &&
    formLoad.dentists.map((d) => ({
      value: d.id,
      label: d.nome,
      data: d
    }));

  const situationsOptions =
    formLoad.situations &&
    formLoad.situations.map((s) => ({
      value: s.id,
      label: s.name,
      data: s
    }));

  formLoad.dentistsOptions = dentistOptions;
  formLoad.situationsOptions = situationsOptions;

  return {
    ...registerModal
  };
});
