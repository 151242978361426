import React, { Component } from 'react';
import {
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table
} from 'reactstrap';
import { Form, Formik } from 'formik';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { debistToProps, formToProps } from '../selectors/FinancialFileDebitsModal.selector';
import { financialDebitsModal } from '../redux/FinancialFile.action';
import { formatDate, parseDate } from '../../../../utils/DateUtils';
import { formatReal } from '../../../../utils/Formatter';
import { t } from 'typy';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Portal } from 'react-overlays';
import warning from '../../../../assets/img/icons/warning.svg';
import ModalMessageConfirm from '../../../../components/ModalMessageConfirm.component';
import Icon from '../../../../components/Icon.component';
import TreatmentFinancialFileApi from '../../../../service/TreatmentFinancialFileApi';
import { showLoader, hideLoader } from '../../../../components/actions/Loader.actions';
import UserAPI from '../../../../service/User.service';
import MessagesComponent from '../../../../components/Messages.component';
import InputDecimalComponent from '../../../../components/InputDecimalComponent';

const CalendarPopoverContainer = ({ children }) => {
  const el = document.getElementById('containerModal');
  return <Portal container={el}>{children}</Portal>;
};

class TreatmentFinancialDebitsModal extends Component {
  state = {
    displayErrorMessage: false,
    message: {
      display: false,
      type: 'danger',
      text: ''
    },
    messagePWD: {
      display: false,
      type: 'danger',
      text: ''
    }
  };

  saveDebitsModal = async (values) => {
    const { totalDebits, closeModal, updateParent, treatment, financialFile, showLoader, hideLoader } = this.props;

    showLoader();
    let checkPassword = false;
    try {
      checkPassword = await UserAPI.checkPassword(values.senha);
    } catch (e) {
      checkPassword = false;
    }

    if (checkPassword) {
      const { totalCharge } = this.calculateTotals(values, totalDebits);

      if (totalCharge !== 0) {
        this.openErrorMessage();
      } else {
        try {
          await TreatmentFinancialFileApi.saveDebits({
            treatment,
            financialFile,
            debits: Object.keys(values.debits).map((id) => values.debits[id])
          });
          updateParent();
          closeModal();
        } catch (e) {
          console.log(e);
          this.setState({
            message: {
              display: true,
              type: 'danger',
              text: 'Erro ao salvar debitos entre em contato com o suporte'
            }
          });
        }
      }
    } else {
      console.log('Senha invalida');
      this.setState({
        messagePWD: {
          display: true,
          type: 'danger',
          text: 'Senha inválida, alteração não permitida'
        }
      });
    }

    hideLoader();
  };

  closeModal = () => {
    this.props.closeModal();
    this.setState({
      message: {
        display: false,
        type: 'danger',
        text: ''
      }
    });
  };

  calculateTotals = (values, totalDebits) => {
    const totalValue = Object.keys(values.debits)
      .map((id) => values.debits[id])
      .reduce((a, c) => (a += parseFloat(c.valor)), 0);

    const totalCharge = parseFloat(totalValue - totalDebits);

    return {
      totalValue,
      totalCharge
    };
  };

  closeErrorMessage = () => this.setState({ displayErrorMessage: false });
  openErrorMessage = () => this.setState({ displayErrorMessage: true });

  render() {
    const { modal, debits, totalDebits, formInit } = this.props;
    const { displayErrorMessage, message, messagePWD } = this.state;

    return [
      <Modal
        isOpen={modal}
        toggle={this.closeModal}
        className={this.props.className + ' table-appointment'}
        size={'default'}
        key={'ModalPrincipal'}
      >
        <ModalHeader
          className='title-primary pb-0'
          toggle={this.closeModal}
          close={
            <button className='close' onClick={this.closeModal}>
              &times;
            </button>
          }
        >
          <strong>Editar - Distribuição Detalhada dos Débitos</strong>
        </ModalHeader>
        <Formik initialValues={formInit} onSubmit={this.saveDebitsModal} validateOnChange={false}>
          {({ errors, values, handleChange }) => {
            const { totalValue, totalCharge } = this.calculateTotals(values, totalDebits);

            return (
              <Form>
                <ModalBody>
                  <div className='margin-top-10' id={'containerModal'}>
                    <MessagesComponent display={message.display} type={message.type} message={message.text} />
                    <Row className={'pt-0'}>
                      <Col sm='12'>
                        <Table responsive>
                          <thead>
                            <tr className={'row'}>
                              <th className={'col-sm-4'}>Descrição</th>
                              <th className={'col-sm-4'}>Vencimento</th>
                              <th className={'col-sm-4'}>Valor</th>
                            </tr>
                          </thead>
                          <tbody style={{ maxHeight: 300 }}>
                            {debits.map((d) => {
                              let linha = '';

                              if (t(d, 'status.id').safeString === 'L') {
                                linha = (
                                  <tr className={'row pb-0'} key={d.id}>
                                    <td className={'col-sm-4 border-top-0 py-2'}>
                                      {d.nome} ({d.parcela} / {d.total_parcela})
                                    </td>
                                    <td className={'col-sm-4 border-top-0 py-2'}>
                                      {formatDate(d.vencimento, 'DD/MM/YYYY')}
                                    </td>

                                    <td className={'col-sm-4 border-top-0 py-2'}>
                                      <strong>{formatReal(d.valor)}</strong>
                                    </td>
                                  </tr>
                                );
                              } else {
                                linha = (
                                  <tr className={'row pb-0'} key={d.id}>
                                    <td className={'col-sm-4 border-top-0 py-2'}>
                                      {d.nome} ({d.parcela} / {d.total_parcela})
                                    </td>
                                    <td className={'col-sm-4 border-top-0 py-2'}>
                                      <DatePicker
                                        invalid={errors.dataParcela ? true : false}
                                        selected={
                                          values.debits[d.id].dataParcela &&
                                          parseDate(values.debits[d.id].dataParcela, 'YYYY-MM-DD')
                                        }
                                        onChange={(value) => {
                                          if (value) {
                                            handleChange({
                                              target: {
                                                name: `debits.${d.id}.dataParcela`,
                                                value: formatDate(value, 'YYYY-MM-DD')
                                              }
                                            });
                                          }
                                        }}
                                        locale='pt-br-griks'
                                        useWeekdaysShort={true}
                                        className={[
                                          'react-datepicker-ignore-onclickoutside input-secondary',
                                          errors.dataOrcamento ? 'is-invalid form-control' : ''
                                        ]}
                                        placeholderText=''
                                        popperContainer={CalendarPopoverContainer}
                                      />
                                    </td>
                                    <td className={'col-sm-4 border-top-0 py-2'}>
                                      <InputGroup className={'reversed'}>
                                        <InputDecimalComponent
                                          className={'input-secondary'}
                                          name={`debits.${d.id}.valor`}
                                          value={values.debits[d.id].valor}
                                          handleChange={handleChange}
                                        />
                                        <InputGroupAddon addonType='prepend'>R$</InputGroupAddon>
                                      </InputGroup>
                                    </td>
                                  </tr>
                                );
                              }
                              return linha;
                            })}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                    <Row className={'pb-0'}>
                      <Col sm={12}>
                        <Table>
                          <tr>
                            <td className='text-right py-1 font-bold'>Valor Total da Conta</td>
                            <td className='text-left py-1 font-bold'>{formatReal(totalValue)}</td>
                          </tr>
                          <tr>
                            <td className='text-right border-top-0 py-1 font-bold'>Diferença/Resíduo</td>
                            <td
                              className={
                                (totalCharge < 0 ? 'text-alert' : 'text') + 'text-right border-top-0 py-1 font-bold'
                              }
                            >
                              {formatReal(totalCharge || 0)}
                            </td>
                          </tr>
                        </Table>
                      </Col>
                    </Row>
                    <span className={'text-center'}>
                      <MessagesComponent
                        display={messagePWD.display}
                        type={messagePWD.type}
                        message={messagePWD.text}
                      />
                    </span>
                    <Row>
                      <Col sm={{ size: 6, offset: 3 }}>
                        <div className='input-float'>
                          <input
                            className='input-secondary'
                            type='password'
                            id='senha'
                            name='senha'
                            placeholder='Senha'
                            onChange={handleChange}
                          />
                          <label htmlFor='senha text-sm font-bold'>Senha</label>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Row>
                    <Col className={'btn-list'} sm={{ size: 6, offset: 6 }}>
                      <button className='button-primary'>Salvar</button>
                      <button className='button-tertiary' type={'button'} onClick={this.closeModal}>
                        Fechar
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </Modal>,
      <Modal isOpen={displayErrorMessage} toggle={this.closeErrorMessage} key={'ModalMessageConfirm'}>
        <ModalHeader
          className='title-primary'
          toggle={this.closeErrorMessage}
          close={
            <button className='close' onClick={this.closeErrorMessage}>
              &times;
            </button>
          }
        >
          <span>
            <p></p>
          </span>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm='4'>
              <div className='modal-icon'>
                <Icon className='icon-alert icon-red' image={warning} alt='warning' />
              </div>
            </Col>
            <Col sm={8} style={{ display: 'flex', alignItems: 'center' }}>
              <h2 style={{ lineHeight: 1.5, letterSpacing: '1px' }} className={'text-default px-3'}>
                Para salvar as alterações, o valor da Diferença / Residuo precisa ser igual a<strong> R$ 0,00</strong>
              </h2>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col sm='12'>
              <button type={'button'} className='button-tertiary float-right' onClick={this.closeErrorMessage}>
                Fechar
              </button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    ];
  }
}

const mapStateToProps = (state) => {
  return {
    ...debistToProps(state),
    formInit: formToProps(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  closeModal: () => dispatch(financialDebitsModal({ modal: false }))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(TreatmentFinancialDebitsModal);
