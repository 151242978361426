import { call, put } from 'redux-saga/effects';
import ProceduresAPI from '../../../../service/ProceduresAPI';

import { standardProcedureModal } from '../redux/ScheduleSettings.actions';

export function* modalScheduleProcedure({ payload: { modal, initForm, dentist } }) {
  const categories = yield call(ProceduresAPI.getAllCategories);
  const data = {
    modal: true,
    initForm,
    dataForm: {
      categories: categories.data,
      dentist
    }
  };

  yield put(standardProcedureModal(data));
}
