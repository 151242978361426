import t from 'typy';
import { createSelector } from 'reselect';

const financialFileSelector = (state) => t(state, 'TreatmentFinancialFileReducer.page').safeObject;

export const propsAccountDataSelector = createSelector(financialFileSelector, (page = {}) => {
  return {
    treatment: page.treatment,
    financialFileId: t(page, 'financialFile.id').safeObject,
    data_contrato: t(page, 'financialFile.data_contrato').safeString,
    informacoes_conta: t(page, 'financialFile.informacoes_conta').safeObject
  };
});
