import { SETTINGS_LIBRARIES_REGISTER_MODAL } from './SettingsLibraries.actions';

const initialState = {};

const SettingsLibrariesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS_LIBRARIES_REGISTER_MODAL:
      return {
        ...state,
        register: {
          ...action.payload
        }
      };
    default:
      return state;
  }
};

export default SettingsLibrariesReducer;
