import React, { Component } from 'react';

import { Col, Row, UncontrolledTooltip } from 'reactstrap';
import { t } from 'typy';
import { cpfFormat, dateFormat, rgFormat } from '../../../utils/Formatter';
import TreatmentFinancialAccountInfosModal from './modals/TreatmentFinancialAccountInfos.modal';
import Icon from '../../../components/Icon.component';
import edit from '../../../assets/img/icons/edit.svg';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { propsAccountDataSelector } from './selectors/FinancialFileAcountInfoPage.selector';
import { financialFileAccountLoadModal } from './redux/FinancialFile.action';
import withSecurity from '../../../config/security/security';
import { PERMISSOES } from '../../../config/security/permissoes';
import { IfComponent } from '../../../components/if.component';

class TreatmentFinancialFileAcountInformationPage extends Component {
  render() {
    const {
      treatment,
      informacoes_conta,
      editAccountModal,
      financialFileId,
      data_contrato,
      updateParent,
      security
    } = this.props;

    const financialFilePermission = PERMISSOES.modules.tratamento_ficha_financeira;

    const hasBasePermition =
      security.hasPermission(financialFilePermission.id, financialFilePermission.permissoes.editar) ||
      security.hasPermission(financialFilePermission.id, financialFilePermission.permissoes.editar_restricao.id);

    return [
      <Row className={'w-100'} key={'page'}>
        <Col sm='12'>
          <div className='box-default'>
            <Row>
              <Col sm='12'>
                <h2 className='title-medium-plus text-uppercase text-secondary font-bold'>
                  Informação da Conta do Tratamento
                  <IfComponent conditional={hasBasePermition}>
                    <a
                      href={'#'}
                      onClick={() => {
                        editAccountModal({
                          modal: true,
                          treatment,
                          financialFile: {
                            id: financialFileId,
                            informacoes_conta
                          }
                        });
                      }}
                    >
                      <Icon className='icon icon-size-small margin-left-10' image={edit} alt='edit' />
                    </a>
                  </IfComponent>
                </h2>
              </Col>
            </Row>
            <Row>
              <Col sm='4'>
                <p className={'text-default text-sm text-secondary-d4 mb-2'}>
                  <strong className={'text-secondary-d4'}>Tipo do Tratamento/Fase</strong>{' '}
                  {t(informacoes_conta, 'tipo_tratamento.descricao').safeString}
                </p>
                <p className={'text-default text-sm text-secondary-d4'}>
                  <span className={'voucherOption'}>
                    <strong className={'text-secondary-d4'}>Comprovante</strong>&nbsp;&nbsp;
                    <span
                      id={'comprovante'}
                      className={'voucherType'}
                      style={{
                        borderColor: t(informacoes_conta, 'comprovante.color').safeString,
                        color: t(informacoes_conta, 'comprovante.color').safeString
                      }}
                    >
                      {t(informacoes_conta, 'comprovante.sigla').safeString}
                    </span>
                  </span>
                </p>
                <UncontrolledTooltip
                  className='tooltip-white text-xsm font-weight-light'
                  target={'comprovante'}
                  placement='right'
                >
                  {t(informacoes_conta, 'comprovante.descricao').safeString}
                </UncontrolledTooltip>
              </Col>
              <Col sm='4'>
                <p className={'text-default text-sm text-secondary-d4 mb-2'}>
                  <strong className={'text-secondary-d4'}>Modalidade</strong>
                  {t(informacoes_conta, 'tipo_modalidade.descricao').safeString}
                </p>
                <p className={'text-default text-sm text-secondary-d4'}>
                  <strong className={'text-secondary-d4'}>CPF Paciente</strong>
                  {cpfFormat(t(informacoes_conta, 'cpf').safeString)}
                </p>
              </Col>
              <Col sm='4'>
                <p className={'text-default text-sm text-secondary-d4 mb-2'}>
                  <strong className={'text-secondary-d4'}>Data do Contrato</strong>{' '}
                  {dateFormat(data_contrato, 'DD/MM/YYYY')}
                </p>
                <p className={'text-default text-sm text-secondary-d4'}>
                  <strong className={'text-secondary-d4'}>RG Paciente</strong>{' '}
                  {rgFormat(t(informacoes_conta, 'rg').safeString)}
                </p>
              </Col>
            </Row>

            <hr></hr>

            <Row>
              <Col sm='4'>
                <p className={'text-default text-sm text-secondary-d4'}>
                  <strong className={'text-secondary-d4'}>Responsável</strong>{' '}
                  {t(informacoes_conta, 'nome_responsavel').safeString} (
                  {t(informacoes_conta, 'tipo_responsavel.descricao').safeString})
                </p>
              </Col>
              <Col sm='4'>
                <p className={'text-default text-sm text-secondary-d4'}>
                  <strong className={'text-secondary-d4'}>CPF Responsável</strong>{' '}
                  {t(informacoes_conta, 'tipo_responsavel.id').safeNumber === 1
                    ? cpfFormat(t(informacoes_conta, 'cpf').safeString)
                    : cpfFormat(t(informacoes_conta, 'cpf_responsavel').safeString)}
                </p>
              </Col>
              <Col sm='4'>
                <p className={'text-default text-sm text-secondary-d4'}>
                  <strong className={'text-secondary-d4'}>RG Responsável</strong>{' '}
                  {t(informacoes_conta, 'tipo_responsavel.id').safeNumber === 1
                    ? rgFormat(t(informacoes_conta, 'rg').safeString)
                    : rgFormat(t(informacoes_conta, 'rg_responsavel').safeString)}
                </p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>,
      <TreatmentFinancialAccountInfosModal key={'TreatmentFinancialAccountInfosModal'} updateParent={updateParent} />
    ];
  }
}

const mapStateToProps = (state) => {
  return {
    ...propsAccountDataSelector(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  editAccountModal: (data) => dispatch(financialFileAccountLoadModal(data))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withSecurity);

export default enhanced(TreatmentFinancialFileAcountInformationPage);
