import React, { Component } from 'react';

import { Container, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import { PatientRegistrationFormContext } from '../PatientRegistrationForm.page';

import { withLoader } from '../../../components/Loader.component';

import ErrorMessageComponent from '../../../components/ErrorMessage.component';
import _ from 'lodash';

class NotesModal extends Component {
  state = {
    error: {
      display: false,
      type: 'danger',
      message: ''
    }
  };

  changeText = _.debounce((searchTerm) => {
    this.props.changeTextUpdate(searchTerm);
  }, 200);

  render() {
    const { notesModal, note, errorNotes, closeModal, changeDate } = this.props;
    const { date, text } = note;

    return (
      <div className='content-box-without-color'>
        <Container>
          <Modal isOpen={notesModal} toggle={closeModal} className='modal-default'>
            <ModalHeader
              className='title-primary'
              toggle={closeModal}
              close={
                <button className='close' onClick={closeModal}>
                  &times;
                </button>
              }
            >
              <strong>Adicionar Anotação</strong>
            </ModalHeader>
            <ModalBody>
              <ErrorMessageComponent
                id={'patientnote'}
                where={'patientnote'}
                display={errorNotes.display}
                type={errorNotes.type}
                message={errorNotes.message}
              />
              <Row>
                <Col sm='12'>
                  <label>
                    Data<span>*</span>
                  </label>
                </Col>
                <Col sm='12'>
                  <DatePicker
                    selected={date}
                    onChange={changeDate}
                    locale='pt-br'
                    useWeekdaysShort={true}
                    className='input-secondary'
                    placeholderText=''
                  />
                </Col>
              </Row>
              <div className='margin-top-10'>
                <Row>
                  <Col sm='12'>
                    <label>
                      Anotação<span>*</span>
                    </label>
                    <textarea
                      className='form-control'
                      rows='2'
                      cols='50'
                      onChange={(e) => this.changeText(e.target.value)}
                      defaultValue={text}
                    />
                  </Col>
                </Row>
              </div>
            </ModalBody>
            <ModalFooter>
              <Row>
                <Col sm={{ size: 3, offset: 6 }}>
                  <button className='button-primary' onClick={this.props.saveNote}>
                    Salvar
                  </button>
                </Col>
                <Col sm='3'>
                  <button className='button-tertiary' onClick={closeModal}>
                    Cancelar
                  </button>
                </Col>
              </Row>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    );
  }
}

export default withLoader(NotesModal);
