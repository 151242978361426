import t from 'typy';
import { createSelector } from 'reselect';
import DomainsAPI from '../../../../service/DomainsAPI';
import moment from 'moment';
import Constants from '../../../../utils/Constants';

const financialFileLaunchOtherPaymentSelector = (state) =>
  t(state, 'TreatmentFinancialFileReducer.otherLaunchPayment').safeObject;

export const launchOtherPaymentToProps = createSelector(
  financialFileLaunchOtherPaymentSelector,
  (otherLaunchPayment = {}) => {
    const { modal, financialFile, paymentTypes = [], receipt = {}, entryTypes = [] } = otherLaunchPayment;

    const paymentsTypeOptions = paymentTypes.map((p) => ({ label: p.nome, value: p.id, data: p }));

    paymentsTypeOptions.unshift({ label: '', value: null, data: {} });

    return {
      modal,
      financialFile,
      paymentTypesOptions: paymentsTypeOptions,
      entryTypesOptions: entryTypes
        .filter((e) => ![Constants.TIPO_LANCAMENTO_PAGAMENTO].includes(e.id))
        .map((p) => {
          let color = '';
          if (p.id === 2) {
            color = '#dc3545';
          }
          return {
            label: p.nome,
            value: p.id,
            data: p,
            color: color
          };
        }),
      receipt: receipt.input
    };
  }
);

export const launchOtherPaymentFormInit = createSelector(
  financialFileLaunchOtherPaymentSelector,
  (otherLaunchPayment = {}) => {
    const { financialFile, entry = {} } = otherLaunchPayment;

    const formaPagamento = {
      value: t(entry, 'forma_pagamento.id').safeObject,
      label: t(entry, 'forma_pagamento.nome').safeString,
      data: entry.forma_pagamento
    };

    const tipo_lancamento = {
      value: t(entry, 'tipo_lancamento.id').safeNumber,
      label: t(entry, 'tipo_lancamento.nome').safeString,
      data: entry.tipo_lancamento
    };

    let motivo = {};

    if (t(entry, 'reason').isDefined) {
      const reason = entry.reason;
      motivo = { label: reason.motivo, value: reason.id, data: reason };
    } else {
      motivo = { label: entry.descricao };
    }

    return {
      financialFile,
      id: entry.id,
      tipo_lancamento,
      anotacao: entry.anotacao,
      referencia: entry.referencia,
      numero_lancamento: entry.numero_lancamento,
      forma_pagamento: formaPagamento,
      valor: entry.valor_lancamento,
      motivo,
      data_vencimento: moment()
    };
  }
);
