import React, { Component } from 'react';

import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import DatePicker from 'react-datepicker';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { hideLoader, showLoader } from '../../../../components/actions/Loader.actions';
import { treatmentEvaluationCreateModal } from '../redux/TreatmentEvaluation.action';
import { Formik } from 'formik';

import * as Yup from 'yup';
import { dateFormat } from '../../../../utils/Formatter';
import TreatmentEvaluationApi from '../../../../service/TreatmentEvaluationApi';
import moment from 'moment';
import MessagesComponent from '../../../../components/Messages.component';

class TreatmentEvaluationCreateModal extends Component {
  state = {
    error: {
      display: false,
      type: 'danger',
      message: ''
    }
  };

  validationSchema = Yup.object().shape({
    dataAvaliacao: Yup.date().required('Data da Avaliação Obrigatório')
  });

  evaluationsave = async (values) => {
    const { showLoader, hideLoader, formLoad, closeModal, updateParent } = this.props;
    const dataAvaliacao = dateFormat(values.dataAvaliacao, 'YYYY-MM-DD');
    try {
      showLoader();
      await TreatmentEvaluationApi.createEvaluationTreatment(formLoad.treatment.id, dataAvaliacao);
      closeModal();
      updateParent();
    } catch (e) {
      console.log(e);
      this.setState({
        error: {
          display: true,
          type: 'danger',
          message: 'Erro ao criar avaliação para o paciente'
        }
      });
    }

    hideLoader();
  };

  render() {
    const { error } = this.state;
    const { modal, closeModal } = this.props;

    return (
      <Modal isOpen={modal} toggle={closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={closeModal}
          close={
            <button className='close' onClick={closeModal}>
              &times;
            </button>
          }
        >
          <strong>Criar Avaliação</strong>
        </ModalHeader>
        <Formik
          onSubmit={this.evaluationsave}
          initialValues={{ dataAvaliacao: moment() }}
          validationSchema={this.validationSchema}
          validateOnChange={false}
        >
          {({ errors, handleSubmit, handleBlur, handleChange, values }) => {
            return (
              <form onSubmit={handleSubmit} autoComplete='off'>
                <ModalBody>
                  <MessagesComponent display={error.display} type={error.type} message={error.message} />
                  <div className='margin-top-10'>
                    <Row>
                      <Col sm={6}>
                        <label className='float-right pt-2'>
                          Data da Avaliação <span> * </span>
                        </label>
                      </Col>
                      <Col className='pl-0' sm={6}>
                        <DatePicker
                          invalid={errors.dataAvaliacao ? true : false}
                          name={'dataAvaliacao'}
                          selected={values.dataAvaliacao}
                          onChange={(value) => {
                            handleChange({
                              target: {
                                name: 'dataAvaliacao',
                                value
                              }
                            });
                          }}
                          useWeekdaysShort
                          locale='pt-br-griks'
                          className={[
                            'react-datepicker-ignore-onclickoutside input-secondary',
                            errors.dataAvaliacao ? 'is-invalid form-control' : ''
                          ]}
                          placeholderText=''
                        />
                        {errors.dataAvaliacao && (
                          <div style={{ fontSize: 10, color: 'red' }}>{errors.dataAvaliacao}</div>
                        )}
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Row>
                    <Col className={'btn-list'} sm={{ size: 6, offset: 6 }}>
                      <button className='button-primary' type={'submit'}>
                        Salvar
                      </button>
                      <button className='button-tertiary' type={'button'} onClick={closeModal}>
                        Cancelar
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = ({ TreatmentEvaluationReducer }) => {
  const { createModal } = TreatmentEvaluationReducer;
  return {
    ...createModal
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(treatmentEvaluationCreateModal({ modal: false })),
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader())
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(TreatmentEvaluationCreateModal);
