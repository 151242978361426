import { createSelector } from 'reselect';
import t from 'typy';

const diagnosticModalSelector = (state) => t(state, 'TreatmentClinicalRecordReducer.diagnosticModal').safeObject;
const angleFormLoadSelector = (state) => t(state, 'TreatmentClinicalRecordReducer.diagnosticModal.formLoad').safeObject;

export const propsModalSelector = createSelector(diagnosticModalSelector, (diagnosticModal = {}) => {
  const { modal, diagnostic, treatment, record } = diagnosticModal;

  return {
    modal,
    diagnostic,
    treatment,
    record
  };
});

export const formLoadSelector = createSelector(angleFormLoadSelector, (formLoad = {}) => {
  const { data = {} } = formLoad;
  const { angles = [], teeth = [], skeletalClass = [], skeletalPatterns = [] } = data;

  const defaultOption = { value: null, label: '' };
  const toothOptions = teeth.map((t) => ({ value: t.id, label: t.descricao, data: t }));
  const anglesOptions = angles.map((t) => ({ value: t.id, label: t.descricao, data: t }));
  const skeletalClassOptions = skeletalClass.map((t) => ({ value: t.id, label: t.descricao, data: t }));
  const skeletalPatternsOptions = skeletalPatterns.map((t) => ({ value: t.id, label: t.descricao, data: t }));

  toothOptions.unshift(defaultOption);
  anglesOptions.unshift(defaultOption);
  skeletalClassOptions.unshift(defaultOption);
  skeletalPatternsOptions.unshift(defaultOption);

  return {
    toothOptions,
    anglesOptions,
    skeletalClassOptions,
    skeletalPatternsOptions
  };
});

export const formInitSelector = createSelector(diagnosticModalSelector, (diagnosticModal = {}) => {
  const { diagnostic = {} } = diagnosticModal;

  const angleRating = diagnostic.classificacaoAngle || {};
  const angle = t(angleRating, 'classificacao_angle').safeObject;
  const thoth_right_6 = t(angleRating, 'dente_direito6').safeObject;
  const thoth_right_3 = t(angleRating, 'dente_direito3').safeObject;
  const thoth_left_3 = t(angleRating, 'dente_esquerdo3').safeObject;
  const thoth_left_6 = t(angleRating, 'dente_esquerdo6').safeObject;
  const classificacao_esqueletica = t(angleRating, 'classe_esqueletica').safeObject;
  const padrao_esqueletico = t(angleRating, 'padrao_esqueletico').safeObject;

  return {
    id: t(angleRating, 'id').safeObject,
    angle: angle && { value: angle.id, label: angle.descricao, data: angle },
    thoth_right_6: thoth_right_6 && { value: thoth_right_6.id, label: thoth_right_6.descricao, data: thoth_right_6 },
    thoth_right_3: thoth_right_3 && { value: thoth_right_3.id, label: thoth_right_3.descricao, data: thoth_right_3 },
    thoth_left_3: thoth_left_3 && { value: thoth_left_3.id, label: thoth_left_3.descricao, data: thoth_left_3 },
    thoth_left_6: thoth_left_6 && { value: thoth_left_6.id, label: thoth_left_6.descricao, data: thoth_left_6 },
    classificacao_esqueletica: classificacao_esqueletica && {
      value: classificacao_esqueletica.id,
      label: classificacao_esqueletica.descricao,
      data: classificacao_esqueletica
    },
    padrao_esqueletico: padrao_esqueletico && {
      value: padrao_esqueletico.id,
      label: padrao_esqueletico.descricao,
      data: padrao_esqueletico
    }
  };
});
