import t from 'typy';
import { createSelector } from 'reselect';
import { formatDate } from '../../../../utils/DateUtils';

const financialFileSelector = (state) => t(state, 'TreatmentFinancialFileReducer.debitsModal').safeObject;

export const debistToProps = createSelector(
  financialFileSelector,
  ({ modal, treatment, financialFile, debits = [] } = {}) => {
    return {
      modal,
      treatment,
      financialFile,
      debits,
      totalDebits: debits.reduce((a, c) => (a += c.valor), 0)
    };
  }
);

export const formToProps = createSelector(
  financialFileSelector,
  ({ modal, treatment, financialFile, debits = [] } = {}) => {
    let debitsObject = {};
    debits.forEach((d) => {
      debitsObject = {
        ...debitsObject,
        [d.id]: {
          id: d.id,
          dataParcela: formatDate(d.vencimento, 'YYYY-MM-DD'),
          valor: d.valor
        }
      };
    });

    return {
      treatment,
      financialFile,
      debits: debitsObject
    };
  }
);
