const defaultProps = {
  pathNotRenderTemplate: ['/cash/report', '/schedule/confirmations/report']
};

const dev = {
  ...defaultProps,
  api: {
    baseURL: 'http://localhost:8080'
  },
  imaginary: {
    baseUrl: 'http://localhost:9000'
  }
};

const hom = {
  ...defaultProps,
  api: {
    baseURL: 'http://backendhom.griks.com.br:8080',
    baseSecondaryURL: 'http://34.198.165.253:8080'
  },
  imaginary: {
    baseUrl: 'http://images.griks.com.br'
  }
};

const prod = {
  ...defaultProps,
  api: {
    baseURL: 'https://backend2.griks.com.br/',
    baseSecondaryURL: 'https://secondarybackend.griks.com.br'
  },
  imaginary: {
    baseUrl: 'https://images.griks.com.br'
  }
};

let config = prod;

// if (process.env.REACT_APP_STAGE === 'production') {
//   config = prod;
// } else if (process.env.REACT_APP_STAGE === 'homolog') {
//   config = hom;
// } else {
//   config = dev;
// }

export default {
  ...config
};
