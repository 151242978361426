import { call, all, put } from 'redux-saga/effects';
import { budgetModalInformations } from '../redux/TreatmentBudget.actions';
import DomainsAPI from '../../../../service/DomainsAPI';
import { showLoader, hideLoader } from '../../../../components/actions/Loader.actions';

export function* modalInformation({ payload: { modal, formLoad = {} } }) {
  yield put(showLoader());
  const domains = yield all([call(DomainsAPI.findAllBudgetTypeResponsability), call(DomainsAPI.findAllVoucherType)]);

  formLoad.typeResponsible = domains[0];
  formLoad.voucherType = domains[1];

  yield put(budgetModalInformations({ modal, formLoad }));
  yield put(hideLoader());
}
