import { call, put } from 'redux-saga/effects';
import { sendToAppointmentAndRedial } from '../redux/Schedule.actions';
import { hideLoader, showLoader } from '../../../components/actions/Loader.actions';
import ProceduresAPI from '../../../service/ProceduresAPI';
import { t } from 'typy';

export function* sendToAppointmentAndRedialProcess({ payload: { modal, schedule } }) {
  try {
    yield put(showLoader());

    const groups = yield call(ProceduresAPI.getAllCategories);

    let procedures = [];

    if (t(schedule, 'procedimento.categoria.id').isDefined) {
      procedures = yield call(ProceduresAPI.getAllProcedures, schedule.procedimento.categoria.id, true);
    }

    yield put(
      sendToAppointmentAndRedial({
        modal,
        schedule,
        groups: groups.data,
        procedures: procedures.data
      })
    );

    yield put(hideLoader());
  } catch (e) {
    console.log(e);
  }
}
