import React, { Component } from 'react';

import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { treatmentChangeSituationModal } from '../redux/Treatment.action';
import { Formik } from 'formik';
import TreatmentAPI from '../../../service/TreatmentAPI';
import { hideLoader, showLoader } from '../../../components/actions/Loader.actions';
import MessagesComponent from '../../../components/Messages.component';
import DomainsAPI from '../../../service/DomainsAPI';

class TreatmentStatusChangeModal extends Component {
  state = {
    situations: [],
    error: {
      display: false,
      type: 'danger',
      message: ''
    }
  };

  componentDidMount = async () => {
    try {
      const situations = await DomainsAPI.findAllTreatmentStatus();
      this.setState({ situations });
    } catch (e) {
      this.setState({
        error: {
          display: true,
          type: 'danger',
          message: 'Error a buscar status do tratamento'
        }
      });
      console.log(e);
    }
  };

  changeSituation = async (values) => {
    const { showLoader, hideLoader, refreshParent, closeModal } = this.props;

    try {
      showLoader();
      await TreatmentAPI.treatmentChangeSituation(values.id, values.situation);
      refreshParent();
      closeModal();
    } catch (e) {
      console.log(e);
      this.setState({
        error: {
          display: true,
          type: 'danger',
          message: 'Error a alterar a situação do tratamento'
        }
      });
    }
    hideLoader();
  };

  render() {
    const { modal, treatment = {}, closeModal } = this.props;
    const { error, situations } = this.state;

    return (
      <Modal isOpen={modal} toggle={closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={closeModal}
          close={
            <button className='close' onClick={closeModal}>
              &times;
            </button>
          }
        >
          <strong>Editar</strong> Situação do Tratamento
        </ModalHeader>
        <Formik
          initialValues={{ id: treatment.id, situation: treatment.situacao_id }}
          validateOnChange={false}
          onSubmit={this.changeSituation}
        >
          {({ errors, handleSubmit, handleChange, values }) => (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <MessagesComponent display={error.display} type={error.type} message={error.message} />
                <div className='margin-top-10'>
                  <Row className='margin-top-10'>
                    <Col sm='12'>
                      <label>
                        Situação do Tratamento<span>*</span>
                      </label>
                      <select id='soflow' name={'situation'} value={values.situation} onChange={handleChange}>
                        {situations.map((s) => (
                          <option value={s.id}>{s.descricao}</option>
                        ))}
                      </select>
                    </Col>
                  </Row>
                </div>
              </ModalBody>
              <ModalFooter>
                <Row>
                  <Col sm={12} className='btn-list text-right'>
                    <button className='button-primary'>Salvar</button>
                    <button className='button-tertiary' type={'button'} onClick={closeModal}>
                      Cancelar
                    </button>
                  </Col>
                </Row>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = ({ TreatmentReducer }) => {
  const { changeSituationModal } = TreatmentReducer;
  return {
    ...changeSituationModal
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(treatmentChangeSituationModal({ modal: false })),
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader())
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(TreatmentStatusChangeModal);
