import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Col, Input, Label, ModalBody, ModalFooter, Row, UncontrolledTooltip } from 'reactstrap';
import ErrorField from '../../../components/ErrorField.component';
import MaskedInput from 'react-text-mask';
import DomainsAPI from '../../../service/DomainsAPI';
import ClinicAPI from '../../../service/ClinicAPI';
import SimpleSuggestionBoxComponent from '../../../components/SimpleSuggestionBox.component';
import { IfComponent } from '../../../components/if.component';
import { IoLogoWhatsapp, IoIosHelpCircleOutline } from 'react-icons/io';
import { t } from 'typy';
import ScheduleAppointmentModal from '../../schedule/modal/ScheduleAppointment.modal';

export const PatientNewForm = ({ closeModal, formSubmitSave }) => {
  const [indicationTypesOfShowWhoIndication, setIndicationTypesOfShowWhoIndication] = useState([1, 2, 3]);
  const [indicationTypeId, setIndicationTypeId] = useState('');
  const [indicationsTypes, setIndicationsTypes] = useState(['']);
  const [indicatorSuggestions, setIndicatorSuggestions] = useState(['']);
  const [ddd, setDdd] = useState('');
  const [formAddValues, setFormAddValues] = useState({ phone1: ddd, phone2: ddd, whoIndicated: {} });

  const signupSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Mínimo de 3 caracteres')
      .max(254, 'Máximo de 254 caracteres')
      .required('Nome obrigatório')
  });

  useEffect(() => {
    const clinicPromisse = ClinicAPI.findByUser().then(({ data }) => {
      setDdd(data.ddd);
      // console.log('ddd', ddd);
      setFormAddValues({ phone1: ddd, phone2: ddd });
    });

    const domainPromisse = DomainsAPI.findIndicationTypes().then(({ data }) => {
      setIndicationsTypes(data);
    });

    Promise.all([clinicPromisse, domainPromisse]).then(() => {
      // this.props.hideLoader();
    });
  }, [ddd]);

  const selectingIndicationTypeId = (id) => {
    // console.log('id', id);
    setIndicationTypeId(id);
    // console.log('SetId', indicationTypeId);
  };

  const clearWhoIndicatior = () => {
    setIndicatorSuggestions([
      {
        id: 0,
        name: 'Nenhuma indicação encontrada'
      }
    ]);
  };

  const findWhoIndicators = (value) => {
    DomainsAPI.findWhoIndication(value, indicationTypeId).then(({ data }) => {
      // this.handleChange({
      //   target: {
      //     name: 'whoIndicated',
      //     value: ''
      //   }
      // });
      if (data.length === 0) {
        setIndicatorSuggestions([
          {
            id: 0,
            name: 'Nenhuma indicação encontrada'
          }
        ]);
      } else {
        setIndicatorSuggestions(
          data.map((i) => {
            return {
              id: i.id,
              name: i.nome
            };
          })
        );
      }
    });
  };

  return (
    <ModalBody>
      <Formik
        validationSchema={signupSchema}
        validateOnChange={false}
        enableReinitialize={true}
        initialValues={formAddValues}
        // onSubmit={(values) => {
        //   // same shape as initial values
        //   console.log(values);

        // }}
        // onSubmit={(values, actions) => {
        //   setTimeout(() => {
        //     // alert(JSON.stringify(values, null, 2));
        //     actions.setSubmitting(false);
        //   }, 1000);

        //   console.log(values);
        // }}
        onSubmit={formSubmitSave}
      >
        {({ errors, values, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col sm='12' className='title-modal'>
                Informações Pessoais
              </Col>
            </Row>

            <Row className='margin-top-10'>
              <Col sm='8'>
                <Label>
                  Nome<span>*</span>
                </Label>
                <Input
                  name='name'
                  className='input-secondary'
                  onChange={handleChange}
                  invalid={errors.name ? true : false}
                  value={values.name}
                />
                <ErrorField errors={errors} fieldName={'name'} />
              </Col>
              <Col sm='4'>
                <Label>Data de Nascimento</Label>
                <MaskedInput
                  id='birthDate'
                  name='birthDate'
                  mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                  value={values.birthDate}
                  onChange={handleChange}
                  render={(ref, props) => (
                    <Input
                      className='input-secondary'
                      innerRef={ref}
                      invalid={errors.birthDate ? true : false}
                      {...props}
                    />
                  )}
                />
                <ErrorField errors={errors} fieldName={'birthDate'} />
              </Col>
            </Row>
            <Row className='margin-top-10'>
              <Col sm='6'>
                <label>Tipo Indicação</label>
                <select
                  id='indicationType'
                  className='form-control'
                  name='indicationType'
                  onChange={(e) => {
                    selectingIndicationTypeId(e.target.value);
                    handleChange(e);
                  }}
                  value={values.indicationsTypes}
                >
                  <option />
                  {indicationsTypes.map((i) => (
                    <option value={i.id} key={i.id}>
                      {i.nome}
                    </option>
                  ))}
                </select>
              </Col>

              {indicationTypesOfShowWhoIndication.includes(parseInt(indicationTypeId)) ? (
                <Col sm='6'>
                  <label>Quem Indicou</label>
                  <IoIosHelpCircleOutline
                    id='tooltipWhoIndicated'
                    className='icon icon-info-who-indicated ml-1'
                    alt='Ajuda'
                  />
                  <UncontrolledTooltip placement='top' target='tooltipWhoIndicated' className='tooltip-white'>
                    Caso o paciente não se lembre do nome de quem indicou, deixe o campo em branco.
                  </UncontrolledTooltip>
                  <SimpleSuggestionBoxComponent
                    name='whoIndicated'
                    className='input-secondary'
                    clearSuggestions={clearWhoIndicatior}
                    selectSuggestion={(value) => {
                      handleChange({
                        target: {
                          name: 'whoIndicated',
                          value
                        }
                      });

                      return value.name;
                    }}
                    suggestions={indicatorSuggestions}
                    findSuggestions={findWhoIndicators}
                    value={values.whoIndicated || {}}
                    defaultValue={{
                      id: t(values, 'whoIndicated.id').safeObject,
                      name: t(values, 'whoIndicated.name').safeObject
                    }}
                  />
                </Col>
              ) : null}

              {4 === parseInt(indicationTypeId) ? (
                <Col sm='6'>
                  <label>Quem Indicou</label>
                  <Input
                    className='input-secondary'
                    name='indicationName'
                    value={values.indicationName}
                    onChange={handleChange}
                  />
                </Col>
              ) : null}

              {6 === parseInt(indicationTypeId) ? (
                <Col sm='6'>
                  <label>Quem Indicou</label>
                  <select
                    id='socialMedia'
                    className='form-control'
                    name='socialMedia'
                    value={values.socialMedia}
                    onChange={handleChange}
                  >
                    <option value={'Facebook'}>Facebook</option>
                    <option value={'Instagram'}>Instagram</option>
                  </select>
                </Col>
              ) : null}
            </Row>

            <Row className='mt-3'>
              <Col sm='12' className='title-modal'>
                Telefones e Contatos
              </Col>
            </Row>

            <Row className='mt-1'>
              <Col sm='4'>
                <label>Telefone 1</label>
                <MaskedInput
                  id='phone1'
                  name='phone1'
                  mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/]}
                  onChange={handleChange}
                  guide={false}
                  value={values.phone1}
                  render={(ref, props) => <Input className='input-secondary' innerRef={ref} {...props} />}
                />
              </Col>
              <Col sm='7'>
                <label>Descrição</label>
                <input
                  className='input-secondary'
                  type='text'
                  name='phone1Name'
                  onChange={handleChange}
                  value={values.phone1Name}
                />
              </Col>
              <Col sm='1'>
                <label>Tipo</label>
                <a
                  href={'#'}
                  onClick={() => {
                    handleChange({
                      target: {
                        name: 'phone1Type',
                        value:
                          t(values.phone1Type).isUndefined || t(values.phone1Type).safeString === 'telefone'
                            ? 'whatsapp'
                            : 'telefone'
                      }
                    });
                  }}
                >
                  <IfComponent conditional={!values.phone1Type || values.phone1Type === 'telefone'}>
                    <IoLogoWhatsapp className='icon' alt='whats' size={'24'} />
                  </IfComponent>
                  <IfComponent conditional={values.phone1Type === 'whatsapp'}>
                    <IoLogoWhatsapp className='icon icon-whats ' alt='whats' size={'24'} />
                  </IfComponent>
                </a>
              </Col>
            </Row>

            <Row className='mt-1'>
              <Col sm='4'>
                <label>Telefone 2</label>
                <MaskedInput
                  id='phone2'
                  name='phone2'
                  mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/]}
                  onChange={handleChange}
                  guide={false}
                  value={values.phone2}
                  render={(ref, props) => <Input className='input-secondary' innerRef={ref} {...props} />}
                />
              </Col>
              <Col sm='7'>
                <label>Descrição</label>
                <input
                  className='input-secondary'
                  type='text'
                  name='phone2Name'
                  onChange={handleChange}
                  value={values.phone2Name}
                />
              </Col>
              <Col sm='1'>
                <label>Tipo</label>
                <a
                  href={'#'}
                  onClick={() => {
                    handleChange({
                      target: {
                        name: 'phone2Type',
                        value:
                          t(values.phone2Type).isUndefined || t(values.phone2Type).safeString === 'telefone'
                            ? 'whatsapp'
                            : 'telefone'
                      }
                    });
                  }}
                >
                  <IfComponent conditional={!values.phone2Type || values.phone2Type === 'telefone'}>
                    <IoLogoWhatsapp className='icon' alt='whats' size={'24'} />
                  </IfComponent>
                  <IfComponent conditional={values.phone2Type === 'whatsapp'}>
                    <IoLogoWhatsapp className='icon icon-whats whats-on' alt='whats' size={'24'} />
                  </IfComponent>
                </a>
              </Col>
            </Row>

            <Row className='margin-top-10'>
              <Col sm='12'>
                <label>E-mail</label>
                <input
                  className='input-secondary'
                  type='text'
                  name='email'
                  value={values.email}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <ModalFooter>
              <Row>
                <Col sm='12'>
                  <button type='submit' className='button-primary float-right'>
                    Salvar
                  </button>
                  <button className='button-tertiary float-right mr-3' type='button' onClick={closeModal}>
                    Cancelar
                  </button>
                </Col>
              </Row>
            </ModalFooter>
          </Form>
        )}
      </Formik>
      <ScheduleAppointmentModal />
    </ModalBody>
  );
};
