import t from 'typy';
import { createSelector } from 'reselect';

const treatmentPlanContentionModalSelector = (state) =>
  t(state, 'TreatmentClinicalRecordReducer.treatmentPlanContentionModal').safeObject;

export const propsModalSelector = createSelector(
  treatmentPlanContentionModalSelector,
  (treatmentPlanGoalModal = {}) => {
    const { modal, treatment, record, plan = {} } = treatmentPlanGoalModal;

    return {
      modal,
      treatment,
      record,
      plan: plan.id
    };
  }
);

export const formInitSelector = createSelector(treatmentPlanContentionModalSelector, (treatmentPlanGoalModal = {}) => {
  const { plan = {} } = treatmentPlanGoalModal;

  const formInit = {
    id: t(plan, 'contencao.id').safeObject,
    superior: t(plan, 'contencao.contencao_superior').safeString,
    inferior: t(plan, 'contencao.contencao_inferior').safeString,
    superior_complementar: t(plan, 'contencao.contencao_superior_complementar').safeString
  };

  return formInit;
});
