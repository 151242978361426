import moment from 'moment';
import React, { Component } from 'react';
import { FiPlusCircle } from 'react-icons/fi';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ReactTooltip from 'react-tooltip';
import { Col, Container, Row, Table, UncontrolledTooltip } from 'reactstrap';
import { compose } from 'recompose';
import { t } from 'typy';
import close from '../../assets/img/icons/close.svg';
import notes from '../../assets/img/icons/notes.svg';
import remarcar from '../../assets/img/icons/remarcar.svg';
import success from '../../assets/img/icons/success.svg';
import warning from '../../assets/img/icons/warning.svg';
import noavatar from '../../assets/img/noavatar.png';
import { hideLoader, showLoader } from '../../components/actions/Loader.actions';
import Icon from '../../components/Icon.component';
import { IfComponent } from '../../components/if.component';
import ModalMessageConfirm from '../../components/ModalMessageConfirm.component';
import ToastCustomMessageComponent from '../../components/ToastCustomMessage.component';
import { PERMISSOES } from '../../config/security/permissoes';
import withSecurity from '../../config/security/security';
import ScheduleAPI from '../../service/ScheduleAPI';
import { phoneFormat } from '../../utils/Formatter';
import ToastUtils from '../../utils/ToastUtils';
import { setHeader } from '../template/redux/Content.actions';
import AddListScheduleRedialModal from './modal/AddListScheduleRedial.modal';
import { scheduleAndRedialAddSaga, scheduleAndRedialSagaPage, scheduleFromPatient } from './redux/Schedule.actions';
import { listScheduleAndRedialPageSelectorToProps } from './selectors/ListScheduleAndRedialPageSelector';
import { showToast } from './TostPatient.component';

class ListScheduleAndRedialPage extends Component {

  divInfiniteScrollRef;

  state = {
    confirmations: [],
    date: moment(),
    confirmDelete: {
      display: false,
      data: {}
    },
    order: null,
    currentPage: 0
  };

  constructor() {
    super();
    this.divInfiniteScrollRef = React.createRef();
  }

  componentDidMount() {
    const intersectionObserver = new IntersectionObserver((entries) => {
      const ratio = entries[ 0 ].intersectionRatio;
      if (ratio === 1) {
        this.props.showLoader();
        setTimeout(() => {
          const currentPage = (this.state.currentPage) + 1;
          this.setState((state) => {
            return {
              ...state,
              currentPage
            };
          });
          setTimeout(() => this.props.hideLoader(), 200);
        }, 200);

      }

    }, {
      rootMargin: '10px',
      threshold: 1.0
    });
    intersectionObserver.observe(this.divInfiniteScrollRef.current);

  }

  openConfirmModal = (data) => {
    this.setState((state) => {
      return {
        confirmDelete: {
          display: true,
          data
        },
        currentPage: state.currentPage
      };
    });
  };

  closeConfirmModal = () => {
    this.setState((state) => {
      return {
        confirmDelete: {
          display: false,
          data: {}
        },
        currentPage: state.currentPage
      };
    });
  };

  confirmDelete = () => {
    const { showLoader, hideLoader, scheduleAndRedial, dentist } = this.props;
    const { confirmDelete, order } = this.state;
    // console.log(dentist);
    showLoader();
    ScheduleAPI.removeScheduleRedialList({
      dentist: dentist.value,
      id: confirmDelete.data.id
    })
      .then(({ data }) => {
        scheduleAndRedial({ dentist: dentist, order });
        hideLoader();
        ToastUtils.success(
          <ToastCustomMessageComponent iconImage={ success } message={ 'Registro Excluído com Sucesso !' }/>,
          {
            autoClose: 7000,
            hideProgressBar: true,
            position: 'top-center'
          }
        );
      })
      .catch((err) => {
        hideLoader();
        console.error(err);
        ToastUtils.error('Erro ao deletar registro entre em contato com o suporte');
      });

    this.closeConfirmModal();
  };

  schedule = (scheduleRedial) => {
    const { dentist } = this.props;

    const schedule = {
      paciente: scheduleRedial.paciente,
      procedimento: scheduleRedial.procedimento,
      data_agendamento: ''
    };
    showToast({
      schedule: schedule,
      onClose: () => {
        ScheduleAPI.removeScheduleRedialList({ dentist: dentist.value, id: scheduleRedial.id });
        this.props.scheduleFromPatient({ schedule: {} });
      },
      onCancel: () => this.props.scheduleFromPatient({ schedule: {} })
    });

    this.props.scheduleFromPatient({
      schedule
    });

    this.props.history.push('/schedule');
  };

  _reasonOrder = () => {
    const order = this.state.order == 'up' ? 'down' : 'up';
    this.props.scheduleAndRedial({ dentist: this.props.dentist, order });
    this.setState((state) => {
      return {
        order: order,
        currentPage: state.currentPage
      }
    });
  };

  render() {
    const { listScheduleRedial = [], add, dentist, scheduleAndRedial } = this.props;
    const { confirmDelete, order, currentPage } = this.state;
    console.log('src', listScheduleRedial.length);
    return [
      <div className={ 'content-box-without-color' } key={ 'home' }>
        <Row>
          <Col sm={ 12 }>
            <div
              className="box-default"
              style={ {
                marginTop: -20
              } }
            >
              <Container>
                <div className="d-flex align-items-center">
                  <div className="p-2">
                    <strong className="title-large text-uppercase">Agendar / Reagendar</strong>
                  </div>
                  <div className="p-2 ml-n2">
                    <IfComponent
                      conditional={ this.props.security.hasPermission(
                        PERMISSOES.modules.agenda.id,
                        PERMISSOES.modules.agenda.permissoes.editar
                      ) }
                    >
                      <a href="#" id="AddListScheduleRedial" onClick={ () => add({ dentist }) }>
                        <FiPlusCircle color="#616e88" size={ 22 } data-tip data-for={ 'tooltipAdicionar' }/>
                      </a>
                      <UncontrolledTooltip
                        placement="top"
                        target="AddListScheduleRedial"
                        className="tooltip-griks"
                        type="light"
                      >
                        Adicionar à Lista
                      </UncontrolledTooltip>
                    </IfComponent>
                  </div>
                  <div className="p-2 ml-auto">
                    <button
                      className="btn btn-default text-sm"
                      style={ {
                        backgroundColor: '#f7f9fc',
                        border: '1px solid #cfd5e3',
                        cursor: 'default',
                        width: '110px',
                        height: '35px'
                      } }
                    >
                      <strong>Total:&nbsp;{ listScheduleRedial.length }</strong>
                    </button>
                  </div>
                </div>

                <Table className="table-header-fixed" responsive>
                  <thead>
                  <tr className="row py-2">
                    <th className="col-sm-3 py-0">
                      <span className="text-sm text-secondary-d4">Paciente</span>
                    </th>
                    <th className="col-sm-1 py-0">
                      <span className="text-sm text-secondary-d4 text-nowrap">Último Atend</span>
                    </th>
                    <th className="col-sm-2 py-0">
                      <a href={ '#' } onClick={ this._reasonOrder } style={{textDecoration:'none'}}>
                          <span className="text-sm text-secondary-d4 pl-3 text-nowrap">
                            Motivo
                            <IfComponent conditional={ order === null }>
                              <MdKeyboardArrowDown size={'21'}/>
                            </IfComponent>
                            <IfComponent conditional={ order === 'up' }>
                              <MdKeyboardArrowDown size={'21'}/>
                            </IfComponent>
                            <IfComponent conditional={ order === 'down' }>
                              <MdKeyboardArrowUp size={'21'}/>
                            </IfComponent>
                          </span>
                      </a>
                    </th>
                    <th className="col-sm-1 py-0">
                      <span className="text-sm text-secondary-d4">Procedimento</span>
                    </th>
                    <th className="col-sm-1 py-0 pl-5">
                      <span className="text-sm text-secondary-d4">Observações</span>
                    </th>
                    <th className="col-sm-3 py-0 pl-5">
                      <span className="text-sm text-secondary-d4">Contato</span>
                    </th>
                    <th className="col-sm-1 icon-tab-schedu non-hover py-0 top">
                      <div style={ { maxHeight: 20 } } className="float-right">
                        <a href={ '#' } style={ { cursor: 'default' } }>
                          <Icon className="icon icon-size-one" image={ remarcar } alt="calendar icon"/> &nbsp;
                        </a>
                        <a href={ '#' } style={ { cursor: 'default' } }>
                          <Icon className="icon icon-size-one " image={ close } alt="close icon"/>
                        </a>
                      </div>
                    </th>
                  </tr>
                  </thead>

                  <tbody style={ { maxHeight: 'calc(100vh - 4OOpx)' } }>
                  { listScheduleRedial.slice(0, currentPage * 100).map((sr) => {
                    return (
                      <tr key={ sr.id } className={ 'row py-0 box-basic px-0' }>
                        <td className="col-sm-3">
                          <Row className="pb-0">
                            <Col sm={ 2 }>
                              <img
                                className={ 'img-user-avatar img-xxldpi' }
                                src={ sr.paciente.foto || noavatar }
                                alt="usuario"
                              />
                            </Col>
                            <Col sm={ 10 } className="p-0">
                              <div className="title-medium pl-3">
                                <p className="text-sm font-bold text-secondary text-nowrap cursor-pointer">
                                  {(sr.paciente.nome.length > 30) ?
                                    <a data-tip data-for={`name_${ sr.id }`}>
                                      
                                      {sr.paciente.nome.match(/.{1,30}/g)[0]}...
                                      
                                      <ReactTooltip className='tooltip-griks' id={`name_${ sr.id }`} type='gray'>
                                        {sr.paciente.nome}
                                      </ReactTooltip>
                                    </a> 
                                    : 
                                    sr.paciente.nome
                                  }
                                </p>
                                <p className="text-sm font-light text-secondary text-nowrap">{ sr.paciente.idade }</p>
                              </div>
                            </Col>
                          </Row>
                        </td>
                        <td className="col-sm-1">
                            <span className="text-sm text-secondary">
                              { sr.dataUltimoAgendamento && moment(sr.dataUltimoAgendamento).format('DD/MM/YYYY') }
                            </span>
                        </td>
                        <td className="col-sm-2">
                            <span className="text-sm text-secondary pl-3 text-nowrap text-capitalize">
                              { sr.motivo.toLowerCase() }
                            </span>
                        </td>
                        <td className="col-sm-1">
                            <span className="">

                              <a data-tip data-for={sr.procedimento.nome}> 
                                <span
                                  className="procedure-primary badge-proc m-0 cursor-pointer"
                                  style={ {
                                    backgroundColor: sr.procedimento.cor,
                                  } }
                                >
                                  { sr.procedimento.codinome }
                                </span>

                                <ReactTooltip className='tooltip-griks' id={sr.procedimento.nome} type='gray'>
                                  {sr.procedimento.nome}
                                </ReactTooltip>
                              </a>
                            </span>
                        </td>
                        { sr.observacao ? (
                          <td className="col-sm-1 pl-5">
                              <span className="text-sm text-secondary text-center">
                                <Icon
                                  className="icon icon-size-one"
                                  image={ notes }
                                  alt="notes"
                                  data-tip
                                  data-for={ `notes_${ sr.id }` }
                                />
                                <ReactTooltip className="tooltip-griks" id={ `notes_${ sr.id }` } type="gray">
                                  { sr.observacao }
                                </ReactTooltip>
                              </span>
                          </td>
                        ) : (
                          <td className="col-sm-1 pl-5">
                            {
                            //<div style={{width:25}}></div>
                            }
                          </td>
                        ) }
                        <td className="col-sm-3 pl-5">
                          <div>
                            { sr.paciente.contato
                              .filter((c) => c.telefone !== '')
                              .map((c, key) => (
                                <p
                                  id={ 'patient-' + sr.id + '-' + key }
                                  className="font-light text-sm text-secondary"
                                  key={ c.telefone }
                                >
                                  { phoneFormat(c.telefone) } - { c.responsavel }
                                </p>
                              )) }
                          </div>
                        </td>
                        <td className="col-sm-1 icon-tab-schedu">
                          <IfComponent
                            conditional={ this.props.security.hasPermission(
                              PERMISSOES.modules.agenda.id,
                              PERMISSOES.modules.agenda.permissoes.editar
                            ) }
                          >
                            <div className="float-right">
                              <a href={ '#' } data-tip data-for={ 'scheduleTooltip' }
                                 onClick={ () => this.schedule(sr) }>
                                <Icon className="icon icon-size-one" image={ remarcar } alt="calendar icon"/> &nbsp;
                              </a>
                              <ReactTooltip id="scheduleTooltip" className="tooltip-griks" type="gray">
                                Agendar Consulta
                              </ReactTooltip>
                              <a
                                href={ '#' }
                                data-tip
                                data-for={ 'deleteToolTip' }
                                style={ { marginTop: 2 } }
                                className='mr-0'
                                onClick={ () => {
                                  this.openConfirmModal(sr);
                                } }
                              >
                                <Icon className="icon icon-size-one" image={ close } alt="delete icon"/>
                              </a>
                              <ReactTooltip className="tooltip-griks" id="deleteToolTip" type="gray">
                                Excluir
                              </ReactTooltip>
                            </div>
                          </IfComponent>
                        </td>
                      </tr>
                    );
                  }) }
                  <div ref={ this.divInfiniteScrollRef } style={ { height: 50 } }></div>
                  </tbody>
                </Table>
              </Container>
            </div>
          </Col>
        </Row>
      </div>,
      <ModalMessageConfirm
        key={ 'confirmModal' }
        title={ <strong>Confirma Exclusão?</strong> }
        message={
          <span className="text-secondary">
            <p>
              Confirma a Exclusão de <strong>{ t(confirmDelete, 'data.paciente.nome').safeString }</strong> da Lista
              Agendar / Reagendar ?
            </p>
          </span>
        }
        openModal={ confirmDelete.display }
        icon={ warning }
        close={ this.closeConfirmModal }
        footer={
          <Row>
            <Col sm="3">
              <button className="button-tertiary" onClick={ this.closeConfirmModal }>
                Não
              </button>
            </Col>
            <Col sm={ { size: 3, offset: 6 } }>
              <button className="button-primary" onClick={ this.confirmDelete }>
                Sim
              </button>
            </Col>
          </Row>
        }
      />,
      <AddListScheduleRedialModal
        key={ 'AddListScheduleRedialModal' }
        updateParent={ () => scheduleAndRedial({ dentist: dentist, order }) }
      />
    ];
  }
}

const mapStateToProps = (state) => {
  return {
    ... listScheduleAndRedialPageSelectorToProps(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  scheduleAndRedial: (data) => dispatch(scheduleAndRedialSagaPage(data)),
  scheduleFromPatient: (data) => dispatch(scheduleFromPatient(data)),
  add: (data) => dispatch(scheduleAndRedialAddSaga(data)),
  setHeader: (data) => dispatch(setHeader(data))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withRouter, withSecurity);

export default enhanced(ListScheduleAndRedialPage);

//style={{width:293,paddingLeft:48}}
