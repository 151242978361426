import { all, put, call } from 'redux-saga/effects';
import { uncheckAppointmentModal } from '../redux/Schedule.actions';
import ProceduresAPI from '../../../service/ProceduresAPI';
import { t } from 'typy';
import ToastUtils from '../../../utils/ToastUtils';

export function* uncheckAppointmentSaga({ payload: { modal, schedule } }) {
  try {
    const category = t(schedule, 'procedimento.categoria.id').safeObject;

    const domains = yield all([
      call(ProceduresAPI.getAllCategories),
      call(ProceduresAPI.getAllProcedures, category, true)
    ]);

    const groups = domains[0].data || [];
    const procedures = domains[1].data || [];

    yield put(
      uncheckAppointmentModal({
        modal,
        schedule,
        groups,
        procedures
      })
    );
  } catch (e) {
    console.error(e);
    ToastUtils.error('Erro ao abrir modal de desmarcar consulta');
  }
}
