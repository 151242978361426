import React, { Component } from 'react';

import { Col, Container, Row, Table } from 'reactstrap';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { formatDate } from '../../utils/DateUtils';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import CashAPI from '../../service/CashAPI';
import { formatReal, formatRealNoShowDolarSign } from '../../utils/Formatter';
import { hideLoader, showLoader } from '../../components/actions/Loader.actions';
import ToastUtils from '../../utils/ToastUtils';
import { getGriksSessionData } from '../../utils/localStorage/SessionStorageUtils';
import t from 'typy';

class CashFlowReportPage extends Component {

  state = {
    griksData: {},
    report: {}
  };

  async componentDidMount() {
    this.props.showLoader();
    const report = JSON.parse(localStorage.getItem('report'));

    this.setState({ report: report.data, griksData: report.griks });
    this.props.hideLoader();
    setTimeout(() => window.print(), 500);

  }

  render() {

    const { report, griksData } = this.state;
    const totalrecebido = (report.valores_recebidos && Object.values(report.valores_recebidos).reduce((s, v) => s + v)) || 0;
    let total = totalrecebido;
    let totalFinal = totalrecebido;
    let totalAjustes = 0;

    if (report.valor_inicial) {
      totalFinal = totalrecebido + report.valor_inicial;
    }

    if (report.ajustes) {
      report.ajustes.forEach(a => totalAjustes += a.valor);
    }

    return (
      <Container style={ { padding: 20, backgroundColor: '#fff', height: '100%' } }>
        <div style={ { marginTop: 20, } }>
          <Row>
            <Col sm={ 12 }><p className="font-bold" style={ { color: '#212529',  fontSize: '16px' } }>
              { t(griksData, 'clinic.nome').safeString }</p></Col>
          </Row>
          <Row>
            <Col sm={ 12 }><p className="font-bold" style={ { color: '#212529',  fontSize: '16px' } }>RELATÓRIO DE FECHAMENTO
              DE CAIXA</p></Col>
          </Row>
          <Row>
            <Col sm="4">
              <p className="font-bolder" style={ { color: '#212529',  fontSize: '16px' } }>Caixa
                de <span className="font-bold" style={ { color: '#212529',  fontSize: '16px' } }>{ report.usuario_nome }</span></p>
            </Col>

            <Col sm="4">
              <p className="font-bolder" style={ { color: '#212529',  fontSize: '16px' } }>
                Abertura <span className="font-bold" style={ { color: '#212529',  fontSize: '16px' } }>{ formatDate(report.aberto_em, 'DD/MM/YYYY HH:mm') }</span>
              </p>
            </Col>
            <Col sm="4">
              <p className="font-bolder" style={ { color: '#212529',  fontSize: '16px' } }>
                Fechamento <span className="font-bold" style={ { color: '#212529',  fontSize: '16px' } }>{ report.fechado_em && formatDate(report.fechado_em, 'DD/MM/YYYY HH:mm') }</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm="12" className={'text-big-sm'}>
              <Table responsive>
                <thead style={ { backgroundColor: '#f3f4f6'} }>
                <tr>
                  <th width="10%">Data</th>
                  <th width="10%">Hora</th>
                  <th width="25%">Paciente</th>
                  <th>Forma de Pagamento</th>
                  <th width="25%">Valor R$</th>
                </tr>
                </thead>
                <tbody className="text-big-sm">
                { report.recebidos && report.recebidos.map(r => (
                  <tr key={ r.id }>
                    <td style={ { borderTop: '1px solid #64686E', borderBottom: '1px solid #64686E' } }>{ formatDate(r.data, 'DD/MM/YYYY') }</td>
                    <td style={ { borderTop: '1px solid #64686E', borderBottom: '1px solid #64686E' } }>{ formatDate(r.data, 'HH:mm') }</td>
                    <td style={ { borderTop: '1px solid #64686E', borderBottom: '1px solid #64686E' } }>{ r.paciente }</td>
                    <td style={ { borderTop: '1px solid #64686E', borderBottom: '1px solid #64686E' } }>{ r.forma_pagamento }</td>
                    <td style={ { borderTop: '1px solid #64686E', borderBottom: '1px solid #64686E' } }>{ formatRealNoShowDolarSign(r.valor) }</td>
                  </tr>
                )) }
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={ { textAlign: 'right' } }><p className="font-bolder" style={ { color: '#212529',  fontSize: '16px' } }>Total</p></td>
                  <td style={ { textAlign: 'text-center' } }><span className="font-bolder" style={ { color: '#212529',  fontSize: '16px' } }>{ formatReal(total) }</span></td>
                  
                </tr>
                </tbody>
              </Table>
            </Col>
          </Row>

          <Row>
            <Col sm="12" className={'text-big-sm'}>
              <h2 className="font-bold" style={ { color: '#212529',  fontSize: '16px' } }>AJUSTES</h2>
              <Table responsive>
                <thead style={ { backgroundColor: '#f3f4f6'} }>
                <tr>
                  <th width="10%">Data</th>
                  <th width="10%">Hora</th>
                  <th width="25%">Paciente</th>
                  <th>Tipo de ajustes</th>
                  <th width="25%">Valor R$</th>
                </tr>
                </thead>
                <tbody className="text-big-sm">
                { report.ajustes && report.ajustes.map(r => (
                  <tr key={ r.id }>
                    <td style={ { borderTop: '1px solid #64686E', borderBottom: '1px solid #64686E' } }>{ formatDate(r.data, 'DD/MM/YYYY') }</td>
                    <td style={ { borderTop: '1px solid #64686E', borderBottom: '1px solid #64686E' } }>{ formatDate(r.data, 'HH:mm') }</td>
                    <td style={ { borderTop: '1px solid #64686E', borderBottom: '1px solid #64686E' } }>{ r.paciente }</td>
                    <td style={ { borderTop: '1px solid #64686E', borderBottom: '1px solid #64686E' } }>{ r.descricao }</td>
                    <td style={ { borderTop: '1px solid #64686E', borderBottom: '1px solid #64686E' } } className="text-alert">{ formatRealNoShowDolarSign(r.valor) }</td>
                  </tr>
                )) }
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                </tbody>
              </Table>
            </Col>
          </Row>

          <div className="box-report">
            <Row>
              <Col sm="6">
                <h2 className="font-bold mt-3" style={ { color: '#212529',  fontSize: '16px' } }>(+) VALORES RECEBIDOS</h2>
                <Table responsive className="table-default">
                  <thead>
                  <tr>
                    <th width="75%"></th>
                    <th width="25%"></th>
                  </tr>
                  </thead>
                  <tbody className="text-big-sm">
                  { report.valores_recebidos && Object.keys(report.valores_recebidos).map(k => (
                    <tr key={ k }>
                      <td style={ { borderTop: '1px solid #64686E', borderBottom: '1px solid #64686E' } }>{ k }</td>
                      <td style={ { borderTop: '1px solid #64686E', borderBottom: '1px solid #64686E' } }>{ formatRealNoShowDolarSign(report.valores_recebidos[k]) }</td>
                    </tr>
                  )) }
                  <tr>
                  	<td style={ { textAlign: 'right', borderTop: '1px solid #64686E', borderBottom: '1px solid #64686E'  } }><p className="font-bolder" style={ { color: '#212529',  fontSize: '16px' } }>Total Recebido</p></td>
                    <td style={ { textAlign: 'text-center', borderTop: '1px solid #64686E', borderBottom: '1px solid #64686E' } }><span className="font-bolder" style={ { color: '#212529',  fontSize: '16px' } }>{ formatReal(total) }</span></td>
                  </tr>
                  </tbody>
                </Table>
              </Col>

              <Col sm="1">
              </Col>

              <Col sm="5">
                <h2 className="font-bold mt-3" style={ { color: '#212529',  fontSize: '16px' } }>(+) SALDO INICIAL</h2>
                <Table responsive className="table-default">
                  <thead>
                  <tr>
                    <th width="75%"></th>
                    <th width="25%"></th>
                  </tr>
                  </thead>
                  <tbody className="text-big-sm">
                  <tr>
                    <td style={ { borderTop: '1px solid #64686E', borderBottom: '1px solid #64686E' } }><p className="font-bold" style={ { color: '#212529',  fontSize: '16px' } }>Troco em Dinheiro</p></td>
                    <td style={ { borderTop: '1px solid #64686E', borderBottom: '1px solid #64686E' } }><p className="font-bold" style={ { color: '#212529',  fontSize: '16px' } }>{ formatRealNoShowDolarSign(report.valor_inicial) }</p></td>
                  </tr>
                  </tbody>
                </Table>

                <h2 className="font-bold" style={ { color: '#212529',  fontSize: '16px' } }>(=) SALDO FINAL</h2>
                <Table responsive className="table-default">
                  <thead>
                  <tr>
                    <th width="75%"></th>
                    <th width="25%"></th>
                  </tr>
                  </thead>
                  <tbody className="text-big-sm">
                  <tr>
                    <td style={ { borderTop: '1px solid #64686E', borderBottom: '1px solid #64686E' } }><p className="font-bolder" style={ { color: '#212529',  fontSize: '16px' } }>Saldo Total Final</p></td>
                    <td style={ { borderTop: '1px solid #64686E', borderBottom: '1px solid #64686E' } }><span className="font-bolder" style={ { color: '#212529',  fontSize: '16px' } }>{ formatRealNoShowDolarSign(totalFinal) }</span></td>
                  </tr>
                  </tbody>
                </Table>

                <Row>
                  <Col sm="12">
                    <div className="box-signature text-center" style={{borderBottom: '1px solid #64686E'}}>
                      <h2 className="font-bolder" style={ { color: '#212529',  fontSize: '16px', marginTop: '40px' } }>RECEBIDO</h2>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.CashReducer.report
  };
};

const mapDispatchToProps = dispatch => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader())
});

const enhanced = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter
);

export default enhanced(CashFlowReportPage);