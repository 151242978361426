import { Sagas } from './PatientReportSagas';

export const Types = {
  LOAD_PATIENT_REPORT: 'report/patient/store/LOAD_PATIENT_REPORT',
  SEND_TO_RESULT_PATIENT_REPORT_OVERVIEW: 'report/patient/store/SEND_TO_RESULT_PATIENT_REPORT_OVERVIEW',
  LOADER_INFINIT_SCROLL: 'report/patient/store/LOADER_INFINIT_SCROLL'
};

// Reducer
const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.LOAD_PATIENT_REPORT:
      return {
        ...state,
        patientReport: {
          ...action.payload
        }
      };
    case Types.SEND_TO_RESULT_PATIENT_REPORT_OVERVIEW:
      return {
        ...state,
        patientReportResult: {
          ...action.payload
        }
      };
    case Types.LOADER_INFINIT_SCROLL:
      return {
        ...state,
        patientReportResultInfinitScroll: {
          ...action.payload
        }
      };
    default:
      return state;
  }
}

// actions

export function loadPagePatientOverviewReport(payload) {
  return {
    type: Sagas.LOAD_PATIENT_REPORT_PAGE,
    payload: {
      ...payload
    }
  };
}

export function sendLoadedPagePatientOverviewReport(payload) {
  return {
    type: Types.LOAD_PATIENT_REPORT,
    payload: {
      ...payload
    }
  };
}

export function findPatientOverviewToReportOverview(payload) {
  return {
    type: Sagas.FIND_PATIENT_REPORT_OVERVIEW_FILTER,
    payload: {
      ...payload
    }
  };
}

export function sendToResultPatientReportOverview(payload) {
  return {
    type: Types.SEND_TO_RESULT_PATIENT_REPORT_OVERVIEW,
    payload: {
      ...payload
    }
  };
}

export function infinitScroll(payload) {
  return {
    type: Types.LOADER_INFINIT_SCROLL,
    payload: {
      ...payload
    }
  };
}
