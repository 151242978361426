import { createSelector } from 'reselect';
import t from 'typy';
import moment from 'moment';

const anticipateSelector = (state) => t(state, 'TreatmentClinicalRecordReducer.anticipateDebit').safeObject;

export const antecipateModalToProps = createSelector(anticipateSelector, (anticipateDebit = {}) => {
  const { modal, treatment, record, debit } = anticipateDebit;

  return {
    modal,
    treatment,
    record,
    debit
  };
});
