import { takeEvery } from 'redux-saga/effects';
import {
  APPOINTMENT_HISTORY_SAGA_MODAL,
  SCHEDULE_AND_REDIAL_ADD_SAGA_MODAL,
  SCHEDULE_AND_REDIAL_SAGA_PAGE,
  SCHEDULE_APPOINTMENT_DETAIL_SAGA_MODAL,
  SCHEDULE_SAGA_PAGE,
  SCHEDULE_SCHEDULE_APPOINTMENT_PATIENT_SAGA_MODAL,
  SCHEDULE_SCHEDULE_APPOINTMENT_SELECT_TREATMENT_SAGA_MODAL,
  SEND_TO_APPOINTMENT_AND_REDIAL_SAGA,
  UNCHECK_APPOINTMENT_SAGA_MODAL,
  SCHEDULE_ORTHODONTICSFREQUENCY_SAGA_PAGE
} from '../redux/Schedule.actions';
import { scheduleSagaProcess } from './SchedulePage.saga';
import { scheduleAppointTreatmentSagaProcess } from './ScheduleAppointmentTreatmentSelect.saga';
import { scheduleAppointPatientSagaProcess } from './ScheduleAppointmentPatientSelect.saga';
import { scheduleDetailModal } from './ScheduleAppointmentDetail.saga';
import { uncheckAppointmentSaga } from './UncheckAppointment.saga';
import { sendToAppointmentAndRedialProcess } from './SendToAppointmentAndRedial.saga';
import { appointmentHistoryModalSaga } from './AppointmentHistory.saga';
import { scheduleAndRedialAddModal, scheduleAndRedialListSaga } from './ScheduleAndRedial.saga';
import { scheduleFrequencySagaProcess } from './ScheduleFrequency.saga';

export function* watchScheduleSagas() {
  yield takeEvery(SCHEDULE_SAGA_PAGE, scheduleSagaProcess);
  yield takeEvery(SCHEDULE_SCHEDULE_APPOINTMENT_SELECT_TREATMENT_SAGA_MODAL, scheduleAppointTreatmentSagaProcess);
  yield takeEvery(SCHEDULE_SCHEDULE_APPOINTMENT_PATIENT_SAGA_MODAL, scheduleAppointPatientSagaProcess);
  yield takeEvery(SCHEDULE_APPOINTMENT_DETAIL_SAGA_MODAL, scheduleDetailModal);
  yield takeEvery(UNCHECK_APPOINTMENT_SAGA_MODAL, uncheckAppointmentSaga);
  yield takeEvery(SEND_TO_APPOINTMENT_AND_REDIAL_SAGA, sendToAppointmentAndRedialProcess);
  yield takeEvery(APPOINTMENT_HISTORY_SAGA_MODAL, appointmentHistoryModalSaga);
  yield takeEvery(SCHEDULE_AND_REDIAL_SAGA_PAGE, scheduleAndRedialListSaga);
  yield takeEvery(SCHEDULE_AND_REDIAL_ADD_SAGA_MODAL, scheduleAndRedialAddModal);
  yield takeEvery(SCHEDULE_ORTHODONTICSFREQUENCY_SAGA_PAGE, scheduleFrequencySagaProcess);
}
