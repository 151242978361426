import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import Icon from './Icon.component';

import PropTypes from 'prop-types';
import { IfComponent } from './if.component';

const ToastCustomMessageComponent = ({ iconImage, title, message, style = {}, iconClass = '' }) => {
  return (
    <Row style={{ marginTop: -1, marginLeft: 1, ...style }} className='text-default text-sm'>
      <Col sm={1}>
        <Icon
          style={{ marginLeft: -1, marginTop: -3.5, paddingLeft: -5 }}
          className={`icon icon-size-smaller ${iconClass}`}
          image={iconImage}
          alt='danger1'
        />
      </Col>
      <Col sm={11}>
        <IfComponent conditional={title}>
          <strong style={{ fontWeight: 600 }}>{title}</strong>
          <br />
        </IfComponent>
        <IfComponent conditional={message}>
          <span className='text-sm'>{message}</span>
        </IfComponent>
      </Col>
    </Row>
  );
};

ToastCustomMessageComponent.propTypes = {
  iconImage: PropTypes.object,
  title: PropTypes.string,
  message: PropTypes.string
};

export default ToastCustomMessageComponent;
