import { combineReducers, createStore, applyMiddleware, compose } from 'redux';

import logger from './logger';

//saga
import createSagaMiddleware from 'redux-saga';
import sagaConfig from '../sagas/sagas.config';
import reducers from './reducers.config';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers(reducers);
const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(logger, sagaMiddleware)));

sagaMiddleware.run(sagaConfig);

export { store };
