import React, { Component } from 'react';

import { Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { treatmentRegisterModal } from '../redux/Treatment.action';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ErrorField from '../../../components/ErrorField.component';
import TreatmentAPI from '../../../service/TreatmentAPI';
import { showLoader, hideLoader } from '../../../components/actions/Loader.actions';

import Select from 'react-select';
import { selectStyles } from '../../../config/core.config';
import { reactSelectHandleChange, reactSelectStyleIsError } from '../../../utils/ReactSelectUtils';
import { treatmentRegisterModalReducerToProps } from './selectors/TreatmentRegisterModalSelector';
import ErrorFieldWithoutBootstrap from '../../../components/ErrorFieldWithoutBootstrap.component';

class TreatmentRegisterModal extends Component {
  formValidationSchema = Yup.object().shape({
    dentist: Yup.object().shape({
      value: Yup.number().required('Dentista Obrigatório')
    }),
    situation: Yup.object().shape({
      value: Yup.string().required('Situação do Tratamento Obrigatório')
    })
  });

  saveTreatment = async (values) => {
    const { showLoader, hideLoader, closeModal, refreshParent, formLoad, callback } = this.props;

    showLoader();

    try {
      await TreatmentAPI.saveTreatment({
        dentist: values.dentist.value,
        speciality: values.speciality,
        situation: values.situation.value,
        patient: formLoad.patient
      }).then(({ data }) => {
        hideLoader();
        closeModal();
        refreshParent(data);
      });
    } catch (e) {
      console.log(e);
      hideLoader();
    }
  };

  render() {
    const { modal, formLoad = {}, closeModal } = this.props;
    const { dentists = [], specialties = [], situations = [] } = formLoad;

    return (
      <Modal isOpen={modal} toggle={closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={closeModal}
          close={
            <button className='close' onClick={closeModal}>
              &times;
            </button>
          }
        >
          <strong>Criar Novo Tratamento</strong>
        </ModalHeader>
        <Formik
          validationSchema={this.formValidationSchema}
          validateOnChange={false}
          initialValues={{
            speciality: 'ORT',
            situation: formLoad.situationsOptions && formLoad.situationsOptions.find((s) => s.value === 'N')
          }}
          onSubmit={this.saveTreatment}
        >
          {({ errors, handleSubmit, handleBlur, handleChange, values }) => {
            console.log(errors);
            return (
              <form onSubmit={handleSubmit}>
                <ModalBody>
                  <div className='margin-top-10'>
                    <Row className='margin-top-10'>
                      <Col sm='6'>
                        <label className={'w-100'}>
                          Doutor(a) <span>*</span>
                        </label>
                        <Select
                          name={'dentist'}
                          placeholder={''}
                          styles={reactSelectStyleIsError(errors, 'dentist.value', selectStyles)}
                          value={values.dentist}
                          defaultValue={values.dentist}
                          options={formLoad.dentistsOptions}
                          onChange={(d) => reactSelectHandleChange(d, handleChange, 'dentist')}
                        />
                        <ErrorFieldWithoutBootstrap errors={errors} fieldName={'dentist.value'} />
                      </Col>
                      <Col sm='6'>
                        <label>
                          Especialidade <span>*</span>
                        </label>
                        <input type={'text'} readOnly={true} value={'Ortodontia'} className='input-secondary' />
                      </Col>
                    </Row>
                    <Row className='margin-top-10'>
                      <Col sm='12'>
                        <label className={'w-100'}>
                          Situação do Tratamento <span>*</span>
                        </label>
                        <Select
                          placeholder={''}
                          styles={reactSelectStyleIsError(errors, 'situation.value', selectStyles)}
                          value={values.situation}
                          defaultValue={values.situation}
                          options={formLoad.situationsOptions}
                          onChange={(s) => reactSelectHandleChange(s, handleChange, 'situation')}
                        />
                        <ErrorFieldWithoutBootstrap errors={errors} fieldName={'situation.value'} />
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Row>
                    <Col className={'btn-list'} sm={{ size: 7, offset: 5 }}>
                      <button className='button-primary' type='submit'>
                        Salvar
                      </button>
                      <button className='button-tertiary' onClick={closeModal}>
                        Cancelar
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...treatmentRegisterModalReducerToProps(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(treatmentRegisterModal({ modal: false, formData: {}, formLoad: {} })),
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader())
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(TreatmentRegisterModal);
