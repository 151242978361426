import { call, put } from 'redux-saga/effects';
import UserAPI from '../../../../service/User.service';
import { chairsModal } from '../redux/ScheduleSettings.actions';

export function* modalScheduleChairs({ payload: { modal, initForm, dentist } }) {
  const assistants = yield call(UserAPI.getAssistants, dentist);
  const data = {
    modal,
    initForm: {
      ...initForm
    },
    dataForm: {
      assistants: assistants.data,
      dentist
    }
  };

  yield put(chairsModal(data));
}
