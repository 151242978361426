import t from 'typy';
export function getGriksSessionData() {
  const griks = sessionStorage.getItem('griks');
  try {
    return JSON.parse(griks);
  } catch (err) {
    console.log(err);
    return {};
  }
}

export function apiAccessTokenSave(token) {
  const griks = getGriksSessionData();
  sessionStorage.setItem(
    'griks',
    JSON.stringify({
      ...griks,
      apiAccessToken: token
    })
  );
}

export function apiAccessToken() {
  const griks = getGriksSessionData();
  if (griks) {
    return griks.apiAccessToken;
  }
}

export function isLogged() {
  const token = apiAccessToken();
  return token ? true : false;
}

export function savePermissions(permissions) {
  const griks = getGriksSessionData();
  sessionStorage.setItem(
    'griks',
    JSON.stringify({
      ...griks,
      permissions
    })
  );
}

export function saveClinicData(clinic) {
  const griks = getGriksSessionData();
  sessionStorage.setItem(
    'griks',
    JSON.stringify({
      ...griks,
      clinic
    })
  );
}

export function saveUserData(user) {
  const griks = getGriksSessionData();
  sessionStorage.setItem(
    'griks',
    JSON.stringify({
      ...griks,
      user
    })
  );
}

export function saveAffiliate(affiliate) {
  const griks = getGriksSessionData();
  sessionStorage.setItem(
    'griks',
    JSON.stringify({
      ...griks,
      affiliate
    })
  );
}

export function getUserData() {
  const griks = getGriksSessionData();
  if (griks) {
    return griks.user;
  } else {
    return {};
  }
}

export function getPermissions() {
  const griks = getGriksSessionData();
  if (griks) {
    return griks.permissions;
  } else {
    return [];
  }
}

export function getClinic() {
  const griks = getGriksSessionData();
  if (griks) {
    return griks.clinic;
  } else {
    return [];
  }
}

export function isAffiliate() {
  const griks = getGriksSessionData();
  if (griks) {
    return t(griks, 'affiliate.id').isDefined;
  } else {
    return [];
  }
}

export function getAffiliate() {
  const griks = getGriksSessionData();
  if (griks) {
    return griks.affiliate;
  } else {
    return [];
  }
}

export function logout() {
  sessionStorage.clear();
}

export function getDentistSelected() {
  const data = sessionStorage.getItem('dentistSelected');

  if (data) {
    return JSON.parse(data);
  } else {
    return {};
  }
}
