import React from 'react';

import PrivateRoute from '../components/PrivateRoute.component';
import TreatmentHomePage from '../pages/treatment/TreatmentHome.page';

const TreatmentRouter = (propsRouter) => {
  return [
    <PrivateRoute
      key='TreatmentHome'
      path='/patient/:patientId/treatment'
      exact
      render={(props) => <TreatmentHomePage {...props} {...propsRouter} />}
    />,
    <PrivateRoute
      key='TreatmentHome'
      path='/patient/:patientId/treatment/:treatmentId'
      exact
      render={(props) => <TreatmentHomePage {...props} {...propsRouter} />}
    />
  ];
};

export default TreatmentRouter;
