export const TREATMENT_FINANCIAL_FILE_SAGA_PAGE = 'TREATMENT_FINANCIAL_FILE_SAGA_PAGE';
export const TREATMENT_FINANCIAL_FILE_PAGE = 'TREATMENT_FINANCIAL_FILE_PAGE';
export const TREATMENT_FINANCIAL_FILE_CLOSED_PAYMENT_PAGE = 'TREATMENT_FINANCIAL_FILE_CLOSED_PAYMENT_PAGE';
export const TREATMENT_FINANCIAL_FILE_MONTHLY_PAYMENT_PAGE = 'TREATMENT_FINANCIAL_FILE_MONTHLY_PAYMENT_PAGE';
export const TREATMENT_FINANCIAL_FILE_DEBITS_PAGE = 'TREATMENT_FINANCIAL_FILE_DEBITS_PAGE';
export const TREATMENT_FINANCIAL_FILE_DEBITS_MODAL = 'TREATMENT_FINANCIAL_FILE_DEBITS_MODAL';

export const TREATMENT_FINANCIAL_ACCOUNT_LOAD_MODAL = 'TREATMENT_FINANCIAL_ACCOUNT_LOAD_MODAL';
export const TREATMENT_FINANCIAL_ACCOUNT_MODAL = 'TREATMENT_FINANCIAL_ACCOUNT_MODAL';

export const TREATMENT_FINANCIAL_ENTRIES_MODAL = 'TREATMENT_FINANCIAL_ENTRIES_MODAL';

export const TREATMENT_FINANCIAL_LAUNCH_PAYMENT_SAGA_MODAL = 'TREATMENT_FINANCIAL_LAUNCH_PAYMENT_SAGA_MODAL';
export const TREATMENT_FINANCIAL_LAUNCH_PAYMENT_MODAL = 'TREATMENT_FINANCIAL_LAUNCH_PAYMENT_MODAL';

export const TREATMENT_FINANCIAL_LAUNCH_OTHER_PAYMENT_SAGA_MODAL =
  'TREATMENT_FINANCIAL_LAUNCH_OTHER_PAYMENT_SAGA_MODAL';
export const TREATMENT_FINANCIAL_LAUNCH_OTHER_PAYMENT_MODAL = 'TREATMENT_FINANCIAL_LAUNCH_OTHER_PAYMENT_MODAL';

export function financialFileSagaCreateModal({ treatment }) {
  return {
    type: TREATMENT_FINANCIAL_FILE_SAGA_PAGE,
    payload: {
      treatment
    }
  };
}

export function financialFileCreateModal({ treatment, financialFile }) {
  return {
    type: TREATMENT_FINANCIAL_FILE_PAGE,
    payload: {
      treatment,
      financialFile
    }
  };
}

export function financialFileAccountLoadModal({ modal, treatment, financialFile }) {
  return {
    type: TREATMENT_FINANCIAL_ACCOUNT_LOAD_MODAL,
    payload: {
      modal,
      treatment,
      financialFile
    }
  };
}

export function financialFileAccountModal({ treatment, financialFile, formLoad, modal }) {
  return {
    type: TREATMENT_FINANCIAL_ACCOUNT_MODAL,
    payload: {
      treatment,
      financialFile,
      formLoad,
      modal
    }
  };
}

export function financialClosedPaymentModal({ treatment, financialFile }) {
  return {
    type: TREATMENT_FINANCIAL_FILE_CLOSED_PAYMENT_PAGE,
    payload: {
      treatment,
      financialFile
    }
  };
}

export function financialMonthlyPaymentModal({ treatment, financialFile }) {
  return {
    type: TREATMENT_FINANCIAL_FILE_MONTHLY_PAYMENT_PAGE,
    payload: {
      treatment,
      financialFile
    }
  };
}

export function financialDebitsPage({ treatment, financialFile, expandDebits }) {
  return {
    type: TREATMENT_FINANCIAL_FILE_DEBITS_PAGE,
    payload: {
      treatment,
      financialFile,
      expandDebits
    }
  };
}

export function financialDebitsModal({ modal, treatment, financialFile, debits }) {
  return {
    type: TREATMENT_FINANCIAL_FILE_DEBITS_MODAL,
    payload: {
      modal,
      treatment,
      financialFile,
      debits
    }
  };
}

export function financialEntriesModal({ treatment, financialFile, entries }) {
  return {
    type: TREATMENT_FINANCIAL_ENTRIES_MODAL,
    payload: {
      treatment,
      financialFile,
      entries
    }
  };
}

export function financialLaunchPaymentSagaModal({ financialFile, treatment, entry, paymentEntries }) {
  return {
    type: TREATMENT_FINANCIAL_LAUNCH_PAYMENT_SAGA_MODAL,
    payload: {
      financialFile,
      treatment,
      entry,
      paymentEntries
    }
  };
}

export function financialLaunchPaymentModal({
  modal,
  financialFile,
  paymentTypes,
  receipt,
  entry,
  referencesByEmission,
  paymentEntries
}) {
  return {
    type: TREATMENT_FINANCIAL_LAUNCH_PAYMENT_MODAL,
    payload: {
      modal,
      financialFile,
      paymentTypes,
      receipt,
      entry,
      referencesByEmission,
      paymentEntries
    }
  };
}

export function financialLaunchOtherPaymentSagaModal({ financialFile, treatment, entry }) {
  return {
    type: TREATMENT_FINANCIAL_LAUNCH_OTHER_PAYMENT_SAGA_MODAL,
    payload: {
      financialFile,
      treatment,
      entry
    }
  };
}

export function financialLaunchOtherPaymentModal({ modal, financialFile, paymentTypes, receipt, entry, entryTypes }) {
  return {
    type: TREATMENT_FINANCIAL_LAUNCH_OTHER_PAYMENT_MODAL,
    payload: {
      modal,
      financialFile,
      paymentTypes,
      receipt,
      entry,
      entryTypes
    }
  };
}

export function expandDebits(expandDebits) {
  return {
    type: TREATMENT_FINANCIAL_FILE_PAGE,
    payload: {
      expandDebits
    }
  };
}
