import React, { Component } from 'react';

import { Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import Select, { components } from 'react-select';
import {
  formInitSelector,
  formLoadSelector,
  propsAccountDataSelector
} from '../selectors/FinancialFileAcountInfoModal.selector';
import { financialFileAccountModal } from '../redux/FinancialFile.action';
import { Form, Formik } from 'formik';
import { reactSelectHandleChange, reactSelectStyleIsError } from '../../../../utils/ReactSelectUtils';
import ErrorFieldWithoutBootstrap from '../../../../components/ErrorFieldWithoutBootstrap.component';
import * as Yup from 'yup';
import { t } from 'typy';
import Constants from '../../../../utils/Constants';
import { IfComponent } from '../../../../components/if.component';
import TreatmentFinancialFileApi from '../../../../service/TreatmentFinancialFileApi';
import { showLoader, hideLoader } from '../../../../components/actions/Loader.actions';
import MessagesComponent from '../../evaluation/modals/TreatmentEvaluationAttendenceRegister.modal';
import { selectStyles } from '../../../../config/core.config';
import InputMaskComponent from '../../../../components/InputMask.component';
import { maskCPF } from '../../../../utils/Formatter';

const SingleValue = ({ children, ...props }) => {
  const { data } = props;

  return (
    <components.SingleValue {...props}>
      {data.value && (
        <div className={'voucherOption'}>
          <div className={'voucherType'} style={{ borderColor: data.color, color: data.color }}>
            {data.value}
          </div>
          {data.label}
        </div>
      )}
    </components.SingleValue>
  );
};

const CustomOptionVoucher = (props) => {
  const { innerProps, isDisabled, data, getStyles } = props;
  console.log(getStyles('option', props));
  return !isDisabled ? (
    <div style={getStyles('option', props)} {...innerProps}>
      <div className={'voucherOption'}>
        <div className={'voucherType'} style={{ borderColor: data.color, color: data.color }}>
          {data.value}
        </div>
        {data.label}
      </div>
    </div>
  ) : null;
};

class TreatmentFinancialAccountInfosModal extends Component {
  state = {
    validationError: [],
    message: {
      display: false,
      type: 'danger',
      text: ''
    }
  };

  validationFormSchema = Yup.object().shape({
    treatment_type: Yup.object().shape({
      value: Yup.number().required('Tipo de Tratamento (Fase) obrigatório')
    }),
    voucher: Yup.object().shape({
      value: Yup.number().required('Comprovante obrigatório')
    }),
    responsible_type: Yup.object().shape({
      value: Yup.number().required('Responsável obrigatório')
    })
  });

  closeModal = () => {
    this.props.closeModal();
    this.setState({
      message: {
        display: false,
        type: 'danger',
        text: ''
      }
    });
  };

  saveAccountInfo = async (values) => {
    const formValid = this.validationForm(values);

    if (formValid) {
      const { treatment, financialFile, showLoader, hideLoader, updateParent } = this.props;
      showLoader();
      try {
        const formDataRequest = {
          id: values.id,
          tipo_tratamento: t(values, 'treatment_type.data.id').safeObject,
          tipo_responsavel: t(values, 'responsible_type.data.id').safeObject,
          comprovante: t(values, 'voucher_type.data.name').safeObject,
          cpf: values.cpf_paciente,
          rg: values.rg_paciente,
          nome_responsavel: values.nome_responsavel,
          cpf_responsavel: values.cpf_responsavel,
          rg_responsavel: values.rg_responsavel
        };

        await TreatmentFinancialFileApi.saveAccountInfo({
          treatment: treatment.id,
          financialFile: financialFile.id,
          formData: formDataRequest
        });

        this.closeModal();
        updateParent();
      } catch (e) {
        this.setState({
          message: {
            display: true,
            type: 'danger',
            text: 'Erro ao salvar Informações da Conta de Tratamento'
          }
        });
      }

      hideLoader();
    }
  };

  validationForm = (values) => {
    const responsible_type = t(values, 'responsible_type.data.id').safeNumber;
    const nome_responsavel = t(values, 'nome_responsavel').safeString;
    const cpf_responsavel = t(values, 'cpf_responsavel').safeString;
    const rg_responsavel = t(values, 'rg_responsavel').safeString;
    const validationError = [];
    let valid = true;

    if (responsible_type !== Constants.TIPO_RESPONSAVEL_PACIENTE) {
      if (!nome_responsavel) {
        validationError['nome_responsavel'] = 'Nome responsável obrigatório';
        valid = false;
      }

      if (!cpf_responsavel || cpf_responsavel === '') {
        validationError['cpf_responsavel'] = 'Cpf responsável obrigatório';
        valid = false;
      }

      if (!rg_responsavel || rg_responsavel === '') {
        validationError['rg_responsavel'] = 'RG responsável obrigatório';
        valid = false;
      }
    }

    this.setState({
      validationError
    });

    return valid;
  };

  render() {
    const { modal, formLoad, formInit } = this.props;
    const { validationError, message } = this.state;
    console.log(validationError);

    return (
      <Modal isOpen={modal} toggle={this.closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={this.closeModal}
          close={
            <button className='close' onClick={this.closeModal}>
              &times;
            </button>
          }
        >
          <strong>Editar</strong> Informações da Conta de Tratamento
        </ModalHeader>
        <Formik initialValues={formInit} validateOnChange={false} onSubmit={this.saveAccountInfo}>
          {({ errors, values, handleChange }) => {
            return (
              <Form>
                <ModalBody>
                  <MessagesComponent display={message.display} type={message.type} message={message.text} />
                  <div className='margin-top-10'>
                    <Row className='margin-top-10 text-left'>
                      <Col sm='12'>
                        <label>
                          Tipo de Tratamento/Fase<span>*</span>
                        </label>
                      </Col>
                      <Col sm={'12'}>
                        <Select
                          value={values.treatment_type}
                          options={formLoad.treatmentTypes}
                          isSearchable={true}
                          ignoreAccents={true}
                          ignoreCase={true}
                          placeholder={''}
                          styles={reactSelectStyleIsError(errors, 'treatment_type.value', selectStyles)}
                          onChange={(data) => reactSelectHandleChange(data, handleChange, 'treatment_type')}
                        />
                        <ErrorFieldWithoutBootstrap errors={errors} fieldName={'treatment_type.value'} />
                      </Col>
                    </Row>
                    <Row className='margin-top-10 text-left'>
                      <Col sm='12'>
                        <p>
                          <strong>Modalidade</strong> Mensalidade/Manutenção
                        </p>
                      </Col>
                    </Row>

                    <Row className='margin-top-10 text-left'>
                      <Col sm='12'>
                        <label>
                          Comprovante<span>*</span>
                        </label>
                      </Col>
                      <Col sm={'12'}>
                        <Select
                          value={values.voucher_type}
                          components={{ Option: CustomOptionVoucher, SingleValue }}
                          isSearchable={true}
                          ignoreAccents={true}
                          ignoreCase={true}
                          options={formLoad.voucherTypes}
                          placeholder={''}
                          styles={reactSelectStyleIsError(errors, 'voucher_type.value', selectStyles)}
                          onChange={(data) => reactSelectHandleChange(data, handleChange, 'voucher_type')}
                        />
                        <ErrorFieldWithoutBootstrap errors={errors} fieldName={'voucher_type.value'} />
                      </Col>
                    </Row>
                    <Row className='margin-top-10'>
                      <Col sm='6'>
                        <label>CPF Paciente</label>
                        <InputMaskComponent
                          guide={false}
                          value={values.cpf_paciente}
                          name={'cpf_paciente'}
                          invalid={errors.cpf_paciente ? true : false}
                          mask={maskCPF()}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col sm='6'>
                        <label>RG Paciente</label>
                        <input
                          className='input-secondary'
                          type='text'
                          name='rg_paciente'
                          onChange={handleChange}
                          value={values.rg_paciente}
                        ></input>
                      </Col>
                    </Row>

                    <hr></hr>

                    <Row className='margin-top-10 text-left'>
                      <Col sm='12'>
                        <label>
                          Responsável<span>*</span>
                        </label>
                      </Col>
                      <Col sm={'12'}>
                        <Select
                          value={values.responsible_type}
                          options={formLoad.responsibleTypes}
                          isSearchable={true}
                          ignoreAccents={true}
                          ignoreCase={true}
                          placeholder={''}
                          styles={reactSelectStyleIsError(errors, 'responsible_type.value', selectStyles)}
                          onChange={(data) => reactSelectHandleChange(data, handleChange, 'responsible_type')}
                        />
                      </Col>
                    </Row>
                    <IfComponent
                      conditional={
                        t(values, 'responsible_type.value').isDefined &&
                        t(values, 'responsible_type.value').safeNumber !== Constants.TIPO_RESPONSAVEL_PACIENTE
                      }
                    >
                      <Row>
                        <Col className='mt-1' sm='12'>
                          <label>
                            Nome Responsável<span>*</span>
                          </label>
                          <Input
                            className='input-secondary'
                            type='text'
                            name='nome_responsavel'
                            onChange={handleChange}
                            value={values.nome_responsavel}
                            invalid={t(validationError, 'nome_responsavel').isDefined}
                          />
                        </Col>
                        <ErrorFieldWithoutBootstrap errors={validationError} fieldName={'nome_responsavel'} />
                      </Row>
                      <Row className='margin-top-10'>
                        <Col sm='6'>
                          <label>
                            CPF Responsável<span>*</span>
                          </label>
                          <InputMaskComponent
                            guide={false}
                            value={values.cpf_responsavel}
                            name={'cpf_responsavel'}
                            invalid={t(validationError, 'cpf_responsavel').isDefined}
                            mask={maskCPF()}
                            onChange={handleChange}
                          />
                          <ErrorFieldWithoutBootstrap errors={validationError} fieldName={'cpf_responsavel'} />
                        </Col>
                        <Col sm='6'>
                          <label>
                            RG Responsável<span>*</span>
                          </label>
                          <Input
                            className='input-secondary'
                            type='text'
                            name='rg_responsavel'
                            onChange={handleChange}
                            value={values.rg_responsavel}
                            invalid={t(validationError, 'cpf_responsavel').isDefined}
                          />
                          <ErrorFieldWithoutBootstrap errors={validationError} fieldName={'rg_responsavel'} />
                        </Col>
                      </Row>
                    </IfComponent>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Row>
                    <Col sm={{ size: 3, offset: 6 }}>
                      <button className='button-primary' type={'submit'}>
                        Salvar
                      </button>
                    </Col>
                    <Col sm='3'>
                      <button className='button-tertiary' type={'button'} onClick={this.closeModal}>
                        Cancelar
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...propsAccountDataSelector(state),
    formLoad: formLoadSelector(state),
    formInit: formInitSelector(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(financialFileAccountModal({ modal: false })),
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader())
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(TreatmentFinancialAccountInfosModal);
