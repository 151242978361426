import React, { Component } from 'react';

import { Container, Row, Col } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from 'react-router-dom';
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { officeHour } from '../redux/ScheduleSettings.actions';
import ScheduleAPI from '../../../../service/ScheduleAPI';
import InputMaskComponent from '../../../../components/InputMask.component';
import { maskTime } from '../../../../utils/Formatter';
import { timeValidate } from '../../../../utils/Validations';
import ErrorMessageComponent from '../../../../components/ErrorMessage.component';
import MessagesComponent from '../../../../components/Messages.component';
import moment from 'moment';

class SettingsScheduleOfficeHoursModal extends Component {
  daysWeek = {
    1: 'Segunda',
    2: 'Terça',
    3: 'Quarta',
    4: 'Quinta',
    5: 'Sexta',
    6: 'Sábado',
    7: 'Domingo'
  };

  state = {
    days: [],
    interval: {
      checked: false,
      start: null,
      end: null
    },
    message: {
      display: false,
      message: ''
    }
  };

  componentWillReceiveProps(nextProps, nextContext) {
    const { initForm: { configs = {}, officeHours = [] } = {} } = nextProps;
    this.setState({
      days: officeHours.map((d) => {
        return {
          ...d,
          checked: d.id ? true : false
        };
      }),
      interval: {
        checked: configs.intervalo_atendimento_inicio ? true : false,
        start: configs.intervalo_atendimento_inicio,
        end: configs.intervalo_atendimento_fim
      }
    });
  }

  checkedDay = (e, day) => {
    const {
      target: { checked }
    } = e;
    const { days } = this.state;
    this.setState({
      days: days.map((d) => {
        let checkedDay = d.checked;
        if (d.dia_semana === day.dia_semana) {
          checkedDay = checked;
        }
        return {
          ...d,
          checked: checkedDay,
          horario_atendimento_inicio: !checkedDay ? '00:00' : d.horario_atendimento_inicio,
          horario_atendimento_fim: !checkedDay ? '00:00' : d.horario_atendimento_fim
        };
      })
    });
  };

  changeTimeStart = (e, day) => {
    const {
      target: { value }
    } = e;
    const { days } = this.state;
    this.setState({
      days: days.map((d) => {
        let newTime = d.horario_atendimento_inicio;
        if (d.dia_semana === day.dia_semana) {
          newTime = value;
        }
        return {
          ...d,
          horario_atendimento_inicio: newTime
        };
      })
    });
  };

  changeTimeEnd = (e, day) => {
    const {
      target: { value }
    } = e;
    const { days } = this.state;
    this.setState({
      days: days.map((d) => {
        let newTime = d.horario_atendimento_fim;
        if (d.dia_semana === day.dia_semana) {
          newTime = value;
        }
        return {
          ...d,
          horario_atendimento_fim: newTime
        };
      })
    });
  };

  changeIntervalCheck = (e) => {
    const {
      target: { checked }
    } = e;
    let { start, end } = this.state.interval;
    if (!checked) {
      start = '00:00';
      end = '00:00';
    }

    this.setState({
      interval: {
        checked,
        start,
        end
      }
    });
  };

  changeIntervalStart = (e) => {
    const {
      target: { value }
    } = e;
    this.setState({
      interval: {
        ...this.state.interval,
        start: value
      }
    });
  };

  changeIntervalEnd = (e) => {
    const {
      target: { value }
    } = e;
    this.setState({
      interval: {
        ...this.state.interval,
        end: value
      }
    });
  };

  saveOfficeHours = async () => {
    const { days, interval } = this.state;
    const {
      dentist,
      initForm: { configs },
      showLoader,
      hideLoader,
      refreshPage,
      closeModal
    } = this.props;

    try {
      const request = days
        .filter((d) => d.checked === true)
        .map((d) => {
          if (!timeValidate(d.horario_atendimento_inicio) || !timeValidate(d.horario_atendimento_fim)) {
            throw Error(
              `Horário de ${this.daysWeek[d.dia_semana]} invalido não é permitido salvar configuração da agenda`
            );
          }

          return {
            id: d.id,
            dia_semana: d.dia_semana,
            horario_atendimento_inicio: d.horario_atendimento_inicio,
            horario_atendimento_fim: d.horario_atendimento_fim
          };
        });

      if (!timeValidate(interval.start) || !timeValidate(interval.end)) {
        throw Error('Intervalo invalido não é permitido salvar configuração da agenda');
      }

      const requestAtendence = {
        id: configs.id,
        intervalo_atendimento_inicio: interval.start,
        intervalo_atendimento_fim: interval.end
      };

      showLoader();
      Promise.all([
        ScheduleAPI.saveOfficeHours({ dentist, data: request }),
        ScheduleAPI.saveAttendenceInterval({ dentist, data: requestAtendence })
      ])
        .then((r) => {
          refreshPage();
          hideLoader();
          closeModal();
        })
        .catch((e) => {
          console.log(e);
          hideLoader();
        });
    } catch (e) {
      console.log(e);
      this.setState({
        message: {
          display: true,
          message: e.message
        }
      });
    }
  };

  render() {
    const { modal, closeModal } = this.props;
    const { days, interval, message } = this.state;

    return (
      <Modal isOpen={modal} toggle={closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={closeModal}
          close={
            <button className='close' onClick={closeModal}>
              &times;
            </button>
          }
        >
          <strong>Editar Horário de Atendimento</strong>
        </ModalHeader>
        <ModalBody>
          <div className='margin-top-10'>
            <Row>
              <Col sm={12}>
                <MessagesComponent display={message.display} type={'danger'} message={message.message} />
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <Row>
                  <Col sm='3'>&nbsp;</Col>

                  <Col sm={8}>
                    <Row>
                      <Col sm='6' style={{ textAlign: 'center' }}>
                        <strong>Início</strong>
                      </Col>

                      <Col sm='6' style={{ textAlign: 'center' }}>
                        <strong>Final</strong>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            {days.map((d) => {
              return (
                <Row key={d.id}>
                  <Col sm='12'>
                    <Row>
                      <Col sm='3'>
                        <div className='absolute-center width100'>
                          <div className='float-right'>
                            <input
                              type='checkbox'
                              className='form-checkbox'
                              id={`check_${d.value}`}
                              checked={d.checked}
                              onClick={(e) => this.checkedDay(e, d)}
                            />
                            <label className='form-checkout-label' htmlFor='check-one'>
                              {this.daysWeek[d.dia_semana]}
                            </label>
                          </div>
                        </div>
                      </Col>

                      <Col sm={8}>
                        <Row>
                          <Col sm='6'>
                            <InputGroup>
                              <InputGroupAddon addonType='prepend'>⊙</InputGroupAddon>
                              <InputMaskComponent
                                readOnly={!d.checked}
                                value={d.horario_atendimento_inicio}
                                name='horario_atendimento_inicio'
                                id={'horario_atendimento_inicio'}
                                mask={maskTime()}
                                invalid={!timeValidate(d.horario_atendimento_inicio)}
                                onChange={(e) => this.changeTimeStart(e, d)}
                                guide={false}
                                className={''}
                              />
                            </InputGroup>
                          </Col>

                          <Col sm='6'>
                            <InputGroup>
                              <InputGroupAddon addonType='prepend'>⊙</InputGroupAddon>
                              <InputMaskComponent
                                readOnly={!d.checked}
                                value={d.horario_atendimento_fim}
                                name='horario_atendimento_fim'
                                id={'horario_atendimento_fim'}
                                mask={maskTime()}
                                invalid={!timeValidate(d.horario_atendimento_fim)}
                                onChange={(e) => this.changeTimeEnd(e, d)}
                                guide={false}
                                className={''}
                              />
                            </InputGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              );
            })}
            <hr></hr>

            <Row className='margin-top-10'>
              <Col sm='12'>
                <Row>
                  <Col sm='3'>
                    <div className='absolute-center width100'>
                      <div className='float-right'>
                        <label className='form-checkout-label' htmlFor='check-one'>
                          Intervalo
                        </label>
                        <input
                          type='checkbox'
                          className='form-checkbox'
                          id='check-one'
                          onClick={this.changeIntervalCheck}
                          checked={interval.checked}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col sm='4'>
                    <InputGroup>
                      <InputGroupAddon addonType='prepend'>⊙</InputGroupAddon>
                      <InputMaskComponent
                        readOnly={!interval.checked}
                        value={interval.start}
                        name='start'
                        id={'start'}
                        mask={maskTime()}
                        invalid={!timeValidate(interval.start || '00:00')}
                        onChange={this.changeIntervalStart}
                        guide={false}
                        className={''}
                      />
                    </InputGroup>
                  </Col>

                  <Col sm='1'>
                    <p className='absolute-center'>às</p>
                  </Col>

                  <Col sm='4'>
                    <InputGroup>
                      <InputGroupAddon addonType='prepend'>⊙</InputGroupAddon>
                      <InputMaskComponent
                        readOnly={!interval.checked}
                        value={interval.end}
                        name='end'
                        id={'end'}
                        mask={maskTime()}
                        invalid={!timeValidate(interval.end || '00:00')}
                        onChange={this.changeIntervalEnd}
                        guide={false}
                        className={''}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col className='btn-list' sm={{ size: 6, offset: 6 }}>
              <button className='button-primary' onClick={this.saveOfficeHours}>
                Salvar
              </button>
              <button className='button-tertiary' onClick={closeModal}>
                Cancelar
              </button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = ({ ScheduleSettingsReducer: { officeHours = {} } = {} }) => {
  return {
    ...officeHours
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(officeHour({ modal: false }))
  };
};

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(SettingsScheduleOfficeHoursModal);
