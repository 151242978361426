import React,{useEffect,useState} from "react";
import Avatar from '../../assets/img/noavatar.png'
import { Col, Row,  Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import '../../assets/css/components/ModalBdayCard.css'
import WhatsappConfirm from '../../assets/img/icons/whatsapp-o.svg'
import WhatsappConfirmBlue from '../../assets/img/icons/whatsappazul.svg'
import Calendar from '../../assets/img/icons/calendar4.svg'
import ReactTooltip from 'react-tooltip';
import ClinicAPI from "../../service/ClinicAPI";

export default function ScheduleModalBdayCard(props){

  const[auxMensagem,setAuxMensagem]= useState(false)

  const onSubmit = () => {
      let Number = props.telefone
      const Nome = props.nomes    
    
      // Appending the phone number to the URL
      let Message = `Olá *${Nome.toUpperCase()}*,\n\n${String.fromCodePoint(0x1F382)} *Feliz Aniversário e muitas Felicidades!*\n\nParabéns! Hoje é seu dia.\nDesejamos que você tenha um dia maravilhoso, na companhia daqueles que ama.\n\nUm grande abraço de toda a nossa equipe!`
  
      // Appending the message to the URL by encoding it
      let Url = `whatsapp://send/?phone=55${Number}&text=${encodeURI(Message.trim())}`;
    
      let Win = window.open(Url);
      Win.focus();
      ClinicAPI.updateMessage(props.id)
      setAuxMensagem(true)
  }

  var Datas = props.datas
  var [Ano] = Datas.split('-')
  const NovaData = props.auxcard+Ano
  
    return(
        <Row className="CardMainBD align-items-center">
          <Col sm={{size:0}} className="p-0">
            {props.image ? 
              <img src={props.image} style={{width:45,height:45}}></img> 
              : 
              <img src={Avatar} style={{width:45,height:45}}></img>
            }  
          </Col>

          <Col sm={{size:0}} className="InfosBD p-0" style={{marginLeft:16}}>
            {props.nomes}
            <br></br>
            {props.idadeAnos} anos
          </Col>

          <Col sm={{size:0}} className='p-0' style={{marginLeft:37,marginBottom:19}}>
            {NovaData}
          </Col>

          <Col sm={{size:0}}>
            {props.meta.has_appointment_today ? 
              <a data-tip='Este paciente tem consulta hoje' data-for='CalendarConfirm-ToolTip'>
                <img src={Calendar} className='CalendarConfirm'/>
              </a> 
              : 
              <div className="BoxAux"/>
            }  
          </Col>

          <Col sm={{size:0}}>
            <button className="BtnZap" onClick={onSubmit}>
              {(props.meta.message_sent || auxMensagem ) ?
                <a data-tip='Mensagem Enviada' data-for='WhatsappBlue-ToollTip'>
                  <img src={WhatsappConfirmBlue} className='WhatsappBlue'/>
                </a>
                : 
                <a data-tip='Enviar Mensagem' data-for='WhatsappConfirmBD-ToollTip'>
                  <img src={WhatsappConfirm} className='WhatsappConfirmBD'/>
                </a>
              }
            </button>
          </Col>
          
          <ReactTooltip className='tooltip-griks' id='CalendarConfirm-ToolTip' place='top' type='light'/>
          <ReactTooltip className='tooltip-griks' id='WhatsappConfirmBD-ToollTip' place='right' type='light'/>
          <ReactTooltip className='tooltip-griks' id='WhatsappBlue-ToollTip' place='right' type='light'/>
        </Row>
    )
}
