import { call, put } from 'redux-saga/effects';
import { treatmentEvaluationReceptionRegister } from '../redux/TreatmentEvaluation.action';
import DomainsAPI from '../../../../service/DomainsAPI';

export function* modalEvaluationReceptionRegister({ payload: { modal, formLoad = {} } }) {
  const reasons = yield call(DomainsAPI.findAllReasons);
  const data = {
    modal,
    formLoad: {
      ...formLoad,
      reasons
    }
  };

  yield put(treatmentEvaluationReceptionRegister(data));
}
