import { apiAccessToken } from '../../localStorage/SessionStorageUtils';
import * as moment from 'moment-timezone';

const accessTokenInRequestInterceptor = (axios) => {
  axios.interceptors.request.use(
    function (config) {
      config.headers = {
        Authorization: apiAccessToken(),
        'timezone-client': moment.tz.guess()
      };

      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
};

export default accessTokenInRequestInterceptor;
