import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import Select from 'react-select';
import { reactSelectHandleChange } from '../../../../utils/ReactSelectUtils';
import { selectStyles } from '../../../../config/core.config';
import MonthPicker from '../../../../components/MonthPicker';
import InputComponent from '../../../../components/InputComponent';
import { Form, Formik } from 'formik';
import SelectComponent from '../../../../components/SelectComponent';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../../../components/actions/Loader.actions';
import FormikUtils from '../../../../utils/FormikUtils';
import t from 'typy';
import TreatmentClinicalRecordApi from '../../../../service/TreatmentClinicalRecordApi';
import ToastUtils from '../../../../utils/ToastUtils';
import * as Yup from 'yup';

const TreatmentClincalRecordTimelineModal = ({ updateParent }, modalRef) => {
  const formValidate = Yup.object().shape({
    // ritimo: Yup.object().shape({
    //   value: Yup.string().required('Ritmo de Tratamento obrigatório')
    // })
  });
  const dispatch = useDispatch();
  const [alterarTimeline, setAlterarTimeline] = useState(false);
  const [fichaClinica, setFichaClinica] = useState({});
  const [tratamento, setTratamento] = useState({});
  const [formInit, setFormInit] = useState({});
  const options = [
    { value: 'DP', label: 'Dentro do Previsto' },
    { value: 'UA', label: 'Um Pouco Atrasado' },
    { value: 'DA', label: 'Definitivamente Atrasado' },
    { value: 'AD', label: 'Adiantado' }
  ];
  useImperativeHandle(modalRef, () => ({
    showModal(fichaClinica, tratamento, timeline) {
      const { sumario } = fichaClinica;

      setFichaClinica(fichaClinica);
      setTratamento(tratamento);
      setAlterarTimeline(true);

      let tempoPrevisto = timeline.tempoPrevistoTratamento
        ? timeline.tempoPrevistoTratamento
        : t(sumario, 'tempo_tratamento_ate').isDefined
        ? t(sumario, 'tempo_tratamento_ate').safeNumber
        : t(sumario, 'tempo_tratamento_de').safeNumber;
      if (tempoPrevisto == 0) {
        tempoPrevisto = '';
      }

      setFormInit({
        ...timeline,
        inicio: timeline.inicio && moment(timeline.inicio),
        previsaoConclusao: timeline.previsaoConclusao && moment(timeline.previsaoConclusao),
        previsaoCorrigida: timeline.previsaoCorrigida && moment(timeline.previsaoCorrigida),
        finalTratamento: timeline.finalTratamento && moment(timeline.finalTratamento),
        ritimo: options.filter((r) => r.value === t(timeline, 'ritimo.id').safeString)[0],
        tempoPrevistoTratamento: tempoPrevisto
      });
      console.log(timeline['inicio']);
    }
  }));

  async function salvarTimeline(values) {
    try {
      dispatch(showLoader());
      const request = {
        ...values,
        inicio: values.inicio && values.inicio.format('YYYY-MM-DD'),
        previsaoConclusao: values.previsaoConclusao && values.previsaoConclusao.format('YYYY-MM-DD'),
        previsaoCorrigida: values.previsaoCorrigida && values.previsaoCorrigida.format('YYYY-MM-DD'),
        finalTratamento: values.finalTratamento && values.finalTratamento.format('YYYY-MM-DD'),
        ritimo: values.ritimo.value
      };
      await TreatmentClinicalRecordApi.saveTimeline(tratamento.id, fichaClinica.id, request);
      setAlterarTimeline(false);
      updateParent();
      dispatch(hideLoader());
    } catch (e) {
      dispatch(hideLoader());
      ToastUtils.error('Erro ao salvar Timeline entre em contato com o suporte');
      console.log(e);
    }
  }

  function recalc(inicio, finalTratamento, { tempoPrevistoTratamento }, handleChange) {
    if (inicio) {
      if (tempoPrevistoTratamento > 0) {
        const previsao = moment(inicio).add(tempoPrevistoTratamento, 'M');
        FormikUtils.setValueField(handleChange, 'previsaoConclusao', previsao);
        // FormikUtils.setValueField(handleChange, 'previsaoCorrigida', previsao);
      }

      if (finalTratamento) {
        const tempo = Math.ceil(moment(finalTratamento).diff(inicio, 'months', true));
        FormikUtils.setValueField(handleChange, 'tempoFinalTratamento', tempo);
        FormikUtils.setValueField(handleChange, 'tempoDiferenca', tempo - (tempoPrevistoTratamento || 0));
      }
    }
  }

  return (
    <Modal isOpen={alterarTimeline} toggle={() => setAlterarTimeline(false)} size={'small'}>
      <ModalHeader
        className='title-primary'
        toggle={() => setAlterarTimeline(false)}
        close={
          <button className='close' onClick={() => setAlterarTimeline(false)}>
            &times;
          </button>
        }
      >
        <strong>Editar Timeline do Tratamento</strong>
      </ModalHeader>
      <Formik
        onSubmit={salvarTimeline}
        initialValues={formInit}
        enableReinitialize={true}
        validationSchema={formValidate}
        validateOnChange={false}
      >
        {({ values, errors, handleChange }) => {
          return (
            <Form>
              <ModalBody>
                <div>
                  <Row>
                    <Col sm='6'>
                      <form>
                        <label className={'w-100'}>Início do Tratamento</label>
                        <MonthPicker
                          anoInicial={10}
                          valueHowMoment={true}
                          name={'inicio'}
                          handleChange={handleChange}
                          values={values}
                          callbackOnChange={(value) => recalc(value, values.finalTratamento, values, handleChange)}
                        />
                      </form>
                    </Col>
                    <Col sm='6'>
                      <form>
                        <label className={'w-100'}>Tempo Previsto de Tratamento</label>
                        <InputComponent
                          name={'tempoPrevistoTratamento'}
                          mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                          guide={false}
                          handler={async (e) => {
                            const {
                              target: { value }
                            } = e;
                            if (values.tempoFinalTratamento) {
                              FormikUtils.setValueField(
                                handleChange,
                                'tempoDiferenca',
                                values.tempoFinalTratamento - (parseInt(value) || 0)
                              );
                            }

                            recalc(
                              values.inicio,
                              values.finalTratamento,
                              { tempoPrevistoTratamento: value },
                              handleChange
                            );
                            await handleChange(e);
                          }}
                          values={values}
                        />
                      </form>
                    </Col>
                  </Row>
                  <Row className='margin-top-10'>
                    <Col sm='6'>
                      <form>
                        <label className={'w-100'}>Previsão de Conclusão</label>
                        <MonthPicker
                          anoInicial={10}
                          valueHowMoment={true}
                          name={'previsaoConclusao'}
                          handleChange={handleChange}
                          values={values}
                        />
                      </form>
                    </Col>
                    <Col sm='6'>
                      <form>
                        <label className={'w-100'}>Previsão Corrigida</label>
                        <MonthPicker
                          anoInicial={10}
                          valueHowMoment={true}
                          name={'previsaoCorrigida'}
                          handleChange={handleChange}
                          values={values}
                        />
                      </form>
                    </Col>
                  </Row>
                  <Row className='margin-top-10'>
                    <Col sm='12'>
                      <label className={'w-100'}>Ritmo de Tratamento*</label>
                      <SelectComponent
                        name={'ritimo'}
                        options={options}
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                        placeholder={'Selecione'}
                      />
                    </Col>
                  </Row>
                  <Row className='margin-top-20'>
                    <Col sm='4'>
                      <form>
                        <label className={'w-100'}>Final do Tratamento</label>
                        <MonthPicker
                          anoInicial={10}
                          valueHowMoment={true}
                          name={'finalTratamento'}
                          handleChange={handleChange}
                          values={values}
                          callbackOnChange={(value) => recalc(values.inicio, value, values, handleChange)}
                        />
                      </form>
                    </Col>
                    <Col sm='4'>
                      <form>
                        <label className={'w-100'}>Tempo Final (meses)</label>
                        <InputComponent
                          handler={handleChange}
                          name={'tempoFinalTratamento'}
                          values={values}
                          disabled={true}
                          errors={errors}
                        />
                      </form>
                    </Col>
                    <Col sm='4'>
                      <form>
                        <label className={'w-100'}>Diferença (meses)</label>
                        <InputComponent
                          handler={handleChange}
                          name={'tempoDiferenca'}
                          values={values}
                          disabled={true}
                          errors={errors}
                        />
                      </form>
                    </Col>
                  </Row>
                </div>
              </ModalBody>
              <ModalFooter>
                <Row>
                  <Col className='btn-list' sm={{ size: 6, offset: 6 }}>
                    <button className='button-primary' type='submit'>
                      Salvar
                    </button>
                    <button className='button-tertiary' type='button' onClick={() => setAlterarTimeline(false)}>
                      Cancelar
                    </button>
                  </Col>
                </Row>
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default forwardRef(TreatmentClincalRecordTimelineModal);
