import React, { Component } from 'react';
import { Col, Container, Row, Table } from 'reactstrap';
import noavatar from '../../../assets/img/noavatar.png';
import { AiOutlineSortAscending } from 'react-icons/ai';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { t } from 'typy';
import { patientnOverviewStateToProps } from './store/PatientOverviewReportSelectors';
import DomainsAPI from '../../../service/DomainsAPI';
import { hideLoader, showLoader } from '../../../components/actions/Loader.actions';
import { arrayToSelectItems, reactSelectHandleChange } from '../../../utils/ReactSelectUtils';
import MultiSelect from '@khanacademy/react-multi-select';
import _ from 'lodash';
import { Form, Formik } from 'formik';
import {
  findPatientOverviewToReportOverview,
  infinitScroll,
  sendToResultPatientReportOverview
} from './store/PatientReportStore';
import { IfComponent } from '../../../components/if.component';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import PacienteAPI from '../../../service/PacienteAPI';
import PatinentViewModal from '../../patinent/components/PatientViewModal.component';
import AppointmentHistoryModal from '../../schedule/modal/AppointmentHistory.modal';

class PatientOverviewReportPage extends Component {
  state = {
    displayDetailModal: false,
    patientData: {},
    loadMore: false,
    currentPage: 1
  };

  _findPatients = ({ dentistas, situacoes, order }) => {
    const { findPatients, clearPage } = this.props;
    clearPage({ patients: [] });
    this.setState({ currentPage: 1 });
    findPatients({
      dentistas,
      situacoes,
      orderBy: order,
      showLoader: true
    });
  };

  componentWillUnmount() {
    this.props.clearPage({ patients: [] });
  }

  closeModalDetail = () => {
    this.setState({ displayDetailModal: false });
  };

  selectPatientAndShowModal = (p) => {
    const { showLoader, hideLoader } = this.props;

    showLoader();
    PacienteAPI.findById(p.id)
      .then((data) => {
        hideLoader();
        this.setState({
          displayDetailModal: true,
          patientData: data
        });
      })
      .catch((error) => {
        hideLoader();
        this.props.sendMessage({
          messageDisplay: true,
          type: 'danger',
          message: `Erro ao buscar dados do pacientes (${error.message})`
        });
      });
  };

  //Infinit Scroll
  _loadMore = (e, { dentistas, situacoes, order }) => {
    if (e.currentTarget.scrollTop + e.currentTarget.clientHeight === e.currentTarget.scrollHeight) {
      const { patientPages, findPatients, loaderInfinitScroll, patientResult, showLoadMore } = this.props;
      const { currentPage } = this.state;
      if (!showLoadMore && patientPages > currentPage) {
        const nextPage = currentPage + 1;
        this.setState({ currentPage: nextPage });
        loaderInfinitScroll({ loaderMoreResult: true });
        findPatients({
          patients: patientResult,
          dentistas,
          situacoes,
          orderBy: order,
          page: nextPage
        });

        // this.scro = e.currentTarget;
        //
        // setTimeout(() => {
        //   let a = this.props.patientResult;
        //   a.push(...this.props.patientResult.map(p => {
        //     p.id = p.id + 2000;
        //     return p;
        //   }));
        //   this.props.clearPage({ patients: a });
        //   this.setState({ loadMore: false });
        //   console.log(this.scro.scrollTop);
        //   this.scro.scrollTo(0, this.scro.scrollTop + 20);
        // }, 2000);
      }
    }
  };

  render() {
    const { patientData, displayDetailModal } = this.state;
    const { dentistsOptions, situationsOptions, patientResult, patientTotal, showLoadMore } = this.props;
    console.log(patientResult);
    return (
      <div className='box-default report-box-default' style={{ minHeight: '60vh' }}>
        <Row>
          <Col sm={12}>
            <strong className='title-medium-plus text-uppercase'>Relação Geral de Pacientes</strong>
          </Col>
        </Row>
        <Formik
          initialValues={{
            order: 'A'
          }}
          onSubmit={this._findPatients}
        >
          {({ values, errors, handleChange, isSubmitting, resetForm, submitForm, dirty }) => {
            return (
              <Form>
                <Container>
                  <Row>
                    <Col sm={{ size: 3 }}>
                      <div style={{ marginLeft: 0, marginTop: 10, marginBottom: 10 }}>
                        <span className='report-doctor'>
                          <span className='report-doctor-select'>
                            <MultiSelect
                              options={dentistsOptions}
                              selected={t(values.dentistas).safeArray}
                              onSelectedChanged={(selected) =>
                                reactSelectHandleChange(selected, handleChange, 'dentistas')
                              }
                              disableSearch={true}
                              overrideStrings={{
                                selectSomeItems: 'Doutor(a)',
                                allItemsAreSelected: 'Todos os dentistas',
                                selectAll: 'Selecionar Todos',
                                search: 'Buscar'
                              }}
                            />
                          </span>
                        </span>
                      </div>
                    </Col>
                    <Col sm={{ size: 3 }} className='ml-2'>
                      <div style={{ marginLeft: 0, marginTop: 10, marginBottom: 10 }}>
                        <span className='report-doctor'>
                          <span className='report-doctor-select'>
                            <MultiSelect
                              options={situationsOptions}
                              selected={t(values.situacoes).safeArray}
                              disableSearch={true}
                              onSelectedChanged={(selected) =>
                                reactSelectHandleChange(selected, handleChange, 'situacoes')
                              }
                              overrideStrings={{
                                selectSomeItems: 'Situação do Tratamento',
                                allItemsAreSelected: 'Todas as situações',
                                selectAll: 'Selecionar Todas',
                                search: 'Buscar'
                              }}
                            />
                          </span>
                        </span>
                      </div>
                    </Col>
                    <Col sm={{ size: 2 }} className='ml-2'>
                      <button
                        style={{ marginTop: 9, width: 105, height: 35 }}
                        className='btn btn-secondary-l5 text-sm border border-secondary-l4 float-left'
                      >
                        {t(values.dentistas).safeArray.length > 0 || t(values, 'situacoes').safeArray.length > 0
                          ? 'Filtrar'
                          : 'Exibir Todos'}
                      </button>
                    </Col>
                    <Col sm={{ size: 2 }}>
                      <IfComponent
                        conditional={
                          t(values.dentistas).safeArray.length > 0 || t(values, 'situacoes').safeArray.length > 0
                        }
                      >
                        <button
                          style={{ marginTop: 9, width: 105, height: 35, marginLeft: '-72px' }}
                          className='btn btn-outline-restartfilter text-sm font-light float-left'
                          onClick={() => {
                            resetForm({ order: 'A' });
                            setTimeout(submitForm, 10);
                          }}
                        >
                          Limpar Filtro
                        </button>
                      </IfComponent>
                    </Col>
                    <Col sm={1}>
                      <span
                        style={{
                          marginTop: 9,
                          backgroundColor: '#f3f4f6',
                          marginLeft: 90,
                          width: 105,
                          height: 35,
                          borderRadius: '0.25rem'
                        }}
                        className='bg border border-secondary-l4 font-bold text-center float-left text-sm'
                      >
                        <p className='p-2 text-secondary-d4'>Total: &nbsp; {patientTotal}</p>
                      </span>
                    </Col>
                  </Row>
                </Container>
                <Table className='table-header-fixed p-2' responsive>
                  <thead>
                    <tr className='row py-2'>
                      <th className='col-sm-3 py-0'>
                        <a
                          className='text-sm text-secondary-d4 font-weight-bold'
                          style={{ textDecoration: 'none' }}
                          href={'javascript:'}
                          onClick={() => {
                            reactSelectHandleChange(values.order === 'A' ? 'D' : 'A', handleChange, 'order');
                            setTimeout(submitForm, 0);
                          }}
                        >
                          {' '}
                          Paciente{' '}
                        </a>
                        <IfComponent conditional={values.order === 'A'}>
                          <MdKeyboardArrowDown size={'21'} />
                        </IfComponent>
                        <IfComponent conditional={values.order === 'D'}>
                          <MdKeyboardArrowUp size={'21'} />
                        </IfComponent>
                      </th>
                      <th className='col-sm-1 py-0'>
                        <span className='text-sm text-secondary-d4 font-weight-bold text-nowrap'>Número</span>
                      </th>
                      <th className='col-sm-2 py-0'>
                        <span className='text-sm text-secondary-d4 font-weight-bold pl-3'>Doutor</span>
                      </th>
                      <th className='col-sm-2 py-0'>
                        <span className='text-sm text-secondary-d4 font-weight-bold'>Especialidade</span>
                      </th>
                      <th className='col-sm-4 py-0'>
                        <span className='text-sm text-secondary-d4 font-weight-bold'>Situação do Tratamento</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ maxHeight: 'calc(100vh - 400px)' }} onScroll={(e) => this._loadMore(e, values)}>
                    {patientResult.map((p) => (
                      <tr
                        className={'row py-0 box-basic px-0 report-overview-result-tr'}
                        key={`patient_result_${p.id}`}
                      >
                        <td className='col-sm-3'>
                          <Row className='pb-0'>
                            <Col sm={2}>
                              <div>
                                <img
                                  onClick={() => this.selectPatientAndShowModal(p)}
                                  className={'img-user-avatar img-xxldpi cursor-pointer'}
                                  src={p.foto || noavatar}
                                  alt='usuario'
                                />
                              </div>
                            </Col>
                            <Col sm={10} className='p-0'>
                              <div className='title-medium pl-4'>
                                <p className='text-sm font-bold text-secondary'>{p.nome}</p>
                                <p className='text-sm font-light text-secondary'>{p.idade}</p>
                              </div>
                            </Col>
                          </Row>
                        </td>
                        <td className='col-sm-1' valign={'middle'}>
                          <div className='text-sm text-secondary h-100'>{p.numeroIdentificacao}</div>
                        </td>
                        <td className='col-sm-2'>
                          {t(p, 'dentista').safeArray.map((d) => {
                            return <div className='text-sm font-light pl-3 text-secondary'>{d.nome}</div>;
                          })}
                        </td>
                        <td className='col-sm-2'>
                          {t(p, 'especialidade').safeArray.map((e) => {
                            return <div className='text-sm font-light text-secondary'>{e.descricao}</div>;
                          })}
                        </td>
                        <td className='col-sm-4'>
                          {t(p, 'status').safeArray.map((s) => {
                            return <div className='text-sm font-light text-secondary'>{s.descricao}</div>;
                          })}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <IfComponent conditional={showLoadMore}>
                  <tr>
                    <td colSpan={5}>Carregando ....</td>
                  </tr>
                </IfComponent>
              </Form>
            );
          }}
        </Formik>
        <PatinentViewModal
          {...this.props}
          showDetailModal={displayDetailModal}
          patientData={patientData}
          closeModal={this.closeModalDetail}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return patientnOverviewStateToProps(state);
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  findPatients: (data) => dispatch(findPatientOverviewToReportOverview(data)),
  clearPage: (data) => dispatch(sendToResultPatientReportOverview(data)),
  loaderInfinitScroll: (data) => dispatch(infinitScroll(data))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(PatientOverviewReportPage);
