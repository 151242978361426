import { toast } from 'react-toastify';

export default class ToastUtils {
  static error(message, props = { autoClose: false, position: 'top-center' }) {
    toast.error(message, {
      className: 'toast-error',
      bodyClassName: 'toast-error-body',
      progressClassName: 'toast-error-progress',
      ...props
    });
  }

  static info(message, props = { autoClose: 5000, position: 'top-center' }) {
    toast.info(message, { ...props });
  }

  static warn(message, props = { autoClose: 5000, position: 'top-center' }) {
    toast.warn(message, { ...props });
  }

  static success(message, props = { autoClose: 5000, position: 'top-center' }) {
    toast(message, {
      className: 'toast-success',
      bodyClassName: 'toast-success-body',
      progressClassName: 'toast-success-progress',
      ...props
    });
  }

  static time(message, props = { autoClose: false, position: 'top-center' }) {
    toast(message, {
      className: 'toast-time',
      bodyClassName: 'toast-time-body',
      progressClassName: 'toast-time-progress',
      ...props
    });
  }

  static trial(message, props = { autoClose: false, position: 'top-center' }) {
    toast(message, {
      className: 'toast-trial',
      bodyClassName: 'toast-trial-body',
      progressClassName: 'toast-trial-progress',
      ...props
    });
  }
}
