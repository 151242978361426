import RestTemplate from '../utils/restTemplate/rest.template';

class TreatmentEvaluationApi {
  static findEvaluationByTreatmentId = (id) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/tratamento/${id}/avaliacao`);
  };

  static createEvaluationTreatment = (treatmentId, date) => {
    const restTemplate = new RestTemplate(true);

    const request = {
      tratamento_id: treatmentId,
      data_avaliacao: date
    };

    return restTemplate.post('/tratamento/avaliacao/create', request);
  };

  static saveReceptionAndRegister = ({
    id,
    tratamento,
    motivo_consulta,
    acompanhante,
    acompanhante_parentesco,
    utiliza_aparelho,
    descricao_utiliza_aparelho,
    usou_aparelho,
    descricao_usou_aparelho,
    anotacoes
  }) => {
    const restTemplate = new RestTemplate(true);

    const request = {
      id,
      tratamento,
      motivo_consulta,
      acompanhante,
      acompanhante_parentesco,
      utiliza_aparelho,
      descricao_utiliza_aparelho,
      usou_aparelho,
      descricao_usou_aparelho,
      anotacoes
    };

    return restTemplate.post('/tratamento/avaliacao/', request);
  };

  static findComplaintByTreatmentId = (id) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/tratamento/${id}/queixa`);
  };

  static saveComplaint = ({ id, avaliacaoId, interessePacienteId, queixas }) => {
    const restTemplate = new RestTemplate(true);

    const complaintRQ = {
      avaliacao: avaliacaoId,
      interesse_paciente: interessePacienteId,
      queixas: queixas.map((q) => ({
        queixa: q.queixaId
      }))
    };

    if (id) {
      complaintRQ.id = id;
      return restTemplate.put('/tratamento/avaliacao/queixa-principal', complaintRQ);
    } else {
      return restTemplate.post('/tratamento/avaliacao/queixa-principal', complaintRQ);
    }
  };

  static findAngleRatingByTreatmentId = (id) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/tratamento/avaliacao/${id}/classificacao-angle`);
  };

  static saveAngleRating = ({
    id,
    avaliacao,
    classificacao_angle,
    dente6_direita,
    dente3_direita,
    dente3_esquerda,
    dente6_esquerda,
    classe_esqueletica,
    padrao_esqueletico
  }) => {
    const request = {
      id,
      avaliacao,
      classificacao_angle,
      dente6_direita,
      dente3_direita,
      dente3_esquerda,
      dente6_esquerda,
      classe_esqueletica,
      padrao_esqueletico
    };

    const restTemplate = new RestTemplate(true);
    return restTemplate.post('/tratamento/avaliacao/classificacao-angle', request);
  };

  static findListIncidentsByTreatmentId = (id) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/tratamento/avaliacao/${id}/problemas-diagnosticos`);
  };

  static saveIncidentsByTretamentId = (id, data) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.put(`/tratamento/avaliacao/${id}/problemas-diagnosticos`, data);
  };

  static findEvaluationResultByTreatmentId = (id) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/tratamento/avaliacao/${id}/resultado-avaliacao`);
  };

  static saveEvaluationResult = ({
    id,
    avaliacao,
    resultado_avaliacao,
    complexidade_avaliacao,
    interesse_clinica,
    aparelho
  }) => {
    const request = {
      id,
      avaliacao,
      resultado_avaliacao,
      complexidade_avaliacao,
      interesse_clinica,
      aparelho
    };

    const restTemplate = new RestTemplate(true);
    return restTemplate.post('/tratamento/avaliacao/resultado-avaliacao', request);
  };

  static findEvaluationDocumentation = (id) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/tratamento/avaliacao/${id}/documentacao-ortodontica`);
  };

  static findEvaluationOutrasAnotacoes = (id) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/tratamento/avaliacao/${id}/outras-anotacoes`);
  };

  static findEvaluationAttendenceRegister = (id) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/tratamento/avaliacao/${id}/registros-atendimento`);
  };

  static deleteEvaluation = (id) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.delete(`/tratamento/avaliacao/${id}`);
  };

  static saveEvaluationDocs = ({ avaliacao, documentos_existentes, documentos_solicitados }) => {
    const request = {
      avaliacao,
      documentos_existentes,
      documentos_solicitados
    };
    const restTemplate = new RestTemplate(true);
    return restTemplate.put('/tratamento/avaliacao/documentacao-ortodontica', request);
  };

  static saveOtherAnnotations = ({ avaliacao, observacoes }) => {
    const request = {
      avaliacao: parseInt(avaliacao),
      observacoes
    };
    const restTemplate = new RestTemplate(true);
    return restTemplate.put('/tratamento/avaliacao/outras-anotacoes', request);
  };

  static saveAttendenceRegister = ({ id, avaliacao, data_atendimento, anotacao, proxima_consulta, dentista }) => {
    const request = {
      id,
      avaliacao,
      data_atendimento,
      anotacao,
      proxima_consulta,
      dentista
    };
    const restTemplate = new RestTemplate(true);
    return restTemplate.post('/tratamento/avaliacao/registro-atendimento', request);
  };

  static deleteAttendenceRegister = (id) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.delete(`/tratamento/avaliacao/registro-atendimento/${id}`);
  };
}

export default TreatmentEvaluationApi;
