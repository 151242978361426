import t from 'typy';
import { createSelector } from 'reselect';

const diagnosticOtherInfosModalSelector = (state) =>
  t(state, 'TreatmentClinicalRecordReducer.diagnosticOtherInfosModal').safeObject;
const diagnosticModalSelector = (state) =>
  t(state, 'TreatmentClinicalRecordReducer.diagnosticOtherInfosModal.diagnostic').safeObject;

export const propsModalSelector = createSelector(
  diagnosticOtherInfosModalSelector,
  (diagnosticOtherInfosModal = {}) => {
    const { modal, diagnostic = {}, treatment, record } = diagnosticOtherInfosModal;

    return {
      modal,
      diagnostic,
      treatment,
      record
    };
  }
);

export const formInitSelector = createSelector(diagnosticModalSelector, (diagnostic = {}) => {
  const { fator_risco, limitacoes_compromissos, outras_anotacoes } = diagnostic;

  const formInit = {
    fator_risco,
    limitacoes_compromissos,
    outras_anotacoes
  };

  return formInit;
});
