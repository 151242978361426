import React, { Component } from 'react';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import edit from '../../../assets/img/icons/edit.svg';
import Icon from '../../../components/Icon.component';
import Select from 'react-select';
import { IfComponent } from '../../../components/if.component';
import { reactSelectHandleChange, reactSelectStyleIsError } from '../../../utils/ReactSelectUtils';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import ProceduresAPI from '../../../service/ProceduresAPI';
import { selectStyles } from '../../../../src/config/core.config';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  sendToAppointmentRedialFormInit,
  sendToAppointmentRedialModalToProps
} from '../selectors/SendToAppointmentAndRedialModal.selector';
import { showLoader, hideLoader } from '../../../components/actions/Loader.actions';
import { scheduleAppointmentDetailModal, sendToAppointmentAndRedial } from '../redux/Schedule.actions';
import ErrorFieldWithoutBootstrap from '../../../components/ErrorFieldWithoutBootstrap.component';
import { t } from 'typy';
import ScheduleAPI from '../../../service/ScheduleAPI';
import moment from 'moment';
import MessagesComponent from '../../../components/Messages.component';

class SendToAppointmentAndRedialModal extends Component {
  defaultState = {
    changeProcedure: false,
    procedures: [],
    message: {
      display: false,
      type: '',
      text: ''
    }
  };

  state = {
    ...this.defaultState
  };

  validateFormSchema = Yup.object().shape({
    procedure: Yup.object().shape({
      value: Yup.number().required('Procedimento Obrigatório')
    }),
    reason: Yup.object().shape({
      value: Yup.string().required('Motivo Obrigatório')
    })
  });

  closeModal = () => {
    const { closeModal } = this.props;
    this.setState({ ...this.defaultState });
    closeModal();
  };

  findProcedures = (group) => {
    const { showLoader, hideLoader } = this.props;
    showLoader();
    ProceduresAPI.getAllProcedures(group.id, true)
      .then(({ data }) => {
        const procedures = data.map((p) => ({
          label: p.nome,
          value: p.id,
          data: p
        }));
        this.setState({ procedures });
        hideLoader();
      })
      .catch((c) => {
        hideLoader();
        this.setState({
          message: {
            display: true,
            type: 'danger',
            text: 'Erro ao buscar procedimentos entre em contato com o suporte'
          }
        });
      });
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.state.procedures.length === 0) {
      this.setState({
        procedures: nextProps.procedures
      });
    }

    return true;
  }

  sendToAppointmentAndRedialSave = (values) => {
    const { schedule, showLoader, hideLoader, closeModal, closeModalDetail, updateParent } = this.props;

    showLoader();
    ScheduleAPI.sendToAppointmentAndRedial({
      id: schedule.id,
      procedure: values.procedure.data.id,
      reason: values.reason.value,
      obs: values.obs
    })
      .then(({ data }) => {
        hideLoader();
        closeModal();
        closeModalDetail();
        updateParent(moment(schedule.data_agendamento));
      })
      .catch((err) => {
        hideLoader();
        this.setState({
          message: {
            display: true,
            type: 'danger',
            text: 'Erro ao enviar consulta para lista de agendar / reagendar entre em contato com o suporte'
          }
        });
      });
  };

  render() {
    const { changeProcedure, procedures, message } = this.state;
    const { modal, groups, reasons, formInit, schedule } = this.props;

    return (
      <Modal isOpen={modal} toggle={this.closeModal} className={this.props.className} size='default'>
        <ModalHeader
          className='title-primary'
          toggle={this.closeModal}
          close={
            <button className='close' onClick={this.closeModal}>
              &times;
            </button>
          }
        >
          <strong>Enviar para a Lista AGENDAR / REAGENDAR</strong>
          {/* { t(schedule, 'paciente.nome').safeString } */}
        </ModalHeader>
        <Formik
          validationSchema={this.validateFormSchema}
          validateOnChange={false}
          initialValues={formInit}
          onSubmit={this.sendToAppointmentAndRedialSave}
        >
          {({ errors, values, handleChange }) => (
            <Form>
              <ModalBody>
                {/*<div className="margin-top-10">*/}
                {/*  <Row>*/}
                {/*    <Col sm="12">*/}
                {/*      <p>*/}
                {/*        <strong>INSERIR PACIENTE NA LISTA </strong>*/}
                {/*        <span className="procedure-primary" style={ {*/}
                {/*          backgroundColor: '#fff',*/}
                {/*          border: 1,*/}
                {/*          borderStyle: 'solid',*/}
                {/*          borderColor: '#F18257',*/}
                {/*          color: '#F18257'*/}
                {/*        } }>Agendar/Reagendar</span>*/}
                {/*      </p>*/}
                {/*    </Col>*/}
                {/*  </Row>*/}
                {/*</div>*/}
                <div className='margin-top-10'>
                  <Row>
                    <Col sm='12'>
                      <p
                        className='text-default text-md text-left
                      '
                      >
                        <strong>Paciente </strong>
                        <span>&nbsp;{t(schedule, 'paciente.nome').safeString}</span>
                      </p>
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col sm='12'>
                      <label>
                        Motivo<span>*</span>
                      </label>
                    </Col>
                    <Col sm='12'>
                      <Select
                        value={values.reason}
                        options={reasons}
                        onChange={(p) => reactSelectHandleChange(p, handleChange, 'reason')}
                        styles={reactSelectStyleIsError(errors, 'reason.value', selectStyles)}
                        placeholder=''
                      />
                      <ErrorFieldWithoutBootstrap fieldName={'reason.value'} errors={errors} />
                    </Col>
                  </Row>
                </div>
                <div className='margin-top-10'>
                  <MessagesComponent display={message.display} type={message.type} message={message.text} />
                  <IfComponent conditional={!changeProcedure}>
                    <Row className='margin-top-10 mb-0'>
                      <Col sm='12'>
                        <p className='text-left'>
                          <strong>Procedimento </strong>
                          <span
                            className='procedure-primary badge-proc ml-2 mr-1'
                            style={{
                              backgroundColor: t(values, 'group.data.rgb').safeString,
                              color: '#000'
                            }}
                          >
                            {t(values, 'procedure.data.codinome').safeString}
                          </span>
                          <a href={'#'} onClick={() => this.setState({ changeProcedure: !changeProcedure })}>
                            <Icon className='icon icon-size-small' image={edit} alt='edit' />
                          </a>
                        </p>
                      </Col>
                    </Row>
                  </IfComponent>
                  <IfComponent conditional={changeProcedure}>
                    <Row className='margin-top-10'>
                      <Col sm='6'>
                        <label>
                          Grupo<span>*</span>
                        </label>
                      </Col>
                      <Col sm='6'>
                        <label>
                          Procedimento <span>*</span>
                        </label>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm='6'>
                        <Select
                          styles={selectStyles}
                          value={values.group}
                          options={groups}
                          onChange={(c) => {
                            reactSelectHandleChange(c, handleChange, 'group');
                            reactSelectHandleChange({}, handleChange, 'procedure');
                            this.findProcedures(c.data);
                          }}
                        />
                      </Col>
                      <Col sm='6'>
                        <Select
                          value={values.procedure}
                          options={procedures}
                          onChange={(p) => reactSelectHandleChange(p, handleChange, 'procedure')}
                          styles={reactSelectStyleIsError(errors, 'procedure.value', selectStyles)}
                        />
                        <ErrorFieldWithoutBootstrap fieldName={'procedure.value'} errors={errors} />
                      </Col>
                    </Row>
                  </IfComponent>
                </div>
                <Row>
                  <Col sm='12'>
                    <form>
                      <label>Observações</label>
                      <textarea className='form-control' rows='1' cols='1' name={'obs'} onChange={handleChange} />
                    </form>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Row>
                  <Col className='btn-list' sm={{ size: 6, offset: 6 }}>
                    <button className='button-primary'>Salvar</button>
                    <button className='button-tertiary' onClick={this.closeModal}>
                      Cancelar
                    </button>
                  </Col>
                </Row>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...sendToAppointmentRedialModalToProps(state),
    formInit: sendToAppointmentRedialFormInit(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  closeModal: () => dispatch(sendToAppointmentAndRedial({ modal: false })),
  closeModalDetail: () => dispatch(scheduleAppointmentDetailModal({ modal: false }))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(SendToAppointmentAndRedialModal);
