import { Col, Row, Table } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import AffiliatesApi from '../../service/AffiliatesApi';
import { getAffiliate } from '../../utils/localStorage/SessionStorageUtils';
import { formatReal } from '../../utils/Formatter';

const AffiliatesCoupon = () => {
  const [affiliate, setAffiliate] = useState(getAffiliate());
  const [coupons, setCoupons] = useState([]);

  useEffect(() => {
    AffiliatesApi.findCupons(affiliate.id).then(setCoupons);
  }, []);

  return (
    <div className='box-default report-box-default' style={{ minHeight: '60vh' }}>
      <Row>
        <Col sm={12}>
          <strong className='title-medium-plus text-uppercase'>Cupons disponíveis</strong>
        </Col>
      </Row>
      <Table>
        <thead>
          <tr>
            <th>Cupom</th>
            <th>Desconto disponível</th>
            <th>Tempo de desconto</th>
            <th>Comissão</th>
            <th>Tempo de Comissão</th>
          </tr>
        </thead>
        <tbody>
          {coupons.map((c) => {
            return (
              <tr>
                <td>{c.numero_cupom}</td>
                <td>{c.tipo_cupom === 'PORCENTAGEM' ? `${c.valor_cupom * 100}%` : formatReal(c.valor_cupom)}</td>
                <td>
                  {c.tempo_cupom} {c.tempo_cupom > 1 ? 'meses' : 'mês'}
                </td>
                <td>
                  {c.tipo_comissao === 'PORCENTAGEM' ? `${c.valor_comissao * 100}%` : formatReal(c.valor_comissao)}
                </td>
                <td>
                  {c.tempo_comissao} {c.tempo_comissao > 1 ? 'meses' : 'mês'}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default AffiliatesCoupon;
