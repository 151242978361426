import React, { Component } from 'react';
import { Col, Container, Row, Table, Card, CardText, CardBody, CardSubtitle } from 'reactstrap';
import people from '../../assets/img/icons/people.svg';
import receipt from '../../assets/img/icons/receipt.svg';
import Icon from '../../components/Icon.component';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { t } from 'typy';
import PatientScheduleAnotherMonth from './frequency/PatientScheduleAnotherMonth';
import { IfComponent } from '../../components/if.component';
import { showLoader, hideLoader } from '../../components/actions/Loader.actions';
import FrequencyOrthodonticsAPI from '../../service/FrequencyOrthodonticsAPI';
import ScheduleAPI from '../../service/ScheduleAPI';
import ErrorMessageComponent from '../../components/ErrorMessage.component';
import PatientScheduleScheduleAndRedial from './frequency/PatientScheduleScheduleAndRedial';
import PatientFDS from './frequency/PatientFDS';
import { withRouter } from 'react-router';
import { scheduleOrthodonticsFrequencyPrePage } from './redux/Schedule.actions';
import NumberFormat from 'react-number-format';
import PercentLabelComponent from '../../components/PercentLabelComponent';
import { FiFileText } from 'react-icons/fi';
import { IoIosPerson } from 'react-icons/io';
import Tooltip from 'react-tooltip';
import question from '../../assets/img/icons/question.svg';
import { IoIosHelpCircle } from 'react-icons/io';
import OrthodonticsFrequencyHelpModal from './modal/OrthodonticsFrequencyHelp.modal';

class OrthodonticsFrequencyPage extends Component {
  state = {
    typeSelected: '',
    openFrequencyHelpModal: false,
    data: [],
    error: {
      display: false,
      message: ''
    }
  };

  closeModal = () => {
    this.setState({ openFrequencyHelpModal: false });
  };

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  percenteCalculate(total, calculate) {
    return (calculate * 100) / total;
  }

  _findScheduleInAnotherMonth = async () => {
    const { showLoader, hideLoader, dentist } = this.props;
    showLoader();
    try {
      const schedules = await FrequencyOrthodonticsAPI.findFrequencyScheduleAnotherMonth({ dentist: dentist.value });

      this.setState({
        typeSelected: 'PAM',
        data: schedules.data || []
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: {
          display: true,
          message: 'Erro ao buscar pacientes agendados em outro mês'
        }
      });
    }

    hideLoader();
  };

  _findScheduleRedial = async () => {
    const { showLoader, hideLoader, dentist, updatePage } = this.props;
    showLoader();
    try {
      const scheduleRedial = await FrequencyOrthodonticsAPI.getScheduleRedial({ dentist: dentist.value });
      this.setState({
        typeSelected: 'PAR',
        data: scheduleRedial.data || [],
        backgroundColor: '#fff'
      });
      updatePage({ dentist });
    } catch (e) {
      console.log(e);
      this.setState({
        error: {
          display: true,
          message: 'Erro ao buscar pacientes agendados em outro mes'
        }
      });
    }

    hideLoader();
  };

  _findFDS = async () => {
    const { showLoader, hideLoader, dentist, updatePage } = this.props;
    showLoader();
    try {
      const scheduleRedial = await FrequencyOrthodonticsAPI.findFrequencyFDS({ dentist: dentist.value });
      this.setState({
        typeSelected: 'FDS',
        data: scheduleRedial.data || []
      });
      updatePage({ dentist });
    } catch (e) {
      console.log(e);
      this.setState({
        error: {
          display: true,
          message: 'Erro ao buscar pacientes agendados em outro mes'
        }
      });
    }

    hideLoader();
  };

  render() {
    const { typeSelected, data, error = {}, openFrequencyHelpModal } = this.state;
    const { dentist = {}, frequency = {} } = this.props;

    return (
      <div className={'content-box-without-color orthodonticsFrequency'} key={'home'}>
        <Row>
          <Col sm={12}>
            <div className='box-default' style={{ marginTop: '-20px' }}>
              <Container>
                <Row className='margin-top-9'>
                  <Col sm='12'>
                    <strong className='title-medium-plus text-uppercase' style={{ fontSize: '18px' }}>
                      GPS ORTODONTIA - PACIENTES EM "TRATAMENTO ATIVO"
                    </strong>
                    <p className='text-secondary text-md-plus font-medium'>
                      Controle de Frequência Mensal
                      <a
                        data-tip='Clique para saber mais'
                        className='ml-2'
                        onClick={() => {
                          this.setState({ openFrequencyHelpModal: true });
                        }}
                      >
                        <IoIosHelpCircle className='mb-1' size={20} style={{ cursor: 'help' }} />
                      </a>
                      <Tooltip className={'tooltip-griks'} placement='top' />
                    </p>
                    <OrthodonticsFrequencyHelpModal modal={openFrequencyHelpModal} closeModal={this.closeModal} />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <ErrorMessageComponent display={error.display} type={'error'} message={error.message} />
                  </Col>
                </Row>
                <hr style={{ marginTop: '-2px' }} />
                <Row>
                  <Col
                    sm={'auto'}
                    style={{
                      borderRightWidth: 1,
                      borderRightStyle: 'solid',
                      borderRightColor: '#f2f2f2',
                      marginLeft: '-7px',
                      marginTop: '-12px',
                      marginRight: '10px'
                    }}
                  >
                    <Row>
                      <Col>
                        <span className='text-secondary font-medium text-md-plus'>PACIENTES EM TRATAMENTO ATIVO</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ marginTop: '-6px' }}>
                        <span className={'title-primary font-bold text-secondary'} style={{ fontSize: '24px' }}>
                          {frequency.totalPacienteAtivos}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <Table responsive>
                          <tbody>
                            {/*<tr>*/}
                            {/*  <td style={{ marginLeft: '-10px' }}>*/}
                            {/*    <Row style={{ marginBottom: '-15px' }}>*/}
                            {/*      <Col sm={1}>*/}
                            {/*        <IoIosPerson*/}
                            {/*          className='icon-jaatendidos'*/}
                            {/*          style={{ width: '46px', height: '46px', marginTop: '-2px' }}*/}
                            {/*          alt='people'*/}
                            {/*        />*/}
                            {/*      </Col>*/}
                            {/*      <Col style={{ marginLeft: '-2px' }}>*/}
                            {/*        <Row>*/}
                            {/*          <Col>*/}
                            {/*            <span className='text-secondary'>JÁ ATENDIDOS</span>*/}
                            {/*          </Col>*/}
                            {/*        </Row>*/}
                            {/*        <Row>*/}
                            {/*          <Col style={{ marginTop: '-9px' }}>*/}
                            {/*            <span*/}
                            {/*              className={'title-primary font-bold text-secondary'}*/}
                            {/*              style={{ fontSize: '21px' }}*/}
                            {/*            >*/}
                            {/*              {frequency.totalJaAtendidos}&nbsp;*/}
                            {/*            </span>{' '}*/}
                            {/*            <PercentLabelComponent*/}
                            {/*              percent={this.percenteCalculate(*/}
                            {/*                frequency.totalPacienteAtivos,*/}
                            {/*                frequency.totalAgendarReagenda*/}
                            {/*              )}*/}
                            {/*              style={{ fontSize: '15px', color: '#434c5e' }}*/}
                            {/*            />*/}
                            {/*          </Col>*/}
                            {/*        </Row>*/}
                            {/*      </Col>*/}
                            {/*    </Row>*/}
                            {/*  </td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                            {/*  <td style={{ marginLeft: '-10px' }}>*/}
                            {/*    <Row style={{ marginBottom: '-15px' }}>*/}
                            {/*      <Col sm={1}>*/}
                            {/*        <IoIosPerson*/}
                            {/*          className='icon-agendados-este-mes'*/}
                            {/*          style={{ width: '46px', height: '46px', marginTop: '-2px' }}*/}
                            {/*          alt='people'*/}
                            {/*        />*/}
                            {/*      </Col>*/}
                            {/*      <Col style={{ marginLeft: '-2px' }}>*/}
                            {/*        <Row>*/}
                            {/*          <Col>AGENDADOS ESTE MÊS</Col>*/}
                            {/*        </Row>*/}
                            {/*        <Row>*/}
                            {/*          <Col style={{ marginTop: '-9px' }}>*/}
                            {/*            <span*/}
                            {/*              className={'title-primary font-bold text-secondary'}*/}
                            {/*              style={{ fontSize: '21px' }}*/}
                            {/*            >*/}
                            {/*              {frequency.totalAgendadosEsteMes}&nbsp;*/}
                            {/*            </span>*/}
                            {/*            {'  '}*/}
                            {/*            <PercentLabelComponent*/}
                            {/*              percent={this.percenteCalculate(*/}
                            {/*                frequency.totalPacienteAtivos,*/}
                            {/*                frequency.totalAgendadosEsteMes*/}
                            {/*              )}*/}
                            {/*              style={{ fontSize: '15px', color: '#434c5e' }}*/}
                            {/*            />*/}
                            {/*          </Col>*/}
                            {/*        </Row>*/}
                            {/*      </Col>*/}
                            {/*    </Row>*/}
                            {/*  </td>*/}
                            {/*</tr>*/}
                            {/*<tr*/}
                            {/*  className={`link ${typeSelected === 'PAM' ? 'bg-primary-l5' : ''}`}*/}
                            {/*  onClick={this._findScheduleInAnotherMonth}*/}
                            {/*>*/}
                            {/*  <td style={{ marginLeft: '-10px' }}>*/}
                            {/*    <Row style={{ marginBottom: '-15px' }}>*/}
                            {/*      <Col sm={1}>*/}
                            {/*        <IoIosPerson*/}
                            {/*          className='icon-agendados-outro-mes'*/}
                            {/*          style={{ width: '46px', height: '46px', marginTop: '-2px' }}*/}
                            {/*          alt='people'*/}
                            {/*        />*/}
                            {/*      </Col>*/}
                            {/*      <Col>*/}
                            {/*        <Row>*/}
                            {/*          <Col sm={10} style={{ marginLeft: '-2px' }}>*/}
                            {/*            <Row>*/}
                            {/*              <Col>*/}
                            {/*                <span className='text-nowrap text-secondary'>AGENDADOS EM OUTRO MÊS</span>*/}
                            {/*              </Col>*/}
                            {/*            </Row>*/}
                            {/*            <Row>*/}
                            {/*              <Col style={{ marginTop: '-9px' }}>*/}
                            {/*                <span*/}
                            {/*                  className={'title-primary font-bold text-secondary'}*/}
                            {/*                  style={{ fontSize: '21px' }}*/}
                            {/*                >*/}
                            {/*                  {frequency.totalAgendadosOutroMes}&nbsp;*/}
                            {/*                </span>*/}
                            {/*                {'  '}*/}
                            {/*                <PercentLabelComponent*/}
                            {/*                  percent={this.percenteCalculate(*/}
                            {/*                    frequency.totalPacienteAtivos,*/}
                            {/*                    frequency.totalAgendadosOutroMes*/}
                            {/*                  )}*/}
                            {/*                  style={{ fontSize: '15px', color: '#434c5e' }}*/}
                            {/*                />*/}
                            {/*              </Col>*/}
                            {/*            </Row>*/}
                            {/*          </Col>*/}
                            {/*          <Col>*/}
                            {/*            <FiFileText*/}
                            {/*              className='vertical-center'*/}
                            {/*              size={'24'}*/}
                            {/*              color={'#343a40'}*/}
                            {/*              stroke-width={'1'}*/}
                            {/*              alt='receipt'*/}
                            {/*            />*/}
                            {/*          </Col>*/}
                            {/*        </Row>*/}
                            {/*      </Col>*/}
                            {/*    </Row>*/}
                            {/*  </td>*/}
                            {/*</tr>*/}
                            {/*<tr*/}
                            {/*  className={`link ${typeSelected === 'PAR' ? 'bg-primary-l5' : ''}`}*/}
                            {/*  onClick={this._findScheduleRedial}*/}
                            {/*>*/}
                            {/*  <td style={{ marginLeft: '-10px' }}>*/}
                            {/*    <Row style={{ marginBottom: '-15px' }}>*/}
                            {/*      <Col sm={1}>*/}
                            {/*        <IoIosPerson*/}
                            {/*          className='icon-agendar-reagendar'*/}
                            {/*          style={{ width: '46px', height: '46px', marginTop: '-2px' }}*/}
                            {/*          alt='people'*/}
                            {/*        />*/}
                            {/*      </Col>*/}
                            {/*      <Col>*/}
                            {/*        <Row>*/}
                            {/*          <Col sm={10} style={{ marginLeft: '-2px' }}>*/}
                            {/*            <Row>*/}
                            {/*              <Col>*/}
                            {/*                <span className='text-nowrap text-secondary'>*/}
                            {/*                  NA LISTA AGENDAR / REAGENDAR*/}
                            {/*                </span>*/}
                            {/*              </Col>*/}
                            {/*            </Row>*/}
                            {/*            <Row>*/}
                            {/*              <Col style={{ marginTop: '-9px' }}>*/}
                            {/*                <span*/}
                            {/*                  className={'title-primary font-bold text-secondary'}*/}
                            {/*                  style={{ fontSize: '21px' }}*/}
                            {/*                >*/}
                            {/*                  {frequency.totalAgendarReagenda}&nbsp;*/}
                            {/*                </span>*/}
                            {/*                {'  '}*/}
                            {/*                <NumberFormat*/}
                            {/*                  value={this.percenteCalculate(*/}
                            {/*                    frequency.totalPacienteAtivos,*/}
                            {/*                    frequency.totalAgendarReagenda*/}
                            {/*                  )}*/}
                            {/*                  displayType={'text'}*/}
                            {/*                  style={{ fontSize: '15px', color: '#434c5e' }}*/}
                            {/*                  decimalScale={3}*/}
                            {/*                  allowLeadingZeros={true}*/}
                            {/*                  fixedDecimalScale={true}*/}
                            {/*                  format={*/}
                            {/*                    this.percenteCalculate(*/}
                            {/*                      frequency.totalPacienteAtivos,*/}
                            {/*                      frequency.totalAgendarReagenda*/}
                            {/*                    ) === 100*/}
                            {/*                      ? '###'*/}
                            {/*                      : '##'*/}
                            {/*                  }*/}
                            {/*                />*/}
                            {/*                %*/}
                            {/*              </Col>*/}
                            {/*            </Row>*/}
                            {/*          </Col>*/}
                            {/*          <Col>*/}
                            {/*            <FiFileText*/}
                            {/*              className='vertical-center'*/}
                            {/*              size={'24'}*/}
                            {/*              color={'#343a40'}*/}
                            {/*              stroke-width={'1'}*/}
                            {/*              alt='receipt'*/}
                            {/*            />*/}
                            {/*          </Col>*/}
                            {/*        </Row>*/}
                            {/*      </Col>*/}
                            {/*    </Row>*/}
                            {/*  </td>*/}
                            {/*</tr>*/}
                            <tr
                              className={`link ${typeSelected === 'FDS' ? 'bg-primary-l5' : ''}`}
                              onClick={this._findFDS}
                            >
                              <td style={{ marginLeft: '-10px' }}>
                                <Row style={{ marginBottom: '-15px' }}>
                                  <Col sm={1}>
                                    <IoIosPerson
                                      className='icon-fds'
                                      style={{ width: '46px', height: '46px', marginTop: '-2px' }}
                                      alt='people'
                                    />
                                  </Col>
                                  <Col>
                                    <Row>
                                      <Col sm={10} style={{ marginLeft: '-2px' }}>
                                        <Row>
                                          <Col>
                                            <span className='text-secondary'>FDS - FORA DO SISTEMA</span>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col style={{ marginTop: '-9px' }}>
                                            <span
                                              className={'title-primary font-bold text-secondary'}
                                              style={{ fontSize: '21px' }}
                                            >
                                              {frequency.totalPacienteFDS}&nbsp;
                                            </span>
                                            {'  '}
                                            <NumberFormat
                                              value={this.percenteCalculate(
                                                frequency.totalPacienteAtivos,
                                                frequency.totalPacienteFDS
                                              )}
                                              displayType={'text'}
                                              style={{ fontSize: '15px', color: '#434c5e' }}
                                              decimalScale={3}
                                              allowLeadingZeros={true}
                                              fixedDecimalScale={true}
                                              format={
                                                this.percenteCalculate(
                                                  frequency.totalPacienteAtivos,
                                                  frequency.totalPacienteFDS
                                                ) === 100
                                                  ? '###'
                                                  : '##'
                                              }
                                            />
                                            %
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Col>
                                        <FiFileText
                                          className='vertical-center'
                                          size={'24'}
                                          color={'#343a40'}
                                          stroke-width={'1'}
                                          alt='receipt'
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </td>
                            </tr>
                          </tbody>
                          <hr style={{ marginTop: '-2px' }} />
                        </Table>
                      </Col>
                    </Row>
                  </Col>
                  <Col style={{ marginRight: '-60px' }}>
                    <IfComponent conditional={typeSelected === 'PAM'}>
                      <PatientScheduleAnotherMonth
                        total={frequency.totalAgendadosOutroMes}
                        percent={this.percenteCalculate(
                          frequency.totalPacienteAtivos,
                          frequency.totalAgendadosOutroMes
                        )}
                        patients={data}
                      />
                    </IfComponent>

                    <IfComponent conditional={typeSelected === 'PAR'}>
                      <PatientScheduleScheduleAndRedial
                        {...this.props}
                        total={frequency.totalAgendarReagenda}
                        callbackUpdate={this._findScheduleRedial}
                        percent={this.percenteCalculate(frequency.totalPacienteAtivos, frequency.totalAgendarReagenda)}
                        patients={data}
                      />
                    </IfComponent>

                    <IfComponent conditional={typeSelected === 'FDS'}>
                      <PatientFDS
                        total={frequency.totalPacienteFDS}
                        percent={this.percenteCalculate(frequency.totalPacienteAtivos, frequency.totalPacienteFDS)}
                        patients={data}
                        dentist={dentist}
                        updateParent={this._findFDS}
                      />
                    </IfComponent>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { ScheduleReducer } = state;

  return {
    ...ScheduleReducer.scheduleFrequency
  };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  updatePage: (data) => dispatch(scheduleOrthodonticsFrequencyPrePage(data))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withRouter);

export default enhanced(OrthodonticsFrequencyPage);
