export const CONTACTS_FIND_ALL = 'CONTACTS_FIND_ALL';
export const CONTACTS_RESULT = 'CONTACTS_RESULT';
export const CONTACT_DETAIL_MODAL = 'CONTACT_DETAIL_MODAL';
export const CONTACT_SAVE_MODAL = 'CONTACT_SAVE_MODAL';
export const CONTACT_SAVE_SAGA = 'CONTACT_SAVE_SAGA';
export const CONTACT_EDIT_SAGA = 'CONTACT_EDIT_SAGA';

export const findAllContacts = (data) => {
  return {
    type: CONTACTS_FIND_ALL,
    data
  };
};

export const contactsDetail = ({ open, contact }) => {
  return {
    type: CONTACT_DETAIL_MODAL,
    contactDetail: {
      openModal: open,
      data: contact
    }
  };
};

export const contactsEdit = (contact) => {
  return {
    type: CONTACT_EDIT_SAGA,
    contactEdit: {
      data: contact
    }
  };
};

export const contactsShowHideSave = ({ open, contact }) => {
  return {
    type: CONTACT_SAVE_MODAL,
    contactModalSave: {
      openModal: open,
      data: contact
    }
  };
};

export const contactsSave = (data) => {
  return {
    type: CONTACT_SAVE_SAGA,
    contactSave: {
      data
    }
  };
};
