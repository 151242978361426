import React, { Component } from 'react';
import { Col, Container, Row, Table } from 'reactstrap';
import edit from '../../../assets/img/icons/edit.svg';
import down from '../../../assets/img/icons/arrow.svg';
import up from '../../../assets/img/icons/arrowup.svg';
import Icon from '../../../components/Icon.component';
import '../../../assets/css/components/TreatmentFinancialFileDebitsPage.css';
import exit from '../../../assets/img/icons/exit.svg';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { propsDebitsSelector } from './selectors/FinancialFileDebitsPage.selector';
import { dateFormat, formatReal } from '../../../utils/Formatter';
import { t } from 'typy';
import TreatmentFinancialDebitsModal from './modals/TreatmentFinancialDebits.modal';
import { financialDebitsModal, financialDebitsPage } from './redux/FinancialFile.action';
import question from '../../../assets/img/icons/question.svg';
import ReactTooltip from 'react-tooltip';
import { IfComponent } from '../../../components/if.component';
import TreatmentFinancialFileApi from '../../../service/TreatmentFinancialFileApi';
import { toast } from 'react-toastify';
import { hideLoader, showLoader } from '../../../components/actions/Loader.actions';
import TreatmentClinicalRecordAntecipatePaymentConfirmModal from '../clinicalRecord/modals/TreatmentClinicalRecordAntecipatePaymentConfirm.modal';
import { clinicalRecordTreatmentAnticipateModal } from '../clinicalRecord/redux/TreatmentClinicalRecord.action';
import { PERMISSOES } from '../../../config/security/permissoes';
import withSecurity from '../../../config/security/security';
import ModalConfirmNegotiation from './modals/ModalConfirmNegotiation';

class TreatmentFinancialFileDebitsPage extends Component {
  state = {
    expandFinancial: false,
    openModalConfirmNegotiation: false
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (nextProps.expandFinancial !== this.props.expandFinancial) {
      this.setState({
        expandFinancial: nextProps.expandFinancial
      });
    }
    return true;
  }

  aproveNegotiation = () => {
    const { treatment, financialFile, showLoader, hideLoader, updateParent } = this.props;
    this._closeModalConfirmNegotiation();
    showLoader();
    TreatmentFinancialFileApi.aproveNegotiation({
      treatment: treatment,
      financialFile: financialFile
    })
      .then(() => {
        hideLoader();
        updateParent();
      })
      .catch((e) => {
        hideLoader();
        toast.error('Erro ao aprovar negociação entre em contato com o suporte', {
          autoClose: false,
          position: 'top-center'
        });
      });
  };

  anticipatePayment = (debit) => {
    const { treatment, financialFile, anticipateModal } = this.props;

    anticipateModal({
      modal: true,
      treatment,
      record: financialFile,
      debit
    });
  };

  render() {
    const { openModalConfirmNegotiation } = this.state;
    const {
      debits = [],
      updateParent,
      debitsModal,
      treatment,
      financialFile,
      finalizedTrading,
      security,
      expandDebits,
      data
    } = this.props;
    const financialFilePermission = PERMISSOES.modules.tratamento_ficha_financeira;

    const hasBasePermition =
      security.hasPermission(financialFilePermission.id, financialFilePermission.permissoes.editar) ||
      security.hasPermission(financialFilePermission.id, financialFilePermission.permissoes.editar_restricao.id);
    return [
      <IfComponent conditional={debits.length > 0} key={'page'}>
        <Row className={'w-100 mt-3'}>
          <Col sm='12'>
            <div className='box-default'>
              <Row>
                <Col sm='12'>
                  <h2 className='title-medium-plus text-uppercase font-bold text-secondary mb-3'>
                    Distribuição Detalhada dos Débitos
                    <IfComponent conditional={hasBasePermition}>
                      <a
                        href={'#'}
                        onClick={() => {
                          debitsModal({
                            modal: true,
                            treatment,
                            financialFile,
                            debits
                          });
                        }}
                      >
                        <Icon className='icon icon-size-small margin-left-10' image={edit} alt='edit' />
                      </a>
                    </IfComponent>
                    <a
                      style={{ marginTop: -6 }}
                      className={'float-right'}
                      href={'#'}
                      onClick={() => {
                        this.props.expandDebitsBox({
                          treatment: data.treatment,
                          financialFile: data.financialFile,
                          expandDebits: !expandDebits
                        });
                        // this.setState({ expandFinancial: !expandFinancial });
                      }}
                    >
                      <Icon
                        className='icon icon-size-two margin-left-10 icon-grey-rounded'
                        image={!expandDebits ? down : up}
                        alt='Expandir'
                      />
                    </a>
                  </h2>
                  <IfComponent conditional={expandDebits}>
                    <div className={' table-financial'}>
                      <Table responsive>
                        <thead>
                          <tr className={'row pb-0'}>
                            <th className={'col-sm-2'}>
                              Descrição
                              <Icon
                                className='icon icon-size-small ml-2'
                                image={question}
                                alt='question'
                                data-tip
                                data-for={'questionDesc'}
                              />
                              <ReactTooltip id={'questionDesc'} place='right' type='light' effect='solid'>
                                <Container>
                                  <Row>
                                    <Col sm={12} className={'title-tertiary'}>
                                      Legenda
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={12}>
                                      PR_FCH <span className='font-light'> - Preço Fechado</span>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={12}>
                                      ENTR_AP <span className='font-light'>- Entrada / Aparelho</span>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={12}>
                                      MENSL <span className='font-light'>- Mensalidade / Manutenção</span>
                                    </Col>
                                  </Row>
                                </Container>
                              </ReactTooltip>
                            </th>
                            <th className={'col-sm-2'}>Vencimento</th>
                            <th className={'col-sm-3'}>Status</th>
                            <th className={'col-sm-2'}>Valor</th>
                            <th width='25%' className='text-center col-sm-3'>
                              Antecipar Lançamento
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {debits.map((d) => {
                            return (
                              <tr className={'row pb-0'} key={d.id}>
                                <td className={'col-sm-2'}>
                                  {d.nome} {d.parcela} / {d.total_parcela}
                                </td>
                                <td className={'col-sm-2'}>{dateFormat(d.vencimento, 'DD/MM/YYYY')}</td>
                                <td className={'col-sm-3'}>
                                  <strong className={`debits-status-${t(d, 'status.id').safeString}`}>
                                    {t(d, 'status.descricao').safeString}
                                  </strong>
                                </td>
                                <td className={'col-sm-2'}>{formatReal(d.valor)}</td>
                                <td className='text-center col-sm-3' style={{ paddingTop: 4.5, paddingBottom: 4.5 }}>
                                  <IfComponent
                                    conditional={
                                      finalizedTrading &&
                                      finalizedTrading === 'S' &&
                                      t(d, 'status.id').safeString === 'F' &&
                                      hasBasePermition
                                    }
                                  >
                                    <button
                                      className='btn btn-secondary-l5 py-0 border border-secondary-l4'
                                      onClick={() => this.anticipatePayment(d)}
                                    >
                                      <Icon
                                        className='icon-dark icon-size-smaller-minus'
                                        style={{ marginTop: -3 }}
                                        image={exit}
                                        alt='edit'
                                      />
                                      <span className={'ml-2'} style={{ fontSize: 11 }}>
                                        Lançar
                                      </span>
                                    </button>
                                  </IfComponent>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </IfComponent>
                </Col>
              </Row>
              <IfComponent conditional={expandDebits && (!finalizedTrading || finalizedTrading === 'N')}>
                <Row>
                  <Col sm={{ size: 3, offset: 9 }} className={'text-right'}>
                    <button className='margin-left-10 button-primary' onClick={this._openModalConfirmNegotiation}>
                      Fechar Negociação
                    </button>
                  </Col>
                </Row>
              </IfComponent>
            </div>
          </Col>
        </Row>
      </IfComponent>,
      <TreatmentFinancialDebitsModal key={'TreatmentFinancialDebitsModal'} updateParent={updateParent} />,
      <TreatmentClinicalRecordAntecipatePaymentConfirmModal
        key={'TreatmentClinicalRecordAntecipatePaymentConfirmModal'}
        updateParent={updateParent}
      />,
      <ModalConfirmNegotiation
        key={'ModalConfirmNegotiation'}
        closeModal={this._closeModalConfirmNegotiation}
        openModal={openModalConfirmNegotiation}
        confirmCallback={this.aproveNegotiation}
      />
    ];
  }

  _closeModalConfirmNegotiation = () => {
    this.setState({
      openModalConfirmNegotiation: false
    });
  };

  _openModalConfirmNegotiation = () => {
    this.setState({
      openModalConfirmNegotiation: true
    });
  };
}

const mapStateToProps = (state) => {
  return {
    ...propsDebitsSelector(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  debitsModal: (data) => dispatch(financialDebitsModal(data)),
  anticipateModal: (data) => dispatch(clinicalRecordTreatmentAnticipateModal(data)),
  expandDebitsBox: (data) => dispatch(financialDebitsPage(data))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withSecurity);

export default enhanced(TreatmentFinancialFileDebitsPage);
