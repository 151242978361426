import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import Select from 'react-select';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap';
import { RadioButton, RadioGroup } from 'react-radio-buttons';
import { compose } from 'recompose';
import { t } from 'typy';
import noavatar from '../../../assets/img/noavatar.png';
import edit from '../../../assets/img/icons/edit.svg';
import { hideLoader, showLoader } from '../../../components/actions/Loader.actions';
import Icon from '../../../components/Icon.component';

import { IfComponent } from '../../../components/if.component';
import MessagesComponent from '../../../components/Messages.component';
import ProceduresAPI from '../../../service/ProceduresAPI';
import ScheduleAPI from '../../../service/ScheduleAPI';
import { dateFormat, phoneFormat } from '../../../utils/Formatter';
import { reactSelectHandleChange, reactSelectStyleIsError } from '../../../utils/ReactSelectUtils';
import { scheduleAppointmentPatientModal } from '../redux/Schedule.actions';
import {
  scheduleAppointmentFinalStepForm,
  scheduleAppointmentFinalStepReducerToProps
} from '../selectors/ScheduleAppointmentFinalStepModal.selector';
import { closeToast } from '../TostPatient.component';
import ErrorFieldWithoutBootstrap from '../../../components/ErrorFieldWithoutBootstrap.component';
import { selectStyles } from '../../../../src/config/core.config';
import danger from '../../../assets/img/icons/danger1.svg';
import { toast, ToastContainer } from 'react-toastify';
import ToastCustomMessageComponent from '../../../components/ToastCustomMessage.component';
import ToastUtils from '../../../utils/ToastUtils';

class ScheduleAppointmentModal extends Component {
  errors = [];

  state = {
    showNotes: false,
    changeProcedure: false,
    showPendence: false,
    procedures: [],
    message: {
      display: false,
      text: ''
    }
  };

  closeModal = () => {
    this.setState({
      changeProcedure: false,
      showPendence: false,
      showNotes: false,
      procedures: [],
      message: {
        display: false,
        text: ''
      }
    });
    this.props.closeModal();
  };

  componentDidMount() {}

  findProcedures = (category) => {
    this.props.showLoader();
    ProceduresAPI.getAllProcedures(category.id, true)
      .then((proceduresData) => {
        const procedures = proceduresData.data || [];

        this.setState({
          procedures: procedures.map((p) => ({
            id: p.id,
            label: p.nome,
            data: p
          }))
        });

        this.props.hideLoader();
      })
      .catch((e) => {
        console.error(e);
        this.setState({
          procedures: [],
          message: {
            display: true,
            text: 'Erro ao buscar procedimentos entre em contato com o suporte'
          }
        });
        this.props.hideLoader();
      });
  };

  saveAppointment = (values) => {
    console.log(values);
    const {
      showLoader,
      hideLoader,
      updateParent,
      closeModal,
      chair,
      date,
      start,
      end,
      patient,
      treatment = {},
      redialBy,
      onSave,
      controleRetorno
    } = this.props;

    showLoader();

    if (t(values, 'procedure.data.id').isDefined) {
      ScheduleAPI.allowSchedule({
        scheduleId: values.id,
        chair: chair.id,
        date: date,
        start: start.format('HH:mm'),
        end: this.endTimeSchedule(start, end, values.procedimento_tempo).format('HH:mm')
      }).then(({ data }) => {
        if (data.allow) {
          ScheduleAPI.saveSchedule({
            id: !redialBy ? values.id : null,
            chair: chair.id,
            patient: patient.paciente_id,
            date: date.format('YYYY-MM-DD'),
            start: start.format('HH:mm'),
            end: end.format('HH:mm'),
            procedure: values.procedure.data.id,
            pendence: JSON.parse(values.pendencia),
            annotation: values.anotations,
            pendenceAnnotation: values.pendencia_obs,
            treatment: treatment.id ? treatment.id : null,
            controleRetorno
          })
            .then((d) => {
              if (redialBy) {
                ScheduleAPI.appointmentRedial({
                  id: values.id,
                  redialBy: redialBy
                })
                  .then((d) => {
                    this.closeModal();
                    updateParent(date);
                    hideLoader();
                    closeToast();
                  })
                  .catch((err) => {
                    hideLoader();
                    this.setState({
                      error: {
                        display: true,
                        type: 'danger',
                        message: 'Erro ao remarcar consulta entre em contato com o suporte'
                      }
                    });
                  });
              } else {
                this.closeModal();
                updateParent(date);
                hideLoader();
                if (onSave) {
                  onSave();
                }
              }
              closeToast();
            })
            .catch((error) => {
              console.log(error);
              this.setState({
                message: {
                  display: true,
                  text: 'Erro ao salvar agendamento de consulta entre em contato com o suporte'
                }
              });
              hideLoader();
            });
        } else {
          ToastUtils.trial(
            <ToastCustomMessageComponent
              iconImage={danger}
              title={'Espaço insuficiente para este Procedimento !'}
              message={'O tempo do procedimento invade o horário de outra consulta já agendada.'}
            />,
            {
              autoClose: 8000,
              hideProgressBar: true,
              position: 'top-center'
            }
          );
          hideLoader();
        }
      });
    } else {
      this.errors['procedure'] = 'Por favor selecione um procedimento ';
      this.setState({ changeProcedure: true });
      hideLoader();
    }
  };

  UNSAFE_componentWillUpdate(nextProps, nextContext) {
    if (JSON.stringify(nextProps.proceduresOptions) !== JSON.stringify(this.props.proceduresOptions)) {
      this.setState({
        procedures: nextProps.proceduresOptions
      });
    }
  }

  endTimeSchedule = (start, end, procedureTime) => {
    if (procedureTime) {
      return moment(start).add(procedureTime, 'm');
    } else {
      if (end) {
        return moment(end);
      } else {
        return moment(start);
      }
    }
  };

  _clearSelectProcedure = (handleChange) => {
    const { changeProcedure } = this.state;

    this.findProcedures(this.props.formInit.category.data);

    this.setState({
      changeProcedure: !changeProcedure
    });
  };

  _selectProcedure = (p, handleChange) => {
    const { data } = p;

    reactSelectHandleChange(p, handleChange, 'procedure');

    reactSelectHandleChange(data.tempo, handleChange, 'procedimento_tempo');
    reactSelectHandleChange(data.codinome, handleChange, 'procedimento_codinome');
    reactSelectHandleChange(data.categoria.rgb, handleChange, 'procedimento_categoria_rgb');
    reactSelectHandleChange(data.nome, handleChange, 'procedimento_nome');

    if (data.pendencia_laboratorio === 'S') {
      reactSelectHandleChange('true', handleChange, 'pendencia');
      reactSelectHandleChange(data.lembrete, handleChange, 'pendencia_obs');
    } else {
      reactSelectHandleChange('false', handleChange, 'pendencia');
      reactSelectHandleChange('', handleChange, 'pendencia_obs');
    }

    console.log(data);
  };

  render() {
    const {
      modal,
      patient = {},
      dentist = {},
      date,
      start,
      end,
      nextSchedule = {},
      scheduleConfig = {},
      categories = [],
      formInit,
      treatment = {}
    } = this.props;
    const { message, changeProcedure, procedures, showNotes } = this.state;
    console.log('example', this.props.example);
    return (
      <Modal isOpen={modal} toggle={this.closeModal} className={this.props.className} size={'mdplus'}>
        <ModalHeader
          className='title-primary'
          toggle={this.closeModal}
          close={
            <button className='close' onClick={this.closeModal}>
              &times;
            </button>
          }
        >
          <strong>Agendar Consulta</strong>
        </ModalHeader>
        <Formik initialValues={formInit} validateOnChange={false} onSubmit={this.saveAppointment}>
          {({ errors, values, handleChange }) => {
            this.errors = errors;
            return (
              <Form>
                <div>
                  <MessagesComponent display={message.display} type={'danger'} message={message.text} />
                </div>
                <div className='box-secondary' style={{ paddingTop: 5, paddingBottom: 5 }}>
                  <div className='text-left'>
                    <Row className='text-default pb-0'>
                      <Col sm='3'>
                        <div>
                          <img
                            style={{ width: 70 }}
                            className='img-patient'
                            src={patient.foto ? patient.foto : noavatar}
                            alt='usuario'
                          />
                        </div>
                      </Col>
                      <Col sm='5'>
                        <p className='mt-2'>
                          <strong className={'font-bold'}>{patient.nome}</strong>
                          <br />
                          <strong className='p-0 m-0'>{patient.numero}</strong> {patient.idade}
                        </p>
                      </Col>
                      <Col sm='4'>
                        <p className='mt-2'>
                          {t(patient, 'telefones').isArray
                            ? patient.telefones.map((t) => <p key={t.id}>{phoneFormat(t.telefone)}</p>)
                            : null}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
                <ModalBody>
                  <Row className='mt-2'>
                    <IfComponent conditional={t(treatment, 'tratamento').isDefined}>
                      <Col sm='6'>
                        <p className='text-right'>
                          <strong>Tratamento</strong>{' '}
                        </p>
                      </Col>
                      <Col sm='6'>
                        <p className='text-left'>{treatment.tratamento + ' - ' + treatment.especialidade}</p>
                      </Col>
                    </IfComponent>
                    <Col sm='6'>
                      <p className='text-right'>
                        <strong>Data</strong>{' '}
                      </p>
                    </Col>
                    <Col sm='6'>
                      <p className='text-left'>{dateFormat(date, 'DD/MM/YYYY - dddd')}</p>
                    </Col>
                    <Col sm='6'>
                      <p className='text-right'>
                        <strong>Horário</strong>{' '}
                      </p>
                    </Col>
                    <Col sm='6'>
                      <p className='text-left'>
                        {start && start.format('HH:mm')} às{' '}
                        {this.endTimeSchedule(start, end, values.procedimento_tempo).format('HH:mm')} (
                        {start && this.endTimeSchedule(start, end, values.procedimento_tempo).diff(start, 'm')}
                        min)
                      </p>
                    </Col>
                    <Col sm='6'>
                      <p className='text-right'>
                        <strong>Procedimento</strong>
                      </p>
                    </Col>
                    <Col sm='6'>
                      <p className='text-left'>
                        <span
                          className='procedure-primary'
                          id={'procedure'}
                          style={{
                            backgroundColor: values.procedimento_categoria_rgb,
                            color: '#454c5e',
                            fontSize: 13
                          }}
                        >
                          {values.procedimento_codinome}
                        </span>
                        <a href={'#'} onClick={() => this._clearSelectProcedure(handleChange)}>
                          <Icon className='icon icon-size-small' image={edit} alt='edit' />
                        </a>
                        <UncontrolledTooltip className='tooltip-white' placement='top' target='procedure'>
                          {values.procedimento_nome}
                        </UncontrolledTooltip>
                      </p>
                    </Col>
                  </Row>
                  <IfComponent conditional={changeProcedure}>
                    <Row>
                      <Col sm='6'>
                        <label>
                          Grupo<span>*</span>
                        </label>
                      </Col>
                      <Col sm='6'>
                        <label>
                          Procedimento<span>*</span>
                        </label>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm='6'>
                        <Select
                          styles={selectStyles}
                          value={values.category}
                          options={categories}
                          onChange={(c) => {
                            reactSelectHandleChange(c, handleChange, 'category');
                            this.findProcedures(c.data);
                            reactSelectHandleChange(null, handleChange, 'procedure');
                          }}
                        />
                      </Col>
                      <Col sm='6'>
                        <Select
                          placeholder={''}
                          value={values.procedure}
                          options={procedures}
                          onChange={(p) => {
                            this._selectProcedure(p, handleChange);
                          }}
                          styles={reactSelectStyleIsError(errors, 'procedure', selectStyles)}
                        />
                        <ErrorFieldWithoutBootstrap fieldName={'procedure'} errors={errors} />
                      </Col>
                    </Row>
                  </IfComponent>
                  <IfComponent conditional={!showNotes}>
                    <Row>
                      <Col sm='6'>
                        <p className='text-right'>
                          <strong>Observações</strong>
                        </p>
                      </Col>
                      <Col sm='6'>
                        <p className='text-left'>
                          <a href={'#'} onClick={() => this.setState({ showNotes: !showNotes })}>
                            <Icon className='icon icon-size-small mr-2' image={edit} alt='edit' />
                          </a>
                          <IfComponent conditional={values.anotacao !== ''}>
                            <i>
                              <span>{values.anotacao}</span>
                            </i>
                          </IfComponent>
                        </p>
                      </Col>
                    </Row>
                  </IfComponent>

                  <IfComponent conditional={showNotes}>
                    <Row>
                      <Col sm={{ size: 12 }}>
                        <label>Observações</label>
                        <textarea
                          rows='1'
                          cols='1'
                          onChange={handleChange}
                          value={values.anotacao}
                          name={'anotations'}
                          className='form-control mb-2'
                        />
                      </Col>
                    </Row>
                  </IfComponent>
                  <Row>
                    <Col sm='12'>
                      <form>
                        <Row className='p-0'>
                          <Col sm={{ size: 6 }} className='p-0'>
                            <label className='float-right'>Pendência de Laboratório</label>
                          </Col>
                          <Col sm={{ size: 6 }}>
                            <RadioGroup
                              pointColor='var(--primary)'
                              className='radio-griks height-sm'
                              name='pendencia'
                              value={values.pendencia}
                              selectedValue={values.pendencia}
                              horizontal
                              onChange={(e) => {
                                handleChange({
                                  target: { name: 'pendencia', value: e === 'true' ? 'true' : 'false' }
                                });
                                handleChange({
                                  target: {
                                    name: 'pendencia_obs',
                                    value: e === 'true' ? values.pendencia_obs : ''
                                  }
                                });
                              }}
                            >
                              <RadioButton
                                rootColor={'var(--secondary-l3)'}
                                pointColor={'var(--primary)'}
                                iconSize={10}
                                value='false'
                              >
                                Não
                              </RadioButton>
                              <RadioButton
                                rootColor={'var(--secondary-l3)'}
                                pointColor={'var(--primary)'}
                                iconSize={10}
                                value='true'
                              >
                                Sim
                              </RadioButton>
                            </RadioGroup>
                          </Col>
                        </Row>
                      </form>
                    </Col>
                  </Row>
                  <IfComponent conditional={values.pendencia === 'true'}>
                    <Row>
                      <Col sm='12'>
                        <label>Pendência</label>
                        <input
                          className='input-secondary'
                          type='text'
                          name='pendencia_obs'
                          value={values.pendencia_obs}
                          onChange={handleChange}
                        />
                      </Col>
                    </Row>
                  </IfComponent>
                  <div>
                    <Row>
                      <Col sm='6'>
                        <p className='text-right'>
                          <strong>Consultas Futuras</strong>
                        </p>
                      </Col>
                      <Col sm='6'>
                        <p className='text-left'>
                          {nextSchedule.proximaConsulta && moment(nextSchedule.proximaConsulta).format('DD/MM/YYYY')} (
                          {nextSchedule.dentista})
                        </p>
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Row>
                    <Col className={'btn-list'} sm={{ size: 5, offset: 7 }}>
                      <button className='button-primary'>Salvar</button>
                      <button className='button-tertiary' type={'button'} onClick={this.closeModal}>
                        Cancelar
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...scheduleAppointmentFinalStepReducerToProps(state),
    formInit: scheduleAppointmentFinalStepForm(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(scheduleAppointmentPatientModal({ modal: false })),
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader())
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(ScheduleAppointmentModal);
