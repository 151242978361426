//actionsTypes
export const CLOSE_MODAL = 'cash/CLOSE_MODAL';
export const OPEN_MODAL = 'cash/OPEN_MODAL';
export const CASH_REPORT = 'cash/REPORT';

// reducer
const initialState = {};

export default function CashReducer(state = initialState, action) {
  switch (action.type) {
    case CLOSE_MODAL: {
      return {
        ...state,
        closeModal: {
          ...action.payload
        }
      };
    }

    case OPEN_MODAL: {
      return {
        ...state,
        openModal: {
          ...action.payload
        }
      };
    }

    case CASH_REPORT: {
      return {
        ...state,
        report: {
          ...action.payload
        }
      };
    }

    default: {
      return state;
    }
  }
}

// actions

export function cashCloseModal({ modal, usuario }) {
  return {
    type: CLOSE_MODAL,
    payload: {
      modal,
      usuario
    }
  };
}

export function cashOpenModal({ modal, usuario }) {
  return {
    type: OPEN_MODAL,
    payload: {
      modal,
      usuario
    }
  };
}

export function cashReport({ cash }) {
  return {
    type: CASH_REPORT,
    payload: {
      cash
    }
  };
}
