import React from 'react';
import { FormFeedback } from 'reactstrap';
import PropTypes from 'prop-types';

const ErrorField = ({ errors, fieldName }) => {
  return <FormFeedback>{errors[fieldName] ? errors[fieldName] : null}</FormFeedback>;
};

ErrorField.propTypes = {
  errors: PropTypes.object,
  fieldName: PropTypes.string.isRequired
};

export default ErrorField;
