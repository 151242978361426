import { t } from 'typy';
import { createSelector } from 'reselect';

const treatmentImagesHomePageSelector = (state) => t(state, 'TreatmentImagesReducer.homePageLoad').safeObject;

export const treatmentImagesHomePageStateToProps = createSelector(
  treatmentImagesHomePageSelector,
  ({ formLoad = {} } = {}) => {
    return {
      treatment: formLoad.treatment || {},
      patient: formLoad.patient || {},
      folders: formLoad.folders || [],
      folderSelected: (t(formLoad, 'folders').isArray && formLoad.folders[0]) || {}
    };
  }
);
