import React, { Component } from 'react';

import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { formatReal } from '../../../../utils/Formatter';
import ToastUtils from '../../../../utils/ToastUtils';

class TreatmentAddGadgetsModal extends Component {
  state = {
    selected: [],
    singleSelection: false
  };

  selectProcedure = (e, p) => {
    if (e.target.checked) {
      if (this.state.singleSelection && this.state.selected.length > 0) {
        ToastUtils.warn('Permitido selecionar somente 1 procedimento');
        e.target.checked = false;
      } else {
        this.setState((state) => {
          state.selected.push({
            id: p.id,
            descricao: p.nome,
            valor_tratamento: p.valorProcedimento
          });
          return state;
        });
      }
    } else {
      this.setState((state) => {
        return { selected: state.selected.filter((s) => s.id !== p.id) };
      });
    }
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.modal !== nextProps.modal) {
      this.setState({
        selected: nextProps.selected,
        singleSelection: nextProps.singleSelection
      });
    }
  }

  render() {
    const { modal, closeModal, gadgets, addGadgets } = this.props;
    const { selected } = this.state;

    return (
      <Modal isOpen={modal} toggle={closeModal} className={this.props.className} size={'default'}>
        <ModalHeader
          className='title-primary'
          toggle={closeModal}
          close={
            <button className='close' onClick={closeModal}>
              &times;
            </button>
          }
        >
          <strong>Lançar Novos Débitos</strong>
        </ModalHeader>
        <ModalBody>
          <div className='margin-top-10' style={{ overflow: 'auto', maxHeight: 500 }}>
            <Row>
              <Col sm='12'>
                {gadgets.map((g) => (
                  <Table responsive>
                    <thead>
                      <tr>
                        <th width='65%'>{g.nome}</th>
                        <th width='20%'>Valor</th>
                        <th width='5%'></th>
                      </tr>
                    </thead>
                    <tbody>
                      {g.procedimentos
                        .filter((p) => p.status === 'ATIVO')
                        .map((p) => {
                          const checked = selected.find((s) => s.id === p.id);
                          return (
                            <tr>
                              <td>{p.nome}</td>
                              <td>
                                <Col sm='12'>{formatReal(p.valorProcedimento)}</Col>
                              </td>
                              <td>
                                <Col sm='12'>
                                  <input
                                    type='checkbox'
                                    className='form-checkbox'
                                    checked={checked}
                                    name={'procedure'}
                                    onClick={(e) => this.selectProcedure(e, p)}
                                  />
                                </Col>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                ))}
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col className='btn-list' sm={{ size: 6, offset: 6 }}>
              <button className='button-primary' onClick={() => addGadgets(this.state.selected)}>
                Salvar
              </button>
              <button className='button-tertiary' onClick={closeModal}>
                Cancelar
              </button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}

export default TreatmentAddGadgetsModal;
