import React, { Component } from 'react';
import arrowDown from '../assets/img/arrow-down.png';
import arrowUp from '../assets/img/arrow-up.png';

import PropTypes from 'prop-types';
import { IfComponent } from './if.component';

class ButtomArrowUpDownComponentComponent extends Component {
  state = {
    open: false
  };

  static propTypes = {
    id: PropTypes.number.isRequired,
    show: PropTypes.bool
  };

  checkUpDown = () => {
    this.setState({
      open: !this.state.open
    });
  };

  render() {
    const { id, disabled = false, show } = this.props;

    return (
      <button className='box-collapse-button' id={id} onClick={this.checkUpDown} disabled={disabled}>
        <IfComponent conditional={show}>
          <span className='changeArrow arrow-down'>
            <img className='img-arrow' src={this.state.open ? arrowUp : arrowDown} alt='arrow' />
          </span>
        </IfComponent>
      </button>
    );
  }
}

export default ButtomArrowUpDownComponentComponent;
