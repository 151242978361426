import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import logo from '../../assets/img/logo.jpg';
import { Link, withRouter } from 'react-router-dom';
import { login } from '../../service/Login.service';
import LoginForm from './Login.form';
import ErrorMessage from '../../components/ErrorMessage.component';
import {
  apiAccessTokenSave,
  saveAffiliate,
  saveClinicData,
  savePermissions,
  saveUserData
} from '../../utils/localStorage/SessionStorageUtils';

import UserAPI from '../../service/User.service';

import Loader from '../../components/Loader.component';

import { connect } from 'react-redux';
import { compose } from 'recompose';

import queryString from 'query-string';

import { showLoader, hideLoader } from '../../components/actions/Loader.actions';
import ClinicAPI from '../../service/ClinicAPI';
import { sendMessage } from '../../components/actions/ErrorMessage.actions';
import { ToastContainer } from 'react-toastify';
import ClientApi from '../../service/ClientApi';
import ToastUtils from '../../utils/ToastUtils';
import { t } from 'typy';
import AffiliatesApi from '../../service/AffiliatesApi';

class LoginPage extends Component {
  state = {
    redirect: ''
  };

  componentDidMount() {
    const { message, redirect } = queryString.parse(this.props.location.search);
    if (message) {
      this.props.sendMessage({
        id: 'login',
        display: true,
        message: message,
        type: 'danger'
      });
      this.setState({
        redirect
      });
    }
  }

  enviarForm = async (values) => {
    const { history, showLoader, hideLoader } = this.props;
    showLoader();

    ClientApi.checkTrial(values)
      .then((_) => {
        login(values)
          .then(async ({ access_token, troca_senha }) => {
            apiAccessTokenSave(access_token);

            const permission = await UserAPI.getUserAccess();

            savePermissions(permission);

            const clinic = await ClinicAPI.findByUser();

            saveClinicData(clinic.data);

            const user = await UserAPI.getFullData();

            saveUserData(user);

            const affiliate = await AffiliatesApi.getAffiliate(clinic.data.id);
            saveAffiliate(affiliate);

            hideLoader();

            this.setState((state) => {
              let pageTo = '/';

              if (this.state.redirect) {
                pageTo = this.state.redirect;
              } else if (troca_senha === 'S') {
                pageTo = '/changepassword';
              }

              // Feito o redirect pelo window location devido a atualização do permissionamento de usuário
              window.location = pageTo;
            });
          })
          .catch((error) => {
            hideLoader();

            let message = '';

            if (error.response) {
              const { data } = error.response;

              if (data.status === 401) {
                message = 'Ops! Usuário ou Senha inválidos';
              }
            } else {
              message = `Erro interno entre em contato com o administrador do sistema [${error.message}]`;
            }

            if (message === '') {
              message = 'Ops! Usuário ou Senha inválidos';
            }

            this.props.sendMessage({
              id: 'login',
              display: true,
              message: message,
              type: 'danger'
            });
          });
      })
      .catch((_) => {
        hideLoader();
        if (
          t(_, 'response.status').safeNumber === 500 &&
          t(_, 'response.data.exception').safeString.includes('Expirado')
        ) {
          console.log('Expirado');
          this.props.history.push('/trial/subscribe-now', { email: values.login });
        } else {
          ToastUtils.error('Erro ao realizar login, entre em contato com o suporte');
        }
      });
  };

  render() {
    return (
      <Container>
        <Loader />
        <main className='main-container-overflow'>
          <div className='login-container'>
            <div className='login-box'>
              {/* <div className="login-box-left">
                <span>Conteúdo Publicitário do <strong>GRIKS</strong></span>
              </div> */}
              <div className='login-box-right'>
                <Row>
                  <Col sm='12'>
                    <img
                      className='img-logo-login'
                      style={{
                        width: 145
                      }}
                      src={logo}
                      alt='logo'
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={{ size: 8, offset: 2 }}>
                    <ErrorMessage where={'login'} />
                    <LoginForm submitCallback={this.enviarForm} />
                  </Col>
                </Row>
                <div className='login-account'>
                  <Row>
                    <Col sm={{ size: 4, offset: 2 }}>
                      <Link to={'/trial'} className='link-secondary login-account-info'>
                        Ainda não tem uma Conta?
                      </Link>
                    </Col>
                    <Col sm='4'>
                      <Link to={'/trial'} className='button-secondary'>
                        Experimente Grátis
                      </Link>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </main>
        <ToastContainer />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  sendMessage: (data) => dispatch(sendMessage(data))
});

const enhanced = compose(withRouter, connect(mapStateToProps, mapDispatchToProps));

export default enhanced(LoginPage);
