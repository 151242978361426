import {
  SETTINGS_PROCEDURES_TABLE_PRICE_MONTHLY_REGISTER_PAGE,
  SETTINGS_PROCEDURES_TABLE_PRICE_REGISTER_PAGE
} from './SettingsProcedureTablePrice.action';

const initialState = {};

export function SettingsProceduresTableReducer(state = initialState, action) {
  switch (action.type) {
    case SETTINGS_PROCEDURES_TABLE_PRICE_REGISTER_PAGE:
      return {
        ...state,
        procedure: {
          ...action.payload
        }
      };
    case SETTINGS_PROCEDURES_TABLE_PRICE_MONTHLY_REGISTER_PAGE:
      return {
        ...state,
        monthly: {
          ...action.payload
        }
      };
    default:
      return state;
  }
}

export default SettingsProceduresTableReducer;
