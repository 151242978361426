import React, { Component } from 'react';

import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';
import PrivateRouter from '../components/PrivateRoute.component';
import LoginPage from '../pages/login/Login.page';
// import StyleSheetPage from '../pages/stylesheet/StyleSheet.page';
import TemplatePage from '../pages/template/Template.page';
import ForgotPasswordPage from '../pages/login/ForgotPassword.page';
import ResetPasswordPage from '../pages/login/ResetPassword.page';
import PatientsRouters from './patients.router';

import { compose } from 'recompose';
import Loader from '../components/Loader.component';
import { hideLoader, showLoader } from '../components/actions/Loader.actions';
import { connect } from 'react-redux';
import ContactsRouter from './contacts.router';
import SettingsRouter from './settings.router';
import ChangePasswordPage from '../pages/login/ChangePassword.page';
import TreatmentRouter from './treatment.router';
import ScheduleRouters from './schedule.router';
import ScheduleHomePage from '../pages/schedule/ScheduleHome.page';
import CashRouter from './cash.router';
import withSecurity from '../config/security/security';
import { IfComponent } from '../components/if.component';
import { PERMISSOES } from '../config/security/permissoes';
import StartTrialPage from '../pages/trial/StartTrialPage';
import StartTrialSuccessPage from '../pages/trial/StartTrialSuccessPage';
import ConfirmTrialPage from '../pages/trial/ConfirmTrialPage';
import ConfirmTrialSuccessPage from '../pages/trial/ConfirmTrialSuccessPage';
import SubscribeNowPage from '../pages/trial/SubscribeNowPage';
import SubscribePaymentPage from '../pages/trial/SubscribePaymentPage';
import SubscribeNFPage from '../pages/trial/SubscribeNFPage';
import SubscribeSuccessPage from '../pages/trial/SubscribeSuccessPage';
import ReportsRouters from './reports.router';
import FlowRouters from './flow.router';
import { getUserData, isAffiliate, isLogged } from '../utils/localStorage/SessionStorageUtils';
import Intercom from 'react-intercom';
import VoucherReportsPage from '../pages/reports/VoucherReportsPage';
import FinancialOverviewReportPrintPage from '../pages/reports/financial/FinancialOverviewReportPrintPage';
import AffiliatesRouters from './affiliates.router';
import AffiliateRegister from '../pages/affiliates/AffiliateRegisterExternal';
import { showHideModalRegisterPatient } from '../pages/patinent/redux/patient.actions';
import AppointmentHistoryModal from '../pages/schedule/modal/AppointmentHistory.modal';
import DeployRouters from './deploy.router';

class AppRouting extends Component {
  state = {
    user: {}
  };

  withoutLayout = (path) => {
    const paths = [
      '/login',
      '/forgotpassword',
      '/resetpassword',
      '/changepassword',
      '/trial',
      '/trial/success',
      '/trial/confirm/success',
      '/trial/subscribe-now',
      '/trial/subscribe/payment',
      '/trial/subscribe/nf',
      '/trial/subscribe/success',
      '/stylesheet',
      '/affili-register'
    ];
    return (
      paths.includes(path) ||
      path.includes('/confirmTrial/') ||
      path.includes('/reports/voucher') ||
      path.includes('/reports/overview/print')
    );
  };

  componentDidMount() {
    this.setState({
      user: getUserData()
    });
  }

  render() {
    const { user } = this.state;
    return (
      <div>
        <Loader />
        {this.withoutLayout(this.props.location.pathname) && (
          <div>
            <Route key='login' exact path='/login' render={(props) => <LoginPage {...props} />} />
            {/*<Route*/}
            {/*  key="stylesheet"*/}
            {/*  exact*/}
            {/*  path="/stylesheet"*/}
            {/*  render={props => <StyleSheetPage {...props} />}*/}
            {/*/>*/}
            <Route key='password' exact path='/forgotpassword' render={(props) => <ForgotPasswordPage {...props} />} />
            <Route key='change' exact path='/resetpassword' render={(props) => <ResetPasswordPage {...props} />} />
            <Route key='changepwd' exact path='/changepassword' render={(props) => <ChangePasswordPage {...props} />} />
            <Route key='startTrial' exact path='/trial' render={(props) => <StartTrialPage {...props} />} />
            <Route
              key='startTrialSuccess'
              exact
              path='/trial/success'
              render={(props) => <StartTrialSuccessPage {...props} />}
            />
            <Route
              key='confirmTrial'
              exact
              path='/confirmTrial/:token'
              render={(props) => <ConfirmTrialPage {...props} />}
            />
            <Route
              key='confirmTrialSucess'
              exact
              path='/trial/confirm/success'
              render={(props) => <ConfirmTrialSuccessPage {...props} />}
            />

            <Route
              key='/trial/subscribeNow'
              exact
              path='/trial/subscribe-now'
              render={(props) => <SubscribeNowPage {...props} />}
            />

            <Route
              key='/trial/subscribe/payment'
              exact
              path='/trial/subscribe/payment'
              render={(props) => <SubscribePaymentPage {...props} />}
            />
            <Route
              key='/trial/subscribe/nf'
              exact
              path='/trial/subscribe/nf'
              render={(props) => <SubscribeNFPage {...props} />}
            />
            <Route
              key='/trial/subscribe/success'
              exact
              path='/trial/subscribe/success'
              render={(props) => <SubscribeSuccessPage {...props} />}
            />

            <Route
              key='AffiliateRegister'
              exact
              path='/affili-register'
              render={(props) => <AffiliateRegister {...props} />}
            />
            {isLogged() && (
              <>
                <PrivateRouter
                  key='VoucherReportsPage'
                  exact
                  path='/reports/voucher/:id'
                  render={(props) => <VoucherReportsPage {...props} />}
                />
              </>
            )}
            <Route
              key='FinancialOverviewReportPrintPage'
              exact
              path='/reports/overview/print'
              render={(props) => <FinancialOverviewReportPrintPage {...props} />}
            />
          </div>
        )}

        {!this.withoutLayout(this.props.location.pathname) && (
          <TemplatePage {...this.props}>
            <Intercom
              appID='wkg2iwyl'
              {...{
                user_id: user.id,
                email: user.login,
                name: user.nome
              }}
            />

            <PrivateRouter
              key='home'
              exact
              path='/'
              render={(props) => {
                if (this.props.security.hasModuleAccess(PERMISSOES.modules.agenda.id)) {
                  return <ScheduleHomePage {...props} />;
                } else {
                  return <div></div>;
                }
              }}
            />

            <IfComponent conditional={this.props.security.hasModuleAccess(PERMISSOES.modules.pacientes.id)}>
              <PatientsRouters showLoader={showLoader} hideLoader={hideLoader} />
            </IfComponent>

            <IfComponent conditional={this.props.security.hasModuleAccess(PERMISSOES.modules.contatos.id)}>
              <ContactsRouter showLoader={showLoader} hideLoader={hideLoader} />
            </IfComponent>
            <IfComponent conditional={this.props.security.hasModuleAccess(PERMISSOES.modules.configuracoes.id)}>
              <SettingsRouter />
            </IfComponent>
            <IfComponent
              conditional={
                this.props.security.hasModuleAccess(PERMISSOES.modules.tratamento_avaliacoes.id) ||
                this.props.security.hasModuleAccess(PERMISSOES.modules.tratamento_ficha_clinica.id) ||
                this.props.security.hasModuleAccess(PERMISSOES.modules.tratamento_ficha_financeira.id) ||
                this.props.security.hasModuleAccess(PERMISSOES.modules.tratamento_orcamentos.id)
              }
            >
              <TreatmentRouter showLoader={showLoader} hideLoader={hideLoader} />
            </IfComponent>

            <IfComponent conditional={this.props.security.hasModuleAccess(PERMISSOES.modules.agenda.id)}>
              <ScheduleRouters />
            </IfComponent>

            <IfComponent conditional={this.props.security.hasModuleAccess(PERMISSOES.modules.caixa.id)}>
              <CashRouter />
            </IfComponent>

            <ReportsRouters />
            <FlowRouters />
            <DeployRouters/>
            <IfComponent conditional={isAffiliate()}>
              <AffiliatesRouters />
            </IfComponent>
            {/* adicionado modal de historico aqui pois ele pode ser chamado de qualquer ponto do sistema */}
            <AppointmentHistoryModal />
          </TemplatePage>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (data) => dispatch(showLoader(data)),
    hideLoader: (data) => dispatch(hideLoader(data)),
    showHideModalRegisterPatient: (data) => dispatch(showHideModalRegisterPatient(data)),
  };
};

const enhanced = compose(withRouter, connect(mapStateToProps, mapDispatchToProps), withSecurity);

export default enhanced(AppRouting);
