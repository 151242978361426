export const SAGA_SETTINGS_USERS_FIND_ALL = 'SAGA_SETTINGS_USERS_FIND_ALL';
export const SETTINGS_USERS_LIST_ALL = 'SETTINGS_USERS_LIST_ALL';
export const SETTINGS_USERS_REGISTER = 'SETTINGS_USERS_REGISTER';
export const SETTINGS_USERS_MODAL_SUCCESS = 'SETTINGS_USERS_MODAL_SUCCESS';

export function usersFindAllSaga({ currentPage, pageSize }) {
  return {
    type: SAGA_SETTINGS_USERS_FIND_ALL,
    payload: {
      currentPage,
      pageSize
    }
  };
}

export function homeUsers(data) {
  return {
    type: SETTINGS_USERS_LIST_ALL,
    data: {
      ...data
    }
  };
}

export function settingsRegisterUserModalForm({ formData, modal }) {
  return {
    type: SETTINGS_USERS_REGISTER,
    payload: {
      formData,
      modal
    }
  };
}

export function modalSuccess({ modal, data }) {
  return {
    type: SETTINGS_USERS_MODAL_SUCCESS,
    payload: {
      modal,
      data
    }
  };
}
