import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { treatmentEvaluationModalOtherAnnotations } from '../redux/TreatmentEvaluation.action';
import { Form, Formik } from 'formik';
import TreatmentEvaluationApi from '../../../../service/TreatmentEvaluationApi';
import { hideLoader, showLoader } from '../../../../components/actions/Loader.actions';
import MessagesComponent from '../../../../components/Messages.component';

class TreatmentOtherAnnotationsModal extends Component {
  state = {
    message: {
      display: false,
      type: 'danger',
      text: ''
    }
  };

  saveAnnotation = async (values) => {
    const { closeModal, formLoad = {}, updateParent, showLoader, hideLoader } = this.props;

    showLoader();
    try {
      await TreatmentEvaluationApi.saveOtherAnnotations({
        avaliacao: formLoad.evaluation.avaliacao_id,
        observacoes: values.annotation
      });
      updateParent();
      closeModal();
    } catch (e) {
      console.log(e);
      this.setState({
        message: {
          display: true,
          type: 'danger',
          text: 'Erro ao salvar documentação ortodôntica entre em contato com o suporte'
        }
      });
    }
    hideLoader();
  };

  render() {
    const { message } = this.state;
    const { modal, closeModal, formLoad = {} } = this.props;
    return (
      <Modal isOpen={modal} toggle={closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={closeModal}
          close={
            <button className='close' onClick={closeModal}>
              &times;
            </button>
          }
        >
          <strong>Editar</strong>
        </ModalHeader>
        <Formik
          initialValues={{ annotation: formLoad.annotation }}
          validateOnChange={false}
          onSubmit={this.saveAnnotation}
        >
          {({ errors, values, handleChange }) => (
            <Form>
              <ModalBody>
                <MessagesComponent display={message.display} type={message.type} message={message.text} />
                <div className='margin-top-10'>
                  <Row className='margin-top-10'>
                    <Col sm='12'>
                      <label>Outras Anotações</label>
                      <textarea id={'annotation'} rows='10' cols='50' onChange={handleChange}>
                        {values.annotation}
                      </textarea>
                    </Col>
                  </Row>
                </div>
              </ModalBody>
              <ModalFooter>
                <Row>
                  <Col sm={{ size: 3, offset: 6 }}>
                    <button className='button-primary'>Salvar</button>
                  </Col>
                  <Col sm='3'>
                    <button className='button-tertiary' type={'button'} onClick={closeModal}>
                      Cancelar
                    </button>
                  </Col>
                </Row>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = ({ TreatmentEvaluationReducer: { evaluationOtherAnnotations = {} } } = {}) => {
  return { ...evaluationOtherAnnotations };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  closeModal: () => dispatch(treatmentEvaluationModalOtherAnnotations({ modal: false }))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(TreatmentOtherAnnotationsModal);
