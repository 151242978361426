export const PERMISSOES = {
  modules: {
    agenda: {
      id: 1,
      permissoes: {
        visualizar: 1,
        editar: 2
      }
    },
    contatos: {
      id: 2,
      permissoes: {
        visualizar: 3,
        editar: 4,
        editar_restricao: {
          id: 5,
          restricoes: {
            nao_excluir_contato: 1
          }
        }
      }
    },
    pacientes: {
      id: 3,
      permissoes: {
        visualizar: 6,
        editar: 7,
        editar_restricao: {
          id: 8,
          restricoes: {
            nao_excluir_pacientes: 2
          }
        }
      }
    },
    tratamento_avaliacoes: {
      id: 4,
      permissoes: {
        visualizar: 9,
        editar: 10
      }
    },
    tratamento_orcamentos: {
      id: 5,
      permissoes: {
        visualizar: 11,
        editar: 12,
        editar_restricao: {
          id: 13,
          restricoes: {
            nao_aprovar: 3,
            nao_excluir: 4,
            nao_alterar_valores: 5
          }
        }
      }
    },
    tratamento_ficha_clinica: {
      id: 6,
      permissoes: {
        visualizar: 14,
        editar: 15,
        editar_restricao: {
          id: 16,
          restricoes: {
            nao_finalizar: 6,
            nao_excluir: 7
          }
        }
      }
    },
    tratamento_ficha_financeira: {
      id: 7,
      permissoes: {
        visualizar: 17,
        editar: 18,
        editar_restricao: {
          id: 19,
          restricoes: {
            nao_lancar_desconto: 8,
            nao_lancar_ajuste_credito: 9,
            nao_excluir_lancamento: 10
          }
        }
      }
    },
    caixa: {
      id: 8,
      permissoes: {
        visualizar: 20,
        operar_caixa: 21
      }
    },
    configuracoes: {
      id: 9,
      permissoes: {
        visualizar: 22,
        editar: 23,
        editar_restricao: {
          id: 24,
          restricoes: {
            editar_agenda: 11,
            editar_clinica: 12,
            editar_usuarios: 13,
            editar_procedimento: 14,
            editar_tabela_preco: 15,
            editar_bibliotecas: 16
          }
        }
      }
    }
  }
};
