import RestTemplate from '../utils/restTemplate/rest.template';
import * as moment from 'moment';

export default class ContactsAPI {
  static findAll = (page, totalPage) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/contatos/', {
      pageNumber: page,
      pageSize: totalPage
    });
  };

  static pescInc = (name) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/contatos/pescinc/${name}`);
  };

  static findById = (id) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/contatos/${id}`);
  };

  static uploadImage = ({ id, image, extension }) => {
    const restTemplate = new RestTemplate(true);

    const requestData = {
      contact_id: id,
      image,
      extension
    };

    return restTemplate.post('/contatos/upload', requestData);
  };

  static insertContact = ({
    titulo,
    nome,
    nascimento,
    telefone_one,
    telefone_one_nome,
    telefone_one_tipo,
    telefone_two,
    telefone_two_nome,
    telefone_two_tipo,
    email,
    anotacoes,
    id_categoria
  }) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post('/contatos', {
      titulo,
      nome,
      nascimento: moment(nascimento, 'DD/MM/YYYY'),
      telefone_one,
      telefone_one_nome,
      telefone_one_tipo,
      telefone_two,
      telefone_two_nome,
      telefone_two_tipo,
      email,
      anotacoes,
      id_categoria
    });
  };

  static updateContact = ({
    id,
    titulo,
    nome,
    nascimento,
    telefone_one,
    telefone_one_nome,
    telefone_one_tipo,
    telefone_two,
    telefone_two_nome,
    telefone_two_tipo,
    email,
    anotacoes,
    id_categoria,
    foto
  }) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.put('/contatos', {
      id,
      titulo,
      nome,
      nascimento: moment(nascimento, 'DD/MM/YYYY'),
      telefone_one,
      telefone_one_nome,
      telefone_one_tipo,
      telefone_two,
      telefone_two_nome,
      telefone_two_tipo,
      email,
      anotacoes,
      id_categoria,
      foto
    });
  };

  static delete(id) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.delete(`/contatos/${id}`);
  }
}
