import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { compose } from 'recompose';
import Loader from '../../components/Loader.component';
import { showLoader, hideLoader } from '../../components/actions/Loader.actions';

import PaginationComponent from '../../components/Pagination.component';
import BaseComponent from '../../config/component/BaseComponent';
import PacienteAPI from '../../service/PacienteAPI';
import { formatDate } from '../../utils/DateUtils';
import { connect } from 'react-redux';
import { setHeader } from '../template/redux/Content.actions';

const defaultState = {
  activePage: 1,
  deletedPatient: {
    items: [],
    totalPages: 1,
    totalElements: 1,
    totalPerPage: 10
  }
};

class PatientDeletedList extends Component {
  state = {
    ...defaultState
  };

  componentDidMount() {
    this.title = 'Pacientes Deletados';
    this.findDeletedPatients();
  }

  findDeletedPatients = () => {
    const { activePage, deletedPatient } = this.state;
    const { totalPerPage } = deletedPatient;
    this.props.showLoader();
    PacienteAPI.findAllDeletedPatient(activePage, totalPerPage).then((r) => {
      const { data = [], totalPages, totalElements } = r;

      this.setState({
        deletedPatient: {
          ...this.state.deletedPatient,
          items: data,
          totalPages,
          totalElements
        }
      });

      this.props.hideLoader();
    });
  };

  changePage = (index) => {
    this.setState({ activePage: index }, () => this.findDeletedPatients());
  };

  render() {
    const { deletedPatient, activePage } = this.state;
    const { items, totalPages } = deletedPatient;

    return (
      <div className='content-box-without-color'>
        <Loader />
        <Container>
          <div className='box-primary'>
            <Row>
              <Col sm='4'>
                <span className='title-tertiary'>Paciente</span>
              </Col>
              <Col sm='3'>
                <span className='title-tertiary'>Data Criação</span>
              </Col>
              <Col sm='2'>
                <span className='title-tertiary'>Data Exclusão</span>
              </Col>
              <Col sm='3'>
                <span className='title-tertiary'>Quem Excluiu</span>
              </Col>
            </Row>
          </div>
          <Row>
            {items.map((p) => {
              const { nome, data_criacao, data_exclusao, usuario_deletou_nome } = p;

              let dataCriacao = '';
              let dataExclusao = '';

              if (data_criacao) {
                dataCriacao = formatDate(data_criacao, 'DD/MM/YYYY');
              }

              if (data_exclusao) {
                dataExclusao = formatDate(data_exclusao, 'DD/MM/YYYY');
              }

              return (
                <Col sm='12'>
                  <div className='box-search'>
                    <Row>
                      <Col sm='5'>
                        <Row>
                          <Col sm='2'>
                            <div className='img-user-search'>
                              <img className={'img-circle'} src={p.foto} alt='usuario' />
                            </div>
                          </Col>
                          <Col>
                            <p className='title-medium vertical-center'>{nome}</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm='2'>
                        <p className='title-medium vertical-center'>{dataCriacao}</p>
                      </Col>
                      <Col sm='2'>
                        <p className='title-medium vertical-center'>{dataExclusao}</p>
                      </Col>
                      <Col sm='3'>
                        <p className='title-medium vertical-center'>{usuario_deletou_nome}</p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col sm={{ size: 2, offset: 5 }}>
              <div className='pagination-primary margin-top-20'>
                <PaginationComponent selectedPage={this.changePage} totalPage={totalPages} activePage={activePage} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
    setHeader: (data) => dispatch(setHeader(data))
  };
};

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(PatientDeletedList);
