import React, { Component } from 'react';

import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from 'reactstrap';
import plus from '../../../assets/img/icons/plus.svg';
import Icon from '../../../components/Icon.component';
import { RadioGroup, RadioButton } from 'react-radio-buttons';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { settingsRegisterUserModalForm, usersFindAllSaga } from './redux/UserSettings.actions';
import { hideLoader, showLoader } from '../../../components/actions/Loader.actions';
import UserSettingsFormRegisterModal from './UserSettingsFormRegister.modal';
import UserAPI from '../../../service/User.service';

import menuDots from '../../../assets/img/menuDots.png';
import menu from '../../../assets/img/menu.png';
import UserSettingsChangeMasterUserModal from './UserSettingsChangeMasterUser.modal';
import UserSettingsSucessoModal from './UserSettingsSucesso.modal';
import warning from '../../../assets/img/icons/warning.svg';
import ModalMessageConfirm from '../../../components/ModalMessageConfirm.component';
import MessagesComponent from '../../../components/Messages.component';
import { IfComponent } from '../../../components/if.component';
import withSecurity from '../../../config/security/security';
import { PERMISSOES } from '../../../config/security/permissoes';
import ClientApi from '../../../service/ClientApi';
import ToastUtils from '../../../utils/ToastUtils';
import ToastCustomMessageComponent from '../../../components/ToastCustomMessage.component';
import danger from '../../../assets/img/icons/danger1.svg';
import ReactTooltip from 'react-tooltip';


class UserSettingsHomePage extends Component {
  state = {
    activePage: 1,
    totalPage: 99999,
    profiles: [],
    openMenu: [],
    master: false,
    changeUserModal: {
      userSelected: {},
      modal: false
    },
    displayConfirmModal: false,
    displayConfirmModalReactivation: false,
    //userDelete: {},
    user: {},
    filter:'S',
    activeUsers:[],
    userData:{
      acessos:{},
      permissoes:{},
      clinicas_acesso:[],
      cpf:undefined,
      cro:undefined,
      cro_uf:undefined,
      dentista_acessos:{},
      id:undefined,
      login:undefined,
      nome:undefined,
      nome_telefone_one:undefined,
      nome_telefone_two:undefined,
      perfil_id:undefined,
      permissions:{},
      rg:undefined,
      rg_emissor:undefined,
      status:undefined,
      telefone_one:undefined,
      telefone_one_whatsapp:undefined,
      telefone_two:undefined,
      telefone_two_whatsapp:undefined,
      tipo_horario_acesso:undefined,
      troca_senha:undefined,
      usuario_cadastro:undefined,
      usuario_id:undefined
    }
  };

  closeModalChangeMasterUser = () => {
    this.setState({
      changeUserModal: {
        userSelected: {},
        modal: false
      }
    });
  };
  selectedPage = (page) => {
    const { activePage, totalPage } = this.state;
    if (activePage !== page) {
      this.setState({ activePage: page });
      this.props.findAll({ currentPage: page, pageSize: totalPage });
    }
  };

  componentDidMount = async () => {
    const userMaster = await UserAPI.isMaster();
    this.props.showLoader();
    const {data} = await UserAPI.findActivesUsers();
    this.setState({ master: userMaster.data.adm });

    this.setState({
      activeUsers: data
    })
    this.props.hideLoader();
  };
  
  editUser = async (user) => {
    const { showLoader, hideLoader, showEditModal } = this.props;

    showLoader();
    const userResponse = await UserAPI.findById(user);

    this.setState({
      teste:userResponse
    })
    console.log ('userResponse',userResponse)
    hideLoader();

    showEditModal({ formData: userResponse.data, modal: true });
  };

  changeMaster = (user) => {
    try {
      this.setState({
        changeUserModal: {
          userSelected: user,
          modal: true
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  openModalConfirmInactivation = (user) => {
    this.setState({
      displayConfirmModal: true,
      user:user
    });
  };

  confirmInactivation = async() => {
    const {user} = this.state 
    console.log(user)

    const resposta = await UserAPI.findById(user.id);

    if (resposta.data.acessos.length > 0) {
      const permissoes = {};

      resposta.data.acessos.forEach((acesso) => {
        
        if(acesso.permissoes[0].restricoes.length > 0){
          permissoes[`mol_${acesso.modulo_id}`] = {
            hasRestriction:true,
            module_id:acesso.modulo_id,
            permission_id:acesso.permissoes[0].permissao_id,
            restrictions:{[`rest_${acesso.permissoes[0].restricoes[0].restricao_id}`]:{restriction_id: acesso.permissoes[0].restricoes[0].restricao_id}} 
          };
        }else{
          permissoes[`mol_${acesso.modulo_id}`] = {
            hasRestriction:false,
            module_id:acesso.modulo_id,
            permission_id:acesso.permissoes[0].permissao_id,
          };
        }
        
      });

      this.setState({
        userData:{
          acessos:resposta.data.acessos,
          clinicas_acesso:resposta.data.clinicas.map(({id}) => ({id})),
          cpf:resposta.data.hasOwnProperty('cpf') ? resposta.data.cpf : undefined,
          cro:resposta.data.hasOwnProperty('cro') ? resposta.data.cro : undefined,
          cro_uf:resposta.data.hasOwnProperty('cro_uf') ? resposta.data.cro_uf : undefined,
          dentista_acessos:resposta.data.dentista_acessos,
          id:resposta.data.id,
          login:resposta.data.login,
          nome:resposta.data.nome,
          nome_telefone_one:resposta.data.hasOwnProperty('nome_telefone_one') ? resposta.data.nome_telefone_one : undefined,
          nome_telefone_two:resposta.data.hasOwnProperty('nome_telefone_two') ? resposta.data.nome_telefone_two : undefined,
          perfil_id:resposta.data.perfil.id,
          permissions: permissoes,
          rg:resposta.data.hasOwnProperty('rg') ? resposta.data.rg : undefined,
          rg_emissor:resposta.data.hasOwnProperty('rg_emissor') ? resposta.data.rg_emissor : undefined,
          status:'N',
          telefone_one:resposta.data.hasOwnProperty('telefone_one') ? resposta.data.telefone_one : undefined,
          telefone_one_whatsapp:resposta.data.hasOwnProperty('telefone_one_whatsapp') ? resposta.data.telefone_one_whatsapp : undefined,
          telefone_two:resposta.data.hasOwnProperty('telefone_two') ? resposta.data.telefone_two : undefined,
          telefone_two_whatsapp:resposta.data.hasOwnProperty('telefone_two_whatsapp') ? resposta.data.telefone_two_whatsapp : undefined,
          tipo_horario_acesso:resposta.data.tipo_horario_acesso,
          troca_senha:resposta.data.troca_senha,
          usuario_cadastro:resposta.data.hasOwnProperty('usuario_cadastro') ? resposta.data.usuario_cadastro : undefined,
          usuario_id:resposta.data.id
        }
      });
    }else{
      this.setState({
        userData:{
          acessos:resposta.data.acessos,
          clinicas_acesso:resposta.data.clinicas.map(({id}) => ({id})),
          cpf:resposta.data.hasOwnProperty('cpf') ? resposta.data.cpf : undefined,
          cro:resposta.data.hasOwnProperty('cro') ? resposta.data.cro : undefined,
          cro_uf:resposta.data.hasOwnProperty('cro_uf') ? resposta.data.cro_uf : undefined,
          dentista_acessos:resposta.data.dentista_acessos,
          id:resposta.data.id,
          login:resposta.data.login,
          nome:resposta.data.nome,
          nome_telefone_one:resposta.data.hasOwnProperty('nome_telefone_one') ? resposta.data.nome_telefone_one : undefined,
          nome_telefone_two:resposta.data.hasOwnProperty('nome_telefone_two') ? resposta.data.nome_telefone_two : undefined,
          perfil_id:resposta.data.perfil.id,
          //permissions: permissoes,
          rg:resposta.data.hasOwnProperty('rg') ? resposta.data.rg : undefined,
          rg_emissor:resposta.data.hasOwnProperty('rg_emissor') ? resposta.data.rg_emissor : undefined,
          status:'N',
          telefone_one:resposta.data.hasOwnProperty('telefone_one') ? resposta.data.telefone_one : undefined,
          telefone_one_whatsapp:resposta.data.hasOwnProperty('telefone_one_whatsapp') ? resposta.data.telefone_one_whatsapp : undefined,
          telefone_two:resposta.data.hasOwnProperty('telefone_two') ? resposta.data.telefone_two : undefined,
          telefone_two_whatsapp:resposta.data.hasOwnProperty('telefone_two_whatsapp') ? resposta.data.telefone_two_whatsapp : undefined,
          tipo_horario_acesso:resposta.data.tipo_horario_acesso,
          troca_senha:resposta.data.troca_senha,
          usuario_cadastro:resposta.data.hasOwnProperty('usuario_cadastro') ? resposta.data.usuario_cadastro : undefined,
          usuario_id:resposta.data.id
        }
      });
    }

    UserAPI.saveUser(this.state.userData);

    this.closeModalConfirmInactivation()

    window.location.reload();
  }

  closeModalConfirmInactivation = () => {
    this.setState({
      displayConfirmModal: false
    });
  };


  openModalConfirmReactivation = (user) => {
    this.setState({
      displayConfirmModalReactivation: true,
      user:user
    });
  }

  confirmReactivation = async() => {
    const {user} = this.state 

    const resposta = await UserAPI.findById(user.id);

    if (resposta.data.acessos.length > 0) {
      const permissoes = {};

      resposta.data.acessos.forEach((acesso) => {
        
        if(acesso.permissoes[0].restricoes.length > 0){
          permissoes[`mol_${acesso.modulo_id}`] = {
            hasRestriction:true,
            module_id:acesso.modulo_id,
            permission_id:acesso.permissoes[0].permissao_id,
            restrictions:{[`rest_${acesso.permissoes[0].restricoes[0].restricao_id}`]:{restriction_id: acesso.permissoes[0].restricoes[0].restricao_id}} 
          };
        }else{
          permissoes[`mol_${acesso.modulo_id}`] = {
            hasRestriction:false,
            module_id:acesso.modulo_id,
            permission_id:acesso.permissoes[0].permissao_id,
          };
        }
        
      });

      this.setState({
        userData:{
          acessos:resposta.data.acessos,
          clinicas_acesso:resposta.data.clinicas.map(({id}) => ({id})),
          cpf:resposta.data.hasOwnProperty('cpf') ? resposta.data.cpf : undefined,
          cro:resposta.data.hasOwnProperty('cro') ? resposta.data.cro : undefined,
          cro_uf:resposta.data.hasOwnProperty('cro_uf') ? resposta.data.cro_uf : undefined,
          dentista_acessos:resposta.data.dentista_acessos,
          id:resposta.data.id,
          login:resposta.data.login,
          nome:resposta.data.nome,
          nome_telefone_one:resposta.data.hasOwnProperty('nome_telefone_one') ? resposta.data.nome_telefone_one : undefined,
          nome_telefone_two:resposta.data.hasOwnProperty('nome_telefone_two') ? resposta.data.nome_telefone_two : undefined,
          perfil_id:resposta.data.perfil.id,
          permissions: permissoes,
          rg:resposta.data.hasOwnProperty('rg') ? resposta.data.rg : undefined,
          rg_emissor:resposta.data.hasOwnProperty('rg_emissor') ? resposta.data.rg_emissor : undefined,
          status:'S',
          telefone_one:resposta.data.hasOwnProperty('telefone_one') ? resposta.data.telefone_one : undefined,
          telefone_one_whatsapp:resposta.data.hasOwnProperty('telefone_one_whatsapp') ? resposta.data.telefone_one_whatsapp : undefined,
          telefone_two:resposta.data.hasOwnProperty('telefone_two') ? resposta.data.telefone_two : undefined,
          telefone_two_whatsapp:resposta.data.hasOwnProperty('telefone_two_whatsapp') ? resposta.data.telefone_two_whatsapp : undefined,
          tipo_horario_acesso:resposta.data.tipo_horario_acesso,
          troca_senha:resposta.data.troca_senha,
          usuario_cadastro:resposta.data.hasOwnProperty('usuario_cadastro') ? resposta.data.usuario_cadastro : undefined,
          usuario_id:resposta.data.id
        }
      });
    }else{
      this.setState({
        userData:{
          acessos:resposta.data.acessos,
          clinicas_acesso:resposta.data.clinicas.map(({id}) => ({id})),
          cpf:resposta.data.hasOwnProperty('cpf') ? resposta.data.cpf : undefined,
          cro:resposta.data.hasOwnProperty('cro') ? resposta.data.cro : undefined,
          cro_uf:resposta.data.hasOwnProperty('cro_uf') ? resposta.data.cro_uf : undefined,
          dentista_acessos:resposta.data.dentista_acessos,
          id:resposta.data.id,
          login:resposta.data.login,
          nome:resposta.data.nome,
          nome_telefone_one:resposta.data.hasOwnProperty('nome_telefone_one') ? resposta.data.nome_telefone_one : undefined,
          nome_telefone_two:resposta.data.hasOwnProperty('nome_telefone_two') ? resposta.data.nome_telefone_two : undefined,
          perfil_id:resposta.data.perfil.id,
          //permissions: permissoes,
          rg:resposta.data.hasOwnProperty('rg') ? resposta.data.rg : undefined,
          rg_emissor:resposta.data.hasOwnProperty('rg_emissor') ? resposta.data.rg_emissor : undefined,
          status:'S',
          telefone_one:resposta.data.hasOwnProperty('telefone_one') ? resposta.data.telefone_one : undefined,
          telefone_one_whatsapp:resposta.data.hasOwnProperty('telefone_one_whatsapp') ? resposta.data.telefone_one_whatsapp : undefined,
          telefone_two:resposta.data.hasOwnProperty('telefone_two') ? resposta.data.telefone_two : undefined,
          telefone_two_whatsapp:resposta.data.hasOwnProperty('telefone_two_whatsapp') ? resposta.data.telefone_two_whatsapp : undefined,
          tipo_horario_acesso:resposta.data.tipo_horario_acesso,
          troca_senha:resposta.data.troca_senha,
          usuario_cadastro:resposta.data.hasOwnProperty('usuario_cadastro') ? resposta.data.usuario_cadastro : undefined,
          usuario_id:resposta.data.id
        }
      });
    }

    UserAPI.saveUser(this.state.userData);

    this.closeModalConfirmReactivation()
    
    window.location.reload();
  }



  closeModalConfirmReactivation = () => {
    this.setState({
      displayConfirmModalReactivation: false
    });
  }


  deleteUser = async () => {
    const { showLoader, hideLoader, findAll, activePage, totalPage } = this.props;
    const { userDelete } = this.state;

    try {
      this.closeModalConfirm();
      showLoader();
      await UserAPI.deleteUser(userDelete.id);
      findAll({ currentPage: activePage, pageSize: totalPage });
      hideLoader();
      this.setState({
        messageDisplay: true,
        messageType: 'info',
        messageText: 'Usuário Deletado com Sucesso'
      });

      setTimeout(() => {
        this.setState({
          messageDisplay: false,
          messageType: 'info',
          messageText: ''
        });
      }, 4000);
    } catch (e) {
      this.closeModalConfirm();
      hideLoader();
      this.setState({
        messageDisplay: true,
        messageType: 'danger',
        messageText: 'Erro ao excluir usuário !'
      });
    }
  };

  _addNewUser = () => {
    const { showLoader, hideLoader, showRegisterModal } = this.props;
    showLoader();
    ClientApi.checkIfCanAddUser()
      .then(({ data }) => {
        const { totalUsers, totalUsersPlan, canCreate, trial } = data;

        if (data.canCreate) {
          showRegisterModal();
        } else {
          let title = '';
          let message = '';

          if (trial) {
            title = 'Limite de usuários no período de teste';
            message = `No período de teste, são permitidos até ${totalUsersPlan} usuários.`;
          } else {
            title = 'Limite de usuário';
            message = `Seu plano só permite a criação de ${totalUsersPlan} usuários.`;
          }

          ToastUtils.time(<ToastCustomMessageComponent message={message} iconImage={danger} title={title} />, {
            autoClose: 4000,
            hideProgressBar: true,
            position: 'top-center'
          });
        }
        hideLoader();
      })
      .catch((err) => {
        console.error(err);
        ToastUtils.error('Erro ao adicionar usuário, entre em contato com o suporte');
        hideLoader();
      });
  };

  saveData = async (values) => {
    const { showLoader, hideLoader, usersFindAllSaga, formData } = this.props;


    if (formData.initForm.usuario_id) {
      values.id = formData.initForm.usuario_id;
      this.setState({ usuarioId: formData.initForm.usuario_id });
    }

    if (this.state.tab === 'cadastro') {
      if (this.validateFormCadastro(values)) {
        try {
          showLoader();

          const userSaved = await UserAPI.saveUser(values);
          const usuarioId = userSaved.data.usuario_id;

          this.setState({ usuarioId });
          hideLoader();

          this.setState({
            tab: 'permissoes'
          });
        } catch (error) {
          hideLoader();
          const { response = {} } = error;
          const { data = {} } = response;

          const messageError = data.message || '';

          console.log(data);

          let message = 'Erro ao salvar usuário';
          if (messageError.includes('UC_USERNAME')) {
            message = 'E-mail já cadastrado por favor selecione outro';
          }

          if (messageError.includes('Já existe um usuário Dentista Master')) {
            message = messageError;
          }

          this.setState({
            errorMessage: {
              display: true,
              type: 'danger',
              message
            }
          });
        }
      }
    }
  };

  ativosInativos = (value) => {
    if(value == 'N'){
      if (Object.keys(this.props.all).length === 0){
        this.props.showLoader();
        this.props.findAll({ currentPage: 1, pageSize: 99999 });
      }

      this.setState({
        filter:'N',
      })

    }else{
      this.setState({
        filter:'S'
      })
    }
  }



  render() {
    const {
      activePage,
      changeUserModal,
      displayConfirmModal,
      displayConfirmModalReactivation,
      messageDisplay,
      messageType,
      messageText,
      user,
      activeUsers
    } = this.state;
    const { all = {}, findAll, security } = this.props;
    const { data = [], totalPages = 0 } = all;
    const settingsPerm = PERMISSOES.modules.configuracoes;

    return (
      <div className='content-box-without-color mt-4'>
        <Container>
          <MessagesComponent display={messageDisplay} type={messageType} message={messageText} />
          <div className='box-default'>
            <Row>
              <Col sm='12'>
                <Row>
                  <Col sm='0' className='d-flex align-items-center'>
                    <strong className='title-medium-plus text-uppercase text-secondary font-bold' style={{fontSize:18}}>Usuários</strong>
                    <IfComponent
                      conditional={
                        security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar) ||
                        (security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar_restricao.id) &&
                          security.hasRestriction(
                            settingsPerm.id,
                            settingsPerm.permissoes.editar_restricao.id,
                            settingsPerm.permissoes.editar_restricao.restricoes.editar_usuarios
                          ))
                      }
                    >
                      <a id='userAdd' href='#' onClick={this._addNewUser}>
                        <Icon
                          //style={{ marginTop: -5, marginBottom: 5 }}
                          className='icon icon-size-small margin-left-10'
                          image={plus}
                          alt='plus'
                        />
                      </a>
                      <UncontrolledTooltip placement='top' target='userAdd' className='tooltip-white'>
                        Adicionar Usuário
                      </UncontrolledTooltip>
                    </IfComponent>
                  </Col>

                  <Col sm='2' className='pl-5'>
                    <RadioGroup
                      id=''
                      name='filtro'
                      className='radio-griksFilter child-half'
                      value={this.state.filter}
                      onChange={this.ativosInativos}
                      horizontal
                    >
                      <RadioButton pointColor={'#b93cef'} value='S'>
                        <a data-tip='Exibir Apenas Usuários Ativos' data-for='userAtivos'>
                          Ativos
                        </a>
                      </RadioButton>   

                      <RadioButton pointColor={'#b93cef'} value='N'>
                        <a data-tip='Exibir Todos Usuários' data-for='allUsers'>
                          Todos
                        </a>
                      </RadioButton>                  
                    </RadioGroup>
                  </Col>
                  <ReactTooltip className='tooltip-griks' id='userAtivos' place='top' type='light'/>
                  <ReactTooltip className='tooltip-griks' id='allUsers' place='top' type='light'/>  
                </Row>
                <div className='content-overflow-default margin-top-10 table-appointment'>
                  <Table className='table-header-medium' responsive>
                    <thead style={{ paddingRight: 18 }}>
                      <tr className='row pb-0'>
                        <th className='col-sm-2'>Nome</th>
                        <th className='col-sm-2'>Categoria/Perfil</th>
                        <th className='col-sm-3'>Dentistas aos quais tem Acesso</th>
                        <th className='col-sm-3'>Criado por</th>
                        <th className='col-sm-1'>Status</th>
                        <th className='col-sm-1'></th>
                      </tr>
                    </thead>
                  </Table>
                </div>
                <div className='content-overflow-default table-appointment'>
                  <Table className='table-header-medium' responsive>
                    <tbody style={{ maxHeight: 350 }}>
                      {
                      (this.state.filter === 'S' ? (activeUsers ? activeUsers : []) : data).map((u) => {
                        return (
                          <div>
                            <IfComponent conditional={u.status === 'N'}>
                              <tr key={u.id} className='row pb-0'>
                                <td className='col-sm-2' >
                                  <p style={{opacity:0.5}}>{u.nome}</p>
                                </td>
                                <td className='col-sm-2'>
                                  <p style={{opacity:0.5}}>
                                      {u.perfil.nome}

                                    <IfComponent conditional={u.perfil.id === 1}>
                                      <a href={'#'} id={'dentistaAdmnistrador'} className={'text-alert'}>
                                        {' '}
                                        *{' '}
                                      </a>
                                      <UncontrolledTooltip
                                        className='tooltip-white'
                                        placement='top'
                                        target='dentistaAdmnistrador'
                                      >
                                        Administrador Master
                                        <br /> Responsável pela assinatura
                                      </UncontrolledTooltip>
                                    </IfComponent>
                                  </p>
                                </td>
                                <td className='col-sm-3'>
                                  {u.perfil.id === 1 ? (
                                    <p style={{opacity:0.5}}>Todos</p>
                                  ) : (
                                    u.dentista_acessos.map((d) => {
                                      return <p style={{opacity:0.5}} key={d.id}>{d.nome}</p>;
                                    })
                                  )}
                                </td>
                                <td className='col-sm-3'>
                                  <p style={{opacity:0.5}}>{u.usuario_auditoria && u.usuario_auditoria.nome}</p>
                                </td>
                                <td className='col-sm-1'>
                                  <p className='text-danger'>Inativo</p>
                                    {
                                      //<p className='danger'>Inativo</p>
                                      //<p style={{color:'#9B1D27'}}>Inativo</p>
                                    }
                                </td>
                                <td className='col-sm-1 text-right'>
                                  <IfComponent
                                    conditional={
                                      security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar) ||
                                      (security.hasPermission(
                                        settingsPerm.id,
                                        settingsPerm.permissoes.editar_restricao.id
                                      ) &&
                                        security.hasRestriction(
                                          settingsPerm.id,
                                          settingsPerm.permissoes.editar_restricao.id,
                                          settingsPerm.permissoes.editar_restricao.restricoes.editar_usuarios
                                        ))
                                    }
                                  >
                                    <UncontrolledDropdown direction='left'>
                                      <DropdownToggle>
                                        <img src={menuDots} style={{ width: 20 }} />
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        {this.state.master && u.perfil.id === 1 && (
                                          <DropdownItem onClick={() => this.changeMaster(u.id)}>
                                            <span className='text-sm'>Trocar Administrador</span>
                                          </DropdownItem>
                                        )}

                                        {
                                          /*
                                            <DropdownItem onClick={() => this.editUser(u.id)}>
                                              <span className='text-sm'>Editar</span>
                                            </DropdownItem>

                                          */
                                        }                                        
                                        <DropdownItem onClick={() => this.openModalConfirmReactivation(u)}>
                                          <span className='text-sm'>Reativação</span>
                                        </DropdownItem>
                                        
                                        {/*

                                          <IfComponent conditional={u.status === 'S'}>
                                          <DropdownItem onClick={() => this.openModalConfirmInactivation(u)}>
                                            <span className='text-sm'>Inativar</span>
                                          </DropdownItem>
                                          </IfComponent>

                                          <IfComponent conditional={![1, 2].includes(u.perfil.id)}>
                                        
                                          <DropdownItem onClick={() => this.openModalConfirm(u)}>
                                            <span className='text-sm'>Excluir</span>
                                            </DropdownItem>
                                        
                                        </IfComponent>
                                        */}

                                      </DropdownMenu>
                                      
                                    </UncontrolledDropdown>
                                  </IfComponent>
                                </td>
                              </tr>
                            </IfComponent>

                            <IfComponent conditional={u.status === 'S'}>
                            <tr key={u.id} className='row pb-0'>
                                <td className='col-sm-2'>
                                  <p>{u.nome}</p>
                                </td>
                                <td className='col-sm-2'>
                                  <p>
                                      {u.perfil.nome}

                                    <IfComponent conditional={u.perfil.id === 1}>
                                      <a href={'#'} id={'dentistaAdmnistrador'} className={'text-alert'}>
                                        {' '}
                                        *{' '}
                                      </a>
                                      <UncontrolledTooltip
                                        className='tooltip-white'
                                        placement='top'
                                        target='dentistaAdmnistrador'
                                      >
                                        Administrador Master
                                        <br /> Responsável pela assinatura
                                      </UncontrolledTooltip>
                                    </IfComponent>
                                  </p>
                                </td>
                                <td className='col-sm-3'>
                                  {u.perfil.id === 1 ? (
                                    <p>Todos</p>
                                  ) : (
                                    u.dentista_acessos.map((d) => {
                                      return <p key={d.id}>{d.nome}</p>;
                                    })
                                  )}
                                </td>
                                <td className='col-sm-3'>
                                  <p>{u.usuario_auditoria && u.usuario_auditoria.nome}</p>
                                </td>
                                <td className='col-sm-1'>
                                  {(u.status === 'S') ?
                                    <p className='text-success'>Ativo</p>
                                    :
                                    <p className='text-danger'>Inativo</p>
                                    //<p className='text-success'>{u.status === 'S' ? 'Ativo' : 'Inativo'}</p>
                                  }
                                </td>
                                <td className='col-sm-1 text-right' style={{background:'trasnparent'}}>
                                  <IfComponent
                                    conditional={
                                      security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar) ||
                                      (security.hasPermission(
                                        settingsPerm.id,
                                        settingsPerm.permissoes.editar_restricao.id
                                      ) &&
                                        security.hasRestriction(
                                          settingsPerm.id,
                                          settingsPerm.permissoes.editar_restricao.id,
                                          settingsPerm.permissoes.editar_restricao.restricoes.editar_usuarios
                                        ))
                                    }
                                  >
                                    <UncontrolledDropdown direction='left'>
                                      <DropdownToggle >
                                        <img src={menuDots} style={{ width: 20 }} />
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        {this.state.master && u.perfil.id === 1 && (
                                          <DropdownItem onClick={() => this.changeMaster(u.id)}>
                                            <span className='text-sm'>Trocar Administrador</span>
                                          </DropdownItem>
                                        )}
                                        <DropdownItem onClick={() => this.editUser(u.id)}>
                                          <span className='text-sm'>Editar</span>
                                          </DropdownItem>
                                        
                                          <DropdownItem onClick={() => this.openModalConfirmInactivation(u)}>
                                            <span className='text-sm'>Inativar</span>
                                          </DropdownItem> 

                                        {/*
                                          <IfComponent conditional={![1, 2].includes(u.perfil.id)}>
                                        
                                          <DropdownItem onClick={() => this.openModalConfirm(u)}>
                                            <span className='text-sm'>Excluir</span>
                                            </DropdownItem>
                                        
                                        </IfComponent>
                                        */}

                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </IfComponent>
                                </td>
                              </tr>
                            </IfComponent>

                          </div>
                        );
                      })}
                    </tbody>
                  </Table>



                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <UserSettingsFormRegisterModal showLoader={this.props.showLoader} hideLoader={this.props.hideLoader} />
        <UserSettingsChangeMasterUserModal
          users={activeUsers}
          userSelected={changeUserModal.userSelected}
          showLoader={this.props.showLoader}
          hideLoader={this.props.hideLoader}
          modal={changeUserModal.modal}
          closeModal={this.closeModalChangeMasterUser}
          refreshList={findAll}
        />
        <UserSettingsSucessoModal />


        <Modal isOpen={displayConfirmModal} toggle={this.closeModalConfirmInactivation} size='small' className={'patientMessage'}>
            <ModalHeader
              className='m-0 title-primary'
              toggle={this.closeModalConfirmInactivation}
              style={{paddingRight:30,paddingLeft:30,paddingTop:30}}
              close={
                <button className='close' onClick={this.closeModalConfirmInactivation}>
                  &times;
                </button>
              }
            >
              <strong>Confirma a Inativação?</strong>
            </ModalHeader>
            
            <ModalBody className='justify-content-center align-items-center' style={{paddingRight:30,paddingLeft:30}}>
                <div className='modal-icon'>
                  <Icon className='' image={warning} alt='warning' />
                </div> 
              <div className='margin-top-20'>
                <Row>
                  <Col className='p-0'>
                    <p>Tem certeza que deseja inativar o usuário <strong>{user.nome}</strong> ?</p>
                    <p>Após a inativação, ele não conseguirá mais acessar o sistema.</p>
                  </Col>
                </Row>
              </div>
            </ModalBody>
            <ModalFooter style={{paddingRight:30,paddingLeft:30,paddingBottom:30}}>
              <Row>
                <Col sm='3' className='p-0'>
                  <button className='button-primary-exception' onClick={this.closeModalConfirmInactivation}>
                    Não
                  </button>
                </Col>
                <Col sm={{ size: 3, offset: 6 }} className='pr-0'>
                  <button className='button-primary float-right' onClick={this.confirmInactivation}>
                    Sim
                  </button>
                </Col>
              </Row>
            </ModalFooter>
        </Modal>


        <Modal isOpen={displayConfirmModalReactivation} toggle={this.closeModalConfirmReactivation} size='small' className={'patientMessage'}>
          <ModalHeader
            className='m-0 title-primary'
            toggle={this.closeModalConfirmReactivation}
            style={{paddingRight:30,paddingLeft:30,paddingTop:30}}
            close={
              <button className='close' onClick={this.closeModalConfirmReactivation}>
                &times;
              </button>
            }
          >
            <strong>Confirma a Reativação?</strong>
          </ModalHeader>
          
          <ModalBody className='' style={{paddingRight:30,paddingLeft:30}}>
            <div className='modal-icon'>
              <Icon className='' image={warning} alt='warning' />
            </div>
            <div className='margin-top-20'>
              <Row>
                <Col className='p-0'>
                  <p>Confirma a Reativação do usuário <strong>{user.nome}</strong> ?</p>
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter style={{paddingRight:30,paddingLeft:30,paddingBottom:30}}>
            <Row>
              <Col sm='3' className='p-0'>
                <button className='button-primary-exception' onClick={this.closeModalConfirmReactivation}>
                  Não
                </button>
              </Col>
              <Col sm={{ size: 3, offset: 6 }} className='pr-0'>
                <button className='button-primary float-right' onClick={this.confirmReactivation}>
                  Sim
                </button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ UserSettingsReducer }) => {
  const { all = {} } = UserSettingsReducer;
  return {
    all
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    findAll: (data) => dispatch(usersFindAllSaga(data)),
    showLoader: (data) => dispatch(showLoader()),
    hideLoader: (data) => dispatch(hideLoader()),
    showRegisterModal: (data) => dispatch(settingsRegisterUserModalForm({ modal: true })),
    showEditModal: (data) => dispatch(settingsRegisterUserModalForm(data))
  };
};

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withSecurity);

export default enhanced(UserSettingsHomePage);