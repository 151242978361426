import t from 'typy';
import { createSelector } from 'reselect';

const cashCloseSelector = (state) => t(state, 'CashReducer.closeModal').safeObject;

export const cashCloseModalSelectorToProps = createSelector(cashCloseSelector, (closeModal = {}) => {
  return {
    modal: closeModal.modal,
    usuario: closeModal.usuario
  };
});
