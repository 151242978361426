import { scheduleAndRedialAdd, scheduleAndRedialPage } from '../redux/Schedule.actions';
import { put, call } from 'redux-saga/effects';
import ScheduleAPI from '../../../service/ScheduleAPI';
import ToastUtils from '../../../utils/ToastUtils';
import { showLoader, hideLoader } from '../../../components/actions/Loader.actions';
import DomainsAPI from '../../../service/DomainsAPI';

export function* scheduleAndRedialListSaga({ payload: { dentist, order } }) {
  yield put(showLoader());
  try {
    const { data: listScheduleRedial } = yield call(ScheduleAPI.getScheduleRedial, { dentist: dentist.value });
    if (order) {
      listScheduleRedial.sort((a, b) => {
        const bandA = a.motivo.toUpperCase();
        const bandB = b.motivo.toUpperCase();

        let comparison = 0;
        if (order == 'up') {
          if (bandA > bandB) {
            comparison = 1;
          } else if (bandA < bandB) {
            comparison = -1;
          }
        } else {
          if (bandA < bandB) {
            comparison = 1;
          } else if (bandA > bandB) {
            comparison = -1;
          }
        }
        return comparison;
      });
    }
    yield put(scheduleAndRedialPage({ dentist, listScheduleRedial }));
  } catch (e) {
    console.error(e);
    ToastUtils.error('Erro ao buscar lista de agendar e reagendar paciente');
  }
  yield put(hideLoader());
}

export function* scheduleAndRedialAddModal({ payload: { dentist, patient } }) {
  try {
    yield put(scheduleAndRedialAdd({ modal: true, dentist, groups: [], patient }));
  } catch (e) {
    console.error(e);
    ToastUtils.error('Erro ao abrir modal de adicioanr agendar / reagendar entre em contato com o suporte');
  }
}
