import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import edit from '../../../assets/img/icons/edit.svg';
import Icon from '../../../components/Icon.component';
import { IfComponent } from '../../../components/if.component';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import MessagesComponent from '../../../components/Messages.component';
import TreatmentClinicalRecordTreatmentPlanGoalsModal from './modals/TreatmentClinicalRecordTreatmentPlanGoals.modal';
import {
  clinicalRecordDiagnosticProblemsModal,
  clinicalRecordTreatmentContentionModal,
  clinicalRecordTreatmentGadgetsPreModal,
  clinicalRecordTreatmentOthersDetailsModal,
  clinicalRecordTreatmentPlanModal,
  clinicalRecordTreatmentStrategiesModal
} from './redux/TreatmentClinicalRecord.action';
import TreatmentClinicalRecordTreatmentPlanStrategiesModal from './modals/TreatmentClinicalRecordTreatmentPlanStrategies.modal';
import TreatmentClinicalRecordTreatmentPlanOthersDetailsModal from './modals/TreatmentClinicalRecordTreatmentPlanOthersDetails.modal';
import TreatmentClinicalRecordContentionModal from './modals/TreatmentClinicalRecordContention.modal';
import TreatmentClinicalRecordTreatmentPlanGadgetsModal from './modals/TreatmentClinicalRecordTreatmentPlanGadgets.modal';
import withSecurity from '../../../config/security/security';
import { PERMISSOES } from '../../../config/security/permissoes';
import { t } from 'typy';
import TreatmentClinicalRecordDiagnosticProblemsModal from './modals/TreatmentClinicalRecordDiagnosticProblems.modal';
import down from '../../../assets/img/icons/arrow.svg';
import TreatmentClinicalRecordMaxilaModal from './modals/TreatmentClinicalRecordMaxila.modal';
import TreatmentClinicalRecordMandibulaModal from './modals/TreatmentClinicalRecordMandibula.modal';

class TreatmentClinicalRecordPlanPage extends Component {
  maxilaModalRef;
  mandibulaModalRef;

  constructor(props) {
    super(props);
    this.maxilaModalRef = React.createRef();
    this.mandibulaModalRef = React.createRef();
  }

  render() {
    const {
      treatment,
      record,
      show,
      error,
      plan = {},
      updateParent,
      treatmentPlanModal,
      treatmentPlanStrategiesModal,
      treatmentPlanOthersDetailsModal,
      treatmentPlanContentionModal,
      treatmentPlanGadgetsModal,
      enabledEdit,
      security,
      problems,
      diagnostic
    } = this.props;
    const {
      objetivos = [],
      estrategias = [],
      aparelhos_dispositivos = [],
      outros_detalhes = [],
      contencao = {},
      sequenciasMaxila = [],
      sequenciasMandibula = []
    } = plan;

    const clinicalRecordPermission = PERMISSOES.modules.tratamento_ficha_clinica;
    const hasPermission =
      security.hasPermission(clinicalRecordPermission.id, clinicalRecordPermission.permissoes.editar) ||
      security.hasPermission(clinicalRecordPermission.id, clinicalRecordPermission.permissoes.editar_restricao.id);

    return [
      <div key={'page'}>
        <IfComponent conditional={error}>
          <MessagesComponent display={true} type={'danger'} message={error} />
        </IfComponent>

        <IfComponent conditional={show}>
          <Row className='margin-top-10'>
            <Col sm='12'>
              <div className='box-default'>
                <Row>
                  <Col className='pb-2 pr-4' sm='6'>
                    <h2 className='title-medium-plus text-secondary text-uppercase mb-3'>
                      Lista de Problemas/Diagnóstico
                      <IfComponent conditional={enabledEdit && hasPermission}>
                        <a
                          href={'#'}
                          onClick={() => {
                            problems({ modal: true, diagnostic, treatment, record });
                          }}
                        >
                          <Icon className='icon icon-size-small margin-left-10' image={edit} alt='edit' />
                        </a>
                      </IfComponent>
                    </h2>
                    <h2 className='text-secondary text-md-plus font-bold text-uppercase'>PROBLEMAS</h2>
                    {t(diagnostic, 'listaProblemas').isArray
                      ? diagnostic.listaProblemas.map((l, index) => (
                          <p className={'w-100 border-bottom px-1 py-2'} key={index}>
                            <strong>{index + 1}.</strong>
                            <span className='ml-1 text-secondary'>{l.problema}</span>
                          </p>
                        ))
                      : null}
                  </Col>
                  <Col className='pb-2' sm='6'>
                    <h2 className='title-secondary text-secondary text-uppercase font-bold mb-3'>
                      Estratégias
                      <IfComponent conditional={enabledEdit && hasPermission}>
                        <a
                          href={'#'}
                          onClick={() => {
                            treatmentPlanStrategiesModal({
                              modal: true,
                              treatment,
                              record,
                              plan
                            });
                          }}
                        >
                          <Icon className='icon icon-size-small margin-left-10' image={edit} alt='edit' />
                        </a>
                      </IfComponent>
                    </h2>
                    <h2 className='text-secondary text-md-plus font-bold text-uppercase'>Como resolver</h2>
                    {estrategias.map((e, index) => (
                      <p className={'py-2 ml-1 border-bottom'} key={'estra' + e.id}>
                        <strong className={'ml-2'}>{index + 1}.</strong>{' '}
                        <span className='ml-1 text-secondary'>{e.estregia}</span>
                      </p>
                    ))}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row className='margin-top-10'>
            <Col sm='12'>
              <div className='box-default'>
                <Row>
                  <Col className='pb-2 pr-4' sm='6'>
                    <h2 className='title-secondary text-secondary text-uppercase font-bold'>
                      Aparelhos e Outros Dispositivos
                      <IfComponent conditional={enabledEdit && hasPermission}>
                        <a
                          href={'#'}
                          onClick={() => {
                            treatmentPlanGadgetsModal({
                              modal: true,
                              treatment,
                              record,
                              plan
                            });
                          }}
                        >
                          <Icon className='icon icon-size-small margin-left-10' image={edit} alt='edit' />
                        </a>
                      </IfComponent>
                    </h2>
                    {aparelhos_dispositivos.map((a, index) => (
                      <p className={'py-2 ml-1 border-bottom'} key={'apd' + a.id}>
                        <strong className={'ml-2'}>{index + 1}.</strong> {a.aparelho_dispositivo.nome}
                      </p>
                    ))}
                  </Col>
                  <Col className='pb-2' sm='6'>
                    <h2 className='title-secondary text-secondary text-uppercase font-bold'>
                      Outros Detalhes do Plano de Tratamento
                      <IfComponent conditional={enabledEdit && hasPermission}>
                        <a
                          href={'#'}
                          onClick={() => {
                            treatmentPlanOthersDetailsModal({
                              modal: true,
                              treatment,
                              record,
                              plan
                            });
                          }}
                        >
                          <Icon className='icon icon-size-small margin-left-10' image={edit} alt='edit' />
                        </a>
                      </IfComponent>
                    </h2>
                    {outros_detalhes.map((o, index) => (
                      <p className={'py-2 ml-1 border-bottom'} key={'outos' + o.id}>
                        <strong className={'ml-2'}>{index + 1}.</strong> {o.outros_detalhes}
                      </p>
                    ))}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row className='margin-top-10'>
            <Col sm='12'>
              <div className='box-default'>
                <Row>
                  <Col className='pb-2 pr-4' sm='6'>
                    <h2 className='title-medium-plus text-secondary text-uppercase mb-3'>Sequência Clínica</h2>
                    <h2 className='text-secondary text-md-plus font-bold text-uppercase'>
                      MAXILA
                      <a
                        href={'#'}
                        onClick={() => {
                          this.maxilaModalRef.current.openModal({
                            sequencias: sequenciasMaxila,
                            treatment,
                            record,
                            plan
                          });
                        }}
                      >
                        <Icon className='icon icon-size-small margin-left-10' image={edit} alt='edit' />
                      </a>
                    </h2>
                    {t(sequenciasMaxila).isArray
                      ? sequenciasMaxila.map((l, index) => (
                          <p className={'w-100 border-bottom px-1 py-2'} key={index}>
                            <strong>{index + 1}.</strong>
                            <span className='ml-1 text-secondary'>{l.sequencia}</span>
                          </p>
                        ))
                      : null}
                  </Col>
                  <Col className='pb-2' sm='6' style={{ marginTop: '2.2rem' }}>
                    <h2 className='text-secondary text-md-plus font-bold text-uppercase'>
                      MANDÍBULA
                      <a
                        href={'#'}
                        onClick={() => {
                          this.mandibulaModalRef.current.openModal({
                            sequencias: sequenciasMandibula,
                            treatment,
                            record,
                            plan
                          });
                        }}
                      >
                        <Icon className='icon icon-size-small margin-left-10' image={edit} alt='edit' />
                      </a>
                    </h2>
                    {t(sequenciasMandibula).isArray
                      ? sequenciasMandibula.map((l, index) => (
                          <p className={'w-100 border-bottom px-1 py-2'} key={index}>
                            <strong>{index + 1}.</strong>
                            <span className='ml-1 text-secondary'>{l.sequencia}</span>
                          </p>
                        ))
                      : null}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row className='margin-top-10'>
            <Col sm='12'>
              <div className='box-default'>
                <Row>
                  <Col sm='12'>
                    <h2 className='title-secondary text-secondary text-uppercase font-bold'>
                      Contenção
                      <IfComponent conditional={enabledEdit && hasPermission}>
                        <a
                          href={'#'}
                          onClick={() => {
                            treatmentPlanContentionModal({
                              modal: true,
                              treatment,
                              record,
                              plan
                            });
                          }}
                        >
                          <Icon className='icon icon-size-small margin-left-10' image={edit} alt='edit' />
                        </a>
                      </IfComponent>
                    </h2>
                    <br />
                  </Col>
                </Row>
                <Row>
                  <Col sm='6' style={{ marginRight: -10, marginTop: -20 }}>
                    <h2 className='text-default font-bold text-secondary text-uppercase'>Fase Inicial</h2>
                    <p className={'py-2 border-top border-secondary-l4 text-secondary-d4'}>
                      <strong className={'text-secondary-d4'}>Conteção Superior</strong> {contencao.contencao_superior}
                    </p>
                    <p className={'py-2 border-top border-secondary-l4 text-secondary-d4'}>
                      <strong className={'text-secondary-d4'}>Contenção Inferior</strong> {contencao.contencao_inferior}
                    </p>
                  </Col>
                  <Col sm='6' style={{ marginTop: -20 }}>
                    <h2 className='text-default font-bold text-secondary text-uppercase'>Fase Complementar</h2>
                    <p className={'py-2 border-top border-secondary-l4 text-secondary-d4'}>
                      <strong className={'text-secondary-d4'}>Contenção Superior</strong>{' '}
                      {contencao.contencao_superior_complementar}
                    </p>
                    <p className={'py-2 border-top border-secondary-l4 text-secondary-d4'}></p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </IfComponent>
      </div>,
      <TreatmentClinicalRecordTreatmentPlanGoalsModal
        key={'TreatmentClinicalRecordTreatmentPlanGoalsModal'}
        updateParent={updateParent}
      />,
      <TreatmentClinicalRecordTreatmentPlanStrategiesModal
        key={'TreatmentClinicalRecordTreatmentPlanStrategiesModal'}
        updateParent={updateParent}
      />,
      <TreatmentClinicalRecordTreatmentPlanOthersDetailsModal
        key={'TreatmentClinicalRecordTreatmentPlanOthersDetailsModal'}
        updateParent={updateParent}
      />,
      <TreatmentClinicalRecordContentionModal
        key={'TreatmentClinicalRecordContentionModal'}
        updateParent={updateParent}
      />,

      <TreatmentClinicalRecordTreatmentPlanGadgetsModal
        key={'TreatmentClinicalRecordTreatmentPlanGadgetsModal'}
        updateParent={updateParent}
      />,

      <TreatmentClinicalRecordDiagnosticProblemsModal
        key={'TreatmentClinicalRecordDiagnosticProblemsModal'}
        updateParent={updateParent}
      />,
      <TreatmentClinicalRecordMaxilaModal
        key={'TreatmentClinicalRecordMaxilaModal'}
        ref={this.maxilaModalRef}
        updateParent={updateParent}
      />,
      <TreatmentClinicalRecordMandibulaModal
        key={'TreatmentClinicalRecordMandibulaModal'}
        ref={this.mandibulaModalRef}
        updateParent={updateParent}
      />
    ];
  }
}

const mapStateToProps = (state) => {
  const { TreatmentClinicalRecordReducer } = state;

  return { ...TreatmentClinicalRecordReducer.treatmentPlanPage };
};

const mapDispatchToProps = (dispatch) => ({
  treatmentPlanModal: (data) => dispatch(clinicalRecordTreatmentPlanModal(data)),
  treatmentPlanStrategiesModal: (data) => dispatch(clinicalRecordTreatmentStrategiesModal(data)),
  treatmentPlanOthersDetailsModal: (data) => dispatch(clinicalRecordTreatmentOthersDetailsModal(data)),
  treatmentPlanContentionModal: (data) => dispatch(clinicalRecordTreatmentContentionModal(data)),
  treatmentPlanGadgetsModal: (data) => dispatch(clinicalRecordTreatmentGadgetsPreModal(data)),
  // Movido da aba diagnostivo
  problems: (data) => dispatch(clinicalRecordDiagnosticProblemsModal(data))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withSecurity);

export default enhanced(TreatmentClinicalRecordPlanPage);
