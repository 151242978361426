import React, { Component } from 'react';
import { Col, Container, Row, Table } from 'reactstrap';
import queryString from 'query-string';
import ScheduleAPI from '../../service/ScheduleAPI';
import ToastUtils from '../../utils/ToastUtils';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { hideLoader, showLoader } from '../../components/actions/Loader.actions';
import moment from 'moment';
import UserAPI from '../../service/User.service';

class ScheduleConfirmationsReportPage extends Component {
  state = {
    confirmations: [],
    date: moment()
  };

  componentDidMount() {
    const { showLoader, hideLoader } = this.props;
    showLoader();
    const { date, dentist } = queryString.parse(this.props.location.search);

    UserAPI.findById(dentist).then(({ data }) => this.setState({ dentist: data }));

    ScheduleAPI.findAppointmentsForConfirmations({
      dentist: dentist,
      data: date
    })
      .then(({ data }) => {
        hideLoader();
        this.setState({ confirmations: data, date: moment(date) }, () => {
          setTimeout(() => window.print(), 500);
        });
      })
      .catch((err) => {
        hideLoader();
        ToastUtils.error('Erro ao buscar confirmações entre em contato com o suporte');
      });
  }

  render() {
    const { date, confirmations = [], dentist = {} } = this.state;
    return (
      <Container style={{ padding: 10 }}>
        <div className='box-search'>
          <Row>
            <Col sm={12}>
              <span className={'title-primary'}> Relatório de Agendamento</span>
            </Col>
          </Row>
          <Row>
            <Col sm={2}>
              <span className={'title-medium'}>
                {' '}
                <stong>Dia</stong> {date.format('DD/MM/YY - dddd')}
              </span>
            </Col>
            <Col sm={10}>
              <span className={'title-medium'}>
                {' '}
                <strong>Dr(a)</strong> {dentist.nome}
              </span>
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <Table responsive bordered>
                <thead>
                  <tr>
                    <th width='10%'>Hora</th>
                    <th width='25%'>Paciente</th>
                    <th>Nr</th>
                    <th>Anotações</th>
                  </tr>
                </thead>
                <tbody>
                  {confirmations.map((c) => {
                    return (
                      <tr>
                        <td>{c.horario}</td>
                        <td>{c.paciente.nome}</td>
                        <td>{c.paciente.numero_identificacao}</td>
                        <td></td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader())
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(ScheduleConfirmationsReportPage);
