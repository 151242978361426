import React, { Component } from 'react';
import PatientOverviewReportPage from './PatientOverviewReportPage';
import { IfComponent } from '../../../components/if.component';
import PatientReportsMenu from './PatientReportsMenu';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { loadPagePatientOverviewReport } from './store/PatientReportStore';

class PatientsReportsPage extends Component {
  state = {
    tabSelected: null
  };

  selectedTab = (tab) => {
    this.setState({ tabSelected: tab });
    switch (tab) {
      case 'RG':
        this.props.loadPatientOverviewReport();
        break;
    }
  };

  componentDidMount() {
    this.selectedTab('RG');
  }

  render() {
    const { tabSelected } = this.state;

    return (
      <div>
        <PatientReportsMenu selectTab={this.selectedTab} menuSelected={tabSelected} />
        <IfComponent conditional={tabSelected === 'RG'}>
          <PatientOverviewReportPage />
        </IfComponent>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  loadPatientOverviewReport: (data) => dispatch(loadPagePatientOverviewReport())
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(PatientsReportsPage);
