import React from 'react';

import MaskedInput from 'react-text-mask';

import PropTypes from 'prop-types';

import { Input } from 'reactstrap';

const InputMaskComponent = ({
  id,
  name,
  mask,
  value,
  guide,
  onChange,
  onBlur,
  invalid,
  placeholder,
  readOnly,
  className = 'input-secondary'
}) => {
  return (
    <MaskedInput
      id={id}
      name={name}
      mask={mask}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      guide={guide}
      placeholder={placeholder}
      render={(ref, props) => (
        <Input className={className} innerRef={ref} invalid={invalid} {...props} readOnly={readOnly} />
      )}
    />
  );
};

InputMaskComponent.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  mask: PropTypes.array.isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  invalid: PropTypes.bool.isRequired,
  guide: PropTypes.bool.isRequired,
  placeholder: PropTypes.string
};

export default InputMaskComponent;
