import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import PatientNotesComponent from './components/PatientNotes.component';

import { compose } from 'recompose';
import { withLoader } from '../../components/Loader.component';

import whatscolor from '../../assets/img/icons/whats.svg';
import plus from '../../assets/img/icons/plus.svg';

import phonecolor from '../../assets/img/icons/phonecolor.svg';
import edit from '../../assets/img/icons/edit.svg';
import noavatar from '../../assets/img/noavatar.png';
import Icon from '../../components/Icon.component';
import BaseComponent from '../../config/component/BaseComponent';
import ErrorMessageComponent from '../../components/ErrorMessage.component';
import PacienteAPI from '../../service/PacienteAPI';
import { dateFormat, cpfFormat, rgFormat, phoneFormat } from '../../utils/Formatter';

import PatientDeleteModal from './modals/PatientDelete.modal';

import Loader from '../../components/Loader.component';
import { showLoader, hideLoader } from '../../components/actions/Loader.actions';
import { connect } from 'react-redux';
import PatientHeaderComponent from './components/PatientHeader.component';
import { setHeader } from '../template/redux/Content.actions';
import PatientEditModal from './modals/PatientEdit.modal';
import { showPatientEditModal } from './redux/patient.actions';
import { PERMISSOES } from '../../config/security/permissoes';
import withSecurity from '../../config/security/security';
import { IfComponent } from '../../components/if.component';
import { IoLogoWhatsapp } from 'react-icons/io';
import moment from 'moment';

class PatientRegistrationFormPage extends Component {
  state = {
    patientId: 0,
    patient: {},
    notesModal: false,
    showDeleteModal: false,
    message: {
      display: false,
      type: 'danger',
      message: ''
    }
  };

  refresh = () => this.findPatient();

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.props.match.params.patientId !== nextProps.match.params.patientId) {
      this._loadPage(nextProps.match.params.patientId);
    }
    return true;
  }

  componentDidMount() {
    const { patientId } = this.props.match.params;
    this._loadPage(patientId);
  }

  _loadPage = (patientId) => {
    this.props.setHeader({ title: '' });
    this.image = edit;

    this.setState(
      {
        patientId
      },
      () => this.findPatient()
    );
  };

  showMessage = (message, type) => {
    this.setState(
      {
        message: {
          display: true,
          message,
          type
        }
      },
      () => {
        setTimeout(() => {
          this.setState({
            message: {
              display: false,
              message,
              type
            }
          });
        }, 5000);
      }
    );
  };

  findPatient = () => {
    this.props.showLoader();
    PacienteAPI.findById(this.state.patientId)
      .then((p) => {
        this.setState({
          patient: p
        });

        this.props.hideLoader();
      })
      .catch((err) => {
        console.log(err);
        this.props.hideLoader();
        this.setState({
          error: {
            display: true,
            type: 'danger',
            message: 'Erro ao buscar anotação'
          }
        });
      });
  };

  openNotesModal = () => {
    this.setState({
      notesModal: true
    });
  };

  closeDeleteModal = () => this.setState({ showDeleteModal: false });

  openConfirmDeletePatient = () => this.setState({ showDeleteModal: true });

  closeModalNotes = () => {
    this.setState({
      notesModal: false
    });
  };

  render() {
    const { message, patient, note, notesModal } = this.state;
    console.log('patient',patient)
    const {
      paciente_id,
      foto,
      numero_identificacao,
      nome,
      nome_preferido,
      data_nascimento,
      sexo,
      profissao,
      rg,
      cpf,
      nome_responsavel,
      tipo_responsavel_nome,
      rg_responsavel,
      cpf_responsavel,
      tipo_indicacao_nome,
      nome_indicacao,
      telefones = [],
      email_contato,
      anotacoes,
      meta = {},
      idade
    } = patient;
    const { security } = this.props;

    const modulePermission = PERMISSOES.modules.pacientes;

    return (
      <div className='content-box-without-color'>
        <Loader />
        <PatientHeaderComponent
          id={paciente_id}
          history={this.props.history}
          foto={foto}
          nome={nome}
          numero_identificacao={numero_identificacao}
          idade={idade}
          botao={'CADASTRO'}
        />

        <Row className='registration-container'>
          <Col sm='12'>
            <div className='box-search iconed'>
              <Row className='pb-0'>
                <Col sm={1}>
                  <div className='title-content'>Cadastro</div>
                </Col>
                <Col sm={2}>
                  <IfComponent
                    conditional={
                      security.hasPermission(modulePermission.id, modulePermission.permissoes.editar) ||
                      security.hasPermission(modulePermission.id, modulePermission.permissoes.editar_restricao.id)
                    }
                  >
                    <a
                      href={'#'}
                      onClick={() => {
                        this.props.showPatientEditModal({ display: true, patient: patient });
                      }}
                    >
                      <Icon className='icon icon-size-small' image={edit} alt='edit' />
                    </a>
                  </IfComponent>
                </Col>
              </Row>
              <ErrorMessageComponent
                id={'patientRegistration'}
                where={'patientRegistration'}
                display={message.display}
                type={message.type}
                message={message.message}
              />
              <Row style={{ marginTop: -10 }}>
                <Col sm='3'>
                  <div className='modal-img-user'>
                    <div className='img-person'>
                      <img className='img-circle img-hdpi' src={foto || noavatar} alt='usuario' />
                    </div>
                  </div>
                </Col>
                <Col sm='9'>
                  <div className='box-register margin-ajust'>
                    <Row>
                      <Col sm='4'>
                        <p>
                          <strong className='rl-3'>Nome</strong> {nome}
                        </p>
                      </Col>
                      <Col sm='4'>
                        <p>
                          <strong>Nome Preferido</strong> {nome_preferido}
                        </p>
                      </Col>
                      <Col sm='4'>
                        <p>
                          <strong>Número</strong> {numero_identificacao}
                        </p>
                      </Col>
                      <Col sm='4'>
                        <p>
                          <strong className='rl-3'>Data Nascimento</strong> {moment(data_nascimento).utc().format('DD/MM/YYYY')}
                        </p>
                      </Col>
                      <Col sm='4'>
                        <p>
                          <strong>Sexo</strong> {sexo}
                        </p>
                      </Col>
                      <Col sm='4'>
                        <p>
                          <strong>Profissão</strong> {profissao}
                        </p>
                      </Col>
                      <Col sm='4'>
                        <p>
                          <strong className='rl-3'>RG</strong> {rgFormat(rg)}
                        </p>
                      </Col>
                      <Col sm='4'>
                        <p>
                          <strong>CPF</strong> {cpfFormat(cpf)}
                        </p>
                      </Col>
                    </Row>
                  </div>
                  <hr className='rl-3' />
                  <div className='box-register'>
                    <Row>
                      <Col sm='4'>
                        <p>
                          <strong className='rl-3'>Responsável</strong> {nome_responsavel}
                          {tipo_responsavel_nome && ' (' + tipo_responsavel_nome + ')'}
                        </p>
                      </Col>
                      <Col sm='4'>
                        <p>
                          <strong>RG</strong> {rgFormat(rg_responsavel)}
                        </p>
                      </Col>
                      <Col sm='4'>
                        <p>
                          <strong>CPF</strong> {cpfFormat(cpf_responsavel)}
                        </p>
                      </Col>
                    </Row>
                  </div>
                  <hr className='rl-3' />
                  <div className='box-register'>
                    <Row>
                      <Col sm='4'>
                        <p>
                          <strong className='rl-3'>Tipo Indicação</strong> {tipo_indicacao_nome}
                        </p>
                      </Col>
                      <Col sm='4'>
                        <p>
                          <strong>Quem Indicou</strong> {nome_indicacao}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row className='pt-3'>
                <Col sm='12'>
                  <span className='title-modal '>Telefones e Contatos</span>
                </Col>
              </Row>
              <div className=''></div>
              <Row>
                <Col sm='10' className='pl-0'>
                  <div className='box-register'>
                    {telefones.map((t) => (
                      <Row key={t.id}>
                        <Col sm='6'>
                          <p className='whats-iconed'>
                            <div>
                              <span>
                                {' '}
                                <strong>Tel </strong> {phoneFormat(t.telefone)}
                              </span>
                              {t.tipo_telefone == 'whatsapp' ? (
                                <IoLogoWhatsapp className='icon-whats whats-on mr-4' alt='whatscolor' size={'20'} />
                              ) : (
                                <div style={{ marginLeft: 42 }} className='float-left'>
                                  &nbsp;
                                </div>
                              )}
                            </div>
                          </p>
                          <p> {t.nome_dono_telefone}</p>
                        </Col>
                      </Row>
                    ))}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm='10'>
                  <p>
                    <strong>E-mail</strong> {email_contato}
                  </p>
                </Col>
              </Row>
            </div>
            {meta.can_remove ? (
              <div>
                <Row className='pb-0 pt-2 pr-3'>
                  <Col sm={{ size: 2, offset: 10 }}>
                    <IfComponent
                      conditional={
                        security.hasPermission(modulePermission.id, modulePermission.permissoes.editar) ||
                        !security.hasRestriction(
                          modulePermission.id,
                          modulePermission.permissoes.editar_restricao.id,
                          modulePermission.permissoes.editar_restricao.restricoes.nao_excluir_pacientes
                        )
                      }
                    >
                      <button
                        style={{ width: 160 }}
                        className='button-cancel text-default text-white float-right'
                        onClick={this.openConfirmDeletePatient}
                      >
                        Excluir Paciente
                      </button>
                    </IfComponent>
                  </Col>
                </Row>
              </div>
            ) : null}
            <Row className='box-search'>
              <Col sm='12'>
                <div className='content-title'>
                  <div className='title-content'>Relacionamento</div>
                </div>
              </Col>
              <Col sm='12'>
                <a onClick={this.openNotesModal}>
                  <div className='box-search-add'>
                    <span className='box-search-add-contact text-uppercase text-default font-bold'>Anotações</span>
                    <Icon className='box-search-add-contact icon icon-size-one' image={plus} alt='plus' />
                  </div>
                </a>
              </Col>
              <Col sm='12'>
                <PatientNotesComponent
                  notes={anotacoes}
                  note={note}
                  displayModal={notesModal}
                  closeModal={this.closeModalNotes}
                  showLoader={this.props.showLoader}
                  hideLoader={this.props.hideLoader}
                  patient={this.state.patient}
                  refreshAllNotes={this.refresh}
                  editNote={this.editNote}
                  openNotesModal={this.openNotesModal}
                  showMessage={this.showMessage}
                />
                <PatientDeleteModal
                  showModal={this.state.showDeleteModal}
                  closeModal={this.closeDeleteModal}
                  patient={this.state.patient}
                  showLoader={this.props.showLoader}
                  hideLoader={this.props.hideLoader}
                />
                <PatientEditModal updateComponent={this.refresh} />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    setHeader: (data) => dispatch(setHeader(data)),
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
    showPatientEditModal: (data) => dispatch(showPatientEditModal(data))
  };
};

const enhanced = compose(withLoader, connect(mapStateToProps, mapDispatchToProps), withSecurity);

export default enhanced(PatientRegistrationFormPage);
