import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import $ from 'jquery';
import 'fullcalendar-scheduler';
import DatePicker from 'react-datepicker';
import { t } from 'typy';
import ScheduleAPI from '../service/ScheduleAPI';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { scheduleReducerToProps } from '../pages/schedule/selectors/SchedulePage.selector';
import {
  scheduleAppointmentDetailSagaModal,
  scheduleAppointmentModal,
  scheduleAppointmentPatientSagaModal,
  scheduleAppointmentSelectTreatmentSagaModal,
  scheduleEventModal,
  scheduleNextAppointment,
  schedulePageSaga
} from '../pages/schedule/redux/Schedule.actions';
import calendar from '../assets/img/icons/calendar.svg';
import close from '../assets/img/icons/close.svg';
import confirmada from '../assets/img/confirmada.png';
import aguardando from '../assets/img/aguardando.png';
import andamento from '../assets/img/andamento.png';
import falta from '../assets/img/falta.png';
import finalizada from '../assets/img/finalizada.png';
import PendenciaLab from '../assets/img/icons/lab.svg';
import { closeToast, showToast } from '../pages/schedule/TostPatient.component';
import { toast, ToastContainer } from 'react-toastify';
import arrowleft from '../assets/img/icons/arrowleft.svg';
import Icon from './Icon.component';
import arrowright from '../assets/img/icons/arrowright.svg';
import PacienteAPI from '../service/PacienteAPI';
import ToastUtils from '../utils/ToastUtils';
import { PERMISSOES } from '../config/security/permissoes';
import withSecurity from '../config/security/security';

import danger from '../assets/img/icons/danger1.svg';
import ToastCustomMessageComponent from './ToastCustomMessage.component';
import ProceduresAPI from '../service/ProceduresAPI';
import { call } from 'redux-saga/effects';
import { hideLoader, showLoader } from './actions/Loader.actions';
import ScheduleAppointmentModal from '../pages/schedule/modal/ScheduleAppointment.modal';
import ScheduleAppointmentDetailModal from '../pages/schedule/modal/ScheduleAppointmentDetail.modal';
import ScheduleEventModal from '../pages/schedule/modal/ScheduleEvent.modal';
import UserAPI from '../service/User.service';

const event = (event) => {
  const { data = {}, title } = event;

  return `
    <div class='calendar-item'>
      <div class='calendar-item-header'>Compromisso / Bloqueio</div> 
      <div class='calendar-item-container'>
          <div class='calendar-item-container-box receipt'><div class='text-default text-sm text-secondary'>${
            data.observacao || ''
          }</div></div>
      </div> 
    </div>`;
};

const schedule = (event) => {
  const { data = {}, title } = event;
  const { procedimento = {} } = data;
  // console.log('event', event);

  return `
    <div class='calendar-item'>
      <div class='calendar-item-header'>${event.title}</div> 
      <div class='calendar-item-container'>
          <div class='calendar-item-container-box receipt' id='${'procedure_' + data.id}'>
            <span>
              <div class="tooltip2 tooltip-white">${procedimento.codinome}
                <span class="tooltiptext tooltip-top">${procedimento.nome}</span>
              </div>
            </span>
          </div>

      <div class='calendar-item-footer'>
          ${
            data.pendencia_laboratorio
              ? `<img src='${PendenciaLab}' class="icon icon-size-lab" style='margin-right: 5px'>`
              : ''
          }
          ${
            data.status === 'Aguardando'
              ? `<img src='${aguardando}' class="icon icon-size-small" style='margin-left: 2px;margin-right: 5px'>`
              : ''
          }
          ${
            data.status === 'Confirmada'
              ? `<img src='${confirmada}' class="icon icon-size-small" style='margin-left: 2px;margin-right: 5px'>`
              : ''
          }
          ${
            data.status === 'Em Atendimento'
              ? `<img src='${andamento}' class="icon icon-size-small" style='margin-left: 2px;margin-right: 5px'>`
              : ''
          }
          ${
            data.status === 'Finalizada'
              ? `<img src='${finalizada}' class="icon icon-size-small" style='margin-left: 2px;margin-right: 5px'>`
              : ''
          }
          ${
            data.status === 'Falta'
              ? `<img src='${falta}' class="icon icon-size-small" style='margin-left: 2px;margin-right: 5px'>`
              : ''
          }
      </div>
      </div> 
    </div>`;
};

class Calendar extends Component {
  state = {
    dayload: [],
    chairsLoad: false,
    businessHoursLoad: false,
    mapping: null
  };
  modulePermission = PERMISSOES.modules.agenda;

  componentDidMount() {
    this.createCalendar();
  }

  loadDay = () => {
    if (this.props.dentistSelected === null) {
      setTimeout(() => this.loadDay(), 500);
    } else {
      this.changeDay(moment());
    }
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (t(this.props, 'dentistSelected.id').safeNumber !== t(nextProps, 'dentistSelected.id').safeNumber) {
      if (t(nextProps, 'dentistSelected.id').isDefined) {
        this.setState(
          {
            businessHoursLoad: false,
            chairsLoad: false,
            mapping: null
          },
          () => this.changeDay(this.state.day || moment(), nextProps.dentistSelected)
        );
      }
    }

    return true;
  }

  refreshCalendar = (config) => {
    const { day, officeHours, schedules } = config;

    $('#calendar').fullCalendar('gotoDate', day);
    const dayOfWeek = day.weekday();

    if (!officeHours.find((o) => o.dia_semana === dayOfWeek)) {
      $('#calendar').fullCalendar('option', 'businessHours', [
        {
          // AM
          dow: [dayOfWeek], // Monday, Tuesday, Wednesday
          start: '00:00',
          end: '00:00'
        }
      ]);

      $('#calendar').fullCalendar('option', 'selectConstraint', 'businessHours');
      this.setState({ businessHoursLoad: false });
    } else {
      let ini = performance.now();
      this.loadConfigs(config);
      console.log('tempo config:', performance.now() - ini);
    }

    if (!this.state.chairsLoad) {
      this.loadChairs(config);
    }

    let ini = performance.now();
    this.loadScheduleDay(schedules);
    console.log('tempo schedule:', performance.now() - ini);
  };

  updateSchedule = async () => {
    const schedules = await ScheduleAPI.findScheduleDay({
      dentist: this.props.dentistSelected.id,
      date: this.state.day.format('YYYY-MM-DD')
    });
    this.loadScheduleDay(schedules.data);
  };

  async loadConfigs(nextProps) {
    console.log('--------  loadConfigs  -------- ');
    // debugger
    const { officeHours = {}, config = {}, day = moment() } = nextProps;
    const dayOfWeek = day.weekday();
    const dayConfig = officeHours.find((o) => o.dia_semana === dayOfWeek) || '18:00';

    if (day.isSameOrAfter(moment(), 'day') && t(dayConfig, 'horario_atendimento_fim').isDefined) {
      const end = t(dayConfig, 'horario_atendimento_fim').safeString.split(':');
      const endIntervalconfig = t(config, 'intervalo_atendimento_fim').safeString.split(':');

      //Define periodo do calendario
      const horarioInicio = dayConfig.horario_atendimento_inicio || '08:00';
      if ($('#calendar').fullCalendar('option', 'minTime') !== horarioInicio) {
        $('#calendar').fullCalendar('option', 'minTime', horarioInicio);
      }

      const horarioFim = end[0] + ':' + (parseInt(end[1]) + 5);
      if ($('#calendar').fullCalendar('option', 'minTime') !== horarioFim) {
        $('#calendar').fullCalendar('option', 'maxTime', horarioFim);
      }
      // console.log($('#calendar').fullCalendar('selectConstraint', 'businessHours'));
      // Define intervalo
      if (!this.state.businessHoursLoad) {
        const businessHoursMap = new Map();

        officeHours.forEach((d) => {
          const start = d.horario_atendimento_inicio || '08:00';
          const end = t(d, 'horario_atendimento_fim').safeString.split(':');

          if (businessHoursMap.get(start + end)) {
            businessHoursMap.set(start + end, {
              days: [...businessHoursMap.get(start + end).days, d.dia_semana],
              start: d.horario_atendimento_inicio || '08:00',
              end: end[0] + ':' + (parseInt(end[1]) + 5)
            });
          } else {
            businessHoursMap.set(start + end, {
              days: [d.dia_semana],
              start: d.horario_atendimento_inicio || '08:00',
              end: end[0] + ':' + (parseInt(end[1]) + 5)
            });
          }
        });

        const businessHours = [];

        for (var [key, value] of businessHoursMap) {
          businessHours.push({
            // AM
            dow: value.days, // Monday, Tuesday, Wednesday
            start: value.start || '08:00',
            end: config.intervalo_atendimento_inicio
          });
          businessHours.push({
            // PM
            dow: value.days, // Monday, Tuesday, Wednesday
            start: endIntervalconfig[0] + ':' + (parseInt(endIntervalconfig[1]) + 5),
            end: value.end
          });
        }

        $('#calendar').fullCalendar('option', 'businessHours', businessHours);
        $('#calendar').fullCalendar('option', 'selectConstraint', 'businessHours');
        $('#calendar').fullCalendar('option', 'eventConstraint', 'businessHours');
        $('#calendar').fullCalendar('option', 'resizeConstraint', 'businessHours');
        this.setState({ businessHoursLoad: true });
      }
    } else {
      if ($('#calendar').fullCalendar('option', 'minTime') !== '00:00') {
        $('#calendar').fullCalendar('option', 'minTime', '00:00');
      }

      if ($('#calendar').fullCalendar('option', 'maxTime') !== '23:59') {
        $('#calendar').fullCalendar('option', 'maxTime', '23:59');
      }

      $('#calendar').fullCalendar('option', 'businessHours', [
        {
          // AM
          dow: [dayConfig.dia_semana], // Monday, Tuesday, Wednesday
          start: '00:00',
          end: '00:00'
        }
      ]);
      $('#calendar').fullCalendar('option', 'selectConstraint', 'businessHours');

      this.setState({ businessHoursLoad: false });
    }
  }

  loadChairs(nextProps) {
    const { chairs } = nextProps;
    let resources = $('#calendar').fullCalendar('getResources');
    if (chairs) {
      //Limpando as cadeiras
      resources.forEach((r) => $('#calendar').fullCalendar('removeResource', r.id));
      chairs.forEach((c) => {
        $('#calendar').fullCalendar('addResource', { id: c.id, title: c.nome_cadeira, status: c.status });
      });
    } else {
      resources.forEach((r) => $('#calendar').fullCalendar('removeResource', r.id));
    }
    this.setState({ chairsLoad: true });
  }

  eventRender = (eventObj, $el) => {
    if (eventObj.type === 'E') {
      $el.find('.fc-content').html(event(eventObj));
    } else {
      $el.find('.fc-content').html(schedule(eventObj));
    }
  };

  resourceRender = (resourceObj, labelTds, bodyTds) => {
    bodyTds.html('<div class="calendar-event-button">agendar eventos</div>');
  };

  selectAction = (start, end, jsEvent, view, resource) => {
    const { day } = this.state;
    const {
      scheduleAppointmentModal,
      dentistSelected,
      redial,
      nextAppointment,
      editSchedule,
      scheduleNextAppointment,
      patient,
      scheduleWithPatientSelected
    } = this.props;

    if (this.props.security.hasPermission(this.modulePermission.id, this.modulePermission.permissoes.editar)) {
      if (start.hasTime()) {
        if (moment().isAfter(start.format())) {
          ToastUtils.time(
            <ToastCustomMessageComponent
              message={'Não é possível agendar consulta em horário anterior ao atual'}
              iconImage={danger}
              title={'Consulta em horário passado !'}
            />,
            {
              autoClose: 8000,
              hideProgressBar: true,
              position: 'top-center'
            }
          );
        } else {
          if (resource.status === 'ATIVO') {
            if (t(redial, 'schedule').isDefined) {
              editSchedule({
                date: moment(day),
                modal: true,
                dentist: { id: redial.schedule.dentista_id },
                chair: { id: resource.id },
                start: moment(start, 'HH:mm'),
                end: moment(end, 'HH:mm'),
                patient: redial.schedule.paciente,
                treatment: t(redial.schedule, 'tratamento').safeObject,
                schedule: redial.schedule,
                redialBy: redial.type
              });
            } else if (t(nextAppointment, 'schedule').isDefined) {
              const { schedule } = nextAppointment;

              editSchedule({
                date: moment(day),
                modal: true,
                dentist: { id: schedule.dentista_id },
                chair: { id: resource.id },
                start: moment(start, 'HH:mm'),
                end: moment(end, 'HH:mm'),
                patient: schedule.paciente,
                treatment: t(schedule, 'tratamento').safeObject,
                schedule: {
                  ...schedule,
                  id: null
                },
                onSave: () => {
                  scheduleNextAppointment({});
                  closeToast();
                }
              });
            } else if (t(patient, 'schedule.paciente.id').isDefined) {
              PacienteAPI.findById(patient.schedule.paciente.id)
                .then(async (data) => {
                  const procedureData = await ProceduresAPI.getById(patient.schedule.procedimento.id);
                  scheduleWithPatientSelected({
                    modal: true,
                    dentist: dentistSelected,
                    chair: resource,
                    date: day,
                    start,
                    end,
                    patient: {
                      ...data,
                      id: data.paciente_id
                    },
                    procedure: procedureData.data,
                    controleRetorno: t(patient, 'schedule.controleRetorno').isDefined
                      ? patient.schedule.controleRetorno
                      : null
                  });
                })
                .catch((err) => {
                  console.error(err);
                  ToastUtils.error('Erro ao buscar dados do paciente entre em contato com o suporte');
                });
            } else {
              scheduleAppointmentModal({
                modal: true,
                date: day,
                start,
                end,
                dentist: dentistSelected,
                chair: resource
              });
            }
          } else {
            ToastUtils.error('Cadeira inativa agendamento não permitido');
          }
        }
      }
    } else {
      ToastUtils.error('Acesso negado operação não permitida');
    }
  };

  dayAction = (date, jsEvent, view, resource) => {
    const { dentist, dentistSelected, officeHours = {} } = this.state.mapping;

    const dayOfWeek = date.weekday();
    const dayConfig = officeHours.find((o) => o.dia_semana === dayOfWeek);
    if (!date.hasTime() && date.isSameOrAfter(moment(), 'day') && t(dayConfig, 'horario_atendimento_fim').isDefined) {
      if (this.props.security.hasPermission(this.modulePermission.id, this.modulePermission.permissoes.editar)) {
        if (resource.status === 'ATIVO') {
          this.props.eventModal({
            modal: true,
            date: this.state.day,
            dentist: dentistSelected,
            chair: resource
          });
        } else {
          ToastUtils.error('Cadeira inativa agendamento não permitido');
        }
      }
    }
  };

  resizeAction = async (event, delta, revertFunc) => {
    if (this.props.security.hasPermission(this.modulePermission.id, this.modulePermission.permissoes.editar)) {
      const { data } = event;
      const { dentistSelected, updateParent } = this.props;
      const {
        mapping: { chairs }
      } = this.state;

      const chair = chairs.find((c) => c.id === parseInt(event.resourceId));

      if (chair.status === 'ATIVO') {
        if (data.status === 'Agendada' || data.status === 'Confirmada' || data.tipo_agendamento === 'E') {
          try {
            await ScheduleAPI.updateScheduleTime({
              chair: event.resourceId,
              dentist: dentistSelected.id,
              schedule: data.id_agendamento,
              time: {
                start: event.start.format('HH:mm'),
                end: event.end.format('HH:mm')
              }
            });
            // updateParent(day);
            this.updateSchedule();
          } catch (e) {
            toast.error('Erro ao salvar agendamento entre em contato com o suporte', {
              autoClose: false,
              position: 'top-center'
            });
            revertFunc();
          }
        } else {
          revertFunc();
        }
      } else {
        ToastUtils.error('Cadeira inativa alteração não permitido');
        revertFunc();
      }
    } else {
      revertFunc();
      ToastUtils.error('Acesso negado operação não permitida');
    }
  };

  eventDropAction = async (event, delta, revertFunc) => {
    if (this.props.security.hasPermission(this.modulePermission.id, this.modulePermission.permissoes.editar)) {
      const { data } = event;
      const { dentistSelected, updateParent } = this.props;
      const {
        mapping: { chairs }
      } = this.state;
      const chair = chairs.find((c) => c.id === parseInt(event.resourceId));

      if (chair.status === 'ATIVO') {
        if (data.status === 'Agendada' || data.status === 'Confirmada' || data.tipo_agendamento === 'E') {
          try {
            await ScheduleAPI.updateScheduleTime({
              chair: event.resourceId,
              dentist: dentistSelected.id,
              schedule: data.id_agendamento,
              time: {
                start: event.start.format('HH:mm'),
                end: event.end.format('HH:mm')
              }
            });
            // updateParent(day);
            this.updateSchedule();
          } catch (e) {
            console.log(e);
            toast.error('Erro ao salvar agendamento entre em contato com o suporte', {
              autoClose: false,
              position: 'top-center'
            });
            revertFunc();
          }
        } else {
          revertFunc();
        }
      } else {
        ToastUtils.error('Cadeira inativa alteração não permitido');
        revertFunc();
      }
    } else {
      revertFunc();
      ToastUtils.error('Acesso negado operação não permitida');
    }
  };

  eventClickAction = (event, element) => {
    const { dentistSelected, config = {} } = this.props;
    const {
      mapping: { chairs, officeHours = {} }
    } = this.state;

    if (event.type === 'E') {
      this.props.eventModal({
        modal: true,
        date: this.state.day,
        dentist: dentistSelected,
        chair: chairs.find((c) => c.id === parseInt(event.resourceId)),
        event: event.data
      });
    } else {
      this.props.scheduleAppointmentDetailModal({
        modal: true,
        schedule: event,
        officeHours,
        config
      });
    }
  };

  createCalendar() {
    console.log('--------  createCalendar  -------- ');
    $('#calendar').fullCalendar({
      locale: 'pt-BR',
      defaultView: 'agendaDay',
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sabado'],
      monthNames: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ],
      defaultDate: moment(),
      now: moment(),
      buttonText: {
        today: 'Hoje',
        month: 'Mês',
        week: 'Semana',
        day: 'Dia',
        list: 'lista'
      },
      nowIndicator: true,
      editable: true,
      selectable: true,
      eventLimit: true, // allow "more" link when too many events
      slotDuration: '00:05:00',
      slotLabelInterval: '00:05:00',
      slotLabelFormat: 'H:mm',
      header: {
        left: '', //'calendarButton title prev,next today Teste',
        center: '',
        right: '' //'agendaDay,agendaWeek'
      },
      eventOverlap: false,
      selectOverlap: false,
      overlap: false,
      eventRender: this.eventRender,
      resourceRender: this.resourceRender,
      allDaySlot: true,
      allDayText: '',
      select: this.selectAction,
      dayClick: this.dayAction,
      dayRender: this.dayRender,
      eventResize: this.resizeAction,
      eventClick: this.eventClickAction,
      resources: [],
      events: [],
      eventDrop: this.eventDropAction,
      resizeConstraint: 'businessHours'
    });

    $('.fc-license-message').html('');
  }

  dayRender = (dayRenderInfo, cell) => {
    cell.html('<div class="calendar-event-button">agendar evento</div>');
  };

  loadScheduleDay(schedules) {
    $('#calendar').fullCalendar('removeEvents');

    if (t(schedules).isArray) {
      schedules.forEach((s) => {
        const { cadeira, agenda } = s;

        const events = agenda.map((a) => {
          let color = '#eeeeee';

          if (a.tipo_agendamento === 'C') {
            color = t(a, 'procedimento.categoria.rgb').safeString;
          }
          const event = {
            id: a.id_agendamento,
            resourceId: cadeira.id,
            start: moment(a.data_agendamento + ' ' + a.horario_inicio, 'YYYY-MM-DD HH:mm'),
            end: moment(a.data_agendamento + ' ' + a.horario_fim, 'YYYY-MM-DD HH:mm'),
            title: a.paciente,
            // constraint: {
            //   resourceIds: [cadeira.id + '']
            // },
            color: color,
            type: a.tipo_agendamento,
            data: a
          };
          // $('#calendar').fullCalendar('renderEvent', event);
          return event;
        });
        $('#calendar').fullCalendar('renderEvents', events);
      });
    }
  }

  changeDay = async (day, dentist) => {
    let dentistSelected = this.props.dentistSelected;
    if (dentist) {
      dentistSelected = dentist;
    }

    day.locale('p-br');
    // $('#calendar').fullCalendar('gotoDate', day);

    this.props.showLoader();
    if (!this.state.mapping) {
      Promise.all([
        ScheduleAPI.config(dentistSelected.id),
        ScheduleAPI.officeHours(dentistSelected.id),
        UserAPI.getChairs(dentistSelected.id),
        ScheduleAPI.findScheduleDay({ dentist: dentistSelected.id, date: day.format('YYYY-MM-DD') })
      ]).then((data) => {
        const mapping = {
          config: data[0].data,
          officeHours: data[1].data,
          chairs: data[2].data,
          schedules: data[3].data,
          day
        };

        this.setState({
          mapping
        });
        this.refreshCalendar(mapping);
        // this.loadScheduleDay(data[3].data);
        this.props.hideLoader();
        this.setState({
          day,
          dayload: [...this.state.dayload, day]
        });
      });
    } else {
      const schedules = await ScheduleAPI.findScheduleDay({
        dentist: dentistSelected.id,
        date: day.format('YYYY-MM-DD')
      });
      const mapping = {
        ...this.state.mapping,
        schedules: schedules.data,
        day
      };

      this.refreshCalendar(mapping);
      // this.loadScheduleDay(schedules.data);
      this.props.hideLoader();
      this.setState({
        day,
        dayload: [...this.state.dayload, day]
      });
    }
    // this.props.changeDay({ dentist, day });
  };

  render() {
    const { day } = this.state;
    $('.fc-license-message').html('');
    return (
      <div className={'calendar'}>
        <Row>
          <Col sm={12}>
            <span className={'title-large'}> {day instanceof moment && day.format('dddd - DD MMM YYYY')}</span>
            <a
              href={'#'}
              onClick={() => {
                this.changeDay(day.add(-1, 'd'));
              }}
            >
              <Icon className='icon icon-size-small margin-left-10' image={arrowleft} alt='arrowleft' />
            </a>
            <a
              onClick={() => {
                this.changeDay(day.add(1, 'd'));
              }}
            >
              <Icon className='icon icon-size-small margin-left-10' image={arrowright} alt='arrowright' />
            </a>
            <a
              href={'#'}
              onClick={() => {
                this.changeDay(moment());
              }}
            >
              <span className='button-grey'>Hoje</span>
            </a>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col sm={12} className='px-0'>
            <div style={{ marginTop: 39, marginRight: 35, marginLeft: 15, width: 238, float: 'left' }}>
              <DatePicker
                inline
                selected={day}
                locale={'pt-br-griks'}
                useWeekdaysShort={true}
                onChange={(value) => {
                  this.changeDay(value);
                }}
              />
            </div>
            <div id='calendar' style={{ marginTop: -15, width: 'calc(100% - 288px)', float: 'left' }}></div>
          </Col>
        </Row>
        <ScheduleEventModal key={'ScheduleEventModal'} updateParent={this.updateSchedule} />
        <ScheduleAppointmentModal key={'ScheduleAppointmentModal'} updateParent={this.updateSchedule} />
        <ScheduleAppointmentDetailModal
          key={'ScheduleAppointmentDetailModal'}
          updateParent={this.updateSchedule}
          changeDay={this.changeDay}
          configurations={this.state.mapping}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...scheduleReducerToProps(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  eventModal: (data) => dispatch(scheduleEventModal(data)),
  scheduleAppointmentModal: (data) => dispatch(scheduleAppointmentModal(data)),
  scheduleAppointmentDetailModal: (data) => dispatch(scheduleAppointmentDetailSagaModal(data)),
  editSchedule: (data) => dispatch(scheduleAppointmentPatientSagaModal(data)),
  scheduleNextAppointment: (data) => dispatch(scheduleNextAppointment(data)),
  scheduleWithPatientSelected: (data) => dispatch(scheduleAppointmentSelectTreatmentSagaModal(data)),
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader())
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withSecurity);

export default enhanced(Calendar);
