import React, { Component } from 'react';

import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import warning from '../../../../assets/img/icons/warning.svg';
import Icon from '../../../../components/Icon.component';
import UserAPI from '../../../../service/User.service';
import TreatmentBudgetApi from '../../../../service/TreatmentBudgetApi';
import MessagesComponent from '../../../../components/Messages.component';

class TreatmentBudgetDeleteModal extends Component {
  state = {
    password: '',
    error: {
      display: false,
      type: 'danger',
      message: ''
    }
  };

  setPassword = (e) => {
    const {
      target: { value }
    } = e;
    this.setState({ password: value });
  };

  confirmDelete = async () => {
    this.props.showLoader();
    if (!this.state.password) {
      this.setState({
        error: {
          display: true,
          type: 'danger',
          message: 'Senha obrigatória'
        }
      });
    } else {
      const checkPassword = await UserAPI.checkPassword(this.state.password);
      try {
        if (checkPassword) {
          await TreatmentBudgetApi.budgetDelete(this.props.budget.id);
          this.props.closeModal();
          this.props.updateParent();
        } else {
          this.setState({
            error: {
              display: true,
              type: 'danger',
              message: 'Senha Inválida'
            }
          });
        }
      } catch (e) {
        console.log(e);
        this.setState({
          error: {
            display: true,
            type: 'danger',
            message: 'Senha Inválida'
          }
        });
      }
    }
    this.props.hideLoader();
  };

  render() {
    const { modal, closeModal } = this.props;
    const { error } = this.state;

    return (
      <Modal isOpen={modal} toggle={closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={closeModal}
          close={
            <button className='close' onClick={closeModal}>
              &times;
            </button>
          }
        >
          <strong>Excluir</strong> Orçamento
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={3}>
              <div className='modal-icon'>
                <Icon className='icon-alert icon-size-big' image={warning} alt='warning' />
              </div>
            </Col>
            <Col sm={9}>
              <div className='margin-top-10'>
                <Row>
                  <Col sm='12'>
                    Tem certeza que deseja <strong>excluir</strong> esse Orçamento?
                  </Col>
                </Row>
              </div>
              <div className='margin-top-10'>
                <Row>
                  <Col sm='12'>Todas as informações serão definitivamente perdidas.</Col>
                </Row>
              </div>
              <div className='margin-top-10'>
                <Row>
                  <Col sm={12}>
                    <MessagesComponent display={error.display} type={error.type} message={error.message} />
                    <input
                      className='input-secondary'
                      type='password'
                      name='senha'
                      placeholder='Senha'
                      onChange={this.setPassword}
                    ></input>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col sm={{ size: 3, offset: 6 }}>
              <button className='button-cancel' onClick={this.confirmDelete}>
                Excluir
              </button>
            </Col>
            <Col sm='3'>
              <button className='button-tertiary' onClick={closeModal}>
                Cancelar
              </button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}

export default TreatmentBudgetDeleteModal;
