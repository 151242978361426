import React, { Component } from 'react';
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip
} from 'reactstrap';
import notes from '../../../assets/img/icons/notes.svg';
import recibo_white from '../../../assets/img/icons/recibo-white.svg';
import recibo_yellow from '../../../assets/img/icons/recibo-yellow.svg';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { financialFileEntriesToProps } from './selectors/FinancialFileEntriesPage.selector';
import menuDots from '../../../assets/img/menuDots.png';
import { IfComponent } from '../../../components/if.component';
import { formatDate } from '../../../utils/DateUtils';
import { t } from 'typy';
import { formatReal, formatRealNoShowDolarSign } from '../../../utils/Formatter';
import ModalMessageConfirm from '../../../components/ModalMessageConfirm.component';
import warning from '../../../assets/img/icons/warning.svg';
import { hideLoader, showLoader } from '../../../components/actions/Loader.actions';
import CashAPI from '../../../service/CashAPI';
import { toast } from 'react-toastify';
import TreatmentFinancialLaunchPaymentModal from './modals/TreatmentFinancialLaunchPayment.modal';
import { financialLaunchOtherPaymentSagaModal, financialLaunchPaymentSagaModal } from './redux/FinancialFile.action';
import TreatmentFinancialOtherLaunchModal from './modals/TreatmentFinancialOtherLaunch.modal';
import withSecurity from '../../../config/security/security';
import { PERMISSOES } from '../../../config/security/permissoes';
import danger from '../../../assets/img/icons/danger1.svg';
import ToastCustomMessageComponent from '../../../components/ToastCustomMessage.component';
import ToastUtils from '../../../utils/ToastUtils';
import Icon from '../../../components/Icon.component';
import { AiOutlineClose } from 'react-icons/ai';

class TreatmentFinancialFileEntriesPage extends Component {
  state = {
    displayConfirmModal: false,
    entryDelete: null,
    isClicked: false,
    isChecked: false,
    entriesSelectedByPaid: []
  };

  toggleButton = () => {
    this.setState({
      isClicked: !this.state.isClicked,
      text: 'Pago'
    });
  };

  selectEntryForPaid = (entry) => {
    let entries = this.state.entriesSelectedByPaid;

    if (entries.includes(entry)) {
      entries = entries.filter((e) => e !== entry);
    } else {
      entries.push(entry);
    }
    this.setState({
      entriesSelectedByPaid: entries
    });
  };

  isCheckedPayment = (entry) => {
    let entries = this.state.entriesSelectedByPaid;

    if (entries.includes(entry)) {
      return true;
    } else {
      return false;
    }
  };

  updateParent = () => {
    this.setState({
      entriesSelectedByPaid: []
    });
    this.props.updateParent();
  };

  cancelPaymentDebits = () => {
    this.setState({
      entriesSelectedByPaid: []
    });
    this.props.updateParent();
  };

  openConfirmDeleteEntry = (entry) => {
    this.setState({
      displayConfirmModal: true,
      entryDelete: entry
    });
  };

  cancelConfirmModal = () =>
    this.setState({
      displayConfirmModal: false,
      entryDelete: null
    });

  deleteEntry = () => {
    const { entryDelete } = this.state;
    const { showLoader, hideLoader, updateParent } = this.props;

    showLoader();
    CashAPI.deleteEntry({ entryId: entryDelete.id })
      .then((d) => {
        hideLoader();
        updateParent();
        this.cancelConfirmModal();
        toast.info('Lançamento deletado com sucesso', {
          autoClose: 2000,
          position: 'top-center'
        });
      })
      .catch((e) => {
        console.error(e);
        hideLoader();
        toast.error('Erro ao excluir Lançamento entre em contato com o suporte', {
          autoClose: false,
          position: 'top-center'
        });
        this.cancelConfirmModal();
      });
  };

  editLaunchPayment = (e) => {
    const { financialFile, treatment, launchPayment, launchOtherPayment } = this.props;

    if (e.tipo_lancamento.id === 1) {
      launchPayment({ financialFile, treatment, entry: e });
    } else {
      launchOtherPayment({ financialFile, treatment, entry: e });
    }
  };

  async _issueVoucher(issue) {
    const { showLoader, hideLoader, updateParent } = this.props;
    try {
      showLoader();
      await CashAPI.issueVoucher(issue.id);
      window.open(`/reports/voucher/${issue.id}`);
      updateParent();
      hideLoader();
    } catch (e) {
      ToastUtils.error('Erro ao emitir recibo, entre em contato com o suporte');
      hideLoader();
    }
  }

  render() {
    const { displayConfirmModal, isClicked, entriesSelectedByPaid } = this.state;
    const { enabledEntries, entries = {}, updateParent, financialFile, treatment, security } = this.props;
    const { lancamentos = [] } = entries;
    const financialFilePerm = PERMISSOES.modules.tratamento_ficha_financeira;

    const hasPermission =
      security.hasPermission(financialFilePerm.id, financialFilePerm.permissoes.editar) ||
      security.hasPermission(financialFilePerm.id, financialFilePerm.permissoes.editar_restricao.id);

    return [
      <Row key={'page'} className={'w-100'}>
        <Col sm='12'>
          <div className='box-default mt-3'>
            <IfComponent conditional={!enabledEntries}>
              <Row>
                <Col sm={12}>
                  <div
                    style={{
                      width: 403,
                      position: 'absolute',
                      zIndex: 1000,
                      left: 0,
                      right: 0,
                      marginLeft: 'auto',
                      marginRight: 'auto'
                    }}
                  >
                    <div
                      className='Toastify__toast Toastify__toast--default toast-time'
                      style={{ boxShadow: '0px 0px 7px 6px #cfa4de' }}
                    >
                      <div role='alert' className='Toastify__toast-body toast-time-body'>
                        <ToastCustomMessageComponent
                          message={
                            'Depois disso, o sistema vai Lançar automaticamente os valores negociados e ativar os pagamentos.'
                          }
                          iconImage={danger}
                          title={'Clique em "Fechar Negociação", para continuar!'}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </IfComponent>
            <Row className={!enabledEntries ? 'disabled' : ''}>
              <Col sm='12'>
                <div>
                  <Row>
                    <Col sm='12'>
                      <div className='row'>
                        <div className='col-sm-12'>
                          <h2 className='title-medium-plus text-bold text-secondary text-uppercase'>
                            Ficha de Pagamentos
                          </h2>
                        </div>
                      </div>
                      <div className='d-flex flex-row'>
                        <div className='p-2'>
                          <button
                            className='btn btn-default'
                            style={{
                              background: '#f7f9fc',
                              border: '1px solid #cfd5e3',
                              cursor: 'default',
                              width: '200px',
                              height: '60px'
                            }}
                          >
                            <p className='font-light text-secondary-d4 text-uppercase' style={{ fontSize: '11px' }}>
                              Débito Corrente
                            </p>
                            <p className='text-md font-bolder text-secondary-d4'>
                              {formatRealNoShowDolarSign(
                                entries.debito_corrente < 0 ? entries.debito_corrente * -1 : entries.debito_corrente
                              )}
                            </p>
                          </button>
                        </div>
                        <div className='p-2 bd-highlight ml-2'>
                          <button
                            className='btn btn-default'
                            style={{
                              background: '#f7f9fc',
                              border: '1px solid #cfd5e3',
                              cursor: 'default',
                              width: '200px',
                              height: '60px'
                            }}
                          >
                            <p className='font-light text-secondary-d4 text-uppercase' style={{ fontSize: '11px' }}>
                              Débito Vencido
                            </p>
                            <p className='text-md font-bolder text-alert'>
                              {formatRealNoShowDolarSign(
                                entries.total_vencidos < 0 ? entries.total_vencidos * -1 : entries.total_vencidos
                              )}
                            </p>
                          </button>
                        </div>
                        <div className='p-2 ml-2'>
                          <button
                            className='btn btn-default'
                            style={{
                              background: '#f7f9fc',
                              border: '1px solid #cfd5e3',
                              cursor: 'default',
                              width: '200px',
                              height: '60px'
                            }}
                          >
                            <div>
                              <p className='font-light text-secondary-d4 text-uppercase' style={{ fontSize: '11px' }}>
                                Total a Pagar
                              </p>
                              <p className='text-md font-bolder text-alert'>
                                {formatRealNoShowDolarSign(
                                  entries.total_apagar < 0 ? entries.total_apagar * -1 : entries.total_apagar
                                )}
                              </p>
                            </div>
                          </button>
                        </div>
                        <div className='p-2 ml-auto'>
                          <IfComponent conditional={hasPermission}>
                            {/* TODO: novoModuloPagamento (Remover esse botÃ£o) */}
                            {/*<button className="btn btn-default text-sm float-right ml-2" style={ { borderRadius: '5px', width: '135px', height: '60px' } }*/}
                            {/*        disabled={ (!enabledEntries ? true : false) }*/}
                            {/*        onClick={ () => this.props.launchPayment({ financialFile, treatment }) }>*/}
                            {/*  <IconsKit icon={u1F4B5} size={32} style={ { color: '#616e88' } }/>*/}
                            {/*  <p style={ { marginTop: '-6px' } } className="text-sm font-light text-secondary">Pagamentos</p>*/}
                            {/*</button>*/}

                            <button
                              role='button'
                              className='btn btn-default'
                              style={{
                                width: '200px',
                                height: '60px'
                              }}
                              disabled={!enabledEntries ? true : false}
                              onClick={() =>
                                this.props.launchOtherPayment({
                                  financialFile,
                                  treatment
                                })
                              }
                            >
                              <p className='text-sm font-bold text-secondary-d4 text-uppercase'>Lançar</p>
                              <p className='text-sm font-bold text-secondary-d4'>Débitos e Ajustes</p>
                            </button>
                          </IfComponent>
                        </div>
                      </div>
                      <IfComponent conditional={entriesSelectedByPaid.length > 0}>
                        <div
                          className='d-flex bd-highlight align-items-center py-2 mt-3'
                          style={{
                            borderTop: '2px solid #E6E9F2',
                            borderBottom: '2px solid #E6E9F2'
                          }}
                        >
                          <div className='d-flex flex-grow-1 justify-content-center align-items-center'>
                            <div className='p-2 mr-3'>
                              <p>
                                <strong className='mr-1'>({t(entriesSelectedByPaid).safeArray.length})</strong>Débitos
                                Selecionados
                              </p>
                            </div>
                            <div className='p-2 mr-3 ml-3'>
                              <p>
                                Valor Total:
                                <strong className='ml-1'>
                                  {formatReal(
                                    entriesSelectedByPaid.reduce(
                                      (total, element) => (total += element.valor_lancamento),
                                      0
                                    )
                                  )}
                                </strong>
                              </p>
                            </div>
                            <div className='p-2 ml-3'>
                              <button
                                onClick={() =>
                                  this.props.launchPayment({
                                    financialFile,
                                    treatment,
                                    paymentEntries: entriesSelectedByPaid
                                  })
                                }
                                className={`px-2 ${isClicked ? 'btn-receive active' : 'btn-receive'}`}
                                style={{ minWidth: '68px' }}
                              >
                                Receber
                              </button>
                            </div>
                          </div>
                          <div className='d-flex align-items-center'>
                            <div className='p-2 mr-3'>
                              <AiOutlineClose
                                onClick={() => this.cancelPaymentDebits()}
                                size='20'
                                color='#434c5e'
                                style={{ cursor: 'pointer' }}
                              ></AiOutlineClose>
                            </div>
                          </div>
                        </div>
                      </IfComponent>
                      <div className='content-overflow-default mt-3'>
                        <Table className='table-default mb-0' responsive>
                          <thead className='bg-secondary-l5'>
                            <tr>
                              <th width='2%'></th>
                              <th>Nr</th>
                              <th>Data</th>
                              <th>Descrição</th>
                              <th>Anot</th>
                              <th>Pgto</th>
                              <th>Referência</th>
                              <th>Recibo</th>
                              <th>Operador</th>
                              <th>Receber</th>
                              <th>Débito</th>
                              <th>Crédito</th>
                              <th>Saldo</th>
                              <th width='2%'></th>
                            </tr>
                          </thead>
                          <tbody>
                            {lancamentos.map((e) => {
                              return (
                                <tr key={e.id} className='pb-0'>
                                  <td className='py-2'>
                                    <Col sm='12'>
                                      <IfComponent
                                        conditional={
                                          (t(e, 'tipo_lancamento.id').safeNumber == 2 ||
                                            t(e, 'tipo_lancamento.id').safeNumber == 4) &&
                                          !t(e, 'pago').safeBoolean
                                        }
                                      >
                                        <input
                                          type='checkbox'
                                          className='form-checkbox'
                                          checked={this.isCheckedPayment(e)}
                                          onClick={() => this.selectEntryForPaid(e)}
                                        />
                                      </IfComponent>
                                    </Col>
                                  </td>
                                  <td>{e.numero_lancamento}</td>
                                  <td>{formatDate(e.data_lancamento, 'DD/MM/YYYY')}</td>
                                  <td>{e.descricao}</td>
                                  <td>
                                    <IfComponent conditional={t(e.anotacao).isDefined}>
                                      <Icon
                                        className='icon icon-size-one'
                                        image={notes}
                                        alt='notes'
                                        id={`note_${e.id}`}
                                      />
                                      <UncontrolledTooltip
                                        className='tooltip-white text-xsm font-weight-light'
                                        placement='right'
                                        target={`note_${e.id}`}
                                      >
                                        {e.anotacao}
                                      </UncontrolledTooltip>
                                    </IfComponent>
                                  </td>
                                  <td>{t(e, 'forma_pagamento.nome').safeString}</td>
                                  <td>{e.referencia}</td>
                                  <td>
                                    <IfComponent conditional={t(e, 'tipo_comprovante.id').safeString === 'NF'}>
                                      NF
                                    </IfComponent>
                                    <IfComponent conditional={t(e, 'tipo_comprovante.id').safeString === 'R'}>
                                      <UncontrolledDropdown direction='left'>
                                        <DropdownToggle>
                                          <Icon
                                            className='icon icon-size-small'
                                            image={e.comprovante_emitido == 'N' ? recibo_white : recibo_yellow}
                                            alt='receipt'
                                          />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <IfComponent conditional={hasPermission}>
                                            <IfComponent conditional={e.comprovante_emitido == 'N'}>
                                              <DropdownItem onClick={() => this._issueVoucher(e)}>
                                                Emitir Recibo
                                              </DropdownItem>
                                            </IfComponent>
                                            <IfComponent conditional={e.comprovante_emitido == 'S'}>
                                              <DropdownItem onClick={() => window.open(`/reports/voucher/${e.id}`)}>
                                                Imprimir 2&ordf; via
                                              </DropdownItem>
                                            </IfComponent>
                                          </IfComponent>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </IfComponent>
                                  </td>
                                  <td>{t(e, 'operador.usuario_nome').safeString}</td>
                                  <td>
                                    <IfComponent
                                      conditional={
                                        (t(e, 'tipo_lancamento.id').safeNumber == 2 ||
                                          t(e, 'tipo_lancamento.id').safeNumber == 4) &&
                                        !t(e, 'pago').safeBoolean
                                      }
                                    >
                                      <button
                                        onClick={() =>
                                          this.props.launchPayment({ financialFile, treatment, paymentEntries: [e] })
                                        }
                                        className='col-sm-12 px-2 btn-receive'
                                        style={{ maxWidth: '68px' }}
                                      >
                                        Receber
                                      </button>
                                    </IfComponent>
                                  </td>
                                  <td className='text-alert'>{formatRealNoShowDolarSign(e.valor_debito)}</td>
                                  <td>{formatRealNoShowDolarSign(e.valor_credito)}</td>
                                  <td width='8%'>
                                    <strong className={e.saldo < 0 ? 'text-alert' : ''}>
                                      {formatRealNoShowDolarSign(e.saldo)}
                                    </strong>
                                  </td>
                                  <td>
                                    <IfComponent
                                      conditional={
                                        e.has_maintenance && (!e.comprovante_emitido || e.comprovante_emitido == 'N')
                                      }
                                    >
                                      <UncontrolledDropdown direction='left'>
                                        <DropdownToggle disabled={!enabledEntries}>
                                          <img src={menuDots} style={{ width: 20 }} />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <IfComponent conditional={hasPermission}>
                                            {/*<DropdownItem*/}
                                            {/*  onClick={() => {*/}
                                            {/*    this.editLaunchPayment(e);*/}
                                            {/*  }}*/}
                                            {/*>*/}
                                            {/*  Editar*/}
                                            {/*</DropdownItem>*/}
                                            <IfComponent
                                              conditional={
                                                security.hasPermission(
                                                  financialFilePerm.id,
                                                  financialFilePerm.permissoes.editar
                                                ) ||
                                                (security.hasPermission(
                                                  financialFilePerm.id,
                                                  financialFilePerm.permissoes.editar_restricao.id
                                                ) &&
                                                  !security.hasRestriction(
                                                    financialFilePerm.id,
                                                    financialFilePerm.permissoes.editar_restricao.id,
                                                    financialFilePerm.permissoes.editar_restricao.restricoes
                                                      .nao_excluir_lancamento
                                                  ))
                                              }
                                            >
                                              <DropdownItem onClick={() => this.openConfirmDeleteEntry(e)}>
                                                Excluir
                                              </DropdownItem>
                                            </IfComponent>
                                          </IfComponent>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </IfComponent>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>,
      <ModalMessageConfirm
        key={'modalConfirm'}
        title={<strong>Confirma Exclusão do Lançamento?</strong>}
        message={
          <span>
            <p>Confirma a Exclusão do Lançamento?</p>
          </span>
        }
        openModal={displayConfirmModal}
        icon={warning}
        close={this.cancelConfirmModal}
        footer={
          <Row>
            <Col sm='3'>
              <button className='button-tertiary' onClick={this.cancelConfirmModal}>
                Não
              </button>
            </Col>
            <Col sm={{ size: 3, offset: 6 }}>
              <button className='button-primary' onClick={this.deleteEntry}>
                Sim
              </button>
            </Col>
          </Row>
        }
      />,
      <TreatmentFinancialLaunchPaymentModal
        key={'TreatmentFinancialLaunchPaymentModal'}
        updateParent={this.updateParent}
      />,
      <TreatmentFinancialOtherLaunchModal
        key={'TreatmentFinancialOtherLaunchModal'}
        updateParent={updateParent}
        lancamentos={lancamentos}
      />
    ];
  }
}

const mapStateToProps = (state) => {
  return {
    ...financialFileEntriesToProps(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  launchPayment: (data) => dispatch(financialLaunchPaymentSagaModal(data)),
  launchOtherPayment: (data) => dispatch(financialLaunchOtherPaymentSagaModal(data))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withSecurity);

export default enhanced(TreatmentFinancialFileEntriesPage);
