import { takeLatest } from 'redux-saga/effects';
import {
  TREATMENT_CLINICAL_ATTENDENCIE_PRE_MODAL,
  TREATMENT_CLINICAL_PRE_LOAD_ATTENDENCIES,
  TREATMENT_CLINICAL_RECORD_DIAGNOSTIC_ANGLE_PRE_LOAD_MODAL,
  TREATMENT_CLINICAL_RECORD_PLANS_GADGETS_PRE_MODAL,
  TREATMENT_CLINICAL_RECORD_PRE_LOAD_DIAGNOSTIC,
  TREATMENT_CLINICAL_RECORD_PRELOAD_PLANS,
  TREATMENT_CLINICAL_RECORD_SUMMARY_PRE_MODAL
} from '../redux/TreatmentClinicalRecord.action';
import { clinicalRecordSummarySaga } from './ClinicalRecordSummary.saga';
import { clinicalRecordAttendenceModalSaga, clinicalRecordAttendenciesSaga } from './ClinicalRecordAttendencies.saga';
import { clinicalRecordDiagnisticModalSaga, clinicalRecordDiagnosticSaga } from './ClinicalRecordDiagnostics.saga';
import { clinicalRecordTreatmentPlanSaga } from './ClinicalRecordTreatmentPlan.saga';
import { clinicalRecordGadgetsPreLoadModal } from './ClinicalRecordGadgets.saga';

export function* watchTreatmentClinicalRecordSagas() {
  yield takeLatest(TREATMENT_CLINICAL_RECORD_SUMMARY_PRE_MODAL, clinicalRecordSummarySaga);
  yield takeLatest(TREATMENT_CLINICAL_PRE_LOAD_ATTENDENCIES, clinicalRecordAttendenciesSaga);
  yield takeLatest(TREATMENT_CLINICAL_ATTENDENCIE_PRE_MODAL, clinicalRecordAttendenceModalSaga);
  yield takeLatest(TREATMENT_CLINICAL_RECORD_PRE_LOAD_DIAGNOSTIC, clinicalRecordDiagnosticSaga);
  yield takeLatest(TREATMENT_CLINICAL_RECORD_DIAGNOSTIC_ANGLE_PRE_LOAD_MODAL, clinicalRecordDiagnisticModalSaga);
  yield takeLatest(TREATMENT_CLINICAL_RECORD_PRELOAD_PLANS, clinicalRecordTreatmentPlanSaga);
  yield takeLatest(TREATMENT_CLINICAL_RECORD_PLANS_GADGETS_PRE_MODAL, clinicalRecordGadgetsPreLoadModal);
}
