import { put, call } from 'redux-saga/effects';
import { appointmentHistoryModal } from '../redux/Schedule.actions';
import ScheduleAPI from '../../../service/ScheduleAPI';
import { toast } from 'react-toastify';

export function* appointmentHistoryModalSaga({ payload: { modal, patient } }) {
  try {
    const history = yield call(ScheduleAPI.findAppointmentHistory, { patient: patient.id });

    yield put(
      appointmentHistoryModal({
        modal,
        patient,
        history: history.data
      })
    );
  } catch (e) {
    console.log(e);
    toast.error('Erro ao buscar histórico de consultas', { autoClose: false, position: 'top-center' });
  }
}
