import React, { Component } from 'react';

import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import DatePicker from 'react-datepicker';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { hideLoader, showLoader } from '../../../../components/actions/Loader.actions';
import { Formik } from 'formik';

import * as Yup from 'yup';
import { dateFormat } from '../../../../utils/Formatter';
import moment from 'moment';
import MessagesComponent from '../../../../components/Messages.component';
import ErrorFieldWithoutBootstrap from '../../../../components/ErrorFieldWithoutBootstrap.component';
import { budgetCreateModal } from '../redux/TreatmentBudget.actions';
import TreatmentBudgetApi from '../../../../service/TreatmentBudgetApi';

class TreatmentBudgetCreateModal extends Component {
  state = {
    error: {
      display: false,
      type: 'danger',
      message: ''
    }
  };

  validationSchema = Yup.object().shape({
    dataOrcamento: Yup.date().required('Data da Orçamento Obrigatório')
  });

  budgetSave = async (values) => {
    const {
      formLoad: { treatment },
      showLoader,
      hideLoader,
      closeModal,
      updateParent
    } = this.props;
    try {
      showLoader();
      const dateBudget = dateFormat(values.dataOrcamento, 'YYYY-MM-DD');
      await TreatmentBudgetApi.createBudget({ tratamento: treatment.id, budgetDate: dateBudget });
      updateParent();
      closeModal();
    } catch (e) {
      console.log(e);
      this.setState({
        error: {
          display: true,
          type: 'danger',
          message: 'Erro ao salvar orçamento entre em contato com o suporte'
        }
      });
    }
    hideLoader();
  };

  render() {
    const { error } = this.state;
    const { modal, closeModal } = this.props;

    return (
      <Modal isOpen={modal} toggle={closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={closeModal}
          close={
            <button className='close' onClick={closeModal}>
              &times;
            </button>
          }
        >
          <strong>Criar</strong>Orçamento
        </ModalHeader>
        <Formik
          onSubmit={this.budgetSave}
          initialValues={{ dataOrcamento: moment() }}
          validationSchema={this.validationSchema}
          validateOnChange={false}
        >
          {({ errors, handleSubmit, handleBlur, handleChange, values }) => {
            return (
              <form onSubmit={handleSubmit} autoComplete='off'>
                <ModalBody>
                  <MessagesComponent display={error.display} type={error.type} message={error.message} />
                  <div className='margin-top-10'>
                    <Row>
                      <Col sm={4}>
                        <label>
                          Data do Orçamento <span> * </span>
                        </label>
                      </Col>
                      <Col s m={8}>
                        <DatePicker
                          invalid={errors.dataOrcamento ? true : false}
                          name={'dataOrcamento'}
                          selected={values.dataOrcamento}
                          onChange={(value) => {
                            handleChange({
                              target: {
                                name: 'dataOrcamento',
                                value
                              }
                            });
                          }}
                          locale='pt-br-griks'
                          className={[
                            'react-datepicker-ignore-onclickoutside input-secondary',
                            errors.dataOrcamento ? 'is-invalid form-control' : ''
                          ]}
                          placeholderText=''
                        />
                        <ErrorFieldWithoutBootstrap errors={errors} fieldName={'dataOrcamento'} />
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Row>
                    <Col sm={{ size: 3, offset: 6 }}>
                      <button className='button-primary' type={'submit'}>
                        Salvar
                      </button>
                    </Col>
                    <Col sm='3'>
                      <button className='button-tertiary' type={'button'} onClick={closeModal}>
                        Cancelar
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = ({ TreatmentBudgetReducer }) => {
  const { createModal } = TreatmentBudgetReducer;
  return {
    ...createModal
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(budgetCreateModal({ modal: false })),
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader())
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(TreatmentBudgetCreateModal);
