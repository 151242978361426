import { call, put } from 'redux-saga/effects';
import { budgetModalTreatmentPaymentMantenanceModal } from '../redux/TreatmentBudget.actions';
import DomainsAPI from '../../../../service/DomainsAPI';
import { showLoader, hideLoader } from '../../../../components/actions/Loader.actions';
import ProceduresAPI from '../../../../service/ProceduresAPI';

export function* modalTreatmentPaymentMantenance({ payload: { modal, formLoad = {} } }) {
  yield put(showLoader());

  const procedures = yield call(ProceduresAPI.getProcedureMonthly);
  formLoad.procedures = procedures.data;

  yield put(budgetModalTreatmentPaymentMantenanceModal({ modal, formLoad }));
  yield put(hideLoader());
}
