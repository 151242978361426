import React, { Component } from 'react';

import { Col, Container, Modal, ModalHeader, Row } from 'reactstrap';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import PatientAddForm from './PatientAdd.modal.form';

import ModalMessageConfirm from '../../../components/ModalMessageConfirm.component';

import { savePatient, showPatientEditModal } from '../redux/patient.actions';

import warning from '../../../assets/img/icons/warning.svg';

import { t } from 'typy';

import success from '../../../assets/img/icons/success.svg';
import Icon from '../../../components/Icon.component';
import ToastUtils from '../../../utils/ToastUtils';
import ToastCustomMessageComponent from '../../../components/ToastCustomMessage.component';

export class PatientAddModal extends Component {
  defaultState = {
    formModalAddValues: {},
    modalPhoneWarning: false,
    error: {
      display: false,
      type: 'danger',
      message: ''
    }
  };

  state = {
    ...this.defaultState
  };

  formSubmitSimpleSave = (values) => {
    this.setState({
      formModalAddValues: values
    });

    // if([1,2,3].includes(parseInt(values.indicationType)) && t(values, 'whoIndicated.id').isUndefined) {
    //   let tipo = '';
    //
    //   if(parseInt(values.indicationType) === 1) {
    //     tipo = 'Dentista';
    //   }else if(parseInt(values.indicationType) === 2) {
    //     tipo = 'Paciente';
    //   } else {
    //     tipo = 'Outros Profissionais';
    //   }
    //
    //   this.setState({
    //     error: {
    //       display: true,
    //       type: 'danger',
    //       message: `Quem indicou obrigatório, por favor digite o nome e selecione um ${tipo} na lista`
    //     }
    //   });
    // }else{
    if (!values.phone1 || values.phone1.length < 3) {
      if (!values.phone2 || values.phone2.length < 3) {
        this.props.close();
        this.setState({
          modalPhoneWarning: true
        });
      } else {
        this.saveSimplePatient();
      }
    } else {
      this.saveSimplePatient();
    }
    // }
  };

  saveSimplePatient = () => {
    const { formModalAddValues } = this.state;

    this.props.showLoader();
    this.props.savePatient(formModalAddValues);
    // console.log('result', result);
    this.close();
    ToastUtils.success(<ToastCustomMessageComponent iconImage={success} title={'Paciente Salvo com Sucesso !'} />, {
      autoClose: 6000,
      hideProgressBar: true,
      position: 'top-center'
    });
  };

  confirmSaveWithoutPhone = () => {
    this.setState(
      {
        modalPhoneWarning: false
      },
      () => {
        this.saveSimplePatient();
        ToastUtils.success(<ToastCustomMessageComponent iconImage={success} title={'Paciente Salvo com Sucesso !'} />, {
          autoClose: 6000,
          hideProgressBar: true,
          position: 'top-center'
        });
      }
    );
  };

  closeModalPhoneWarnning = () => {
    this.setState(
      {
        modalPhoneWarning: false
      },
      () => {
        this.props.hideLoader();
        this.props.openPatientRegisterModal();
      }
    );
  };

  componentDidMount = () => {
    this.setState({
      formModalAddValues: undefined
    });
  };

  close = () => {
    this.setState({
      ...this.defaultState
    });
    this.props.close();
  };

  render() {
    const { show, showLoader, hideLoader } = this.props;
    const { formModalAddValues, modalPhoneWarning, error } = this.state;
    const { name } = formModalAddValues || {};

    return (
      <div className='content-box-without-color'>
        <Container>
          <Modal isOpen={show} toggle={this.close} id='patientSave' className='modal-default'>
            <ModalHeader
              className='title-primary'
              toggle={this.close}
              close={
                <button className='close' onClick={this.close}>
                  &times;
                </button>
              }
            >
              <strong>Adicionar Paciente</strong>
            </ModalHeader>
            <PatientAddForm
              formModalAddValues={formModalAddValues}
              onSubmitForm={this.formSubmitSimpleSave}
              close={this.close}
              showLoader={showLoader}
              hideLoader={hideLoader}
              errorDisplay={error}
            />
          </Modal>
          <ModalMessageConfirm
            className='modal-small patientMessage'
            title={
              <span>
                <span className='font-light'>Paciente</span>
                <strong> {name}</strong>
              </span>
            }
            message={
              <span>
                <p>
                  Você não preencheu um <strong>número de telefone</strong> para o(a) paciente.
                </p>
                <p>Deseja prosseguir mesmo assim?</p>
              </span>
            }
            openModal={modalPhoneWarning}
            icon={warning}
            close={this.closeModalPhoneWarnning}
            footer={
              <Row>
                <Col sm='3' className='btn-list' key={1}>
                  <button className='button-tertiary' onClick={this.closeModalPhoneWarnning}>
                    Voltar
                  </button>
                </Col>
                <Col className='btn-list' sm={{ size: 3, offset: 6 }} key={2}>
                  <button className='button-primary' onClick={this.confirmSaveWithoutPhone}>
                    Prosseguir
                  </button>
                </Col>
              </Row>
            }
          />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    savePatient: (data) => dispatch(savePatient(data)),
    showPatientEditModal: (data) => dispatch(showPatientEditModal(data))
  };
};

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(PatientAddModal);
