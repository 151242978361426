import React, { Component } from 'react';
import PropTypes from 'prop-types';

const TagComprovante = ({ color, text }) => {
  return (
    <span className={'voucherOption'}>
      <span
        className={'voucherType'}
        style={{
          borderColor: color,
          color: color,
          marginLeft: 8
        }}
      >
        {text}
      </span>
    </span>
  );
};

TagComprovante.propsType = {
  color: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default TagComprovante;
