import t from 'typy';
import { createSelector } from 'reselect';

const financialFileSelector = (state) => t(state, 'TreatmentFinancialFileReducer.debitsPage').safeObject;

export const propsDebitsSelector = createSelector(financialFileSelector, (page = {}) => {
  return {
    data: {
      treatment: t(page, 'treatment').safeObject,
      financialFile: t(page, 'financialFile').safeObject
    },
    expandDebits: t(page, 'expandDebits').safeObject,
    treatment: t(page, 'treatment.id').safeObject,
    financialFile: t(page, 'financialFile.id').safeObject,
    finalizedTrading: t(page, 'financialFile.negociacao_finalizada').safeString,
    debits: t(page, 'financialFile.debitos').safeObject
  };
});
