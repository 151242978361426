import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

import edit from '../../../assets/img/icons/edit.svg';
import excluir from '../../../assets/img/icons/excluir.svg';
import Icon from '../../../components/Icon.component';

import warning from '../../../assets/img/icons/warning.svg';

import { dateFormat } from '../../../utils/Formatter';
import NotesModal from '../modals/Notes.modal';
import PacienteAPI from '../../../service/PacienteAPI';
import ErrorMessageComponent from '../../../components/ErrorMessage.component';
import { withLoader } from '../../../components/Loader.component';

import * as moment from 'moment';
import ModalMessageConfirm from '../../../components/ModalMessageConfirm.component';

class PatientNotesComponent extends Component {
  state = {
    displayConfirmModal: false,
    selectedNote: null,
    error: {
      display: false,
      type: 'danger',
      message: ''
    },
    errorNotes: {
      display: false,
      type: 'danger',
      message: ''
    },
    note: {
      date: moment().startOf('day'),
      text: ''
    }
  };

  saveNote = () => {
    const { note } = this.state;
    const { date, text } = note;

    if (!date || !text) {
      this.setState({
        errorNotes: {
          display: true,
          type: 'danger',
          message: 'Data e Anotação são obrigatórios'
        }
      });
    } else {
      const noteSave = {
        data: date,
        text
      };
      this.props.showLoader();
      let promisseSave = {};
      if (!note.id) {
        promisseSave = PacienteAPI.saveNote(this.props.patient.paciente_id, noteSave);
      } else {
        noteSave.id = note.id;

        promisseSave = PacienteAPI.updateNote(this.props.patient.paciente_id, noteSave);
      }
      promisseSave
        .then((n) => {
          const { hideLoader, closeModal, refreshAllNotes } = this.props;
          hideLoader();
          closeModal();
          refreshAllNotes();
        })
        .catch((err) => {
          console.log(err);
          this.props.hideLoader();
          this.setState({
            error: {
              display: true,
              type: 'danger',
              message: 'Erro ao Cadastrar Anotacao'
            }
          });
        });
    }
  };

  deleteConfirm = (id) => {
    this.setState({
      selectedNote: id,
      displayConfirmModal: true
    });
  };

  closeModalConfirm = () => this.setState({ displayConfirmModal: false });

  deleteNote = () => {
    const { paciente_id } = this.props.patient;
    const { selectedNote } = this.state;
    this.props.showLoader();
    PacienteAPI.deleteNote(paciente_id, selectedNote)
      .then((e) => {
        this.setState({ displayConfirmModal: false });
        this.props.refreshAllNotes();
        this.props.hideLoader();
        this.props.showMessage('Anotação deletada com sucesso', 'success');
      })
      .catch((err) => {
        console.log(err);
        this.props.hideLoader();
        this.setState({
          error: {
            display: true,
            type: 'danger',
            message: 'Erro ao Excluir anotação'
          }
        });
      });
  };

  closeModal = () => this.setState({ notesModal: false });

  changeDate = (date) => {
    this.setState({
      note: {
        ...this.state.note,
        date
      }
    });
  };

  changeTextUpdate = (value) => {
    this.setState({
      note: {
        ...this.state.note,
        text: value
      }
    });
  };

  editNote = (note) => {
    this.props.openNotesModal();
    this.setState({
      note: {
        id: note.id,
        date: moment(note.data_registro).startOf('day'),
        text: note.anotacao
      }
    });
  };

  render() {
    const { notes = [] } = this.props;
    const { error } = this.state;
    return (
      <Container className='mt-3'>
        <ErrorMessageComponent
          id={'patientnotes'}
          where={'patientnotes'}
          display={error.display}
          type={error.type}
          message={error.message}
        />
        <div className='box-primary margin-top-10'>
          <Row>
            <Col sm='1'>
              <span className='text-sm text-secondary-d4 font-bolder'>Data</span>
            </Col>
            <Col sm='8'>
              <span className='text-sm text-secondary-d4 font-bolder'>Anotação</span>
            </Col>
            <Col sm='2'>
              <span className='text-sm text-secondary-d4 font-bolder'>Usuário</span>
            </Col>
            <Col sm='1' />
          </Row>
        </div>

        <div className='box-search'>
          <div style={{ display: notes.length <= 0 ? 'block' : 'none' }}>Nenhuma Anotação Cadastrada</div>
          {notes.map((a) => (
            <div className='margin-top-10' key={a.id}>
              <Row>
                <Col sm='1'>
                  <p>{dateFormat(a.data_registro, 'DD/MM/YYYY')}</p>
                </Col>
                <Col sm='8'>
                  <div>{a.anotacao}</div>
                </Col>
                <Col sm='2'>
                  <p>{a.usuario_nome}</p>
                </Col>
                <Col sm='1'>
                  <button className='float-right ml-3' onClick={() => this.deleteConfirm(a.id)}>
                    <Icon className='icon icon-size-small icon-alert' image={excluir} alt='excluir' />
                  </button>
                  <button className='float-right' onClick={() => this.editNote(a)}>
                    <Icon className='icon icon-size-small' image={edit} alt='edit' />
                  </button>
                </Col>
              </Row>
            </div>
          ))}
        </div>

        <NotesModal
          notesModal={this.props.displayModal}
          note={this.state.note}
          errorNotes={this.state.errorNotes}
          closeModal={this.props.closeModal}
          changeDate={this.changeDate}
          changeTextUpdate={this.changeTextUpdate}
          saveNote={this.saveNote}
        />

        <ModalMessageConfirm
          className='modal-exclude'
          title={<strong>Excluir Anotação</strong>}
          message={
            <span>
              <p>Confirma a exclusão desta anotação?</p>
            </span>
          }
          openModal={this.state.displayConfirmModal}
          icon={warning}
          footer={
            <Row>
              <Col sm='3'>
                <button className='button-tertiary' onClick={this.closeModalConfirm}>
                  Não
                </button>
              </Col>
              <Col sm={{ size: 3, offset: 6 }}>
                <button className='button-primary' onClick={this.deleteNote}>
                  Sim
                </button>
              </Col>
            </Row>
          }
        />
      </Container>
    );
  }
}

export default withLoader(PatientNotesComponent);
