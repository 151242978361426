import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { isLogged } from '../utils/localStorage/SessionStorageUtils';

import { connect } from 'react-redux';

const PrivateRoute = (props) => {
  return <div>{isLogged() === true ? <Route {...props} /> : <Redirect to='/login' />}</div>;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps)(PrivateRoute);
