import React, { useEffect, useState } from 'react';
import moment from 'moment';

const Timer = ({ entrada, timerColor, color }) => {
  const [timer, setTimer] = useState();
  const [timerMoment, setTimerMoment] = useState();

  useEffect(() => {
    createTimer();
    let refreshIntervalId = setInterval(() => createTimer(), 1000);
    return () => {
      clearInterval(refreshIntervalId);
    };
  }, []);

  function createTimer() {
    const horaAtual = moment();
    const flowStart = moment(entrada);
    const difference = horaAtual.diff(flowStart, 'second');

    let timerNow = moment();
    timerNow = timerNow.set({ hour: 0, minutes: 0, seconds: 0 });
    if (difference >= 0) {
      timerNow.add(difference, 'second');
    }
    setTimerMoment(timerNow);

    if (timerNow.get('hour') > 0) {
      setTimer(timerNow.format('HH:mm:ss'));
    } else {
      setTimer(timerNow.format('mm:ss'));
    }
  }

  // function getColor() {
  //   const timeAwait = moment().diff(timerMoment, 'minutes');
  //   if(timeAwait <= 10) {
  //     return '#28a745';
  //   }else if(timeAwait > 10 && timeAwait < 20) {
  //     return '#dc3545';
  //   }else {
  //     return '#b83cef';
  //   }
  // }

  return (
    <p className='font-bold text-secondary text-md-plus'>
      <span style={{ color: color ? color : timerColor(timerMoment) }}>{timer}</span>
    </p>
  );
};

export default Timer;
