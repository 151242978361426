import {
  SETTINGS_SCHEDULE_CHAIRS_MODAL,
  SETTINGS_SCHEDULE_OFFICE_HOURS_MODAL,
  SETTINGS_SCHEDULE_STANDARD_INTERVAL_MODAL,
  SETTINGS_SCHEDULE_STANDARD_PROCEDURE_MODAL
} from './ScheduleSettings.actions';

const initialState = {};

const ScheduleSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS_SCHEDULE_CHAIRS_MODAL:
      return {
        ...state,
        chairs: {
          ...action.payload
        }
      };
    case SETTINGS_SCHEDULE_STANDARD_INTERVAL_MODAL:
      return {
        ...state,
        standardInterval: {
          ...action.payload
        }
      };
    case SETTINGS_SCHEDULE_STANDARD_PROCEDURE_MODAL:
      return {
        ...state,
        procedure: {
          ...action.payload
        }
      };
    case SETTINGS_SCHEDULE_OFFICE_HOURS_MODAL:
      return {
        ...state,
        officeHours: {
          ...action.payload
        }
      };
    default:
      return state;
  }
};

export default ScheduleSettingsReducer;
