import React, { Component } from 'react';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { antecipateModalToProps } from '../selectors/TreatmentAnticipateModalSelector';
import { clinicalRecordTreatmentAnticipateModal } from '../redux/TreatmentClinicalRecord.action';
import { showLoader, hideLoader } from '../../../../components/actions/Loader.actions';
import CashAPI from '../../../../service/CashAPI';
import { toast } from 'react-toastify';

class TreatmentClinicalRecordAntecipatePaymentConfirmModal extends Component {
  antecipateDebit = () => {
    const { showLoader, hideLoader, closeModal, updateParent, record, debit } = this.props;
    showLoader();
    CashAPI.anticipateDebit({ financialFile: record, debit: debit.id })
      .then((d) => {
        updateParent();
        closeModal();
        hideLoader();
      })
      .catch((e) => {
        console.error(e);
        hideLoader();
        toast.error('Erro ao antecipar lançamento', { autoClose: false, position: 'top-center' });
      });
  };

  render() {
    const { modal, closeModal } = this.props;

    return (
      <Modal isOpen={modal} toggle={closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={closeModal}
          close={
            <button className='close' onClick={closeModal}>
              &times;
            </button>
          }
        >
          <strong>Antecipar Lançamento</strong>
        </ModalHeader>
        <ModalBody>
          <div className='margin-top-10'>
            <Row className='margin-top-10'>
              <Col sm='12'>
                <span className='text-default'>
                  Por padrão, <strong>os débitos são lançados no dia 1º de seu mês de vencimento,</strong> na Ficha de
                  Pagamento
                </span>
                <br />
                <br />
                <span className='text-default'>
                  Com este comando, você vai{' '}
                  <strong>antecipar o lançamento do débito selecionado para o dia de hoje.</strong> Este procedimento se
                  aplica quando o paciente deseja antecipar o pagamento.
                </span>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col className={'btn-list'} sm={{ size: 6, offset: 6 }}>
              <button className='button-primary' onClick={this.antecipateDebit}>
                Lançar
              </button>
              <button className='button-tertiary' onClick={closeModal}>
                Cancelar
              </button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...antecipateModalToProps(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  closeModal: () => dispatch(clinicalRecordTreatmentAnticipateModal({ modal: false }))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(TreatmentClinicalRecordAntecipatePaymentConfirmModal);
