import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { sendMessage } from './actions/ErrorMessage.actions';

const ErrorMessageComponent = (props) => {
  const { display = false, type = 'danger', message, where, id } = props;

  return (
    <div>
      {display && id && where === id ? (
        <Alert color={type} {...props}>
          {message}
        </Alert>
      ) : null}
    </div>
  );
};

ErrorMessageComponent.propTypes = {
  // display: PropTypes.bool.isRequired,
  // type: PropTypes.string.isRequired,
  // message: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
  return state.ErrorMessage;
};

const mapDispatchToProps = (dispatch) => {
  return {
    dismiss: (data) => dispatch(sendMessage(data))
  };
};
const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(ErrorMessageComponent);
