import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { IfComponent } from '../../../components/if.component';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { propsMonthlyPaymentSelector } from './selectors/FinancialFileMonthlyPaymentPage.selector';
import { t } from 'typy';
import { dateFormat, formatReal } from '../../../utils/Formatter';
import moment from 'moment';

class TreatmentFinancialFileMonthlyPaymentPage extends Component {
  render() {
    const { monthly = {}, contractDate = '' } = this.props;

    const valorEntrada = t(monthly, 'valor_total_entrada').safeNumber;
    const valorDesconto = t(monthly, 'valor_desconto').safeNumber;
    const quantidadeEstimada = t(monthly, 'quantidade_estimada').safeNumber;
    let valorMensalidade =
      t(monthly, 'valor_mensalidade').safeNumber - t(monthly, 'valor_desconto_mensalidade').safeNumber;

    const valorTotalEntrada = valorEntrada - valorDesconto;
    const valorTotalManutencao = quantidadeEstimada * valorMensalidade;
    const valorTotalTratamento = valorTotalEntrada + valorTotalManutencao;
    const lastDate = moment(contractDate)
      .add(monthly.parcelado_em + quantidadeEstimada, 'M')
      .set('D', monthly.dia_vencimento_mensalidade);

    return (
      <IfComponent conditional={t(monthly, 'valor_total_entrada').isDefined}>
        <Row className={'w-100 mt-3'} key={'page'}>
          <Col sm='12'>
            <div className='box-default'>
              <Row>
                <Col sm='12'>
                  <h2 className='title-large text-uppercase text-secondary font-bold'>
                    Resumo da Conta - Mensalidades / Manutenção
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col sm='6'>
                  <h2 className='text-default font-bold'>ENTRADA / APARELHO</h2>
                  <p className={'text-default text-sm text-secondary-d4 mb-2 '}>
                    <strong className={'text-secondary-d4'}>Valor Total</strong>
                    {formatReal(valorEntrada - valorDesconto)}
                  </p>
                  <IfComponent conditional={monthly.parcelado_em > 0}>
                    <p className={'text-default text-sm text-secondary-d4'}>
                      <strong className={'text-secondary-d4'}>Parcelamento</strong>
                      {monthly.valor_entrada && formatReal(monthly.valor_entrada) + ' + '} {monthly.parcelado_em}x{' '}
                      {formatReal(monthly.valor_parcela_entrada)}
                    </p>
                  </IfComponent>
                </Col>
                <Col sm='6'>
                  <h2 className='text-default font-bold'>MENSALIDADES / MANUTENÇÃO</h2>
                  <p className={'text-default text-sm text-secondary-d4 mb-2'}>
                    <strong className={'text-secondary-d4'}>Mensalidade / Manutenção</strong>
                    {formatReal(valorMensalidade)}
                  </p>
                  <p className={'text-default text-sm text-secondary-d4 mb-2'}>
                    <strong className={'text-secondary-d4'}>Dia do Vencimento</strong>
                    {monthly.dia_vencimento_mensalidade}
                  </p>
                  <p className={'text-default text-sm text-secondary-d4'}>
                    <strong className={'text-secondary-d4'}>Quantidade Estimada</strong>
                    {quantidadeEstimada} (até {dateFormat(lastDate, 'MMMM/YYYY')})
                  </p>
                </Col>
              </Row>
              <Row className='margin-top-10'>
                <Col sm='12'>
                  <div className='border-top border-bottom text-center py-2'>
                    <span className={'text-default text-sm text-secondary-d4'}>
                      Valor Total do Tratamento
                      <strong className={'text-secondary-d4 ml-2'}>{formatReal(valorTotalTratamento)}</strong>
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </IfComponent>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...propsMonthlyPaymentSelector(state)
  };
};

const mapDispatchToProps = (dispatch) => ({});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(TreatmentFinancialFileMonthlyPaymentPage);
