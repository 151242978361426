import { formatDate } from './DateUtils';
import { parsePhoneNumber } from 'libphonenumber-js';
import { t } from 'typy';

export function cpfFormat(cpf = '') {
  cpf = cpf.replace(/[^\d]/g, '');
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}

export function rgFormat(rg = '') {
  rg = rg.replace(/[^\w]/g, '');
  if (rg.length === 9) {
    return rg.replace(/(\d{2})(\d{3})(\d{3})(\w{1})/, '$1.$2.$3-$4');
  } else {
    return rg.replace(/(\d{2})(\d{3})(\d{3})(\w{2})/, '$1.$2.$3-$4');
  }
}

export function cepFormat(cep = '') {
  if (cep) {
    return cep.replace(/(\d{5})(\d{3})/, '$1-$2');
  }
  return '';
}

export function formatFloat(int) {
  if (t(int).isDefined) {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      currency: 'BRL',
      minimumFractionDigits: 2
    });
    return formatter.format(int);
  } else {
    return 'R$ ';
  }
}

export function formatReal(int) {
  if (t(int).isTruthy) {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2
    });
    return formatter.format(int);
  } else {
    return 'R$ ';
  }
}

export function formatRealSemVerificacao(int) {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  });
  return formatter.format(int);
}

export function formatRealNoShowDolarSign(int) {
  if (t(int).isDefined) {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2
    });
    return formatter.format(int);
  } else {
    return '';
  }
}

export function dateFormat(date, pattern) {
  if (date) {
    return formatDate(date, pattern);
  }
}

export function phoneFormat(number) {
  if (number && number.toString().length > 2) {
    const phoneNumber = parsePhoneNumber(number, 'BR');
    return phoneNumber.formatNational();
  }
  return '';
}

export function maskCEP() {
  return [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
}

export function maskDate() {
  return [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
}

export function maskTime() {
  return [/\d/, /\d/, ':', /\d/, /\d/];
}
//99.999.999/9999-99
export function maskCNPJ() {
  return [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
}

export function maskCPF() {
  return [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
}

export function maskRG() {
  return [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /[0-9xX]/];
}

export function maskPhoneDefine(phone = '') {
  let maskTelefone = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/];

  if (phone && phone.replace(/[^a-zA-Z0-9]/g, '').length === 11) {
    maskTelefone = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  }
  return maskTelefone;
}
