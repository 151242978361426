import React from 'react';
import PrivateRouter from '../components/PrivateRoute.component';
import { Route } from 'react-router';
import DeployHomePage from '../pages/deploy/DeployHomePage';

const DeployRouters = (props) => {
  return [<PrivateRouter key='DeployHomePage' exact path='/deploy' render={(props) => <DeployHomePage {...props} />} />];
};

export default DeployRouters;
