import React, { Component } from 'react';

import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { treatmentEvaluationReceptionRegister } from '../redux/TreatmentEvaluation.action';
import { Formik } from 'formik';
import TreatmentEvaluationApi from '../../../../service/TreatmentEvaluationApi';
import { hideLoader, showLoader } from '../../../../components/actions/Loader.actions';
import MessagesComponent from '../../../../components/Messages.component';

import Select from 'react-select';
import { reactSelectHandleChange, reactSelectStyleIsError } from '../../../../utils/ReactSelectUtils';
import { selectStyles } from '../../../../../src/config/core.config';

class TreatmentEvaluationReceptionRegistrationModal extends Component {
  state = {
    message: {
      display: false,
      type: 'danger',
      text: ''
    }
  };

  validationForm = {};

  saveReceptionAndRegister = async (values) => {
    const { closeModal, formLoad = {}, showLoader, hideLoader, updateParent } = this.props;
    const { treatment, evaluation } = formLoad;
    showLoader();
    try {
      await TreatmentEvaluationApi.saveReceptionAndRegister({
        id: evaluation.avaliacao_id,
        tratamento: treatment.id,
        motivo_consulta: values.motivo_consulta && values.motivo_consulta.value,
        acompanhante: values.acompanhante,
        acompanhante_parentesco: values.parentesco,
        utiliza_aparelho: values.usa_aparelho,
        descricao_utiliza_aparelho: values.usa_aparelho_desc,
        usou_aparelho: values.usou_aparelho,
        descricao_usou_aparelho: values.usou_aparelho_desc,
        anotacoes: values.anotacoes
      });
      await updateParent();
      closeModal();
    } catch (e) {
      console.log(e);
      this.setState({
        message: {
          display: true,
          type: 'danger',
          text: 'Erro ao salvar dados de informação da recepção e cadastro entre em contato com o suporte'
        }
      });
    }

    hideLoader();
  };

  render() {
    const { message } = this.state;
    const { modal, closeModal, formLoad = {}, formInit = {}, reasonsOptions } = this.props;
    const { reasons = [] } = formLoad;

    return (
      <Modal isOpen={modal} toggle={closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={closeModal}
          close={
            <button className='close' onClick={closeModal}>
              &times;
            </button>
          }
        >
          <strong>Informações da Recepção e Cadastro</strong>
        </ModalHeader>
        <Formik
          initialValues={formInit}
          validateOnChange={false}
          validationSchema={this.validationForm}
          onSubmit={this.saveReceptionAndRegister}
        >
          {({ error, errors, values, handleSubmit, handleChange }) => (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <div className='margin-top-10'>
                  <MessagesComponent display={message.display} type={message.type} message={message.text} />
                  <Row className='margin-top-10'>
                    <Col sm='12'>
                      <label className='w-100'>Motivo da Consulta </label>
                      <Select
                        options={reasonsOptions}
                        ignoreCase={true}
                        value={values.motivo_consulta}
                        placeholder={''}
                        onChange={(e) => reactSelectHandleChange(e, handleChange, 'motivo_consulta')}
                        styles={reactSelectStyleIsError(errors, 'motivo_consulta', selectStyles)}
                      />
                    </Col>
                  </Row>
                  <Row className='margin-top-10'>
                    <Col sm='8'>
                      <label>Acompanhante</label>
                      <input
                        className='input-secondary'
                        type='text'
                        name='acompanhante'
                        onChange={handleChange}
                        value={values.acompanhante}
                      ></input>
                    </Col>
                    <Col sm='4'>
                      <label>Parentesco</label>
                      <input
                        className='input-secondary'
                        type='text'
                        name='parentesco'
                        onChange={handleChange}
                        value={values.parentesco}
                      ></input>
                    </Col>
                  </Row>
                  <Row className={'pb-0'}>
                    <Col sm='12'>
                      <label>Está Usando Aparelho?</label>
                      <div className='input-radio pl-3'>
                        <input
                          type='radio'
                          id='usa_aparelho'
                          onChange={handleChange}
                          name='usa_aparelho'
                          value='S'
                          checked={values.usa_aparelho === 'S'}
                        />
                        <label htmlFor='sim'>Sim</label>
                      </div>

                      <div className='input-radio pl-3'>
                        <input
                          type='radio'
                          id='usa_aparelho'
                          onChange={handleChange}
                          name='usa_aparelho'
                          value='N'
                          checked={values.usa_aparelho === 'N'}
                        />
                        <label htmlFor='nao'>Não</label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm='12'>
                      <textarea
                        className='form-control'
                        rows='2'
                        cols='50'
                        name={'usa_aparelho_desc'}
                        value={values.usa_aparelho_desc}
                        onChange={handleChange}
                      ></textarea>
                    </Col>
                  </Row>
                  <Row className={'pb-0'}>
                    <Col sm='12'>
                      <label>Já Usou Aparelho?</label>
                      <div className='input-radio pl-3'>
                        <input
                          type='radio'
                          id='usou_aparelho'
                          onChange={handleChange}
                          name='usou_aparelho'
                          value='S'
                          checked={values.usou_aparelho === 'S'}
                        />
                        <label htmlFor='sim'>Sim</label>
                      </div>

                      <div className='input-radio pl-3'>
                        <input
                          type='radio'
                          id='usou_aparelho'
                          onChange={handleChange}
                          name='usou_aparelho'
                          value='N'
                          checked={values.usou_aparelho === 'N'}
                        />
                        <label htmlFor='nao'>Não</label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm='12'>
                      <textarea
                        className='form-control'
                        rows='2'
                        cols='50'
                        name={'usou_aparelho_desc'}
                        value={values.usou_aparelho_desc}
                        onChange={handleChange}
                      ></textarea>
                    </Col>
                  </Row>
                  <Row className='margin-top-10'>
                    <Col sm='12'>
                      <label>Anotações</label>
                      <textarea
                        className='form-control'
                        rows='2'
                        cols='50'
                        name={'anotacoes'}
                        value={values.anotacoes}
                        onChange={handleChange}
                      ></textarea>
                    </Col>
                  </Row>
                </div>
              </ModalBody>
              <ModalFooter>
                <Row>
                  <Col className='btn-list' sm={{ size: 6, offset: 6 }}>
                    <button className='button-primary'>Salvar</button>
                    <button className='button-tertiary' type={'button'} onClick={closeModal}>
                      Cancelar
                    </button>
                  </Col>
                </Row>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = ({ TreatmentEvaluationReducer }) => {
  const { evaluationReceptionRegisterModal = {} } = TreatmentEvaluationReducer;
  const { formLoad: { evaluation = {}, reasons } = {} } = evaluationReceptionRegisterModal;

  const reasonsOptions = [{}];
  if (reasons) {
    reasonsOptions.push(
      ...reasons.map((r) => ({
        value: r.id,
        label: r.descricao,
        data: r
      }))
    );
  }

  return {
    ...evaluationReceptionRegisterModal,
    formInit: {
      motivo_consulta:
        evaluation.motivo_consulta_id && reasonsOptions.find((r) => r.id === evaluation.motivo_consulta_id),
      acompanhante: evaluation.nome_acompanhante,
      parentesco: evaluation.parentesco_acompanhante,
      usa_aparelho: evaluation.utiliza_aparelho && evaluation.utiliza_aparelho.substr(0, 1),
      usa_aparelho_desc: evaluation.descricao_utiliza_aparelho,
      usou_aparelho: evaluation.usou_aparelho && evaluation.usou_aparelho.substr(0, 1),
      usou_aparelho_desc: evaluation.descricao_usou_aparelho,
      anotacoes: evaluation.anotacoes
    },
    reasonsOptions
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(treatmentEvaluationReceptionRegister({ modal: false })),
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader())
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(TreatmentEvaluationReceptionRegistrationModal);
