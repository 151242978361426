import React, { Component } from 'react';

import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import copy from '../../../assets/img/icons/copy.svg';
import Icon from '../../../components/Icon.component';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { t } from 'typy';
import moment from 'moment';
import { phoneFormat } from '../../../utils/Formatter';
import { appointmentMessageModal } from '../redux/Schedule.actions';

class AppointmentConfirmMessageModal extends Component {
  textArea = '';

  message = `Olá! 
Confirmamos a consulta de #PACIENTE#, #SEMANA# dia #DATA# às #HORA# hs. 
- Responda SIM, para confirmar ou NÃO para Cancelar. 
- Para reagendar, responda a mensagem ou ligue #TELEFONE#. 
 
Obrigado,
#CLINICA#`;

  render() {
    const { modal, closeModal, clinic = {}, appointment = {} } = this.props;

    let msg = this.message.replace('#PACIENTE#', t(appointment, 'paciente.nome').safeString);
    const appointmentDate = moment(appointment.data);
    msg = msg.replace('#SEMANA#', appointmentDate.format('dddd').toUpperCase());
    msg = msg.replace('#DATA#', appointmentDate.format('DD/MM/YYYY'));
    msg = msg.replace('#HORA#', appointment.horario);

    msg = msg.replace('#TELEFONE#', phoneFormat(clinic.ddd + clinic.telefone));
    msg = msg.replace('#CLINICA#', clinic.nome);

    return (
      <Modal isOpen={modal} toggle={closeModal} className={this.props.className} size={'lg'}>
        <ModalHeader
          className='title-primary'
          toggle={closeModal}
          close={
            <button className='close' onClick={closeModal}>
              &times;
            </button>
          }
        >
          <strong>Ver</strong> Mensagem de Confirmação
        </ModalHeader>
        <ModalBody>
          <div className='margin-top-10'>
            <Row style={{ backgroundColor: '#f2f2f2', padding: 10 }}>
              <Col sm='12'>
                <div className='content-message-top'>MENSAGEM</div>
                <div className='box-search' style={{ padding: 10, margin: 10, textAlign: 'left' }}>
                  <textarea rows={10} ref={(textarea) => (this.textArea = textarea)}>
                    {msg}
                  </textarea>
                </div>
              </Col>
            </Row>
            <Row className='margin-top-10'>
              <Col sm='12'>Você pode editar livremente a mensagem antes de copiar ou copiar para enviar.</Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col sm={{ size: 3, offset: 9 }}>
              <button
                className='button-tertiary'
                onClick={() => {
                  this.textArea.select();
                  document.execCommand('copy');
                }}
              >
                Copiar
                <Icon className='icon icon-size-small margin-left-10' image={copy} alt='copy' />
              </button>{' '}
            </Col>
          </Row>
          <Row className='margin-top-20'>
            <Col sm={{ size: 3, offset: 9 }}>
              <button className='button-tertiary' onClick={closeModal}>
                Cancelar
              </button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { ScheduleReducer } = state;

  return {
    ...ScheduleReducer.appointmentMessageModal
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(appointmentMessageModal({ modal: false }))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(AppointmentConfirmMessageModal);
