import React from 'react';
import { t } from 'typy';

export const reactSelectHandleChange = (data, handleChange, name) => {
  handleChange({
    target: {
      name: name,
      value: data
    }
  });
};

export const reactSelectStyleIsError = (errors, field, styles = {}) => {
  if (t(errors, field).isDefined) {
    return {
      ...styles,
      control: (base) => ({
        ...base,
        borderColor: 'red'
      })
    };
  } else {
    return {
      ...styles
    };
  }
};

export const arrayToSelectItems = (array, label, value) => {
  return array.map((item) => ({
    label: t(item, label).safeString,
    value: t(item, value).safeObject,
    data: item
  }));
};
