import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

const PercentLabelComponent = ({ percent, style = {} }) => {
  const mask = () => {
    if (percent === 100) {
      return '###';
    } else if (percent > 0.99 && percent < 10) {
      return '#';
    } else {
      return '##';
    }
  };
  return (
    <>
      <NumberFormat
        value={percent}
        displayType={'text'}
        decimalScale={3}
        allowLeadingZeros={true}
        fixedDecimalScale={true}
        style={style}
        format={mask()}
      />
      %
    </>
  );
};

PercentLabelComponent.propTypes = {
  percent: PropTypes.number.isRequired,
  style: PropTypes.string
};

export default PercentLabelComponent;
