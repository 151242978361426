import React, { Component } from 'react';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import TreatmentBudgetApi from '../../../../service/TreatmentBudgetApi';
import MessagesComponent from '../../../../components/Messages.component';

class TreatmentBudgetAproveModal extends Component {
  state = {
    error: {
      display: false,
      type: 'danger',
      message: ''
    }
  };

  budgetAprove = async () => {
    const { closeModal, budget, updateParent, showLoader, hideLoader } = this.props;

    try {
      showLoader();
      await TreatmentBudgetApi.budgetAprove(budget.id);
      updateParent();
      closeModal();
    } catch (e) {
      console.log(e);
      this.setState({
        error: {
          display: true,
          type: 'danger',
          message: 'Erro ao aprovar orçamento entre em contato com o suporte'
        }
      });
    }
    hideLoader();
  };

  render() {
    const { modal, closeModal, paymentModality } = this.props;
    const { error } = this.state;

    return (
      <Modal isOpen={modal} toggle={closeModal} className={this.props.className} size={'default'}>
        <ModalHeader
          className='title-primary'
          toggle={closeModal}
          close={
            <button className='close' onClick={closeModal}>
              &times;
            </button>
          }
        >
          <strong>Aprovar Orçamento</strong>
        </ModalHeader>
        <ModalBody>
          <div className=''>
            <MessagesComponent display={error.display} type={error.type} message={error.message} />
            <Row>
              <Col sm='12'>
                <span className='text-default text-secondary'>
                  Ao aprovar este Orçamento de{' '}
                  <span className='text-uppercase'>
                    <strong className='mr-0'>{paymentModality},</strong>
                  </span>{' '}
                  automaticamente será criada a <strong className='mr-0'>Ficha Financeira,</strong> de acordo com os
                  dados do Orçamento.
                  <br />
                  <br />
                  Após aprovado, o Orçamento permanecerá disponível apenas para leitura, não podendo mais ser alterado.
                </span>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col className='btn-list' sm={{ size: 6, offset: 6 }}>
              <button className='button-primary' onClick={this.budgetAprove}>
                Aprovar
              </button>
              <button className='button-tertiary' onClick={closeModal}>
                Cancelar
              </button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}

export default TreatmentBudgetAproveModal;
