import { clinicalRecordSummaryModal } from '../redux/TreatmentClinicalRecord.action';
import { all, call, put } from 'redux-saga/effects';
import DomainsAPI from '../../../../service/DomainsAPI';
import _ from 'lodash';

export function* clinicalRecordSummarySaga({ payload: { modal, formLoad = {} } }) {
  const domains = yield all([
    call(DomainsAPI.findAllTreatmentTypes),
    call(DomainsAPI.findAllComplaint),
    call(DomainsAPI.findAllBraces),
    call(DomainsAPI.findAllPrescricoes),
    call(DomainsAPI.findAllSlot)
  ]);

  formLoad.treatmentTypes = domains[0];
  formLoad.complaints = domains[1];
  formLoad.treatmentTimes = _.range(6, 54, 6);
  formLoad.braces = domains[2];
  formLoad.prescricoes = domains[3];
  formLoad.slots = domains[4];

  yield put(clinicalRecordSummaryModal({ modal, formLoad }));
}
