import React, { useEffect, useState } from 'react';
import moment from 'moment';

const TimerInMinutes = ({ entrada, tempo, timerColor }) => {
  const [timer, setTimer] = useState();
  const [timerMoment, setTimerMoment] = useState();

  useEffect(() => {
    createTimer();
    let refreshIntervalId = setInterval(() => createTimer(), 1000);
    return () => {
      clearInterval(refreshIntervalId);
    };
  }, []);

  function createTimer() {
    let procedimento = moment(entrada).add(tempo, 'minute');
    const difference = procedimento.diff(moment(), 'minutes', true);

    let timer = moment();
    timer = timer.set({ hour: 0, minutes: 0, seconds: 0 });
    timer.add(difference, 'seconds');

    setTimerMoment(timer);
    // setTimer(timer.format('mm'));
    setTimer(Math.round(difference) * -1);
  }

  function getColor() {
    if (timer > 0) {
      return '#dc3545';
    } else {
      return '#000000';
    }
  }

  return (
    <span className='text-xsm font-bold py-0 ml-1 mr-1' style={{ color: timerColor ? timerColor(timer) : getColor() }}>
      {timer > 0 ? `+${timer}` : timer}'
    </span>
  );
};

export default TimerInMinutes;
