export const LOADER_MESSAGE = 'LOADER_MESSAGE';

export const showLoader = (data) => ({
  type: LOADER_MESSAGE,
  payload: { display: true }
});

export const hideLoader = (data) => ({
  type: LOADER_MESSAGE,
  payload: { display: false }
});

export const loaderMessage = (data) => ({
  type: LOADER_MESSAGE,
  payload: data
});
