import React, { Component } from 'react';
import { Button, ButtonGroup, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import Select, { components } from 'react-select';
import DatePicker from 'react-datepicker';
import '../../../../assets/css/components/TreatmentBudgetInformationsModal.css';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { budgetModalInformations } from '../redux/TreatmentBudget.actions';
import { Form, Formik } from 'formik';
import moment from 'moment';
import TreatmentBudgetApi from '../../../../service/TreatmentBudgetApi';
import MessagesComponent from '../../../../components/Messages.component';
import { hideLoader, showLoader } from '../../../../components/actions/Loader.actions';
import ErrorField from '../../../../components/ErrorField.component';

import * as Yup from 'yup';
import ErrorFieldWithoutBootstrap from '../../../../components/ErrorFieldWithoutBootstrap.component';
import { maskCPF, maskRG } from '../../../../utils/Formatter';
import InputMaskComponent from '../../../../components/InputMask.component';
import { t } from 'typy';
import { IfComponent } from '../../../../components/if.component';
import { selectStyles } from '../../../../config/core.config';
import { reactSelectStyleIsError } from '../../../../utils/ReactSelectUtils';

const styleBorderLine = { border: 1, borderStyle: 'solid', borderColor: '#D4D9E2' };

const SingleValue = ({ children, ...props }) => {
  const { data } = props;
  return (
    <components.SingleValue {...props}>
      {data.value && (
        <div className={'voucherOption'}>
          <div className={'voucherType'} style={{ borderColor: data.color, color: data.color }}>
            {data.value}
          </div>
          {data.label}
        </div>
      )}
    </components.SingleValue>
  );
};

const CustomOptionVoucher = (props) => {
  const { innerProps, isDisabled, data, getStyles } = props;
  console.log(getStyles('option', props));
  return !isDisabled ? (
    <div style={getStyles('option', props)} {...innerProps}>
      <div className={'voucherOption'}>
        <div className={'voucherType'} style={{ borderColor: data.color, color: data.color }}>
          {data.value}
        </div>
        {data.label}
      </div>
    </div>
  ) : null;
};

class TreatmentBudgetInformationsModal extends Component {
  formValidationSchema = Yup.object().shape({
    data_orcamento: Yup.date().nullable().required('Data Orçamento Obrigatório'),
    tipo_responsavel: Yup.object().shape({
      value: Yup.string().required('Responsável Obrigatório')
    })
  });

  state = {
    error: {
      display: false,
      type: 'danger',
      message: ''
    },
    isSubmit: false
  };

  saveBudgetInformation = async (values) => {
    const {
      formLoad: { treatment },
      showLoader,
      hideLoader,
      updateParent
    } = this.props;
    try {
      showLoader();
      await TreatmentBudgetApi.saveBudgetInformation({
        tratamento: treatment.id,
        id: values.id,
        dataOrcamento: values.data_orcamento,
        validade: values.validade,
        tipoResponsavel: t(values, 'tipo_responsavel.data.id').safeString,
        nomeResponsavel: values.nome_responsavel,
        cpf: values.cpf,
        rg: values.rg,
        comprovante: values.comprovante && values.comprovante.data && values.comprovante.data.name
      });
      updateParent();
      this.closeModal();
    } catch (e) {
      console.log(e);
      this.setState({
        error: {
          display: true,
          type: 'danger',
          message: 'Erro ao salvar orçamento entre em contato com o suporte'
        }
      });
    }
    hideLoader();
  };

  closeModal = () => {
    this.setState({
      error: {
        display: false,
        type: 'danger',
        message: ''
      },
      isSubmit: false
    });
    this.props.closeModal();
  };

  render() {
    const { error, isSubmit } = this.state;
    const { modal, formLoad = {}, formInit } = this.props;
    const { typeResponsible = [], voucherType = [] } = formLoad;

    return (
      <Modal
        isOpen={modal}
        toggle={this.closeModal}
        className={this.props.className}
        style={formLoad.submit ? { marginTop: 150 } : {}}
      >
        <ModalHeader
          className='title-primary'
          toggle={this.closeModal}
          close={
            <button className='close' onClick={this.closeModal}>
              &times;
            </button>
          }
        >
          <strong>Editar</strong>Informações do Orçamento
        </ModalHeader>
        <Formik
          initialValues={formInit}
          onSubmit={this.saveBudgetInformation}
          validateOnChange={false}
          validationSchema={this.formValidationSchema}
        >
          {({ errors, values, handleChange, submitForm }) => {
            if (!isSubmit && formLoad.submit) {
              this.setState(
                {
                  isSubmit: true
                },
                () => submitForm()
              );
            }

            return (
              <Form>
                <ModalBody>
                  <MessagesComponent display={error.display} type={error.type} message={error.message} />
                  <div className='margin-top-10'>
                    <Row className='margin-top-10'>
                      <Col sm='7'>
                        <label>
                          Data do Orçamento <span> *</span>
                        </label>
                        <div className='customDatePickerWidth'>
                          <DatePicker
                            name={'dataOrcamento'}
                            selected={values.data_orcamento}
                            onChange={(value) => {
                              handleChange({
                                target: {
                                  name: 'data_orcamento',
                                  value
                                }
                              });
                            }}
                            useWeekdaysShort={true}
                            locale='pt-br-griks'
                            className={[
                              'input-secondary',
                              'dateInput',
                              errors.data_orcamento ? 'is-invalid form-control' : ''
                            ]}
                            style={{ width: 300 }}
                            placeholderText=''
                          />
                          <ErrorFieldWithoutBootstrap errors={errors} fieldName={'data_orcamento'} />
                        </div>
                      </Col>
                      <Col sm='5'>
                        <label>
                          Validade (dias) <span> *</span>
                        </label>
                        <ButtonGroup style={{ width: '100%' }}>
                          <input
                            type={'text'}
                            id={'validade'}
                            value={values.validade}
                            onChange={handleChange}
                            size={11}
                            style={{}}
                            className={'form-control'}
                          />
                        </ButtonGroup>
                      </Col>
                    </Row>
                    <Row className='margin-top-10'>
                      <Col sm='12'>
                        <label>Comprovante</label>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm='12'>
                        <Select
                          className={errors.comprovante ? 'is-invalid' : ''}
                          value={values.comprovante}
                          components={{ Option: CustomOptionVoucher, SingleValue }}
                          isSearchable={true}
                          ignoreAccents={true}
                          ignoreCase={true}
                          options={voucherType.map((t) => ({
                            value: t.sigla,
                            label: t.descricao,
                            color: t.color,
                            data: t
                          }))}
                          placeholder={''}
                          styles={selectStyles}
                          onChange={(selectedOption) => {
                            handleChange({
                              target: {
                                name: 'comprovante',
                                value: selectedOption
                              }
                            });
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className='margin-top-10'>
                      <Col sm='6'>
                        <label>CPF Paciente</label>
                        <InputMaskComponent
                          guide={false}
                          onChange={handleChange}
                          value={values.cpf_paciente}
                          name={'cpf_paciente'}
                          id={'cpf_paciente'}
                          mask={maskCPF()}
                        />
                      </Col>
                      <Col sm='6'>
                        <label>RG Paciente</label>
                        <InputMaskComponent
                          guide={false}
                          onChange={handleChange}
                          value={values.rg_paciente}
                          name={'rg_paciente'}
                          id={'rg_paciente'}
                          mask={maskRG()}
                        />
                      </Col>
                    </Row>

                    <hr></hr>

                    <Row className='margin-top-10'>
                      <Col sm='12'>
                        <label>
                          Responsável <span>*</span>
                        </label>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm='12'>
                        <Select
                          className={errors.tipo_responsavel ? 'is-invalid' : ''}
                          value={values.tipo_responsavel}
                          isSearchable={true}
                          ignoreAccents={true}
                          ignoreCase={true}
                          options={typeResponsible.map((t) => ({ value: t.id, label: t.descricao, data: t }))}
                          placeholder={''}
                          styles={reactSelectStyleIsError(errors, 'tipo_responsavel.value', selectStyles)}
                          onChange={(selectedOption) => {
                            handleChange({
                              target: {
                                name: 'tipo_responsavel',
                                value: selectedOption
                              }
                            });
                          }}
                        />
                        <ErrorFieldWithoutBootstrap fieldName={'tipo_responsavel.value'} errors={errors} />
                      </Col>
                    </Row>

                    <IfComponent
                      conditional={
                        t(values, 'tipo_responsavel.data.id').isDefined &&
                        t(values, 'tipo_responsavel.data.id').safeString !== 'N'
                      }
                    >
                      <Row>
                        <Col sm='12'>
                          <label>
                            Nome Responsável<span>*</span>
                          </label>
                          <Input
                            className='input-secondary'
                            type='text'
                            name='nome_responsavel'
                            onChange={handleChange}
                            value={values.nome_responsavel}
                            // invalid={ t(validationError, 'nome_responsavel').isDefined }
                          />
                        </Col>
                        {/*<ErrorFieldWithoutBootstrap errors={ validationError } fieldName={ 'nome_responsavel' }/>*/}
                      </Row>

                      <Row className='margin-top-10'>
                        <Col sm='5'>
                          <label>CPF Responsável</label>
                          <InputMaskComponent
                            guide={false}
                            onChange={handleChange}
                            value={values.cpf}
                            name={'cpf'}
                            id={'cpf'}
                            mask={maskCPF()}
                          />
                        </Col>
                        <Col sm='7'>
                          <label>RG Responsável</label>
                          <InputMaskComponent
                            guide={false}
                            onChange={handleChange}
                            value={values.rg}
                            name={'rg'}
                            id={'rg'}
                            mask={maskRG()}
                          />
                        </Col>
                      </Row>
                    </IfComponent>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Row>
                    <Col sm={{ size: 3, offset: 6 }}>
                      <button className='button-primary'>Salvar</button>
                    </Col>
                    <Col sm='3'>
                      <button className='button-tertiary' type={'button'} onClick={this.closeModal}>
                        Cancelar
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = ({ TreatmentBudgetReducer }) => {
  const { information = {} } = TreatmentBudgetReducer;
  const { formLoad: { budget = {}, typeResponsible = [], voucherType = [] } = {} } = information;
  let typeReponsabilityOption = {};
  let voucherOption = {};

  if (budget.tipo_responsavel_enum) {
    const type = typeResponsible.find((t) => t.id === budget.tipo_responsavel_enum);
    if (type) {
      typeReponsabilityOption = { value: type.id, label: type.descricao, data: type };
    }
  }

  if (budget.comprovante) {
    const voucher = budget.comprovante;
    if (voucher) {
      voucherOption = {
        value: voucher.sigla,
        label: voucher.descricao,
        color: voucher.color,
        data: voucher
      };
    }
  }

  return {
    clearMessage: true,
    ...information,
    formInit: {
      id: budget.id,
      data_orcamento: moment(budget.data_orcamento),
      validade: budget.validade,
      tipo_responsavel: typeReponsabilityOption,
      nome_responsavel: budget.responsavel,
      cpf: budget.cpf,
      rg: budget.rg,
      cpf_paciente: budget.cpfPaciente,
      rg_paciente: budget.rgPaciente,
      comprovante: voucherOption
    }
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(budgetModalInformations({ modal: false })),
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader())
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(TreatmentBudgetInformationsModal);
