import React, { Component } from 'react';
import { IfComponent } from '../../../../components/if.component';
import { t } from 'typy';
import { Col, Label, Row } from 'reactstrap';
import SelectComponent from '../../../../components/SelectComponent';
import InputComponent from '../../../../components/InputComponent';
import { maskCPF } from '../../../../utils/Formatter';
import states from '../../../../utils/states';
import UserAPI from '../../../../service/User.service';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { hideLoader, showLoader } from '../../../../components/actions/Loader.actions';
import ToastUtils from '../../../../utils/ToastUtils';

class EmissionReferenceComponent extends Component {
  state = {
    dentistsOptions: [],
    states_options: []
  };

  componentDidMount() {
    const { referencesByEmission, handleChange, values, errors, showLoader, hideLoader } = this.props;
    showLoader();
    UserAPI.findAllDentistas()
      .then((data) => {
        this.setState({
          states_options: states.map((s) => ({ label: s.sigla, value: s.sigla })),
          dentistsOptions: data.map((d) => ({ label: d.nome, value: d.id }))
        });
        hideLoader();
      })
      .catch((err) => {
        console.error(err);
        this.props.hideLoader();
        ToastUtils.error('Erro ao buscar dentistas, entre em contato com o suporte');
      });
  }

  render() {
    const { referencesByEmission, handleChange, values, errors } = this.props;
    const { dentistsOptions, states_options } = this.state;
    return (
      <div className='margin-top-10' style={{ background: '#f7f9fc', border: '1px solid #cfd5e3' }}>
        <IfComponent conditional={t(referencesByEmission, 'voucher_type.id').safeString === 'NF'}>
          <Row className='margin-top-10 ml-2'>
            <Col sm={12}>
              <strong>Emitente &nbsp;</strong> {t(referencesByEmission, 'razao_social').safeString}
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <strong>CNPJ &nbsp;</strong> {t(referencesByEmission, 'cnpj').safeString}
            </Col>
          </Row>
        </IfComponent>

        <IfComponent conditional={t(referencesByEmission, 'voucher_type.id').safeString === 'R'}>
          <IfComponent conditional={t(referencesByEmission, 'emission_type.id').safeString === 'F'}>
            <Row className='margin-top-10 ml-2' style={{ verticalAlign: 'middle' }}>
              <Col sm={12}>
                <strong>Emitente &nbsp;</strong> {t(referencesByEmission, 'dentist.nome_completo').safeString}
              </Col>
            </Row>
            <Row className='ml-2'>
              <Col sm={4}>
                <strong>CPF &nbsp;</strong> {t(referencesByEmission, 'cpf').safeString}
              </Col>
              <Col sm={4}>
                <strong>CRO &nbsp;</strong> {t(referencesByEmission, 'cro').safeString}/
                {t(referencesByEmission, 'cro_uf').safeString}
              </Col>
            </Row>
          </IfComponent>
          <IfComponent conditional={t(referencesByEmission, 'emission_type.id').safeString === 'V'}>
            <Row>
              <Col sm={12}>
                <Label>
                  Nome <span>*</span>
                </Label>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <SelectComponent
                  name={'dentist'}
                  options={dentistsOptions}
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                />
              </Col>
            </Row>
            <Row className='margin-top-10'>
              <Col sm={5}>
                <Label>
                  CPF <span>*</span>
                </Label>
              </Col>
              <Col sm={4}>
                <Label>
                  CRO <span>*</span>
                </Label>
              </Col>
              <Col sm={3}>
                <Label>
                  UF <span>*</span>
                </Label>
              </Col>
            </Row>
            <Row>
              <Col sm={5}>
                <InputComponent
                  type={'text'}
                  name={'cpf'}
                  handler={handleChange}
                  values={values}
                  errors={errors}
                  mask={maskCPF()}
                />
              </Col>
              <Col sm={4}>
                <InputComponent
                  type={'text'}
                  name={'cro'}
                  handler={handleChange}
                  maxLength={5}
                  values={values}
                  errors={errors}
                />
              </Col>
              <Col sm={3}>
                <SelectComponent
                  name={'cro_uf'}
                  options={states_options}
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                />
              </Col>
            </Row>
          </IfComponent>
        </IfComponent>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader())
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));
export default enhanced(EmissionReferenceComponent);
