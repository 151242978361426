import React, { Component } from 'react';
import { Col, Container, Input, Row } from 'reactstrap';
import logo from '../../assets/img/logo.jpg';
import { formatReal } from '../../utils/Formatter';
import ToastUtils from '../../utils/ToastUtils';
import { ToastContainer } from 'react-toastify';
import ToastCustomMessageComponent from '../../components/ToastCustomMessage.component';
import danger from '../../assets/img/icons/danger1.svg';

import { showLoader, hideLoader } from '../../components/actions/Loader.actions';
import UserService from '../../service/User.service';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import ClientApi from '../../service/ClientApi';

class SubscribeNowPage extends Component {
  state = {
    qtdUsers: 5,
    additionalUsers: 0,
    totalValue: 99,
    planValue: 99,
    paymentType: '',
    paymentTypeCod: '',
    totalYears: 0
  };

  componentDidMount() {
    const { state = {} } = this.props.location;
    const totalYearsFull = this.state.planValue * 12;
    const totalYears = Math.floor(totalYearsFull - totalYearsFull * 0.2);
    this.setState({ totalYears });
    this.props.showLoader();
    ClientApi.findByName(state.email)
      .then(({ data }) => {
        this.setState({
          email: state.email,
          nome: data.nome
        });
        this.props.hideLoader();
      })
      .catch((err) => {
        this.props.hideLoader();
        ToastUtils.error('Não foi possivel iniciar assinatura por favor entre em contato com o suporte');
        window.location = '/';
      });
  }

  setAdditionalUser = (total) => {
    console.log(total);

    if (!total) {
      this.setState({
        additionalUsers: 0,
        totalValue: 99
      });
    } else {
      const totalValue = this.state.planValue + total * 10;
      const totalYearsFull = totalValue * 12;
      const totalYears = Math.floor(totalYearsFull - totalYearsFull * 0.2);

      this.setState({
        additionalUsers: total,
        totalValue,
        totalYears
      });
    }
  };

  checkedPayment = (type, checked) => {
    if (checked) {
      this.setState({ paymentType: type });
    } else {
      this.setState({ paymentType: '' });
    }
  };

  next = () => {
    const { additionalUsers, totalValue, paymentType, totalYears, email, nome } = this.state;

    if (paymentType == '') {
      ToastUtils.trial(<ToastCustomMessageComponent message={'Selecione um tipo de pagamento'} iconImage={danger} />, {
        autoClose: 8000,
        hideProgressBar: true,
        position: 'top-center'
      });
    } else {
      const data = {
        additionalUsers,
        totalValue: paymentType == 'M' ? totalValue : totalYears,
        paymentType,
        email,
        nome
      };

      this.props.history.push('/trial/subscribe/payment', data);
    }
  };

  render() {
    const { totalValue, paymentType, totalYears } = this.state;

    return (
      <Container className={'subscribe-now'}>
        <div>
          <div className={'subscribe-now-content'}>
            <Row>
              <Col sm={12} className={'header'}>
                <img
                  src={logo}
                  alt='logo'
                  style={{
                    width: 125,
                    height: 34
                  }}
                />
                <br />
                <span className={'title-xlarge mb-3'}>
                  O seu Período de Testes terminou. Adquira o seu Plano e continue utilizando o Griks.
                </span>
              </Col>
            </Row>
            <Row className={'plans'}>
              <Col className={'content'}>
                <div className={'content-header'}>
                  <div className={'content-title'}>PLANO BÁSICO</div>
                  <div className={'content-subtitle'}>Plano com todas as Funcionalidades</div>
                </div>
                <div className={'content-details'}>
                  <ul>
                    <li>Todas as Funcionalidades</li>
                    <li>Até 5 Usuários</li>
                  </ul>
                </div>
                <div className={'content-price-box'}>
                  <Col className={'content-price-box-sifrao'}>R$</Col>
                  <Col className={'content-price-box-value'}>99</Col>
                  <Col className={'content-price-box-for'}>/mês</Col>
                </div>
              </Col>
              <Col className={'content'}>
                <div className={'content-header'}>
                  <div className={'content-title'}>USUÁRIOS ADICIONAIS</div>
                  <div className={'content-subtitle'}>Adicione mais Usuários ao seu Plano</div>
                </div>
                <div className={'content-details'}>
                  <ul>
                    <li>
                      Adicionar Usuários{' '}
                      <span className={'content-details-users'}>
                        <span style={{ width: 36, height: 25 }}>
                          <input
                            className='form-control'
                            style={{ fontSize: 14, width: 36, height: 25, float: 'right', marginRight: 110 }}
                            size={3}
                            onChange={(e) => this.setAdditionalUser(e.target.value)}
                          />
                        </span>
                      </span>
                    </li>
                    <li>
                      <span>O valor é o mesmo para qualquer categoria de usuário</span>
                    </li>
                  </ul>
                </div>
                <div className={'content-price-box'}>
                  <Col className={'content-price-box-sifrao'}>R$</Col>
                  <Col className={'content-price-box-value'}>10</Col>
                  <Col className={'content-price-box-for'}>/mês</Col>
                </div>
              </Col>
              <Col className={'content content-last'}>
                <div className={'content-header'}>
                  <div className={'content-title'}>FORMAS DE PAGAMENTO</div>
                  <div className={'content-subtitle'}>Pague com Cartão de Crédito</div>
                </div>
                <div className={'content-details-payment'}>
                  <ul>
                    <li>
                      <Input
                        checked={paymentType === 'M'}
                        type='checkbox'
                        onChange={(e) => this.checkedPayment('M', e.target.checked)}
                      />{' '}
                      Mensal: {formatReal(totalValue)}
                      /mês
                    </li>
                    <li>
                      <Input
                        type='checkbox'
                        checked={paymentType === 'Y'}
                        onChange={(e) => this.checkedPayment('Y', e.target.checked)}
                      />{' '}
                      Anual: {formatReal(totalYears)} (com 20% de desconto)
                    </li>
                  </ul>
                </div>
                <div className={'content-price-box'}>
                  <div className={'content-price-box-subscribe'}>
                    <button
                      style={{ height: 37 }}
                      className={'content-price-box-subscribe-button button-primary'}
                      onClick={this.next}
                    >
                      Assinar
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <ToastContainer />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader())
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(SubscribeNowPage);
