import RestTemplate from '../utils/restTemplate/rest.template';

export default class DomainsAPI {
  static findIndicationTypes = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/domain/indication/types');
  };

  static findWhoIndication = (name, type) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/tipoindicacao/${type}/pescinc/${name}`);
  };

  static findResponsibleTypes = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/domain/responsible/types');
  };

  static findContactsCategories = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/domain/contacts/categories');
  };

  static findAllUsersProfiles = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/domain/users/profiles');
  };

  static findAllModules = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/domain/modules').then((m) => m.data);
  };

  static findAllReasons = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/domain/treatment/reasons').then((m) => m.data);
  };

  /**
   * Buscando todos as queixas
   * @returns {Promise<* | never>}
   */
  static findAllComplaint = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/bibliotecas/tipo/Q/items').then((m) => m.data);
  };

  /**
   * Busca todos as bibliotecas de Arcos
   * @returns {Promise<* | never>}
   */
  static findAllArch = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/bibliotecas/tipo/A/items').then((m) => m.data);
  };

  static findAllTags = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/bibliotecas/tipo/K/items').then((m) => m.data);
  };

  static findAllInterestsOfPatient = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/domain/patient/interests').then((m) => m.data);
  };

  static findAllAngles = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/domain/angles').then((m) => m.data);
  };

  static findAllTeeth = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/domain/tooth').then((m) => m.data);
  };

  static findAllSkeletalClass = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/domain/skeletal-class').then((m) => m.data);
  };

  static findAllSkeletalPatterns = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/domain/skeletal-pattern').then((m) => m.data);
  };

  static findAllEvaluationResult = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/domain/evaluation-result').then((m) => m.data);
  };

  static findAllEvaluationComplexity = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/domain/evaluation-complexity').then((m) => m.data);
  };

  static findAllEvaluationClinicInterest = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/domain/evaluation-clinic-interest').then((m) => m.data);
  };

  static findAllBraces = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/domain/braces').then((m) => m.data);
  };
  static findAllTreatmentStatus = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/domain/treatment-status').then((m) => m.data);
  };

  static findAllOrthoDocs = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/domain/ortho-docs').then((m) => m.data);
  };

  static findAllVoucherType = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/domain/voucher-type').then((m) => m.data);
  };

  static findAllBudgetTypeResponsability = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/domain/budget-type-responsability').then((m) => m.data);
  };

  static findAllTreatmentTypes = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/domain/treatment-type').then((m) => m.data);
  };

  static findAllPrescricoes = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/domain/prescricao').then((m) => m.data);
  };

  static findAllSlot = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/domain/slot').then((m) => m.data);
  };

  static findAllDevices = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/domain/devices').then((m) => m.data);
  };

  static getAllScheduleStatus() {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/domain/schedule/status').then(({ data }) => data);
  }

  static getAllPaymentTypes() {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/domain/cash/payment/types').then(({ data }) => data);
  }

  static getAllEntryTypes() {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/domain/cash/entry/types').then(({ data }) => data);
  }

  static getAllReasonsOfEntryType(entryId) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/domain/cash/entry/types/${entryId}/reasons`).then(({ data }) => data);
  }
}
