import React, { Component } from 'react';
import Loader from '../../components/Loader.component';
import { Col, Container, Input, Label, Row } from 'reactstrap';
import logo from '../../assets/img/logo.jpg';
import ErrorField from '../../components/ErrorField.component';
import ConfirmarEmail from '../../assets/img/ConfirmarEmail.svg';
import Icon from '../../components/Icon.component';
import { t } from 'typy';

class StartTrialSuccessPage extends Component {
  state = {
    email: ''
  };

  componentDidMount() {
    this.setState({
      email: t(this.props, 'location.state.email').safeString
    });
  }

  render() {
    return (
      <Container>
        <Loader />
        <main className='main-container-overflow'>
          <div className='login-container'>
            <div className='login-box'>
              <div className='login-box-right' style={{ paddingLeft: 57, paddingRight: 57 }}>
                <Row>
                  <Col sm='12'>
                    <img className='img-logo-login' src={logo} alt='logo' />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col sm='12' className={'font-bold title-large'}>
                    Confirmando o seu E-mail...
                  </Col>
                </Row>
                <Row>
                  <Col sm='12' className={'text-md font-light'} style={{ padding: 20 }}>
                    Nos próximos instantes, você receberá um e-mail, Clique no link contido <br />
                    na mensagem, para confirmar o seu e-mail e prosseguir.
                  </Col>
                </Row>

                <Row style={{ marginLeft: 50, marginRight: 54, marginTop: 10 }}>
                  <Col sm={8}>
                    <Row>
                      <Col xs='auto'>SEU E-MAIL</Col>
                    </Row>
                    <Row>
                      <Col xs='auto' style={{ fontSize: 16, fontWeight: 600 }}>
                        <strong>{this.state.email}</strong>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={{ size: 2, offset: 2 }}>
                    <Icon image={ConfirmarEmail} style={{ width: 70 }} />
                  </Col>
                </Row>

                <br />
                <Row className={'h-50 align-content-center'} style={{ paddingBottom: 35 }}>
                  <Col sm='12' className={'text-md font-light'} style={{ padding: 20 }}>
                    Caso não encontre o e-mail de confirmação, verifique sua caixa de spam
                    <br /> e confirme se o e-mail fornecido está correto.
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </main>
      </Container>
    );
  }
}

export default StartTrialSuccessPage;
