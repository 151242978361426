import t from 'typy';
import { createSelector } from 'reselect';

const uncheckAppointmentModalSelector = (state) => t(state, 'ScheduleReducer.uncheckAppointmentModal').safeObject;

export const uncheckAppointmentStateToProps = createSelector(
  uncheckAppointmentModalSelector,
  ({ modal, schedule, groups = [], procedures = [] } = {}) => {
    return {
      modal,
      schedule,
      groups: groups.map((g) => ({
        label: g.nome,
        value: g.id,
        data: g
      })),
      procedures: procedures.map((p) => ({
        label: p.nome,
        value: p.id,
        data: p
      }))
    };
  }
);

export const uncheckAppointmentFormInit = createSelector(
  uncheckAppointmentModalSelector,
  ({ schedule = {}, groups = [], procedures = [] } = {}) => {
    const { procedimento = {} } = schedule;
    const { categoria } = procedimento;

    const group = groups.find((g) => g.id === categoria.id) || {};
    const procedure = procedures.find((g) => g.id === procedimento.id) || {};

    return {
      type: 'P',
      group: {
        label: group.nome,
        value: group.id,
        data: group
      },
      procedure: {
        label: procedure.nome,
        value: procedure.id,
        data: procedure
      }
    };
  }
);
