import t from 'typy';
import { createSelector } from 'reselect';

const sendToAppointmentRedialModalSelector = (state) =>
  t(state, 'ScheduleReducer.sendToAppointmentAndRedial').safeObject;

export const sendToAppointmentRedialModalToProps = createSelector(
  sendToAppointmentRedialModalSelector,
  ({ modal, schedule, groups = [], procedures = [] } = {}) => {
    return {
      modal,
      schedule,
      groups: groups.map((g) => ({
        label: g.nome,
        value: g.id,
        data: g
      })),
      procedures: procedures.map((p) => ({
        label: p.nome,
        value: p.id,
        data: p
      })),
      reasons: [
        { label: 'Saiu sem Agendar', value: 'S', data: 'Saiu sem Agendar' },
        { label: 'Fora do Sistema (FDS)', value: 'F', data: 'Fora do Sistema (FDS)' },
        { label: 'Iniciar Tratamento', value: 'I', data: 'Iniciar Tratamento' },
        { label: 'Outros', value: 'O', data: 'Outros' }
      ]
    };
  }
);

export const sendToAppointmentRedialFormInit = createSelector(
  sendToAppointmentRedialModalSelector,
  ({ schedule = {}, groups = [], procedures = [] } = {}) => {
    const { procedimento = {} } = schedule;
    const { categoria } = procedimento;

    const group = groups.find((g) => g.id === categoria.id) || {};
    const procedure = procedures.find((g) => g.id === procedimento.id) || {};

    return {
      group: {
        label: group.nome,
        value: group.id,
        data: group
      },
      procedure: {
        label: procedure.nome,
        value: procedure.id,
        data: procedure
      }
    };
  }
);
