import React, { Component } from 'react';

import { Row, Col, Input } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { RadioButton, RadioGroup } from 'react-radio-buttons';
import { Formik } from 'formik';
import { chairsModal } from '../redux/ScheduleSettings.actions';
import * as Yup from 'yup';
import ErrorField from '../../../../components/ErrorField.component';
import UserAPI from '../../../../service/User.service';
import MessagesComponent from '../../../../components/Messages.component';

class SettingsScheduleChairsModal extends Component {
  state = {
    messageDisplay: false,
    messageDisplayText: ''
  };

  validateSchema = Yup.object().shape({
    nome_cadeira: Yup.string().required('Nome da cadeira obrigatório'),
    // auxiliar_id: Yup.number().required('Auxiliar Obrigatório'),
    status: Yup.string().required('Status Obrigatório')
  });

  salvarCadeira = async (values) => {
    const {
      chairs: { dataForm: { dentist } = {} },
      showLoader,
      hideLoader,
      refreshPage
    } = this.props;
    showLoader();
    try {
      const dataSave = {
        ...values,
        ['status']: values.status.substr(0, 1)
      };
      await UserAPI.saveChairs({ ...dataSave, dentist });
      hideLoader();
      this.closeModal();
      refreshPage();
    } catch (e) {
      hideLoader();
      this.setState({
        messageDisplay: true,
        messageDisplayText: 'Erro ao salvar cadeira'
      });
    }
  };

  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    const { messageDisplay, messageDisplayText } = this.state;
    const {
      chairs: { modal = false, dataForm = {}, initForm = {} }
    } = this.props;
    const { id = null, assistants = [] } = dataForm;

    return (
      <Modal isOpen={modal} toggle={this.closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={this.closeModal}
          close={
            <button className='close' onClick={this.closeModal}>
              &times;
            </button>
          }
        >
          <strong>{initForm.id ? 'Editar' : 'Adicionar'} Cadeira</strong>
        </ModalHeader>
        <Formik
          initialValues={initForm}
          onSubmit={this.salvarCadeira}
          validateOnChange={false}
          validationSchema={this.validateSchema}
        >
          {({ errors, handleSubmit, handleBlur, handleChange, values }) => {
            const status = values && values.status && values.status.substr(0, 1);
            return (
              <form onSubmit={handleSubmit}>
                <ModalBody>
                  <MessagesComponent display={messageDisplay} type={'danger'} message={messageDisplayText} />
                  <div className='margin-top-10'>
                    <Row className='margin-top-10'>
                      <Row className='margin-top-10'>
                        <Col sm='6'>
                          <label>
                            Nome da cadeira<span>*</span>
                          </label>
                          <Input
                            className='input-secondary'
                            type='text'
                            name='nome_cadeira'
                            id='nome_cadeira'
                            value={values.nome_cadeira}
                            invalid={errors.nome_cadeira ? true : false}
                            maxLength={17}
                            onChange={handleChange}
                          ></Input>
                          <ErrorField fieldName={'nome_cadeira'} errors={errors} />
                        </Col>
                        <Col sm='6'>
                          <label>Auxiliar</label>
                          <select
                            name='auxiliar_id'
                            value={values.auxiliar_id}
                            className='form-control'
                            onChange={handleChange}
                          >
                            <option></option>
                            {assistants.map((a) => {
                              return (
                                <option key={a.id} value={a.id}>
                                  {a.nome}
                                </option>
                              );
                            })}
                          </select>
                          <ErrorField fieldName={'auxiliar_id'} errors={errors} />
                        </Col>
                      </Row>
                      <Col sm='12'>
                        <label>
                          Status<span>*</span>
                        </label>
                        <RadioGroup
                          id='status'
                          name='status'
                          className='radio-griks'
                          value={status}
                          onChange={(e) => {
                            handleChange({
                              target: {
                                name: 'status',
                                value: e
                              }
                            });
                          }}
                          horizontal
                        >
                          <RadioButton pointColor={'#522068'} iconSize={20} value='A'>
                            Ativo
                          </RadioButton>
                          <RadioButton pointColor={'#522068'} iconSize={20} value='I'>
                            Inativo
                          </RadioButton>
                        </RadioGroup>
                        <input className={errors.status ? 'is-invalid form-control' : ''} style={{ display: 'none' }} />
                        <ErrorField fieldName={'status'} errors={errors} />
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Row>
                    <Col className='btn-list' sm={{ size: 6, offset: 6 }}>
                      <button className='button-primary' type={'submit'}>
                        Salvar
                      </button>
                      <button className='button-tertiary' type={'button'} onClick={this.closeModal}>
                        Cancelar
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = ({ ScheduleSettingsReducer }) => {
  const { chairs = {} } = ScheduleSettingsReducer;
  return {
    chairs
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: (data) => dispatch(chairsModal({ modal: false }))
  };
};

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(SettingsScheduleChairsModal);
