import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import AffiliatesMenu from './AffiliatesMenu';
import { IfComponent } from '../../components/if.component';
import { FaRegChartBar } from 'react-icons/fa';
import { setHeader } from '../template/redux/Content.actions';
import { useDispatch } from 'react-redux';
import AffiliatesCoupon from './AffiliatesCoupon';
import AffiliatesReports from './AffiliatesReports';

const AffiliatesPage = () => {
  const [menu, setMenu] = useState('r');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setHeader({ title: '' }));
  }, []);

  function _selectMenu(menu) {
    // const { loadPatientReport, loadFinancialReport } = this.props;

    setMenu(menu);

    switch (menu) {
      case 'r':
        /*this.props.loadPatientOverviewReport();*/
        break;
      case 'c':
        /*this.pros.loadFinancialReport();*/
        break;
    }
    return false;
  }

  return (
    <div className='content-box-without-color' style={{ marginTop: '-20px' }}>
      <Container>
        <div>
          <Row>
            <Col sm='6'>
              <Row>
                <Col sm='6'>
                  <div className='icon-header'>
                    <FaRegChartBar color='#979797' size={25} />
                  </div>
                  <div className='title-content'>Afiliados</div>
                </Col>
              </Row>
            </Col>
            <Col sm='6'>
              <AffiliatesMenu selectMenu={_selectMenu} menuSelected={menu} />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <IfComponent conditional={menu === 'r'}>
                <AffiliatesReports />
              </IfComponent>
              <IfComponent conditional={menu === 'c'}>
                <AffiliatesCoupon />
              </IfComponent>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default AffiliatesPage;
