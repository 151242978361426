import t from 'typy';
import { createSelector } from 'reselect';

const scheduleEventModalSelector = (state) =>
  t(state, 'ScheduleReducer.scheduleAppointmentTreatmentSelectModal').safeObject;

export const scheduleAppointmentTreatmentReducerToProps = createSelector(
  scheduleEventModalSelector,
  (scheduleAppointmentTreatmentSelectModal = {}) => {
    const {
      date,
      modal,
      dentist,
      chair,
      start,
      end,
      patient,
      treatments,
      procedure,
      controleRetorno
    } = scheduleAppointmentTreatmentSelectModal;
    return {
      date,
      modal,
      dentist,
      chair,
      start,
      end,
      patient,
      treatments,
      procedure,
      controleRetorno
    };
  }
);
