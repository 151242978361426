import React from 'react';

import PrivateRoute from '../components/PrivateRoute.component';
import ContactsHomePage from '../pages/contacts/ContactsHome.page';

const ContactsRouter = (propsRouter) => {
  return [
    <PrivateRoute
      key='contactsHome'
      exact
      path='/contacts'
      render={(props) => <ContactsHomePage {...props} {...propsRouter} />}
    />
  ];
};

export default ContactsRouter;
