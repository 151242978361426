import React, { Component } from 'react';

import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import plus from '../../../../assets/img/icons/plus.svg';
import Icon from '../../../../components/Icon.component';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { clinicalRecordSummaryModal, clinicalRecordSummaryPreModal } from '../redux/TreatmentClinicalRecord.action';
import Select from 'react-select';
import { formLoad, formInit } from '../selectors/TreatmentSummarySelector';
import { registerModal } from '../../../settings/libraries/redux/SettingsLibraries.actions';
import SettingsLibrariesRegisterModal from '../../../settings/libraries/modal/SettingsLibrariesRegister.modal';
import { hideLoader, showLoader } from '../../../../components/actions/Loader.actions';
import { Form, Formik } from 'formik';
import { reactSelectHandleChange, reactSelectStyleIsError } from '../../../../utils/ReactSelectUtils';
import minus from '../../../../assets/img/icons/minus.svg';
import TreatmentClinicalRecordApi from '../../../../service/TreatmentClinicalRecordApi';
import { t } from 'typy';
import MessagesComponent from '../../../../components/Messages.component';

import * as Yup from 'yup';
import ErrorFieldWithoutBootstrap from '../../../../components/ErrorFieldWithoutBootstrap.component';
import { selectStyles } from '../../../../config/core.config';
import { select } from 'redux-saga/effects';

class TreatmentClincalRecordSummaryModal extends Component {
  state = {
    error: {
      display: false,
      type: 'danger',
      message: ''
    }
  };

  validationFormSchema = Yup.object().shape({
    treatment_type: Yup.object().shape({
      value: Yup.number().required('Tipo de Tratamento (Fase) obrigatório')
    }),
    tempoTratamentoDe: Yup.object().shape({
      value: Yup.number().required('Tempo Tratamento obrigatório')
    })
  });

  closeModal = () => {
    this.props.closeModal();
    this.setState({
      error: {
        display: false,
        type: 'danger',
        message: ''
      }
    });
  };

  saveSummary = async (values) => {
    const { showLoader, hideLoader, updateParent } = this.props;
    showLoader();
    try {
      await TreatmentClinicalRecordApi.summarySave({
        tratamento: values.treatment,
        ficha: values.clinicalRecord,
        id: values.summary,
        tipo_tratamento: t(values, 'treatment_type.data.id').safeObject,
        plano_tratamento: values.treatment_plan,
        tempo_tratamento_de: t(values, 'tempoTratamentoDe.data').safeObject,
        tempo_tratamento_ate: t(values, 'tempoTratamentoAte.data').safeObject,
        atencao: values.atention,
        aparelho: t(values, 'brace.data.id').safeObject,
        nome_comercial: values.comercial_name,
        prescricao: t(values, 'prescricao.data.id').safeObject,
        slot: t(values, 'slot.data.id').safeObject,
        queixas: t(values, 'complaint').isArray ? values.complaint.map((c) => c.data.id) : []
      });
      updateParent();
      this.closeModal();
    } catch (e) {
      console.log(e);
      this.setState({
        error: {
          display: true,
          type: 'danger',
          message: 'Erro ao salvar sumario entre em contato com o suporte'
        }
      });
    }
    hideLoader();
  };

  render() {
    const { modal, formLoad, addNewComplaint, formInit } = this.props;
    const { error } = this.state;
    return (
      <Modal isOpen={modal} toggle={this.closeModal} className={this.props.className} size={'small'}>
        <ModalHeader
          className='title-primary'
          toggle={this.closeModal}
          close={
            <button className='close' onClick={this.closeModal}>
              &times;
            </button>
          }
        >
          <strong>Editar Sumário de Tratamento</strong>
        </ModalHeader>
        <Formik
          initialValues={formInit}
          onSubmit={this.saveSummary}
          validateOnChange={false}
          validationSchema={this.validationFormSchema}
        >
          {({ errors, values, handleChange }) => (
            <Form>
              <ModalBody>
                <MessagesComponent display={error.display} type={error.type} message={error.message} />
                <div className='margin-top-10'>
                  <Row className='margin-top-10'>
                    <Col sm='12'>
                      <label>
                        Tipo de Tratamento (Fase) <span> *</span>
                      </label>
                    </Col>
                    <Col sm={12}>
                      <Select
                        value={values.treatment_type}
                        options={formLoad.treatmentTypesOption}
                        isSearchable={true}
                        ignoreAccents={true}
                        ignoreCase={true}
                        placeholder={''}
                        styles={selectStyles}
                        onChange={(data) => reactSelectHandleChange(data, handleChange, 'treatment_type')}
                      />
                      <ErrorFieldWithoutBootstrap errors={errors} fieldName='treatment_type.value' />
                    </Col>
                  </Row>

                  <Row className='margin-top-10'>
                    <Col sm='12'>
                      <label>Queixa Principal</label>
                    </Col>
                    <Col sm={11}>
                      <Select
                        value={values.complaint}
                        options={formLoad.complaintsOption}
                        isMulti
                        isSearchable={true}
                        ignoreAccents={true}
                        ignoreCase={true}
                        placeholder={''}
                        styles={selectStyles}
                        onChange={(data) => reactSelectHandleChange(data, handleChange, 'complaint')}
                      />
                    </Col>
                    <Col sm='1'>
                      <a
                        href='#'
                        id='plus'
                        onClick={() => {
                          addNewComplaint({
                            modal: true,
                            formData: {
                              library: { id: 1, descricao: 'QUEIXA PRINCIPAL' }
                            }
                          });
                        }}
                      >
                        <Icon className='icon icon-size-small absolute-center ' image={plus} alt='plus' />
                      </a>
                    </Col>
                  </Row>

                  <Row className='margin-top-10'>
                    <Col sm='12'>
                      <label>Plano de Tratamento</label>
                    </Col>
                    <Col sm={12}>
                      <input
                        className='input-secondary'
                        type='text'
                        name='treatment_plan'
                        placeholder={'Exemplo: Classe II com avanço de mandíbula'}
                        value={values.treatment_plan}
                        onChange={handleChange}
                      ></input>
                    </Col>
                  </Row>
                  <Row className={'mt-2'}>
                    <Col sm='12'>
                      <label>
                        Tempo de Tratamento<span>*</span>
                      </label>
                    </Col>
                    <Col sm={4}>
                      <div style={{ width: 'calc(100% + 15px)' }}>
                        <Select
                          options={formLoad.treatmentTimesOption}
                          value={values.tempoTratamentoDe}
                          isSearchable={true}
                          ignoreAccents={true}
                          ignoreCase={true}
                          placeholder={''}
                          styles={reactSelectStyleIsError(errors, 'tempoTratamentoDe', selectStyles)}
                          onChange={(data) => reactSelectHandleChange(data, handleChange, 'tempoTratamentoDe')}
                        />
                      </div>
                    </Col>
                    {values.tempoTratamentoAte && (
                      <Col sm={1}>
                        <label className={'absolute-center'}>a</label>
                      </Col>
                    )}
                    {values.tempoTratamentoAte && (
                      <Col sm={4}>
                        <div style={{ width: 'calc(100% + 15px)', marginLeft: -15 }}>
                          <Select
                            options={formLoad.treatmentTimesOption}
                            value={values.tempoTratamentoAte}
                            isSearchable={true}
                            ignoreAccents={true}
                            ignoreCase={true}
                            placeholder={''}
                            styles={reactSelectStyleIsError(errors, 'tempoTratamentoAte', selectStyles)}
                            onChange={(data) => reactSelectHandleChange(data, handleChange, 'tempoTratamentoAte')}
                          />
                        </div>
                      </Col>
                    )}
                    <Col sm='1'>
                      <label className={'absolute-center ml-3'}>Meses</label>
                    </Col>
                    <Col sm='2' style={{ display: 'flex' }}>
                      {!values.tempoTratamentoAte && (
                        <a
                          style={{ marginTop: -5 }}
                          className='float-left ml-2'
                          href={'#'}
                          onClick={() => {
                            reactSelectHandleChange({}, handleChange, 'tempoTratamentoAte');
                          }}
                          style={{ alignSelf: 'center' }}
                        >
                          <Icon
                            className='icon icon-size-two margin-left-10'
                            image={plus}
                            style={{ width: 22 }}
                            alt='plus'
                          />
                        </a>
                      )}

                      {values.tempoTratamentoAte && (
                        <a
                          href={'#'}
                          className={''}
                          onClick={() => {
                            reactSelectHandleChange(null, handleChange, 'tempoTratamentoAte');
                          }}
                          style={{ alignSelf: 'center' }}
                        >
                          <Icon
                            className='icon icon-size-two absolute-center'
                            image={minus}
                            style={{ width: 24 }}
                            alt='plus'
                          />
                        </a>
                      )}
                    </Col>
                  </Row>

                  {errors.tempoTratamentoDe && errors.tempoTratamentoDe.value ? (
                    <Row>
                      <Col sm={12}>
                        <div style={{ fontSize: 10, color: 'red' }}>{errors.tempoTratamentoDe.value}</div>
                      </Col>
                    </Row>
                  ) : null}

                  <Row className='margin-top-10'>
                    <Col sm='12'>
                      <label>Atenção</label>
                      <textarea
                        style={{ padding: '6px 12px' }}
                        className={'input-secondary'}
                        rows='1'
                        cols='50'
                        value={values.atention}
                        name={'atention'}
                        onChange={handleChange}
                      ></textarea>
                    </Col>
                  </Row>

                  <hr></hr>

                  <Row className='margin-top-10'>
                    <Col sm='12'>
                      <label>Aparelho/Técnica</label>
                    </Col>
                    <Col sm={12}>
                      <Select
                        value={values.brace}
                        options={formLoad.bracesOption}
                        isSearchable={true}
                        ignoreAccents={true}
                        ignoreCase={true}
                        placeholder={''}
                        styles={selectStyles}
                        onChange={(data) => reactSelectHandleChange(data, handleChange, 'brace')}
                      />
                    </Col>
                  </Row>

                  <Row className='margin-top-10'>
                    <Col sm='12'>
                      <label>Nome Comercial</label>
                      <input
                        className='input-secondary'
                        type='text'
                        name='comercial_name'
                        value={values.comercial_name}
                        onChange={handleChange}
                      ></input>
                    </Col>
                  </Row>

                  <Row className='margin-top-10'>
                    <Col sm='6'></Col>
                    <Col sm='6'></Col>
                    <Col sm={6}>
                      <label className={'w-100'}>Prescrição</label>
                      <Select
                        value={values.prescricao}
                        options={formLoad.prescricoesOption}
                        isSearchable={true}
                        ignoreAccents={true}
                        ignoreCase={true}
                        placeholder={''}
                        styles={selectStyles}
                        onChange={(data) => reactSelectHandleChange(data, handleChange, 'prescricao')}
                      />
                    </Col>
                    <Col sm={6}>
                      <label className={'w-100'}>Slot</label>
                      <Select
                        value={values.slot}
                        options={formLoad.slotsOption}
                        isSearchable={true}
                        ignoreAccents={true}
                        ignoreCase={true}
                        placeholder={''}
                        styles={selectStyles}
                        onChange={(data) => reactSelectHandleChange(data, handleChange, 'slot')}
                      />
                    </Col>
                  </Row>
                </div>
              </ModalBody>
              <ModalFooter>
                <Row>
                  <Col className='btn-list' sm={{ size: 6, offset: 6 }}>
                    <button className='button-primary' type='submit'>
                      Salvar
                    </button>
                    <button className='button-tertiary' type='button' onClick={this.closeModal}>
                      Cancelar
                    </button>
                  </Col>
                </Row>
              </ModalFooter>
            </Form>
          )}
        </Formik>

        <SettingsLibrariesRegisterModal
          showLoader={showLoader}
          hideLoader={hideLoader}
          refreshPage={() => {
            this.props.updateForm(this.props);
          }}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { summary } = state.TreatmentClinicalRecordReducer;

  return {
    ...summary,
    formLoad: formLoad(state),
    formInit: formInit(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(clinicalRecordSummaryModal({ modal: false })),
  updateForm: (data) => dispatch(clinicalRecordSummaryPreModal(data)),
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  addNewComplaint: (data) => dispatch(registerModal(data))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(TreatmentClincalRecordSummaryModal);
