import React, { Component } from 'react';
import {
  Alert,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip
} from 'reactstrap';
import plus from '../../../assets/img/icons/plus.svg';
import arrow from '../../../assets/img/icons/arrow.svg';
import arrowUp from '../../../assets/img/icons/arrowup.svg';
import edit from '../../../assets/img/icons/edit.svg';
import excluir from '../../../assets/img/icons/excluir.svg';
import danger from '../../../assets/img/icons/danger.svg';
import broke from '../../../assets/img/icons/broke.svg';
import repair from '../../../assets/img/icons/repair.svg';
import dia_anterior from '../../../assets/img/icons/anterior.svg';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import Icon from '../../../components/Icon.component';
import { IfComponent } from '../../../components/if.component';
import { dateFormat } from '../../../utils/Formatter';
import { t } from 'typy';
import PaginationComponent from '../../../components/Pagination.component';
import {
  clinicalRecordAttendanciePreModal,
  clinicalRecordPreAttendancies
} from './redux/TreatmentClinicalRecord.action';
import menuDots from '../../../assets/img/menuDots.png';
import TreatmentClinicalRecordAttendenceModal from './modals/TreatmentClinicalRecordAttendence.modal';
import ReactTooltip from 'react-tooltip';
import OdontogramaComponent from './components/Odontograma.component';
import moment from 'moment';
import warning from '../../../assets/img/icons/warning.svg';
import ModalMessageConfirm from '../../../components/ModalMessageConfirm.component';
import TreatmentClinicalRecordApi from '../../../service/TreatmentClinicalRecordApi';
import MessagesComponent from '../../../components/Messages.component';
import withSecurity from '../../../config/security/security';
import { PERMISSOES } from '../../../config/security/permissoes';

class TreatmentClinicalRecordAttendenciesPage extends Component {
  state = {
    activePage: 1,
    selectedAttendenceIndex: {},
    deleteAttendenceModal: false,
    attendenceSelectedForDelete: {},
    error: {
      display: false,
      type: 'danger',
      message: ''
    }
  };

  changePage = (page) => {
    const { data = {} } = this.props;

    this.setState({ activePage: page });

    this.props.attendencies({
      data: {
        treatment: data.treatment,
        record: data.record,
        page: page
      }
    });
  };

  toggleAttendence = (index) => {
    const { selectedAttendenceIndex } = this.state;

    const toggle = selectedAttendenceIndex[index] || false;

    this.setState({
      selectedAttendenceIndex: {
        ...selectedAttendenceIndex,
        [index]: !toggle
      }
    });
  };

  closeModalConfirmClosedAttendence = () => this.setState({ deleteAttendenceModal: false });

  openDeleteAttendence = (a) => {
    this.setState({
      attendenceSelectedForDelete: a,
      deleteAttendenceModal: true
    });
  };

  confirmDeleteAttendence = async () => {
    const { data = {} } = this.props;
    const { attendenceSelectedForDelete, activePage } = this.state;
    try {
      await TreatmentClinicalRecordApi.deleteAttendenceRecord({
        attendenceId: attendenceSelectedForDelete.id,
        treatmentId: data.treatment,
        recordId: data.record
      });
    } catch (e) {
      this.setState({
        error: {
          display: true,
          type: 'danger',
          message: 'Erro ao remover atendimento entre em contato com o suporte'
        }
      });

      setTimeout(() => {
        this.setState({
          error: {
            display: false,
            type: 'danger',
            message: ''
          }
        });
      }, 3000);
    }
    this.closeModalConfirmClosedAttendence();
    this.changePage(activePage);
  };

  render() {
    const { data = {}, attendenceModal, formLoad, enabledEdit, security } = this.props;
    const { activePage, selectedAttendenceIndex, deleteAttendenceModal, error } = this.state;
    const { attendencies = {}, treatment, record } = data;
    const { data: attendenciesData = [], meta, totalPages, totalElements } = attendencies;

    const clinicalRecordPermission = PERMISSOES.modules.tratamento_ficha_clinica;
    const hasPermission =
      security.hasPermission(clinicalRecordPermission.id, clinicalRecordPermission.permissoes.editar) ||
      security.hasPermission(clinicalRecordPermission.id, clinicalRecordPermission.permissoes.editar_restricao.id);

    return [
      <Row key={'page'}>
        <Col sm='12'>
          <IfComponent conditional={data.error}>
            <Alert color={'danger'} style={{ padding: 10 }}>
              <span dangerouslySetInnerHTML={{ __html: data.error }} />
            </Alert>
          </IfComponent>
          <MessagesComponent display={error.display} type={error.type} message={error.message} />
          <div className='box-default'>
            <Row>
              <Col sm='12'>
                <h2 className='title-medium-plus text-uppercase text-secondary'>
                  Registro de Atendimento
                  <IfComponent conditional={enabledEdit && hasPermission}>
                    <a
                      id='addAttendence'
                      href={'#'}
                      onClick={() =>
                        attendenceModal({
                          modal: true,
                          treatment,
                          record
                        })
                      }
                    >
                      <Icon className='icon icon-size-small margin-left-10' image={plus} alt='plus' />
                    </a>
                    <UncontrolledTooltip
                      className='tooltip-white text-xsm font-weight-light'
                      placement='top'
                      target='addAttendence'
                    >
                      Adicionar Novo Atendimento
                    </UncontrolledTooltip>
                  </IfComponent>
                </h2>
                <div className='content-overflow-default mt-2'>
                  <Table responsive>
                    <thead style={{ backgroundColor: '#ebecf2' }}>
                      <tr className='row pb-0'>
                        <th style={{ width: 75, paddingRight: 5, paddingLeft: 5 }}>Data</th>
                        <th style={{ width: 75, paddingRight: 5, paddingLeft: 12 }}>Sup</th>
                        <th style={{ width: 75, paddingRight: 5, paddingLeft: 12 }}>Inf</th>
                        <th style={{ width: 'calc(49vw - 315px)', paddingRight: 5, paddingLeft: 12 }}>
                          Anotações do Atendimento
                        </th>
                        <th style={{ width: 'calc(49vw - 315px)', paddingRight: 5, paddingLeft: 12 }}>
                          Previsto para o Próximo Atendimento
                        </th>
                        <th style={{ width: 75, paddingRight: 5, paddingLeft: 12 }}>Sup</th>
                        <th style={{ width: 75, paddingRight: 5, paddingLeft: 12 }}>Inf</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody style={{ maxHeight: 320, overflowY: 'scroll' }}>
                      {attendenciesData.map((a, index) => {
                        const linhas = [];
                        linhas.push(
                          <tr className='row pb-0' key={index}>
                            <td style={{ width: 75, paddingRight: 5, paddingLeft: 5 }}>
                              {a.registrado_em_data_anterior === 'N' ? (
                                dateFormat(a.data_atendimento, 'DD/MM/YYYY')
                              ) : (
                                <span style={{ textDecoration: 'underline' }} id={`dataAnterior_data_${index}`}>
                                  {dateFormat(a.data_atendimento, 'DD/MM/YYYY')}
                                  <UncontrolledTooltip
                                    className='tooltip-white'
                                    placement='right'
                                    target={`dataAnterior_data_${index}`}
                                  >
                                    Registrado em data posterior: {dateFormat(a.registrado_em, 'DD/MM/YYYY H:mm:ss')}
                                    <br />
                                    por {a.usuario_cadastro.nome_cadastro}
                                  </UncontrolledTooltip>
                                </span>
                              )}
                            </td>
                            <td style={{ width: 75, paddingRight: 5, paddingLeft: 12 }}>
                              {t(a, 'arco_superior.nome').safeString}
                            </td>
                            <td style={{ width: 75, paddingRight: 5, paddingLeft: 12 }}>
                              {t(a, 'arco_inferior.nome').safeString}
                            </td>
                            <td style={{ width: 'calc(49vw - 315px)', paddingRight: 5, paddingLeft: 12 }}>
                              {a.anotacao}
                            </td>
                            <td style={{ width: 'calc(49vw - 315px)', paddingRight: 5, paddingLeft: 12 }}>
                              {t(a, 'planejado.proximo_atendimento').safeString}
                            </td>
                            <td style={{ width: 75, paddingRight: 5, paddingLeft: 12 }}>
                              {t(a, 'planejado.arco_superior.nome').safeString}
                            </td>
                            <td style={{ width: 75, paddingRight: 5, paddingLeft: 12 }}>
                              {t(a, 'planejado.arco_inferior.nome').safeString}
                            </td>

                            <td>
                              <a className='float-right' href={'#'} onClick={() => this.toggleAttendence(index)}>
                                <Icon
                                  className='icon icon-size-small'
                                  image={selectedAttendenceIndex[index] ? arrowUp : arrow}
                                  alt='arrow'
                                />
                              </a>
                              <IfComponent conditional={enabledEdit && hasPermission}>
                                {moment().diff(moment(a.data_registro), 'days') < 2 && (
                                  <UncontrolledDropdown className='float-right' direction='left'>
                                    <DropdownToggle>
                                      <img src={menuDots} style={{ width: 20, marginTop: -8 }} />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem
                                        onClick={() => {
                                          attendenceModal({
                                            modal: true,
                                            treatment: data.treatment,
                                            record: data.record,
                                            attendence: a
                                          });
                                        }}
                                      >
                                        <Icon className='icon icon-size-lab' image={edit} alt='edit' />{' '}
                                        <span className='text-sm'>Editar</span>
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() => {
                                          this.openDeleteAttendence(a);
                                        }}
                                      >
                                        <Icon className='icon icon-size-lab icon-alert' image={excluir} alt='excluir' />{' '}
                                        <span className='text-sm'>Excluir</span>
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                )}
                              </IfComponent>
                            </td>
                          </tr>
                        );

                        if (selectedAttendenceIndex[index]) {
                          linhas.push(
                            <tr className='row pb-0'>
                              <td className='col-sm-12 py-1' colSpan={12}>
                                {a.registrado_em_data_anterior === 'S' && (
                                  <span>
                                    <img src={dia_anterior} id={`dataAnterior_${index}`} style={{ width: 22 }} />
                                    <UncontrolledTooltip
                                      className='tooltip-white'
                                      placement='right'
                                      target={`dataAnterior_${index}`}
                                    >
                                      Registrado em data posterior: {dateFormat(a.registrado_em, 'DD/MM/YYYY H:mm:ss')},
                                      por <br /> {a.usuario_cadastro.nome_cadastro}
                                    </UncontrolledTooltip>
                                  </span>
                                )}

                                {t(a, 'procedimento_agenda.codinome').isString && (
                                  <span id={`procedimento_${index}`} className={'receipt-03'}>
                                    {a.procedimento_agenda.codinome}
                                    <UncontrolledTooltip placement='right' target={`procedimento_${index}`}>
                                      {a.procedimento_agenda.nome}
                                    </UncontrolledTooltip>
                                  </span>
                                )}

                                {a.consulta_emergencia === 'S' && (
                                  <span id={`emergencia_${index}`}>
                                    <Icon
                                      className='mr-2 icon-size-small service-registration-icons'
                                      image={danger}
                                      alt='arrow'
                                    />
                                    <UncontrolledTooltip
                                      placement='right'
                                      className={'tooltip-white'}
                                      target={`emergencia_${index}`}
                                    >
                                      Consulta de Emergência
                                    </UncontrolledTooltip>
                                  </span>
                                )}

                                {t(a, 'dentista.nome').isString && (
                                  <span className={'receipt-03'} id={`dentista_${index}`}>
                                    {a.dentista.nome.substr(0, 2).toUpperCase()}
                                    <UncontrolledTooltip
                                      placement='right'
                                      className={'tooltip-white'}
                                      target={`dentista_${index}`}
                                    >
                                      {a.dentista.nome}
                                    </UncontrolledTooltip>
                                  </span>
                                )}

                                {t(a, 'auxiliar.nome').isString && (
                                  <span id={`auxiliar_${index}`} className={'receipt-03'}>
                                    {a.auxiliar.nome.substr(0, 3).toUpperCase()}
                                    <UncontrolledTooltip
                                      placement='right'
                                      className={'tooltip-white'}
                                      target={`auxiliar_${index}`}
                                    >
                                      {a.auxiliar.nome}
                                    </UncontrolledTooltip>
                                  </span>
                                )}

                                {t(a, 'meta.hasBreak').safeBoolean && (
                                  <span style={{ marginLeft: 10, marginRight: 10 }}>
                                    <Icon
                                      className='icon-size-small service-registration-icons'
                                      style={{ fill: 'red', width: 20, height: 20 }}
                                      image={broke}
                                      alt='arrow'
                                      id={`quebra_${index}`}
                                      data-tip
                                      data-for={`quebra_${index}`}
                                    />

                                    <ReactTooltip id={`quebra_${index}`} place='right' type='light' effect='solid'>
                                      <OdontogramaComponent
                                        show={true}
                                        quebras={a.odontograma.filter((o) => o.tipo === 'Q').map((o) => o.numero)}
                                        recolagens={[]}
                                        showRecolagens={false}
                                      />
                                    </ReactTooltip>
                                  </span>
                                )}

                                {t(a, 'meta.hasRecolagem').safeBoolean && (
                                  <span>
                                    <Icon
                                      className='icon-size-small service-registration-icons'
                                      image={repair}
                                      alt='arrow'
                                      id={`recolagem_${index}`}
                                      data-tip
                                      data-for={`recolagem_${index}`}
                                    />

                                    <ReactTooltip id={`recolagem_${index}`} place='right' type='light' effect='solid'>
                                      <OdontogramaComponent
                                        show={true}
                                        quebras={[]}
                                        recolagens={a.odontograma.filter((o) => o.tipo === 'R').map((o) => o.numero)}
                                        showQuebras={false}
                                      />
                                    </ReactTooltip>
                                  </span>
                                )}
                              </td>
                            </tr>
                          );
                        }

                        return linhas;
                      })}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>

            <Row>
              <Col style={{ marginTop: -20 }} sm={{ size: 2, offset: 5 }}>
                <PaginationComponent selectedPage={this.changePage} totalPage={totalPages} activePage={activePage} />
              </Col>
            </Row>

            <Row>
              <Col sm='12'>
                <Row>
                  <Col className='box-secondary text-center' sm='3'>
                    <p className='text-default text-sm'>
                      Atendimentos <strong className='ml-2'>{t(meta, 'atendimentos').safeNumber}</strong>
                    </p>
                  </Col>
                  <Col className='box-secondary text-center' sm='3'>
                    <p className='text-default text-sm'>
                      Emergências/Extras <strong className='ml-2'>{t(meta, 'emergencias').safeNumber}</strong>
                    </p>
                  </Col>
                  <Col className='box-secondary text-center' sm='3'>
                    <p className='text-default text-sm'>
                      Quebras <strong className='ml-2'>{t(meta, 'quebras').safeNumber}</strong>
                    </p>
                  </Col>
                  <Col className='box-secondary text-center' sm='3'>
                    <p className='text-default text-sm'>
                      Recolagens <strong className='ml-2'>{t(meta, 'recolagens').safeNumber}</strong>
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>,
      <TreatmentClinicalRecordAttendenceModal
        key={'attendenceModal'}
        updateParent={() => {
          this.changePage(1);
          this.props.updateParent();
        }}
      />,
      <ModalMessageConfirm
        key={'confirmModal'}
        title={<strong>Confirma Exclusão?</strong>}
        message={
          <span>
            <p>Confirma a Exclusão do Registro de Atendimento ?</p>
          </span>
        }
        openModal={deleteAttendenceModal}
        icon={warning}
        close={this.closeModalConfirmClosedAttendence}
        footer={
          <Row>
            <Col sm='3'>
              <button className='button-tertiary' onClick={this.closeModalConfirmClosedAttendence}>
                Não
              </button>
            </Col>
            <Col sm={{ size: 3, offset: 6 }}>
              <button className='button-primary' onClick={this.confirmDeleteAttendence}>
                Sim
              </button>
            </Col>
          </Row>
        }
      />
    ];
  }
}

const mapStateToProps = (state) => {
  const { TreatmentClinicalRecordReducer: { attendence } = {} } = state;

  return {
    ...attendence
  };
};

const mapDispatchToProps = (dispatch) => ({
  attendencies: (data) => dispatch(clinicalRecordPreAttendancies(data)),
  attendenceModal: (data) => dispatch(clinicalRecordAttendanciePreModal(data))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withSecurity);

export default enhanced(TreatmentClinicalRecordAttendenciesPage);
