import React, { Component } from 'react';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap';

import { selectStyles } from '../../../../../src/config/core.config';
import question from '../../../../assets/img/icons/question.svg';
import Icon from '../../../../components/Icon.component';
import OdontogramaComponent from '../components/Odontograma.component';
import arrowUp from '../../../../assets/img/icons/arrowup.svg';
import arrowDown from '../../../../assets/img/icons/arrow.svg';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Select from 'react-select';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { clinicalRecordAttendancieModal } from '../redux/TreatmentClinicalRecord.action';
import { formLoadSelector } from '../selectors/TreatmentAttendenceSelector';
import { reactSelectHandleChange, reactSelectStyleIsError } from '../../../../utils/ReactSelectUtils';
import { hideLoader, showLoader } from '../../../../components/actions/Loader.actions';
import UserAPI from '../../../../service/User.service';
import { Form, Formik } from 'formik';
import { t } from 'typy';
import TreatmentClinicalRecordApi from '../../../../service/TreatmentClinicalRecordApi';
import { formatDate } from '../../../../utils/DateUtils';
import MessagesComponent from '../../../../components/Messages.component';
import * as Yup from 'yup';
import ErrorFieldWithoutBootstrap from '../../../../components/ErrorFieldWithoutBootstrap.component';

class TreatmentClinicalRecordAttendenceModal extends Component {
  state = {
    modal: true,
    odontogramaOpen: false,
    assistences: [],
    error: {
      display: false,
      type: 'danger',
      message: ''
    }
  };

  formValidation = Yup.object().shape({
    attendence_date: Yup.string().required('Data Atendimento Obrigatória').nullable(),
    dentist: Yup.object().shape({
      value: Yup.number().required('Doutor(a) Obrigatório')
    })
  });

  closeModal = () => {
    const { closeModal } = this.props;
    closeModal();
    this.setState({
      odontogramaOpen: false,
      error: {
        display: false,
        type: 'danger',
        message: ''
      }
    });
  };

  findAssistences = async (dentist) => {
    const { showLoader, hideLoader } = this.props;

    showLoader();

    const assistences = await UserAPI.getAssistants(dentist.value);
    const assistencesOptions = [{ value: null, label: '', data: {} }];
    assistencesOptions.push(...assistences.data.map((t) => ({ value: t.id, label: t.nome, data: t })));

    this.setState({
      assistences: assistencesOptions
    });

    hideLoader();
  };

  saveAttendence = async (values) => {
    console.log(values);

    const { id, treatment, record, showLoader, hideLoader, updateParent } = this.props;

    showLoader();
    const now = moment();

    const odontograma = [];

    if (t(values, 'odontograma.q').isArray) {
      odontograma.push(
        ...values.odontograma.q.map((d) => ({
          numero: d,
          tipo: 'Q'
        }))
      );
    }

    if (t(values, 'odontograma.r').isArray) {
      odontograma.push(
        ...values.odontograma.r.map((d) => ({
          numero: d,
          tipo: 'R'
        }))
      );
    }

    try {
      await TreatmentClinicalRecordApi.saveAttendenceRecord({
        attendenceId: values.id,
        treatmentId: treatment.id,
        recordId: record,
        data_atendimento: formatDate(values.attendence_date, 'YYYY-MM-DDTHH:mm:ss'),
        consulta_emergencia: values.emergencia,
        arco_superior: t(values, 'arco_superior.data.id').safeObject,
        arco_inferior: t(values, 'arco_inferior.data.id').safeObject,
        anotacao: values.anotacao,
        dentista: t(values, 'dentist.data.id').safeObject,
        auxiliar: t(values, 'assistence.data.id').safeObject,
        registrado_em_data_anterior: now.diff(values.attendence_date, 'days') > 1 ? 'S' : 'N',
        planejado: {
          id: values.planejado_id,
          arco_superior: t(values, 'planejado_arco_superior.data.id').safeObject,
          arco_inferior: t(values, 'planejado_arco_inferior.data.id').safeObject,
          proximo_atendimento: values.planejado_proximo_atendimento
        },
        odontograma: odontograma
      });

      updateParent();
      this.closeModal();
    } catch (e) {
      console.error(e);

      this.setState({
        error: {
          display: true,
          type: 'danger',
          message: 'Erro ao salvar atendimento entre em contato com o suporte'
        }
      });
    }
    hideLoader();
  };

  shouldComponentUpdate(nextProps, nextState) {
    console.log(nextProps);
    if (
      t(nextProps, 'modal').safeBoolean &&
      t(this.props, 'formLoad.formInit.dentist.value').safeNumber !==
        t(nextProps, 'formLoad.formInit.dentist.value').safeNumber
    ) {
      const dentist = t(nextProps, 'formLoad.formInit.dentist').safeObject;
      if (dentist) {
        this.findAssistences(dentist);
      }
    }
    return true;
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      t(nextProps, 'modal').safeBoolean &&
      t(this.props, 'formLoad.formInit.id').safeNumber !== t(nextProps, 'formLoad.formInit.id').safeNumber
    ) {
      const dentist = t(nextProps, 'formLoad.formInit.dentist').safeObject;
      if (dentist) {
        this.findAssistences(dentist);
      }
    }
  }

  render() {
    const { odontogramaOpen, assistences, error } = this.state;
    const { modal, formLoad } = this.props;

    return (
      <Modal isOpen={modal} toggle={this.closeModal} className={this.props.className} size={'default'}>
        <ModalHeader
          className='title-primary'
          toggle={this.closeModal}
          close={
            <button className='close' onClick={this.closeModal}>
              &times;
            </button>
          }
        >
          Registrar Atendimento
        </ModalHeader>
        <Formik
          validationSchema={this.formValidation}
          validateOnChange={false}
          initialValues={formLoad.formInit}
          onSubmit={this.saveAttendence}
        >
          {({ values, errors, handleChange }) => {
            return (
              <Form>
                <ModalBody className={'pt-0'}>
                  <MessagesComponent display={error.display} type={error.type} message={error.message} />
                  <div className=''>
                    <h1 className='text-md-plus font-bold text-secondary text-uppercase'>Este Atendimento</h1>
                    <Row className='mt-3'>
                      <Col sm='12' className={'mb-0 pb-0'}>
                        <label>Data</label>
                        {t(values, 'edit').safeBoolean ? (
                          <span>
                            &nbsp;
                            <a href='#' id='questionDataAtendimento'>
                              <Icon className='icon icon-size-smaller' image={question} alt='questionDataAtendimento' />
                            </a>
                            <UncontrolledTooltip
                              className='tooltip-white'
                              placement='bottom'
                              target='questionDataAtendimento'
                            >
                              Não é permitido alteração da data de atendimento, caso seja necessário por favor exclua o
                              atendimento e cadastre-o novamente
                            </UncontrolledTooltip>
                          </span>
                        ) : null}
                      </Col>
                      <Col className={'force-input-full-width'} sm={6}>
                        <DatePicker
                          disabled={t(values, 'edit').safeBoolean}
                          selected={values.attendence_date}
                          onChange={(value) => {
                            handleChange({
                              target: {
                                name: 'attendence_date',
                                value: !value ? moment() : value
                              }
                            });
                          }}
                          maxDate={new Date()}
                          locale={'pt-br-griks'}
                          className={
                            errors.attendence_date ? 'input-secondary is-invalid form-control' : 'form-control'
                          }
                          placeholderText=''
                          useWeekdaysShort={true}
                        />
                        <ErrorFieldWithoutBootstrap errors={errors} fieldName={'attendence_date'} />
                      </Col>
                      <Col className={'pt-1'} sm={{ size: 6 }} style={{ alignItems: 'flex-start', display: 'flex' }}>
                        <input
                          style={{ marginTop: 2.5 }}
                          type='checkbox'
                          className='form-checkbox'
                          id='check-one'
                          checked={values.emergencia === 'S'}
                          onClick={() => {
                            handleChange({
                              target: {
                                name: 'emergencia',
                                value: values.emergencia === 'S' ? 'N' : 'S'
                              }
                            });
                          }}
                        />
                        <label className='form-checkout-label pt-1' htmlFor='check-one'>
                          Emergência/Extra
                        </label>
                        <a href='#' id='question'>
                          <Icon className='icon icon-size-smaller ml-2 pt-1' image={question} alt='question' />
                        </a>
                        <UncontrolledTooltip className={'tooltip-white'} placement='bottom' target='question'>
                          Emergência / Extras são consultas não programadas, agendadas por necessidade do paciente:
                          quebras e outros imprevistos
                        </UncontrolledTooltip>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm='6'>
                        <label className={'w-100'}>Superior</label>
                        <Select
                          styles={selectStyles}
                          options={formLoad.archOption}
                          value={values.arco_superior}
                          isSearchable={true}
                          ignoreAccents={true}
                          ignoreCase={true}
                          placeholder={''}
                          onChange={(data) => {
                            reactSelectHandleChange(data, handleChange, 'arco_superior');
                            reactSelectHandleChange(data, handleChange, 'planejado_arco_superior');
                          }}
                        />
                      </Col>
                      <Col sm='6'>
                        <label className={'w-100'}>Inferior</label>
                        <Select
                          styles={selectStyles}
                          options={formLoad.archOption}
                          value={values.arco_inferior}
                          isSearchable={true}
                          ignoreAccents={true}
                          ignoreCase={true}
                          placeholder={''}
                          onChange={(data) => {
                            reactSelectHandleChange(data, handleChange, 'arco_inferior');
                            reactSelectHandleChange(data, handleChange, 'planejado_arco_inferior');
                          }}
                        />
                      </Col>
                    </Row>

                    <Row className='margin-top-10'>
                      <Col sm='12'>
                        <label>Anotações do Atendimento</label>
                        <textarea
                          className={'form-control'}
                          rows='2'
                          cols='50'
                          onChange={handleChange}
                          name={'anotacao'}
                          value={values.anotacao}
                        ></textarea>
                      </Col>
                    </Row>
                    <Row className='margin-top-10'>
                      <Col sm='6'>
                        <label className={'w-100'}>
                          Doutor(a)<span>*</span>
                        </label>
                        <Select
                          options={formLoad.dentistsOption}
                          value={values.dentist}
                          isSearchable={true}
                          ignoreAccents={true}
                          ignoreCase={true}
                          placeholder={''}
                          styles={reactSelectStyleIsError(errors, 'dentist.value', selectStyles)}
                          onChange={(data) => {
                            reactSelectHandleChange(data, handleChange, 'dentist');
                            reactSelectHandleChange({}, handleChange, 'assistence');
                            this.findAssistences(data);
                          }}
                        />
                        <ErrorFieldWithoutBootstrap errors={errors} fieldName={'dentist.value'} />
                      </Col>
                      <Col sm='6'>
                        <label className={'w-100'}>Auxiliar</label>
                        <Select
                          styles={selectStyles}
                          options={assistences}
                          value={values.assistence}
                          isSearchable={true}
                          ignoreAccents={true}
                          ignoreCase={true}
                          placeholder={''}
                          onChange={(data) => reactSelectHandleChange(data, handleChange, 'assistence')}
                        />
                      </Col>
                    </Row>
                    <h2 className='title-medium margin-top-10 text-secondary'>
                      Quebras e Recolagens
                      <a href={'#'} onClick={() => this.setState({ odontogramaOpen: !odontogramaOpen })}>
                        <Icon
                          className='icon icon-size-small margin-left-10'
                          image={odontogramaOpen ? arrowUp : arrowDown}
                          alt='arrow'
                        />
                      </a>
                    </h2>
                    <Row className='mt-2'>
                      <Col sm='12'>
                        <OdontogramaComponent
                          show={odontogramaOpen}
                          daysSelected={(type, days) => {
                            handleChange({
                              target: {
                                name: `odontograma[${type}]`,
                                value: days
                              }
                            });
                          }}
                          quebras={t(values, 'odontograma.q').isArray ? values.odontograma.q : []}
                          recolagens={t(values, 'odontograma.r').isArray ? values.odontograma.r : []}
                        />
                      </Col>
                    </Row>
                    <hr className={'mb-3 mt-0'} />
                    <h2 className='text-md-plus font-bold text-secondary text-uppercase'>Próximo Atendimento</h2>
                    <Row className='margin-top-10'>
                      <Col sm='12'>
                        <label>Próximo Atendimento</label>
                        <textarea
                          rows='2'
                          cols='50'
                          className={'form-control'}
                          name={'planejado_proximo_atendimento'}
                          value={values.planejado_proximo_atendimento}
                          onChange={handleChange}
                        ></textarea>
                      </Col>
                    </Row>
                    <Row className='margin-top-10'>
                      <Col sm='6'>
                        <label className={'w-100'}>Superior</label>
                        <Select
                          options={formLoad.archOption}
                          value={values.planejado_arco_superior}
                          isSearchable={true}
                          ignoreAccents={true}
                          ignoreCase={true}
                          placeholder={''}
                          styles={selectStyles}
                          onChange={(data) => reactSelectHandleChange(data, handleChange, 'planejado_arco_superior')}
                        />
                      </Col>
                      <Col sm='6'>
                        <label className={'w-100'}>Inferior</label>
                        <Select
                          options={formLoad.archOption}
                          value={values.planejado_arco_inferior}
                          isSearchable={true}
                          ignoreAccents={true}
                          ignoreCase={true}
                          placeholder={''}
                          styles={selectStyles}
                          onChange={(data) => reactSelectHandleChange(data, handleChange, 'planejado_arco_inferior')}
                        />
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Row>
                    <Col className='btn-list' sm={{ size: 6, offset: 6 }}>
                      <button className='button-primary'>Salvar</button>
                      <button className='button-tertiary' onClick={this.closeModal} type={'button'}>
                        Cancelar
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    TreatmentClinicalRecordReducer: { attendenceModal = {} }
  } = state;

  return {
    ...attendenceModal,
    formLoad: formLoadSelector(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(clinicalRecordAttendancieModal({ modal: false })),
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader())
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(TreatmentClinicalRecordAttendenceModal);
