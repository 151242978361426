import React from 'react';
import { Col, Row } from 'reactstrap';

const CashHomeMenu = (props) => {
  return (
    <Row style={{ marginTop: -20 }}>
      <Col sm='12'>
        <nav className='nav-inline border-bottom-0'>
          <ul>
            <li className='active'>
              <a href=''>Movimento de Caixa</a>
            </li>
            {/*<li>*/}
            {/*  <a href="">Recibos Emitidos</a>*/}
            {/*</li>*/}
          </ul>
        </nav>
        <hr style={{ marginTop: -20, marginBottom: 5 }} />
      </Col>
    </Row>
  );
};

export default CashHomeMenu;
