import { FILL_MODAL_ADD_PATIENT, REFRESH_LIST, SHOW_PATIENT_EDIT_MODAL } from './patient.actions';
import { SHOW_HIDE_MODAL_REGISTER } from './patient.actions';

const initialState = {};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FILL_MODAL_ADD_PATIENT: {
      return {
        form: { ...payload }
      };
    }
    case SHOW_HIDE_MODAL_REGISTER: {
      return {
        displayModalRegister: payload.display
      };
    }
    case REFRESH_LIST: {
      return {
        refreshList: true
      };
    }

    case SHOW_PATIENT_EDIT_MODAL: {
      return {
        ...payload
      };
    }

    default:
      return state;
  }
};
