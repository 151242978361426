import { call, put, takeEvery } from '@redux-saga/core/effects';
import { loadDentists } from '../../../../shared/dentists_store';
import { hideLoader, showLoader } from '../../../../components/actions/Loader.actions';
import ToastUtils from '../../../../utils/ToastUtils';
import {
  infinitScroll,
  sendLoadedPagePatientOverviewReport,
  sendToResultPatientReportOverview
} from './PatientReportStore';
import DomainsAPI from '../../../../service/DomainsAPI';
import PacienteAPI from '../../../../service/PacienteAPI';
import t from 'typy';

export const Sagas = {
  LOAD_PATIENT_REPORT_PAGE: 'report/patient/store/LOAD_PATIENT_REPORT_PAGE',
  FIND_PATIENT_REPORT_OVERVIEW_FILTER: 'report/patient/store/FIND_PATIENT_REPORT_OVERVIEW_FILTER'
};

export function* watchPatientReport() {
  yield takeEvery(Sagas.LOAD_PATIENT_REPORT_PAGE, loadPageSaga);
  yield takeEvery(Sagas.FIND_PATIENT_REPORT_OVERVIEW_FILTER, findPatientReportOverview);
}

function* loadPageSaga({ payload }) {
  try {
    yield put(loadDentists());
    const status = yield call(DomainsAPI.findAllTreatmentStatus);
    yield put(sendLoadedPagePatientOverviewReport({ status }));
    yield put(hideLoader());
  } catch (e) {
    yield put(hideLoader());
    console.error(e);
    ToastUtils.error('Erro ao buscar dados do relatorio de paciente');
  }
}

function* findPatientReportOverview({ payload }) {
  try {
    if (payload.showLoader) {
      yield put(showLoader());
    }

    const patients = yield call(
      PacienteAPI.findPatientReportOverview,
      payload.orderBy,
      payload.dentistas,
      payload.situacoes,
      payload.page,
      15
    );
    const patientsResult = patients.data;
    if (t(payload.patients).isArray) {
      patientsResult.data = [...payload.patients, ...patientsResult.data];
    }

    yield put(sendToResultPatientReportOverview({ patients: patientsResult }));
    yield put(hideLoader());
    yield put(infinitScroll({ loaderMoreResult: false }));
  } catch (e) {
    yield put(hideLoader());
    console.error(e);
    ToastUtils.error('Erro ao buscar dados do relatorio de paciente');
  }
}
