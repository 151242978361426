import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

const AffiliatesMenu = (props) => {
  const { menuSelected, selectMenu } = props;
  return (
    <Row>
      <Col sm={11}>
        <div className='report-menu float-right'>
          <span className='report-sub'>
            <a
              style={{ marginRight: '20px' }}
              className={menuSelected === 'p' ? 'report-sub active' : 'report-sub'}
              href={'javascript:void'}
              onClick={() => selectMenu('r')}
            >
              {' '}
              Relatórios{' '}
            </a>
          </span>
          <span className='report-sub'>
            <a
              style={{ marginRight: '20px' }}
              className={menuSelected === 'f' ? 'report-sub active' : 'report-sub'}
              href={'javascript:void'}
              onClick={() => selectMenu('c')}
            >
              {' '}
              Cupons{' '}
            </a>
          </span>
        </div>
      </Col>
    </Row>
  );
};

export default AffiliatesMenu;
