import RestTemplate from '../utils/restTemplate/rest.template';

class TreatmentBudgetApi {
  static getBudgetByTreatment = (id) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/tratamento/${id}/orcamento`);
  };

  static createBudget = ({ tratamento, budgetDate }) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post(`/tratamento/${tratamento}/orcamento/create`, {
      data_orcamento: budgetDate
    });
  };

  static saveBudgetInformation({
    tratamento,
    id,
    dataOrcamento,
    validade,
    tipoResponsavel,
    nomeResponsavel,
    cpf,
    rg,
    comprovante
  }) {
    if (tipoResponsavel === 'N') {
      nomeResponsavel = '';
    }

    const restTemplate = new RestTemplate(true);
    return restTemplate.post(`/tratamento/${tratamento}/orcamento/`, {
      id,
      dataOrcamento,
      validade,
      tipoResponsavel,
      nomeResponsavel,
      cpf,
      rg,
      comprovante
    });
  }

  static saveTreatmentPlan = ({
    orcamento,
    id,
    tipo_tratamento,
    plano_tratamento,
    tempo_tratamento_de,
    tempo_tratamento_ate,
    aparelho,
    nome_comercial
  }) => {
    const request = {
      id,
      tipo_tratamento,
      plano_tratamento,
      tempo_tratamento_de,
      aparelho,
      nome_comercial
    };

    if (tempo_tratamento_ate) {
      request.tempo_tratamento_ate = tempo_tratamento_ate;
    }

    const restTemplate = new RestTemplate(true);

    return restTemplate.post(`/tratamento/orcamento/${orcamento}/planos-tratamentos`, request);
  };

  static savePaymentModality = ({ orcamento, modalidade }) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.put(`/tratamento/orcamento/${orcamento}/modalidade`, {
      modalidade
    });
  };

  static savePaymentMantenance = ({
    idPagamento,
    orcamento,
    id_mensalidade,
    tipo_aparelho,
    valor_entrada,
    valor_total_entrada,
    valor_desconto,
    parcelado_em,
    dia_vencimento,
    valor_mensalidade,
    valor_desconto_mensalidade,
    quantidade_estimada,
    dia_vencimento_mensalidade,
    valor_parcela_entrada
  }) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post(`/tratamento/orcamento/${orcamento}/pagamento/mensalidade`, {
      id: idPagamento,
      mensalidade: {
        id: id_mensalidade,
        tipo_aparelho,
        valor_entrada: valor_entrada || 0,
        valor_total_entrada,
        valor_desconto,
        parcelado_em,
        dia_vencimento,
        valor_mensalidade,
        valor_desconto_mensalidade,
        quantidade_estimada,
        dia_vencimento_mensalidade,
        valor_parcela_entrada
      }
    });
  };

  static savePaymentClosedPrice = ({
    budgetId,
    paymentId,
    closedPriceId,
    valor_total,
    valor_desconto,
    parcelado_em,
    dia_vencimento,
    valor_entrada,
    valor_parcela,
    procedimentos
  }) => {
    const restTemplate = new RestTemplate(true);

    const request = {
      id: paymentId,
      preco_fechado: {
        id: closedPriceId,
        valor_total: parseFloat(valor_total),
        valor_desconto: parseFloat(valor_desconto),
        parcelado_em,
        dia_vencimento,
        valor_entrada: parseFloat(valor_entrada),
        valor_parcela: parseFloat(valor_parcela),
        procedimentos: procedimentos.map((p) => ({
          procedimento: p.procedimento,
          valor_tratamento: parseFloat(p.valor_tratamento)
        }))
      }
    };

    return restTemplate.post(`/tratamento/orcamento/${budgetId}/pagamento/preco-fechado`, request);
  };

  static saveOtherNotes = ({ budgetId, notes }) => {
    const restTemplate = new RestTemplate(true);

    const request = {
      anotacao: notes
    };

    return restTemplate.put(`/tratamento/orcamento/${budgetId}/outras-anotacoes`, request);
  };

  static budgetAprove = (orcamento) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post(`/tratamento/orcamento/${orcamento}/aprovar`);
  };

  static budgetDelete = (orcamento) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.delete(`/tratamento/orcamento/${orcamento}`);
  };
}

export default TreatmentBudgetApi;
