import React, { Component } from 'react';

import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { contactsDetail, contactsEdit } from '../redux/contacts.actions';
import { dateFormat, phoneFormat } from '../../../utils/Formatter';
import noavatar from '../../../assets/img/noavatar.png';
import whats_color from '../../../assets/img/icons/whatsapp_color.svg';

import * as moment from 'moment';
import { IfComponent } from '../../../components/if.component';
import { PERMISSOES } from '../../../config/security/permissoes';
import withSecurity from '../../../config/security/security';

class ContactsDetailModal extends Component {
  state = {};

  render() {
    const { openModal, closeModal, data, security } = this.props;

    const modulePermission = PERMISSOES.modules.contatos;

    return (
      <Modal id='contactDetail' isOpen={openModal} toggle={closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={closeModal}
          close={
            <button className='close' onClick={closeModal}>
              &times;
            </button>
          }
        >
          {data.titulo && data.titulo} {data.nome}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm='12 margin-top-10'>
              <div className='modal-img-user'>
                <div className='absolute-center p-2'>
                  <img className='img-circle mb-3' src={data.foto || noavatar} alt='usuario' />
                </div>
              </div>
            </Col>
          </Row>
          <div className='mt-4'>
            <Row>
              <Col sm='12' className='text-center'>
                <span className='title-medium'>{data.nome}</span>
              </Col>
              <Col sm='12 margin-top-10'>
                <span className='ml-4'>
                  {data.categoria && data.categoria.description}
                  {dateFormat(data.nascimento, 'DD/MM/YYYY')}&ensp; - &ensp;{moment().diff(data.nascimento, 'years')}{' '}
                  {''} anos
                </span>
              </Col>
            </Row>
          </div>

          <hr />

          <Row>
            <Col className='mt-3' sm='12'>
              {data.telefone_one && (
                <p>
                  <span className={'ml-4'}>
                    <strong>Tel 1</strong>
                  </span>
                  <span>{phoneFormat(data.telefone_one, 'BR')}</span>{' '}
                  <span className={'ml-1'}>{data.telefone_one_nome} </span>
                  <IfComponent conditional={data.telefone_one_tipo === 'whatsapp'}>
                    <img className={'ml-1'} src={whats_color} style={{ width: 20 }} />
                  </IfComponent>
                </p>
              )}
              {data.telefone_two && (
                <p>
                  <span className={'ml-4'}>
                    <strong>Tel 2</strong>
                  </span>
                  <span>{phoneFormat(data.telefone_two, 'BR')}</span>{' '}
                  <span className={'ml-1'}>{data.telefone_two_nome} </span>
                  <IfComponent conditional={data.telefone_two_tipo === 'whatsapp'}>
                    <img className={'ml-1'} src={whats_color} style={{ width: 20 }} />
                  </IfComponent>
                </p>
              )}

              {data.email && (
                <p>
                  <strong>E-mail</strong> {data.email}
                </p>
              )}
              {data.anotacoes && (
                <p className='ml-4 text-justify'>
                  <strong>Anotações</strong> {data.anotacoes}
                </p>
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col sm={{ size: 3, offset: 9 }}>
              <IfComponent
                conditional={
                  security.hasPermission(modulePermission.id, modulePermission.permissoes.editar) ||
                  security.hasPermission(modulePermission.id, modulePermission.permissoes.editar_restricao.id)
                }
              >
                <button
                  className='button-tertiary'
                  onClick={() => {
                    this.props.closeModal();
                    this.props.contactsEdit(data);
                  }}
                >
                  Editar
                </button>
              </IfComponent>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = ({ ContactsReducer = {} }) => {
  const { openModal = false, data = {} } = ContactsReducer.contactDetail || {};
  return {
    openModal,
    data
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(contactsDetail({ open: false, contact: {} })),
    contactsEdit: (data) => dispatch(contactsEdit(data))
  };
};

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withSecurity);

export default enhanced(ContactsDetailModal);
