import React, { Component } from 'react';
import {
  Row,
  Col,
  Input,
  UncontrolledTooltip,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle
} from 'reactstrap';
import { getStates } from '../../../utils/StatesUtil';

import { t } from 'typy';
import MaskedInput from 'react-text-mask';

import MultiSelect from '@khanacademy/react-multi-select';
import './MultiSelect.css';
import { RadioGroup, RadioButton } from 'react-radio-buttons';

import Select from 'react-select';
import { reactSelectHandleChange, reactSelectStyleIsError } from '../../../utils/ReactSelectUtils';
import { selectStyles } from '../../../../src/config/core.config';

import { maskCPF, maskPhoneDefine, maskRG } from '../../../utils/Formatter';
import InputMaskComponent from '../../../components/InputMask.component';
import { ClinicStorageUtils } from '../../../utils/localStorage/ClinicStorageUtils';
import Icon from '../../../components/Icon.component';

import { IfComponent } from '../../../components/if.component';
import { IoIosHelpCircle } from 'react-icons/io';
import { IoLogoWhatsapp } from 'react-icons/io';

class UserSettingsFormRegisterCadastroForm extends Component {
  state = {
    modal: true,
    checked: false,
    dentistasAcesso: [],
    defaultData: {
      ddd: null
    },
    expandCat: false
  };

  componentDidMount() {
    let ddd = ClinicStorageUtils.getDDD();
    this.setState({
      defaultData: {
        ...this.state.defaultData,
        ddd
      }
    });
  }

  shouldComponentUpdate = (nextProps, nextState, nextContext) => {
    const { values = {} } = nextProps;

    if (values.dentistasAcesso && this.state.dentistasAcesso !== values.dentistasAcesso) {
      this.setState({
        dentistasAcesso: values.dentistasAcesso
      });
    }
    return true;
  };

  selectedDentistas = (selected) => {
    this.setState({
      dentistasAcesso: selected
    });

    this.props.handleChange({
      target: {
        name: 'dentista_acessos',
        value: selected.map((d) => {
          return {
            id: d
          };
        })
      }
    });
  };

  render() {
    const { profiles, errors, dentistas, display, values = {}, handleChange } = this.props;
    const { defaultData, isMaster } = this.state;

    if (!values.status) {
      handleChange({
        target: {
          name: 'status',
          value: 'S'
        }
      });
    }

    return (
      <div style={{ display: display ? 'block' : 'none' }}>
        <Row>
          <Col sm='12'>
            <label>
              Nome<span>*</span>
            </label>
            <input
              className='input-secondary'
              type='text'
              name='nome'
              value={values.nome}
              onChange={handleChange}
            ></input>
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col sm='8'>
            <label className='w-100 mb-0 pb-0 position-relative' style={{ zIndex: 9999999 }}>
              Categoria/Perfil<span>*</span>
              <a
                id={'cateTip'}
                style={{ marginLeft: -10 }}
                className='ml-2'
                onClick={() => {
                  this.setState({ expandCat: !this.state.expandCat });
                }}
              >
                <IoIosHelpCircle size={16} color='#616e88' style={{ cursor: 'help' }} alt='help' />
              </a>
              <UncontrolledTooltip className={'tooltip-white'} placement='top' target='cateTip'>
                Clique para saber mais!
              </UncontrolledTooltip>
            </label>

            <IfComponent conditional={values.perfil_id === 1}>: Dentista Administrador</IfComponent>
            <IfComponent conditional={values.perfil_id !== 1}>
              <Select
                options={profiles
                  .filter((p) => {
                    if (isMaster && p.id === 1) {
                      return false;
                    } else {
                      return true;
                    }
                  })
                  .map((p) => {
                    return { value: p.id, label: p.nome };
                  })}
                defaultValue={profiles
                  .filter((p) => {
                    if (isMaster && p.id === 1) {
                      return false;
                    } else {
                      return true;
                    }
                  })
                  .map((p) => {
                    if (values.perfil_id === p.id) {
                      return { value: p.id, label: p.nome };
                    }
                  })}
                ignoreCase={true}
                placeholder={''}
                onChange={(e) => reactSelectHandleChange(e.value, handleChange, 'perfil_id')}
                styles={reactSelectStyleIsError(errors, 'perfil_id.value', selectStyles)}
              />
            </IfComponent>
          </Col>
          <Col sm='4'>
            <label>Status</label>
            <RadioGroup
              id='status'
              name='status'
              className='radio-griks child-half'
              value={values.status}
              onChange={(e) => {
                handleChange({
                  target: {
                    name: 'status',
                    value: e
                  }
                });
              }}
              horizontal
            >
              <RadioButton pointColor={'var(--primary-accent)'} value='S'>
                Ativo
              </RadioButton>
              <RadioButton pointColor={'var(--primary-accent)'} iconSize={20} value='N'>
                Inativo
              </RadioButton>
            </RadioGroup>
          </Col>
          <IfComponent conditional={this.state.expandCat}>
            <Col sm='12'>
              <Card className={'border text-left'}>
                <CardBody className={'pb-2'}>
                  <CardSubtitle className={'text-center text-default text-sm font-bolder text-secondary-d4 mb-2'}>
                    <span className={'pt-1'}>Categoria / Perfil</span>
                  </CardSubtitle>
                  <CardText className={'text-default text-sm text-secondary-d4 mb-2'}>
                    <strong className={'text-secondary-d4'}>Administrador</strong>- é qualquer usuário com o atributo de
                    Administrador. Tem acesso irrestrito a todas as funções disponíveis no sistema. Só existirá um
                    Administrador. Inicialmente, é quem adquiriu a Assinatura.
                  </CardText>
                  <CardText className={'text-default text-sm text-secondary-d4 mb-2'}>
                    <strong className={'text-secondary-d4'}>Dentista</strong>- é um usuário Dentista, que pode ter
                    Agenda e Tratamentos. Caso não seja o Administrador, suas permissões de acesso serão definidas pelo
                    Administrador.
                  </CardText>
                  <CardText className={'text-default text-sm text-secondary-d4 mb-2'}>
                    <strong className={'text-secondary-d4'}>Auxiliar Clínica (ASB)</strong>- é um usuário que atua como
                    auxiliar no atendimento clínico do paciente.
                  </CardText>
                  <CardText className={'text-default text-sm text-secondary-d4 mb-2'}>
                    <strong className={'text-secondary-d4'}>Auxiliar de Recepção</strong>- é um usuário que atua na
                    recepção da clínica.
                  </CardText>
                  <CardText className={'text-default text-sm text-secondary-d4 mb-2'}>
                    <strong className={'text-secondary-d4'}>Auxiliar Administrativo</strong>- é um usuário, que atua em
                    funções administrativas.
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </IfComponent>
        </Row>
        <Row className='mt-2'>
          <Col sm='12'>
            <label>
              Nome de Usuário<span>*</span>{' '}
            </label>
            <span className={'pl-2'}>
              <IoIosHelpCircle
                size={16}
                color='#616e88'
                style={{ cursor: 'help' }}
                alt='help'
                id='questionDesc'
                data-tip
                data-for={'questionDesc'}
              />
            </span>
            <UncontrolledTooltip className={'tooltip-white'} placement='top' target='questionDesc'>
              Forma reduzida (até 19 caracteres) como o nome do usuário aparecerá no sistema. Ex. “Dr. Paulo Lopes”
            </UncontrolledTooltip>
            <input
              className='input-secondary'
              type='text'
              name='usuario_cadastro'
              value={values.usuario_cadastro}
              onChange={handleChange}
              maxLength='19'
            ></input>
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col sm='12'>
            <label>
              E-mail<span>*</span>
            </label>
            <input
              className='input-secondary'
              type='text'
              name='login'
              value={values.login}
              onChange={handleChange}
            ></input>
          </Col>
        </Row>
        <Row className={'mt-2'}>
          <Col sm='12'>
            <label>
              Dentistas aos quais terá acesso<span>*</span>
            </label>
            <span className={'pl-2'}>
              <IoIosHelpCircle
                size={16}
                color='#616e88'
                style={{ cursor: 'help' }}
                alt='help'
                className='mb-1'
                id='dentistasTool'
                data-tip
                data-for={''}
              />
            </span>
            <UncontrolledTooltip className={'tooltip-white'} placement='top' target='dentistasTool'>
              Usuário terá acesso às informações dos Dentistas selecionados
            </UncontrolledTooltip>
            <Select
              options={dentistas.map((d) => {
                return {
                  value: d.id,
                  label: d.nome_completo
                };
              })}
              ignoreCase={true}
              isMulti={true}
              defaultValue={dentistas
                .filter((d) => {
                  if (values.dentista_acessos) {
                    if (values.dentista_acessos.some((dent) => d.id == dent.id)) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                })
                .map((d) => {
                  return {
                    value: d.id,
                    label: d.nome_completo
                  };
                })}
              placeholder={''}
              onChange={(e) =>
                reactSelectHandleChange(
                  e.map((set) => {
                    return { id: set.value, nome_completo: set.label };
                  }),
                  handleChange,
                  'dentista_acessos'
                )
              }
              styles={reactSelectStyleIsError(errors, 'dentista_acessos.value', selectStyles)}
            />
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col sm={'12'}>
            <label>Horário de Acesso ao Sistema</label>
            <RadioGroup
              name='tipo_horario_acesso'
              className='radio-griks child-half'
              value={values.tipo_horario_acesso}
              onChange={(e) => {
                handleChange({
                  target: {
                    name: 'tipo_horario_acesso',
                    value: e
                  }
                });
              }}
              horizontal
            >
              <RadioButton pointColor={'var(--primary-accent)'} iconSize={20} value='E'>
                Horario Restrito
              </RadioButton>
              <RadioButton pointColor={'var(--primary-accent)'} iconSize={20} value='S'>
                Sem Restrição
              </RadioButton>
            </RadioGroup>
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col sm='12'>
            <h2 className='text-default font-bold text-uppercase text-secondary'>Outras informações</h2>
          </Col>
        </Row>
        <Row>
          <Col sm='9'>
            <label>CRO</label>
            <input
              className='input-secondary'
              type='text'
              name='cro'
              value={values.cro}
              onChange={handleChange}
            ></input>
          </Col>
          <Col sm='3'>
            <label className='w-100'>UF</label>
            <Select
              options={getStates().map((s) => {
                return { value: s.sigla, label: s.sigla };
              })}
              ignoreCase={true}
              placeholder={''}
              defaultValue={getStates().map((s) => {
                if (s.sigla === values.cro_uf) {
                  return { value: s.sigla, label: s.sigla };
                }
              })}
              onChange={(e) => reactSelectHandleChange(e.value, handleChange, 'cro_uf')}
              styles={reactSelectStyleIsError(errors, 'cro_uf.value', selectStyles)}
            />
          </Col>
        </Row>
        <Row className={'mt-2'}>
          <Col sm='4'>
            <label>CPF</label>
            <InputMaskComponent
              invalid={errors.cpf ? true : false}
              value={values.cpf}
              name='cpf'
              id={'cpf'}
              mask={maskCPF()}
              onChange={handleChange}
              guide={false}
            />
          </Col>
          <Col sm='4'>
            <label>RG</label>
            <Input
              className='input-secondary'
              invalid={errors.rg ? true : false}
              value={values.rg}
              name='rg'
              id={'rg'}
              onChange={handleChange}
            />
          </Col>
          <Col sm='4'>
            <label>Emissor</label>
            <input
              className='input-secondary'
              type='text'
              name='rg_emissor'
              value={values.rg_emissor}
              onChange={handleChange}
            ></input>
          </Col>
        </Row>
        {/* <Row className="mt-2">
          <Col sm='12'>
            <label>Telefone 1</label>
          </Col>
        </Row>
        <Row>
          <Col sm="4">
            <InputMaskComponent guide={ false } value={ values.telefone_one || defaultData.ddd } name={ 'telefone_one' }
                                invalid={ errors.telefone_one ? true : false }
                                id={ 'telefone_one' } mask={ maskPhoneDefine(values.telefone_one || defaultData.ddd) }
                                placeholder='(11) 12315...' onChange={ handleChange }/>
          </Col>
          <Col sm="5">
            <input className="input-secondary" type="text" name="nome_telefone_one" value={ values.nome_telefone_one }
                   onChange={ handleChange } placeholder='Próprio'></input>
          </Col>
          <Col sm='3'>
            <select id="soflow" name='telefone_one_whatsapp' value={ values.telefone_one_whatsapp }
                    onChange={ handleChange }>
              <option></option>
              <option value={ 'W' }> Whatsapp</option>
              <option value={ 'F' }> Fixo</option>
            </select>
          </Col>
        </Row> */}
        <Row className='mt-2'>
          <Col sm='4'>
            <label htmlFor='telefone_one'>Telefone</label>
            <MaskedInput
              id='telefone_one'
              name='telefone_one'
              mask={maskPhoneDefine(values.telefone_one)}
              value={values.telefone_one}
              onChange={handleChange}
              guide={false}
              render={(ref, props) => (
                <Input
                  className='input-secondary'
                  innerRef={ref}
                  // invalid={errors.birthDate ? true : false}
                  {...props}
                />
              )}
            />
          </Col>
          <Col sm='7'>
            <label>Descrição</label>
            <input
              className='input-secondary'
              type='text'
              name='nome_telefone_one'
              onChange={handleChange}
              value={values.nome_telefone_one}
            />
          </Col>
          <Col sm='1'>
            <label id='tooltipWhats' onClick={() => {}}>
              Tipo
            </label>
            <UncontrolledTooltip placement='top' target='tooltipWhats' className='tooltip-white'>
              Este telefone tem whatsapp?
            </UncontrolledTooltip>

            <a
              href={'#'}
              onClick={() => {
                handleChange({
                  target: {
                    name: 'telefone_one_whatsapp',
                    value:
                      t(values.telefone_one_whatsapp).isUndefined || t(values.telefone_one_whatsapp).safeString === 'T'
                        ? 'W'
                        : 'T'
                  }
                });
              }}
            >
              <IoLogoWhatsapp
                size={'28'}
                color={'#57BB63'}
                className={
                  'mt-1 toggle-whats ' +
                  (t(values.telefone_one_whatsapp).isUndefined || values.telefone_one_whatsapp === 'T'
                    ? null
                    : 'whats-on')
                }
                alt='whats'
              />
            </a>
          </Col>
        </Row>
      </div>
    );
  }
}

export default UserSettingsFormRegisterCadastroForm;
