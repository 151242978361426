export const SETTINGS_PROCEDURES_REGISTER_PAGE = 'SETTINGS_PROCEDURES_REGISTER_PAGE';

export function registerModal({ modal, formData }) {
  return {
    type: SETTINGS_PROCEDURES_REGISTER_PAGE,
    payload: {
      modal,
      formData
    }
  };
}
