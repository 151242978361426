import RestTemplate from '../utils/restTemplate/rest.template';

class SettingsAPI {
  static saveClinicSettings = (data) => {
    const restTemplate = new RestTemplate(true);
    const { tecnico = {} } = data;
    const saveData = {
      nome: data.nome,
      cnpj: data.cnpj,
      rg: tecnico.rg,
      emissor: tecnico.emissor,
      cpf: tecnico.cpf,
      cro: tecnico.cro,
      cro_uf: tecnico.cro_uf,
      razao_social: data.razao_social,
      nome_responsavel_tecnico: tecnico.nome
    };

    return restTemplate.put(`/clinic/${data.id}/information`, saveData);
  };

  static saveClinicAddressSettings = (data) => {
    debugger;
    const restTemplate = new RestTemplate(true);
    let phone = '';
    let ddd = '';

    if (data.telefone_completo) {
      let phoneWithoutMask = data.telefone_completo.replace(/[^a-zA-Z0-9]/g, '');
      ddd = phoneWithoutMask.substr(0, 2);
      phone = phoneWithoutMask.substr(2);
    }

    const saveData = {
      ...data,
      endereco: data.nome,
      ddd,
      telefone: phone,
      uf: data.uf.value
    };
    return restTemplate.put(`/clinic/${data.id}/address`, saveData);
  };

  static saveClinicTimeSettings = (data) => {
    const restTemplate = new RestTemplate(true);

    const dataSave = {
      horario_funcionamento_inicio: data.horario_inicio,
      horario_funcionamento_fim: data.horario_fim,
      dias_funcionamento: data.dias_atendimento.map((d) => {
        return {
          dayOfWeek: d.dia_semana,
          day: d.dia_semana_nome
        };
      })
    };

    return restTemplate.put(`/clinic/${data.id}/time`, dataSave);
  };

  static listAllLibraries = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/bibliotecas');
  };

  static saveLibrary = ({ library, libraryItem }) => {
    const restTemplate = new RestTemplate(true);
    let promiseResponse;
    let rq = {
      nome: libraryItem.name,
      descricao: libraryItem.name
    };

    if (!libraryItem.id) {
      promiseResponse = restTemplate.post(`/bibliotecas/${library}/items`, rq);
    } else {
      rq.id = libraryItem.id;
      rq.posicao = libraryItem.position;
      promiseResponse = restTemplate.put(`/bibliotecas/${library}/items`, rq);
    }

    return promiseResponse;
  };

  static deleteLibraryItem = (library, libraryItem) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.delete(`/bibliotecas/${library}/items/${libraryItem}`);
  };

  static getAllGroupOfProcedures = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/procedimentos/categorias');
  };

  static getProceduresOfGroup = (groupId) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/procedimentos/categoria/${groupId}/procedimentos`);
  };

  static saveProcedure = (data) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post('/procedimentos', data);
  };

  static orderLibrary = (library, item, order) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.patch(`/bibliotecas/${library}/items/${item}?order=${order}`);
  };

  static savePreferencesVoucherEmission = (type, data) => {
    let request = {};

    if (type === 'R') {
      request = {
        voucher_type: type,
        emission_type: data.emission_type.value,
        dentist: data.dentist.value,
        cpf: data.cpf,
        cro: data.cro,
        cro_uf: data.cro_uf.value,
        signature: data.signature
      };
    } else {
      request = {
        voucher_type: type,
        emission_type: 'F',
        razao_social: data.razao_social,
        cnpj: data.cnpj
      };
    }
    const restTemplate = new RestTemplate(true);
    return restTemplate.post('/clinic/preferences/voucher-invoice', request);
  };

  static uploadImageAssign = (image, extension) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post('/clinic/preferences/voucher-invoice/signature', {
      extension: extension,
      image: image.replace(/\bdata:.*,/g, '')
    });
  };
}

export default SettingsAPI;
