import React, { Component } from 'react';
import FinancialOverviewReportPage from './FinancialOverviewReportPage';
import FinancialOverdueDebitsPage from './FinancialOverdueDebitsPage';
import FinancialBillingPage from './FinancialBillingPage';
import { IfComponent } from '../../../components/if.component';
import FinancialReportsMenu from './FinancialReportsMenu';
import { connect } from 'react-redux';
import { compose } from 'recompose';

class FinancialReportsPage extends Component {
  state = {
    tabSelected: null
  };

  selectedTab = (tab) => {
    const { tabSelected } = this.state;

    switch (tab) {
      case 'RE':
        break;
      case 'DV':
        break;
      case 'RF':
        break;
    }
    this.setState({ tabSelected: tab });
  };

  componentDidMount() {
    this.selectedTab('RE');
  }

  render() {
    const { tabSelected } = this.state;

    return (
      <div>
        <FinancialReportsMenu selectTab={this.selectedTab} menuSelected={tabSelected} />
        <IfComponent conditional={tabSelected === 'RE'}>
          <FinancialOverviewReportPage />
        </IfComponent>
        <IfComponent conditional={tabSelected === 'DV'}>
          <FinancialOverdueDebitsPage />
        </IfComponent>
        <IfComponent conditional={tabSelected === 'RF'}>
          <FinancialBillingPage />
        </IfComponent>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default FinancialReportsPage;
