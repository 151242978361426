import { treatmentRegisterModal } from '../redux/Treatment.action';
import { call, put } from 'redux-saga/effects';
import UserAPI from '../../../service/User.service';
import specialties from '../../../utils/specialties';
import situations from '../../../utils/TreatmentSituations';

export function* tretamentRegisterModal({ payload: { modal, formLoad, callback } }) {
  const dentists = yield call(UserAPI.findAllDentistas);
  yield put(
    treatmentRegisterModal({
      modal,
      formData: {},
      formLoad: { ...formLoad, dentists, specialties, situations },
      callback
    })
  );
}
