import React, { Component } from 'react';

import { Col, Container, Row, Table } from 'reactstrap';
import edit from '../../../assets/img/icons/edit.svg';
import plus from '../../../assets/img/icons/plus.svg';
import excluir from '../../../assets/img/icons/excluir.svg';
import Icon from '../../../components/Icon.component';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { dateFormat } from '../../../utils/Formatter';
import TreatmentEvaluationReceptionRegistrationModal from './modals/TreatmentEvaluationReceptionRegistration.modal';
import {
  treatmentEvaluationModalOtherAnnotations,
  treatmentEvaluationModalPreAttendence,
  treatmentEvaluationOpenProblems,
  treatmentEvaluationPreModalOrthoDocs,
  treatmentEvaluationPreModalResult,
  treatmentEvaluationPreOpenAngleRating,
  treatmentEvaluationPreOpenComplaint,
  treatmentEvaluationPreOpenReceptionRegister
} from './redux/TreatmentEvaluation.action';
import _ from 'lodash';
import TreatmentEvaluationPrincipalComplaintModal from './modals/TreatmentEvaluationPrincipalComplaint.modal';
import TreatmentEvaluationAngleRatingModel from './modals/TreatmentEvaluationAngleRating.modal';
import TreatmentEvaluatonProblemsDiagnosticModal from './modals/TreatmentEvaluatonProblemsDiagnostic.modal';
import TreatmentEvaluationResultModal from './modals/TreatmentEvaluationResult.modal';
import TreatmentExclusionConfirm from '../modals/TreatmentConfirm.modal';
import TreatmentEvaluationApi from '../../../service/TreatmentEvaluationApi';
import { hideLoader, showLoader } from '../../../components/actions/Loader.actions';
import MessagesComponent from '../../../components/Messages.component';
import TreatmentOrthoDocsModal from './modals/TreatmentOrthoDocs.modal';
import TreatmentOtherAnnotationsModal from './modals/TreatmentOtherAnnotations.modal';
import TreatmentEvaluationAttendenceRegisterModal from './modals/TreatmentEvaluationAttendenceRegister.modal';
import withSecurity from '../../../config/security/security';
import { IfComponent } from '../../../components/if.component';
import { PERMISSOES } from '../../../config/security/permissoes';

class TreatmentEvaluationPage extends Component {
  state = {
    exclusionTreatment: false,
    exclusionAttendence: false,
    attendenceSelected: {},
    message: {
      display: false,
      type: 'danger',
      text: ''
    }
  };

  checkExcluinTreatment = () => this.setState({ exclusionTreatment: true });

  cancelExclusionTreatmentEvaluationConfirm = () => this.setState({ exclusionTreatment: false });

  confirmTreatmentEvaluationConfirm = async () => {
    this.props.showLoader();
    try {
      await TreatmentEvaluationApi.deleteEvaluation(this.props.formLoad.evaluation.avaliacao_id);
      this.cancelExclusionTreatmentEvaluationConfirm();
      this.props.updateParentDelete();
    } catch (e) {
      console.log(e);
      this.setState({
        message: {
          display: true,
          type: 'danger',
          text: 'Erro ao deletar avaliação'
        }
      });
    }

    this.props.hideLoader();
  };

  checkDeleteAttendence = (a) => this.setState({ exclusionAttendence: true, attendenceSelected: a });

  cancelExclusionTreatmentAttendenceConfirm = () => this.setState({ exclusionAttendence: false });

  confirmTreatmentAttendenceConfirm = async () => {
    this.props.showLoader();
    try {
      await TreatmentEvaluationApi.deleteAttendenceRegister(this.state.attendenceSelected.id);
      this.cancelExclusionTreatmentAttendenceConfirm();
      this.props.updateParent();
    } catch (e) {
      console.log(e);
      this.setState({
        message: {
          display: true,
          type: 'danger',
          text: 'Erro ao deletar avaliação'
        }
      });
    }

    this.props.hideLoader();
  };

  render() {
    const { exclusionTreatment, message, exclusionAttendence } = this.state;
    const {
      show = false,
      formLoad = {},
      treatmentEvaluationReceptionRegister,
      treatmentComplaint,
      updateParent,
      treatmentAngleRating,
      treatmentProblems,
      treatmentResult,
      treatmentDocs,
      treatmentOtherAnnotations,
      treatmentAttendence,
      security
    } = this.props;

    const {
      evaluation = {},
      treatment = {},
      complaint = {},
      angleRating = {},
      listIncidents = {},
      evaluationResult = {},
      docs = {},
      otherAnotations = {},
      attendenceRegister = []
    } = formLoad;

    const { paciente = {} } = evaluation;
    const evaluationPerm = PERMISSOES.modules.tratamento_avaliacoes;

    return (
      <Container>
        {show && (
          <div>
            <MessagesComponent display={message.display} type={message.type} message={message.text} />
            <Row className='margin-top-10'>
              <Col sm='12'>
                <div className='box-default'>
                  <Row>
                    <Col sm='12'>
                      <h2 className='title-medium-plus text-secondary text-uppercase'>
                        Informações da Recepção e Cadastro
                        <IfComponent
                          conditional={security.hasPermission(evaluationPerm.id, evaluationPerm.permissoes.editar)}
                        >
                          <a
                            href={'#'}
                            onClick={() =>
                              treatmentEvaluationReceptionRegister({
                                modal: true,
                                formLoad: {
                                  treatment,
                                  evaluation
                                }
                              })
                            }
                          >
                            <Icon className='icon icon-size-small margin-left-10' image={edit} alt='edit' />
                          </a>
                        </IfComponent>
                      </h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm='4'>
                      <p className='text-sm text-default mb-2'>
                        <strong>Nome</strong> {paciente.nome}
                      </p>
                      <p className='text-sm text-default mb-2'>
                        <strong>Nome Preferido</strong> {paciente.nome_preferido}
                      </p>
                    </Col>
                    <Col sm='4'>
                      <p className='text-sm text-default mb-2'>
                        <strong>Data de Nascimento</strong> {dateFormat(paciente.data_nascimento, 'DD/MM/YYYY')}{' '}
                      </p>
                      <p className='text-sm text-default mb-2'>
                        <strong>Idade</strong> {paciente.idade}
                      </p>
                    </Col>
                    <Col sm='4'>
                      <p className='text-sm text-default mb-2'>
                        <strong>Data de Avaliação</strong> {dateFormat(evaluation.data_avaliacao, 'DD/MM/YYYY')}{' '}
                      </p>
                      <p className='text-sm text-default mb-2'>
                        <strong>Acompanhante</strong>
                        {evaluation.nome_acompanhante}
                        {evaluation.parentesco_acompanhante && <span> - {evaluation.parentesco_acompanhante}</span>}
                      </p>
                    </Col>
                  </Row>

                  <hr className={'mt-2 mb-3'} />

                  <Row>
                    <Col sm='4'>
                      <p className='text-sm text-default'>
                        <strong>Tipo de Indicação</strong> {paciente.tipo_indicacao_nome}
                      </p>
                    </Col>
                    <Col sm='4'>
                      <p className='text-sm text-default'>
                        <strong>Quem Indicou</strong> {paciente.nome_indicacao}
                      </p>
                    </Col>
                  </Row>

                  <hr className={'mt-2 mb-3'} />

                  <Row>
                    <Col sm='6'>
                      <p className='text-sm text-default mb-2'>
                        <strong>Motivo da Consulta</strong> {evaluation.motivo_consulta_descricao}
                      </p>
                      <p className='text-sm text-default mb-2'>
                        <strong>Já Usou Aparelho?</strong>{' '}
                        {evaluation.usou_aparelho && evaluation.usou_aparelho.substr(0, 1) == 'N' ? 'Não' : 'Sim'}.{' '}
                        {evaluation.descricao_usou_aparelho}
                      </p>
                    </Col>
                    <Col sm='6'>
                      <p className='text-sm text-default mb-2'>
                        <strong>Está Usando Aparelho?</strong>{' '}
                        {evaluation.utiliza_aparelho && evaluation.utiliza_aparelho.substr(0, 1) == 'N' ? 'Não' : 'Sim'}
                        . {evaluation.descricao_utiliza_aparelho}
                      </p>
                      <p className='text-sm text-default mb-2'>
                        <strong>Anotações </strong>
                        {evaluation.anotacoes}
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <Row className='margin-top-10'>
              <Col sm='12'>
                <div className='box-default'>
                  <Row>
                    <Col sm='12'>
                      <h2 className='title-medium-plus text-secondary text-uppercase'>
                        Queixa Principal e Motivação
                        <IfComponent
                          conditional={security.hasPermission(evaluationPerm.id, evaluationPerm.permissoes.editar)}
                        >
                          <a
                            href={'#'}
                            onClick={() =>
                              treatmentComplaint({
                                modal: true,
                                formLoad: {
                                  treatment,
                                  evaluation,
                                  complaint
                                }
                              })
                            }
                          >
                            <Icon className='icon icon-size-small margin-left-10' image={edit} alt='edit' />
                          </a>
                        </IfComponent>
                      </h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm='6'>
                      <p className='text-sm text-default mb-2'>
                        <strong>Queixa Principal</strong>{' '}
                        {complaint.queixas &&
                          _.join(
                            complaint.queixas.map((q) => q.queixa_nome),
                            ', '
                          )}
                      </p>
                    </Col>
                    <Col sm='6'>
                      <p className='text-sm text-default mb-2'>
                        <strong>Interesse do Paciente</strong>{' '}
                        {complaint.interesse_paciente && complaint.interesse_paciente.descricao}
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <Row className='margin-top-10'>
              <Col sm='12'>
                <div className='box-default'>
                  <Row>
                    <Col sm='6'>
                      <h2 className='title-medium-plus text-secondary text-uppercase mb-3'>
                        Classificação de Angle
                        <IfComponent
                          conditional={security.hasPermission(evaluationPerm.id, evaluationPerm.permissoes.editar)}
                        >
                          <a
                            href={'#'}
                            onClick={() => {
                              treatmentAngleRating({
                                modal: true,
                                formLoad: {
                                  evaluation,
                                  angleRating
                                }
                              });
                            }}
                          >
                            <Icon className='icon icon-size-small margin-left-10' image={edit} alt='edit' />
                          </a>
                        </IfComponent>
                      </h2>
                      <h3 className='title-small text-center text-default text-sm'>
                        <strong className='mr-3'>Classificação de Angle</strong>
                        {angleRating.classificacao_angle && angleRating.classificacao_angle.descricao}
                      </h3>

                      <Row className='margin-top-20'>
                        <Col className='margin-top-20 text-right' sm='1'>
                          Dir
                        </Col>
                        <Col sm='10'>
                          <Row>
                            <Col sm='6' className='border-bottom border-right p-1'>
                              <Row className='text-center'>
                                <Col sm='6'>6</Col>
                                <Col sm='6'>3</Col>
                              </Row>
                            </Col>
                            <Col sm='6' className='border-bottom p-1'>
                              <Row className='text-center'>
                                <Col sm='6'>3</Col>
                                <Col sm='6'>6</Col>
                              </Row>
                            </Col>
                            <Col sm='6' className='border-right p-1'>
                              <Row className='text-center'>
                                <Col sm='6'>{angleRating.dente6_direita && angleRating.dente6_direita.descricao}</Col>
                                <Col sm='6'>{angleRating.dente3_direita && angleRating.dente3_direita.descricao}</Col>
                              </Row>
                            </Col>
                            <Col sm='6' className='p-1'>
                              <Row className='text-center'>
                                <Col sm='6'>{angleRating.dente6_esquerda && angleRating.dente6_esquerda.descricao}</Col>
                                <Col sm='6'>{angleRating.dente3_esquerda && angleRating.dente3_esquerda.descricao}</Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <Col className='margin-top-20' sm='1'>
                          Esq
                        </Col>
                      </Row>
                      <Row className='margin-top-10'>
                        <Col sm='12'>
                          <p className='text-default text-sm text-center mb-2'>
                            <strong>Classificação esquelética AP</strong>{' '}
                            {angleRating.classe_esqueletica && angleRating.classe_esqueletica.descricao}
                          </p>
                          <p className='text-default text-sm text-center'>
                            <strong>Padrão Esquelético-Cefálico</strong>{' '}
                            {angleRating.padrao_esqueletico && angleRating.padrao_esqueletico.descricao}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={{ size: 6 }}>
                      <h2 className='title-medium-plus text-secondary text-uppercase'>
                        Lista de Problemas/Diagnóstico
                        <IfComponent
                          conditional={security.hasPermission(evaluationPerm.id, evaluationPerm.permissoes.editar)}
                        >
                          <a
                            href={'#'}
                            onClick={() =>
                              treatmentProblems({
                                modal: true,
                                formLoad: {
                                  treatment,
                                  evaluation,
                                  problems: listIncidents
                                }
                              })
                            }
                          >
                            <Icon className='icon icon-size-small margin-left-10' image={edit} alt='edit' />
                          </a>
                        </IfComponent>
                      </h2>
                      <h2 className='text-secondary text-md-plus font-bold text-uppercase'>Problemas</h2>
                      <div className={'margin-top-10'}>
                        {listIncidents.map((l, index) => (
                          <p className='text-sm text-default w-100 border-bottom px-1 py-2'>
                            <strong className='font-bold'>{++index}.</strong>
                            {l.problema_diagnostico}
                          </p>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <Row className='margin-top-10'>
              <Col sm='12'>
                <div className='box-default'>
                  <Row>
                    <Col sm='12'>
                      <h2 className='title-medium-plus text-secondary text-uppercase'>
                        Resultado da Avaliação
                        <IfComponent
                          conditional={security.hasPermission(evaluationPerm.id, evaluationPerm.permissoes.editar)}
                        >
                          <a
                            href={'#'}
                            onClick={() => {
                              treatmentResult({
                                modal: true,
                                formLoad: {
                                  treatment,
                                  evaluation,
                                  evaluationResult
                                }
                              });
                            }}
                          >
                            <Icon className='icon icon-size-small margin-left-10' image={edit} alt='edit' />
                          </a>
                        </IfComponent>
                      </h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm='12'>
                      <p className='text-sm text-default pb-2'>
                        <strong>Resultado Avaliação</strong>{' '}
                        <span className='text-uppercase'>
                          {evaluationResult.resultado_avaliacao &&
                            _.startCase(_.lowerCase(evaluationResult.resultado_avaliacao.descricao))}
                        </span>
                      </p>
                    </Col>
                    <Col sm='4'>
                      <p className='text-sm text-default mb-2'>
                        <strong>Complexidade do Caso</strong>{' '}
                        {evaluationResult.complexidade_avaliacao &&
                          _.startCase(_.lowerCase(evaluationResult.complexidade_avaliacao.descricao))}
                      </p>
                    </Col>
                    <Col sm='4'>
                      <p className='text-sm text-default mb-2'>
                        <strong>Interesse da Clínica</strong>{' '}
                        {evaluationResult.interesse_clinica &&
                          _.startCase(_.lowerCase(evaluationResult.interesse_clinica.descricao))}
                      </p>
                    </Col>
                    <Col sm='4'>
                      <p className='text-sm text-default mb-2'>
                        <strong>Aparelho Preferido</strong>{' '}
                        {evaluationResult.aparelho && evaluationResult.aparelho.descricao}
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <Row className='margin-top-10'>
              <Col sm='12'>
                <div className='box-default'>
                  <Row>
                    <Col sm='6'>
                      <Row>
                        <Col sm='12'>
                          <h2 className='title-medium-plus text-secondary text-uppercase'>
                            Documentação Ortodôntica
                            <IfComponent
                              conditional={security.hasPermission(evaluationPerm.id, evaluationPerm.permissoes.editar)}
                            >
                              <a
                                href={'#'}
                                onClick={() => {
                                  treatmentDocs({
                                    modal: true,
                                    formLoad: {
                                      evaluation,
                                      docsForm: docs
                                    }
                                  });
                                }}
                              >
                                <Icon className='icon icon-size-small margin-left-10' image={edit} alt='edit' />
                              </a>
                            </IfComponent>
                          </h2>
                        </Col>
                        <Col sm='12'>
                          <p className='text-sm text-default text-secondary-d4 mb-2'>
                            <strong className='text-secondary-d4'>Doc Existente</strong>{' '}
                            {docs.existente &&
                              _.join(
                                docs.existente.map((d) => d.documentacoes.descricao),
                                ', '
                              )}
                          </p>
                        </Col>
                        <Col sm='12'>
                          <p className='text-sm text-default mb-2'>
                            <strong className='text-secondary-d4'>Doc Solicitado</strong>
                            {docs.solicitada &&
                              _.join(
                                docs.solicitada.map((d) => d.documentacoes.descricao),
                                ', '
                              )}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm='6'>
                      <Row>
                        <Col sm='12'>
                          <h2 className='title-medium-plus text-secondary text-uppercase'>
                            Outras Anotações
                            <IfComponent
                              conditional={security.hasPermission(evaluationPerm.id, evaluationPerm.permissoes.editar)}
                            >
                              <a
                                href={'#'}
                                onClick={() => {
                                  treatmentOtherAnnotations({
                                    modal: true,
                                    formLoad: {
                                      evaluation,
                                      annotation: otherAnotations.anotacao
                                    }
                                  });
                                }}
                              >
                                <Icon className='icon icon-size-small margin-left-10' image={edit} alt='edit' />
                              </a>
                            </IfComponent>
                          </h2>
                        </Col>
                        <Col sm='12'>
                          <p className='text-sm text-default mb-2'>
                            <strong className='text-secondary-d4'>Anotações</strong>{' '}
                            {otherAnotations && otherAnotations.anotacao}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            {/*<Row className="margin-top-10">*/}
            {/*  <Col sm="12">*/}
            {/*    <div className="box-default">*/}
            {/*      <Row>*/}
            {/*        <Col sm="12">*/}
            {/*          <h2 className="title-medium-plus text-secondary text-uppercase">Atendimentos Realizados antes do Início do Tratamento*/}
            {/*            <IfComponent*/}
            {/*              conditional={ security.hasPermission(evaluationPerm.id, evaluationPerm.permissoes.editar) }>*/}
            {/*              <a href={ '#' } onClick={ () => {*/}
            {/*                treatmentAttendence({ modal: true, formLoad: { evaluation, treatment } });*/}
            {/*              } }>*/}
            {/*                <Icon className="icon icon-size-small margin-left-10" image={ plus } alt="plus"/>*/}
            {/*              </a>*/}
            {/*            </IfComponent>*/}
            {/*          </h2>*/}
            {/*          <div className="content-overflow-default">*/}
            {/*            <Table className="table-default" responsive>*/}
            {/*              <thead className="bg-secondary-l5">*/}
            {/*              <tr>*/}
            {/*                <th width="100">Data</th>*/}
            {/*                <th>Anotações</th>*/}
            {/*                <th>Proxima Consulta</th>*/}
            {/*                <th>Doutor(a)</th>*/}
            {/*                <th></th>*/}
            {/*                <th width="10%"></th>*/}
            {/*              </tr>*/}
            {/*              </thead>*/}
            {/*              <tbody>*/}
            {/*              { attendenceRegister.map(a => (*/}
            {/*                <tr key={ a.id }>*/}
            {/*                  <td>{ dateFormat(a.dataAtendimento, 'DD/MM/YYYY') }</td>*/}
            {/*                  <td>{ a.anotacao }</td>*/}
            {/*                  <td>{ a.proximaConsulta }</td>*/}
            {/*                  <td>{ a.dentista.nome }</td>*/}
            {/*                  <td className="py-2">*/}
            {/*                    <IfComponent*/}
            {/*                      conditional={ security.hasPermission(evaluationPerm.id, evaluationPerm.permissoes.editar) }>*/}
            {/*                      <a className="float-right" href={ '#' } onClick={ () => {*/}
            {/*                        this.checkDeleteAttendence(a);*/}
            {/*                      } }>*/}
            {/*                        <Icon className="icon icon-size-small icon-alert" image={ excluir } alt="excluir"/>*/}
            {/*                      </a>*/}
            {/*                    </IfComponent>*/}
            {/*                    <IfComponent*/}
            {/*                      conditional={ security.hasPermission(evaluationPerm.id, evaluationPerm.permissoes.editar) }>*/}
            {/*                      <a className="float-right mr-2" href={ '#' } onClick={ () => {*/}
            {/*                        treatmentAttendence({ modal: true, formLoad: { evaluation, attendence: a } });*/}
            {/*                        } }>*/}
            {/*                          <Icon className="icon icon-size-small" image={ edit } alt="edit"/>*/}
            {/*                      </a>*/}
            {/*                    </IfComponent>*/}
            {/*                  </td>*/}
            {/*                </tr>*/}
            {/*              )) }*/}
            {/*              </tbody>*/}
            {/*            </Table>*/}
            {/*          </div>*/}
            {/*        </Col>*/}
            {/*      </Row>*/}
            {/*    </div>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            <Row>
              <Col sm={12}>
                <Col sm={2} className={'float-right margin-top-10'}>
                  <IfComponent
                    conditional={security.hasPermission(evaluationPerm.id, evaluationPerm.permissoes.editar)}
                  >
                    <a
                      href={'#'}
                      onClick={() => {
                        this.checkExcluinTreatment();
                      }}
                    >
                      <button className='button-cancel float-right px-3' type={'button'}>
                        Excluir Avaliação
                      </button>
                    </a>
                  </IfComponent>
                </Col>
              </Col>
            </Row>
          </div>
        )}
        <TreatmentEvaluationReceptionRegistrationModal updateParent={updateParent} />
        <TreatmentEvaluationPrincipalComplaintModal updateParent={updateParent} />
        <TreatmentEvaluationAngleRatingModel updateParent={updateParent} />
        <TreatmentEvaluatonProblemsDiagnosticModal updateParent={updateParent} />
        <TreatmentEvaluationResultModal updateParent={updateParent} />
        <TreatmentOrthoDocsModal updateParent={updateParent} />
        <TreatmentOtherAnnotationsModal updateParent={updateParent} />
        <TreatmentEvaluationAttendenceRegisterModal updateParent={updateParent} />

        <TreatmentExclusionConfirm
          cancelAction={this.cancelExclusionTreatmentEvaluationConfirm}
          display={exclusionTreatment}
          confirmAction={this.confirmTreatmentEvaluationConfirm}
          text={
            'Tem certeza que deseja excluir a FICHA DE AVALIAÇÃO? <br />' +
            ' Todas as informações serão definitivamente perdidas'
          }
        />
        <TreatmentExclusionConfirm
          cancelAction={this.cancelExclusionTreatmentAttendenceConfirm}
          display={exclusionAttendence}
          confirmAction={this.confirmTreatmentAttendenceConfirm}
          text={'Tem certeza que deseja excluir o Atendimento?'}
        />
      </Container>
    );
  }
}

const mapStateToProps = ({ TreatmentEvaluationReducer }) => {
  const { evaluationPage } = TreatmentEvaluationReducer;
  return {
    ...evaluationPage
  };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  treatmentEvaluationReceptionRegister: (data) => dispatch(treatmentEvaluationPreOpenReceptionRegister(data)),
  treatmentComplaint: (data) => dispatch(treatmentEvaluationPreOpenComplaint(data)),
  treatmentAngleRating: (data) => dispatch(treatmentEvaluationPreOpenAngleRating(data)),
  treatmentProblems: (data) => dispatch(treatmentEvaluationOpenProblems(data)),
  treatmentResult: (data) => dispatch(treatmentEvaluationPreModalResult(data)),
  treatmentDocs: (data) => dispatch(treatmentEvaluationPreModalOrthoDocs(data)),
  treatmentOtherAnnotations: (data) => dispatch(treatmentEvaluationModalOtherAnnotations(data)),
  treatmentAttendence: (data) => dispatch(treatmentEvaluationModalPreAttendence(data))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withSecurity);

export default enhanced(TreatmentEvaluationPage);
