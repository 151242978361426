import React, { Component } from 'react';

import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown
} from 'reactstrap';
import plus from '../../../assets/img/icons/plus.svg';
import ReactTooltip from 'react-tooltip';
import edit from '../../../assets/img/icons/edit.svg';
import Icon from '../../../components/Icon.component';
import UserAPI from '../../../service/User.service';
import ScheduleAPI from '../../../service/ScheduleAPI';
import { hideLoader, showLoader } from '../../../components/actions/Loader.actions';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import _ from 'lodash';
import menuDots from '../../../assets/img/menuDots.png';
import SettingsScheduleChairsModal from './modals/SettingsScheduleChairs.modal';
import {
  chairsModalSaga,
  officeHour,
  standardIntervalModal,
  standardProcedureModalSaga
} from './redux/ScheduleSettings.actions';
import SettingsSheduleChairsDeleteModal from './modals/SettingsSheduleChairsDelete.modal';
import SettingsScheduleStandardIntervalModal from './modals/SettingsScheduleStandardInterval.modal';
import SettingsScheduleStandardProcedureModal from './modals/SettingsScheduleStandardProcedure.modal';
import SettingsScheduleOfficeHoursModal from './modals/SettingsScheduleOfficeHours.modal';

import { getDentistSelected } from '../../../utils/localStorage/SessionStorageUtils';
import withSecurity from '../../../config/security/security';
import { PERMISSOES } from '../../../config/security/permissoes';
import { IfComponent } from '../../../components/if.component';

import Select from 'react-select';
import { selectStyles } from '../../../../src/config/core.config';
import SettingsScheduleAndControlReturStandardProceduresModal from './modals/SettingsScheduleAndControlReturStandardProceduresModal';

import t from 'typy';

class SettingsScheduleHomePage extends Component {
  state = {
    dentists: [],
    dentistsOptions: [],
    dentistSelected: null,
    chairs: [],
    configs: {},
    officeHours: [],
    charDelete: {},
    charDeleteModal: false,
    configsControlReturn: []
  };

  standardProceduresRef;

  constructor(props) {
    super(props);
    this.standardProceduresRef = React.createRef();
    this.componetModal = React.createRef();
  }

  componentDidMount = async () => {
    try {
      this.props.showLoader();
      const dentists = await UserAPI.findAllDentistas();
      let dentistSelected = null;
      this.setState({
        dentists,
        dentistsOptions: dentists.map((d) => {
          return {
            value: d.id,
            label: d.nome
          };
        })
      });

      if (this.state.dentistSelected === null) {
        dentistSelected = await getDentistSelected();

        this.setState(
          {
            dentistSelected: dentistSelected
          },
          () => this.dataFind()
        );
      }

      this.props.hideLoader();
    } catch (e) {
      console.log(e);
    }
  };

  selectDentist = (e) => {
    this.setState(
      {
        dentistSelected: e
      },
      () => this.dataFind()
    );
  };

  dataFind = () => {
    const { showLoader, hideLoader } = this.props;
    const { dentistSelected } = this.state;

    showLoader();

    Promise.all([
      this.findOfficeHours(dentistSelected.value),
      this.findScheduleConfig(dentistSelected.value),
      this.findChairs(dentistSelected.value)
    ])
      .then((data) => hideLoader())
      .catch((data) => hideLoader());
  };

  findOfficeHours = (dentistSelected) => {
    return ScheduleAPI.officeHours(dentistSelected).then((h) => {
      const office = _.orderBy(h.data, ['dia_semana'], ['desc']);
      let days = [];
      _.range(1, 8).forEach((i) => {
        const day = office.find((d) => d.dia_semana == i) || {
          dia_semana: i,
          horario_atendimento_inicio: '00:00',
          horario_atendimento_fim: '00:00'
        };

        days.push(day);
      });

      this.setState({ officeHours: days });
    });
  };

  findChairs = (dentistSelected) => {
    return UserAPI.getChairs(dentistSelected).then((c) => {
      this.setState({
        chairs: c.data
      });
    });
  };

  findScheduleConfig = (dentistSelected) => {
    return ScheduleAPI.config(dentistSelected).then((config) => {
      this.setState({
        configs: config.data
      });
    });
  };

  changeChairs = async (chair) => {
    try {
      const { chairsModal, showLoader, hideLoader } = this.props;
      showLoader();
      const chairData = await UserAPI.findChairById(chair.id);
      chairsModal({ modal: true, dentist: this.state.dentistSelected.value, initForm: chairData.data });
      hideLoader();
    } catch (e) {
      console.error(e);
    }
  };

  deleteChair = (chair) => {
    this.setState({
      charDelete: chair,
      charDeleteModal: true
    });
  };

  closeDeleteChairModal = () => {
    this.setState({
      charDelete: {},
      charDeleteModal: false
    });
  };

  findScheduleControlReturnConfig = (dentistSelected) => {
    return ScheduleAPI.configControlReturn(dentistSelected).then((config) => {
      this.setState({
        configsControlReturn: config.data
      });
    });
  };

  clickHandler = () =>{
    this.componetModal.current.openModal();
  }
  

  render() {
    const { dentistsOptions, dentistSelected, chairs, configs, officeHours, charDelete, charDeleteModal } = this.state;
    const {
      officeHour,
      standardProcedureModal,
      standardIntervalModal,
      chairsModal,
      showLoader,
      hideLoader,
      security
    } = this.props;

    const settingsPerm = PERMISSOES.modules.configuracoes;

    return (
      <div className='content-box-without-color'>
        <Container>
          <Row className='pb-0'>
            <Col sm='12'>
              <div className='box-search' style={{ padding: '20px' }}>
                <Row className='pb-0'>
                  <Col sm='5'>
                    <div className='box-search-info'>
                      <span className='text-default text-sm float-left pt-2 pr-2 pl-2'>
                        <strong>Doutor(a)</strong>
                      </span>
                      <Select
                        value={dentistSelected}
                        options={dentistsOptions}
                        ignoreCase={true}
                        placeholder={''}
                        onChange={(e) => this.selectDentist(e)}
                        styles={selectStyles}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          {dentistSelected && (
            <div>
              <Row>
                <Col sm='6'>
                  <div className='box-default w-100 mt-2 h-100'>
                    <Row>
                      <Col sm='12'>
                        <h2 className='title-medium-plus text-uppercase text-secondary font-bold'>
                          Horário de Atendimento
                          <IfComponent
                            conditional={
                              security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar) ||
                              (security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar_restricao.id) &&
                                security.hasRestriction(
                                  settingsPerm.id,
                                  settingsPerm.permissoes.editar_restricao.id,
                                  settingsPerm.permissoes.editar_restricao.restricoes.editar_agenda
                                ))
                            }
                          >
                            <a
                              href={'#'}
                              onClick={() => {
                                officeHour({
                                  modal: true,
                                  dentist: dentistSelected.value,
                                  initForm: {
                                    officeHours,
                                    configs
                                  }
                                });
                              }}
                            >
                              <Icon className='icon icon-size-small margin-left-10' image={edit} alt='edit' />
                            </a>
                          </IfComponent>
                        </h2>
                        <div className='content-overflow-default'>
                          <Table responsive>
                            <thead>
                              <tr>
                                <th width='10%'>Horário</th>
                                <th width='5%'>Seg</th>
                                <th width='5%'>Ter</th>
                                <th width='5%'>Qua</th>
                                <th width='5%'>Qui</th>
                                <th width='5%'>Sex</th>
                                <th width='5%'>Sab</th>
                                <th width='5%'>Dom</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>Início</th>
                                {officeHours.map((o) => {
                                  return <td>{o.horario_atendimento_inicio}</td>;
                                })}
                              </tr>
                              <tr>
                                <th>Final</th>
                                {officeHours.map((o) => {
                                  return <td>{o.horario_atendimento_fim}</td>;
                                })}
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className='margin-top-10' sm='12'>
                        <p>
                          <strong className='pr-2 font-bolder pl-2 ml-1'>Intervalo </strong>{' '}
                          {configs.intervalo_atendimento_inicio} às {configs.intervalo_atendimento_fim} hs
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col sm='6'>
                  <div className='box-default mt-2 h-100'>
                    <Row>
                      <Col sm='12'>
                        <h2 className='title-medium-plus text-uppercase text-secondary font-bold'>
                          Cadeiras
                          <IfComponent
                            conditional={
                              security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar) ||
                              (security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar_restricao.id) &&
                                security.hasRestriction(
                                  settingsPerm.id,
                                  settingsPerm.permissoes.editar_restricao.id,
                                  settingsPerm.permissoes.editar_restricao.restricoes.editar_agenda
                                ))
                            }
                          >
                            <a
                              data-tip
                              data-for={'addCad'}
                              href={'#'}
                              onClick={() => {
                                chairsModal({ modal: true, dentist: dentistSelected.value });
                              }}
                            >
                              <Icon className='icon icon-size-small margin-left-10' image={plus} alt='plus' />
                            </a>
                          </IfComponent>
                        </h2>
                        <ReactTooltip effect='solid' className='tooltip-griks' type='light' id='addCad'>
                          Adicionar Nova Cadeira
                        </ReactTooltip>
                        <div className='content-overflow-default'>
                          <Table responsive>
                            <thead>
                              <tr>
                                <th width='95%'>Nome da Cadeira</th>
                                <th width='15%'>Status</th>
                                <th width='5%'></th>
                              </tr>
                            </thead>
                            <tbody>
                              {chairs.map((c) => {
                                return (
                                  <tr key={c.id}>
                                    <td>{c.nome_cadeira}</td>
                                    <td className={c.status === 'ATIVO' ? 'text-success' : 'text-alert'}>{c.status}</td>
                                    <td>
                                      <IfComponent
                                        conditional={
                                          security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar) ||
                                          (security.hasPermission(
                                            settingsPerm.id,
                                            settingsPerm.permissoes.editar_restricao.id
                                          ) &&
                                            security.hasRestriction(
                                              settingsPerm.id,
                                              settingsPerm.permissoes.editar_restricao.id,
                                              settingsPerm.permissoes.editar_restricao.restricoes.editar_agenda
                                            ))
                                        }
                                      >
                                        <UncontrolledDropdown direction='left'>
                                          <DropdownToggle>
                                            <img src={menuDots} style={{ width: 20 }} />
                                          </DropdownToggle>
                                          <DropdownMenu>
                                            <DropdownItem onClick={() => this.changeChairs(c)}>Editar</DropdownItem>
                                            <DropdownItem onClick={() => this.deleteChair(c)}>Excluir</DropdownItem>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>
                                      </IfComponent>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm='6'>
                  <div className='box-default w-100 mt-3' style={{ height: 156 }}>
                    <Row>
                      <Col sm='12'>
                        <h2 className='title-medium-plus text-uppercase text-secondary font-bold'>
                          Intervalo Padrão de Agendamento
                          <IfComponent
                            conditional={
                              security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar) ||
                              (security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar_restricao.id) &&
                                security.hasRestriction(
                                  settingsPerm.id,
                                  settingsPerm.permissoes.editar_restricao.id,
                                  settingsPerm.permissoes.editar_restricao.restricoes.editar_agenda
                                ))
                            }
                          >
                            <a
                              href={'#'}
                              onClick={this.clickHandler}
                            >
                              <Icon className='icon icon-size-small margin-left-10' image={edit} alt='edit' />
                            </a>
                          </IfComponent>
                        </h2>
                      </Col>
                      <Col className='margin-top-10' sm='12'>
                        {
                          (configs.intervalo_padrao == 0) ?
                          <p>
                            <strong className='pr-2 font-bolder'>Intervalo de Agendamento</strong>{' '}
                            Sem Intervalo Fixo
                          </p>
                          :
                          <p>
                            <strong className='pr-2 font-bolder'>Intervalo de Agendamento</strong>{' '}
                            {configs.intervalo_padrao} {configs.intervalo_padrao_tipo}
                          </p>
                        }
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col sm='6'>
                  <div className='box-default mt-3'>
                    <Row>
                      <Col sm='12'>
                        <h2 className='title-medium-plus text-uppercase text-secondary font-bold'>
                          Procedimento Padrão da Agenda
                          <IfComponent
                            conditional={
                              security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar) ||
                              (security.hasPermission(settingsPerm.id, settingsPerm.permissoes.editar_restricao.id) &&
                                security.hasRestriction(
                                  settingsPerm.id,
                                  settingsPerm.permissoes.editar_restricao.id,
                                  settingsPerm.permissoes.editar_restricao.restricoes.editar_agenda
                                ))
                            }
                          >
                            <a
                              href={'#'}
                              onClick={() =>
                                this.standardProceduresRef.current.openModal(dentistSelected.value, configs)
                              }
                            >
                              <Icon className='icon icon-size-small margin-left-10' image={edit} alt='edit' />
                            </a>
                          </IfComponent>
                        </h2>
                      </Col>
                      <Col className='margin-top-10' sm='12'>
                        <Row>
                          <Col>
                            <strong className='pr-2 font-bolder'>Padrão da Agenda</strong>
                            <span
                              className='procedure-primary'
                              style={{ backgroundColor: configs.procedimento_categoria_rgb }}
                            >
                              {configs.procedimento_codinome}{' '}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <strong className='pr-2 font-bolder'>Padrão da Lista Controle</strong>
                            <span
                              className='procedure-primary'
                              style={{
                                backgroundColor: t(configs, 'procedimentoPadraoControle.categoria.rgb').safeString
                              }}
                            >
                              {t(configs, 'procedimentoPadraoControle.codinome').safeString}{' '}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <strong className='pr-2 font-bolder'>Padrão da Lista Contenção</strong>
                            <span
                              className='procedure-primary'
                              style={{
                                backgroundColor: t(configs, 'procedimentoPadraoContencao.categoria.rgb').safeString
                              }}
                            >
                              {t(configs, 'procedimentoPadraoContencao.codinome').safeString}{' '}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            
              <SettingsScheduleStandardIntervalModal
                ref={this.componetModal}
                refreshPage={this.dataFind}
                id={configs.id}
                idDentista={configs.dentista_id}
                intervalo={configs.intervalo_padrao}
              />
            
            </div>

          )}
          
        </Container>

        <SettingsScheduleChairsModal showLoader={showLoader} hideLoader={hideLoader} refreshPage={this.dataFind} />
        <SettingsSheduleChairsDeleteModal
          showLoader={showLoader}
          hideLoader={hideLoader}
          chair={charDelete}
          dentist={dentistSelected}
          modal={charDeleteModal}
          closeModal={this.closeDeleteChairModal}
          refreshPage={this.dataFind}
        />


        <SettingsScheduleStandardProcedureModal
          showLoader={showLoader}
          hideLoader={hideLoader}
          refreshPage={this.dataFind}
        />

        <SettingsScheduleAndControlReturStandardProceduresModal
          callback={this.dataFind}
          ref={this.standardProceduresRef}
        />

        <SettingsScheduleOfficeHoursModal showLoader={showLoader} hideLoader={hideLoader} refreshPage={this.dataFind} />
      </div>
    );
  }
}

const mapStateToProps = ({ ScheduleSettingsReducer }) => {};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (data) => dispatch(showLoader()),
    hideLoader: (data) => dispatch(hideLoader()),
    chairsModal: (data) => dispatch(chairsModalSaga(data)),
    standardIntervalModal: (data) => dispatch(standardIntervalModal(data)),
    standardProcedureModal: (data) => dispatch(standardProcedureModalSaga(data)),
    officeHour: (data) => dispatch(officeHour(data))
  };
};

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withSecurity);

export default enhanced(SettingsScheduleHomePage);
