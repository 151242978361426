import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';

class InputDecimalComponent extends Component {
  render() {
    const {
      name,
      value,
      errors,
      handleChange,
      valueChange,
      fixedDecimalScale = true,
      prefix = '',
      classNameStyle = ''
    } = this.props;

    return (
      <NumberFormat
        custonInput={Input}
        decimalScale={2}
        prefix={prefix}
        fixedDecimalScale={fixedDecimalScale}
        decimalSeparator={','}
        className={'form-control ' + classNameStyle}
        thousandSeparator={'.'}
        value={value}
        style={errors && (errors[name] ? { borderColor: 'red' } : {})}
        onValueChange={async (v) => {
          const { formattedValue, value, floatValue } = v;

          if (handleChange) {
            await handleChange({
              target: {
                name: name,
                value: floatValue
              }
            });
          }

          if (valueChange) valueChange(v);
        }}
        {...this.props}
      />
    );
  }
}

InputDecimalComponent.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  errors: PropTypes.array,
  handleChange: PropTypes.func.isRequired,
  valueChange: PropTypes.func,
  fixedDecimalScale: PropTypes.bool,
  prefix: PropTypes.string,
  classNameStyle: PropTypes.string
};

export default InputDecimalComponent;
