import React, { Component } from 'react';
import { Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Form, Formik } from 'formik';
import MessagesComponent from '../../../../components/Messages.component';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { clinicalRecordDiagnosticModal } from '../redux/TreatmentClinicalRecord.action';
import Select from 'react-select';
import {
  formInitSelector,
  formLoadSelector,
  propsModalSelector
} from '../selectors/TreatmentClinicalRecordModalAngleSelector';
import TreatmentClinicalRecordApi from '../../../../service/TreatmentClinicalRecordApi';
import { t } from 'typy';

import { showLoader, hideLoader } from '../../../../components/actions/Loader.actions';
import { selectStyles } from '../../../../config/core.config';

class TreatmentClinicalRecordDiagnosticAngleModal extends Component {
  state = {
    error: {
      display: false,
      type: 'danger',
      text: ''
    }
  };

  saveAngleRating = async (values) => {
    const { diagnostic, treatment, record, showLoader, hideLoader, updateParent } = this.props;
    try {
      showLoader();
      await TreatmentClinicalRecordApi.saveRatingAngleInDiagnostic({
        treatmentId: treatment,
        recordId: record,
        diagnosticId: diagnostic.id,
        id: values.id,
        classificacao_angle: t(values, 'angle.data.id').safeObject,
        dente_direito6: t(values, 'thoth_right_6.data.id').safeObject,
        dente_direito3: t(values, 'thoth_right_3.data.id').safeObject,
        dente_esquerdo3: t(values, 'thoth_left_3.data.id').safeObject,
        dente_esquerdo6: t(values, 'thoth_left_6.data.id').safeObject,
        classe_esqueletica: t(values, 'classificacao_esqueletica.data.id').safeObject,
        padrao_esqueletico: t(values, 'padrao_esqueletico.data.id').safeObject
      });

      updateParent();
      this.closeModal();
    } catch (e) {
      console.log(e);
      this.setState({
        error: {
          display: true,
          type: 'danger',
          text: 'Erro ao salvar Classificação de Angle por favor entre em contato com o suporte'
        }
      });
    }
    hideLoader();
  };

  closeModal = () => {
    const { closeModal } = this.props;
    closeModal();
    this.setState({
      error: {
        display: false,
        type: 'danger',
        text: ''
      }
    });
  };

  render() {
    const { error } = this.state;
    const { modal, formLoad = {}, formInit = {} } = this.props;

    const { toothOptions = [], anglesOptions = [], skeletalClassOptions = [], skeletalPatternsOptions = [] } = formLoad;

    return (
      <Modal isOpen={modal} toggle={this.closeModal} className={this.props.className} size={'lg'}>
        <ModalHeader
          className='title-primary'
          toggle={this.closeModal}
          close={
            <button className='close' onClick={this.closeModal}>
              &times;
            </button>
          }
        >
          <strong>Editar</strong>
          Classificação de Angle
        </ModalHeader>

        <Formik
          // validationSchema={ this.validationForm }
          validateOnChange={false}
          initialValues={formInit}
          onSubmit={this.saveAngleRating}
        >
          {({ errors, values, handleSubmit, handleChange }) => (
            <Form>
              <ModalBody>
                <MessagesComponent display={error.display} type={error.type} message={error.text} />
                <div className='margin-top-10'>
                  <Row className='margin-top-10'>
                    <Col sm='12'>
                      <label>Classificação de Angle</label>
                    </Col>
                    <Col sm={12}>
                      <Select
                        value={values.angle}
                        isSearchable={true}
                        ignoreAccents={true}
                        ignoreCase={true}
                        options={anglesOptions}
                        placeholder={''}
                        styles={selectStyles}
                        onChange={(selectedOption) => {
                          handleChange({
                            target: {
                              name: 'angle',
                              value: selectedOption
                            }
                          });
                        }}
                      />
                    </Col>
                  </Row>

                  <Row className='margin-top-10'>
                    <Col>
                      <Row>
                        <Col sm={1} style={{ alignSelf: 'center' }}>
                          Dir
                        </Col>
                        <Col sm={10}>
                          <Container>
                            <Row
                              style={{
                                borderBottomColor: '#F2F2F2',
                                borderBottom: 1,
                                borderBottomStyle: 'solid',
                                marginBottom: 10,
                                paddingBottom: 0,
                                height: 25
                              }}
                            >
                              <Col style={{ textAlign: 'center' }}>6</Col>
                              <Col style={{ textAlign: 'center' }}>3</Col>
                              <Col style={{ textAlign: 'center', borderLeft: 1, borderLeftStyle: 'solid' }}>3</Col>
                              <Col style={{ textAlign: 'center' }}>6</Col>
                            </Row>
                            <Row>
                              <Col sm={3}>
                                <Select
                                  value={values.thoth_right_6}
                                  isSearchable={true}
                                  ignoreAccents={true}
                                  ignoreCase={true}
                                  options={toothOptions}
                                  placeholder={''}
                                  styles={selectStyles}
                                  onChange={(selectedOption) => {
                                    handleChange({
                                      target: {
                                        name: 'thoth_right_6',
                                        value: selectedOption
                                      }
                                    });
                                  }}
                                />
                              </Col>
                              <Col sm={3}>
                                <Select
                                  value={values.thoth_right_3}
                                  isSearchable={true}
                                  ignoreAccents={true}
                                  ignoreCase={true}
                                  options={toothOptions}
                                  placeholder={''}
                                  styles={selectStyles}
                                  onChange={(selectedOption) => {
                                    handleChange({
                                      target: {
                                        name: 'thoth_right_3',
                                        value: selectedOption
                                      }
                                    });
                                  }}
                                />
                              </Col>
                              <Col sm={3}>
                                <Select
                                  value={values.thoth_left_3}
                                  isSearchable={true}
                                  ignoreAccents={true}
                                  ignoreCase={true}
                                  options={toothOptions}
                                  placeholder={''}
                                  styles={selectStyles}
                                  onChange={(selectedOption) => {
                                    handleChange({
                                      target: {
                                        name: 'thoth_left_3',
                                        value: selectedOption
                                      }
                                    });
                                  }}
                                />
                              </Col>
                              <Col sm={3}>
                                <Select
                                  value={values.thoth_left_6}
                                  isSearchable={true}
                                  ignoreAccents={true}
                                  ignoreCase={true}
                                  options={toothOptions}
                                  placeholder={''}
                                  styles={selectStyles}
                                  onChange={(selectedOption) => {
                                    handleChange({
                                      target: {
                                        name: 'thoth_left_6',
                                        value: selectedOption
                                      }
                                    });
                                  }}
                                />
                              </Col>
                            </Row>
                          </Container>
                        </Col>
                        <Col sm={1} style={{ alignSelf: 'center' }}>
                          Esq
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className='margin-top-10'>
                    <Col sm='6'>
                      <label>Classificação Esquelética</label>
                    </Col>

                    <Col sm='6'>
                      <label>Padrão Esquelético</label>
                    </Col>

                    <Col sm={6}>
                      <Select
                        value={values.classificacao_esqueletica}
                        isSearchable={true}
                        ignoreAccents={true}
                        ignoreCase={true}
                        options={skeletalClassOptions}
                        placeholder={''}
                        styles={selectStyles}
                        onChange={(selectedOption) => {
                          handleChange({
                            target: {
                              name: 'classificacao_esqueletica',
                              value: selectedOption
                            }
                          });
                        }}
                      />
                    </Col>
                    <Col sm={6}>
                      <Select
                        value={values.padrao_esqueletico}
                        isSearchable={true}
                        ignoreAccents={true}
                        ignoreCase={true}
                        options={skeletalPatternsOptions}
                        placeholder={''}
                        styles={selectStyles}
                        onChange={(selectedOption) => {
                          handleChange({
                            target: {
                              name: 'padrao_esqueletico',
                              value: selectedOption
                            }
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </ModalBody>
              <ModalFooter>
                <Row>
                  <Col sm={{ size: 2, offset: 8 }}>
                    <button className='button-primary'>Salvar</button>
                  </Col>
                  <Col sm={{ size: 0, offset: 0 }}>
                    <button className='button-tertiary' type={'button'} onClick={this.closeModal}>
                      Cancelar
                    </button>
                  </Col>
                </Row>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...propsModalSelector(state),
    formLoad: formLoadSelector(state),
    formInit: formInitSelector(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(clinicalRecordDiagnosticModal({ modal: false })),
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader())
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(TreatmentClinicalRecordDiagnosticAngleModal);
