export const timeValidate = (timeValue) => {
  const time = timeValue.split(':');
  const hour = time[0] && time[0].replace('_', '');
  const minute = time[1] && time[1].replace('_', '');

  if (hour !== '') {
    if (hour >= 0 && hour <= 24 && minute >= 0 && minute < 60) {
      return true;
    } else {
      return false;
    }
  }
  return true;
};
