import { put, takeLatest } from 'redux-saga/effects';
import { SAVE_PATIENT, REFRESH_LIST, showPatientEditModal } from '../redux/patient.actions';
import { SEND_MESSAGE } from '../../../components/actions/ErrorMessage.actions';
import { LOADER_MESSAGE } from '../../../components/actions/Loader.actions';
import PacienteAPI from '../../../service/PacienteAPI';

export function* watchRegisterPatientSaga() {
  yield takeLatest(SAVE_PATIENT, savePatientSaga);
}

export function* savePatientSaga(action) {
  try {
    const { data, status } = yield PacienteAPI.saveSimplePatient(action.data);

    yield put({
      type: LOADER_MESSAGE,
      payload: {
        display: false
      }
    });

    // yield put({
    //   type: SEND_MESSAGE,
    //   data: {
    //     where: 'patientList',
    //     display: true,
    //     type: "info",
    //     message: "Paciente Salvo Com Sucesso"
    //   }
    // });

    yield put({
      type: REFRESH_LIST,
      payload: {
        refreshList: true
      }
    });
    if (action.data.saveAndComplete) {
      const patient = yield PacienteAPI.findById(data.paciente_id);

      yield put(
        showPatientEditModal({
          patient,
          display: true
        })
      );
    }
  } catch (err) {
    console.error(err);
    yield put({
      type: LOADER_MESSAGE,
      payload: {
        display: false
      }
    });
    yield put({
      type: SEND_MESSAGE,
      data: {
        display: true,
        type: 'danger',
        message: 'Erro ao salvar Paciente'
      }
    });
  }
}
