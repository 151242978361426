import React, { Component } from 'react';

import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { appointmentHistoryStateToProps } from '../selectors/AppointmentHistoryModal.selector';
import moment from 'moment';
import { appointmentHistoryModal } from '../redux/Schedule.actions';
import avatar from '../../../assets/img/noavatar.png';
import NumberFormat from 'react-number-format';

class AppointmentHistoryModal extends Component {
  weekLabel = { 1: 'Seg', 2: 'Ter', 3: 'Qua', 4: 'Qui', 5: 'Sex', 6: 'Sab', 7: 'Dom' };

  render() {
    const { modal, patient = {}, history = [], frequencia = 0 } = this.props;

    return (
      <Modal
        isOpen={modal}
        toggle={this.props.closeModal}
        className={this.props.className + ' table-appointment'}
        size={'mlarge'}
      >
        <ModalHeader
          className='title-primary'
          toggle={this.props.closeModal}
          close={
            <button className='close' onClick={this.props.closeModal}>
              &times;
            </button>
          }
        >
          <span>
            <img
              style={{ width: 65, height: 65 }}
              className='img-circle small mr-2'
              src={patient.foto || avatar}
              alt='usuario'
            />
          </span>
          <strong>{patient.nome}</strong>
        </ModalHeader>
        <ModalBody>
          <div className='margin-top-10'>
            <Row style={{ marginTop: -25 }}>
              <Col sm='12'>
                <Row className='mb-3'>
                  <Col sm='12'>
                    <strong className='title-large mr-5 float-left'>HISTÓRICO DE CONSULTAS</strong>
                    <span
                      style={{
                        backgroundColor: '#f3f4f6',
                        marginLeft: '-30px',
                        marginTop: '-6px',
                        width: 110,
                        height: 42
                      }}
                      className='button-grey text-center'
                    >
                      <span className='text-md-plus text-secondary font-bold'>
                        <NumberFormat
                          value={frequencia}
                          displayType={'text'}
                          decimalScale={3}
                          allowLeadingZeros={true}
                          fixedDecimalScale={true}
                          format={frequencia === 100 ? '###' : frequencia < 10 ? '#' : '##'}
                        />
                        <span className='text-sm text-secondary'>%</span>
                      </span>
                      <p className='text-xsm text-secondary font-medium' style={{ marginTop: '-0.3rem' }}>
                        Frequência
                      </p>
                    </span>
                  </Col>
                </Row>
                <div style={{ marginTop: 10 }}>
                  <Table className='table-header-fixed' responsive>
                    <thead className='container-fluid px-0 border-bottom'>
                      <tr className='row'>
                        <th className='col-sm-3'>Situação</th>
                        <th className='col-sm-3'>Data</th>
                        <th className='col-sm-1'>Horário</th>
                        <th className='col-sm-5'>Doutor(a)</th>
                      </tr>
                    </thead>
                    <tbody className='container-fluid px-0'>
                      {history &&
                        history.map((h) => (
                          <tr className='row pb-0' key={h.dataAgendamento}>
                            <td className='col-sm-3' style={{ backgroundColor: h.status_rgb, color: '#000' }}>
                              <span>{h.status}</span>
                            </td>
                            <td className='col-sm-3'>
                              <span className='mr-2'>{moment(h.dataAgendamento).format('DD/MM/YYYY')}</span>
                              {this.weekLabel[moment(h.dataAgendamento).weekday()]}
                            </td>
                            <td className='col-sm-1'>{h.horario}</td>
                            <td className='col-sm-5'>{h.dentista}</td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </div>
          <ModalFooter></ModalFooter>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...appointmentHistoryStateToProps(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(appointmentHistoryModal({ modal: false }))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(AppointmentHistoryModal);
