import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Row, Col, Container, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import noavatar from '../../../assets/img/noavatar.png';
import t from 'typy';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import StringUtils from '../../../utils/StringUtils';
import { formatNumberFlow } from '../flow_utils';

const FlowTimelineModal = ({ usuario }, modalRef) => {
  const [showModal, setShowModal] = useState(false);
  const [fluxo, setFluxo] = useState({});
  const [aguardando, setAguardando] = useState({});
  const [emAtendimento, setEmAtendimento] = useState({});
  const [atendido, setAtendido] = useState({});

  function closeModal() {
    setShowModal(false);
  }

  useImperativeHandle(modalRef, () => ({
    openModal(fluxo) {
      setShowModal(true);
      setFluxo(fluxo);
      setAguardando(fluxo.historicoStatus.find((s) => s.status === 'AGUARDANDO'));
      setEmAtendimento(fluxo.historicoStatus.find((s) => s.status === 'EM_ATENDIMENTO'));
      setAtendido(fluxo.historicoStatus.find((s) => s.status === 'ATENDIDO'));
    }
  }));

  function getDiffeenceOfProcedure(entrada, saida) {
    console.log(entrada);
    console.log(saida);

    const flowStart = moment(entrada);
    const timeToSchedule = moment(saida);
    // const timeToSchedule = moment(fluxo.dentistaAgenda.data_agendamento + 'T' + '17:40');
    const difference = flowStart.diff(timeToSchedule, 'minutes', true);

    if (difference > 0) {
      return <>+{difference.toFixed(0)}</>;
    } else {
      return <>{difference.toFixed(0)}</>;
    }
  }

  function getDiffeenceOfProcedureNumber(entrada, saida) {
    console.log(entrada);
    console.log(saida);

    const flowStart = moment(entrada);
    const timeToSchedule = moment(saida);
    // const timeToSchedule = moment(fluxo.dentistaAgenda.data_agendamento + 'T' + '17:40');
    const difference = flowStart.diff(timeToSchedule, 'minutes', true);

    return difference.toFixed(0);
  }

  function getColorEspera() {
    const tempo = getDiffeenceOfProcedureNumber(t(aguardando, 'saida').safeString, t(aguardando, 'entrada').safeString);
    if (tempo > 0 && tempo < 10) {
      return '#000000';
    } else if (tempo >= 10 && tempo < 20) {
      return '#e75d5c';
    } else if (tempo >= 20) {
      return '#b83cef';
    }
  }

  return (
    <div>
      <Modal isOpen={showModal} toggle={closeModal}>
        <ModalHeader
          className='mb-1'
          toggle={closeModal}
          close={
            <button style={{ zIndex: '2' }} className='close' onClick={closeModal}>
              &times;
            </button>
          }
        ></ModalHeader>
        <ModalBody className='mt-n5 p-2'>
          <Container fluid={true} style={{ zIndex: '1' }} className='p-1'>
            <Container fluid={true}>
              <Row>
                <Col sm='2'>
                  <img
                    src={
                      t(fluxo, 'dentistaAgenda.paciente.foto').isDefined
                        ? t(fluxo, 'dentistaAgenda.paciente.foto').safeString
                        : noavatar
                    }
                    alt='User Avatar'
                    className='img-fluid'
                  />
                </Col>
                <Col sm='10' className='ml-n2 p-2'>
                  <Row className='py-0'>
                    <Col>
                      <p className='text-md-plus text-secondary font-bold mb-0 py-0'>
                        {t(fluxo, 'dentistaAgenda.paciente.nome').safeString}
                      </p>
                      <p className='text-sm font-light text-secondary'>
                        ({t(fluxo, 'dentistaAgenda.paciente.id').safeString}){' '}
                        {t(fluxo, 'dentistaAgenda.paciente.idade').safeString}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>

            <Container fluid={true}>
              <Row className='mt-2 mb-4'>
                <Col sm='12'>
                  <strong className=' text-secondary text-md font-bold text-uppercase'>Timeline do Atendimento</strong>
                </Col>
              </Row>
            </Container>

            <Container fluid={true}>
              <Row className='py-0 mt-n1'>
                <Col sm='3'>
                  <p className='text-sm text-secondary-d4 font-bolder'>Data</p>
                </Col>
                <Col sm='3'>
                  <p className='text-sm text-secondary-d4 font-bolder'>Procedimento</p>
                </Col>
                <Col sm='3'>
                  <p className='text-sm text-secondary-d4 font-bolder'>Doutor(a)</p>
                </Col>
                <Col sm='3'>
                  <p className='text-sm text-secondary-d4 font-bolder'>Cadeira</p>
                </Col>
              </Row>
              <Row className='py-0'>
                <Col sm='3'>
                  <p className='text-sm text-secondary font-bold'>
                    {moment(t(fluxo, 'dentistaAgenda.data_agendamento').safeString).format('DD/MM/YYYY')}
                  </p>
                </Col>
                <Col sm='3'>
                  <ReactTooltip id='tooltipProcedimento' className='tooltip-griks' type='light'>
                    {t(fluxo, 'dentistaAgenda.procedimento.nome').safeString}
                  </ReactTooltip>
                  <span
                    data-tip
                    data-for={'tooltipProcedimento'}
                    className='procedure-primary'
                    style={{
                      backgroundColor: t(fluxo, 'dentistaAgenda.procedimento.categoria.rgb').safeString
                    }}
                  >
                    {t(fluxo, 'dentistaAgenda.procedimento.codinome').safeString}
                  </span>
                </Col>
                <ReactTooltip id='tooltipDentista' className='tooltip-griks' type='light'>
                  {t(fluxo, 'dentistaAgenda.dentista_nome').safeString}
                </ReactTooltip>
                <Col sm='3'>
                  <span data-tip data-for={'tooltipDentista'} className='receipt-03'>
                    {StringUtils.abbreviateString(t(fluxo, 'dentistaAgenda.dentista_nome').safeString)}
                  </span>
                </Col>
                <ReactTooltip id='tooltipCadeira' className='tooltip-griks' type='light'>
                  {t(fluxo, 'dentistaAgenda.cadeira').safeString}
                </ReactTooltip>
                <Col sm='3'>
                  <span data-tip data-for={'tooltipCadeira'} className='receipt-03'>
                    {StringUtils.abbreviateString(t(fluxo, 'dentistaAgenda.cadeira').safeString)}
                  </span>
                </Col>
              </Row>
            </Container>

            <Container fluid={true} className='mt-3'>
              <Row className='py-0'>
                <Col sm='3'>
                  <p className='text-sm text-secondary-d4 font-bolder'>Agendamento</p>
                </Col>
                <Col sm='3'>
                  <p className='text-sm text-secondary-d4 font-bolder'>Chegada</p>
                </Col>
                <Col sm='3'>
                  <p className='text-sm text-secondary-d4 font-bolder'>Pontualidade</p>
                </Col>
                <Col sm='3'>
                  <p className='text-sm text-secondary-d4 font-bolder'>Espera</p>
                </Col>
              </Row>
              <Row className='py-0 mt-n1'>
                <Col sm='3'>
                  <p className='text-sm text-secondary font-bold'>{t(fluxo, 'dentistaAgenda.horario_de').safeString}</p>
                </Col>
                <Col sm='3'>
                  <p className='text-sm text-secondary font-bold'>
                    {moment(t(aguardando, 'entrada').safeString).format('HH:mm')}
                  </p>
                </Col>
                <Col sm='3'>
                  <p
                    className={`text-sm ${
                      getDiffeenceOfProcedureNumber(
                        `${t(fluxo, 'dentistaAgenda.data_agendamento').safeString}T${
                          t(fluxo, 'dentistaAgenda.horario_de').safeString
                        }`,
                        t(aguardando, 'entrada').safeString
                      ) > 0
                        ? 'text-danger '
                        : ''
                    } font-bold`}
                  >
                    {getDiffeenceOfProcedure(
                      `${t(fluxo, 'dentistaAgenda.data_agendamento').safeString}T${
                        t(fluxo, 'dentistaAgenda.horario_de').safeString
                      }`,
                      t(aguardando, 'entrada').safeString
                    )}
                  </p>
                </Col>
                <Col sm='3'>
                  <p className={`text-sm font-bold`} style={{ color: getColorEspera() }}>
                    {getDiffeenceOfProcedureNumber(
                      t(aguardando, 'saida').safeString,
                      t(aguardando, 'entrada').safeString
                    )}
                  </p>
                </Col>
              </Row>
            </Container>

            <Container fluid={true} className='mt-3'>
              <Row className='py-0'>
                <Col sm='3'>
                  <p className='text-sm text-secondary-d4 font-bolder'>Início Atend</p>
                </Col>
                <Col sm='3'>
                  <p className='text-sm text-secondary-d4 font-bolder'>Tempo Atend</p>
                </Col>
                <Col sm='3'>
                  <p className='text-sm text-secondary-d4 font-bolder'>Dif de Tempo</p>
                </Col>
                <Col sm='3'>
                  <p className='text-sm text-secondary-d4 font-bolder'>Final do Atend</p>
                </Col>
              </Row>
              <Row className='py-0 mt-n1'>
                <Col sm='3'>
                  <p className='text-sm text-secondary font-bold'>
                    {moment(t(emAtendimento, 'entrada').safeString).format('HH:mm')}
                  </p>
                </Col>
                <Col sm='3'>
                  <p className='text-sm text-secondary font-bold'>
                    {getDiffeenceOfProcedureNumber(
                      t(emAtendimento, 'saida').safeString,
                      t(emAtendimento, 'entrada').safeString
                    )}
                  </p>
                </Col>
                <Col sm='3'>
                  <p
                    className={`text-sm ${
                      getDiffeenceOfProcedureNumber(
                        t(emAtendimento, 'saida').safeString,
                        t(emAtendimento, 'entrada').safeString
                      ) -
                        t(fluxo, 'dentistaAgenda.procedimento.tempo').safeNumber >
                      0
                        ? 'text-danger'
                        : ''
                    } font-bold`}
                  >
                    {formatNumberFlow(
                      getDiffeenceOfProcedureNumber(
                        t(emAtendimento, 'saida').safeString,
                        t(emAtendimento, 'entrada').safeString
                      ) - t(fluxo, 'dentistaAgenda.procedimento.tempo').safeNumber
                    )}
                  </p>
                </Col>
                <Col sm='3'>
                  <p className='text-sm text-secondary font-bold'>
                    {moment(t(atendido, 'saida').safeString).format('HH:mm')}
                  </p>
                </Col>
              </Row>
            </Container>
          </Container>
        </ModalBody>
        <ModalFooter className='mb-0 pb-0'></ModalFooter>
      </Modal>
    </div>
  );
};

export default forwardRef(FlowTimelineModal);
