import { takeEvery, call, put, delay } from 'redux-saga/effects';
import {
  clinicSettings,
  editClinicSettings,
  SAGA_FIND_CLINIC_SETTINGS_BY_USER,
  SAGA_SAVE_ADDRESS_SETTINGS,
  SAGA_SAVE_CLINIC_TIME_SETTINGS,
  SAGA_SAVE_SETTINGS
} from '../redux/settings.actions';

import ClinicApi from '../../../../service/ClinicAPI';
import { pickBy, identity } from 'lodash';
import { hideLoader } from '../../../../components/actions/Loader.actions';
import SettingsAPI from '../../../../service/SettingsAPI';
import { sendMessage } from '../../../../components/actions/ErrorMessage.actions';
import ToastUtils from '../../../../utils/ToastUtils';

export function* watchSettings(state) {
  yield takeEvery(SAGA_FIND_CLINIC_SETTINGS_BY_USER, findSettingsByUser);
  yield takeEvery(SAGA_SAVE_ADDRESS_SETTINGS, saveClinicAddressSettings);
  yield takeEvery(SAGA_SAVE_CLINIC_TIME_SETTINGS, saveClinicTimeSettings);
  yield takeEvery(SAGA_SAVE_SETTINGS, saveSettings);
}

export function* findSettingsByUser(state) {
  const clinic = yield call(ClinicApi.findByUser);
  const { data } = clinic;

  const horarioFuncionamento = data.configuracao || {};

  const dataReturn = {
    clinica: pickBy(
      {
        id: data.id,
        nome: data.nome,
        razao_social: data.razao_social,
        cnpj: data.cnpj,
        tecnico: pickBy(
          {
            nome: data.nome_responsavel_tecnico,
            cpf: data.cpf,
            rg: data.rg,
            emissor: data.emissor,
            cro: data.cro,
            cro_uf: data.cro_uf
          },
          identity
        ),
        endereco: pickBy(
          {
            nome: data.endereco,
            numero: data.numero,
            complemento: data.complemento,
            bairro: data.bairro,
            uf: data.uf,
            cep: data.cep,
            cidade: data.cidade,
            ddd: data.ddd,
            telefone: data.telefone,
            email: data.email,
            website: data.website
          },
          identity
        ),
        horario_funcionamento: pickBy(
          {
            id: horarioFuncionamento.id,
            horario_inicio: horarioFuncionamento.horario_funcionamento_inicio,
            horario_fim: horarioFuncionamento.horario_funcionamento_fim,
            dias_atendimento: data.dias_atendimento
          },
          identity
        ),
        referencia_emissao: data.referencia_emissao
      },
      identity
    )
  };

  yield put(clinicSettings(dataReturn));

  yield put(hideLoader());
}

export function* saveSettings({ data }) {
  try {
    yield call(SettingsAPI.saveClinicSettings, data);
    yield put(editClinicSettings({ clinic: { open: false } }));

    yield put(hideLoader());
    yield put({ type: SAGA_FIND_CLINIC_SETTINGS_BY_USER });
    yield call(delay, 3000);
  } catch (e) {
    yield put(hideLoader());
    ToastUtils.error('Erro ao salvar configuração');
  }
}

export function* saveClinicAddressSettings({ data }) {
  try {
    yield call(SettingsAPI.saveClinicAddressSettings, data);
    yield put(hideLoader());
    yield put({ type: SAGA_FIND_CLINIC_SETTINGS_BY_USER });
  } catch (e) {
    yield put(hideLoader());
    ToastUtils.error('Erro ao salvar configuração');
  }
}

export function* saveClinicTimeSettings({ data }) {
  try {
    yield call(SettingsAPI.saveClinicTimeSettings, data);
    yield put(hideLoader());
    yield put({ type: SAGA_FIND_CLINIC_SETTINGS_BY_USER });
  } catch (e) {
    yield put(hideLoader());
    ToastUtils.error('Erro ao salvar configuração');
  }
}
