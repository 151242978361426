import RestTemplate from '../utils/restTemplate/rest.template';

export default class ClientApi {
  static starTrial(name, email, phone) {
    const restTemplate = new RestTemplate(false);
    return restTemplate.post('/client/trial/', {
      name,
      email,
      telefone: phone
    });
  }

  static checkPending(token) {
    const restTemplate = new RestTemplate(false);
    return restTemplate.get(`/client/trial/pending/${token}`);
  }

  static checkTrial(data) {
    const restTemplate = new RestTemplate(false);
    const { login, senha } = data;

    const postData = {
      username: login,
      password: senha
    };
    return restTemplate.post('/client/trial/check/', postData);
  }

  static configureClinic(token, data) {
    const restTemplate = new RestTemplate(false);
    return restTemplate.post(`/client/trial/${token}/configure`, data);
  }

  static subscribe(data) {
    const restTemplate = new RestTemplate(false);
    return restTemplate.post('/client/trial/subscribe/', data);
  }

  static findByName = (email) => {
    const restTemplate = new RestTemplate(false);
    return restTemplate.get(`/client/trial/nome?email=${email}`);
  };

  static validateCupom = (cupom) => {
    const restTemplate = new RestTemplate(false);
    return restTemplate.get(`/client/trial/cupom?cupom=${cupom}`);
  };

  static checkIfCanAddUser = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/client/checkIfCanAddUser');
  };
}
