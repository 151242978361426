import t from 'typy';
import { createSelector } from 'reselect';

const appointmentMissingModalSelector = (state) => t(state, 'ScheduleReducer.appointmentDeleteModal').safeObject;

export const appointmentDeleteToProps = createSelector(appointmentMissingModalSelector, ({ modal, schedule } = {}) => {
  return {
    modal,
    schedule
  };
});
