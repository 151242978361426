import React, { Component } from 'react';

import PropTypes from 'prop-types';

export const IfComponent = (props) => {
  let renderData = null;
  if (props.conditional) {
    renderData = props.children;
  }
  return renderData;
};

IfComponent.propTypes = {
  conditional: PropTypes.bool.isRequired
};
