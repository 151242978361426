import RestTemplate from '../utils/restTemplate/rest.template';

export default class ProceduresAPI {
  static getAllCategories = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/procedimentos/categorias');
  };

  static getById = (id) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/procedimentos/${id}`);
  };

  static getAllProcedures = (categoryId, enabledOnly = false) => {
    const restTemplate = new RestTemplate(true);
    if (!enabledOnly) {
      return restTemplate.get(`/procedimentos/categoria/${categoryId}/procedimentos`);
    } else {
      return restTemplate.get(`/procedimentos/categoria/${categoryId}/procedimentos/ativos`);
    }
  };

  static saveProcedure = ({ id, nome, codinome, time, pendencia, lembrete, status, categoria }) => {
    const restTemplate = new RestTemplate(true);

    const request = {
      categoria_id: categoria,
      nome,
      codinome,
      tempo: time,
      pendencia_laboratorio: pendencia,
      lembrete,
      status
    };

    if (id) {
      request.id = id;
      return restTemplate.put('/procedimentos', request);
    } else {
      return restTemplate.post('/procedimentos', request);
    }
  };

  static deleteProcedure = (id) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.delete(`/procedimentos/${id}`);
  };

  static getCategoriesOfClosedPrice = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/tabelaprecos/fechado/categorias');
  };

  static getAllProceduresOfClosedPrice = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/tabelaprecos/fechado/categorias/full');
  };

  static getProcedureOfCategory = (category) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/tabelaprecos/fechado/categoria/${category}/procedimentos`);
  };

  static getProcedureMonthly = () => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get('/tabelaprecos/mensalidade');
  };

  static updateStatusProcedure = (id, status) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.put(`/tabelaprecos/procedure/${id}/status`, { status });
  };

  static saveTablePriceProcedure = (categoryId, { id, nome, valorProcedimento, status }) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post(`/tabelaprecos/fechado/categoria/${categoryId}/procedimento`, {
      id,
      nome,
      valor_procedimento: valorProcedimento,
      status
    });
  };

  static deleteProcedureClosedPrice = (id) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.delete(`/tabelaprecos/fechado/procedimento/${id}`);
  };

  static saveMonthlyDeviceType = (data) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post('/tabelaprecos/mensal/tipoAparelho', data);
  };

  static deleteMonthlyDeviceType = (id) => {
    const restTemplate = new RestTemplate(true);
    return restTemplate.delete(`/tabelaprecos/mensal/tipoAparelho/${id}`);
  };

  static checkIfProcedureAssociateAnyDentist(procedure) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.delete(`/procedimentos/${procedure}/disabled/check`);
  }
}
