import React, { Component } from 'react';
import Loader from '../../components/Loader.component';
import { Col, Container, Input, Label, Row } from 'reactstrap';
import logo from '../../assets/img/logo.jpg';
import ErrorField from '../../components/ErrorField.component';
import { Form, Formik } from 'formik';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { hideLoader, showLoader } from '../../components/actions/Loader.actions';
import * as Yup from 'yup';
import ClientApi from '../../service/ClientApi';
import { withRouter } from 'react-router';
import ToastUtils from '../../utils/ToastUtils';
import { t } from 'typy';
import { ToastContainer } from 'react-toastify';
import danger from '../../assets/img/icons/danger1.svg';
import ToastCustomMessageComponent from '../../components/ToastCustomMessage.component';
import MaskedInput from 'react-text-mask';
import { maskPhoneDefine } from '../../utils/Formatter';

class StartTrialPage extends Component {
  validationSchema = Yup.object().shape({
    nome: Yup.string().required('Por favor digite o seu nome'),
    telefone: Yup.string().required('Por favor digite o seu telefone'),
    email: Yup.string().required('Por favor digite um e-mail').email('E-mail Inválido')
  });

  saveTrial = (values) => {
    const { showLoader, hideLoader, history } = this.props;

    showLoader();
    ClientApi.starTrial(values.nome, values.email, values.telefone)
      .then((data) => {
        hideLoader();
        history.push(`/confirmTrial/${btoa(values.email)}`);
      })
      .catch((err) => {
        hideLoader();
        const errMessage = t(err, 'response.data.message');
        if (errMessage.isDefined && errMessage.safeString.includes('Cliente')) {
          ToastUtils.trial(
            <ToastCustomMessageComponent
              iconImage={danger}
              title={'E-mail já cadastrado !'}
              message={'Por favor, utilize um novo e-mail.'}
            />,
            {
              autoClose: 7000,
              hideProgressBar: true,
              position: 'top-center'
            }
          );
        } else {
          ToastUtils.trial(
            <ToastCustomMessageComponent
              iconImage={danger}
              title={'Erro ao registrar o trial entre em contato com o suporte !'}
            />,
            {
              autoClose: 7000,
              hideProgressBar: true,
              position: 'top-center'
            }
          );
        }
      });
  };

  render() {
    return (
      <Formik onSubmit={this.saveTrial} validationSchema={this.validationSchema} validateOnChange={false}>
        {({ errors, values, handleChange }) => {
          return (
            <Form>
              <Container>
                <Loader />
                <main className='main-container-overflow'>
                  <div className='login-container'>
                    <div className='login-box' style={{ height: 611 }}>
                      <div className='login-box-right'>
                        <Row>
                          <Col sm='12'>
                            <img className='img-logo-login' src={logo} alt='logo' />
                          </Col>
                        </Row>
                        <br />
                        <br />
                        <Row>
                          <Col sm='12' className={'font-bold title-large pb-3'}>
                            OK, Vamos Começar...
                          </Col>
                        </Row>

                        <br />
                        <Row>
                          <Col sm='12' className={'text-md font-light'}>
                            Preencha os dados para iniciar seus{' '}
                            <strong className='text-uppercase'>10 dias de Teste </strong>
                            <strong>Grátis.</strong>
                            <br />
                            Não será necessário informações do seu cartão de crédito.
                          </Col>
                        </Row>
                        <br />

                        <Row style={{ marginTop: 10, marginBottom: 10 }}>
                          <Col sm={{ size: 8, offset: 2 }}>
                            <Label className={'text-secondary'}>
                              Seu Nome <span> *</span>
                            </Label>
                            <Input name='nome' onChange={handleChange} invalid={errors.nome ? true : false} />
                            <ErrorField errors={errors} fieldName={'nome'} />
                          </Col>
                        </Row>
                        <Row style={{ marginTop: 10, marginBottom: 10 }}>
                          <Col sm={{ size: 8, offset: 2 }}>
                            <Label className={'text-secondary'}>
                              Seu E-mail <span> *</span>
                            </Label>
                            <Input name='email' onChange={handleChange} invalid={errors.email ? true : false} />
                            <ErrorField errors={errors} fieldName={'email'} />
                          </Col>
                        </Row>
                        <Row style={{ marginTop: 10, marginBottom: 10 }}>
                          <Col sm={{ size: 8, offset: 2 }}>
                            <Label className={'text-secondary'}>
                              Telefone Celular <span> *</span>
                            </Label>
                            <MaskedInput
                              id='telefone'
                              name='telefone'
                              guide={false}
                              mask={maskPhoneDefine(values.telefone)}
                              defaultValue={values.telefone}
                              onChange={handleChange}
                              render={(ref, props) => (
                                <Input
                                  autocomplete={'off'}
                                  className='input-secondary'
                                  innerRef={ref}
                                  invalid={errors.telefone ? true : false}
                                  {...props}
                                />
                              )}
                            />
                            <ErrorField errors={errors} fieldName={'telefone'} />
                          </Col>
                        </Row>
                        <Row className={'h-25 align-content-center'}>
                          <Col sm={{ size: 10, offset: 1 }}>
                            <button type='submit' className='button-primary' style={{ width: 230, height: 37 }}>
                              Continuar
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </main>
              </Container>
              <ToastContainer />
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader())
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withRouter);

export default enhanced(StartTrialPage);
