import axios from 'axios';
import accessTokenInRequestInterceptor from './interceptors/accessTokenInRequest.interceptor';
import errorResponseInterceptor from './interceptors/errorResponse.interceptor';
import RestParams from './rest.params';

class RestTemplate {
  restInstance;

  constructor(withAutorization = true, withSecondaryBackend = false) {
    this.restInstance = axios.create({
      baseURL: withSecondaryBackend? RestParams.apiSecondaryBaseUrl : RestParams.apiBaseUrl,
      headers: {
        ...RestParams.base.headers
      },
      timeout: 60000
    });

    errorResponseInterceptor(this.restInstance);

    if (withAutorization) {
      this.authorization();
    }
  }

  authorization = () => {
    accessTokenInRequestInterceptor(this.restInstance);
  };

  set timeout(timeInMills) {
    this.restInstance.defaults.timeout = timeInMills;
  }

  get = async (url, requestParams) => {
    let requestPromisse;

    if (requestParams) {
      requestPromisse = this.restInstance.get(url, {
        params: {
          ...requestParams
        }
      });
    } else {
      requestPromisse = this.restInstance.get(url);
    }

    return requestPromisse;
  };

  post = async (url, body) => {
    return this.restInstance.post(url, body);
  };

  put = async (url, body) => {
    return this.restInstance.put(url, body);
  };

  patch = async (url, body) => {
    return this.restInstance.patch(url, body);
  };

  delete = async (url, body) => {
    return this.restInstance.delete(url, body);
  };

  custom = async (requestConfig) => {
    return this.restInstance(requestConfig);
  };

  get instance() {
    return this.restInstance;
  }
}

export default RestTemplate;
