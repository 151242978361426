import React, { Component } from 'react';

import { Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import minus from '../../../../assets/img/icons/minus.svg';
import plus from '../../../../assets/img/icons/plus.svg';
import Icon from '../../../../components/Icon.component';

import { FieldArray, Form, Formik } from 'formik';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { treatmentEvaluationOpenProblems } from '../redux/TreatmentEvaluation.action';

import { showLoader, hideLoader } from '../../../../components/actions/Loader.actions';
import TreatmentEvaluationApi from '../../../../service/TreatmentEvaluationApi';
import MessagesComponent from '../../../../components/Messages.component';

class TreatmentEvaluatonProblemsDiagnosticModal extends Component {
  state = {
    message: {
      display: false,
      type: 'danger',
      text: ''
    }
  };

  arrayFieldControle;

  saveProblems = async (values) => {
    const {
      showLoader,
      hideLoader,
      updateParent,
      closeModal,
      formLoad: { evaluation }
    } = this.props;
    showLoader();
    try {
      const problems = values.problems.filter((p) => p !== '').map((p) => ({ problema_diagnostico: p }));

      await TreatmentEvaluationApi.saveIncidentsByTretamentId(evaluation.avaliacao_id, problems);
      updateParent();
      closeModal();
    } catch (e) {
      console.log(e);
      this.setState({
        message: {
          display: true,
          type: 'danger',
          text: 'Erro ao salvar lista de lista de problemas/diagnóstico entre em contato com o suporte'
        }
      });
    }
    hideLoader();
  };

  render() {
    const { message } = this.state;
    const { modal = false, closeModal, formLoad = {} } = this.props;
    let { problems = [] } = formLoad;

    if (problems.length === 0) {
      problems.push({ problema_diagnostico: '' });
    }

    return (
      <Modal isOpen={modal} toggle={closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={closeModal}
          close={
            <button className='close' onClick={closeModal}>
              &times;
            </button>
          }
        >
          <strong>Problemas/Diagnóstico</strong>
        </ModalHeader>
        <Formik
          initialValues={{ problems: problems.map((p) => p.problema_diagnostico) }}
          validateOnChange={false}
          onSubmit={this.saveProblems}
        >
          {({ errors, values, handleSubmit, handleChange }) => (
            <Form>
              <MessagesComponent display={message.display} type={message.type} message={message.text} />
              <ModalBody>
                <div className='margin-top-10'>
                  <Row>
                    <Col>
                      <h1 className='title-modal float-left '>Lista de Problemas</h1>
                      <a href={'#'} onClick={() => this.arrayFieldControle.push('')}>
                        <Icon
                          style={{ marginTop: -3 }}
                          className='icon icon-size-two float-left ml-2'
                          image={plus}
                          alt='plus'
                        />
                      </a>
                    </Col>
                  </Row>
                  <FieldArray
                    name='problems'
                    render={(p) => {
                      this.arrayFieldControle = p;
                      return (
                        <div>
                          {values.problems.map((i, index) => (
                            <Row className='margin-top-10'>
                              <Col sm='1'>
                                <label className={'absolute-center'}>{index + 1}.</label>
                              </Col>
                              <Col sm={10}>
                                <Input
                                  name={`problems.${index}`}
                                  onChange={handleChange}
                                  value={values.problems[index]}
                                  className='input-secondary'
                                  type='text'
                                  maxLength={70}
                                ></Input>
                              </Col>
                              <Col sm='1'>
                                <a
                                  href={'#'}
                                  onClick={() => {
                                    p.remove(index);
                                    if (values.problems.length === 1) {
                                      p.push('');
                                    }
                                  }}
                                >
                                  <Icon className='icon icon-size-two absolute-center' image={minus} alt='minus' />
                                </a>
                              </Col>
                            </Row>
                          ))}
                        </div>
                      );
                    }}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <Row>
                  <Col sm={{ size: 3, offset: 6 }}>
                    <button className='button-primary'>Salvar</button>
                  </Col>
                  <Col sm='3'>
                    <button className='button-tertiary' type={'button'} onClick={closeModal}>
                      Cancelar
                    </button>
                  </Col>
                </Row>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = ({ TreatmentEvaluationReducer: { evaluationProblems = {} } } = {}) => {
  return {
    ...evaluationProblems
  };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  closeModal: () => dispatch(treatmentEvaluationOpenProblems({ modal: false }))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(TreatmentEvaluatonProblemsDiagnosticModal);
