// Action Types
import { Sagas } from '../saga/settings_emission_references_sagas';

export const Types = {
  EDIT_MODAL: 'settings_emission_references/Edit'
};

//Reducer
const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.EDIT_MODAL:
      return {
        ...state,
        editarModal: {
          ...action.payload
        }
      };
    default:
      return state;
  }
}

// Actions
export function openSettingsEmissionsSaga(payload) {
  return {
    type: Sagas.SETTINGS_EMISSION_REFERENCES_EDIT,
    payload
  };
}

export function openSettingsEmissionsReferences(formLoad) {
  return {
    type: Types.EDIT_MODAL,
    payload: {
      formLoad
    }
  };
}
