import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import '../../assets/css/Settings.css';
import withSecurity from '../../config/security/security';

class SettingsMenuPage extends Component {
  render() {
    const {
      location: { pathname }
    } = this.props;

    return (
      <div className='content-box-without-color' style={{ marginTop: -50 }}>
        <div className={'settingsMenu'}>
          <Link
            className={'pb-0 ' + (pathname === '/settings/clinic' || pathname === '/settings' ? 'active' : '')}
            to={'/settings/clinic'}
          >
            Clínica
          </Link>
          <Link className={'pb-0 ' + (pathname === '/settings/users' ? 'active' : '')} to={'/settings/users'}>
            Usuários
          </Link>
          <Link className={'pb-0 ' + (pathname === '/settings/schedule' ? 'active' : '')} to={'/settings/schedule'}>
            Agenda
          </Link>
          <Link className={'pb-0 ' + (pathname === '/settings/procedures' ? 'active' : '')} to={'/settings/procedures'}>
            Procedimentos
          </Link>
          <Link className={'pb-0 ' + (pathname === '/settings/libraries' ? 'active' : '')} to={'/settings/libraries'}>
            Bibliotecas
          </Link>
        </div>
      </div>
    );
  }
}

export default withSecurity(SettingsMenuPage);
