import React from 'react';

import PrivateRoute from '../components/PrivateRoute.component';
import SettingsClinicHomePage from '../pages/settings/clinic/SettingsClinicHome.page';
import SettingsMenuPage from '../pages/settings/SettingsMenu.page';
import UserSettingsHomePage from '../pages/settings/users/UserSettingsHome.page';
import SettingsScheduleHomePage from '../pages/settings/schedule/SettingsScheduleHome.page';
import SettingsLibrariesHomePage from '../pages/settings/libraries/SettingsLibrariesHome.page';
import SettingsProceduresHomePage from '../pages/settings/procedures/SettingsProceduresHome.page';

const SettingsRouter = (propsRouter) => {
  return [
    <PrivateRoute
      key='SettingsHome'
      path='/settings'
      render={(props) => <SettingsMenuPage {...props} {...propsRouter} />}
    />,

    <PrivateRoute
      key='SettingClinicHome'
      exact
      path='/settings'
      render={(props) => <SettingsClinicHomePage {...props} {...propsRouter} />}
    />,

    <PrivateRoute
      key='SettingsClinicHome'
      path='/settings/clinic'
      render={(props) => <SettingsClinicHomePage {...props} {...propsRouter} />}
    />,

    <PrivateRoute
      key='SettingsUserHome'
      exact
      path='/settings/users'
      render={(props) => <UserSettingsHomePage {...props} {...propsRouter} />}
    />,

    <PrivateRoute
      key='SettingsScheduleHome'
      exact
      path='/settings/schedule'
      render={(props) => <SettingsScheduleHomePage {...props} {...propsRouter} />}
    />,

    <PrivateRoute
      key='SettingsLibrariesHome'
      exact
      path='/settings/libraries'
      render={(props) => <SettingsLibrariesHomePage {...props} {...propsRouter} />}
    />,

    <PrivateRoute
      key='SettingsProceduresHome'
      exact
      path='/settings/procedures'
      render={(props) => <SettingsProceduresHomePage {...props} {...propsRouter} />}
    />
  ];
};

export default SettingsRouter;
