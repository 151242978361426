import React, { Component } from 'react';

import { Alert, Col, Container, Row, Table } from 'reactstrap';
import { RadioButton, RadioGroup } from 'react-radio-buttons';
import edit from '../../../assets/img/icons/edit.svg';
import Icon from '../../../components/Icon.component';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { hideLoader, showLoader } from '../../../components/actions/Loader.actions';
import { cpfFormat, dateFormat, formatReal } from '../../../utils/Formatter';
import moment from 'moment';
import TreatmentBudgetInformationsModal from './modals/TreatmentBudgetInformations.modal';
import {
  budgetModalTreatmentPaymentClosedPrice,
  budgetPreModalInformations,
  budgetPreModalTreatmentPaymentMantenanceModal,
  budgetPreModalTreatmentPlan
} from './redux/TreatmentBudget.actions';
import TreatmentBudgeTretamentPlanModal from './modals/TreatmentBudgeTretamentPlan.modal';
import TreatmentBudgetApi from '../../../service/TreatmentBudgetApi';
import MessagesComponent from '../../../components/Messages.component';
import TreatmentBudgetPaymentMantenanceModal from './modals/TreatmentBudgetPaymentMantenance.modal';
import TreatmentBudgetPaymentClosedPriceModal from './modals/TreatmentBudgetPaymentClosedPrice.modal';
import TreatmentBudgetOtherNotesModal from './modals/TreatmentBudgetOtherNotes.modal';
import TreatmentBudgetAproveModal from './modals/TreatmentBudgetAprove.modal';
import TreatmentBudgetDeleteModal from './modals/TreatmentBudgetDelete.modal';
import withSecurity from '../../../config/security/security';
import { PERMISSOES } from '../../../config/security/permissoes';
import { IfComponent } from '../../../components/if.component';
import { t } from 'typy';
import ToastUtils from '../../../utils/ToastUtils';
import { formatToRG } from 'brazilian-values';
import danger from '../../../assets/img/icons/danger1.svg';
import { toast, ToastContainer } from 'react-toastify';
import ToastCustomMessageComponent from '../../../components/ToastCustomMessage.component';

class TreatmentBudgetHomePage extends Component {
  state = {
    error: {
      display: false,
      type: 'danger',
      message: ''
    },
    otherNotesModal: false,
    modalBudgetAprove: false,
    paymentModality: '',
    modalBudgetDelete: false
  };

  savePaymentModality = async (modality) => {
    const { showLoader, formLoad = {}, hideLoader, updateParent } = this.props;
    const { budget = {} } = formLoad;

    showLoader();
    try {
      await TreatmentBudgetApi.savePaymentModality({
        orcamento: budget.id,
        modalidade: modality
      });

      await updateParent();
    } catch (e) {
      console.log(e);
      this.setState({
        error: {
          display: true,
          type: 'danger',
          message: 'Erro ao salvar modalidade pagamento, entre em contato com o suporte'
        }
      });
    }

    hideLoader();
  };

  budgetAprove = () => {
    const { formLoad } = this.props;

    const { budget = {}, treatment, evaluation } = formLoad;

    if (t(budget.responsavel).isUndefined) {
      this.props.modalInformations({
        modal: true,
        formLoad: {
          treatment,
          budget,
          submit: true
        }
      });
      ToastUtils.trial(
        <ToastCustomMessageComponent
          iconImage={danger}
          title={'O campo Responsável é Obrigatório !'}
          message={'Preencha o campo, salve e clique em Aprovar Orçamento.'}
          iconClass={'icon-red'}
        />,
        {
          autoClose: 6000,
          hideProgressBar: true,
          position: 'top-center'
        }
      );
    } else if (
      t(budget, 'plano_tratamento.tipo_tratamento').isUndefined ||
      t(budget, 'plano_tratamento.tempo_tratamento_de').isUndefined
    ) {
      this.props.treatmentPlan({
        modal: true,
        formLoad: {
          treatment,
          evaluation,
          budget,
          submit: true
        }
      });
      ToastUtils.trial(
        <ToastCustomMessageComponent
          iconImage={danger}
          title={'Preencha os Campos Obrigatórios assinalados !'}
          message={'Preencha os campos, salve e clique em Aprovar Orçamento.'}
        />,
        {
          autoClose: 6000,
          hideProgressBar: true,
          position: 'top-center'
        }
      );
    } else if (t(budget.pagamento, 'tipo_pagamento').isUndefined) {
      ToastUtils.error('Plano de tratamento é Obrigatório');
    } else {
      this.setState({
        modalBudgetAprove: true,
        paymentModality: budget.pagamento.tipo_pagamento === 'P' ? 'Preço Fechado' : 'Mensalidade / Manutenção'
      });
    }
  };

  budgetDelete = () => {
    this.setState({
      modalBudgetDelete: true
    });
  };

  closeModalBudgetAprove = () => {
    this.setState({ modalBudgetAprove: false });
  };

  closeModalBudgetDelete = () => {
    this.setState({ modalBudgetDelete: false });
  };

  _checkIsDisabledPayment(budget) {
    const { security } = this.props;
    const budgetPermission = PERMISSOES.modules.tratamento_orcamentos;

    if (
      security.hasPermission(budgetPermission.id, budgetPermission.permissoes.editar) ||
      (security.hasPermission(budgetPermission.id, budgetPermission.permissoes.editar_restricao.id) &&
        !security.hasRestriction(
          budgetPermission.id,
          budgetPermission.permissoes.editar_restricao.id,
          budgetPermission.permissoes.editar_restricao.restricoes.nao_alterar_valores
        ))
    ) {
      return budget.status !== 'P';
    } else {
      return true;
    }
  }

  render() {
    const { error, otherNotesModal, modalBudgetAprove, modalBudgetDelete, paymentModality } = this.state;
    const {
      show,
      formLoad = {},
      modalInformations,
      updateParent,
      treatmentPlan,
      treatmentPaymentMantenance,
      treatmentPaymentClosedPrice,
      showLoader,
      hideLoader,
      updateDeleteBudget,
      security
    } = this.props;

    const { budget = {}, treatment, evaluation } = formLoad;
    const { plano_tratamento = {}, pagamento = {} } = budget;
    const { preco_fechado = {}, mensalidade = {} } = pagamento;

    let budgetValidate = moment(budget.data_orcamento);
    budgetValidate.add(budget.validade, 'days');

    const budgetPermission = PERMISSOES.modules.tratamento_orcamentos;
    const hasPermission =
      security.hasPermission(budgetPermission.id, budgetPermission.permissoes.editar) ||
      security.hasPermission(budgetPermission.id, budgetPermission.permissoes.editar_restricao.id);

    return (
      <Container>
        {show && (
          <div>
            <MessagesComponent display={error.display} type={error.type} message={error.message} />
            <Row className='margin-top-10'>
              <Col sm='12'>
                {budget.data_aprovacao_orcamento && (
                  <Alert
                    color='success'
                    className='text-default text-sm font-bold'
                    style={{
                      padding: '12px 25px',
                      marginRight: 20,
                      color: 'var(--green)',
                      borderColor: 'var(--green)'
                    }}
                  >
                    Orçamento Aprovado em {dateFormat(budget.data_aprovacao_orcamento, 'DD/MM/YYYY')}
                  </Alert>
                )}

                <div className='box-default margin-top-10'>
                  <Row>
                    <Col sm='12'>
                      <h2 className='title-medium-plus text-secondary text-uppercase'>
                        Informações do Orçamento
                        {budget.status === 'P' && (
                          <IfComponent conditional={hasPermission}>
                            <a
                              href={'#'}
                              onClick={() => {
                                modalInformations({
                                  modal: true,
                                  formLoad: {
                                    treatment,
                                    budget
                                  }
                                });
                              }}
                            >
                              <Icon className='icon icon-size-small margin-left-10' image={edit} alt='edit' />
                            </a>
                          </IfComponent>
                        )}
                      </h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm='4'>
                      <p className='text-default text-sm mb-2'>
                        <strong>Data do Orçamento</strong>
                        {dateFormat(budget.data_orcamento, 'DD/MM/YYYY')}
                      </p>
                    </Col>
                    <Col sm='8'>
                      <p className='text-default text-sm mb-2'>
                        <strong>Validade do Orçamento</strong>
                        {budget.validade} dias ({dateFormat(budgetValidate, 'DD/MM/YYYY')})
                      </p>
                    </Col>
                    <Col sm='4'>
                      <p className='text-default text-sm'>
                        <strong style={{ float: 'left', marginRight: 5 }}>Comprovante</strong>{' '}
                        {budget.comprovante && (
                          <span className={'voucherOption'}>
                            <span
                              className={'voucherType'}
                              style={{
                                borderColor: budget.comprovante.color,
                                color: budget.comprovante.color
                              }}
                            >
                              {budget.comprovante.sigla}
                            </span>
                          </span>
                        )}
                      </p>
                    </Col>
                    <Col sm='4'>
                      <p className='text-default text-sm'>
                        <strong>RG Paciente</strong> {formatToRG(t(budget.rgPaciente).safeString, 'SP')}
                      </p>
                    </Col>
                    <Col sm='4'>
                      <p className='text-default text-sm'>
                        <strong>CPF Paciente</strong> {cpfFormat(budget.cpfPaciente)}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <hr className='my-2' />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm='4'>
                      <p className='text-default text-sm'>
                        <strong>Responsável</strong> {budget.responsavel}{' '}
                        {budget.tipo_responsavel && '(' + budget.tipo_responsavel.descricao + ')'}
                      </p>
                    </Col>
                    <Col sm='4'>
                      <p className='text-default text-sm'>
                        <strong>RG Responsável</strong> {formatToRG(t(budget.rg).safeString, 'SP')}
                      </p>
                    </Col>
                    <Col sm='4'>
                      <p className='text-default text-sm'>
                        <strong>CPF Responsável</strong> {cpfFormat(budget.cpf)}
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <Row className='margin-top-10'>
              <Col sm='12'>
                <div className='box-default'>
                  <Row>
                    <Col sm='12'>
                      <h2 className='title-medium-plus text-secondary text-uppercase'>
                        Plano de Tratamento
                        {budget.status === 'P' && (
                          <IfComponent conditional={hasPermission}>
                            <a
                              href={'#'}
                              onClick={() =>
                                treatmentPlan({
                                  modal: true,
                                  formLoad: {
                                    treatment,
                                    evaluation,
                                    budget
                                  }
                                })
                              }
                            >
                              <Icon className='icon icon-size-small margin-left-10' image={edit} alt='edit' />
                            </a>
                          </IfComponent>
                        )}
                      </h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm='4'>
                      <p className='text-default text-sm mb-2'>
                        <strong>Tipo de Tratamento / Fase</strong>
                        {plano_tratamento.tipo_tratamento && plano_tratamento.tipo_tratamento.descricao}
                      </p>
                      <p className='text-default text-sm'>
                        <strong>Tempo de Tratamento</strong> &nbsp;
                        {plano_tratamento.tempo_tratamento_de && plano_tratamento.tempo_tratamento_ate ? (
                          <span>
                            {plano_tratamento.tempo_tratamento_de} a {plano_tratamento.tempo_tratamento_ate} meses
                          </span>
                        ) : (
                          <IfComponent conditional={plano_tratamento.tempo_tratamento_de}>
                            <span>{plano_tratamento.tempo_tratamento_de} meses</span>
                          </IfComponent>
                        )}
                      </p>
                    </Col>
                    <Col sm='4'>
                      <p className='text-default text-sm mb-2'>
                        <strong>Plano de Tratamento</strong>
                        {plano_tratamento.plano_tratamento}
                      </p>
                      <p className='text-default text-sm'>
                        <strong>Aparelho / Técnica</strong>
                        {plano_tratamento.aparelho && plano_tratamento.aparelho.descricao}
                      </p>
                    </Col>
                    <Col sm='4'>
                      <p className='text-default text-sm mb-2'>
                        <br />
                      </p>
                      <p className='text-default text-sm'>
                        <strong>Nome Comercial</strong> {plano_tratamento.nome_comercial}
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <Row className='margin-top-10'>
              <Col sm='12'>
                <div className='box-default'>
                  <form className='w-100'>
                    <Row>
                      <Col sm={{ size: 4, offset: 2 }}>
                        <label className='title-medium-plus text-secondary text-uppercase mr-4 pr-4 mt-2 pt-1 float-right'>
                          Modalidade de Pagamento
                        </label>
                      </Col>
                      <Col sm={{ size: 4 }}>
                        <RadioGroup
                          className='ml-3 radio-griks d-flex justify-content-center pt-2'
                          name='mensalidade'
                          value={pagamento.tipo_pagamento ? pagamento.tipo_pagamento : 'M'}
                          horizontal
                          onChange={(e) => this.savePaymentModality(e)}
                        >
                          <RadioButton
                            pointColor={'var(--primary)'}
                            rootColor={'var(--secondary-l3)'}
                            iconSize={20}
                            value='M'
                            disabled={this._checkIsDisabledPayment(budget)}
                            checked={pagamento.tipo_pagamento === 'M'}
                          >
                            <span className='px-3 text-uppercase'>Mensalidade / Manutenção</span>
                          </RadioButton>
                          <RadioButton
                            pointColor={'var(--primary)'}
                            rootColor={'var(--secondary-l3)'}
                            iconSize={20}
                            value='P'
                            disabled={this._checkIsDisabledPayment(budget)}
                            checked={pagamento.tipo_pagamento === 'P'}
                          >
                            <span className='px-5 text-uppercase'>Preço Fechado</span>
                          </RadioButton>
                        </RadioGroup>
                      </Col>

                      {/* <div className="input-radio">

                          <input type="radio" id="mensalidade" name="mensalidade" value="mensalidade"
                                 disabled={ this._checkIsDisabledPayment(budget) }
                                 onChange={ () => {
                                 } }
                                 onClick={ () => this.savePaymentModality('M') }

                                 checked={ pagamento.tipo_pagamento === 'M' }/>
                          <label htmlFor="mensalidade">Mensalidade/Manutenção</label>
                        </div>

                        <div className="input-radio">

                          <input type="radio" id="fechado" name="fechado" value="fechado"
                                 disabled={ this._checkIsDisabledPayment(budget) }
                                 onChange={ () => {
                                 } }
                                 onClick={ () => this.savePaymentModality('P') }
                                 checked={ pagamento.tipo_pagamento === 'P' }/>
                          <label htmlFor="fechado">Preço Fechado</label>
                        </div> */}
                    </Row>
                  </form>
                </div>
              </Col>
            </Row>

            {pagamento.tipo_pagamento === 'P' && (
              <Row>
                <Col sm='12'>
                  <div className='box-default mt-2'>
                    <Row>
                      <Col sm='12'>
                        <h2 className='title-large text-secondary font-bold text-uppercase'>
                          Orçamento - Preço Fechado
                          {budget.status === 'P' && (
                            <IfComponent
                              conditional={
                                security.hasPermission(budgetPermission.id, budgetPermission.permissoes.editar) ||
                                (security.hasPermission(
                                  budgetPermission.id,
                                  budgetPermission.permissoes.editar_restricao.id
                                ) &&
                                  !security.hasRestriction(
                                    budgetPermission.id,
                                    budgetPermission.permissoes.editar_restricao.id,
                                    budgetPermission.permissoes.editar_restricao.restricoes.nao_alterar_valores
                                  ))
                              }
                            >
                              <a
                                href={'#'}
                                onClick={() =>
                                  treatmentPaymentClosedPrice({
                                    modal: true,
                                    formLoad: {
                                      budget,
                                      preco_fechado
                                    }
                                  })
                                }
                              >
                                <Icon className='icon icon-size-small margin-left-10' image={edit} alt='edit' />
                              </a>
                            </IfComponent>
                          )}
                        </h2>
                        <div className='content-overflow-default margin-top-10'>
                          <Row>
                            <Col sm='6'>
                              <span className='text-md-plus font-bold text-secondary d-block text-center'>
                                Preço Fechado - Discriminação
                              </span>
                              <Table className='margin-top-10' responsive>
                                <thead>
                                  <tr>
                                    <th>Procedimento/Custos</th>
                                    <th>Valor R$</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {preco_fechado.categorias_procedimentos &&
                                    preco_fechado.categorias_procedimentos &&
                                    preco_fechado.categorias_procedimentos.map((c) =>
                                      c.procedimentos.map((p) => (
                                        <tr key={p.id}>
                                          <td className='py-2'>{p.descricao}</td>
                                          <td className='py-2'>{formatReal(p.valor_tratamento)}</td>
                                        </tr>
                                      ))
                                    )}
                                </tbody>
                              </Table>
                            </Col>

                            <Col sm='6'>
                              <span className='text-md-plus font-bold text-secondary d-block text-center'>
                                Preço Fechado - Totalização
                              </span>
                              <div className='margin-top-10 text-center'>
                                <Row>
                                  <Col style={{ textAlign: 'right', paddingRight: 7 }}>
                                    <p className='text-default text-sm'>
                                      <strong className='mr-0'>Valor do Tratamento</strong>
                                    </p>
                                  </Col>
                                  <Col
                                    style={{
                                      textAlign: 'left',
                                      paddingLeft: 7
                                    }}
                                  >
                                    {' '}
                                    {formatReal(preco_fechado.valor_total)}{' '}
                                  </Col>
                                </Row>
                                <IfComponent conditional={t(preco_fechado.valor_desconto).isTruthy}>
                                  <Row>
                                    <Col style={{ textAlign: 'right', paddingRight: 7 }}>
                                      <p className='text-default text-sm'>
                                        <strong className='mr-0'>Desconto</strong>
                                      </p>
                                    </Col>
                                    <Col
                                      style={{
                                        textAlign: 'left',
                                        paddingLeft: 7
                                      }}
                                    >
                                      {' '}
                                      {formatReal(preco_fechado.valor_desconto)}
                                    </Col>
                                  </Row>
                                </IfComponent>
                                <IfComponent
                                  conditional={t(preco_fechado.valor_total - preco_fechado.valor_desconto).isTruthy}
                                >
                                  <Row>
                                    <Col style={{ textAlign: 'right', paddingRight: 7 }}>
                                      <p className='text-default text-sm'>
                                        <strong className='mr-0'>Valor com Desconto</strong>
                                      </p>
                                    </Col>
                                    <Col
                                      style={{
                                        textAlign: 'left',
                                        paddingLeft: 7
                                      }}
                                    >
                                      {formatReal(preco_fechado.valor_total - preco_fechado.valor_desconto)}
                                    </Col>
                                  </Row>
                                </IfComponent>
                                <IfComponent conditional={preco_fechado.valor_entrada}>
                                  <Row>
                                    <Col style={{ textAlign: 'right', paddingRight: 7 }}>
                                      <p className='text-default text-sm'>
                                        <strong>Entrada</strong>
                                      </p>
                                    </Col>
                                    <Col
                                      style={{
                                        textAlign: 'left',
                                        paddingLeft: 7
                                      }}
                                    >
                                      {formatReal(preco_fechado.valor_entrada)}
                                    </Col>
                                  </Row>
                                </IfComponent>
                                <IfComponent conditional={preco_fechado.parcelado_em > 0}>
                                  <Row>
                                    <Col style={{ textAlign: 'right', paddingRight: 7 }}>
                                      <p className='text-default text-sm'>
                                        <strong className='mr-0'>Parcelamento</strong>
                                      </p>
                                    </Col>
                                    <Col style={{ textAlign: 'left', paddingLeft: 7 }}>
                                      {!preco_fechado.parcelado_em
                                        ? 1
                                        : preco_fechado.parcelado_em > 1
                                        ? preco_fechado.parcelado_em
                                        : 1}
                                      x {formatReal(preco_fechado.valor_parcela)}
                                    </Col>
                                  </Row>
                                </IfComponent>
                                <Row>
                                  <Col style={{ textAlign: 'right', paddingRight: 7 }}>
                                    <p className='text-default text-sm'>
                                      <strong className='mr-0'>Dia do Vencimento</strong>
                                    </p>
                                  </Col>
                                  <Col
                                    style={{
                                      textAlign: 'left',
                                      paddingLeft: 7
                                    }}
                                  >
                                    {' '}
                                    {preco_fechado.dia_vencimento}
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12}>
                              <Table className='mb-0' responsive>
                                <tr>
                                  <th
                                    className={
                                      'text-default text-secondary text-sm font-bolder border-top border-bottom py-2 text-center bg-white'
                                    }
                                  >
                                    <IfComponent conditional={preco_fechado.parcelado_em > 0}>
                                      Parcelamento&nbsp;
                                      <span className='font-weight-normal pl-lg-2'>
                                        {preco_fechado.valor_entrada && preco_fechado.valor_entrada > 0
                                          ? formatReal(preco_fechado.valor_entrada) + ' +'
                                          : ''}{' '}
                                        {!preco_fechado.parcelado_em
                                          ? 1
                                          : preco_fechado.parcelado_em > 1
                                          ? preco_fechado.parcelado_em
                                          : 1}{' '}
                                        x{' '}
                                        {formatReal(
                                          (preco_fechado.valor_total -
                                            (preco_fechado.valor_desconto ? preco_fechado.valor_desconto : 0) -
                                            (preco_fechado.valor_entrada ? preco_fechado.valor_entrada : 0)) /
                                            preco_fechado.parcelado_em
                                        )}
                                      </span>
                                    </IfComponent>
                                    <IfComponent
                                      conditional={!preco_fechado.parcelado_em || preco_fechado.parcelado_em === 0}
                                    >
                                      Valor do Tratamento&nbsp;
                                      <span className='font-weight-normal pl-lg-2'>
                                        {formatReal(
                                          preco_fechado.valor_total -
                                            (preco_fechado.valor_desconto ? preco_fechado.valor_desconto : 0)
                                        )}
                                      </span>
                                    </IfComponent>
                                  </th>
                                </tr>
                              </Table>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            )}

            {pagamento.tipo_pagamento === 'M' && (
              <Row>
                <Col sm='12'>
                  <div className='box-default mt-2'>
                    <Row>
                      <Col sm='12'>
                        <h2 className='title-medium-plus text-secondary text-uppercase'>
                          Orçamento - Mensalidade/Manutenção
                          <span className='title-small'>
                            {budget.status === 'P' && (
                              <IfComponent
                                conditional={
                                  security.hasPermission(budgetPermission.id, budgetPermission.permissoes.editar) ||
                                  (security.hasPermission(
                                    budgetPermission.id,
                                    budgetPermission.permissoes.editar_restricao.id
                                  ) &&
                                    !security.hasRestriction(
                                      budgetPermission.id,
                                      budgetPermission.permissoes.editar_restricao.id,
                                      budgetPermission.permissoes.editar_restricao.restricoes.nao_alterar_valores
                                    ))
                                }
                              >
                                <a
                                  href={'#'}
                                  onClick={() =>
                                    treatmentPaymentMantenance({
                                      modal: true,
                                      formLoad: {
                                        budget,
                                        mensalidade
                                      }
                                    })
                                  }
                                >
                                  <Icon className='icon icon-size-small margin-left-10' image={edit} alt='edit' />
                                </a>
                              </IfComponent>
                            )}
                          </span>
                        </h2>
                        <div className='content-overflow-default margin-top-10'>
                          <Row>
                            <Col className='text-center' sm='5'>
                              <span className='text-md font-bold text-secondary text-uppercase'>
                                Entrada / Aparelho
                              </span>
                              <Row className='margin-top-10'>
                                <Col style={{ textAlign: 'right', paddingRight: 7 }}>
                                  <p className='text-default text-sm'>
                                    <strong className='mr-0'>Tipo de Aparelho</strong>
                                  </p>
                                </Col>
                                <Col
                                  style={{
                                    textAlign: 'left',
                                    paddingLeft: 7
                                  }}
                                >
                                  {mensalidade.tipo_aparelho && mensalidade.tipo_aparelho.aparelho}
                                </Col>
                              </Row>
                              <Row>
                                <Col style={{ textAlign: 'right', paddingRight: 7 }}>
                                  <p className='text-default text-sm'>
                                    <strong className='mr-0'>Entrada / Aparelho</strong>
                                  </p>
                                </Col>
                                <Col
                                  style={{
                                    textAlign: 'left',
                                    paddingLeft: 7
                                  }}
                                >
                                  {' '}
                                  {formatReal(mensalidade.valor_total_entrada)}
                                </Col>
                              </Row>
                              <IfComponent conditional={mensalidade.valor_desconto}>
                                <Row>
                                  <Col style={{ textAlign: 'right', paddingRight: 7 }}>
                                    <p className='text-default text-sm'>
                                      <strong className='mr-0'>Desconto</strong>
                                    </p>
                                  </Col>
                                  <Col
                                    style={{
                                      textAlign: 'left',
                                      paddingLeft: 7
                                    }}
                                  >
                                    {' '}
                                    {formatReal(mensalidade.valor_desconto)}
                                  </Col>
                                </Row>
                              </IfComponent>
                              <IfComponent conditional={mensalidade.valor_desconto}>
                                <Row>
                                  <Col style={{ textAlign: 'right', paddingRight: 7 }}>
                                    <p className='text-default text-sm'>
                                      <strong className='mr-0'>Valor com Desconto</strong>
                                    </p>
                                  </Col>
                                  <Col
                                    style={{
                                      textAlign: 'left',
                                      paddingLeft: 7
                                    }}
                                  >
                                    {' '}
                                    {formatReal(mensalidade.valor_total_entrada - mensalidade.valor_desconto)}
                                  </Col>
                                </Row>
                              </IfComponent>
                              <IfComponent conditional={mensalidade.parcelado_em > 0}>
                                <Row>
                                  <Col style={{ textAlign: 'right', paddingRight: 7 }}>
                                    <p className='text-default text-sm'>
                                      <strong className='mr-0'>Parcelamento</strong>
                                    </p>
                                  </Col>
                                  <Col
                                    style={{
                                      textAlign: 'left',
                                      paddingLeft: 7
                                    }}
                                  >
                                    {formatReal(mensalidade.valor_entrada)} + {mensalidade.parcelado_em}x{' '}
                                    {formatReal(mensalidade.valor_parcela_entrada)}
                                  </Col>
                                </Row>
                              </IfComponent>
                              <Row>
                                <Col style={{ textAlign: 'right', paddingRight: 7 }}>
                                  <p className='text-default text-sm'>
                                    <strong className='mr-0'>Dia do Vencimento</strong>
                                  </p>
                                </Col>
                                <Col style={{ textAlign: 'left', paddingLeft: 7 }}> {mensalidade.dia_vencimento} </Col>
                              </Row>
                            </Col>

                            <Col className='text-center' sm={{ size: 6, offset: 1 }}>
                              <span className='text-md font-bold text-secondary text-uppercase'>
                                Mensalidade /Manutenção
                              </span>

                              <Row className='margin-top-10'>
                                <Col style={{ textAlign: 'right', paddingRight: 7 }}>
                                  <p className='text-default text-sm'>
                                    <strong className='mr-0'>Mensalidade/Manutenção</strong>
                                  </p>
                                </Col>
                                <Col
                                  style={{
                                    textAlign: 'left',
                                    paddingLeft: 7
                                  }}
                                >
                                  {formatReal(mensalidade.valor_mensalidade)}
                                </Col>
                              </Row>
                              <IfComponent conditional={mensalidade.valor_desconto_mensalidade}>
                                <Row>
                                  <Col style={{ textAlign: 'right', paddingRight: 7 }}>
                                    <p className='text-default text-sm'>
                                      <strong className='mr-0'>Desconto</strong>
                                    </p>
                                  </Col>
                                  <Col
                                    style={{
                                      textAlign: 'left',
                                      paddingLeft: 7
                                    }}
                                  >
                                    {' '}
                                    {formatReal(mensalidade.valor_desconto_mensalidade)}
                                  </Col>
                                </Row>
                              </IfComponent>
                              <IfComponent conditional={mensalidade.valor_desconto_mensalidade}>
                                <Row>
                                  <Col style={{ textAlign: 'right', paddingRight: 7 }}>
                                    <p className='text-default text-sm'>
                                      <strong className='mr-0'>Valor com Desconto</strong>
                                    </p>
                                  </Col>
                                  <Col
                                    style={{
                                      textAlign: 'left',
                                      paddingLeft: 7
                                    }}
                                  >
                                    {' '}
                                    {formatReal(mensalidade.valor_mensalidade - mensalidade.valor_desconto_mensalidade)}
                                  </Col>
                                </Row>
                              </IfComponent>
                              <Row>
                                <Col style={{ textAlign: 'right', paddingRight: 7 }}>
                                  <p className='text-default text-sm'>
                                    <strong className='mr-0'>Quantidade Estimada</strong>
                                  </p>
                                </Col>
                                <Col
                                  style={{
                                    textAlign: 'left',
                                    paddingLeft: 7
                                  }}
                                >
                                  {mensalidade.quantidade_estimada} mensalidades
                                </Col>
                              </Row>
                              <Row>
                                <Col style={{ textAlign: 'right', paddingRight: 7 }}>
                                  <p className='text-default text-sm'>
                                    <strong className='mr-0'>Dia do Vencimento</strong>
                                  </p>
                                </Col>
                                <Col
                                  style={{
                                    textAlign: 'left',
                                    paddingLeft: 7
                                  }}
                                >
                                  {' '}
                                  {mensalidade.dia_vencimento_mensalidade}{' '}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={5}>
                        <Table className='mb-0' responsive>
                          <thead>
                            <tr>
                              <th
                                className={
                                  'text-default text-secondary text-sm font-bolder py-2 bg-white border-top border-bottom'
                                }
                                style={{ textAlign: 'center' }}
                              >
                                {mensalidade.valor_entrada > 0 ? (
                                  <span>
                                    Entrada{' '}
                                    <span className='font-weight-normal pl-lg-2'>
                                      {formatReal(mensalidade.valor_entrada)} + {mensalidade.parcelado_em}x{' '}
                                      {formatReal(mensalidade.valor_parcela_entrada)}
                                    </span>{' '}
                                  </span>
                                ) : (
                                  <span>
                                    Entrada{' '}
                                    <span className='font-weight-normal pl-lg-2'>
                                      {formatReal(mensalidade.valor_total_entrada - (mensalidade.valor_desconto || 0))}
                                    </span>
                                  </span>
                                )}
                              </th>
                            </tr>
                          </thead>
                        </Table>
                      </Col>
                      <Col sm={{ size: 6, offset: 1 }}>
                        <Table className='mb-0' responsive>
                          <thead>
                            <tr>
                              <th
                                className={
                                  'text-default text-secondary text-sm font-bolder py-2 bg-white border-top border-bottom'
                                }
                                style={{ textAlign: 'center' }}
                              >
                                Mensalidades{' '}
                                <span className='font-weight-normal pl-lg-2'>
                                  {formatReal(
                                    mensalidade.valor_mensalidade -
                                      (mensalidade.valor_desconto_mensalidade
                                        ? mensalidade.valor_desconto_mensalidade
                                        : 0)
                                  )}
                                </span>
                              </th>
                            </tr>
                          </thead>
                        </Table>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            )}

            <Row>
              <Col sm='12'>
                <div className={'box-default mt-2'}>
                  <Row>
                    <Col sm='12'>
                      <h2 className='title-medium-plus text-secondary text-uppercase'>
                        Outras Anotações
                        <span className='title-small'>
                          {budget.status === 'P' && (
                            <IfComponent conditional={hasPermission}>
                              <a href={'#'} onClick={() => this.setState({ otherNotesModal: true, budget })}>
                                <Icon className='icon icon-size-small margin-left-10' image={edit} alt='edit' />
                              </a>
                            </IfComponent>
                          )}
                        </span>
                      </h2>
                      <div className='content-overflow-default margin-top-10'>
                        <Row>
                          <Col sm={12} className={'text-default text-sm text-secondary-d4'}>
                            <strong className={'text-sm text-secondary-d4'}>Anotações</strong>
                            {budget.outras_observacoes}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            {budget.status === 'P' && (
              <Row className='margin-top-10'>
                <Col sm={{ size: 4, offset: 8 }} className={'btn-list text-right pr-4'}>
                  <IfComponent
                    conditional={
                      security.hasPermission(budgetPermission.id, budgetPermission.permissoes.editar) ||
                      (security.hasPermission(budgetPermission.id, budgetPermission.permissoes.editar_restricao.id) &&
                        !security.hasRestriction(
                          budgetPermission.id,
                          budgetPermission.permissoes.editar_restricao.id,
                          budgetPermission.permissoes.editar_restricao.restricoes.nao_aprovar
                        ))
                    }
                  >
                    <button className='button-primary' onClick={this.budgetAprove}>
                      Aprovar
                    </button>
                  </IfComponent>
                  <IfComponent
                    conditional={
                      security.hasPermission(budgetPermission.id, budgetPermission.permissoes.editar) ||
                      (security.hasPermission(budgetPermission.id, budgetPermission.permissoes.editar_restricao.id) &&
                        !security.hasRestriction(
                          budgetPermission.id,
                          budgetPermission.permissoes.editar_restricao.id,
                          budgetPermission.permissoes.editar_restricao.restricoes.nao_excluir
                        ))
                    }
                  >
                    <button className='button-cancel' onClick={this.budgetDelete}>
                      Excluir
                    </button>
                  </IfComponent>
                </Col>
              </Row>
            )}
          </div>
        )}

        <TreatmentBudgetInformationsModal updateParent={updateParent} />
        <TreatmentBudgeTretamentPlanModal updateParent={updateParent} />
        <TreatmentBudgetPaymentMantenanceModal updateParent={updateParent} />
        <TreatmentBudgetPaymentClosedPriceModal updateParent={updateParent} />
        <TreatmentBudgetOtherNotesModal
          notes={budget.outras_observacoes}
          modal={otherNotesModal}
          closeModal={() => this.setState({ otherNotesModal: false })}
          updateParent={updateParent}
          budget={budget}
          showLoader={showLoader}
          hideLoader={hideLoader}
        />
        <TreatmentBudgetAproveModal
          updateParent={updateParent}
          budget={budget}
          modal={modalBudgetAprove}
          paymentModality={paymentModality}
          closeModal={this.closeModalBudgetAprove}
          showLoader={showLoader}
          hideLoader={hideLoader}
        />

        <TreatmentBudgetDeleteModal
          updateParent={updateDeleteBudget}
          budget={budget}
          modal={modalBudgetDelete}
          closeModal={this.closeModalBudgetDelete}
          showLoader={showLoader}
          hideLoader={hideLoader}
        />
      </Container>
    );
  }
}

const mapStateToProps = ({ TreatmentBudgetReducer }) => {
  const { page } = TreatmentBudgetReducer;
  return { ...page };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  modalInformations: (data) => dispatch(budgetPreModalInformations(data)),
  treatmentPlan: (data) => dispatch(budgetPreModalTreatmentPlan(data)),
  treatmentPaymentMantenance: (data) => dispatch(budgetPreModalTreatmentPaymentMantenanceModal(data)),
  treatmentPaymentClosedPrice: (data) => dispatch(budgetModalTreatmentPaymentClosedPrice(data))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withSecurity);

export default enhanced(TreatmentBudgetHomePage);
