import t from 'typy';
import { createSelector } from 'reselect';

const financialFileSelector = (state) => t(state, 'TreatmentFinancialFileReducer.closedPaymentModal').safeObject;

export const propsClosedPaymentSelector = createSelector(financialFileSelector, (page = {}) => {
  return {
    closed_price: t(page, 'financialFile.pagamento.preco_fechado').safeObject
  };
});
