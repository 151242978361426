import React, { Component } from 'react';
import warning from '../../../assets/img/icons/warning.svg';
import { Col, Row } from 'reactstrap';
import ModalMessageConfirm from '../../../components/ModalMessageConfirm.component';

import PropTypes from 'prop-types';
import UserAPI from '../../../service/User.service';
import MessagesComponent from '../../../components/Messages.component';
import { Form, Formik } from 'formik';

class TreatmentExclusionConfirm extends Component {
  state = {
    message: {
      display: false,
      type: 'danger',
      text: ''
    }
  };

  confirm = async (values) => {
    try {
      const ok = await UserAPI.checkPassword(values.senha);
      if (ok) {
        this.props.confirmAction();
      } else {
        this.setState({
          message: {
            display: true,
            type: 'danger',
            text: 'Senha inválida'
          }
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        message: {
          display: true,
          type: 'danger',
          text: 'Senha inválida'
        }
      });
    }
  };

  render() {
    const { message } = this.state;
    const { text, title = '', display, confirmAction, cancelAction } = this.props;
    return (
      <Formik enableReinitialize={true} initialValues={{ senha: '' }} validateOnChange={false} onSubmit={this.confirm}>
        {({ values, handleChange, handleSubmit }) => (
          <Form>
            <ModalMessageConfirm
              title={<strong>Excluir Tratamento</strong>}
              size={'md'}
              message={
                <div>
                  <p>
                    <span
                      style={{ fontSize: 14 }}
                      dangerouslySetInnerHTML={{ __html: !text ? `Confirma a Exclusão do Tratamento?` : text }}
                    />
                  </p>
                  <MessagesComponent display={message.display} type={message.type} message={message.text} />
                  <div className='margin-top-10'>
                    <Row>
                      <Col sm={12}>
                        {/* <label>Senha<strong> *</strong></label> */}
                        <form onSubmit={handleSubmit}>
                          <div className='input-float'>
                            <input
                              ref={(input) => input && input.focus()}
                              value={values.senha}
                              className='form-control'
                              type='password'
                              name='senha'
                              placeholder='Senha'
                              onChange={handleChange}
                            />
                            <br />
                            <label htmlFor='senha text-sm font-bold'>Senha</label>
                          </div>
                        </form>
                      </Col>
                    </Row>
                  </div>
                </div>
              }
              openModal={display}
              icon={warning}
              close={cancelAction}
              footer={
                <Row>
                  <Col sm='3'>
                    <button className='button-tertiary' type={'button'} onClick={cancelAction}>
                      Não
                    </button>
                  </Col>
                  <Col sm={{ size: 3, offset: 6 }}>
                    <button className='button-primary' onClick={handleSubmit}>
                      Sim
                    </button>
                  </Col>
                </Row>
              }
            />
          </Form>
        )}
      </Formik>
    );
  }
}

TreatmentExclusionConfirm.propTypes = {
  title: PropTypes.string,
  display: PropTypes.bool.isRequired,
  confirmAction: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired
};

export default TreatmentExclusionConfirm;
