import { takeEvery } from 'redux-saga/effects';
import { findTreatmentFinancialFilePage, treatmentFinancialAccountInfoModal } from './TreatmentFinancialFilePage.saga';
import {
  TREATMENT_FINANCIAL_ACCOUNT_LOAD_MODAL,
  TREATMENT_FINANCIAL_FILE_SAGA_PAGE,
  TREATMENT_FINANCIAL_LAUNCH_OTHER_PAYMENT_SAGA_MODAL,
  TREATMENT_FINANCIAL_LAUNCH_PAYMENT_SAGA_MODAL
} from '../redux/FinancialFile.action';
import { launchPaymentModalSaga } from './LaunchPaymentModal.saga';
import { otherLaunchPaymentModalSaga } from './OtherLaunchPaymentModal.saga';

export function* watchTreatmentFinancialFileSagas() {
  yield takeEvery(TREATMENT_FINANCIAL_FILE_SAGA_PAGE, findTreatmentFinancialFilePage);
  yield takeEvery(TREATMENT_FINANCIAL_ACCOUNT_LOAD_MODAL, treatmentFinancialAccountInfoModal);
  yield takeEvery(TREATMENT_FINANCIAL_LAUNCH_PAYMENT_SAGA_MODAL, launchPaymentModalSaga);
  yield takeEvery(TREATMENT_FINANCIAL_LAUNCH_OTHER_PAYMENT_SAGA_MODAL, otherLaunchPaymentModalSaga);
}
