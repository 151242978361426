import {
  CONTACT_EDIT_SAGA,
  CONTACT_SAVE_SAGA,
  CONTACTS_FIND_ALL,
  CONTACTS_RESULT,
  contactsShowHideSave,
  findAllContacts
} from '../redux/contacts.actions';
import { sendMessage } from '../../../components/actions/ErrorMessage.actions';
import { call, put, takeEvery, delay } from 'redux-saga/effects';
import ContactsAPI from '../../../service/ContactsAPI';
import { hideLoader } from '../../../components/actions/Loader.actions';

export function* watchFindAllContactsSaga() {
  yield takeEvery(CONTACTS_FIND_ALL, findAll);
}

export function* watchSaveContactsSaga() {
  yield takeEvery(CONTACT_SAVE_SAGA, saveContact);
}

export function* watchEditContactsSaga() {
  yield takeEvery(CONTACT_EDIT_SAGA, editContact);
}

export function* editContact(state) {
  try {
    const { data } = state.contactEdit;
    const contact = yield call(ContactsAPI.findById, data.id);
    console.log('contato', contact);
    yield put(hideLoader());
    yield put(contactsShowHideSave({ open: true, contact: contact.data }));
  } catch (e) {
    console.log(e);
    yield put(hideLoader());
    yield put(
      sendMessage({
        where: 'contacthome',
        display: true,
        type: 'danger',
        message: 'Erro ao buscar contato'
      })
    );
    yield call(delay, 5000);
    yield put(
      sendMessage({
        where: 'contacthome',
        display: false
      })
    );
  }
}

export function* findAll(state) {
  try {
    const { page, totalPage } = state.data;
    const { data, status } = yield ContactsAPI.findAll(page, totalPage);

    yield put({
      type: CONTACTS_RESULT,
      payload: data
    });

    yield put(hideLoader());
  } catch (e) {
    console.log(e);
    yield put({
      type: CONTACTS_RESULT,
      payload: []
    });
    yield put(hideLoader());
  }
}

export function* saveContact(state) {
  try {
    const { contactSave } = state;
    const { data } = contactSave;
    let contact = {};

    if (data.id) {
      contact = yield call(ContactsAPI.updateContact, data);
    } else {
      contact = yield call(ContactsAPI.insertContact, data);
    }

    if (data.imageUpload.image) {
      yield call(ContactsAPI.uploadImage, {
        id: contact.data.id,
        image: data.imageUpload.image.replace(/\bdata:.*,/g, ''),
        extension: data.imageUpload.extension.replace('.', '')
      });
    }
    //mandar mensagem
    yield put(contactsShowHideSave({ open: false, contact: {} }));
    yield put(findAllContacts({ page: 1, totalPage: 10 }));
    yield put(hideLoader());
    yield call(delay, 5000);
    yield put(
      sendMessage({
        where: 'contacthome',
        display: false
      })
    );
  } catch (e) {
    console.log(e);
    yield put(hideLoader());
    yield put(
      sendMessage({
        where: 'contactSave',
        display: true,
        type: 'danger',
        message: 'Erro ao salvar contato'
      })
    );
    yield call(delay, 5000);
    yield put(
      sendMessage({
        where: 'contactSave',
        display: false
      })
    );
  }
}
