import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import PropTypes from 'prop-types';
import _ from 'lodash';

const PaginationComponent = (props) => {
  const { selectedPage, totalPage, activePage } = props;
  let startPage = 1;
  let totalPageView = 11;

  if (totalPage > 11) {
    if (totalPageView / activePage <= 1.5) {
      startPage = activePage - 5;
    }

    totalPageView = startPage + 10;

    if (totalPageView > totalPage) {
      totalPageView = totalPage + 1;
    }

    startPage = Math.floor(totalPageView / 2) - 4;

    if (startPage < 1) {
      startPage = 1;
    }
  } else {
    totalPageView = totalPage + 1;
  }

  return (
    <div className='pagination-primary margin-top-20'>
      <Pagination aria-label='Page navigation'>
        <PaginationItem
          disabled={activePage <= 1}
          onClick={() => {
            if (activePage > 1) {
              selectedPage(activePage - 1);
            }
          }}
        >
          <PaginationLink previous href='#' />
        </PaginationItem>
        {_.range(startPage, totalPageView).map((p) => (
          <PaginationItem key={p} active={activePage === p}>
            <PaginationLink href='#' onClick={() => selectedPage(p)}>
              {p}
            </PaginationLink>
          </PaginationItem>
        ))}
        <PaginationItem
          disabled={activePage >= totalPage}
          onClick={() => {
            if (activePage < totalPage) {
              selectedPage(activePage + 1);
            }
          }}
        >
          <PaginationLink next href='#' />
        </PaginationItem>
      </Pagination>
    </div>
  );
};

PaginationComponent.propTypes = {
  selectedPage: PropTypes.func.isRequired,
  totalPage: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired
};

export default PaginationComponent;
