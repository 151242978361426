import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import Select from 'react-select';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { showLoader, hideLoader } from '../../../../components/actions/Loader.actions';
import MessagesComponent from '../../modals/TreatmentConfirm.modal';
import { Form, Formik } from 'formik';
import TreatmentEvaluationApi from '../../../../service/TreatmentEvaluationApi';
import { treatmentEvaluationModalOrthoDocs } from '../redux/TreatmentEvaluation.action';

class TreatmentOrthoDocsModal extends Component {
  state = {
    message: {
      display: false,
      type: 'danger',
      text: ''
    }
  };

  saveOrthoDocs = async (values) => {
    const { formLoad, showLoader, hideLoader, closeModal, updateParent } = this.props;
    showLoader();
    try {
      await TreatmentEvaluationApi.saveEvaluationDocs({
        avaliacao: formLoad.evaluation.avaliacao_id,
        documentos_existentes: values.existente.map((d) => d.value),
        documentos_solicitados: values.solicitadas.map((d) => d.value)
      });
    } catch (e) {
      console.log(e);
      this.setState({
        message: {
          display: true,
          type: 'danger',
          text: 'Erro ao salvar documentação ortodôntica entre em contato com o suporte'
        }
      });
    }
    updateParent();
    closeModal();
    hideLoader();
  };

  selectOption = (data, handleChange, name) => {
    handleChange({
      target: {
        name: name,
        value: data
      }
    });
  };

  render() {
    const { message } = this.state;
    const { modal, closeModal, docsOptions, formInit } = this.props;
    return (
      <Modal isOpen={modal} toggle={closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={closeModal}
          close={
            <button className='close' onClick={closeModal}>
              &times;
            </button>
          }
        >
          <strong>Editar</strong> Documentação Ortodôntica
        </ModalHeader>
        <Formik initialValues={formInit} validateOnChange={false} onSubmit={this.saveOrthoDocs}>
          {({ error, values, handleChange }) => (
            <Form>
              <ModalBody>
                <MessagesComponent display={message.display} type={message.type} message={message.text} />
                <div className='margin-top-10'>
                  <Row className='margin-top-10'>
                    <Col sm='12'>
                      <label>Doc Existente</label>
                    </Col>
                    <Col sm={12}>
                      <Select
                        value={values.existente}
                        isMulti
                        isSearchable={true}
                        ignoreAccents={true}
                        ignoreCase={true}
                        options={docsOptions}
                        placeholder={''}
                        onChange={(data) => this.selectOption(data, handleChange, 'existente')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm='12'>
                      <label>Doc Solicitado</label>
                    </Col>
                    <Col sm={12}>
                      <Select
                        value={values.solicitadas}
                        isMulti
                        isSearchable={true}
                        ignoreAccents={true}
                        ignoreCase={true}
                        options={docsOptions}
                        placeholder={''}
                        onChange={(data) => this.selectOption(data, handleChange, 'solicitadas')}
                      />
                    </Col>
                  </Row>
                </div>
              </ModalBody>
              <ModalFooter>
                <Row>
                  <Col sm={{ size: 3, offset: 6 }}>
                    <button className='button-primary'>Salvar</button>
                  </Col>
                  <Col sm='3'>
                    <button className='button-tertiary' type={'button'} onClick={closeModal}>
                      Cancelar
                    </button>
                  </Col>
                </Row>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = ({ TreatmentEvaluationReducer: { evaluationOrthoDocs = {} } } = {}) => {
  const { formLoad: { formData = {}, docsForm = {} } = {} } = evaluationOrthoDocs;
  const { docs = [] } = formData;
  const docsOptions = docs.map((d) => ({ value: d.id, label: d.descricao }));
  const formInit = {
    existente:
      docsForm.existente && docsForm.existente.map((d) => docsOptions.find((dop) => dop.value === d.documentacoes.id)),
    solicitadas:
      docsForm.solicitada && docsForm.solicitada.map((d) => docsOptions.find((dop) => dop.value === d.documentacoes.id))
  };

  return { ...evaluationOrthoDocs, docsOptions, formInit };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  closeModal: () => dispatch(treatmentEvaluationModalOrthoDocs({ modal: false }))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(TreatmentOrthoDocsModal);
