import RestTemplate from '../utils/restTemplate/rest.template';

function getTemplate(authentication) {
  return new RestTemplate(authentication);
}

export function login(data) {
  const restTemplate = getTemplate(false);
  const { login, senha } = data;

  const postData = {
    username: login,
    password: senha
  };

  return restTemplate.post('/login', JSON.stringify(postData)).then((response) => response.data);
}

export function forgotPassword(data) {
  const restTemplate = getTemplate(false);
  const { email } = data;

  return restTemplate.get(`/pwd/reset/${email}`);
}

export function resetPasswordValidate(token) {
  return getTemplate(false).get(`/pwd/reset/${token}/validate`);
}

export function resetPassword(data) {
  const rest = getTemplate(false);

  const postData = {
    password: data.password,
    token: data.token
  };

  return rest.post('/pwd/reset', JSON.stringify(postData));
}

export function changePassword({ current, newPWD }) {
  return getTemplate(true).post('/updatePassword', {
    currentPWD: current,
    newPWD
  });
}
