import React, { Component } from 'react';

import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import edit from '../../../assets/img/icons/edit.svg';
import Icon from '../../../components/Icon.component';
import Select from 'react-select';
import { reactSelectHandleChange, reactSelectStyleIsError } from '../../../utils/ReactSelectUtils';
import ErrorFieldWithoutBootstrap from '../../../components/ErrorFieldWithoutBootstrap.component';
import { IfComponent } from '../../../components/if.component';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { scheduleRedialModalToProps } from '../selectors/AddListScheduleRedial.selector';
import { scheduleAndRedialAdd } from '../redux/Schedule.actions';
import PacienteAPI from '../../../service/PacienteAPI';
import SugestionBoxComponent from '../../../components/SuggestionBoxComponent';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import Radium, { StyleRoot } from 'radium';
import { fadeIn } from 'react-animations';
import noavatar from '../../../assets/img/noavatar.png';
import { Form, Formik } from 'formik';
import ProceduresAPI from '../../../service/ProceduresAPI';
import { hideLoader, showLoader } from '../../../components/actions/Loader.actions';
import ScheduleAPI from '../../../service/ScheduleAPI';
import { t } from 'typy';
import * as Yup from 'yup';
import MessagesComponent from '../../../components/Messages.component';
import { selectStyles } from '../../../../src/config/core.config';

class AddListScheduleRedialModal extends Component {
  initalState = {
    suggestionsData: [],
    value: '',
    patientSelected: null,
    message: {
      display: false,
      text: ''
    },
    changeProcedure: false,
    groups: [],
    procedures: [],
    clinicSettings: {},
    othersErrors: {}
  };

  state = {
    ...this.initalState
  };

  validateSchema = Yup.object().shape({
    patient: Yup.object().required('Paciente Obrigatório'),
    reason: Yup.object().shape({
      value: Yup.string().required('Motivo Obrigatório')
    })
  });

  closeModal = () => {
    const { closeModal } = this.props;
    closeModal();
    this.setState({ ...this.initalState });
  };

  selectedPatient = (patientSelected) => {
    this.setState({ patientSelected });
  };

  renderSuggestion(suggestion, { query }) {
    const suggestionText = `${suggestion.name}`;
    const matches = AutosuggestHighlightMatch(suggestionText, query);
    const parts = AutosuggestHighlightParse(suggestionText, matches);
    if (suggestion.id) {
      return (
        <span className={'suggestion-content'}>
          <img src={suggestion.image} style={{ maxWidth: 62, maxHeight: 62 }} alt='' />
          <span className='name'>
            {parts.map((part, index) => {
              const className = part.highlight ? 'highlight' : null;

              return (
                <span className={className} key={index}>
                  {part.text}
                </span>
              );
            })}
          </span>
        </span>
      );
    } else {
      return (
        <span className={'suggestion-content'}>
          <span className='name'>
            <span className={''}>
              {'Nenhum paciente encontrado com o nome'} {<span className='highlight'>({query})</span>}
            </span>
          </span>
        </span>
      );
    }
  }

  renderSuggestionsContainer({ containerProps, children, query }) {
    return (
      <StyleRoot>
        <div
          {...containerProps}
          style={{
            animation: 'x 0.9s',
            animationName: Radium.keyframes(fadeIn, 'fadeIn')
          }}
        >
          {children}
        </div>
      </StyleRoot>
    );
  }

  findPatients = async (searchTerm) => {
    if (searchTerm.length > 2) {
      try {
        const p = await PacienteAPI.pescInc(searchTerm);
        if (p.length === 0) {
          p.push({
            nome: 'Nenhum paciente encontrado'
          });
        }

        this.setState({
          suggestionsData: p.map((pt) => {
            return {
              id: pt.id,
              name: pt.nome,
              image: pt.foto || noavatar
            };
          })
        });
      } catch (err) {
        this.setState({
          suggestionsData: []
        });
      }
    } else {
      this.setState({
        suggestionsData: []
      });
    }
  };

  renderInputComponent = (inputProps) => (
    <div className=''>
      <input
        {...inputProps}
        className='input-secondary'
        type='text'
        name='firstname'
        autoComplete='off'
        data-lpignore='true'
        spellCheck='false'
      />
    </div>
  );

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  saveInList = (values) => {
    const { changeProcedure, clinicSettings = {} } = this.state;

    if (changeProcedure && t(values, 'procedure.value').isUndefined) {
      this.setState({
        othersErrors: { procedure: 'Procedimento obrigatório' }
      });
    } else if (!clinicSettings.procedimento_id) {
      this.setState({
        message: {
          display: true,
          type: 'danger',
          text: 'Por favor selecione um procedimento'
        }
      });
      this.setState({ changeProcedure: true });
    } else {
      const { clinicSettings = {}, changeProcedure } = this.state;
      const { dentist, showLoader, hideLoader, updateParent } = this.props;
      showLoader();
      ScheduleAPI.addScheduleRedialList({
        paciente: values.patient.id,
        procedimento: changeProcedure ? values.procedure.value : clinicSettings.procedimento_id,
        dentista: dentist.value,
        observacao: values.observation,
        motivo: values.reason.value
      })
        .then((d) => {
          hideLoader();
          updateParent();
          this.closeModal();
        })
        .catch((err) => {
          console.error(err);
          this.setState({
            message: {
              display: true,
              type: 'danger',
              text: 'Erro ao salvar paciente na lista de agendar e reagendar'
            }
          });
          hideLoader();
        });
    }

    console.log(values);
  };

  changeProcedures = (values) => {
    const { showLoader } = this.props;

    showLoader();
    this.findGroups();
    this.setState({ changeProcedure: true });
  };

  findGroups() {
    const { hideLoader } = this.props;

    ProceduresAPI.getAllCategories()
      .then(({ data }) => {
        this.setState({ groups: data.map((g) => ({ label: g.nome, value: g.id })) });
        hideLoader();
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          message: {
            display: true,
            type: 'danger',
            text: 'Erro ao buscar categorias de procedimentos'
          }
        });
        hideLoader();
      });
  }

  findProcedures = (group) => {
    const { showLoader, hideLoader } = this.props;
    showLoader();
    ProceduresAPI.getAllProcedures(group, true)
      .then(({ data }) => {
        const procedures = data.map((p) => ({
          label: p.nome,
          value: p.id
        }));
        this.setState({ procedures });
        hideLoader();
      })
      .catch((c) => {
        hideLoader();
        this.setState({
          message: {
            display: true,
            type: 'danger',
            text: 'Erro ao buscar procedimentos entre em contato com o suporte'
          }
        });
      });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (t(this.props, 'dentist.value').safeNumber !== t(nextProps, 'dentist.value').safeNumber) {
      const { showLoader, hideLoader } = this.props;

      showLoader();
      if (t(nextProps, 'dentist.value').isDefined) {
        ScheduleAPI.config(nextProps.dentist.value).then(({ data }) => {
          hideLoader();
          this.findProcedures(data.procedimento_categoria_id);
          this.setState({ clinicSettings: data });
        });
      } else {
        hideLoader();
        this.setState({ clinicSettings: {} });
      }
    }
    return true;
  }

  render() {
    const { modal, reasons, patient = {} } = this.props;
    const {
      value,
      suggestionsData,
      groups = [],
      procedures,
      clinicSettings = {},
      changeProcedure,
      othersErrors,
      message
    } = this.state;
    console.log(clinicSettings);

    const inputProps = {
      placeholder: '',
      value,
      onChange: this.onChange
    };

    return (
      <Modal isOpen={modal} toggle={this.closeModal} size='default' className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={this.closeModal}
          close={
            <button className='close' onClick={this.closeModal}>
              &times;
            </button>
          }
        >
          <strong>
            Inserir na Lista <span className='text-uppercase'>Agendar / Reagendar</span>
          </strong>
        </ModalHeader>
        <Formik
          initialValues={{ patient }}
          enableReinitialize={true}
          validationSchema={this.validateSchema}
          validateOnChange={false}
          onSubmit={this.saveInList}
        >
          {({ values, errors, handleChange }) => {
            return (
              <Form>
                <ModalBody>
                  <MessagesComponent display={message.display} type={'danger'} message={message.text} />
                  <div className='margin-top-10'>
                    <Row>
                      <Col className='text-left' sm='12'>
                        <label>
                          Paciente <span>*</span>
                        </label>
                      </Col>
                      <Col sm={12}>
                        <form autoComplete='off'>
                          <SugestionBoxComponent
                            renderSuggestionsContainer={this.renderSuggestionsContainer}
                            renderSuggestion={this.renderSuggestion}
                            renderInputComponent={(props) =>
                              this.renderInputComponent({
                                ...props,
                                style: {
                                  borderColor: errors.patient ? 'red' : ''
                                }
                              })
                            }
                            findSuggestions={this.findPatients}
                            selectedSuggestion={(patient) => {
                              handleChange({
                                target: {
                                  name: 'patient',
                                  value: patient
                                }
                              });
                            }}
                            suggestionsData={suggestionsData}
                            inputProps={inputProps}
                          />
                        </form>
                        <ErrorFieldWithoutBootstrap fieldName={'patient'} errors={errors} />
                      </Col>
                    </Row>
                  </div>
                  <div className='margin-top-10'>
                    <Row>
                      <Col sm='12'>
                        <label>
                          Motivo<span>*</span>
                        </label>
                      </Col>
                      <Col sm='12'>
                        <Select
                          options={reasons}
                          ignoreCase={true}
                          placeholder={''}
                          onChange={(e) => reactSelectHandleChange(e, handleChange, 'reason')}
                          styles={reactSelectStyleIsError(errors, 'reason.value', selectStyles)}
                        />
                        <ErrorFieldWithoutBootstrap fieldName={'reason.value'} errors={errors} />
                      </Col>
                    </Row>
                  </div>
                  <div className='margin-top-10'>
                    <IfComponent conditional={!changeProcedure}>
                      <Row className='pb-0'>
                        <Col className='text-left' sm='12'>
                          <p className='mb-0'>
                            <strong className='text-sm font-bold'>Procedimento</strong>
                            <span
                              className='procedure-primary badge-proc mr-2 ml-2'
                              style={{
                                backgroundColor: clinicSettings.procedimento_categoria_rgb,
                                color: '#454c5e',
                                fontSize: 13
                              }}
                            >
                              {clinicSettings.procedimento_codinome}
                            </span>
                            <a href={'#'} onClick={() => this.changeProcedures(values)}>
                              <Icon className='icon icon-size-small' image={edit} alt='edit' />
                            </a>
                          </p>
                        </Col>
                      </Row>
                    </IfComponent>
                    <IfComponent conditional={changeProcedure}>
                      <Row className='margin-top-10'>
                        <Col sm='6'>
                          <label>
                            Grupo<span>*</span>
                          </label>
                        </Col>
                        <Col sm='6'>
                          <label>Procedimento</label>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm='6'>
                          <Select
                            value={values.group}
                            options={groups}
                            placeholder=''
                            onChange={(c) => {
                              reactSelectHandleChange(c, handleChange, 'group');
                              reactSelectHandleChange({}, handleChange, 'procedure');
                              this.findProcedures(c.value);
                            }}
                            styles={selectStyles}
                          />
                        </Col>
                        <Col sm='6'>
                          <Select
                            value={values.procedure}
                            options={procedures}
                            placeholder=''
                            onChange={(p) => reactSelectHandleChange(p, handleChange, 'procedure')}
                            styles={reactSelectStyleIsError(othersErrors, 'procedure', selectStyles)}
                          />
                          <ErrorFieldWithoutBootstrap fieldName={'procedure'} errors={othersErrors} />
                        </Col>
                      </Row>
                    </IfComponent>
                  </div>
                  <div className='margin-top-10'>
                    <Row>
                      <Col sm='12'>
                        <form>
                          <label>Observações</label>
                          <textarea
                            rows='1'
                            cols='50'
                            className='form-control'
                            name={'observation'}
                            onChange={handleChange}
                          ></textarea>
                        </form>
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Row>
                    <Col className='btn-list' sm={{ size: 6, offset: 6 }}>
                      <button className='button-tertiary btn-md float-right' type={'button'} onClick={this.closeModal}>
                        Cancelar
                      </button>
                      <button className='button-primary btn-md float-right mr-3'>Salvar</button>
                    </Col>
                  </Row>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...scheduleRedialModalToProps(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  closeModal: () => dispatch(scheduleAndRedialAdd({ modal: false }))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(AddListScheduleRedialModal);
