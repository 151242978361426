export const SETTINGS_LIBRARIES_REGISTER_MODAL = 'SETTINGS_LIBRARIES_REGISTER_MODAL';

export function registerModal({ modal, formData }) {
  return {
    type: SETTINGS_LIBRARIES_REGISTER_MODAL,
    payload: {
      modal,
      formData
    }
  };
}
