import {
  TREATMENT_BUDGET_CREATE_MODAL,
  TREATMENT_BUDGET_MODAL_INFORMATIONS,
  TREATMENT_BUDGET_MODAL_PAYMENT_CLOSED_PRICE,
  TREATMENT_BUDGET_MODAL_PAYMENT_MANTENANCE,
  TREATMENT_BUDGET_MODAL_TREATMENT_PLAN,
  TREATMENT_BUDGET_PAGE
} from './TreatmentBudget.actions';

const initialState = {};

const TreatmentBudgetReducer = (state = initialState, action) => {
  switch (action.type) {
    case TREATMENT_BUDGET_CREATE_MODAL: {
      return {
        ...state,
        createModal: {
          ...action.payload
        }
      };
    }
    case TREATMENT_BUDGET_PAGE: {
      return {
        ...state,
        page: {
          ...action.payload
        }
      };
    }
    case TREATMENT_BUDGET_MODAL_INFORMATIONS: {
      return {
        ...state,
        information: {
          ...action.payload
        }
      };
    }
    case TREATMENT_BUDGET_MODAL_TREATMENT_PLAN: {
      return {
        ...state,
        treatmentPlan: {
          ...action.payload
        }
      };
    }
    case TREATMENT_BUDGET_MODAL_PAYMENT_MANTENANCE: {
      return {
        ...state,
        paymentMantenance: {
          ...action.payload
        }
      };
    }
    case TREATMENT_BUDGET_MODAL_PAYMENT_CLOSED_PRICE: {
      return {
        ...state,
        paymentClosedPrice: {
          ...action.payload
        }
      };
    }
    default: {
      return state;
    }
  }
};

export default TreatmentBudgetReducer;
