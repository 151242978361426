import env from '../../environment/env';

const RestParams = {
  base: {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  },
  apiBaseUrl: env.api.baseURL,
  apiSecondaryBaseUrl: env.api.baseSecondaryURL
};

export default {
  ...RestParams
};
