import { call, put, takeEvery } from '@redux-saga/core/effects';
import UserAPI from '../service/User.service';
import { hideLoader, showLoader } from '../components/actions/Loader.actions';

export const Sagas = {
  LOAD_DENTISTS: 'dentists/store/sagas/load'
};

export function* watchSharedDentits() {
  yield takeEvery(Sagas.LOAD_DENTISTS, loadDentistsSaga);
}

function* loadDentistsSaga() {
  yield put(showLoader());
  const dentistsResponse = yield call(UserAPI.findAllDentistas);
  yield put(sendDentsts(dentistsResponse));
  yield put(hideLoader());
}

// Reducer
export const Types = {
  SEND_DENTISTS_LOADED: 'dentists/shared/store/SEND_DENTISTS_LOADED'
};

// Reducer
const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.SEND_DENTISTS_LOADED:
      return {
        ...state,
        dentists: {
          ...action.payload
        }
      };
    default:
      return state;
  }
}

// Actions
export function loadDentists() {
  return {
    type: Sagas.LOAD_DENTISTS,
    payload: {}
  };
}

export function sendDentsts(dentists) {
  return {
    type: Types.SEND_DENTISTS_LOADED,
    payload: {
      dentists
    }
  };
}
