import React, { Component } from 'react';

import { Col, Container, Row } from 'reactstrap';
import edit from '../../../assets/img/icons/edit.svg';
import Icon from '../../../components/Icon.component';

import { connect } from 'react-redux';
import { compose } from 'recompose';

import { contactsDetail, contactsEdit, findAllContacts } from '../redux/contacts.actions';
import { phoneFormat } from '../../../utils/Formatter';
import noavatar from '../../../assets/img/noavatar.png';
import { IfComponent } from '../../../components/if.component';
import { PERMISSOES } from '../../../config/security/permissoes';
import withSecurity from '../../../config/security/security';

export class ContactsListComponent extends Component {
  state = {
    activePage: 1,
    totalPage: 10
  };

  componentDidMount = () => {
    const { activePage, totalPage } = this.state;
    this.props.showLoader();
    this.props.findAllContacts({ page: activePage, totalPage });
  };

  selectedPage = (page) => {
    this.setState({ activePage: page });
    this.props.findAllContacts({ page, totalPage: this.state.totalPage });
  };

  edit = (e, contact) => {
    e.stopPropagation();
    this.props.showLoader();
    this.props.contactsEdit(contact);
  };

  render() {
    const { activePage } = this.state;
    const { contacts = [], totalPages = 0, showDetail, security } = this.props;

    const modulePermission = PERMISSOES.modules.contatos;

    return (
      <Container className=''>
        <Row>
          {contacts.map((c) => {
            const { foto, titulo = '', nome, categoria, telefone_one = '', telefone_two = '' } = c;
            return (
              <Col sm='12' md='6' key={c.id}>
                <div className='link' onClick={() => showDetail(c)}>
                  <div className='box-search'>
                    <Row>
                      <Col sm='2'>
                        <div className='img-user-search'>
                          <img className='img-circle img-ldpi-plus' src={foto || noavatar} alt='usuario' />
                        </div>
                      </Col>
                      <Col sm='7'>
                        <div className='box-search-info vertical-center backs'>
                          <span className='title-small'>
                            <strong>{`${titulo} ${nome}`}</strong>
                          </span>
                          <p>{categoria.description}</p>
                        </div>
                      </Col>
                      <Col sm='2'>
                        <div className='box-search-phones vertical-center'>
                          <p>{phoneFormat(telefone_one)}</p>
                          <p>{phoneFormat(telefone_two)}</p>
                        </div>
                      </Col>
                      <Col sm='1'>
                        <IfComponent
                          conditional={
                            security.hasPermission(modulePermission.id, modulePermission.permissoes.editar) ||
                            security.hasPermission(modulePermission.id, modulePermission.permissoes.editar_restricao.id)
                          }
                        >
                          <a href='#' onClick={(e) => this.edit(e, c)}>
                            <Icon className='icon icon-size-small icon-box-edit' image={edit} alt='edit' />
                          </a>
                        </IfComponent>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>

        {/*<Row>*/}
        {/*  <Col sm={ { size: 2, offset: 5 } }>*/}
        {/*    <div className="pagination-primary margin-top-20">*/}
        {/*      <PaginationComponent*/}
        {/*        selectedPage={ this.selectedPage }*/}
        {/*        totalPage={ totalPages }*/}
        {/*        activePage={ activePage }*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </Col>*/}
        {/*</Row>*/}
      </Container>
    );
  }
}

const mapStateToProps = ({ ContactsReducer = {} }) => {
  return {
    contacts: ContactsReducer.data,
    totalPages: ContactsReducer.totalPages
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    findAllContacts: (data) => dispatch(findAllContacts(data)),
    showDetail: (data) => dispatch(contactsDetail({ open: true, contact: data })),
    contactsEdit: (data) => dispatch(contactsEdit(data))
  };
};

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withSecurity);

export default enhanced(ContactsListComponent);
