import React, { Component } from 'react';

import { Col, Input, ModalBody, ModalFooter, Row, UncontrolledTooltip } from 'reactstrap';

import noavatar from '../../../assets/img/noavatar.png';
import info from '../../../assets/img/icons/help.svg';
import help from '../../../assets/img/icons/help.svg';
import Icon from '../../../components/Icon.component';
import MaskedInput from 'react-text-mask';
import { Formik } from 'formik';
import AvatarModal from '../../../components/Avatar.modal';
import SimpleSuggestionBoxComponent from '../../../components/SimpleSuggestionBox.component';

import * as Yup from 'yup';
import DomainsAPI from '../../../service/DomainsAPI';
import ErrorField from '../../../components/ErrorField.component';
import { IfComponent } from '../../../components/if.component';
import whats from '../../../assets/img/icons/whats.svg';
import InputMaskComponent from '../../../components/InputMask.component';
import { maskCPF } from '../../../utils/Formatter';
import ErrorFieldWithoutBootstrap from '../../../components/ErrorFieldWithoutBootstrap.component';
import { isCPF } from 'brazilian-values';
import { t } from 'typy';
import { formatDate, parseDate } from '../../../utils/DateUtils';
import ToastUtils from '../../../utils/ToastUtils';
import ToastCustomMessageComponent from '../../../components/ToastCustomMessage.component';
import danger from '../../../assets/img/icons/danger1.svg';
import { reactSelectHandleChange, reactSelectStyleIsError } from '../../../utils/ReactSelectUtils';
import { selectStyles } from '../../../config/core.config';
import Select from 'react-select';
import { IoLogoWhatsapp } from 'react-icons/io';
import moment from 'moment';

export class PatientEditModalForm extends Component {
  state = {
    tooltipOpen: false,
    displayAvatarModal: false,
    responsibleTypes: [],
    typeIndication: [],
    uploadFoto: {},
    indicationTypeId: null,
    indicatorSuggestions: [],
    indicationWhoValue: {},
    responsibleTypesOptions: []
  };

  _form;

  indicationTypesOfShowWhoIndication = [1, 2, 3];

  closeAvatarModal = (e) => {
    e.stopPropagation();
    this.setState({ displayAvatarModal: false });
  };

  openAvatarModal = () => {
    this.setState({ displayAvatarModal: true });
  };

  componentDidMount = () => {
    const { showLoader, hideLoader } = this.props;
    showLoader();
    const responsiblePromisse = DomainsAPI.findResponsibleTypes().then(({ data }) => {
      this.setState({
        responsibleTypes: data,
        responsibleTypesOptions: [
          {},
          ...data.map((r) => ({
            label: r.nome,
            value: r.id
          }))
        ]
      });
    });

    const indicationTypePromisse = DomainsAPI.findIndicationTypes().then(({ data }) => {
      this.setState({
        typeIndication: data
      });
    });

    this.setState({
      indicationTypeId: this.props.formInitValues.tipo_indicacao_id
    });

    Promise.all([responsiblePromisse, indicationTypePromisse])
      .then(() => {
        hideLoader();
      })
      .catch((err) => {
        console.err(err);
        hideLoader();
      });
  };

  validateSchema = Yup.object().shape({
    nome: Yup.string()
      // .max(27, 'Nome deve conter no máximo 27 caracteres')
      .required('Nome Obrigatório'),
    nome_preferido: Yup.string().max(19, 'Nome Preferido deve conter no máximo 17 caracteres'),
    numero_identificacao: Yup.string().max(8, 'Número deve conter no máximo 8 caracteres')
  });

  callbackUploadImage = (image, extension) => {
    this.setState({
      uploadFoto: {
        image,
        extension
      }
    });
  };
  selectingIndicationType = (indicationTypeId) => {
    this.setState({
      indicationTypeId
    });
  };

  clearWhoIndicatior = () => {
    this.setState({
      indicatorSuggestions: [
        {
          id: 0,
          name: 'Nenhuma indicação encontrada'
        }
      ]
    });
  };

  findWhoIndicators = (value) => {
    const { indicationTypeId = this.props.formInitValues.tipo_indicacao_id } = this.state;

    DomainsAPI.findWhoIndication(value, indicationTypeId).then(({ data }) => {
      this.setState({
        indicatorSuggestions: data.map((i) => {
          return {
            id: i.id,
            name: i.nome
          };
        })
      });
    });
  };

  validateForm = (values, actions, uploadFoto) => {
    let valid = true;

    if (
      t(values.tipo_responsavel_id).isDefined &&
      parseInt(values.tipo_responsavel_id) !== 1 &&
      t(values.rg).isDefined &&
      !t(values.rg).isEmptyString &&
      values.rg === values.rg_responsavel
    ) {
      actions.setFieldError('rg_responsavel', 'Rg do responsável não pode ser igual ao Rg do paciente');
      valid = false;
    }

    if (
      t(values.tipo_responsavel_id).isDefined &&
      parseInt(values.tipo_responsavel_id) !== 1 &&
      t(values.cpf).isDefined &&
      !t(values.cpf).isEmptyString &&
      values.cpf === values.cpf_responsavel
    ) {
      actions.setFieldError('cpf_responsavel', 'CPF do responsável não pode ser igual ao CPF do paciente');
      valid = false;
    } else if (
      t(values.tipo_responsavel_id).isDefined &&
      parseInt(values.tipo_responsavel_id) !== 1 &&
      t(values.cpf).isDefined &&
      !t(values.cpf_responsavel).isEmptyString &&
      !isCPF(values.cpf_responsavel)
    ) {
      valid = false;
      actions.setFieldError('cpf_responsavel', 'CPF Inválido');
      ToastUtils.time(
        <ToastCustomMessageComponent
          message={'Insira um CPF válido ou deixe o campo em branco, para salvar.'}
          iconImage={danger}
          title={'CPF do responsável inválido ! '}
        />,
        {
          autoClose: 8000,
          hideProgressBar: true,
          position: 'top-center'
        }
      );
    }

    if (values.cpf && !isCPF(values.cpf)) {
      valid = false;
      ToastUtils.time(
        <ToastCustomMessageComponent
          message={'Insira um CPF válido ou deixe o campo em branco, para salvar.'}
          iconImage={danger}
          title={'CPF inválido ! '}
        />,
        {
          autoClose: 8000,
          hideProgressBar: true,
          position: 'top-center'
        }
      );
    }

    if (valid) {
      this.props.savePatient(values, uploadFoto.image, uploadFoto.extension);
    }
  };

  render() {
    const { formInitValues, closeModal } = this.props;

    const {
      displayAvatarModal,
      responsibleTypes,
      typeIndication,
      uploadFoto,
      indicatorSuggestions,
      responsibleTypesOptions
    } = this.state;

    if (formInitValues) {
      formInitValues.whoIndicated = {
        id: formInitValues.indicacao_relacao,
        name: formInitValues.nome_indicacao
      };

      formInitValues.indicationName = formInitValues.nome_indicacao;
    }

    return (
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={formInitValues}
          validationSchema={this.validateSchema}
          validateOnChange={false}
          onSubmit={(values, actions) => this.validateForm(values, actions, uploadFoto)}
        >
          {({ errors, handleSubmit, handleBlur, handleChange, values = {} }) => {
            
            let maskTelefone1 = [
              '(',
              /[1-9]/,
              /\d/,
              ')',
              ' ',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/
            ];

            let maskTelefone2 = [
              '(',
              /[1-9]/,
              /\d/,
              ')',
              ' ',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/
            ];
            let t1 = (values.telefones && values.telefones[0]) || {};
            let t2 = (values.telefones && values.telefones[1]) || {};
            let telefone1 = { ...t1 };
            let telefone2 = { ...t2 };
            if (t1.telefone && t1.telefone.replace(/[^a-zA-Z0-9]/g, '').length === 11) {
              maskTelefone1 = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
            }

            if (t2.telefone && t2.telefone.replace(/[^a-zA-Z0-9]/g, '').length === 11) {
              maskTelefone2 = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
            }

            let foto = values.foto || noavatar;

            if (uploadFoto.image) {
              foto = uploadFoto.image || noavatar;
            }
            return (
              <form onSubmit={handleSubmit} ref={(ref) => (this._form = ref)}>
                <ModalBody>
                  <Row>
                    <Col sm='4'>
                      <div className='modal-img-user'>
                        <div className='absolute-center'>
                          <a onClick={this.openAvatarModal}>
                            <img src={foto} name='foto' className='img-circle' alt='noavatar' />
                          </a>
                        </div>
                      </div>
                    </Col>
                    <Col sm='8'>
                      <label>
                        Nome<span>*</span>
                      </label>
                      <Input
                        className='input-secondary'
                        type='text'
                        name='nome'
                        defaultValue={values.nome}
                        onChange={handleChange}
                        invalid={errors.nome ? true : false}
                        maxLength={254}
                      />
                      <ErrorField errors={errors} fieldName={'nome'} />
                      <Row>
                        <Col sm='8' className='p-0 pt-4'>
                          <label>Nome Preferido</label>
                          <Icon
                            id='tooltipFavName'
                            className='icon icon-info-fav-name'
                            image={info}
                            alt='Nome Favorito'
                          />
                          <UncontrolledTooltip placement='top' target='tooltipFavName' className='tooltip-white'>
                            Nome que prefere ser chamado, apelido. Nome que constará nas mensagens
                          </UncontrolledTooltip>

                          <Input
                            className='input-secondary'
                            type='text'
                            name='nome_preferido'
                            defaultValue={values.nome_preferido}
                            onChange={handleChange}
                            invalid={errors.nome_preferido ? true : false}
                            maxLength={19}
                          />
                          <ErrorField errors={errors} fieldName={'nome_preferido'} />
                        </Col>
                        <Col sm='4' className='p-0 pt-4 pl-2'>
                          <label>Número</label>
                          <input
                            className='input-secondary'
                            type='text'
                            name='numero_identificacao'
                            defaultValue={values.numero_identificacao}
                            onChange={handleChange}
                            maxLength={8}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <div className='margin-top-10'>
                    <Row>
                      <Col sm={3}>
                        <form>
                          <label>Data de Nasc</label>
                          <input
                            className='input-secondary'
                            type='text'
                            name='data_nascimento'
                            defaultValue={moment(values.data_nascimento).utc().format('DD/MM/YYYY')} 
                            onChange={(e) => {
                              handleChange({
                                target: { name: 'data_nascimento', value: parseDate(e.target.value, 'DD/MM/YYYY') }
                              });
                            }}
                          />
                        </form>
                      </Col>
                      <Col sm='3'>
                        <label>Sexo</label>
                        <select name='sexo' className='form-control' defaultValue={values.sexo} onChange={handleChange}>
                          <option />
                          <option value='M'>Masculino</option>
                          <option value='F'>Feminino</option>
                        </select>
                      </Col>
                      <Col sm='6'>
                        <form>
                          <label>Profissão</label>
                          <input
                            className='input-secondary'
                            type='text'
                            name='profissao'
                            defaultValue={values.profissao}
                            onChange={handleChange}
                          />
                        </form>
                      </Col>
                    </Row>
                  </div>
                  <div className='margin-top-10'>
                    <Row>
                      <Col sm='6'>
                        <form>
                          <label>CPF</label>

                          <InputMaskComponent
                            className='input-secondary'
                            guide={false}
                            onChange={handleChange}
                            invalid={errors['cpf']}
                            value={values.cpf}
                            name={'cpf'}
                            id={'cpf'}
                            mask={maskCPF()}
                          />
                          <ErrorFieldWithoutBootstrap fieldName={'cpf'} errors={errors} />

                          {/*<input*/}
                          {/*  className="input-secondary"*/}
                          {/*  type="text"*/}
                          {/*  name="cpf"*/}
                          {/*  defaultValue={ values.cpf }*/}
                          {/*  onChange={ handleChange }*/}
                          {/*/>*/}
                        </form>
                      </Col>
                      <Col sm='3'>
                        <label>RG</label>
                        <Input
                          className='input-secondary'
                          guide={false}
                          onChange={handleChange}
                          invalid={errors['rg']}
                          value={values.rg}
                          name={'rg'}
                          id={'rg'}
                        />
                        <ErrorFieldWithoutBootstrap fieldName={'rg'} errors={errors} />
                      </Col>
                      <Col sm='3'>
                        <form>
                          <label>Orgão Expedidor</label>
                          <input
                            className='input-secondary'
                            type='text'
                            name='orgaoExpedidor'
                            defaultValue={values.orgaoExpedidor}
                            onChange={handleChange}
                          />
                        </form>
                      </Col>
                    </Row>
                  </div>
                  <div className='margin-top-10'>
                    <Row>
                      <Col sm='12'>
                        <span className='title-modal'>Responsável</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm='6'>
                        <label>Responsável</label>
                      </Col>
                      {values.tipo_responsavel_id === undefined ||
                        (parseInt(values.tipo_responsavel_id) !== 1 && (
                          <Col sm='6'>
                            <label>Nome do Responsável</label>
                          </Col>
                        ))}
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <Select
                          value={
                            values.tipo_responsavel_id
                              ? responsibleTypesOptions.find((r) => r.value === values.tipo_responsavel_id)
                              : {}
                          }
                          isSearchable={true}
                          ignoreAccents={true}
                          ignoreCase={true}
                          options={responsibleTypesOptions}
                          placeholder={''}
                          styles={reactSelectStyleIsError(errors, 'tipo_responsavel_id', selectStyles)}
                          onChange={(data) => {
                            if (data.value === 1) {
                              reactSelectHandleChange('', handleChange, 'nome_responsavel');
                            }

                            reactSelectHandleChange(data.value, handleChange, 'tipo_responsavel_id');
                          }}
                        />
                      </Col>
                      <Col sm={6}>
                        {values.tipo_responsavel_id === undefined ||
                          (parseInt(values.tipo_responsavel_id) !== 1 && (
                            <input
                              className='input-secondary'
                              type='text'
                              name='nome_responsavel'
                              defaultValue={values.nome_responsavel}
                              onChange={handleChange}
                            />
                          ))}
                      </Col>
                    </Row>
                  </div>
                  {values.tipo_responsavel_id === undefined ||
                    (parseInt(values.tipo_responsavel_id) !== 1 && (
                      <div>
                        <div className='margin-top-10'>
                          <Row>
                            <Col sm='6'>
                              <label>CPF</label>
                              <InputMaskComponent
                                className='input-secondary'
                                guide={false}
                                onChange={handleChange}
                                invalid={errors['cpf_responsavel']}
                                value={values.cpf_responsavel}
                                name={'cpf_responsavel'}
                                id={'cpf_responsavel'}
                                mask={maskCPF()}
                              />
                              <ErrorFieldWithoutBootstrap fieldName={'cpf_responsavel'} errors={errors} />
                            </Col>
                            <Col sm='3'>
                              <label>RG</label>
                              <Input
                                className='input-secondary'
                                guide={false}
                                onChange={handleChange}
                                invalid={errors['rg_responsavel']}
                                value={values.rg_responsavel}
                                name={'rg_responsavel'}
                                id={'rg_responsavel'}
                              />
                              <ErrorFieldWithoutBootstrap fieldName={'rg_responsavel'} errors={errors} />
                            </Col>
                            <Col sm='3'>
                              <label>Orgão Expedidor</label>
                              <input
                                className='input-secondary'
                                type='text'
                                name='orgaoExpedidorResponsavel'
                                defaultValue={values.orgaoExpedidorResponsavel}
                                onChange={handleChange}
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    ))}
                  <div className='margin-top-10'>
                    <Row>
                      <Col sm='12'>
                        <span className='title-modal'>Indicação</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm='6'>
                        <label>Tipo Indicação</label>
                        <select
                          name='tipo_indicacao_id'
                          className='form-control'
                          value={values.tipo_indicacao_id}
                          onChange={(e) => {
                            handleChange({
                              target: {
                                name: 'nome_indicacao',
                                value: ''
                              }
                            });

                            handleChange({
                              target: {
                                name: 'indicacao_relacao',
                                value: ''
                              }
                            });

                            handleChange({
                              target: {
                                name: 'whoIndicated',
                                value: null
                              }
                            });
                            this.selectingIndicationType(e.target.value);
                            handleChange(e);
                          }}
                        >
                          <option />
                          {typeIndication.map((t) => (
                            <option value={t.id}>{t.nome}</option>
                          ))}
                        </select>
                      </Col>
                      {this.indicationTypesOfShowWhoIndication.includes(parseInt(values.tipo_indicacao_id)) ? (
                        <Col sm='6'>
                          <label>Quem Indicou</label>
                          <Icon
                            id='tooltipWhoIndicated'
                            className='icon icon-info-who-indicated'
                            image={help}
                            alt='Nome Favorito'
                          />
                          <UncontrolledTooltip placement='top' target='tooltipWhoIndicated' className='tooltip-white'>
                            Caso o paciente não se lembre do nome de quem indicou, deixe o campo em branco.
                          </UncontrolledTooltip>
                          <SimpleSuggestionBoxComponent
                            name='whoIndicated'
                            className='input-secondary'
                            clearSuggestions={this.clearWhoIndicatior}
                            selectSuggestion={(value) => {
                              handleChange({
                                target: {
                                  name: 'whoIndicated',
                                  value
                                }
                              });

                              return value.name;
                            }}
                            suggestions={indicatorSuggestions}
                            findSuggestions={this.findWhoIndicators}
                            defaultValue={{
                              id: values.indicacao_relacao,
                              name: values.nome_indicacao
                            }}
                            value={values.whoIndicated || {}}
                          />
                        </Col>
                      ) : null}

                      {4 === parseInt(values.tipo_indicacao_id) ? (
                        <Col sm='6'>
                          <label>Quem Indicou</label>
                          <Input
                            className='input-secondary'
                            name='nome_indicacao'
                            value={values.nome_indicacao}
                            onChange={handleChange}
                          />
                        </Col>
                      ) : null}

                      {6 === parseInt(values.tipo_indicacao_id) ? (
                        <Col sm='6'>
                          <label>Quem Indicou</label>
                          <select
                            id='soflow'
                            name='nome_indicacao'
                            className='form-control'
                            value={values.nome_indicacao}
                            onChange={handleChange}
                            // onChange={ e => {
                            //   this.selectingIndicationType(e.target.value);
                            //   handleChange(e);
                            // } }
                          >
                            <option />
                            <option value={'Facebook'}>Facebook</option>
                            <option value={'Instagram'}>Instagram</option>
                          </select>
                        </Col>
                      ) : null}
                    </Row>
                  </div>
                  <div className='margin-top-10'>
                    <Row>
                      <Col sm='12'>
                        <span className='title-modal'>Telefones e Contatos</span>
                      </Col>
                    </Row>
                  </div>
                  <div className='margin-10'>
                    <input
                      className='form-control'
                      type='hidden'
                      name={'telefone_id_1'}
                      defaultValue={telefone1.id}
                      onChange={handleChange}
                    />
                    <Row>
                      <Col sm='4'>
                        <label>Telefone 1</label>
                        <MaskedInput
                          id='phone1'
                          name='telefones[0].telefone'
                          mask={maskTelefone1}
                          onChange={handleChange}
                          guide={false}
                          value={telefone1.telefone}
                          render={(ref, props) => <Input className='input-secondary' innerRef={ref} {...props} />}
                        />
                      </Col>
                      <Col sm='7'>
                        <form>
                          <label>Descrição</label>
                          <input
                            className='input-secondary'
                            type='text'
                            name={'telefones[0].nome_dono_telefone'}
                            defaultValue={telefone1.nome_dono_telefone}
                            onChange={handleChange}
                          />
                        </form>
                      </Col>
                      <Col sm='1'>
                        <label>Tipo</label>
                        <a
                          href={'#'}
                          onClick={() => {
                            handleChange({
                              target: {
                                name: 'telefones[0].tipo_telefone',
                                value:
                                  t(telefone1.tipo_telefone).isUndefined ||
                                  t(telefone1.tipo_telefone).safeString === 'telefone'
                                    ? 'whatsapp'
                                    : 'telefone'
                              }
                            });
                          }}
                        >
                          <IfComponent conditional={!telefone1.tipo_telefone || telefone1.tipo_telefone === 'telefone'}>
                            <IoLogoWhatsapp className='icon icon-whats' alt='whats' size={'28'} />
                          </IfComponent>
                          <IfComponent conditional={telefone1.tipo_telefone === 'whatsapp'}>
                            <IoLogoWhatsapp className='icon icon-whats whats-on' alt='whats' size={'28'} />
                          </IfComponent>
                        </a>
                      </Col>
                    </Row>
                  </div>

                  <div className='margin-top-20'>
                    <input type='hidden' name={'telefone_id_2'} defaultValue={telefone2.id} onChange={handleChange} />
                    <Row>
                      <Col sm='4'>
                        <label>Telefone 2</label>
                        <MaskedInput
                          id='phone2'
                          name='telefones[1].telefone'
                          mask={maskTelefone2}
                          onChange={handleChange}
                          guide={false}
                          value={telefone2.telefone}
                          render={(ref, props) => <Input className='input-secondary' innerRef={ref} {...props} />}
                        />
                      </Col>
                      <Col sm='7'>
                        <form>
                          <label>Descrição</label>
                          <input
                            className='input-secondary'
                            type='text'
                            name={'telefones[1].nome_dono_telefone'}
                            defaultValue={telefone2.nome_dono_telefone}
                            onChange={handleChange}
                          />
                        </form>
                      </Col>
                      <Col sm='1'>
                        <a
                          href={'#'}
                          onClick={() => {
                            handleChange({
                              target: {
                                name: 'telefones[1].tipo_telefone',
                                value:
                                  t(telefone2.tipo_telefone).isUndefined ||
                                  t(telefone2.tipo_telefone).safeString === 'telefone'
                                    ? 'whatsapp'
                                    : 'telefone'
                              }
                            });
                          }}
                        >
                          <IfComponent conditional={!telefone2.tipo_telefone || telefone2.tipo_telefone === 'telefone'}>
                            <IoLogoWhatsapp className='icon icon-whats' alt='whats' size={'28'} />
                          </IfComponent>
                          <IfComponent conditional={telefone2.tipo_telefone === 'whatsapp'}>
                            <IoLogoWhatsapp className='icon icon-whats whats-on' alt='whats' size={'28'} />
                          </IfComponent>
                        </a>
                      </Col>
                    </Row>
                  </div>
                  <div className='margin-top-10'>
                    <Row>
                      <Col sm='12'>
                        <form>
                          <label>E-mail</label>
                          <input
                            className='input-secondary'
                            type='text'
                            name='email_contato'
                            defaultValue={values.email_contato}
                            onChange={handleChange}
                          />
                        </form>
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Row>
                    <Col sm={{ size: 6, offset: 6 }} className={'btn-list'}>
                      <button className='button-primary' type='submit'>
                        Salvar
                      </button>
                      <button type='button' className='button-tertiary' onClick={closeModal}>
                        Cancelar
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
              </form>
            );
          }}
        </Formik>
        <AvatarModal
          onlyUploa={true}
          display={displayAvatarModal}
          closeModal={this.closeAvatarModal}
          callbackImage={this.callbackUploadImage}
        />
      </div>
    );
  }
}

export default PatientEditModalForm;
