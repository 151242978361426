import React,{useEffect,useState,useImperativeHandle,forwardRef} from "react"
import Calendar from '../../assets/img/icons/calendar4.svg'
import Whatsapp from '../../assets/img/icons/whatsapp-o.svg'
import Info from '../../assets/img/icons/info.png'
import Cake from '../../assets/img/icons/birthday_cake.svg'
import Card from './ScheduleModalBdayCard' 
import X from '../../assets/img/icons/fechar.png'
import {Col, Row, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import '../../assets/css/components/ModalBday.css'
import ClinicAPI from "../../service/ClinicAPI"
import Icon from "../../components/Icon.component"



const ScheduleModalBday = (props,modalref) => {
    const [showModal, setShowModal] = useState(false);
    const [patients, setPatients] = useState([]);

    function closeModal() {
        setShowModal(false);
    }
    
    useImperativeHandle(modalref, () => ({
      async openModal(){
        setShowModal(true);
        // A chamada precisa ficar na abertura do modal:
        getBirthdayToday();
      }
    }));

    const Tempo = new Date();
    var Dia = Tempo.getDate()
    var Mes = Tempo.getMonth() + 1
    const Ano = Tempo.getFullYear()
    
    const Numbers = [1,2,3,4,5,6,7,8,9,0]
    
    if (Dia in Numbers){
        Dia = '0'+Dia 
    }

    if(Mes in Numbers){
        Mes = '0'+Mes
    }

    var Auxday = Dia+'/'+Mes+'/'+Ano
    const AuxdayCard = Dia+'/'+Mes+'/'
    
    async function getBirthdayToday(){
        const {data} = await ClinicAPI.getBirthdayToday()
        setPatients(data);
    }

    return(
        <div>
            <Modal isOpen={showModal} className={"modal-mdplus"} centered={true} toggle={closeModal}>
                <ModalHeader className='modalBD-header' 
                    close={
                        <button className='close' onClick={closeModal}>
                            &times;
                        </button>
                        }
                    >
                        <Row className="align-items-center d-flex justify-content-center">
                            <div className="ml-1 d-flex justify-content-center align-items-center" style={{width:35,height:35,borderRadius:17,background:'#eae7fc'}}>
                                <img src={Cake} className="supercake" style={{width:20,marginBottom:2}}/>
                            </div>
                            <strong className="pl-2 text-center" style={{fontSize:21}}>Aniversariantes</strong>
                        </Row>
                </ModalHeader>

                <ModalBody className="modalBD-body">
                    <Row className="mt-3 mb-3 p-0 align-items-center">
                        
                        <Col sm={{size:0}} className="title-secundary pl-2">
                            ANIVERSARIANTES DE HOJE
                        </Col>

                        <Col sm={{size:0}} className="AuxSpan1BD">
                            <span className='Span1BD'>{Auxday}</span>
                        </Col>
                    </Row>

                    {
                        /*
                        <Row className="p-0 mt-3 mb-3">
                            <Col sm={{size:0}} className="p-0" >
                                <img src={Info} style={{width:20,height:20}}/>
                            </Col>
                            <Col className="TextoBD p-0">
                                O Griks envia, automaticamente às 9hs da manhã, uma mensagem de <br/>Feliz Aniversário, pelo whatsapp, para cada aniversáriante.
                            </Col>
                        </Row>
                        */
                    }

                    

                    <Row className="TitlesCardBD align-items-center">
                        <Col className="col-7 p-0">
                            Aniversariantes
                        </Col>
                        
                        <Col sm={{size:0}} className="DataNascBD p-0 text-center">
                            Data Nasc
                        </Col>

                        <Col sm={{size:0}} className="CalendarioBD p-0 text-center">
                            <img src={Calendar} style={{width:23,height:23}}/>
                        </Col>

                        <Col sm={{size:0}} className="p-0 text-center" style={{marginLeft:53}}>
                            <img src={Whatsapp} style={{width:23,height:23}} className='whatsAppImg'/>
                        </Col>
                    </Row>

                    <Row className="CardBD p-0" style={{maxHeight:250}}>
                        <Col className="p-0">
                            {patients.length == 0 ? 
                                <Col className="p-0 mt-4 mb-2 text-center" style={{fontSize:18,color:'#434c5e'}}>
                                    <strong>
                                        Não há aniversariante no dia de hoje!
                                    </strong>
                                </Col>       
                                : 
                                patients.map((patient)=> (<Card key={patient.patient_id} id={patient.patient_id} nomes={patient.name} datas={patient.birthDate} auxcard={AuxdayCard} idadeAnos={patient.idade} meta={patient.meta} image={patient.photo} telefone={patient.phone}/>))
                            }
                        </Col>
                    </Row>

                </ModalBody>

                <ModalFooter className="modalBD-footer">
                    <Row className="p-0 m-0" style={{float:'right'}}>
                        <button className='FecharBD' onClick={closeModal}>Fechar</button>
                    </Row>
                </ModalFooter>
            </Modal>
        </div>
    )
}
export default forwardRef(ScheduleModalBday);


/*

//quando tiver ja implementada a função de envio de mensagem automatica 
//colocar na linha 79 o seguinte codigo 
                    
                    <Row className="p-0 mt-3 mb-3">
                        <Col sm={{size:0}} className="p-0" >
                            <img src={Info} style={{width:20,height:20}}/>
                        </Col>
                        <Col className="TextoBD p-0">
                            O Griks envia, automaticamente às 9hs da manhã, uma mensagem de <br/>Feliz Aniversário, pelo whatsapp, para cada aniversáriante.
                        </Col>
                    </Row>


<Row className="mt-3 mb-3 p-0 align-items-center">


//e na linha 65 substituir por 
                    <Row className="mt-3 p-0 align-items-center">



*/