import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Modal, ModalBody, ModalHeader, Row, Col, Table, ModalFooter } from 'reactstrap';
import MessagesComponent from '../../../components/Messages.component';
import { t } from 'typy';
import ScheduleAppointmentModalNew from '../../schedule/modal/ScheduleAppointmentModalNew';

const ScheduleAppointmentDefaultTreatmentNew = ({ formSubmitSave }, ref) => {
  const [message, setMessage] = useState({ display: false, text: '' });
  const [patient, setPatient] = useState({ name: 'Nome do Paciente' });
  const [treatmentSelected, setTreatmentSelected] = useState({ id: 0 });
  const [showModal, setShowModal] = useState(false);
  const modalRef = useRef(false);

  useImperativeHandle(ref, () => ({
    openModal() {
      setShowModal(true);
      console.log(showModal);
    }
  }));

  const selectElement = (tid, id) => {
    let t = document.getElementById(tid);
    let tbody = t.getElementsByTagName('tbody')[0];
    let trs = tbody.getElementsByTagName('tr');
    for (var i = 0; i < trs.length; i++) {
      trs[i].classList.remove('active');
    }
    for (var j = 0; j < trs.length; j++) {
      if (trs[j].getAttribute('data-key') === id) {
        trs[j].classList.add('active');
      }
    }
  };

  const closeModal = () => {
    // setTreatmentSelected({ id: '' });
    setMessage({ display: false, text: '' });
    setShowModal(false);
  };

  return (
    <>
      <Modal isOpen={showModal} toggle={closeModal} className={' modal-default'}>
        <ModalHeader className='title-primary' toggle={closeModal}>
          <strong>Agendar Consulta</strong>
        </ModalHeader>
        <ModalBody>
          <MessagesComponent display={message.display} type={'danger'} message={message.text} />
          <div className='margin-top-10'>
            <Row>
              <Col className='text-left' sm='12'>
                <p className='text-default'>
                  <strong>Paciente</strong> {patient.name}
                </p>
              </Col>
            </Row>
            <Row className='margin-top-10'>
              <Col className='text-left' sm='12'>
                <h1 className='title-modal'>Selecione o Tratamento</h1>
              </Col>
            </Row>
          </div>
          <Row>
            <Col sm='12'>
              <Table id={'tablefy'} responsive>
                <thead>
                  <tr>
                    <th width='5%'></th>
                    <th>Tratamento</th>
                    <th>Especialidade</th>
                    <th>Avaliação</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    onClick={() => {
                      setTreatmentSelected({ id: 0 });
                      selectElement('tablefy', 0);
                    }}
                    data-key='0'
                    key={t.id}
                  >
                    <td>
                      <input
                        onChange={null}
                        id={'check-one'}
                        type='checkbox'
                        className='form-checkbox'
                        checked={treatmentSelected.id === 0}
                      />
                    </td>
                    <td colSpan={3}> Novo Tratamento/ Primeira Consulta /Consulta Avulsa</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col sm='12'>
              <button
                type='button'
                className='button-primary float-right'
                onClick={() => {
                  modalRef.current.openModal();
                }}
              >
                Próximo
              </button>
              <button type='button' className='button-tertiary float-right mr-3' onClick={closeModal}>
                Cancelar
              </button>
            </Col>
          </Row>
        </ModalFooter>
        <ScheduleAppointmentModalNew ref={modalRef} />
      </Modal>
    </>
  );
};
export default forwardRef(ScheduleAppointmentDefaultTreatmentNew);
