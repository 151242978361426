import RestTemplate from '../utils/restTemplate/rest.template';
import moment from 'moment';

export default class TreatmentImagesApi {
  static findAllFoldersByTreatment(treatment) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/tratamento/${treatment}/images/folders`);
  }

  static createFolder(treatment, folderName) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.post(`/tratamento/${treatment}/images/folder`, {
      name: folderName
    });
  }

  static renameFolder(treatment, folderId, newFolderName) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.put(`/tratamento/${treatment}/images/folder/rename`, {
      folder_id: folderId,
      folder_new_name: newFolderName
    });
  }

  static deleteFolder(treatment, folderId) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.delete(`/tratamento/${treatment}/images/folder/${folderId}`);
  }

  static getFolder(treatment, folderId) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.get(`/tratamento/${treatment}/images/folder/${folderId}`);
  }

  static saveImageInFolder(treatment, name, folderId, image, order, indexImage, thumbnail) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.put(`/tratamento/${treatment}/images/folder/${folderId}/upload`, {
      image: {
        name: name + ` (${indexImage})`,
        image,
        extension: 'jpg'
      },
      thumbnail: {
        name: name + ` (${indexImage})`,
        image: thumbnail,
        extension: 'jpg'
      },
      order
    });
  }

  static reorderImagesInFolder(treatment, folderId, images) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.put(
      `/tratamento/${treatment}/images/folder/${folderId}/reorder`,
      images.map((i) => i.id)
    );
  }

  static renameImage(treatment, imageid, name) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.put(`/tratamento/${treatment}/image/${imageid}/rename`, {
      name
    });
  }

  static deleteImage(treatment, imageid) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.delete(`/tratamento/${treatment}/image/${imageid}`);
  }

  static moveImages(treatment, folderTo, folderFrom, images) {
    const restTemplate = new RestTemplate(true);
    return restTemplate.put(`/tratamento/${treatment}/images/move`, {
      from: folderFrom,
      to: folderTo,
      images: images.map((i) => i.id)
    });
  }
}
