import React, { Component } from 'react';

import { Col, Modal, ModalBody, ModalHeader, Row, ModalFooter } from 'reactstrap';

import success from '../../../assets/img/icons/success.svg';
import Icon from '../../../components/Icon.component';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { modalSuccess } from './redux/UserSettings.actions';
import { withRouter } from 'react-router';

class UserSettingsSucessoModal extends Component {
  _goToScheduleSettings = () => {
    const { modalData: { data: { user } = {} } = {} } = this.props;

    const userData = {
      value: user.id,
      label: user.usuario_cadastro,
      data: {
        id: user.id,
        nome_completo: user.usuario_cadastro,
        nome: user.nome,
        tipo_dentista: user.perfil.nome
      }
    };

    sessionStorage.setItem('dentistSelected', JSON.stringify(userData));
    this.props.closeModalSuccess({
      modal: false,
      data: {}
    });
    this.props.history.push('/settings/schedule');
  };

  closeModal = () => {
    this.props.closeModalSuccess({
      modal: false,
      data: {}
    });
  };

  render() {
    const { modalData: { modal = false, data = {} } = {} } = this.props;
    const { usuario_cadastro = '', perfil = {}, status } = data;

    const closeBtn = (
      <button className='close' onClick={this.closeModal}>
        &times;
      </button>
    );
    return (
      <Modal isOpen={modal} toggle={this.closeModal} className={this.props.className}>
        <ModalHeader className='title-primary' toggle={this.closeModal} close={closeBtn}>
          <strong>{usuario_cadastro}</strong> {perfil.nome}{' '}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm='12'>
              <div className='modal-icon'>
                <Icon className='icon-success icon-size-medium' image={success} alt='success' />
              </div>
            </Col>
          </Row>
          <div className='margin-top-20'>
            <Row>
              <Col sm='12'>
                <p className='text-secondary text-md'>Usuário salvo com sucesso!</p>
                {perfil.id === 1 ||
                  (perfil.id === 2 && (
                    <p className='text-secondary text-md'>Vamos configurar os horários da Agenda do(a) Doutor(a) ?</p>
                  ))}
              </Col>
            </Row>
          </div>
        </ModalBody>

        <ModalFooter>
          {status === 'N' && (perfil.id === 1 || perfil.id === 2) && (
            <Row>
              <Col className='btn-list' sm={{ size: 4, offset: 8 }}>
                <button className='button-primary' onClick={this.closeModal}>
                  Fechar
                </button>
              </Col>
            </Row>
          )}

          {perfil.id !== 1 && perfil.id !== 2 && (
            <Row>
              <Col className='btn-list' sm={{ size: 4, offset: 8 }}>
                <button className='button-primary' onClick={this.closeModal}>
                  Fechar
                </button>
              </Col>
            </Row>
          )}

          {status === 'S' && (perfil.id === 1 || perfil.id === 2) && (
            <Row>
              <Col className='btn-list' sm={{ size: 9, offset: 4 }}>
                <button className='button-primary col-sm-6' onClick={this._goToScheduleSettings}>
                  Configurar a agenda
                </button>
                <button className='button-tertiary col-sm-4' onClick={this.closeModal}>
                  Agora não
                </button>
              </Col>
            </Row>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = ({ UserSettingsReducer }) => {
  const { success = {} } = UserSettingsReducer;
  console.log(success);
  return {
    modalData: {
      ...success
    }
  };
};

const mapDispathToProps = (dispatch) => {
  return {
    closeModalSuccess: (data) => dispatch(modalSuccess(data))
  };
};

const enhanced = compose(connect(mapStateToProps, mapDispathToProps), withRouter);

export default enhanced(UserSettingsSucessoModal);
