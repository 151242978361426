import React, { Component } from 'react';
import { Card, CardBody, CardImg, Col, Container, Row, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faTrashAlt } from '@fortawesome/free-regular-svg-icons';

import fileDownload from '../../../assets/img/file_download.svg';
import fileImage from '../../../assets/img/file_image.svg';
import plus from '../../../assets/img/icons/plus.svg';
import Icon from '../../../components/Icon.component';
import InputInlineEditComponent from '../../../components/InputInlineEdit.component';
import { t } from 'typy';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { treatmentImagesHomePageStateToProps } from './selectors/TreatmentImagesHomePageSelector';
import TreatmentImagesApi from '../../../service/TreatmentImagesApi';
import { hideLoader, showLoader } from '../../../components/actions/Loader.actions';
import { openHomeLoadPageSaga } from './store/TreatmentImagesStore';
import ToastUtils from '../../../utils/ToastUtils';
import { IfComponent } from '../../../components/if.component';
import ModalMessageConfirm from '../../../components/ModalMessageConfirm.component';
import warning from '../../../assets/img/icons/warning.svg';
import TreatmentImageCarouselModel from './modals/TreatmentImageCarouselModel';
import DoubleClickInstance from '../../../utils/DoubleClickInstance';
import edit from '../../../assets/img/icons/edit.svg';
import RestTemplate from '../../../utils/restTemplate/rest.template';
import images from '../../../assets/img/multiple_images.png';
import TreatmentImagesUploadImagesModal from './modals/TreatmentImagesUploadImagesModal';
import { FiPlusCircle } from 'react-icons/fi';
import { AiOutlineFolder } from 'react-icons/ai';
import { FiEdit } from 'react-icons/fi';
import ReactTooltip from 'react-tooltip';
import ImageGallery from 'react-image-gallery';

class TreatmentImagesHomePage extends Component {
  defaultState = {
    addFolder: false,
    folderForDelete: {
      showConfirm: false,
      folder: null
    },
    folderSelected: {},
    folderRename: {},
    carousel: {
      open: false,
      images: [],
      activeIndex: 0
    },
    imageRename: {},
    imagesSelected: [],
    uploadImage: {
      open: false
    }
  };

  state = {
    ...this.defaultState
  };
  renameImageDoubleClickInstance = new DoubleClickInstance();
  renameFolderDoubleClickInstance = new DoubleClickInstance();
  uploadRef;

  constructor(props) {
    super(props);
    this.uploadRef = React.createRef();
  }

  componentDidMount() {
    this.setState({
      ...this.defaultState
    });
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    // if (t(nextProps.folders).isArray && t(this.state, 'folderSelected.folder_id').isUndefined) {
    //   if (nextProps.folders.length > 0)
    //     setTimeout(() => this._selectFolder(nextProps.folders[0]), 100);
    // }

    if (t(this.props, 'folderSelected.id').safeNumber !== t(nextProps, 'folderSelected.id').safeNumber) {
      setTimeout(() => this._selectFolder(nextProps.folderSelected), 100);
    }

    return true;
  }

  _order = async (index) => {
    const { treatment, showLoader, hideLoader, refreshPage } = this.props;
    const { folderSelected } = this.state;
    showLoader();
    const result = Array.from(this.state.folderSelected.images);
    const [removed] = result.splice(this.state.e, 1);
    result.splice(index, 0, removed);
    await TreatmentImagesApi.reorderImagesInFolder(treatment, folderSelected.folder_id, result);
    this._selectFolder({
      id: this.state.folderSelected.folder_id
    });
    hideLoader();
  };

  async _renameFolder(folder, newName) {
    const { treatment, showLoader, hideLoader, refreshPage } = this.props;
    try {
      showLoader();
      await TreatmentImagesApi.renameFolder(treatment, folder.id, newName);
      hideLoader();
      refreshPage({ treatment });
      this._selectFolder(folder);
    } catch (e) {
      ToastUtils.error('Erro ao alterar nome da pasta entre em contato com o suporte');
      console.error(e);
    }
  }

  async _newFolder(name) {
    const { treatment, showLoader, hideLoader, refreshPage } = this.props;
    try {
      showLoader();
      var folder = await TreatmentImagesApi.createFolder(treatment, name);
      refreshPage({ treatment });
      this.setState({
        addFolder: false,
        folderSelected: null
      });
      this._selectFolder(folder.data);
      hideLoader();
    } catch (e) {
      ToastUtils.error('Erro ao alterar nome da pasta entre em contato com o suporte');
      hideLoader();
      console.error(e);
    }
  }

  _preDeleteFolder = (folder) => {
    this.setState({
      folderForDelete: {
        showConfirm: true,
        folder
      }
    });
  };

  _closePreDeleteFolder = () => {
    this.setState({
      folderForDelete: {
        showConfirm: false,
        folder: null
      }
    });
  };

  _deleteFolder = () => {
    const { treatment, showLoader, hideLoader, refreshPage } = this.props;
    showLoader();
    TreatmentImagesApi.deleteFolder(treatment, this.state.folderForDelete.folder.id);
    refreshPage({ treatment });
    this._closePreDeleteFolder();
    this.setState({
      folderSelected: null
    });
    hideLoader();
  };

  _selectFolder = async (folder) => {
    const { treatment, showLoader, hideLoader, refreshPage } = this.props;
    try {
      showLoader();
      const folderSelected = await TreatmentImagesApi.getFolder(treatment, folder.id);
      this.setState({
        folderSelected: folderSelected.data,
        imagesSelected: []
      });
      hideLoader();
    } catch (e) {
      console.log(e);
      ToastUtils.error('Erro ao selecionar pasta, entre em contato com o suporte');
      hideLoader();
    }
  };

  showImages = (index) => {
    const images = this.state.folderSelected.images.map((i, index) => ({
      src: i.url,
      altText: '',
      caption: '',
      header: i.name,
      key: index + 1
    }));

    console.log(images);
    console.log(index);

    this.setState({
      carousel: {
        open: true,
        images: images,
        activeIndex: index
      }
    });
  };

  _updateImageName = async (text, c) => {
    const { treatment, showLoader, hideLoader, refreshPage } = this.props;
    try {
      showLoader();
      await TreatmentImagesApi.renameImage(treatment, c.id, text);

      hideLoader();
    } catch (e) {
      console.error(e);
      hideLoader();
      ToastUtils.error('Erro ao renomear imagem, entre em contato com o suporte');
    }
  };

  _renameImage = (image, isRename) => {
    this.setState({
      imageRename: {
        ...this.state.imageRename,
        [`image_${image}`]: isRename
      }
    });
  };

  _selectImageOrRename = (image, isRename) => {
    const { imagesSelected } = this.state;
    if (imagesSelected.some((i) => i.id === image.id)) {
      if (isRename) {
        this._renameImage(image.id, true);
      } else {
        this.setState({ imagesSelected: imagesSelected.filter((i) => i.id !== image.id) });
      }
    } else {
      imagesSelected.push(image);
      this.setState({ imagesSelected });
    }
  };

  _downloadSelectedsImages = () => {
    const { imagesSelected } = this.state;
    const { treatment, showLoader, hideLoader, refreshPage } = this.props;
    showLoader();
    const restTemplate = new RestTemplate(true);
    restTemplate
      .custom({
        url: `/tratamento/${treatment}/images/download`, //your url
        method: 'post',
        responseType: 'blob', // important
        data: imagesSelected.map((i) => i.url)
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'download.zip'); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.setState({ imagesSelected: [] });
        hideLoader();
      })
      .catch((err) => {
        hideLoader();
        ToastUtils.error('Erro ao recuperar imagens para download, entre em contato com o suporte');
      });
  };

  _moveImagesToFolder = async (folderTo) => {
    const { folders, patient, treatment, showLoader, hideLoader } = this.props;
    const { folderSelected, imagesSelected } = this.state;

    try {
      showLoader();
      if (folderSelected.folder_id.toString() !== folderTo.toString()) {
        await TreatmentImagesApi.moveImages(treatment, folderTo, folderSelected.folder_id, imagesSelected);
        this._selectFolder({
          id: folderSelected.folder_id
        });
      }

      hideLoader();
    } catch (e) {
      hideLoader();
      console.error(e);
      ToastUtils.error('Erro ao mover imagens, entre em contato com o suporte');
    }
  };

  _cardDragStart(c) {
    return (e) => {
      var imageObj = new Image();
      imageObj.src = images;
      e.dataTransfer.dropEffect = 'move';
      e.dataTransfer.setDragImage(imageObj, 10, 10);
      this.setState({ e: e.currentTarget.parentElement.id });
      if (!this.state.imagesSelected.some((i) => i.id === c.id)) {
        this._selectImageOrRename(c, true);
      }
    };
  }

  _selectImage(e, c, isRename) {
    e.stopPropagation();
    this._selectImageOrRename(c, isRename);
  }

  _closeUploadImage = () => {
    this.setState({
      uploadImage: {
        open: false
      }
    });
  };

  _renameFolderOnSelect = (f) => {
    const { folderSelected } = this.state;

    if (t(folderSelected, 'folder_id').safeNumber === f.id) {
      this.setState({
        folderRename: f
      });
    } else {
      this._selectFolder(f);
    }
  };

  render() {
    const { folders, patient, treatment } = this.props;
    const { addFolder, folderForDelete, folderSelected, carousel, imagesSelected, uploadImage, folderRename } =
      this.state;

    return (
      <Container>
        {/*<input type="file" id="file" ref={ (ref) => this.upload = ref }*/}
        {/*       style={ { display: 'none' } }*/}
        {/*       onChange={ (event) => this._uploadFile(event) } multiple={ true }/>*/}
        <Row className={'w-100'} key={'page'}>
          <Col sm={12}>
            <div className='box-default'>
              <Row>
                <Col sm={12}>
                  <div className='row'>
                    <div className='img-pac-name'>
                      <h6 className='font-bold text-secondary' style={{ fontSize: '18px' }}>
                        IMAGENS
                      </h6>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row id={'linha'}>
                <Col sm={3} style={{ borderRight: 1, borderRightStyle: 'solid', borderRightColor: '#d6dae2' }}>
                  <div className={'img-pac-name font-bold mt-2 text-secondary mb-4'} style={{ fontSize: '16px' }}>
                    PASTAS
                  </div>

                  {folders.map((f) => {
                    let classFolder = 'p-2 ';
                    if (f.id === t(folderSelected, 'folder_id').safeNumber) {
                      classFolder += ' bg-primary-l5 ';
                    }

                    if (this.state.over === f.id) {
                      classFolder += 'bg-primary-l4';
                    }

                    return (
                      <Row
                        key={f.id}
                        className={classFolder}
                        id={f.id}
                        onDragLeave={(e) => {
                          document.getElementById(e.currentTarget.id).className = `row ${classFolder}`;
                          e.preventDefault();
                        }}
                        onDragOver={(e) => {
                          console.log(e.currentTarget.id);
                          document.getElementById(e.currentTarget.id).className = 'row p-2 bg-primary-l4';
                          e.preventDefault();
                        }}
                        onDrop={(e) => {
                          this._moveImagesToFolder(e.currentTarget.id);
                          console.log(e.currentTarget.id + 'drop');
                          // if(e.currentTarget.id === folderSelected.folder_id) {
                          //   classFolder += ' bg-primary-l5 ';
                          // }
                          document.getElementById(e.currentTarget.id).className = `row ${classFolder}`;
                          e.preventDefault();
                        }}
                        onDoubleClick={() =>
                          this.renameFolderDoubleClickInstance.onDoubleClicked(() => this._renameFolderOnSelect(f))
                        }
                        onClick={() =>
                          this.renameFolderDoubleClickInstance.onClicked(() => this._renameFolderOnSelect(f))
                        }
                      >
                        <Col sm={10} className={'p-1 d-flex'}>
                          <div>
                            <AiOutlineFolder color='#616e88' size={20} />
                          </div>
                          &nbsp;&nbsp;
                          <span className={'w-100'} style={{ marginTop: '0.1rem' }}>
                            <InputInlineEditComponent
                              text={f.nomePasta}
                              onClick={() => this._selectFolder(f)}
                              show={t(folderRename, 'id').safeNumber === f.id}
                              textStyle={{
                                textDecoration: 'none',
                                fontSize: 13,
                                fontWeight: 400,
                                color: '#474c5e'
                              }}
                              textClassName={'img-folder-name'}
                              save={(text) => this._renameFolder(f, text)}
                            />
                          </span>
                        </Col>
                        <IfComponent conditional={f.id === t(folderSelected, 'folder_id').safeNumber}>
                          <a href={'#'} onClick={() => this._preDeleteFolder(f)}>
                            <Col sm={2} className={'text-center p-2'}>
                              <FontAwesomeIcon icon={faTrashAlt} className={'mb-1'} />
                            </Col>
                          </a>
                        </IfComponent>
                      </Row>
                    );
                  })}
                  <IfComponent conditional={addFolder}>
                    <Row className={'mt-2'} id={'addFolder'}>
                      <Col sm={12} className={'p-1 d-flex'}>
                        <div>
                          <AiOutlineFolder color='#474c5e' size={16} />
                        </div>
                        &nbsp;&nbsp;
                        <span className={'w-100'}>
                          <InputInlineEditComponent
                            text={''}
                            show={true}
                            cancel={() => this.setState({ addFolder: false })}
                            save={(text) => this._newFolder(text)}
                          />
                        </span>
                      </Col>
                    </Row>
                  </IfComponent>
                  <Row className={'mt-2'}>
                    <a
                      onClick={() => {
                        this.setState({ addFolder: true });
                      }}
                    >
                      <div className={'d-flex p-1'}>
                        <div>
                          <FiPlusCircle color='#616e88' size={20} className='ml-2 mb-2' />
                        </div>
                        &nbsp;&nbsp;<span className={'mb-1 text-secondary'}>Nova Pasta</span>
                      </div>
                    </a>
                  </Row>
                </Col>
                <Col sm={9} style={{ minHeight: 500 }}>
                  <IfComponent conditional={t(folderSelected, 'folder_id').isDefined}>
                    <Row>
                      <Col sm={10} className={'mt-1'}>
                        <AiOutlineFolder color='#616e88' size={27} style={{ marginBottom: '0.4rem' }} />
                        <span
                          id={'addImageContainer'}
                          className={
                            'img-folder-name-title vertical-center ml-2 text-secondary font-light text-md-plus'
                          }
                        >
                          {t(folderSelected, 'folder_name').safeString}
                          <a
                            data-tip=''
                            data-for={'addImage'}
                            href={'#'}
                            onClick={() => {
                              this.uploadRef.current.openFileUpload();
                              this.setState({ uploadImage: { open: true } });
                            }}
                          >
                            <FiPlusCircle color='#616e88' size={22} className='ml-3 mb-1' />
                          </a>
                        </span>
                        <ReactTooltip id={'addImage'} className='tooltip-griks' type='light'>
                          Adicionar Novas Imagens
                        </ReactTooltip>
                        {/*<UncontrolledTooltip className="tooltip-white text-xsm font-weight-light" placement="top"*/}
                        {/*                     target={'addImage'}*/}
                        {/*>*/}
                        {/*  Adicionar Novas Imagens*/}
                        {/*</UncontrolledTooltip>*/}
                      </Col>
                      <IfComponent conditional={t(imagesSelected).safeArray.length > 0}>
                        <Col sm={2} className={'mt-2 text-right pr-4'}>
                          <a id='downloadImage' href={'#'} onClick={this._downloadSelectedsImages}>
                            <Icon image={fileDownload} className={'icon-size-small'} />
                          </a>
                          <UncontrolledTooltip
                            className='tooltip-white text-xsm font-weight-light'
                            placement='top'
                            target='downloadImage'
                          >
                            Fazer download das imagens selecionadas
                          </UncontrolledTooltip>
                        </Col>
                      </IfComponent>
                    </Row>
                    <Row id={'imagesGalery'}>
                      {t(folderSelected, 'images').safeArray.map((c, index) => {
                        return (
                          <Col
                            key={c.id}
                            id={index}
                            style={{}}
                            sm={'auto'}
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={(e) => {
                              if (e.currentTarget.id) {
                                if (this.state.currentIndex !== e.currentTarget.id) {
                                  this._order(e.currentTarget.id);
                                  this.setState({ currentIndex: e.currentTarget.id });
                                }
                              }
                            }}
                          >
                            <Card
                              style={{ width: 225, height: 150, padding: 0 }}
                              className={
                                imagesSelected.some((i) => i.id === c.id)
                                  ? 'image-card image-card-selected'
                                  : 'image-card'
                              }
                              draggable
                              onDragStart={this._cardDragStart(c)}
                            >
                              <a
                                href={'#'}
                                onDoubleClick={() =>
                                  this.renameImageDoubleClickInstance.onDoubleClicked(() => this.showImages(index + 1))
                                }
                                onClick={() =>
                                  this.renameImageDoubleClickInstance.onClicked(() =>
                                    this._selectImageOrRename(c, false)
                                  )
                                }
                              >
                                <CardImg top src={c.thumbnail} className={'image-card-image'} />
                              </a>
                              <CardBody
                                className={'border border-bottom-radius-9 p-0'}
                                style={
                                  t(this.state, `imageRename.image_${c.id}`).safeBoolean
                                    ? { backgroundColor: '#fff' }
                                    : {
                                        backgroundColor: '#fff',
                                        height: 37
                                      }
                                }
                              >
                                <div className={'text-center p-2 pr-3'}>
                                  <InputInlineEditComponent
                                    text={c.name}
                                    onClick={() => this.showImages(index + 1)}
                                    textStyle={{
                                      textDecoration: 'none',
                                      fontSize: 13,
                                      fontWeight: 400
                                    }}
                                    cancel={() => this._renameImage(c.id, false)}
                                    show={t(this.state, `imageRename.image_${c.id}`).safeBoolean}
                                    save={(text) => this._updateImageName(text, c)}
                                  />
                                </div>
                                <div
                                  className={'image-card-menu'}
                                  style={t(this.state, `imageRename.image_${c.id}`).safeBoolean ? { bottom: 10 } : {}}
                                >
                                  <a id='renameImage' href={'#'} onClick={(e) => this._renameImage(c.id, true)}>
                                    <FiEdit color='#646874' size={17} />
                                  </a>
                                  <UncontrolledTooltip
                                    className='tooltip-white text-xsm font-weight-light'
                                    placement='bottom'
                                    target='renameImage'
                                  >
                                    Clique para Renomear a Imagem
                                  </UncontrolledTooltip>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        );
                      })}
                    </Row>
                  </IfComponent>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <TreatmentImagesUploadImagesModal
          ref={this.uploadRef}
          open={uploadImage.open}
          close={this._closeUploadImage}
          folderSelected={folderSelected}
          treatment={treatment}
          updateParent={() => {
            this._selectFolder({ id: folderSelected.folder_id });
          }}
        />
        <TreatmentImageCarouselModel
          open={carousel.open}
          images={t(folderSelected, 'images').safeArray}
          treatment={treatment}
          activeIndex={carousel.activeIndex}
          folder={folderSelected}
          closeModal={() =>
            this.setState({
              carousel: {
                open: false,
                images: [],
                activeIndex: 0
              }
            })
          }
          updateParent={() => {
            this._selectFolder({
              id: folderSelected.folder_id
            });
          }}
        />
        <ModalMessageConfirm
          key={'modalConfirm'}
          title={<strong>Confirma Exclusão da Pasta {t(folderForDelete, 'folder.nomePasta').safeString}?</strong>}
          message={
            <span>
              <p className={'text-center'}>
                Deletar a pasta fará com que todas as imagens contidas nela sejam excluidas.
              </p>
              <p className={'text-center font-bold'}>Deseja prosseguir mesmo assim?</p>
            </span>
          }
          openModal={folderForDelete.showConfirm}
          icon={warning}
          close={this._closePreDeleteFolder}
          footer={
            <Row>
              <Col sm='3'>
                <button className='button-tertiary' onClick={this._closePreDeleteFolder}>
                  Não
                </button>
              </Col>
              <Col sm={{ size: 3, offset: 6 }}>
                <button className='button-primary' onClick={this._deleteFolder}>
                  Sim
                </button>
              </Col>
            </Row>
          }
        />
        ,
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  // TreatmentImagesReducer
  return treatmentImagesHomePageStateToProps(state);
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  refreshPage: (data) => dispatch(openHomeLoadPageSaga(data))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(TreatmentImagesHomePage);
