import React, { Component } from 'react';

import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { IfComponent } from '../../components/if.component';
import withSecurity from '../../config/security/security';
import { PERMISSOES } from '../../config/security/permissoes';

class TreatmentMenuPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      exibirComponente: false
    };
  }

  handleContextMenu = (event,tab) => {
    event.preventDefault();

    window.open(`/patient/${this.props.pacienteId}/treatment/${this.props.treatment.id}#${tab}`, '_blank');
  };

  render() {
    const { tabSelected, selectedTab, treatment, security, pacienteId } = this.props;
    const modules = PERMISSOES.modules;

    return (
      <Row className='margin-top-20'>
        <Col sm='12'>
          <nav className='nav-inline border-0'>
            <ul>
              <IfComponent conditional={security.hasModuleAccess(modules.tratamento_avaliacoes.id)}>
                <li className={tabSelected === 'A' ? 'active' : ''}>
                  <a href='#' onClick={() => selectedTab('A')} onContextMenu={(event) => this.handleContextMenu(event,'A')}>
                    Avaliação/Exame Clínico
                  </a>
                </li>
              </IfComponent>
              <IfComponent conditional={security.hasModuleAccess(modules.tratamento_orcamentos.id)}>
                <li className={tabSelected === 'O' ? 'active' : ''}>
                  <a href='#' onClick={() => selectedTab('O')} onContextMenu={(event) => this.handleContextMenu(event,'O')}>
                    Orçamento
                  </a>
                </li>
              </IfComponent>
              <IfComponent conditional={security.hasModuleAccess(modules.tratamento_ficha_clinica.id)}>
                <li className={tabSelected === 'F' ? 'active' : ''}>
                  <a href='#' onClick={() => selectedTab('F')} onContextMenu={(event) => this.handleContextMenu(event,'F')}>
                    Ficha Clínica
                  </a>
                </li>
              </IfComponent>
              <IfComponent conditional={security.hasModuleAccess(modules.tratamento_ficha_financeira.id)}>
                <li className={tabSelected === 'FF' ? 'active' : ''}>
                  <a href='#' onClick={() => selectedTab('FF')} onContextMenu={(event) => this.handleContextMenu(event,'FF')}>
                    Ficha Financeira
                  </a>
                </li>
              </IfComponent>
              <li className={tabSelected === 'IM' ? 'active' : ''}>
                <a href='#'  onClick={() => selectedTab('IM')} onContextMenu={(event) => this.handleContextMenu(event,'IM')}>
                  Imagens
                </a>
              </li>
            </ul>
          </nav>
          <hr style={{ marginTop: -19, marginBottom: 8, marginRight: 20 }} />
        </Col>
      </Row>
    );
  }
}

TreatmentMenuPage.propTypes = {
  selectedTab: PropTypes.func.isRequired
};

export default withSecurity(TreatmentMenuPage);
