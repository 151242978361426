import React, { Component } from 'react';

import { Container, Row, Col } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import Select from 'react-select';
import { showLoader, hideLoader } from '../../../../components/actions/Loader.actions';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { treatmentEvaluationOpenAngleRating } from '../redux/TreatmentEvaluation.action';
import { Formik } from 'formik';
import TreatmentEvaluationApi from '../../../../service/TreatmentEvaluationApi';
import MessagesComponent from '../../../../components/Messages.component';
import { selectStyles } from '../../../../config/core.config';
import { reactSelectHandleChange } from '../../../../utils/ReactSelectUtils';

class TreatmentEvaluationAngleRatingModal extends Component {
  state = {
    message: {
      display: false,
      type: 'danger',
      text: ''
    }
  };

  saveAngleRating = async (values) => {
    const { closeModal, formLoad = {}, showLoader, hideLoader, updateParent } = this.props;
    const { angleRating: { id } = {}, evaluation: { avaliacao_id } = {} } = formLoad;
    const {
      angle,
      thoth_right_6,
      thoth_right_3,
      thoth_left_3,
      thoth_left_6,
      classificacao_esqueletica,
      padrao_esqueletico
    } = values;

    showLoader();
    try {
      await TreatmentEvaluationApi.saveAngleRating({
        id,
        avaliacao: avaliacao_id,
        classificacao_angle: angle && angle.value,
        dente6_direita: thoth_right_6 && thoth_right_6.value,
        dente3_direita: thoth_right_3 && thoth_right_3.value,
        dente3_esquerda: thoth_left_3 && thoth_left_3.value,
        dente6_esquerda: thoth_left_6 && thoth_left_6.value,
        classe_esqueletica: classificacao_esqueletica && classificacao_esqueletica.value,
        padrao_esqueletico: padrao_esqueletico && padrao_esqueletico.value
      });
      updateParent();
      closeModal();
    } catch (e) {
      console.log(e);
      this.setState({
        message: {
          display: true,
          type: 'danger',
          text: 'Erro ao salvar classificação de angle entre em contato com o suporte'
        }
      });
    }

    hideLoader();
    console.log(values);
  };

  render() {
    const { message } = this.state;
    const { closeModal, modal = false, formLoad = {} } = this.props;
    const {
      formData: { toothOptions = [], anglesOptions = [], skeletalClassOptions = [], skeletalPatternsOptions = [] } = {},
      formInit = {}
    } = formLoad;

    return (
      <Modal isOpen={modal} toggle={closeModal} className={this.props.className} size={'lg'}>
        <ModalHeader
          className='title-primary'
          toggle={closeModal}
          close={
            <button className='close' onClick={closeModal}>
              &times;
            </button>
          }
        >
          <strong>Editar</strong> Classificação de Angle
        </ModalHeader>

        <Formik
          validationSchema={this.validationForm}
          validateOnChange={false}
          initialValues={formInit}
          onSubmit={this.saveAngleRating}
        >
          {({ errors, values, handleSubmit, handleChange }) => (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <MessagesComponent display={message.display} type={message.type} message={message.text} />
                <div className='margin-top-10'>
                  <Row className='margin-top-10'>
                    <Col sm='12'>
                      <label className='mb-sm-n1'>Classificação de Angle</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <Select
                        value={values.angle}
                        isSearchable={true}
                        ignoreAccents={true}
                        ignoreCase={true}
                        options={anglesOptions}
                        placeholder={''}
                        styles={selectStyles}
                        onChange={(p) => reactSelectHandleChange(p, handleChange, 'angle')}
                      />
                    </Col>
                  </Row>

                  <Row className='margin-top-10'>
                    <Col>
                      <Row>
                        <Col sm={1} style={{ alignSelf: 'center' }}>
                          Dir
                        </Col>
                        <Col sm={10}>
                          <Container>
                            <Row
                              style={{
                                borderBottomColor: '#F2F2F2',
                                borderBottom: 1,
                                borderBottomStyle: 'solid',
                                paddingBottom: 0,
                                height: 35
                              }}
                            >
                              <Col style={{ textAlign: 'center', paddingTop: 10 }}>6</Col>
                              <Col style={{ textAlign: 'center', paddingTop: 10 }}>3</Col>
                              <Col
                                style={{ textAlign: 'center', paddingTop: 10, borderLeft: 1, borderLeftStyle: 'solid' }}
                              >
                                3
                              </Col>
                              <Col style={{ textAlign: 'center', paddingTop: 10 }}>6</Col>
                            </Row>
                            <Row style={{ marginRight: 20 }}>
                              <Col style={{ paddingTop: 5 }} sm={3}>
                                <Select
                                  value={values.thoth_right_6}
                                  isSearchable={true}
                                  ignoreAccents={true}
                                  ignoreCase={true}
                                  options={toothOptions}
                                  placeholder={''}
                                  styles={selectStyles}
                                  onChange={(selectedOption) => {
                                    handleChange({
                                      target: {
                                        name: 'thoth_right_6',
                                        value: selectedOption
                                      }
                                    });
                                  }}
                                />
                              </Col>
                              <Col style={{ paddingTop: 5 }} sm={3}>
                                <Select
                                  value={values.thoth_right_3}
                                  isSearchable={true}
                                  ignoreAccents={true}
                                  ignoreCase={true}
                                  options={toothOptions}
                                  placeholder={''}
                                  styles={selectStyles}
                                  onChange={(selectedOption) => {
                                    handleChange({
                                      target: {
                                        name: 'thoth_right_3',
                                        value: selectedOption
                                      }
                                    });
                                  }}
                                />
                              </Col>
                              <Col style={{ borderLeft: 1, borderLeftStyle: 'solid', paddingTop: 5 }} sm={3}>
                                <Select
                                  value={values.thoth_left_3}
                                  isSearchable={true}
                                  ignoreAccents={true}
                                  ignoreCase={true}
                                  options={toothOptions}
                                  placeholder={''}
                                  styles={selectStyles}
                                  onChange={(selectedOption) => {
                                    handleChange({
                                      target: {
                                        name: 'thoth_left_3',
                                        value: selectedOption
                                      }
                                    });
                                  }}
                                />
                              </Col>
                              <Col style={{ paddingTop: 5 }} sm={3}>
                                <Select
                                  value={values.thoth_left_6}
                                  isSearchable={true}
                                  ignoreAccents={true}
                                  ignoreCase={true}
                                  options={toothOptions}
                                  placeholder={''}
                                  styles={selectStyles}
                                  onChange={(selectedOption) => {
                                    handleChange({
                                      target: {
                                        name: 'thoth_left_6',
                                        value: selectedOption
                                      }
                                    });
                                  }}
                                />
                              </Col>
                            </Row>
                          </Container>
                        </Col>
                        <Col sm={1} style={{ alignSelf: 'center' }}>
                          Esq
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className='margin-top-10'>
                    <Col sm='6'>
                      <label className='mb-sm-n1'>Classificação Esquelética</label>
                    </Col>
                    <Col sm='6'>
                      <label className='mb-sm-n1'>Padrão Esquelético Facial</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Select
                        value={values.classificacao_esqueletica}
                        isSearchable={true}
                        ignoreAccents={true}
                        ignoreCase={true}
                        options={skeletalClassOptions}
                        placeholder={''}
                        styles={selectStyles}
                        onChange={(selectedOption) => {
                          handleChange({
                            target: {
                              name: 'classificacao_esqueletica',
                              value: selectedOption
                            }
                          });
                        }}
                      />
                    </Col>
                    <Col sm={6}>
                      <Select
                        value={values.padrao_esqueletico}
                        isSearchable={true}
                        ignoreAccents={true}
                        ignoreCase={true}
                        options={skeletalPatternsOptions}
                        placeholder={''}
                        styles={selectStyles}
                        onChange={(selectedOption) => {
                          handleChange({
                            target: {
                              name: 'padrao_esqueletico',
                              value: selectedOption
                            }
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </ModalBody>
              <ModalFooter>
                <Row>
                  <Col sm={{ size: 1, offset: 8 }}>
                    <button className='button-primary'>Salvar</button>
                  </Col>
                  <Col sm={{ size: 2, offset: 1 }}>
                    <button className='button-tertiary' type={'button'} onClick={closeModal}>
                      Cancelar
                    </button>
                  </Col>
                </Row>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = ({ TreatmentEvaluationReducer: { evaluationAngleRating = {} } } = {}) => {
  const { formLoad = {} } = evaluationAngleRating;
  const { formData = {}, angleRating = {} } = formLoad;
  const { angles = [], teeth = [], skeletalClass = [], skeletalPatterns = [] } = formData;

  const defaultOption = { value: null, label: '' };
  const toothOptions = teeth.map((t) => ({ value: t.id, label: t.descricao }));
  const anglesOptions = angles.map((t) => ({ value: t.id, label: t.descricao }));
  const skeletalClassOptions = skeletalClass.map((t) => ({ value: t.id, label: t.descricao }));
  const skeletalPatternsOptions = skeletalPatterns.map((t) => ({ value: t.id, label: t.descricao }));

  toothOptions.unshift(defaultOption);
  anglesOptions.unshift(defaultOption);
  skeletalClassOptions.unshift(defaultOption);
  skeletalPatternsOptions.unshift(defaultOption);

  return {
    ...evaluationAngleRating,
    formLoad: {
      ...formLoad,
      formInit: {
        angle:
          angleRating.classificacao_angle && anglesOptions.find((a) => a.value === angleRating.classificacao_angle.id),
        thoth_right_6:
          angleRating.dente6_direita && toothOptions.find((t) => t.value === angleRating.dente6_direita.id),
        thoth_right_3:
          angleRating.dente3_direita && toothOptions.find((t) => t.value === angleRating.dente3_direita.id),
        thoth_left_3:
          angleRating.dente3_esquerda && toothOptions.find((t) => t.value === angleRating.dente3_esquerda.id),
        thoth_left_6:
          angleRating.dente6_esquerda && toothOptions.find((t) => t.value === angleRating.dente6_esquerda.id),
        classificacao_esqueletica:
          angleRating.classe_esqueletica &&
          skeletalClassOptions.find((s) => s.value === angleRating.classe_esqueletica.id),
        padrao_esqueletico:
          angleRating.padrao_esqueletico &&
          skeletalPatternsOptions.find((s) => s.value === angleRating.padrao_esqueletico.id)
      },
      formData: {
        ...formData,
        toothOptions,
        anglesOptions,
        skeletalClassOptions,
        skeletalPatternsOptions
      }
    }
  };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  closeModal: () => dispatch(treatmentEvaluationOpenAngleRating({ modal: false }))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(TreatmentEvaluationAngleRatingModal);
