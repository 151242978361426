import axios from 'axios';
import env from '../environment/env';

export default class ImageApi {
  static resizedImage = async (file, width, height) => {
    const formData = new FormData();
    formData.append('file', file);
    console.log(env.imaginary.baseUrl);
    return axios
      .post(`${env.imaginary.baseUrl}/resize?width=${width}&height=${height}&type=jpeg`, formData, {
        responseType: 'arraybuffer'
      })
      .then((data) => {
        let u8 = new Uint8Array(data.data);
        let b64encoded = btoa(
          [].reduce.call(
            new Uint8Array(data.data),
            function (p, c) {
              return p + String.fromCharCode(c);
            },
            ''
          )
        );
        // let mimetype = 'image/jpeg';
        return b64encoded;
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  };

  static convertImage = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    console.log(env.imaginary.baseUrl);
    return axios
      .post(`${env.imaginary.baseUrl}/convert?&type=jpeg&quality=72`, formData, {
        responseType: 'arraybuffer'
      })
      .then((data) => {
        let u8 = new Uint8Array(data.data);
        let b64encoded = btoa(
          [].reduce.call(
            new Uint8Array(data.data),
            function (p, c) {
              return p + String.fromCharCode(c);
            },
            ''
          )
        );
        // let mimetype = 'image/jpeg';
        return b64encoded;
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  };

  static thumbnailImage = async (file, width, height) => {
    const formData = new FormData();
    formData.append('file', file);
    console.log(env.imaginary.baseUrl);
    return axios
      .post(
        `${env.imaginary.baseUrl}/thumbnail?width=${width}&height=${height}&type=jpeg&embed=true&extend=white`,
        formData,
        {
          responseType: 'arraybuffer'
        }
      )
      .then((data) => {
        let u8 = new Uint8Array(data.data);
        let b64encoded = btoa(
          [].reduce.call(
            new Uint8Array(data.data),
            function (p, c) {
              return p + String.fromCharCode(c);
            },
            ''
          )
        );
        // let mimetype = 'image/jpeg';
        return b64encoded;
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  };
}
