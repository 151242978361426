import { SEND_MESSAGE } from '../actions/ErrorMessage.actions';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case SEND_MESSAGE:
      return action.data;
    default:
      return state;
  }
};
