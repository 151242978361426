import React, { Component } from 'react';

import { Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import minus from '../../../../assets/img/icons/minus.svg';
import plus from '../../../../assets/img/icons/plus.svg';
import Icon from '../../../../components/Icon.component';

import { FieldArray, Form, Formik } from 'formik';

import { connect } from 'react-redux';
import { compose } from 'recompose';

import { hideLoader, showLoader } from '../../../../components/actions/Loader.actions';
import MessagesComponent from '../../../../components/Messages.component';
import { clinicalRecordTreatmentStrategiesModal } from '../redux/TreatmentClinicalRecord.action';
import TreatmentClinicalRecordApi from '../../../../service/TreatmentClinicalRecordApi';
import { propsModalSelector } from '../selectors/TreatmentClinicalRecordTreatmentStrategiesModalSelector';

class TreatmentClinicalRecordTreatmentPlanStrategiesModal extends Component {
  state = {
    message: {
      display: false,
      type: 'danger',
      text: ''
    }
  };
  arrayFieldControle;

  saveStrategies = async (values) => {
    const { showLoader, hideLoader, updateParent, closeModal, treatment, record, plan } = this.props;

    showLoader();
    try {
      const strategies = values.strategies.filter((g) => g !== '');

      await TreatmentClinicalRecordApi.saveTreatmentPlanStrategies({
        treatmentId: treatment,
        recordId: record,
        planId: plan,
        strategies
      });
      updateParent();
      closeModal();
    } catch (e) {
      console.log(e);
      this.setState({
        message: {
          display: true,
          type: 'danger',
          text: 'Erro ao salvar lista de Estratégias de Tratamento entre em contato com o suporte'
        }
      });
    }
    hideLoader();
  };

  render() {
    const { message } = this.state;
    const { modal = false, closeModal, strategies = [''] } = this.props;

    return (
      <Modal isOpen={modal} toggle={closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={closeModal}
          close={
            <button className='close' onClick={closeModal}>
              &times;
            </button>
          }
        >
          <strong>Adicionar Estratégias</strong>
        </ModalHeader>
        <Formik validateOnChange={false} initialValues={{ strategies }} onSubmit={this.saveStrategies}>
          {({ errors, values, handleSubmit, handleChange }) => (
            <Form>
              <MessagesComponent display={message.display} type={message.type} message={message.text} />
              <ModalBody>
                <div className='margin-top-10'>
                  <Row>
                    <Col>
                      <h1 className='title-modal float-left'>Lista de Estratégias</h1>
                      <a
                        href={'#'}
                        style={{ marginTop: -5 }}
                        className='float-left ml-2'
                        onClick={() => this.arrayFieldControle.push('')}
                      >
                        <Icon className='icon icon-size-two' image={plus} alt='plus' />
                      </a>
                    </Col>
                  </Row>
                  <FieldArray
                    name='strategies'
                    render={(p) => {
                      this.arrayFieldControle = p;
                      return (
                        <div>
                          {values.strategies.map((i, index) => (
                            <Row className='margin-top-10' key={index}>
                              <Col sm='1'>
                                <label className='pt-2'>{index + 1}.</label>
                              </Col>
                              <Col sm={10}>
                                <Input
                                  style={{ marginLeft: -12, width: 'calc(100% + 12px)' }}
                                  name={`strategies.${index}`}
                                  onChange={handleChange}
                                  value={values.strategies[index]}
                                  className='input-secondary'
                                  type='text'
                                  maxLength={70}
                                ></Input>
                              </Col>
                              <Col sm='1'>
                                <a
                                  href={'#'}
                                  onClick={() => {
                                    p.remove(index);
                                    if (values.strategies.length === 1) {
                                      p.push('');
                                    }
                                  }}
                                >
                                  <Icon className='icon icon-size-two absolute-center' image={minus} alt='minus' />
                                </a>
                              </Col>
                            </Row>
                          ))}
                        </div>
                      );
                    }}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <Row>
                  <Col className='btn-list' sm={{ size: 6, offset: 6 }}>
                    <button className='button-primary'>Salvar</button>
                    <button className='button-tertiary' type={'button'} onClick={closeModal}>
                      Cancelar
                    </button>
                  </Col>
                </Row>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (state = {}) => {
  return {
    ...propsModalSelector(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  closeModal: () => dispatch(clinicalRecordTreatmentStrategiesModal({ modal: false }))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(TreatmentClinicalRecordTreatmentPlanStrategiesModal);
