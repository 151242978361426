import React, { Component } from 'react';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  scheduleAppointmentPatientSagaModal,
  scheduleAppointmentSelectTreatmentModal
} from '../redux/Schedule.actions';
import { scheduleAppointmentTreatmentReducerToProps } from '../selectors/ScheduleAppointmentTreatmentModal.selector';
import { dateFormat } from '../../../utils/Formatter';
import MessagesComponent from '../../../components/Messages.component';
import { t } from 'typy';

class ScheduleAppointmentSelectTreatmentModal extends Component {
  state = {
    treatmentSelected: {},
    message: {
      display: false,
      text: ''
    }
  };

  selectElement = (tid, id) => {
    let t = document.getElementById(tid);
    let tbody = t.getElementsByTagName('tbody')[0];
    let trs = tbody.getElementsByTagName('tr');
    for (var i = 0; i < trs.length; i++) {
      trs[i].classList.remove('active');
    }
    for (var i = 0; i < trs.length; i++) {
      if (trs[i].getAttribute('data-key') == id) {
        trs[i].classList.add('active');
      }
    }
  };

  closeModal = () => {
    this.props.closeModal();
    this.setState({
      treatmentSelected: {},
      message: {
        display: false,
        text: ''
      }
    });
  };

  nextStep = () => {
    const { treatmentSelected } = this.state;
    if (t(treatmentSelected, 'id').isUndefined) {
      this.setState({
        message: {
          display: true,
          text: 'Selecione um tratamento'
        }
      });
    } else {
      const { date, dentist, chair, start, end, patient, next, procedure, controleRetorno } = this.props;

      next({
        date,
        modal: true,
        dentist,
        chair,
        start,
        end,
        patient,
        treatment: treatmentSelected,
        procedure,
        controleRetorno
      });
      this.closeModal();
    }
  };

  render() {
    const { treatmentSelected, message } = this.state;
    const { modal, patient = {}, treatments = [] } = this.props;

    return (
      <Modal isOpen={modal} toggle={this.closeModal} className={this.props.className + ' modal-default'}>
        <ModalHeader
          className='title-primary'
          toggle={this.closeModal}
          close={
            <button className='close' onClick={this.closeModal}>
              &times;
            </button>
          }
        >
          <strong>Agendar Consulta</strong>
        </ModalHeader>
        <ModalBody>
          <MessagesComponent display={message.display} type={'danger'} message={message.text} />
          <div className='margin-top-10'>
            <Row>
              <Col className='text-left' sm='12'>
                <p className='text-default'>
                  <strong>Paciente</strong> {patient.name}
                </p>
              </Col>
            </Row>
            <Row className='margin-top-10'>
              <Col className='text-left' sm='12'>
                <h1 className='title-modal'>Selecione o Tratamento</h1>
              </Col>
            </Row>
          </div>
          <Row>
            <Col sm='12'>
              <Table id={'tablefy'} responsive>
                <thead>
                  <tr>
                    <th width='5%'></th>
                    <th>Tratamento</th>
                    <th>Especialidade</th>
                    <th>Avaliação</th>
                  </tr>
                </thead>
                <tbody>
                  {treatments.map((t) => {
                    return (
                      <tr
                        onClick={() => {
                          this.setState({ treatmentSelected: t });
                          this.selectElement('tablefy', t.id);
                        }}
                        data-key={t.id}
                        key={t.id}
                      >
                        <td>
                          <input
                            onChange={null}
                            type='checkbox'
                            className='form-checkbox'
                            id={'check-one'}
                            checked={treatmentSelected.id === t.id}
                          />
                        </td>
                        <td>{t.tratamento}</td>
                        <td>Ortodontia</td>
                        <td>{dateFormat(t.data_avaliacao, 'DD/MM/YYYY')}</td>
                      </tr>
                    );
                  })}
                  <tr
                    onClick={() => {
                      this.setState({ treatmentSelected: { id: 0 } });
                      this.selectElement('tablefy', 0);
                    }}
                    data-key='0'
                    key={t.id}
                  >
                    <td>
                      <input
                        onChange={null}
                        id={'check-one'}
                        type='checkbox'
                        className='form-checkbox'
                        checked={treatmentSelected.id === 0}
                      />
                    </td>
                    <td colSpan={3}> Novo Tratamento/ Primeira Consulta /Consulta Avulsa</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col sm={{ size: 6, offset: 6 }}>
              <div className='btn-list'>
                <button className='button-primary' onClick={this.nextStep}>
                  Próximo
                </button>
                <button className='button-tertiary' onClick={this.closeModal}>
                  Cancelar
                </button>
              </div>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...scheduleAppointmentTreatmentReducerToProps(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(scheduleAppointmentSelectTreatmentModal({ modal: false })),
  next: (data) => dispatch(scheduleAppointmentPatientSagaModal(data))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(ScheduleAppointmentSelectTreatmentModal);
