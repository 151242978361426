import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { getClinic } from '../../utils/localStorage/SessionStorageUtils';

class ListScheduleMenu extends Component {
  state = {
    tab: 'A',
    clinic: {}
  };

  selectedTab(tab) {
    this.setState({ tab });
    this.props.selectedTab(tab);
  }

  componentDidMount() {
    const clinic = getClinic();
    this.setState({
      clinic
    });
  }

  render() {
    const tab = this.state.tab;
    return (
      <Row
        className='row content-box-without-color'
        style={{
          marginTop: -25
        }}
      >
        <Col sm='12'>
          <nav className='nav-inline border-bottom-0'>
            <ul style={{ marginBottom: 0, paddingTop: '7px' }}>
              <li style={{ marginRight: '10px' }} className={tab === 'A' ? 'active' : ''}>
                <a href='#' onClick={() => this.selectedTab('A')}>
                  Agendar / Reagendar
                </a>
              </li>
              {/*
              <li className={tab === 'F' ? 'active' : ''}>
                <a href='#' onClick={() => this.selectedTab('F')}>
                  GPS - Ortodontia
                </a>
              </li>
              */}
              <li style={{ marginLeft: '10px' }} className={tab === 'CR' ? 'active' : ''}>
                <a href='#' onClick={() => this.selectedTab('CR')}>
                  Controle e Contenção
                </a>
              </li>
            </ul>
          </nav>
          <hr style={{ marginTop: -3 }} />
        </Col>
      </Row>
    );
  }
}

ListScheduleMenu.propTypes = {
  dentistSelected: PropTypes.func
};

export default ListScheduleMenu;
