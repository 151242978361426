import React, { Component } from 'react';
import {
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table
} from 'reactstrap';
import Select from 'react-select';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  launchPaymentFormInit,
  launchPaymentToProps
} from '../selectors/FinancialFileEntriesLaunchPaymentModal.selector';
import { financialLaunchPaymentModal } from '../redux/FinancialFile.action';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { reactSelectHandleChange, reactSelectStyleIsError } from '../../../../utils/ReactSelectUtils';
import ErrorFieldWithoutBootstrap from '../../../../components/ErrorFieldWithoutBootstrap.component';
import CashAPI from '../../../../service/CashAPI';
import moment from 'moment';
import { hideLoader, showLoader } from '../../../../components/actions/Loader.actions';
import ToastUtils from '../../../../utils/ToastUtils';
import { selectStyles } from '../../../../../src/config/core.config';
import { t } from 'typy';
import InputDecimalComponent from '../../../../components/InputDecimalComponent';
import TagComprovante from '../../../../components/TagComprovante';
import { IfComponent } from '../../../../components/if.component';
import Icon from '../../../../components/Icon.component';
import arrowUp from '../../../../assets/img/icons/arrowup.svg';
import arrowDown from '../../../../assets/img/icons/arrow.svg';
import EmissionReferenceComponent from './EmissionReferenceComponent';
import { AiOutlineCreditCard } from 'react-icons/ai';
import { GoCreditCard } from 'react-icons/go';
import { FaMoneyCheck } from 'react-icons/fa';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { MdReceipt } from 'react-icons/md';
import { AiOutlineBarcode } from 'react-icons/ai';
import { dateFormat, formatReal } from '../../../../utils/Formatter';

class TreatmentFinancialLaunchPaymentModal extends Component {
  state = {
    showEmission: false,
    showParcialMessage: false,
    showValueGreater: false
  };

  PAYMENT_TYPE = 1;

  schemaValidation = Yup.object().shape({
    valor: Yup.number().required('Valor Obrigatório'),
    forma_pagamento: Yup.object().shape({
      value: Yup.number().required('Forma de Pagamento Obrigatória')
    })
  });

  iconesPagamento = {
    CD: <AiOutlineCreditCard size={20} className='mb-1' />,
    CC: <GoCreditCard size={20} className='mb-1' />,
    C: <FaMoneyCheck size={20} className='mb-1' />,
    D: <FaRegMoneyBillAlt size={20} className='mb-1' />,
    T: <MdReceipt size={20} className='mb-1' />,
    B: <AiOutlineBarcode size={20} className='mb-1' />
  };

  closeModal = () => {
    const { closeModal } = this.props;
    this.setState({
      showEmission: false,
      showParcialMessage: false,
      showValueGreater: false
    });
    closeModal();
  };

  launchPayment = (values, actions) => {
    const { showLoader, hideLoader, updateParent, receipt, paymentEntries } = this.props;

    showLoader();

    let voucher = {};
    if (t(receipt, 'sigla').safeString === 'SS') {
      if (t(values, 'referencesByEmission.emission_type.id').safeString === 'V') {
        let valid = true;
        if (!t(values, 'dentist.value').isDefined) {
          actions.setFieldError('dentist.value', 'Dentista Obrigatório');
          valid = false;
        }

        if (t(values, 'cpf').isEmptyString) {
          actions.setFieldError('cpf', 'CPF Obrigatório');
          valid = false;
        }

        if (t(values, 'cro').isEmptyString) {
          actions.setFieldError('cro', 'CRO Obrigatório');
          valid = false;
        }

        if (!t(values, 'cro_uf.value').isDefined) {
          actions.setFieldError('cro_uf.value', 'CRO UF Obrigatório');
          valid = false;
        }

        if (!valid) {
          this.setState({ showEmission: true });
          return;
        } else {
          voucher = {
            dentist: t(values, 'dentist.value').safeNumber,
            emission: t(values, 'dentist.label').safeString,
            cpf: t(values, 'cpf').safeString,
            cro: t(values, 'cro').safeString,
            cro_uf: t(values, 'cro_uf.label').safeString
          };
        }
      } else {
        if (t(values, 'referencesByEmission.voucher_type.id').safeString === 'R') {
          voucher = {
            dentist: t(values, 'referencesByEmission.dentist.id').safeNumber,
            emission: t(values, 'referencesByEmission.dentist.nome_completo').safeString,
            cpf: t(values, 'referencesByEmission.cpf').safeString,
            cro: t(values, 'referencesByEmission.cro').safeString,
            cro_uf: t(values, 'referencesByEmission.cro_uf').safeString,
            assinatura: t(values, 'referencesByEmission.signature').safeString
          };
        } else {
          voucher = {
            emission: t(values, 'referencesByEmission.razao_social').safeString,
            cnpj: t(values, 'referencesByEmission.cnpj').safeString
          };
        }
      }
    }

    CashAPI.launchPayment({
      id: values.id,
      tipo_lancamento: this.PAYMENT_TYPE,
      data_lancamento: moment().format('YYYY-MM-DDTHH:mm:ss'),
      descricao: 'Pagamento',
      anotacao: values.anotacao,
      forma_pagamento: values.forma_pagamento.data.id,
      referencia: values.referencia,
      valor_lancamento: values.valor,
      ficha_financeira: values.financialFile,
      voucher,
      paymentEntries
    })
      .then((d) => {
        updateParent();
        hideLoader();
        this.closeModal();
      })
      .catch((err) => {
        hideLoader();
        const erroData = t(err, 'response.data.exception');
        if (erroData.isDefined && erroData.safeString.includes('Nenhuma caixa aberto')) {
          ToastUtils.error('Caixa Fechado por favor abra seu caixa para fazer o lançamento');
        } else {
          ToastUtils.error('Erro ao lançar pagamento entre em contato com o suporte');
        }
      });
  };

  showHideEmissionReferences = () => {
    this.setState({ showEmission: !this.state.showEmission });
  };

  render() {
    const { modal, formInit, paymentTypes, receipt = {}, referencesByEmission, paymentEntries } = this.props;
    const { showEmission, showParcialMessage, showValueGreater } = this.state;
    let totalValue = paymentEntries.reduce((total, item) => total += item.valor_debito, 0);

    return (
      <Modal isOpen={modal} toggle={this.closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={this.closeModal}
          close={
            <button className='close' onClick={this.closeModal}>
              &times;
            </button>
          }
        >
          Receber Pagamento
        </ModalHeader>
        <Formik
          initialValues={formInit}
          onSubmit={this.launchPayment}
          validationSchema={this.schemaValidation}
          validateOnChange={false}
        >
          {({ errors, values, handleChange }) => {
            return (
              <Form>
                <ModalBody>
                  <div className='margin-top-10'>
                    <Row className='margin-top-10'>
                      <Col sm='7'>
                        <label>
                          Valor <span>*</span>
                        </label>
                        <InputGroup className='reversed'>
                          <InputDecimalComponent
                            name={'valor'}
                            handleChange={handleChange}
                            valueChange={(fieldValue) => {
                              this.setState({
                                showParcialMessage: fieldValue.value < totalValue,
                                showValueGreater: fieldValue.value > totalValue
                              });
                            }}
                            invalid={errors.valor ? true : false}
                            readOnly={paymentEntries.length > 1}
                            value={values.valor}
                            style={{ fontSize: 14, fontWeight: 600 }}
                          />
                          <InputGroupAddon addonType='prepend'>
                            <span style={{ fontSize: 14, fontWeight: 600 }}>R$</span>
                          </InputGroupAddon>
                        </InputGroup>
                        <ErrorFieldWithoutBootstrap fieldName={'valor'} errors={errors} />
                        <IfComponent conditional={showParcialMessage}>
                          <Row>
                            <Col sm='12' className='p-1 text-xsm text-danger font-bold'>
                              Atenção: Pagamento Parcial
                            </Col>
                          </Row>
                        </IfComponent>
                        <IfComponent conditional={showValueGreater}>
                          <Row>
                            <Col sm='12' className='p-1 text-xsm text-danger font-bold'>
                              Atenção: Pagamento não pode ser maior que {formatReal(totalValue)}
                            </Col>
                          </Row>
                        </IfComponent>
                      </Col>
                    </Row>

                    {/*<Row>*/}
                    {/*  <Col sm={ 12 }>*/}
                    {/*    <Select*/}
                    {/*      options={ paymentTypesOptions }*/}
                    {/*      value={ values.forma_pagamento }*/}
                    {/*      isSearchable={ true }*/}
                    {/*      ignoreAccents={ true }*/}
                    {/*      ignoreCase={ true }*/}
                    {/*      placeholder={ '' }*/}
                    {/*      styles={ reactSelectStyleIsError(errors, 'forma_pagamento', selectStyles) }*/}
                    {/*      onChange={ (e) => reactSelectHandleChange(e, handleChange, 'forma_pagamento') }*/}
                    {/*    />*/}
                    {/*    <ErrorFieldWithoutBootstrap fieldName={ 'forma_pagamento.value' } errors={ errors }/>*/}
                    {/*  </Col>*/}
                    {/*</Row>*/}

                    {/* TRECHO DE CÓDIGO DOS BUTTONS PARA 'FORMA_PAGAMENTO' */}

                    <Row className='margin-top-20'>
                      <Col sm='12' className='p-1 text-secondary text-sm text-uppercase font-bold'>
                        Débito(s)
                      </Col>
                    </Row>

                    <Table responsive>
                      <tbody className='text-secondary font-bold'>
                        {paymentEntries.map((p) => {
                          return (
                            <tr key={p.id}>
                              <th scope='row' style={{ padding: '0.5rem' }}>
                                {p.numero_lancamento}
                              </th>
                              <td style={{ padding: '0.5rem' }}>{dateFormat(p.data_lancamento, 'DD/MM/YYYY')}</td>
                              <td style={{ padding: '0.5rem' }}>{p.descricao}</td>
                              <td style={{ padding: '0.5rem' }}>{formatReal(p.valor_debito)}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>

                    <Row className='margin-top-20'>
                      <Col sm='12'>
                        <label>
                          Forma de Pagamento <span>*</span>
                        </label>
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        className='btn-group'
                        sm={{ size: 12, offset: 0 }}
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          textAlign: 'center',
                          margin: '0px 10px 10px 0px'
                        }}
                      >
                        {paymentTypes.map((p) => {
                          return (
                            <button
                              key={p.id}
                              type={'button'}
                              className='btn btn-default text-secondary font-light text-sm text-center'
                              style={{
                                backgroundColor: t(values, 'forma_pagamento.value').safeNumber == p.id ? '#ebecf2' : '',
                                borderRadius: '5px',
                                width: '140px',
                                height: '43px',
                                margin: 3
                              }}
                              onClick={() =>
                                reactSelectHandleChange({ value: p.id, data: p }, handleChange, 'forma_pagamento')
                              }
                            >
                              {this.iconesPagamento[p.tipoFormaPagamento.id]}&nbsp;&nbsp;{p.nome}
                            </button>
                          );
                        })}
                        <ErrorFieldWithoutBootstrap fieldName={'forma_pagamento.value'} errors={errors} />
                      </Col>
                    </Row>

                    <Row className='margin-top-10'>
                      <Col sm={12}>
                        <div className={'d-flex '}>
                          <label>Comprovante</label>
                          <IfComponent conditional={t(receipt, 'sigla').safeString === ''}>
                            <a href={'#'} className={'w-100 text-right'} onClick={this.showHideEmissionReferences}>
                              <Icon
                                className='icon icon-size-small margin-left-10'
                                image={!showEmission ? arrowDown : arrowUp}
                                alt='arrow'
                              />
                            </a>
                          </IfComponent>
                          <IfComponent conditional={t(receipt, 'sigla').safeString === 'SS'}>
                            <TagComprovante
                              color={receipt.color}
                              text={t(referencesByEmission, 'voucher_type.sigla').safeString}
                            />
                            <a href={'#'} className={'w-100 text-right'} onClick={this.showHideEmissionReferences}>
                              <Icon
                                className='icon icon-size-small margin-left-10'
                                image={!showEmission ? arrowDown : arrowUp}
                                alt='arrow'
                              />
                            </a>
                          </IfComponent>

                          <IfComponent conditional={t(receipt, 'sigla').safeString === 'NN'}>
                            <TagComprovante color={receipt.color} text={receipt.sigla} />
                            <a href={'#'} className={'w-100 text-right'} onClick={this.showHideEmissionReferences}>
                              <Icon
                                className='icon icon-size-small margin-left-10'
                                image={!showEmission ? arrowDown : arrowUp}
                                alt='arrow'
                              />
                            </a>
                          </IfComponent>
                        </div>
                      </Col>
                    </Row>
                    <IfComponent conditional={showEmission}>
                      <IfComponent conditional={t(receipt, 'sigla').safeString === 'SS'}>
                        <EmissionReferenceComponent
                          referencesByEmission={referencesByEmission}
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                        />
                      </IfComponent>

                      <Row className='margin-top-10'>
                        <Col sm='12'>
                          <label>Nr Referência </label>
                          <input
                            className='input-secondary'
                            type='text'
                            name='referencia'
                            value={values.referencia}
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>

                      <Row className='margin-top-10'>
                        <Col sm='12'>
                          <label>Anotação</label>
                          <textarea
                            className='form-control'
                            rows='2'
                            cols='50'
                            value={values.anotacao}
                            name={'anotacao'}
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>
                    </IfComponent>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Row>
                    <Col className='btn-list' sm={{ size: 6, offset: 6 }}>
                      <button className='button-tertiary' type={'button'} onClick={this.closeModal}>
                        Cancelar
                      </button>
                      <button className='button-primary' type='submit' disabled={showValueGreater}>
                        Receber
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...launchPaymentToProps(state),
    formInit: launchPaymentFormInit(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  closeModal: () => dispatch(financialLaunchPaymentModal({ modal: false }))
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(TreatmentFinancialLaunchPaymentModal);
