import t from 'typy';
import { createSelector } from 'reselect';
import ProceduresAPI from '../../../service/ProceduresAPI';

const scheduleModalSelector = (state) => t(state, 'ScheduleReducer.scheduleAppointmentPatientModal').safeObject;

export const scheduleAppointmentFinalStepReducerToProps = createSelector(
  scheduleModalSelector,
  (scheduleAppointmentPatientModal = {}) => {
    const {
      modal,
      dentist,
      chair,
      date,
      start,
      end,
      patient,
      treatment,
      nextSchedule,
      scheduleConfig,
      categories = [],
      procedures = [],
      redialBy,
      onSave,
      controleRetorno
    } = scheduleAppointmentPatientModal;

    const categoriesOptions = categories.map((c) => ({ label: c.nome, value: c.id, data: c }));
    const proceduresOptions = procedures.map((p) => ({ label: p.nome, value: p.id, data: p }));

    return {
      modal,
      dentist,
      chair,
      date,
      start,
      end,
      patient,
      treatment,
      nextSchedule,
      scheduleConfig,
      categories: categoriesOptions,
      proceduresOptions,
      redialBy,
      onSave,
      controleRetorno
    };
  }
);

export const scheduleAppointmentFinalStepForm = createSelector(
  scheduleModalSelector,
  (scheduleAppointmentPatientModal = {}) => {
    const {
      scheduleConfig = {},
      categories = [],
      procedures = [],
      schedule = {},
      procedure
    } = scheduleAppointmentPatientModal;

    let categoryOption = {};
    let proceduresOption = {};
    let procedimento_categoria_rgb = '';
    let procedimento_codinome = '';
    let procedimento_nome = '';
    let procedimento_tempo = 15;
    let pendencia = false;
    let pendencia_obs = '';
    if (t(schedule.procedimento).isDefined) {
      categoryOption =
        scheduleConfig && t(categories).isArray
          ? categories.find((c) => c.id === parseInt(schedule.procedimento.categoria.id))
          : {};
      proceduresOption =
        procedures && t(procedures).isArray ? procedures.find((c) => c.id === parseInt(schedule.procedimento.id)) : {};
      procedimento_categoria_rgb = t(schedule, 'procedimento.categoria.rgb').safeString;
      procedimento_codinome = t(schedule, 'procedimento.codinome').safeString;
      procedimento_tempo = t(schedule, 'procedimento.tempo').safeString;
      procedimento_nome = t(schedule, 'procedimento.nome').safeString;
      pendencia = schedule.is_pendencia_laboratorio + '';
      pendencia_obs = schedule.pendencia_laboratorio;
    } else if (procedure) {
      categoryOption =
        scheduleConfig && t(categories).isArray
          ? categories.find((c) => c.id === parseInt(procedure.categoria.id))
          : {};
      proceduresOption =
        procedures && t(procedures).isArray ? procedures.find((c) => c.id === parseInt(procedure.id)) : {};
      procedimento_categoria_rgb = procedure.categoria.rgb;
      procedimento_codinome = procedure.codinome;
      procedimento_tempo = procedure.tempo;
      procedimento_nome = procedure.nome;
      pendencia = procedure.pendencia_laboratorio === 'S' ? 'true' : 'false';
      pendencia_obs = procedure.lembrete;
    } else {
      categoryOption =
        scheduleConfig && t(categories).isArray
          ? categories.find((c) => c.id === parseInt(scheduleConfig.procedimento_categoria_id))
          : {};
      proceduresOption =
        procedures && t(procedures).isArray
          ? procedures.find((c) => c.id === parseInt(scheduleConfig.procedimento_id))
          : {};
      procedimento_categoria_rgb = scheduleConfig.procedimento_categoria_rgb;
      procedimento_codinome = scheduleConfig.procedimento_codinome;
      procedimento_nome = scheduleConfig.procedimento_nome;
      procedimento_tempo = scheduleConfig.procedimento_tempo;

      if (scheduleConfig.procedimento_pendencia_laboratorio === 'S') {
        pendencia = 'true';
        pendencia_obs = scheduleConfig.procedimento_lembrete;
      }
    }

    return {
      id: schedule.id,
      anotacao: schedule.observacao,
      procedimento_categoria_rgb,
      procedimento_codinome,
      procedimento_nome,
      pendencia,
      pendencia_obs,
      category: categoryOption && { label: categoryOption.nome, value: categoryOption.id, data: categoryOption },
      procedure: proceduresOption && {
        label: proceduresOption.nome,
        value: proceduresOption.id,
        data: proceduresOption
      },
      procedimento_tempo
    };
  }
);
