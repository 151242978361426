import React, { Component } from 'react';

import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';

import TakePictureComponent from './TakePicture.component';

import ErrorMessageComponent from './ErrorMessage.component';

import Avatar from 'react-avatar-edit';
import ToastUtils from '../utils/ToastUtils';

export class AvatarModal extends Component {
  state = {
    picture: null,
    type: '',
    error: {
      type: '',
      display: '',
      message: ''
    },
    preview: null
  };

  choiceType = (value) => {
    this.setState({
      type: value
    });
  };

  setImage = (image) => {
    this.setState({
      picture: image,
      extension: 'jpg'
    });
  };

  cancelPicture = () => {
    this.setState({
      picture: null,
      extension: ''
    });
  };

  permissionsExtensions = ['.jpg', '.jpeg', '.png'];

  onCrop = (preview) => {
    this.setState({
      preview
    });
  };

  saveImage = (e) => {
    const { picture, extension } = this.state;
    this.props.callbackImage(picture, extension);
    this.cancelPicture();
    this.props.closeModal(e);
  };

  _previewImage = () => {
    if (!this.state.preview) {
      ToastUtils.error('Por favor selecione uma imagem!');
    }

    this.setState({
      picture: this.state.preview,
      extension: 'jpg'
    });
  };

  render() {
    const { picture, type, error } = this.state;
    const { display, closeModal, className, onlyUpload } = this.props;

    return (
      <div className='content-box-without-color'>
        <Modal isOpen={display && !picture} toggle={closeModal} className={className}>
          <ModalHeader toggle={closeModal}>Selecione a Imagem {!onlyUpload}</ModalHeader>
          <ModalBody>
            <div className={'content'}>
              <ErrorMessageComponent
                id={'avatar'}
                where={'avatar'}
                type={error.type}
                display={error.display}
                message={error.message}
              />
              <div className='input-file avatar-modal'>
                <Avatar
                  width={390}
                  height={295}
                  onCrop={this.onCrop}
                  label={'Selecione uma imagem'}
                  // borderStyle={{}}
                />
              </div>

              <br />
            </div>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col sm={{ size: 3, offset: 6 }}>
                <button className='button-primary' onClick={this._previewImage}>
                  Salvar
                </button>
              </Col>
              <Col sm='3'>
                <button className='button-tertiary' onClick={closeModal}>
                  Cancelar
                </button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
        <Modal isOpen={picture ? true : false} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Confirmar Imagem</ModalHeader>
          <ModalBody>
            <img src={picture} width={200} heigth={200} />
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col sm={{ size: 3, offset: 6 }}>
                <button className='button-primary' onClick={this.saveImage}>
                  Salvar
                </button>
              </Col>
              <Col sm='3'>
                <button className='button-tertiary' onClick={this.cancelPicture}>
                  Cancelar
                </button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default AvatarModal;
