import React, { useState, useEffect, useRef } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import userDefaultIcon from '../../assets/img/noavatar.png';
import { AiOutlineFolderOpen, AiOutlineInfoCircle } from 'react-icons/ai/index';
import Timer from './timer';
import t from 'typy';
import ReactTooltip from 'react-tooltip';
import StringUtils from '../../utils/StringUtils';
import moment from 'moment';
import TimerInMinutes from './timerInMinutes';
import { Link } from 'react-router-dom';
import { hideLoader, showLoader } from '../../components/actions/Loader.actions';
import FlowApi from '../../service/FlowApi';
import ToastUtils from '../../utils/ToastUtils';
import { useDispatch } from 'react-redux';
import FlowTimelineModal from './modals/FlowTimeline.modal';
import { formatNumberFlow } from './flow_utils';

const CardFlowAttended = ({ fluxo, callback }) => {
  console.log(fluxo);
  const modalInfo = useRef();
  const dispatch = useDispatch();
  const [aguardando, setAguardando] = useState({});
  const [emAtendimento, setEmAtendimento] = useState({});
  const [atendido, setAtendido] = useState({});

  useEffect(() => {
    setAguardando(fluxo.historicoStatus.find((s) => s.status === 'AGUARDANDO'));
    setEmAtendimento(fluxo.historicoStatus.find((s) => s.status === 'EM_ATENDIMENTO'));
    setAtendido(fluxo.historicoStatus.find((s) => s.status === 'ATENDIDO'));
  }, []);

  function getDiffeenceOfProcedure(entrada, saida) {
    const flowStart = moment(entrada);
    const timeToSchedule = moment(saida);
    const difference = flowStart.diff(timeToSchedule, 'minutes', true);

    if (difference > 0) {
      return <>{difference.toFixed(0)}</>;
    } else {
      return <>{difference.toFixed(0)}</>;
    }
  }

  function getDiffeenceOfProcedureNumber(entrada, saida) {
    const flowStart = moment(entrada);
    const timeToSchedule = moment(saida);
    const difference = flowStart.diff(timeToSchedule, 'minutes', true);
    return difference.toFixed(0);
  }

  async function nextFlow() {
    try {
      dispatch(showLoader());
      await FlowApi.nextFlow(fluxo.fluxoAtendimento);
      dispatch(hideLoader());
      callback();
    } catch (err) {
      console.error(err);
      dispatch(hideLoader());
      ToastUtils.error('Erro ao avançar no fluxo');
    }
  }

  function getColorEspera(tempo) {
    if (tempo >= 0 && tempo < 10) {
      return '#000000';
    } else if (tempo >= 10 && tempo < 20) {
      return '#dc3545';
    } else if (tempo >= 20) {
      return '#b83cef';
    }
  }

  function getColorTempoAtendimento(tempo) {
    if (tempo <= 0) {
      return '#000000';
    } else if (tempo > 0) {
      return '#dc3545';
    }
  }
  return (
    <>
      <div className='row'>
        <div className='col-sm-12'>
          <div className='mb-1'>
            <div className='card-body pt-2 pb-0 pl-0' style={{ maxHeight: '77px' }}>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='row'>
                    <div className='col-sm-2'>
                      <img
                        className='img-circle img-ldpi img-user-avatar'
                        style={{ minWidth: '55px', minHeight: '55px' }}
                        src={
                          t(fluxo, 'pacienteFoto').isDefined
                            ? t(fluxo, 'pacienteFoto').safeString
                            : userDefaultIcon
                        }
                        alt='avatar'
                      />
                    </div>
                    <div className='col-sm-10 p-1 px-1 py-2'>
                      <div className='row p-0'>
                        <div className='px-3'>
                          <p className='text-sm font-bold text-secondary'>
                            {t(fluxo, 'pacienteNome').safeString}
                          </p>
                        </div>
                      </div>
                      <div className='row py-1 ml-3'>
                        <ReactTooltip id='tooltipProcedimento' className='tooltip-griks' type='light'>
                          {t(fluxo, 'procedimentoNome').safeString}
                        </ReactTooltip>
                        <span
                          data-tip
                          data-for={'tooltipProcedimento'}
                          className='procedure badge-proc align-self-center px-2'
                          style={{
                            backgroundColor: t(fluxo, 'procedimentoCategoriaRGB').safeString,
                            fontSize: '10px'
                          }}
                        >
                          {t(fluxo, 'procedimentoCodiNome').safeString}
                        </span>

                        <ReactTooltip id='tooltipDentista' className='tooltip-griks' type='light'>
                          {fluxo.dentistaNome}
                        </ReactTooltip>
                        <a
                          data-tip
                          data-for={'tooltipDentista'}
                          href={'#'}
                          className='receipt-03'
                          style={{
                            fontSize: '10px',
                            backgroundColor: '#ebecf2',
                            border: '1px solid #ebecf2',
                            textDecoration: 'none',
                            cursor: 'default'
                          }}
                        >
                          {StringUtils.abbreviateString(fluxo.dentistaNome)}
                        </a>

                        <ReactTooltip id='tooltipArrivalTime' className='tooltip-griks' type='light'>
                          Chegada
                        </ReactTooltip>
                        <span
                          data-tip
                          data-for={'tooltipArrivalTime'}
                          className='text-secondary text-xsm text-danger font-bold ml-1'
                        >
                          {moment(aguardando.entrada).format('HH:mm')}'
                        </span>

                        <ReactTooltip id='tooltipWaitingTime' className='tooltip-griks' type='light'>
                          Espera
                        </ReactTooltip>
                        <span
                          data-tip
                          data-for={'tooltipWaitingTime'}
                          className='text-secondary text-xsm font-bold ml-2'
                        >
                          <span
                            style={{
                              color: getColorEspera(getDiffeenceOfProcedureNumber(aguardando.saida, aguardando.entrada))
                            }}
                          >
                            {getDiffeenceOfProcedure(aguardando.saida, aguardando.entrada)}'
                          </span>
                        </span>

                        <ReactTooltip id='tooltipMainTime' className='tooltip-griks' type='light'>
                          Diferença (Tempo atend - Tempo agendado)
                        </ReactTooltip>
                        <span data-tip data-for={'tooltipMainTime'} className='text-secondary text-xsm font-bold ml-2'>
                          <span
                            style={{
                              color: getColorTempoAtendimento(
                                getDiffeenceOfProcedureNumber(emAtendimento.saida, emAtendimento.entrada) -
                                  parseInt(fluxo.procedimentoTempo)
                              )
                            }}
                          >
                            {formatNumberFlow(
                              getDiffeenceOfProcedureNumber(emAtendimento.saida, emAtendimento.entrada) -
                                parseInt(fluxo.procedimentoTempo)
                            )}
                            '
                          </span>
                        </span>

                        <ReactTooltip id='tooltipFinalTime' className='tooltip-griks' type='light'>
                          Final do Atendimento
                        </ReactTooltip>
                        <span
                          data-tip
                          data-for={'tooltipFinalTime'}
                          className='text-secondary text-xsm text font-bold ml-2'
                        >
                          {moment(atendido.entrada).format('HH:mm')}
                        </span>

                        <ReactTooltip id='tooltipFinancialFile' className='tooltip-griks' type='light'>
                          Ficha Clínica
                        </ReactTooltip>
                        <Link to={`/patient/${fluxo.pacienteId}/treatment`}>
                          <a data-tip data-for={'tooltipFinancialFile'} className='d-flex align-self-start' href='#'>
                            <AiOutlineFolderOpen className='ml-2' size='18px' />
                          </a>
                        </Link>

                        <ReactTooltip id='tooltipTimelineFlowModal' className='tooltip-griks' type='light'>
                          Timeline do Atendimento
                        </ReactTooltip>
                        <span>
                          <a
                            data-tip
                            data-for={'tooltipTimelineFlowModal'}
                            className='d-flex align-self-start ml-2'
                            href={'#'}
                            onClick={() => modalInfo.current.openModal(fluxo)}
                          >
                            <AiOutlineInfoCircle size='18px' />
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FlowTimelineModal ref={modalInfo} />
    </>
  );
};

export default CardFlowAttended;
