class FormikUtils {
  static clearField = (handleChange, name, value) => {
    handleChange({
      target: {
        name,
        value
      }
    });
  };

  static setField = (e, handleChange, name) => {
    handleChange({
      target: {
        name,
        value: e.target.value
      }
    });
  };

  static setValueField = (handleChange, name, value) => {
    handleChange({
      target: {
        name,
        value
      }
    });
  };
}

export default FormikUtils;
