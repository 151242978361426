import { takeLatest } from 'redux-saga/effects';
import {
  TREATMENT_BUDGET_MODAL_PRE_PAYMENT_MANTENANCE,
  TREATMENT_BUDGET_PRE_MODAL_INFORMATIONS,
  TREATMENT_BUDGET_PRE_MODAL_TREATMENT_PLAN
} from '../redux/TreatmentBudget.actions';
import { modalInformation } from './TreatmentBudgetInformation.saga';
import { modalTreatmentPlan } from './TreatmentBudgetTreatmentPlan.saga';
import { modalTreatmentPaymentMantenance } from './TreatmentBudgetTreatmentPaymentMantenance.saga';

export function* watchTreatmentBudgetSagas() {
  yield takeLatest(TREATMENT_BUDGET_PRE_MODAL_INFORMATIONS, modalInformation);
  yield takeLatest(TREATMENT_BUDGET_PRE_MODAL_TREATMENT_PLAN, modalTreatmentPlan);
  yield takeLatest(TREATMENT_BUDGET_MODAL_PRE_PAYMENT_MANTENANCE, modalTreatmentPaymentMantenance);
}
