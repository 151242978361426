import t from 'typy';
import { createSelector } from 'reselect';
import ToastUtils from '../../../utils/ToastUtils';
import { showToast } from '../TostPatient.component';

const schedulePageSelector = (state) => t(state, 'ScheduleReducer.page').safeObject;
const scheduleRedialSelector = (state) => t(state, 'ScheduleReducer.redialCalendar').safeObject;
const nextAppointmentSelector = (state) => t(state, 'ScheduleReducer.nextAppointment').safeObject;
const scheduleFromPatientSelector = (state) => t(state, 'ScheduleReducer.scheduleFromPatient').safeObject;

export const scheduleReducerToProps = createSelector(
  schedulePageSelector,
  scheduleRedialSelector,
  nextAppointmentSelector,
  scheduleFromPatientSelector,
  (page = {}, redialCalendar, nextAppointment, scheduleFromPatient) => {
    const { dentist, config, officeHours, chairs, schedules, day } = page;

    return {
      dentist,
      config,
      officeHours,
      chairs,
      schedules,
      day,
      redial: redialCalendar,
      nextAppointment,
      patient: scheduleFromPatient
    };
  }
);
