import React, { Component } from 'react';
import ContactsListComponent from './components/ContactsList.component';
import { hideLoader, showLoader } from '../../components/actions/Loader.actions';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import ContactsDetailModal from './components/ContactsDetail.modal';
import ContactsSearchComponent from './components/ContactsSearch.component';
import ContactsSaveModal from './components/ContactsSave.modal';
import ErrorMessageComponent from '../../components/ErrorMessage.component';
import { Col, Row } from 'reactstrap';
import { setHeader } from '../template/redux/Content.actions';

import contact from '../../assets/img/icons/contact.svg';
import withSecurity from '../../config/security/security';

export class ContactsHomePage extends Component {
  componentDidMount() {
    this.props.setHeader({
      title: 'Contatos',
      image: contact
    });
  }

  render() {
    const { showLoader, hideLoader, security } = this.props;

    return (
      <div>
        <div className='content-box-without-color contact-container'>
          <Row>
            <Col sm='12'>
              <ErrorMessageComponent id={'contacthome'} />
            </Col>
          </Row>
          <ContactsSearchComponent {...this.props} />
          <ContactsListComponent showLoader={showLoader} hideLoader={hideLoader} />
        </div>
        <ContactsDetailModal />
        <ContactsSaveModal showLoader={showLoader} hideLoader={hideLoader} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (data) => dispatch(showLoader()),
    hideLoader: (data) => dispatch(hideLoader()),
    setHeader: (data) => dispatch(setHeader(data))
  };
};

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withSecurity);

export default enhanced(ContactsHomePage);
