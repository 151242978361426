import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import ptBR from 'date-fns/locale/pt-BR';
import moment from 'moment';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';
import { DateRange } from 'react-date-range';

const CustomDateRange = styled(DateRange)`
  text-transform: capitalize;
  input,
  select {
    text-transform: capitalize;
  }

  .rdrDayToday .rdrDayNumber span:after {
    background-color: #753491;
  }
`;

const ModalPeriod = ({ onSelected }, modalRef) => {
  const [showModal, setShowModal] = useState(false);
  const [periodo, setPeriodo] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  });

  useImperativeHandle(modalRef, () => ({
    openModal() {
      setShowModal(true);
    }
  }));

  function closeModal() {
    setShowModal(false);
  }

  return (
    <Modal isOpen={showModal} toggle={() => closeModal(false)} size={'mdplus'}>
      <ModalHeader
        className='title-primary'
        toggle={() => closeModal()}
        close={
          <button className='close' onClick={() => closeModal()}>
            &times;
          </button>
        }
      >
        <strong>Selecionar Período</strong>
      </ModalHeader>
      <ModalBody style={{ textTransform: 'capitalize' }}>
        <CustomDateRange
          locale={ptBR}
          onChange={(item) => {
            setPeriodo(item.selection);
            onSelected(item.selection);
          }}
          dateDisplayFormat={'dd/MM/yyyy'}
          rangeColors={['#753491']}
          editableDateInputs={true}
          moveRangeOnFirstSelection={true}
          months={2}
          ranges={[periodo]}
          direction='horizontal'
        />
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col sm={{ size: 12, offset: 10 }}>
            <button
              className='button-tertiary'
              onClick={() => {
                closeModal();
              }}
            >
              Aplicar
            </button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

export default forwardRef(ModalPeriod);
