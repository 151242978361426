import React, { Component } from 'react';
import Loader from '../../components/Loader.component';
import { Col, Container, Input, Label, Row } from 'reactstrap';
import logo from '../../assets/img/logo.jpg';
import ErrorField from '../../components/ErrorField.component';
import { Form, Formik } from 'formik';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { hideLoader, showLoader } from '../../components/actions/Loader.actions';
import * as Yup from 'yup';
import ClientApi from '../../service/ClientApi';
import { withRouter } from 'react-router';
import ToastUtils from '../../utils/ToastUtils';
import { t } from 'typy';
import { ToastContainer } from 'react-toastify';
import Select from 'react-select';
import { getStates } from '../../utils/StatesUtil';
import { reactSelectHandleChange, reactSelectStyleIsError } from '../../utils/ReactSelectUtils';
import { selectStyles } from '../../config/core.config';
import { maskPhoneDefine } from '../../utils/Formatter';
import MaskedInput from 'react-text-mask';
import ErrorFieldWithoutBootstrap from '../../components/ErrorFieldWithoutBootstrap.component';

class ConfirmTrialPage extends Component {
  state = {
    token: '',
    statesOptions: []
  };

  validationSchema = Yup.object().shape({
    // cro: Yup.string().required('CRO obrigatório'),
    // uf: Yup.object().shape({
    //   value: Yup.string().required('UF obrigatório')
    // }),
    senha: Yup.string().required('Senha obrigatório'),
    csenha: Yup.string()
      .required('Senha obrigatório')
      .oneOf([Yup.ref('senha')], 'Senha não confere')
  });

  componentDidMount() {
    const { showLoader, hideLoader, history } = this.props;
    const { token } = this.props.match.params;
    showLoader();
    ClientApi.checkPending(token)
      .then(() => {
        this.setState({
          token,
          statesOptions: getStates().map((s) => {
            return { value: s.sigla, label: s.sigla };
          })
        });
        hideLoader();
      })
      .catch((err) => {
        hideLoader();
        history.push('/login');
        ToastUtils.error('Token expirado por favor inicie o processo novamente ou entre em contato com o suporte');
      });
  }

  configClinic = (values) => {
    const { showLoader, hideLoader, history } = this.props;
    const { token } = this.state;

    showLoader();
    const request = {
      nome: values.nome,
      cro: '',
      uf: '',
      telefone: values.telefone,
      senha: values.senha
    };

    ClientApi.configureClinic(token, request)
      .then(({ data }) => {
        hideLoader();
        history.push('/trial/confirm/success', { nome: values.nome });
      })
      .catch((err) => {
        console.error(err);
        hideLoader();
        ToastUtils.error('Erro ao registrar o trial entre em contato com o suporte');
      });
  };

  render() {
    const { statesOptions } = this.state;
    return (
      <Formik
        initialValues={{
          telefone: '',
          senha: ''
        }}
        onSubmit={this.configClinic}
        validationSchema={this.validationSchema}
        validateOnChange={false}
      >
        {({ errors, values, handleChange }) => {
          return (
            <Form autoComplete='off'>
              <Container>
                <Loader />
                <main className='main-container-overflow'>
                  <div className='login-container'>
                    <div className='login-box' style={{ height: t(errors).isEmptyObject ? 460 : 500 }}>
                      <div className='login-box-right' style={{ paddingLeft: 100, paddingRight: 100 }}>
                        <Row>
                          <Col sm='12'>
                            <img className='img-logo-login' src={logo} alt='logo' />
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col sm='12' className={'font-bold title-large pb-3'}>
                            Vamos criar a Sua Conta...
                          </Col>
                        </Row>
                        {/*<Row style={ { marginTop: 10, marginBottom: 10 } }>*/}
                        {/*  <Col sm={ 12 }>*/}
                        {/*    <Label className={ 'text-secondary' }>Seu Nome <span> *</span></Label>*/}
                        {/*    <Input*/}
                        {/*      name="nome"*/}
                        {/*      onChange={ handleChange }*/}
                        {/*      invalid={ (errors.nome) ? true : false }*/}
                        {/*    />*/}
                        {/*    <ErrorField errors={ errors } fieldName={ 'nome' }/>*/}
                        {/*  </Col>*/}
                        {/*</Row>*/}
                        {/*<Row style={ { marginTop: 10, marginBottom: -5 } }>*/}
                        {/*  <Col sm={ 9 }><Label className={ 'text-secondary' }>CRO <span> *</span></Label></Col>*/}
                        {/*  <Col sm={ 3 }><Label className={ 'text-secondary' }>UF <span> *</span></Label></Col>*/}
                        {/*</Row>*/}
                        {/*<Row style={ { marginBottom: 10 } }>*/}
                        {/*  <Col sm={ 9 }>*/}
                        {/*    <Input*/}
                        {/*      name="cro"*/}
                        {/*      onChange={ handleChange }*/}
                        {/*      invalid={ (errors.cro) ? true : false }*/}
                        {/*    />*/}
                        {/*    <ErrorField errors={ errors } fieldName={ 'cro' }/>*/}
                        {/*  </Col>*/}
                        {/*  <Col sm={ 3 }>*/}
                        {/*    <Select*/}
                        {/*      placeholder={ '' }*/}
                        {/*      options={ statesOptions }*/}
                        {/*      onChange={ (p) => reactSelectHandleChange(p, handleChange, 'uf') }*/}
                        {/*      styles={ reactSelectStyleIsError(errors, 'uf.value', selectStyles) }*/}
                        {/*    />*/}
                        {/*    <ErrorFieldWithoutBootstrap errors={ errors } fieldName={ 'uf.value' }/>*/}
                        {/*  </Col>*/}
                        {/*</Row>*/}
                        {/*<Row style={ { marginTop: 10, marginBottom: 10 } }>*/}
                        {/*  <Col sm={ 12 }>*/}
                        {/*    <Label className={ 'text-secondary' }>Telefone com WhatsApp <span> *</span></Label>*/}
                        {/*    <MaskedInput*/}
                        {/*      id="telefone"*/}
                        {/*      name="telefone"*/}
                        {/*      guide={ false }*/}
                        {/*      mask={ maskPhoneDefine(values.telefone) }*/}
                        {/*      defaultValue={ values.telefone }*/}
                        {/*      onChange={ handleChange }*/}
                        {/*      render={ (ref, props) => (*/}
                        {/*        <Input*/}
                        {/*          autocomplete={ 'off' }*/}
                        {/*          className="input-secondary"*/}
                        {/*          innerRef={ ref }*/}
                        {/*          invalid={ errors.telefone ? true : false }*/}
                        {/*          { ...props }*/}
                        {/*        />*/}
                        {/*      ) }*/}
                        {/*    />*/}
                        {/*    <ErrorField errors={ errors } fieldName={ 'telefone' }/>*/}
                        {/*  </Col>*/}
                        {/*</Row>*/}
                        <Row style={{ marginTop: 10, marginBottom: 10 }}>
                          <Col sm={12}>
                            <Label className={'text-secondary'}>
                              Senha <span> *</span>
                            </Label>
                            <Input
                              value={values.senha}
                              name='senha'
                              type={'password'}
                              onChange={handleChange}
                              autocomplete={'off'}
                              invalid={errors.senha ? true : false}
                            />
                            <ErrorField errors={errors} fieldName={'senha'} />
                          </Col>
                        </Row>
                        <Row style={{ marginTop: 10, marginBottom: 10 }}>
                          <Col sm={12}>
                            <Label className={'text-secondary'}>
                              Confirmar Senha <span> *</span>
                            </Label>
                            <Input
                              value={values.csenha}
                              name='csenha'
                              type={'password'}
                              onChange={handleChange}
                              autocomplete={'off'}
                              invalid={errors.csenha ? true : false}
                            />
                            <ErrorField errors={errors} fieldName={'csenha'} />
                          </Col>
                        </Row>
                        <Row style={{ marginTop: 10 }}>
                          <Col className='text-left' sm={12}>
                            Ao prosseguir, você concorda com o{' '}
                            <a href={'https://griks-assets.s3.amazonaws.com/termos_de_uso.pdf'} target={'blank'}>
                              {' '}
                              <span style={{ color: '#007bff' }}>Termo de Uso</span>
                            </a>{' '}
                            e a{' '}
                            <a href={'https://griks-assets.s3.amazonaws.com/politica_privacidade.pdf'} target={'blank'}>
                              {' '}
                              <span style={{ color: '#007bff' }}>Politica de Privacidade</span>
                            </a>{' '}
                            do Griks
                          </Col>
                        </Row>
                        <Row className={'align-content-center'}>
                          <Col sm={{ size: 10, offset: 1 }}>
                            <button type='submit' className='button-primary' style={{ width: 230, height: 37 }}>
                              Continuar
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </main>
              </Container>
              <ToastContainer />
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader())
});

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withRouter);

export default enhanced(ConfirmTrialPage);
