import { call, all, put } from 'redux-saga/effects';
import { showLoader, hideLoader } from '../../../../components/actions/Loader.actions';
import DomainsAPI from '../../../../service/DomainsAPI';
import {
  treatmentEvaluationModalResult,
  treatmentEvaluationOpenAngleRating
} from '../redux/TreatmentEvaluation.action';

export function* modalEvaluationResult({ payload: { modal, formLoad = {} } }) {
  yield put(showLoader());

  const domains = yield all([
    call(DomainsAPI.findAllEvaluationResult),
    call(DomainsAPI.findAllEvaluationClinicInterest),
    call(DomainsAPI.findAllEvaluationComplexity),
    call(DomainsAPI.findAllBraces)
  ]);

  formLoad.formData = {
    results: domains[0],
    clinicInterests: domains[1],
    complexities: domains[2],
    braces: domains[3]
  };

  yield put(treatmentEvaluationModalResult({ modal, formLoad }));

  yield put(hideLoader());
}
