import { call, put, takeEvery } from 'redux-saga/effects';
import { hideLoader, showLoader } from '../../../../components/actions/Loader.actions';
import UserAPI from '../../../../service/User.service';
import { openSettingsEmissionsReferences } from '../store/settings_emission_references_store';
import ClinicAPI from '../../../../service/ClinicAPI';

export const Sagas = {
  SETTINGS_EMISSION_REFERENCES_EDIT: 'settings_emission_references/saga/Edit'
};

export function* watchSettingsEmissionReferences() {
  yield takeEvery(Sagas.SETTINGS_EMISSION_REFERENCES_EDIT, prepareEditSettingsEmissionReferences);
}

export function* prepareEditSettingsEmissionReferences({ payload }) {
  yield put(showLoader());
  const dentists = yield call(UserAPI.findAllDentistas);
  const references = yield call(ClinicAPI.getReferencesByEmission);
  yield put(
    openSettingsEmissionsReferences({
      dentists,
      showModal: payload.showModal,
      clinica: payload.data,
      references: references.data
    })
  );
  yield put(hideLoader());
}
