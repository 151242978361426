import ErrorMessage from '../../components/reducers/ErrorMessage.reducer';
import LoaderReducer from '../../components/reducers/Loader.reducer';
import ContentReducer from '../../pages/template/redux/Content.reducer';
import PatientReducer from '../../pages/patinent/redux/patient.reducer';
import ContactsReducer from '../../pages/contacts/redux/contacts.reducer';
import SettingsReducer from '../../pages/settings/clinic/redux/settings.reducers';
import UserSettingsReducer from '../../pages/settings/users/redux/UserSettings.reducer';
import ScheduleSettingsReducer from '../../pages/settings/schedule/redux/ScheduleSettingsReducer';
import SettingsLibrariesReducer from '../../pages/settings/libraries/redux/SettingsLibrariesReducer';
import SettingsProceduresReducer from '../../pages/settings/procedures/redux/SettingsProcedures.reducer';
import SettingsProceduresTableReducer from '../../pages/settings/procedures/redux/SettingsProcedureTablePrice.reducer';
import TreatmentReducer from '../../pages/treatment/redux/Treatment.reducer';
import TreatmentEvaluationReducer from '../../pages/treatment/evaluation/redux/TreatmentEvaluation.reducer';
import TreatmentBudgetReducer from '../../pages/treatment/budget/redux/TreatmentBudget.reducer';
import TreatmentClinicalRecordReducer from '../../pages/treatment/clinicalRecord/redux/TreatmentClinicalRecord.reducers';
import TreatmentFinancialFileReducer from '../../pages/treatment/financialFile/redux/TreatmentFinancialFile.reducer';
import ScheduleReducer from '../../pages/schedule/redux/Schedule.reducer';
import CashReducer from '../../pages/cash/redux/cash';
import SettingsEmissionReferenceReducer from '../../pages/settings/clinic/store/settings_emission_references_store';
import TreatmentImagesReducer from '../../pages/treatment/images/store/TreatmentImagesStore';
import DentistsSharedReducer from '../../shared/dentists_store';
import PatientReportReducer from '../../pages/reports/patients/store/PatientReportStore';

export default {
  ErrorMessage,
  LoaderReducer,
  ContentReducer,
  PatientReducer,
  ContactsReducer,
  SettingsReducer,
  UserSettingsReducer,
  ScheduleSettingsReducer,
  SettingsLibrariesReducer,
  SettingsProceduresReducer,
  SettingsProceduresTableReducer,
  TreatmentReducer,
  TreatmentEvaluationReducer,
  TreatmentBudgetReducer,
  TreatmentClinicalRecordReducer,
  TreatmentFinancialFileReducer,
  ScheduleReducer,
  CashReducer,
  SettingsEmissionReferenceReducer,
  TreatmentImagesReducer,
  PatientReportReducer,

  //Shareds
  DentistsSharedReducer
};
