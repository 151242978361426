import React, { Component } from 'react';

import { Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { procedureTablePrice } from '../redux/SettingsProcedureTablePrice.action';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ErrorField from '../../../../components/ErrorField.component';
import ProceduresAPI from '../../../../service/ProceduresAPI';
import { t } from 'typy';
import InputDecimalComponent from '../../../../components/InputDecimalComponent';
import ToastUtils from '../../../../utils/ToastUtils';
import ErrorFieldWithoutBootstrap from '../../../../components/ErrorFieldWithoutBootstrap.component';

class SettingsProcedureClosedPriceModal extends Component {
  state = {
    errorMessage: {
      display: false,
      type: 'danger',
      message: ''
    }
  };

  formValidationSchema = Yup.object().shape({
    procedure: Yup.string().required('Procedimento Obrigatório'),
    valor: Yup.number().required('Valor Obrigatório')
  });

  saveProcedure = (values) => {
    const { formData, showLoader, hideLoader, closeModal, refreshPage } = this.props;
    const { category = {} } = formData;

    showLoader();
    ProceduresAPI.saveTablePriceProcedure(category.id, {
      id: formData.id,
      nome: values.procedure,
      valorProcedimento: values.valor,
      status: 'A'
    })
      .then(({ data }) => {
        hideLoader();
        closeModal();
        refreshPage(category);
      })
      .catch(({ data }) => {
        console.error(data);
        ToastUtils.error('Erro ao salvar procedimento');
        hideLoader();
      });
  };

  render() {
    const { errorMessage } = this.state;
    const { modal, closeModal, formData: { category = {} } = {} } = this.props;

    return (
      <Modal isOpen={modal} toggle={closeModal} className={this.props.className}>
        <ModalHeader
          className='title-primary'
          toggle={closeModal}
          close={
            <button className='close' onClick={closeModal}>
              &times;
            </button>
          }
        >
          {t(this.props.formData, 'id').isDefined ? <strong>Editar</strong> : <strong>Adicionar</strong>}
          Procedimento
        </ModalHeader>
        <Formik
          validationSchema={this.formValidationSchema}
          initialValues={this.props.formData}
          validateOnChange={false}
          onSubmit={this.saveProcedure}
        >
          {({ errors, handleSubmit, handleBlur, handleChange, values }) => {
            return (
              <form onSubmit={handleSubmit}>
                <ModalBody>
                  <div className='margin-top-10'>
                    <Row className='margin-top-10'>
                      <Col className='text-left' sm='12'>
                        <h1 className='title-modal'>
                          <strong>Ortodontia</strong> Modalidade Preço Fechado
                        </h1>
                      </Col>
                    </Row>
                    <Row className='margin-top-10'>
                      <Col className='text-left' sm='12'>
                        <p>
                          <strong>Categoria</strong> {category.nome}
                        </p>
                      </Col>
                    </Row>
                    <Row className='margin-top-10'>
                      <Col sm='7'>
                        <label>
                          Procedimento<span>*</span>
                        </label>
                        <Input
                          className='input-secondary'
                          type='text'
                          name='procedure'
                          value={values.procedure}
                          onChange={handleChange}
                          invalid={errors.procedure ? true : false}
                        />
                        <ErrorField errors={errors} fieldName={'procedure'} />
                      </Col>
                      <Col sm='5'>
                        <label>Valor</label>
                        <InputDecimalComponent
                          classNameStyle={'input-secondary'}
                          prefix={'R$ '}
                          name={'valor'}
                          value={values.valor}
                          handleChange={handleChange}
                          errors={errors}
                        />
                        <ErrorFieldWithoutBootstrap errors={errors} fieldName={'valor'} />

                        {/*<InputGroup invalid={ errors.valor ? true : false }>*/}
                        {/*  <InputGroupAddon addonType="prepend" invalid={ errors.valor ? true : false }>*/}
                        {/*    <InputGroupText invalid={ errors.valor ? true : false }>R$</InputGroupText>*/}
                        {/*  </InputGroupAddon>*/}
                        {/*  <Input*/}
                        {/*    type="number" value={ values.valor } min="0" step="0.01" data-number-to-fixed="2"*/}
                        {/*    data-number-stepfactor="100" id="valor"*/}
                        {/*    onChange={ handleChange }*/}
                        {/*    invalid={ errors.valor ? true : false }/>*/}
                        {/*  <ErrorField errors={ errors } fieldName={ 'valor' }/>*/}
                        {/*</InputGroup>*/}
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Row>
                    <Col sm={{ size: 3, offset: 6 }}>
                      <button className='button-primary' type={'submit'}>
                        Salvar
                      </button>
                    </Col>
                    <Col sm='3'>
                      <button className='button-tertiary' onClick={closeModal}>
                        Cancelar
                      </button>
                    </Col>
                  </Row>
                </ModalFooter>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = ({ SettingsProceduresTableReducer }) => {
  const { procedure } = SettingsProceduresTableReducer;

  return {
    ...procedure
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(procedureTablePrice({ modal: false, formData: {} }))
  };
};

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhanced(SettingsProcedureClosedPriceModal);
